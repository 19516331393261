import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MessagesService } from 'src/app/api/services/messages/messages.service';
import { UserService } from 'src/app/api/services/user/user.service';
import { StatusEnum } from 'src/app/enums/status';
import { RolService } from 'src/app/services/rol/rol.service';
import { FileData } from '../../../../api/interfaces/fileData';
import { MassiveMessage, Mensaje, Destinatarios } from '../../../../api/interfaces/messages';
import { DownloadService } from '../../../../api/services/download/download.service';
import { saveAs } from 'file-saver';
import { TrainingDays } from 'src/app/api/interfaces/register';
import { Level, TType } from 'src/app/api/interfaces/user';

@Component({
  selector: 'app-message-massive',
  templateUrl: './message-massive.component.html',
  styleUrls: ['./message-massive.component.scss']
})
export class MessageMassiveComponent implements OnInit {

  @Input('message') message: Mensaje;
  @Input('autofocus') autofocus: boolean;
  @Input('levels') levels:  Level[];
  @Input('trainingDays') trainingDays: TrainingDays[];
  @Input('allTypes') allTypes: TType[];

  lid: number [] = [2,3,4];
  gid: number [] = [7,3];
  tid: number [] = [1,2];

  levelsToShow: Level[] = [];
  trainingDaysToShow: TrainingDays[] = [];
  typesToShow: TType[] = [];
  metadataJson: Destinatarios;

  @ViewChild('lastChat') lastChat;

  class: string = "incomingMessage";

  studentReply: string = "";
  /**
   * @var studentReply
   *  "yes": quiere seguir recibiendo plan
   *  "no": no quiere seguir recibiendo plan
   *  "noReply": no contesto nada
   */


  showDestinatarios: boolean = false;
  isAdmin: boolean = false;
  
  constructor(
    private rol: RolService,
    private messageService: MessagesService,
    private userService: UserService,
    private downloadService: DownloadService
  ) { }

  ngOnInit(): void {
    this.isAdmin = this.rol.isAdmin();
    if(this.isAdmin) {
      this.class = "outgoingMessage";
    }
    if(this.message.metadata.charAt(0) == '{') {

      this.metadataJson = JSON.parse(this.message.metadata);
      this.levels.forEach(level => {
        this.metadataJson.nid.forEach(element => {
          if(element == level.id) {
            this.levelsToShow.push(level);
          }
        })
      });
      
      this.trainingDays.forEach(el => {
        this.metadataJson.gid.forEach(element => {
          if(element == el.entrenamientoGrupoId) {
            this.trainingDaysToShow.push(el);
          }
        })
      });
      
      this.allTypes.forEach(el => {
        this.metadataJson.tid.forEach(element => {
          if(element == el.entrenamientoTipoId) {
            this.typesToShow.push(el);
          }
        })
      });
    }
    // if(!this.isAdmin && this.message.vacaciones) {
    //   this.getMyProfileData();
    // }
  }

  toggleDestinatarios() {
    this.showDestinatarios = !this.showDestinatarios;
  }

  // getMyProfileData() {
  //   const id: number = parseInt(localStorage.getItem('idAlumno'),10);
  //   this.userService.getProfileUserId(id).subscribe(response => {
  //     if(response.estado == StatusEnum.SUSPENDIDO) {
  //       //TODO: agregar validacion si respondio que no o nunca respondio
  //       /*if (respondio no)  {
  //          this.studentReply = "no";
  //       } else {
  //         this.studentReply = "noReply";
  //       }
  //       */
  //       this.studentReply = "noReply";
  //     }
  //   },
  //     error => {
  //       console.error(error);
  //     });
  // }

  ngAfterViewInit(){
    if(this.autofocus) {
      this.lastChat.nativeElement.focus();
    }
  } 

  // choose(aux: string) {
  //   if(aux == 'yes') {
  //     // todo: esta harcodeado! hay que recibir el id de la suspencion y pasarlo
  //     this.messageService.deleteSuspension(1).subscribe( response=> {
  //     },
  //     error => {
  //       console.error(error);
  //     });
  //     this.studentReply = "yes";
  //   } else {
  //     this.studentReply = "no";
  //   }
  // }

  download() {
    const fileData: FileData = {
      filename: "respuestas.pdf",
    }
    this.downloadService.downloadHolidays()
      .subscribe(archivo => {
        saveAs(archivo, fileData.filename);
      });
  }

}
