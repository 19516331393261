<div class="conversation">    
    <app-message-header [canal]="canal"></app-message-header>
    <div>
        <div *ngIf="!showForm" class="messages"
            infinite-scroll
            infiniteScroll
            [infiniteScrollDistance]="2"
            [infiniteScrollThrottle]="50"
            [scrollWindow]="false"
            (scrolledUp)="onUpMessages()">
            
            <div *ngIf="messages.length == 0">
                <div class="flexCenter" *ngIf="isAdmin">
                    <mat-icon svgIcon="megaphone" class="massiveEmpty" ></mat-icon>
                </div>
                <br/>
                <p class="body3" *ngIf="isAdmin">{{'messages.massive.empty' | translate}}</p>
                <div class="noSelected" *ngIf="!isAdmin">
                    <p class="headline6">{{'messages.massive.emptyNoAdmin' | translate}}</p>
                    <p class="body2">{{'messages.massive.emptyNoAdmin2' | translate}}</p>
                </div>
                </div>
            <div *ngFor="let message of messages; let i = index">
                <app-message-massive
                    *ngIf="loaded"
                    [message]="message" 
                    [autofocus]="i+1 == messages.length"
                    [levels]="allLevels"
                    [trainingDays]="allTrainingDays"
                    [allTypes]="allTypes">
                </app-message-massive>
            </div>
        </div>
    </div>

    <app-new-massive 
        *ngIf="showForm && isAdmin" 
        [allLevels]="allLevels"
        [allTrainingDays]="allTrainingDays"
        (newMessageEvent)="newMessageEvent($event)" 
        (closeEvent)="closeEvent()">
    </app-new-massive>
    
    <app-new-message 
        *ngIf="!showForm && isAdmin" 
        [allLevels]="allLevels"
        [allTrainingDays]="allTrainingDays"
        (click)="toggleNewMassive()"
        [isMassive]="true"
        [enable]="true">
    </app-new-message>

    <div class="newMessage" *ngIf="!isAdmin">
        <p class="body3">{{'messages.massive.noAdmin' | translate}}</p>
    </div>
</div>
