<form [formGroup]="suspensionesForm" *ngIf="suspensiones">
    
    <mat-form-field appearance="fill" class="inputDate">
        <mat-label>{{ 'profile.statusHistoryTab.from' | translate}}</mat-label>
        <input id="dateInput" matInput disabled formControlName="fechaDesde" [min]="minDate" [matDatepicker]="picker">
        <mat-datepicker-toggle class="primaryIcon" [disabled]="!suspensiones.editable" matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker [disabled]="disable == true ? true : null" disabled="false" #picker>
            <mat-datepicker-actions>
                <button mat-button matDatepickerCancel>{{ 'calendar.cancel' | translate}}</button>
                <button mat-raised-button color="accent" matDatepickerApply (click)="addSuspensiones()">{{ 'calendar.save' | translate}}</button>
              </mat-datepicker-actions>
        </mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" class="inputDate">
        <mat-label>{{ 'profile.statusHistoryTab.until' | translate}}</mat-label>
        <input id="dateInput2" matInput disabled formControlName="fechaHasta" [min]="minDateEnd" [matDatepicker]="picker2">
        <mat-datepicker-toggle class="primaryIcon" [disabled]="!suspensiones.editable" matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-datepicker [disabled]="disable == true ? true : null" disabled="false" #picker2>
            <mat-datepicker-actions>
                <button mat-button matDatepickerCancel>{{ 'calendar.cancel' | translate}}</button>
                <button mat-raised-button color="accent" matDatepickerApply (click)="addSuspensiones()">{{ 'calendar.save' | translate}}</button>
              </mat-datepicker-actions>
        </mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" class="inputsFilter2">
        <mat-label>{{'profile.statusHistoryTab.reasons' | translate}}</mat-label>
        <mat-select #motivosSelect id="motivosInput" formControlName="suspensionMotivoId">
          <mat-option *ngFor="let motivo of motivos" [value]="motivo.id">{{motivo.motivoDescripcion}}
          </mat-option>
        </mat-select>
    </mat-form-field>

    <button *ngIf="disable || suspensiones.editable" (click)="removeSuspensiones()"  mat-icon-button>
        <mat-icon svgIcon="delete" id="deleteButton" class="delete"></mat-icon>
    </button>

    <div class="deleteDiv" *ngIf="!disable && isMobile">
        <button class="deleteButton" *ngIf="!disable" mat-button  (click)="removeSuspensiones()"> {{'registration.stepThree.delete' | translate}}</button>
    </div>
</form>
