import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {DateAdapter} from '@angular/material/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from "@angular/material/icon";
import { MobileDetectorService } from '../../../../services/mobileDetector/mobile-detector.service';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';
import { Formacion, FormacionEdicion } from '../../../../api/interfaces/trainers';
import { TrainersService } from '../../../../api/services/trainers/trainers.service';
import { GenericDialogComponent } from '../../../generic-dialog/generic-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-formacion-inputs',
  templateUrl: './formacion-inputs.component.html',
  styleUrls: ['./formacion-inputs.component.scss']
})
export class FormacionInputsComponent implements OnInit, OnDestroy {

  isMobile: boolean = false;
  disable: boolean = true;
  activo: boolean = true;
  lock: boolean = false;

  dontExistInDB: number = -1;

  private sharedData$: Subscription;

  formacionForm = new FormGroup({
    entrenadorFormacionTitulo: new FormControl(),
    entrenadorFormacionDescripcion: new FormControl(),
    fechaFinal: new FormControl(),
  });

  @Output() removeFormacionEvent = new EventEmitter<any>();
  @Output() saveFormacionEvent: EventEmitter<any> = new EventEmitter();
  
  @Input('i') i?: number;
  @Input('formacion') formacion? : Formacion;
  @Input('entrenadorId') entrenadorId: number;

  constructor(
    private _adapter: DateAdapter<any>,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private mobileDetectorService: MobileDetectorService,
    private sharedData: SharedDataService,
    private trainersService: TrainersService,
    public dialog: MatDialog,
    ) { 
      this.matIconRegistry.addSvgIcon(
        `delete`,
        this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../assets/delete.svg')
      );
    }

    ngOnInit(): void {
      this.sharedData$ = this.sharedData.currentFlag.subscribe(flag => {
        if(flag) {
          if(flag.key == "editFormacion") {
            if(flag.value && this.disable) {
              this.lock = true;
            }
            if(!flag.value) {
              this.lock = false;
            }
          }
        }
      });

      //user must be able to complete/delete the new formacion after is added in formacion-card.component
      if(this.formacion.id == this.dontExistInDB) {
        this.disable = false;
        this.enableInputs();
      }

      this.isMobile = this.mobileDetectorService.isMobile();
      this.addFormacion();
      this._adapter.setLocale('es');
      if(this.formacion) {
        this.formacionForm.patchValue({entrenadorFormacionTitulo: this.formacion.entrenadorFormacionTitulo});
        this.formacionForm.patchValue({entrenadorFormacionDescripcion: this.formacion.entrenadorFormacionDescripcion});
      } else {
        this.formacionForm.patchValue({id: null});  //requerido por backend
      }
      if(this.disable) {
       this.disableInputs();
      }
    }

    enableInputs() {
      this.formacionForm.controls['entrenadorFormacionTitulo'].enable();
      this.formacionForm.controls['entrenadorFormacionDescripcion'].enable();
      this.formacionForm.controls['fechaFinal'].enable();
      this.sharedData.changeFlag("editFormacion", true);
    }

    disableInputs() {
      this.formacionForm.controls['entrenadorFormacionTitulo'].disable();
      this.formacionForm.controls['entrenadorFormacionDescripcion'].disable();
      this.formacionForm.controls['fechaFinal'].disable();
      this.sharedData.changeFlag("editFormacion", false);
    }
  
    removeFormacion() {
      const dialogRef = this.dialog.open(GenericDialogComponent, {
        data: {
          title: "¿Desea eliminar la formación?",
          iconClass: "bigGreyIcon",
          text: "",
          icon: "error_outlined",
          confirm: "ELIMINAR",
          cancel: "CANCELAR"
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          const { entrenadorFormacionTitulo,  entrenadorFormacionDescripcion } = this.formacionForm.getRawValue();
          const formacion: FormacionEdicion = {
            entrenadorFormacionTitulo: entrenadorFormacionTitulo,
            entrenadorFormacionDescripcion: entrenadorFormacionDescripcion,
            activo: false
          }
          
          if(this.formacion.id == this.dontExistInDB) {
            this.sharedData.changeFlag("editFormacion", false);
            this.removeFormacionEvent.emit(this.i);
          } else {
            this.trainersService.editFormacion(formacion, this.formacion.id)
            .subscribe(response => {
              this.sharedData.changeFlag("editFormacion", false);
              this.removeFormacionEvent.emit(this.i);
            },
            error => {
              console.error(error);
            });
          }
        }
      });
    }
  
    addFormacion() {
      this.formacionForm.value.i = this.i;
      this.saveFormacionEvent.emit(this.formacionForm.value);
    }

    saveFormacion() {
      const { entrenadorFormacionTitulo,  entrenadorFormacionDescripcion } = this.formacionForm.getRawValue();
      const formacion: FormacionEdicion = {
        entrenadorFormacionTitulo: entrenadorFormacionTitulo,
        entrenadorFormacionDescripcion: entrenadorFormacionDescripcion,
        activo: true
      }

      this.toggleEdit();

      
      if(this.formacion.id == this.dontExistInDB) {
        this.trainersService.createFormacion(formacion, this.entrenadorId)
        .subscribe(response => {
          this.formacion.id = response;
        },
        error => {
          console.error(error);
        });
      } else {
        this.trainersService.editFormacion(formacion, this.formacion.id)
        .subscribe(response => {
          },
        error => {
          console.error(error);
        });
      }

    }

    toggleEdit() {
      this.disable = !this.disable;
      this.disable ? this.disableInputs() : this. enableInputs() ;
    }

    public ngOnDestroy(): void {
      this.sharedData$.unsubscribe();
    }

}
