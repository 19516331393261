import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DetalleFormularioDinamico, FormularioDinamicoPregunta, OpcionTextoFormularioDinamicoPregunta } from 'src/app/api/interfaces/formulario-dinamico';
import { TiposPreguntaEnum } from 'src/app/enums/tiposPregunta';


@Component({
  selector: 'app-radio-text-area-input',
  templateUrl: './radio-text-area-input.component.html',
  styleUrls: ['./radio-text-area-input.component.scss']
})
export class RadioTextAreaInputComponent implements OnInit {

  @Input('requerido') requerido: boolean;
  @Input('opciones') opciones: DetalleFormularioDinamico[];
  @Input('pregunta') pregunta: FormularioDinamicoPregunta;
  @Input('cantCaracteres') cantCaracteres: number;

  @Output() textoIngresadoEvent = new EventEmitter<OpcionTextoFormularioDinamicoPregunta>();

  showTextArea: boolean = false;

  radioGroupClass: string = "radioGroupColumn";
  radioButtonClass: string = "example-radio-button";

  optionsForm = new FormGroup({
   valor: new FormControl(),
  });

  constructor() { }

  ngOnInit(): void {
    if(this.pregunta.tipo == TiposPreguntaEnum.RADIO_HORIZONTAL) {
      this.radioGroupClass = "radioGroupRowWithMargin"
      this.radioButtonClass = "radioButtonHorizontal";
    }
    this.optionsForm.statusChanges
    .subscribe(val => {
      this.emitirRespuesta();
    });
    if(!this.cantCaracteres) {
      this.cantCaracteres = 200;
    }
  }

  emitirRespuesta() {
    const { valor } = this.optionsForm.getRawValue();
    const opcionTexto: OpcionTextoFormularioDinamicoPregunta = {
      preguntaId: this.pregunta.id,
      respuestaTexto: this.showTextArea ? valor : "No"
    }
    this.textoIngresadoEvent.emit(opcionTexto);
  }

  toggleTextArea(aux: boolean) {
    this.showTextArea = aux;
    aux ? this.optionsForm.value.valor = null : this.optionsForm.value.valor =  "No";
    this.emitirRespuesta();
  }

}
