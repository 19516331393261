<div class="dialogContainer450">
    <div class="flexBeetwen">
        <p class="headline5" *ngIf="!edit"> {{'section.dialogs.newSection'  | translate}} </p>
        <p class="headline5" *ngIf="edit" > {{'section.dialogs.editSection' | translate}} </p>

        <button mat-button class="buttonDialogClose" mat-dialog-close>
            <mat-icon class="clearIcon">close</mat-icon>
        </button>
    </div>
    <form [formGroup]="newForm">
        <mat-form-field appearance="fill" class="inputfullWidth">
            <mat-label> {{ 'section.dialogs.input'| translate}} </mat-label>
            <input matInput type="text" formControlName="seccionNombre" required>      
          </mat-form-field>
    </form>
    <button mat-raised-button color="accent" (click)="onSubmit()" id="submit" class="primaryButton" [disabled]="!newForm.valid"> {{'section.dialogs.confirmButton' | translate}}</button>
</div>