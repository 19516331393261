import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog'
import { ParametroEdicion, ParametroEtiqueta } from 'src/app/api/interfaces/parametros';
import { DomiciliacionService } from 'src/app/api/services/domiciliacion/domiciliacion.service';
import { ParametrosService } from 'src/app/api/services/parametros/parametros.service';
import { SnackbarService } from 'src/app/utils/snackbar/snackbar.service';

/**
 * Edit parametro.valor with given number.
 * This is made only to update arancel.
 */
@Component({
  selector: 'app-settings-arancel-dialog',
  templateUrl: './settings-arancel-dialog.component.html',
  styleUrls: ['./settings-arancel-dialog.component.scss']
})
export class SettingsArancelDialogComponent implements OnInit {

  waiting: boolean = false;

  arancelForm = new FormGroup({
    arancel: new FormControl(),
  });

  constructor(
    private parametrosService: ParametrosService,
    private domiciliacionService: DomiciliacionService,
    private snack: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: ParametroEtiqueta,
    private dialogRef: MatDialogRef<SettingsArancelDialogComponent>
  ) { }

  ngOnInit(): void {
    this.arancelForm.patchValue({arancel: this.data.valor});
  }

  onSubmit() {
    this.waiting = true;
    const parametro: ParametroEdicion = {
      id: this.data.id,
      valor: this.arancelForm.value.arancel
    };
    this.parametrosService.editarParametro(parametro).subscribe(response=> {
      let tipoEntrenamiento: number = 0;
      // 1 presencial, 2 online
      this.data.clave.toLowerCase() === 'online' ? tipoEntrenamiento = 2 : tipoEntrenamiento = 1;
      this.domiciliacionService.modificarArancelTipoEntrenamiento(parametro.valor, tipoEntrenamiento).subscribe(response => {
        this.waiting = false;
        this.dialogRef.close(true);
      },
      error => {
        this.waiting = false;
        console.error(error);
        this.snack.openSnackBar(error.error.message);
      });
    },
    error => {
      this.waiting = false;
      console.error(error);
      this.snack.openSnackBar(error.error.message);
    });
  }

}
