import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { GenericDialogThreeButtonsData } from '../../api/interfaces/dialogThreeButtons';


/**
 * Display a warning and request user's confirmation before do something.
 * User can choose if do an action or not with confirmation
 * 
 * @param DialogThreeButtonsData {@link GenericDialogThreeButtonsData}
 * 
 * 
 * @example calling the dialog and doing something after confirmation
 * ```ts
 * const dialogRef = this.dialog.open(GenericDialogComponent, {
 *     data: {
 *       title: "¿Desea guardar los cambios?",
 *       iconClass: "bigBlueIcon",
 *       text: "Esto no es reversible",
 *       icon: "error_outlined",
 *       cancel: "NO"
 *       confirm: "GUARDAR,
 *       confirmAndAction: "GUARDAR Y NOTIFICAR",
 *       actionTrue: "true",
 *       actionFalse: "false"
 *     }
 *   });
 *
 *   dialogRef.afterClosed().subscribe(result => {
 *     let today = new Date().toISOString();
 *     if(result) {
 *        const action: string = sessionStorage.getItem("action")
 *       //do something and use action
 *   });
 * ```
 */
@Component({
  selector: 'app-generic-dialog-three-buttons',
  templateUrl: './generic-dialog-three-buttons.component.html',
  styleUrls: ['./generic-dialog-three-buttons.component.scss']
})
export class GenericDialogThreeButtonsComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<GenericDialogThreeButtonsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GenericDialogThreeButtonsData) { }

  ngOnInit(): void {
  }

  confirmAndAction(action: string) {
    sessionStorage.setItem("action",action);
    this.dialogRef.close(true);
  }

}
