<app-title 
    [contact]="true" 
    [disable]="disable" 
    [valid]="contactForm.valid" 
    [estado]="estado"
    (onSubmitEvent)='onSubmit()' 
    (editEvent)='edit()'>
</app-title>

<form [formGroup]="contactForm">
    

    <mat-form-field appearance="fill" class="inputPrefix">
        <mat-label>{{ 'forms.user.prefix' | translate}}</mat-label>
        <input min="1" required [attr.disabled]="disable == true ? true : null" id="prefix1Input" formControlName="prefijoCelular" type="number"
            matInput>
    </mat-form-field>

    <mat-form-field appearance="fill" class="inputPhone">
        <mat-label>{{ 'forms.contact.phone' | translate}}</mat-label>
        <input min="1" required [attr.disabled]="disable == true ? true : null" id="phoneInput" formControlName="numeroCelular" type="number"
            matInput>
    </mat-form-field>

    <mat-form-field appearance="fill" class="inputPrefix" *ngIf="showComplete">
        <mat-label>{{ 'forms.user.prefix' | translate}}</mat-label>
        <input min="1" required [attr.disabled]="disable == true ? true : null" id="prefix2Input" formControlName="prefijoEmergencia" type="number"
            matInput>
    </mat-form-field>

    <mat-form-field appearance="fill" class="inputPhone" *ngIf="showComplete">
        <mat-label>{{ 'forms.user.emergencyPhone' | translate}}</mat-label>
        <input min="1" required [attr.disabled]="disable == true ? true : null" id="emergencyPhoneInput" formControlName="numeroEmergencia"
            type="number" matInput>
    </mat-form-field>

    <mat-form-field class="inputTwoLine" appearance="fill">
        <mat-label>{{ 'forms.contact.email' | translate}}</mat-label>
        <input required [attr.disabled]="disable == true ? true : null" id="emailInput" formControlName="email" type="mail"
            matInput>
    </mat-form-field>
</form>