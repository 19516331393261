<div class="dialogContainer" *ngIf="createStudent && !waiting">
    <div class="flexBeetwen">
        <p mat-dialog-title class="headline5">{{ 'admin.createStudent.title' | translate }}</p>
        <button mat-button class="buttonDialogClose" mat-dialog-close>
            <mat-icon class="clearIcon">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content class="mat-typography">

        
        <form [formGroup]="studentForm">

            <div *ngIf="controlCantidad.limiteAlcanzado" class="flexLimits limiteAlcanzado">
                <div class="column1">
    
                    <mat-icon class="statusPendingIcon">
                        info_outlined
                    </mat-icon>
                </div>
                <div class="column2">
                    <p class="subtitle2 limiteAlcanzadoTexto">{{ 'admin.createStudent.limits.topReachedTitle' | translate }}</p>
                    <p class="body4 limiteAlcanzadoTexto"> <b> {{controlCantidad.texto}}</b> {{ 'admin.createStudent.limits.hint' | translate }}</p>
                    <!-- <p class="body3"> {{ 'admin.createStudent.limits.topReachedText' | translate}} <b>{{ 'admin.createStudent.limits.topReachedText2' | translate : {monto : controlCantidad.monto} }}</b> </p> -->
                </div>
            </div>
    
            <div *ngIf="controlCantidad.limiteSuperado" class="flexLimits limiteSuperado">
                <div class="column1">
    
                    <mat-icon class="statusPendingIcon limiteSuperadoTexto material-icons-outlined">
                        group_add
                    </mat-icon>
                </div>
                <div class="column2">
                    <p class="subtitle2 limiteSuperadoTexto" *ngIf="controlCantidad.cantidadAlumnosAdicionales == 1" >{{ 'admin.createStudent.limits.topOvercomeTitle' | translate : {adicional: controlCantidad.cantidadAlumnosAdicionales} }}</p>
                    <p class="subtitle2 limiteSuperadoTexto" *ngIf="controlCantidad.cantidadAlumnosAdicionales > 1" >{{ 'admin.createStudent.limits.topOvercomeTitle2' | translate : {adicional: controlCantidad.cantidadAlumnosAdicionales} }}</p>
                    <p class="body4 limiteSuperadoTexto"> <b> {{controlCantidad.texto}}</b> {{ 'admin.createStudent.limits.hint' | translate }}</p>
                    <!-- <p class="body3"> {{ 'admin.createStudent.limits.topOvercomeText' | translate}} <b>{{ 'admin.createStudent.limits.topReachedText2' | translate : {monto : controlCantidad.monto} }}</b> {{ 'admin.createStudent.limits.topOvercomeText2' | translate}} </p> -->
                </div>
            </div>
    
            <mat-form-field class="inputFullWidth2" appearance="fill">
                <mat-label>{{ 'forms.user.emailInput' | translate}}</mat-label>
                <input id="emailInput" formControlName="email" type="email" matInput required>
            </mat-form-field>
            
            <mat-form-field class="inputFullWidth2" appearance="fill">
                <mat-label>{{ 'forms.user.name' | translate}}</mat-label>
                <input id="nameInput" formControlName="nombre" type="text" matInput required>
            </mat-form-field>
            
            <mat-form-field class="inputFullWidth2" appearance="fill">
                <mat-label>{{ 'forms.user.lastName' | translate}}</mat-label>
                <input id="lastNameInput" formControlName="apellido" type="text" matInput required>
            </mat-form-field>
            
            <mat-form-field class="inputFullWidth2" appearance="fill">
                <mat-label>{{ 'forms.user.secondLastname' | translate}}</mat-label>
                <input id="secondlastNameInput" formControlName="segundoApellido" type="text" matInput>
            </mat-form-field>
            
        </form>
    </mat-dialog-content>
        
        <button color="accent" [disabled]="!studentForm.valid || waiting" mat-raised-button class="buttonDialog" (click)="onSubmit()">{{ 'admin.createStudent.button' | translate }}</button>

        <div *ngIf="!controlCantidad.limiteAlcanzado && !controlCantidad.limiteSuperado" class="flexCenter">
            <mat-icon>
                group
            </mat-icon>
            <p class="body4 hint"> <b> {{controlCantidad.texto}} </b> {{ 'admin.createStudent.limits.hint' | translate }}</p>
        </div>
</div>

<app-loading *ngIf="waiting" [showText]="false"></app-loading>

<div class="dialogContainer450" *ngIf="success">
    <div class="centerText">
        <mat-icon class="logo" svgIcon="done"></mat-icon>
        <p mat-dialog-title class="headline5">{{ 'admin.createStudent.success.title' | translate }}</p>
    </div>
    <p class="subtitle1">{{ 'admin.createStudent.success.text' | translate }}</p>
    <button mat-raised-button color="accent" class="buttonDialog" [mat-dialog-close]="true" cdkFocusInitial >{{ 'admin.createStudent.success.button' | translate }}</button>
</div>