<body>
    <app-header [registration]="true"></app-header>
    <article>
        <div class="welcome">
            <div class="centerDesktop">
                <mat-icon svgIcon="check" class="thanksIcon"></mat-icon>
                <p class="headline5">{{'registration.thanks.title'|translate}}</p>
                <p class="subtitle1">{{'registration.thanks.subtitle'|translate}}</p>
            </div>
        </div>
    </article>
</body>