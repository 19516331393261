import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Documento, Country } from '../../../../api/interfaces/register';
import { Contact, Personal } from '../../../../api/interfaces/user';
import { RegisterService } from '../../../../api/services/register/register.service';
import { SharedDataService } from '../../../../services/sharedData/shared-data.service';

@Component({
  selector: 'app-trainer-data',
  templateUrl: './trainer-data.component.html',
  styleUrls: ['./trainer-data.component.scss']
})
export class TrainerDataComponent implements OnInit {

  disable: boolean = true;

  maxDate: Date = new Date();
  documentos: Documento[] = [];
  countrys: Country[];

  title: string = `{{ 'profile.data.personal' | translate }}`
  @Input('personalData') personalData : Personal;
  @Input('showComplete') showComplete: boolean;
  @Input('contactData') contactData: Contact;

  @Output() onSubmitPersonalEvent = new EventEmitter<any>();


  userForm = new FormGroup({
    nombre: new FormControl({ value: '', disabled: this.disable }),
    apellido: new FormControl({ value: '', disabled: this.disable }),
    segundoApellido: new FormControl({ value: '', disabled: this.disable }),
    numeroDocumento: new FormControl({ value: '', disabled: this.disable }, [Validators.pattern(/^[a-zA-Z0-9]+$/),]),
    documentoTipo: new FormControl({ value: '', disabled: this.disable}),
    fechaNacimiento: new FormControl({ value: '', disabled: this.disable }),
    pais: new FormControl({ value: '', disabled: this.disable }),
    residencia: new FormControl({ value: '', disabled: this.disable }),
    email: new FormControl({ value: '', disabled: this.disable }),
    prefijoCelular: new FormControl({ value: '', disabled: this.disable }),
    numeroCelular: new FormControl({ value: '', disabled: this.disable }),
  });

  constructor(
    private registerService: RegisterService,
    private sharedData: SharedDataService
  ) { }

  ngOnInit(): void {
    this.patch();
    this.sharedData.currentMessage.subscribe(message => {
      if (message === "updateProfileData") {
        this.patch();
      }
    });
    this.getCountry();
    this.getTiposDocumento();
  }

  patch() {
    this.userForm.patchValue({nombre: this.personalData.nombre});
    this.userForm.patchValue({apellido: this.personalData.apellido});
    this.userForm.patchValue({segundoApellido: this.personalData.segundoApellido});
    this.userForm.patchValue({numeroDocumento: this.personalData.numeroDocumento});
    if(this.personalData.documentoTipo) {
      this.userForm.patchValue({documentoTipo: this.personalData.documentoTipo.documento});
    }
    this.userForm.patchValue({fechaNacimiento : this.personalData.fechaNacimiento });
    if(this.personalData.pais) {
      this.userForm.patchValue({pais : this.personalData.pais.pais });
    }
    this.userForm.patchValue({residencia: this.personalData.residencia });

    this.userForm.patchValue({email: this.contactData.email});
    this.userForm.patchValue({numeroCelular: this.contactData.numeroCelular});
    this.userForm.patchValue({numeroEmergencia: this.contactData.numeroEmergencia});
    this.userForm.patchValue({prefijoCelular: this.contactData.prefijoCelular});
    this.userForm.patchValue({prefijoEmergencia: this.contactData.prefijoEmergencia});
  }

  edit() {
    this.disable = !this.disable;
    if(!this.disable) {
      this.userForm.controls['nombre'].enable();
      this.userForm.controls['apellido'].enable();
      this.userForm.controls['segundoApellido'].enable();
      this.userForm.controls['numeroDocumento'].enable();
      this.userForm.controls['documentoTipo'].enable();
      this.userForm.controls['pais'].enable();
      this.userForm.controls['residencia'].enable();

      this.userForm.controls['email'].enable();
      this.userForm.controls['numeroCelular'].enable();
      this.userForm.controls['prefijoCelular'].enable();
     
    } else {
      this.userForm.controls['nombre'].disable();
      this.userForm.controls['apellido'].disable();
      this.userForm.controls['segundoApellido'].disable();
      this.userForm.controls['numeroDocumento'].disable();
      this.userForm.controls['documentoTipo'].disable();
      this.userForm.controls['pais'].disable();
      this.userForm.controls['residencia'].disable();

      this.userForm.controls['email'].disable();
      this.userForm.controls['numeroCelular'].disable();
      this.userForm.controls['prefijoCelular'].disable();
    }
  }

  onSubmit() {
    this.edit();
    const indexDocumento: number = this.documentos.findIndex(documento => documento.documento == this.userForm.value.documentoTipo);
    const indexPais: number = this.countrys.findIndex(country => country.pais == this.userForm.value.pais);

    this.userForm.value.documentoTipo = this.documentos[indexDocumento];
    this.userForm.value.pais = this.countrys[indexPais];
    this.userForm.value.documentoTipoId = this.documentos[indexDocumento].id;    
    this.userForm.value.paisId = this.countrys[indexPais].id;
    
    this.onSubmitPersonalEvent.emit(this.userForm.value);
  }

  getCountry() {
    this.registerService.getCountry()
    .subscribe(response => {
      this.countrys = response;
    },
      error => {
        console.error(error);
      });
  }

  getTiposDocumento() {
    this.registerService.getTiposDocumento()
    .subscribe(response => {
      this.documentos = response;
    },
      error => {
        console.error(error);
      });
  }

  saveCountry(country : Country) {
    this.userForm.patchValue({pais: country});
  }

}
