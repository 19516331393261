<ng-container *ngIf="showComplete">
  <div class="status" *ngIf="status == StatusEnum.PENDING">
    <mat-icon class="statusPendingIcon">error_outline</mat-icon>
    <div class="statusPendingText">
      {{'status.P' | translate}}
    </div>
  </div>

  <div class="status" *ngIf="status == StatusEnum.ACTIVADO">
    <mat-icon class="statusAcceptedIcon">check_circle_outline</mat-icon>
    <div class="statusAcceptedText">
      {{'status.A' | translate}}
    </div>
  </div>

  <div class="status" *ngIf="status == StatusEnum.SUSPENDIDO">
    <mat-icon class="material-icons-outlined">no_accounts</mat-icon>
    <p class="statusCreatedText">
      {{'status.S' | translate}}
    </p>
  </div>

  <div class="status" *ngIf="status == StatusEnum.CREATED">
    <mat-icon class="material-icons-outlined">note_alt_outline</mat-icon>
    <div class="statusCreatedText">
      {{'status.C' | translate}}
    </div>
  </div>

  <div class="status" *ngIf="status == StatusEnum.INACTIVE">
    <div class="statusInactiveText">
      {{'status.I' | translate}}
    </div>
  </div>


  <!-- estados de planes -->
  <div class="status" *ngIf="status == StatusEnum.PROGRAMADO">
    <mat-icon class="statusPendingIcon">update</mat-icon>
    <div class="statusPendingText">
      {{status}}
    </div>
  </div>

  <div class="status" *ngIf="status == StatusEnum.CURSO">
    <mat-icon class="statusAcceptedIcon">check_circle_outline</mat-icon>
    <div class="statusAcceptedText">
      {{status}}
    </div>
  </div>

</ng-container>


<ng-container *ngIf="!showComplete">

  <div *ngIf="status == StatusEnum.ACTIVADO">
    <mat-icon class="statusAcceptedIcon">check_circle_outline</mat-icon>
  </div>
  
  <div *ngIf="status == StatusEnum.CREATED">
    <mat-icon class="material-icons-outlined">note_alt_outline</mat-icon>
  </div>
  
  <div *ngIf="status == StatusEnum.PENDING">
    <mat-icon class="statusPendingIcon">error_outline</mat-icon>
  </div>
  
  <div *ngIf="status == StatusEnum.INACTIVE">
    <mat-icon class="statusAcceptedIcon">check_circle_outline</mat-icon>
  </div>

  <div *ngIf="status == StatusEnum.SUSPENDIDO">
    <mat-icon class="material-icons-outlined">no_accounts</mat-icon>
  </div>
</ng-container>