import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ChangeUserStatus, UserComplete } from '../../interfaces/user';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  private url = environment.apiUrl + '/';
  httpOptionsJson = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }),
    withCredentials: true,
  };


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json'
    }),
    withCredentials: true,
  };

  constructor(private http: HttpClient) { }

  getMyProfile(): Observable<UserComplete>{
    return this.http.get<UserComplete>(this.url + 'api/user/myProfile', this.httpOptions);
  }

  getProfileUserId(id: number): Observable<UserComplete> {
    return this.http.get<UserComplete>(this.url + `api/alumnos/?alumno_id=${id}`, this.httpOptions);
  }

  /**
   * Used to update student data and status
   * @param changeUserStatus - {@link ChangeUserStatus}
   * @param id 
   * @returns 
   */
  changeUserStatus(changeUserStatus: ChangeUserStatus, id: number): Observable<any> {
    return this.http.put(this.url + `api/alumnos/activar/${id}`,changeUserStatus,this.httpOptionsJson);
  }

}
