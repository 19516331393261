<div *ngIf="!isMobile" [class]="class">
    <mat-card *ngIf="showAntecedentes && pasosHabilitados.antecedentes" class="cardProfile">
        <app-background></app-background>
    </mat-card>
    <div *ngIf="showMarcasObjetivos" class="secondColumn">
        <mat-card class="cardProfileRigth">
            <app-achievements-card [alumnoId]="alumnoId" [allAchievements]="allAchievements"></app-achievements-card>
        </mat-card>
        <mat-card class="cardProfileRigth2">
            <app-objetives-card [alumnoId]="alumnoId" [objetives]="objetives"></app-objetives-card>
        </mat-card>
    </div>
</div>

<div *ngIf="isMobile" class="responsiveFlex">
    <mat-card *ngIf="showMarcasObjetivos" class="cardProfile">
        <app-achievements-card [alumnoId]="alumnoId" [allAchievements]="allAchievements"></app-achievements-card>
    </mat-card>
    <mat-card *ngIf="showMarcasObjetivos" class="cardProfile">
        <app-objetives-card [alumnoId]="alumnoId" [objetives]="objetives"></app-objetives-card>
    </mat-card>
    <mat-card *ngIf="showAntecedentes && pasosHabilitados.antecedentes" class="cardProfile">
        <app-background></app-background>
    </mat-card>
</div>