import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FiltratedObjetives, Objetive } from '../../../../api/interfaces/register';
import {DateAdapter} from '@angular/material/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from "@angular/material/icon";
import { MobileDetectorService } from '../../../../services/mobileDetector/mobile-detector.service';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';
import { ActivatedRoute } from '@angular/router';
import { GenericDialogComponent } from '../../../generic-dialog/generic-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RegisterService } from '../../../../api/services/register/register.service';
import { of, Subscription } from 'rxjs';
import { FlagsEnum } from '../../../../enums/sharedDataFlag';
import { HoursDayFixedService } from 'src/app/utils/hoursDayFixed/hours-day-fixed.service';

@Component({
  selector: 'app-inputs',
  templateUrl: './inputs.component.html',
  styleUrls: ['./inputs.component.scss']
})
export class InputsComponent implements OnInit, OnDestroy {

  private sharedDataFlag$: Subscription;

  isRegistration: boolean = false;
  isMobile: boolean = false;
  disable: boolean = true;
  activo: boolean = true;
  lock: boolean = false;

  minDate: Date = new Date();
  objetivesForm = new FormGroup({
    name: new FormControl(),
    date: new FormControl(),
    i: new FormControl(),
    id: new FormControl(),
  });

  selected: Date;

  @Output() removeObjetiveEvent = new EventEmitter<any>();
  @Output() saveObjetiveEvent: EventEmitter<any> = new EventEmitter();
  
  @Input('i') i?: number;
  @Input('objetive') objetive? : FiltratedObjetives;
  @Input('alumnoId') alumnoId?: number;

  constructor(
    private _adapter: DateAdapter<any>,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private mobileDetectorService: MobileDetectorService,
    private sharedData: SharedDataService,
    private router: ActivatedRoute,
    private dialog: MatDialog,
    private registerService: RegisterService,
    private hoursDayFixed: HoursDayFixedService,
  ) {
    this.matIconRegistry.addSvgIcon(
      `delete`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../assets/delete.svg')
    );
   }

   ngOnInit(): void {
    this.objetivesForm.value.i = this.i;
    const url = this.router.snapshot.routeConfig.path;
    if(url == "registration") {
      this.isRegistration = true;
    }

    this.sharedDataFlag$ = this.sharedData.currentFlag.subscribe(flag => {
      if(flag) {
        if(flag.key == FlagsEnum.editObjetives) {
          if(flag.value && this.disable) {
            this.lock = true;
          }
          if(!flag.value) {
            this.lock = false;
          }
        }
      }
    });

    this.isMobile = this.mobileDetectorService.isMobile();
    this.addObjetive();
    this._adapter.setLocale('es');

    if(this.objetive) {
      this.objetivesForm.patchValue({name: this.objetive.objetivoDescripcion});
      this.objetivesForm.patchValue({date: this.objetive.fechaObjetivo});
      this.objetivesForm.patchValue({id: this.objetive.id});
      if(this.objetive.id_objetive) {
        this.i = this.objetive.id_objetive;
      }
      if(this.isRegistration) {
        this.i = this.objetive.id_objetive;
      }
    } else {
      this.objetivesForm.patchValue({id: null});  //requerido por backend
    }

    if(this.disable && !this.isRegistration) {
      this.disableInputs();
    }

     //user must be able to complete the new objetive after is added in objetives.component
     if(this.objetiveNotExistInDB()) {
      this.disable = false;
      this.enableInputs();
    }

  }

  /**
   * Remove from db an existing objetive using id and active false, or remove from frontend a non-saved objetive using only removeObjetiveEvent
   */
  removeObjetive() {
    if(this.isRegistration) {
      this.emitRemoveEventAndChangeFlag();
    } else {
      const dialogRef = this.dialog.open(GenericDialogComponent, {
        data: {
          title: `¿Está seguro que desea eliminar el objetivo?`,
          iconClass: "bigBlueIcon",
          text: "",
          icon: "error_outlined",
          confirm: "ELIMINAR",
          cancel: "NO"
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          const { name, date } = this.objetivesForm.getRawValue();
          
          if(this.objetiveNotExistInDB()) {
            this.emitRemoveEventAndChangeFlag();
          } else {
            const objetive: FiltratedObjetives[] = [{
              objetivoDescripcion: name,
              fechaObjetivo: this.hoursDayFixed.getDateFixed(new Date(date).toISOString()),
              activo: false,
              id: this.objetive.id
            }];
            
            this.registerService.editObjetives(this.alumnoId, objetive)
            // of(25)
            .subscribe(response => {
              this.emitRemoveEventAndChangeFlag();
            },
            error => {
              console.error(error);
            });
            
          }
        }
      });
    }
  }

  emitRemoveEventAndChangeFlag() {
    this.removeObjetiveEvent.emit(this.i);
    this.sharedData.changeFlag(FlagsEnum.editObjetives, false);
  }

  addObjetive() {
    if(this.i && !this.isRegistration) {
      this.objetivesForm.value.i = this.i;
    }
    if(this.isRegistration || !this.objetivesForm.value.i) {
      this.objetivesForm.value.i = this.i;
    }
    if(this.objetivesForm.value.date) {
      let auxDate = this.objetivesForm.value.date;
      if(auxDate._d) {
        auxDate._d.setHours(12);
      }
    }
    this.saveObjetiveEvent.emit(this.objetivesForm.value);
  }

  enableInputs() {
    this.objetivesForm.controls['name'].enable();
    this.objetivesForm.controls['date'].enable();
    if(!this.isRegistration) {
      this.sharedData.changeFlag(FlagsEnum.editObjetives, true);
    }
  }

  disableInputs() {
    this.objetivesForm.controls['name'].disable();
    this.objetivesForm.controls['date'].disable();
    this.sharedData.changeFlag(FlagsEnum.editObjetives, false);
  }

  toggleEdit() {
    this.disable = !this.disable;
    this.disable ? this.disableInputs() : this. enableInputs() ;
  }


  /**
   * If isRegistration only call toggleEdit without saving, because its saved at stepEndComponent
   * Else this create the required object to send to backend and save in db a new achievement, or edit a existing one.
   */
  saveObjetive() {
    const { name, date } = this.objetivesForm.getRawValue();
    const objetive: FiltratedObjetives[] = [{
      objetivoDescripcion: name,
      fechaObjetivo: this.hoursDayFixed.getDateFixed(new Date(date).toISOString()),
      activo: true,
      id: this.objetiveNotExistInDB() ? null : this.objetive.id
    }];
    
    this.registerService.editObjetives(this.alumnoId, objetive)
    .subscribe(response => {
      this.objetive.id = response;
      this.objetive.objetivoDescripcion = objetive[0].objetivoDescripcion;
      this.objetive.fechaObjetivo = objetive[0].fechaObjetivo;
      this.toggleEdit();
    },
    error => {
      console.error(error);
    });
  }

  objetiveNotExistInDB() : boolean {
    return (!this.objetive || (this.objetive && !this.objetive.id));
  }

  public ngOnDestroy(): void {
    this.sharedDataFlag$.unsubscribe();
  }

}