import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

import { EditarGrupo, Group, GroupsPageable, GrupoCompleto, PostGrupo } from '../../interfaces/groups';

@Injectable({
  providedIn: 'root'
})
export class GroupsService {

  private url = environment.apiUrl + '/';

  httpOptionsJson = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }),
    withCredentials: true,
  };

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json'
    }),
    withCredentials: true,
  };

  constructor(private http: HttpClient) { }

  getGrupos(page: number, size: number): Observable<GroupsPageable> {
    return this.http.get<GroupsPageable>(this.url + `api/entrenamientogrupos/listado?page=${page}&size=${size}`,  this.httpOptionsJson);
  }

  postGrupo(grupo: PostGrupo): Observable<any> {
    return this.http.post<any>(this.url + 'api/entrenamientogrupos', grupo, this.httpOptionsJson);
  }

  activarDesactivar(grupoId: number, activo: boolean): Observable<any> {
    return this.http.delete<any>(this.url + `api/entrenamientogrupos?grupoId=${grupoId}&activo=${activo}`, this.httpOptionsJson);
  }

  editar(grupoId: number, grupo: EditarGrupo, enviarMensaje: boolean): Observable<any> {
    return this.http.put<any>(this.url + `api/entrenamientogrupos?grupoId=${grupoId}&enviarMensaje=${enviarMensaje}`, grupo, this.httpOptionsJson);
  }

  getGrupo(grupoId: number): Observable<GrupoCompleto> {
    return this.http.get<GrupoCompleto>(this.url + `api/entrenamientogrupos/get?grupoId=${grupoId}`,  this.httpOptionsJson);
  }
}
