import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ParametrosService } from 'src/app/api/services/parametros/parametros.service';
import { Destinatarios, EnviarMensaje, LevelMassive, MassiveMessage, Mensaje, TrainingDaysMassive } from '../../../../api/interfaces/messages';
import { DateAdapter} from '@angular/material/core';
import { SnackbarService } from 'src/app/utils/snackbar/snackbar.service';
import { MessagesService } from 'src/app/api/services/messages/messages.service';
import { RegisterService } from 'src/app/api/services/register/register.service';
import { TrainingService } from 'src/app/api/services/training/training.service';
import { TrainingDays } from 'src/app/api/interfaces/register';
import { Level, TType } from 'src/app/api/interfaces/user';
import { NgModel } from '@angular/forms';
import { TrainingGroupEnum } from 'src/app/enums/training-type';

@Component({
  selector: 'app-new-massive',
  templateUrl: './new-massive.component.html',
  styleUrls: ['./new-massive.component.scss']
})
export class NewMassiveComponent implements OnInit {

  newForm = new FormGroup({
    asunto: new FormControl(),
    mensaje: new FormControl('',[ 
      Validators.required,
      Validators.pattern(/^(?!\s*$).+/),]),
    vacaciones: new FormControl(false),
    fechaDesde: new FormControl(),
    fechaHasta: new FormControl(),
  });

  levels;
  traningDays;
  type;

  @Output() newMessageEvent = new EventEmitter<EnviarMensaje>();
  @Output() closeEvent = new EventEmitter<any>();
  @Input('allLevels') allLevels: Level[] = [];

  gid: string = "gid=";
  nid: string = "nid=";
  tid: string = "tid=";
  longitudMensaje: number = 0;

  enabledTrainingDaysSelect: boolean = false;
  enabled: boolean = false;
  indexOnline: number = -1;
  
  allTrainingDays: TrainingDays[] = [];
  allTypes: TType[] = [];

  constructor(
    private _adapter: DateAdapter<any>,
    private snack: SnackbarService,
    private messagesService: MessagesService,
    private registerService: RegisterService,
    private trainingService: TrainingService
    ) { }

  ngOnInit(): void {
    this._adapter.setLocale('es');
    this.newForm.statusChanges
    .subscribe(val => {
      this.checkDisabled();
    });

    this.longitudMensaje = parseInt(sessionStorage.getItem('longitudMensaje'),10);
    this.getTrainingDays();
    this.getTrainingTypes();
  }

  onChangeType() {
    if(this.type && this.type.length > 0) {
      this.enabledTrainingDaysSelect = this.type.some( element => element.entrenamientoTipoId == 1);
      if(!this.enabledTrainingDaysSelect) {
        this.removeTrainingDays();
      }
    } else {
      this.removeTrainingDays();
    }
    this.checkDisabled();
  }

  getTrainingTypes() {
    this.registerService.getTrainingTypes()
    .subscribe(response => {
      this.allTypes= response;
    },
      error => {
        console.error(error);
      });
  }

  removeTrainingDays() {
    this.enabledTrainingDaysSelect = false;
    this.traningDays = [];
    this.gid = "gid=";
  }

  onSubmit() {
    const { asunto, mensaje} = this.newForm.getRawValue();
    if(mensaje.trim().length > 0 ) {
      let nidArray: number[] = [];
      let gidArray: number[] = [];
      let tidArray: number[] = [];

      if(this.traningDays && this.traningDays.length > 0) {
        this.traningDays.forEach(element => {
          gidArray.push(element.entrenamientoGrupoId);
        });
      }
  
      if(this.levels && this.levels.length > 0) {
        this.levels.forEach(element => {
          nidArray.push(element.id);
        });
      } 
  
      if(this.type && this.type.length > 0) {
        this.type.forEach(element => {
          tidArray.push(element.entrenamientoTipoId);
        });
      }
      let metadata: Destinatarios = {
        nid: nidArray,
        gid: gidArray,
        tid: tidArray,
      }

      const metadataString: string = JSON.stringify(metadata);

      // const newMassive: MassiveMessage = {
        //   fecha: new Date(),
        // asunto: asunto,
        //   mensaje: mensaje,
        // etiquetas: this.selectedEtiquetas,
        // vacaciones: this.newForm.value.vacaciones
        // };
        const newMassive: EnviarMensaje = {
          mensaje: mensaje,
          mensajeTipo: "BASE",
          titulo: asunto,
          metadata: metadataString
        }
        // this.messagesService.sendMasivoSinFiltros(newMassive).subscribe(response => {
        //   this.newMessageEvent.emit(response);
        // }, error => {
        //   console.error(error);
        //   this.snack.snackTranslate('snacks.error.generic');
        // });

        this.messagesService.sendMasivoFiltrosDinamicos(newMassive,this.nid,this.gid, this.tid).subscribe(response => {
          this.snack.snackTranslate('snacks.sucess.messages.feedbackReply');
          this.newMessageEvent.emit(response);
        }, error => {
          console.error(error);
          this.snack.openSnackBar(error.error.message);
        });
      }
    
  }

  checkDisabled() {
    // let etiquetasValid: boolean = false;
    //   if( this.selectedEtiquetas.length > 0 && ((
    //       this.newForm.value.vacaciones && 
    //       this.newForm.value.fechaDesde && 
    //       this.newForm.value.fechaHasta) ||
    //       !this.newForm.value.vacaciones)
    //   ) {
    //       etiquetasValid = true;
    //   } else {
    //     etiquetasValid = false;
    //   }

    //   this.enabled = this.newForm.valid && etiquetasValid;
    //   return this.newForm.valid && etiquetasValid
    // return this.newForm.value.mensaje.length() > 0 ? true : false;;

    let trainingDaysSelected: boolean = false;
    let levelSelected: boolean = false;
    let typeSelected: boolean = true;
    let presencialSelected: boolean = false;
    let onlineSelected: boolean = false;

    if(this.type && this.type.length > 0) {
      presencialSelected = this.type.some(element => element.entrenamientoTipoId == 1);
      onlineSelected = this.type.some(element => element.entrenamientoTipoId == 2);
      typeSelected = true;
      this.tid = "";
      this.type.forEach(element => {
        const aux: string = `&tid=${element.entrenamientoTipoId}`
        this.tid += aux;
      });
      this.tid = this.tid.substring(1);
    } else {
      this.tid = "tid=";
    }
    
    if(this.traningDays && this.traningDays.length > 0) {
      trainingDaysSelected = true;
      this.gid = "";
      this.traningDays.forEach(element => {
        const aux: string = `&gid=${element.entrenamientoGrupoId}`
        this.gid += aux;
      });
      this.gid = this.gid.substring(1);
    } else {
      this.gid = "gid=";
    }

    if(this.levels && this.levels.length > 0) {
      levelSelected = true;
      this.nid = "";
      this.levels.forEach(element => {
        const aux: string = `&nid=${element.id}`
        this.nid += aux;
      });
      this.nid = this.nid.substring(1);
    } else {
      this.nid = "nid=";
    }

    let gruposValid: boolean = false;
    const onlineOnly: boolean = (onlineSelected && !presencialSelected);
    const onlineAndPresencial: boolean = (onlineSelected &&  presencialSelected && trainingDaysSelected);

    if( onlineOnly || 
        (presencialSelected && trainingDaysSelected) || 
        (onlineAndPresencial)
      ) {
      gruposValid = true;
    }

    //gid ya esta construido, falta agregar online si corresponde
    if(onlineOnly && this.indexOnline != -1) {
      this.gid = "gid=" + this.indexOnline;
    }
    if(onlineAndPresencial && this.indexOnline != -1) {
      this.gid += "&gid=" + this.indexOnline;
    }
    this.enabled  = this.newForm.valid && levelSelected && gruposValid;
  }

  // toggleHolidays() {
  //   this.newForm.patchValue({vacaciones: !this.newForm.value.vacaciones});
  // }

  close() {
    this.closeEvent.emit();
  }

  getTrainingDays () {
    this.registerService.getTrainingDays()
    .subscribe(response => {
      this.allTrainingDays = response;
      const NOT_FOUND: number = -1;
      //remover no definido y online, guardar el id de online
      let index = this.allTrainingDays.findIndex(element => element.grupoDescripcion == TrainingGroupEnum.noDefinido);
      if(index != NOT_FOUND) {
        this.allTrainingDays.splice(index,1);
      }
      index = this.allTrainingDays.findIndex(element => element.grupoDescripcion == TrainingGroupEnum.online);
      if(index != NOT_FOUND) {
        this.indexOnline = this.allTrainingDays[index].entrenamientoGrupoId;
        this.allTrainingDays.splice(index,1);
      }
    },
      error => {
        console.error(error);
      });
  }
  
  selectAll(select: NgModel, values) {
    select.update.emit(values); 
    this.checkDisabled();
  }

  deselectAll(select: NgModel) {
    select.update.emit([]); 
    this.checkDisabled();
  }
}