import { Component, OnInit, Input } from '@angular/core';
import { NavbarButton } from '../../../api/interfaces/login';
import { ActivatedRoute, Router } from '@angular/router';
import { MobileDetectorService } from '../../../services/mobileDetector/mobile-detector.service';
import { MessagesService } from '../../../api/services/messages/messages.service';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';


/**
 * Create buttons for navbar, with 2 class and new messages warning.
 * @param option NavbarButton
 */
@Component({
  selector: 'app-navbar-button',
  templateUrl: './navbar-button.component.html',
  styleUrls: ['./navbar-button.component.scss']
})
export class NavbarButtonComponent implements OnInit {

  @Input('option') option!: NavbarButton;
  @Input('showCircle') showCircle: boolean;
  
  iconClass: string = "navbarIcon";
  textClass: string = "menuText";
  
  isMobile: boolean = false;
  
  navbarClass: string = "";
  active: string = "";
  
  constructor(
    private router: Router,
    private mobileDetectorService: MobileDetectorService,
    private messagesService: MessagesService,
    private sharedData: SharedDataService,
    private activatedRouter: ActivatedRoute,
  ) {  }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    this.active = sessionStorage.getItem('active') as string;
    this.setClass();
    this.setCircle();

      this.sharedData.currentMessage.subscribe(message => {
        if (message === "ultimoMensajeAdmin" || message === "ultimoMensaje") {
          this.setCircle();
        }
      });
  }

  navigate(path: string) {
    //ticket 2329: reload page if user clicks in current page icon
    const url = this.activatedRouter.snapshot.routeConfig.path;
    if(url != path) {
    this.router.navigate([path]);
    } else {
      window.location.reload();
    }
  }
  
  setClass() {
    if(this.option.path) {
      this.navbarClass = "navbarButton";
      if(this.option.path.toLowerCase().includes(this.active.toLowerCase())) {
        this.iconClass = "navbarIconActive";
        this.textClass = "menuTextActive";
      }
    } else {
      this.navbarClass = "navbarButtonDisabled";
    }
  }

  setCircle() {
    if(!this.option.title.toLocaleLowerCase().includes("mensajes") ) { 
      this.showCircle = false;
    }
  }

}
