<form [formGroup]="optionsForm">
    <p class="subtitle1 textLeft">{{pregunta.titulo}} <span *ngIf="requerido"> *</span></p>
    <mat-form-field appearance="fill" class="inputfullWidth">
        <mat-label>{{pregunta.placeholder}}</mat-label>
        <input 
            maxLength={{cantCaracteres}}
            aria-labelledby="textInput" 
            id="textInput" 
            formControlName="valor" 
            type="text" 
            matInput 
            [required]="requerido"
            [mask]="pregunta.mask">

            <mat-hint align="end">{{optionsForm.value.valor?.length || 0}} / {{cantCaracteres}}</mat-hint>
            <mat-hint *ngIf="pregunta.textoAsistivo">{{pregunta.textoAsistivo}}</mat-hint>
    </mat-form-field>
    

</form>