import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { MessagesTypes } from 'src/app/enums/messagesTypes';
import { RolService } from 'src/app/services/rol/rol.service';
import { Mensaje, Message, MetadataAvisoFeedback } from '../../../../api/interfaces/messages';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit {

  @Input('message') message:  Mensaje;
  @Input('autofocus') autofocus: boolean;

  @ViewChild('lastChat') lastChat;

  class: string = "incomingMessage";
  classFeedback: string = "";

  feedback: string = "";
  fechaFeedback: string = "";
  feedbackOpcion: number = 0;
  circleClass: string = "circleMessages ";

  isAdmin: boolean = false;
  isStudent: boolean = false;

  constructor(
    private rolService: RolService
  ) { }

  ngOnInit(): void {
    this.isAdmin = this.rolService.isAdmin();
    this.isStudent = this.rolService.isStudent();
    this.configureClassAndValues();
  }

  configureClassAndValues() {


    if(this.message.remitenteId == parseInt(localStorage.getItem('idUsuario'),10)) {
      this.class = "outgoingMessage";
    } else {
      //required to get user profile
      if(this.message.remitenteId) {
        sessionStorage.setItem('id_usuario',this.message.remitenteId.toString());
      }
    }
    
    if(this.message.mensajeTipo == "FEEDBACK") {
      const aux: string [] = this.message.metadata.split("¬");
      this.fechaFeedback = aux[0];
      this.feedback = aux[1];
      if(this.isStudent) {
        this.classFeedback = "feedbackIncomingMessage";
      } else {
        this.classFeedback = "feedbackOutgoingMessage";
      }
    }

    if(this.message.mensajeTipo == MessagesTypes.AVISO) {
      const metadataJson: MetadataAvisoFeedback = JSON.parse(this.message.metadata);
      this.fechaFeedback = metadataJson.fechaFeedback;
      this.feedbackOpcion = metadataJson.feedbackOpcion;
      if(this.isStudent) {
        this.classFeedback = "feedbackOutgoingMessage";
      } else {
        this.classFeedback = "feedbackIncomingMessage";
      }
      switch(this.feedbackOpcion) {
        case 1: this.circleClass += "mal"; break;
        case 2: this.circleClass += "regular"; break;
        case 3: this.circleClass += "bien"; break;
        case 4: this.circleClass += "fenomenal"; break;
        default: break;
      }
    }
  }
  
  ngAfterViewInit(){
    if(this.autofocus && this.lastChat) {
      this.lastChat.nativeElement.focus();
    }
  } 

}
