import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TrainingDays } from '../../../api/interfaces/register';
import { EntrenamientoModelBroken, Training } from '../../../api/interfaces/user';
import { TrainingTypeEnum } from '../../../enums/training-type';

@Component({
  selector: 'app-training-type',
  templateUrl: './training-type.component.html',
  styleUrls: ['./training-type.component.scss']
})
export class TrainingTypeComponent implements OnInit {

  @Input('training') training: EntrenamientoModelBroken;
  @Input('edit') edit: boolean;
  @Input('groups') groups?: TrainingDays[];
  @Input('isAdmin') isAdmin?: boolean;
  @Input('userForm') userForm?: any;
  @Input('status') status: string;

  @Output() saveGroupEvent = new EventEmitter<TrainingDays>();

  TrainingTypeEnum = TrainingTypeEnum;
  constructor() { }

  ngOnInit(): void {
  }

  saveGroup(group: TrainingDays) {
    this.saveGroupEvent.emit(group);
  }

}
