import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DomiciliacionService } from '../../../../api/services/domiciliacion/domiciliacion.service';
import { SnackbarService } from '../../../../utils/snackbar/snackbar.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from "@angular/material/icon";
import { MobileDetectorService } from '../../../../services/mobileDetector/mobile-detector.service';
import { SharedDataService } from '../../../../services/sharedData/shared-data.service';
import { Emisor, MonthTariff, StudentTariffData } from '../../../../api/interfaces/domiciliacion';
import { MatDialog } from '@angular/material/dialog';
import { PopupArancelComponent } from '../popup-arancel/popup-arancel.component';
import { saveAs } from 'file-saver';
import { DownloadService } from 'src/app/api/services/download/download.service';
import { EmisorDialogComponent } from '../emisor-dialog/emisor-dialog.component';
import { MessageDomiciliacionComponent } from '../message-domiciliacion/message-domiciliacion.component';


/**
 * Display the students data for one billing period:
 * name, lastname, training type, register date, $ import, status.
 * Filters: name, export, selected, type.
 * Can edit the $import for every student.
 * Can export xml file
 * Can navigate to MainTableDomiciliacionComponent
 */
@Component({
  selector: 'app-month-table-domiciliacion',
  templateUrl: './month-table-domiciliacion.component.html',
  styleUrls: ['./month-table-domiciliacion.component.scss']
})
export class MonthTableDomiciliacionComponent implements OnInit {

  totalElements: number = 0;
  totalPages: number = 0;
  size: number = 10;
  page: number = 0;

  first: boolean = true;
  last: boolean = false;
  done: boolean = false;

  isMobile: boolean = false;
  noData: boolean = false;

  ape: string = "";
  status: string = "";
  tipoId: string = "";

  selectedMonth: MonthTariff = null;

  @Output() changeTableEvent = new EventEmitter<MonthTariff>();

  filters = {
    nombreApellido: "",
    estado: "",
    entrenamientoTipoId: "",
    tipo: ""
  };


  displayedColumns: string[] = ['name','trainingGroup','date','tariff','status','actions'];
  displayedColumnsMobile: string[] = ['name','tariff','status','actions'];
  domiciliacionData: StudentTariffData[];
  loaded: boolean = false;

  constructor(
    private domiciliacionService: DomiciliacionService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private mobileDetectorService: MobileDetectorService,
    private sharedData: SharedDataService,
    public dialog: MatDialog,
    private snack: SnackbarService,
    private download: DownloadService
  ) {
    this.matIconRegistry.addSvgIcon(
      `delete`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../assets/delete.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `xml`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../assets/xml.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `xml_blue`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../assets/xml_blue.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `xml_disable`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../assets/xml_disable.svg')
    );
  }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    this.selectedMonth = JSON.parse(sessionStorage.getItem('selectedMonth'));
    this.loadData();
    this.sharedData.currentMessage.subscribe(message => {
      if (message === "reloadData") {
        this.loadData();
      }
    });

  }

  loadMore(event) {
    this.page = event;
    this.loadData();
  }

  loadData() {
    this.domiciliacionData = [];
    this.noData = false;
    this.loaded = false;

    this.domiciliacionService.getDomiciliacionFiltrable(this.selectedMonth.id, this.filters.nombreApellido, this.filters.estado, this.filters.tipo ,this.page, this.size).subscribe( response => {
      this.totalPages = response.totalPages;
      this.first = response.first;
      this.last = response.last;
      this.done = true;
      this.totalElements = response.totalElements;
      if (response.content.length == 0) {
        this.noData = true;
        this.loaded = true;
      } else {
        response.content.forEach(student => {
          if (student != null) {
            student.selected = false;
            this.domiciliacionData.push(student);
          }
        });
        this.loaded = true;
      }
    },
    error => {
      console.error(error);
    });
  }

  edit(element: MonthTariff) {
    sessionStorage.setItem('id_usuario', JSON.stringify(element.id));
    this.changeTableEvent.emit(element);
  }

  navigate() {
    sessionStorage.removeItem('selectedMonth');
    this.changeTableEvent.emit(null);
  }

  export() {

    // const dialogRef = this.dialog.open(EmisorDialogComponent, {
    // });

    // dialogRef.afterClosed().subscribe(result => {
      // let emisor: Emisor = JSON.parse(sessionStorage.getItem('emisor'));
      // if (emisor) {
        // this.download.exportarFiltrable(this.selectedMonth.id, this.filters.nombreApellido, this.filters.estado, this.filters.tipo, 1).subscribe( response => {
        //   const nombre : string = `DOM ${this.selectedMonth.date}.xml`;
        //   this.snack.openSnackBar(`Se cobraron ${this.totalElements} alumnos`);
        //   // saveAs(response, nombre);
        //   this.loadData();
        //   sessionStorage.removeItem('emisor');
        // },
        // error => {
        //   console.error(error);
        //   this.snack.openSnackBar(error.error.message);
        // });
      // }
    // });

    //domiciliacionId: number, ape: string, status: string, tipoId: string

    this.domiciliacionService.cobrarFiltrable(this.selectedMonth.id, this.filters.nombreApellido, this.filters.estado, this.filters.tipo).subscribe( response => {
      this.totalElements > 1 ? this.snack.snackTranslate('admin.domiciliacion.monthTable.studentsPay') : this.snack.snackTranslate('admin.domiciliacion.monthTable.studentPay');
      this.loadData();
    },
    error => {
      console.error(error);
      this.snack.openSnackBar(error.error.message);
    });
   
  }

  openDialogMessage() {
    this.dialog.open(MessageDomiciliacionComponent, {
      data: {
        domiciliacionId: this.selectedMonth.id
      }
    });
  }

  // exportElement(element: StudentTariffData) {

    // const dialogRef = this.dialog.open(EmisorDialogComponent, {
    // });

    // dialogRef.afterClosed().subscribe(result => {
      // let emisor: Emisor = JSON.parse(sessionStorage.getItem('emisor'));
      // if (emisor) {
        // this.download.exportarId(element.id, element.alumnoId, element.arancel, 1).subscribe( response => {
        //   const nombre : string = `DOM ${element.nombre} ${element.apellido} ${this.selectedMonth.date}.xml`;
        //   // saveAs(response, nombre);
        //   this.loadData();
        //   sessionStorage.removeItem('emisor');
        // },
        // error => {
        //   console.error(error);
        //   this.snack.openSnackBar(error.message);
        // });
      // }
    // });
  // }

  cobrarAlumno(element: StudentTariffData) {
    this.domiciliacionService.cobrarAlumno(element.id, element.alumnoId).subscribe( response => {
      this.loadData();
      element.estado == 'N' ? this.snack.snackTranslate('admin.domiciliacion.monthTable.studentPay') : this.snack.snackTranslate('admin.domiciliacion.monthTable.studentRevert');
    },
    error => {
      console.error(error);
      this.snack.openSnackBar(error.message);
    });
  }

  changeFromCheckbox(element: StudentTariffData ) {
    const index: number = this.domiciliacionData.findIndex(aux => aux.id == element.id);
    this.domiciliacionData[index].selected = !this.domiciliacionData[index].selected;
  }

  setFilters(filters) {
    if(filters) {
      this.filters.nombreApellido = filters.nombreApellido ? filters.nombreApellido : "";
      this.filters.estado = filters.status ? filters.status : "";
      this.filters.tipo = filters.type ? filters.type : "";
      //validacion para mostrar texto correcto si no hay alumnos con los filtros seleccionados
      // if(filters.nombreApellido || filters.status || filters.level || filters.trainer || filters.type || filters.leido ) {
      //   this.firstLoad = false;
      // }
    }
  }

  filtersChange(filters) {
    this.page = 0;
    this.setFilters(filters);
    // localStorage.setItem('filters',JSON.stringify(filters));
    this.loadData();
  }

  editarArancel(element: StudentTariffData ) {
    // const index: number = this.domiciliacionData.findIndex(aux => aux.id == element.id);
    this.dialog.open(PopupArancelComponent, {
      data: element
    });
  }

}
