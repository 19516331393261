import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MobileDetectorService } from 'src/app/services/mobileDetector/mobile-detector.service';
import { RolService } from 'src/app/services/rol/rol.service';

@Component({
  selector: 'app-admin-feedback',
  templateUrl: './admin-feedback.component.html',
  styleUrls: ['./admin-feedback.component.scss']
})
export class AdminFeedbackComponent implements OnInit {

  isMobile: boolean = false;
  isAdmin: boolean = false;
  fechaFeedback: string = ""
  alumnoId: number = 0;

  constructor(
    private mobileDetectorService: MobileDetectorService,
    private router: Router,
    private rolService: RolService
  ) { }


  ngOnInit(): void {

    this.isAdmin = this.rolService.isAdmin();
    this.isMobile = this.mobileDetectorService.isMobile();
    sessionStorage.setItem('active','alumnos');
    this.alumnoId = parseInt(sessionStorage.getItem('id_usuario'),10);
  }

  navigate() {
    this.isAdmin ?  this.router.navigate(['/admin/alumnos']) :  this.router.navigate(['/entrenador/alumnos']);
  }

}
