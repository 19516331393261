import { Component, OnInit } from '@angular/core';
import { Day, EntrenamientoDia } from 'src/app/api/interfaces/register';
import { CambioDiaEntrenamiento } from 'src/app/api/interfaces/user';
import { PlansService } from 'src/app/api/services/plans/plans.service';
import { trainingDaysEnum } from 'src/app/enums/training-type';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';
import { SnackbarService } from 'src/app/utils/snackbar/snackbar.service';
import { MatDialog } from '@angular/material/dialog';
import { WeekService } from 'src/app/utils/week/week.service';
import { UserService } from 'src/app/api/services/user/user.service';

@Component({
  selector: 'app-change-days-dialog',
  templateUrl: './change-days-dialog.component.html',
  styleUrls: ['./change-days-dialog.component.scss']
})
export class ChangeDaysDialogComponent implements OnInit {

  entrenamientoDia: EntrenamientoDia[] = [];
  loaded: boolean = false;

  alumnoId: number = 0;

  valid: boolean = false;

  fecha: string = "";

  days: Day[] = [
    {name:"L", selected: false, index: 2, disabled: false},
    {name:"M", selected: false, index: 3, disabled: false},
    {name:"M", selected: false, index: 4, disabled: false},
    {name:"J", selected: false, index: 5, disabled: false},
    {name:"V", selected: false, index: 6, disabled: false},
    {name:"S", selected: false, index: 7, disabled: false},
    {name:"D", selected: false, index: 1, disabled: false},
  ];
  
  constructor(
    private plansService: PlansService,
    private snack: SnackbarService,
    private sharedData: SharedDataService,
    private dialogRef: MatDialog,
    private weekService: WeekService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    let aux = new Date();
    let date = aux.getDate() + 7;
    aux.setDate(date);
    let semana2 = this.weekService.getWeek(aux);
    this.fecha = " " + this.weekService.convertDate(semana2[0]);

    this.alumnoId = parseInt(sessionStorage.getItem('id_usuario'),10);
    this.userService.getProfileUserId(this.alumnoId).subscribe(response => {
      this.entrenamientoDia = response.entrenamientoDia;
        this.entrenamientoDia.forEach(dia => {

          if(dia.entrenamientoDiaTipo == trainingDaysEnum.IN_GROUP) {
            const aux: Day = this.days.find(day => day.index == dia.diaSemana);
            aux.disabled = true;
          }
          if(dia.entrenamientoDiaTipo == trainingDaysEnum.ADITIONAL) {
            const aux: Day = this.days.find(day => day.index == dia.diaSemana);
            aux.selected = true;
          }
          this.loaded = true;
          
      });
    },
    error => {
      this.loaded = true;
      console.error(error);
      this.snack.snackTranslate('snacks.error.generic');
    })
  }

  onSubmit() {
    this.loaded = false;
    let diasFinal: CambioDiaEntrenamiento[] = [];
    this.days.forEach(day => {
      const aux : CambioDiaEntrenamiento = {
        semanaDia: day.index,
        nuevoDia: day.selected
      }
      if(!day.disabled) {
        diasFinal.push(aux);
      }
    });
    this.plansService.changeDays(this.alumnoId, diasFinal)
      .subscribe(response => {
        this.snack.snackTranslate('snacks.sucess.plan.changeDays');
        this.sharedData.changeMessage("reloadWeek");
        this.loaded = true;
        this.dialogRef.closeAll();
      },
      error => {
        this.loaded = true;
        console.error(error);
        this.snack.openSnackBar(error.error.message);
      });
  }

  
  saveDay(day: Day) {
    const position: number = this.days.findIndex(element => element.index == day.index);
    this.days[position].selected = !day.selected;
  }

}
