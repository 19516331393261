<app-title 
    [bank]="true" 
    [disable]="disable" 
    [valid]="bankForm.valid" 
    [estado]="estado"
    (onSubmitEvent)='onSubmit()' 
    (editEvent)='edit()'>
</app-title>

<form [formGroup]="bankForm">
    <!-- <mat-form-field class="inputTwoLine" appearance="fill">
        <mat-label>{{ 'forms.bank.entityInput' | translate}}</mat-label>
        <input  required  [disabled]="disable" id="entityInput" formControlName="entidad"
            type="text" matInput>
    </mat-form-field>

    <mat-form-field class="inputTwoLine" appearance="fill">
        <mat-label>{{ 'forms.bank.codeInput' | translate}}</mat-label>
        <input [disabled]="disable"
            placeholder="{{ 'forms.bank.codePlaceholder' | translate}}" maxlength = "24" id="claveBancariaInput"
            formControlName="claveBancaria" type="text" matInput required>
            <mat-hint align="start"><strong> {{ 'forms.bank.hint1' | translate}} </strong> </mat-hint>
        <mat-hint align="end">{{bankForm.value.claveBancaria?.length || 0}} / 24</mat-hint>
    </mat-form-field> 

    <br/>-->

    
    <mat-form-field class="inputTwoLine" appearance="fill">
        <mat-label>{{ 'forms.bank.amount' | translate}}</mat-label>
        <input [disabled]="disable  || !isAdmin"
            placeholder="{{ 'forms.bank.amount' | translate}}" id="montoInput"
            formControlName="arancel" type="number" matInput required>
    </mat-form-field>

    <div class="flexLeft alignCenter">

        <mat-icon class="mediumIcon">
            help
        </mat-icon>
        <span>
            <span class="body3Ligth"> {{'settings.arancel.arancelCard.link1' | translate}}</span>
            <span class="body3" (click)="navigateConfigAranceles()">{{'settings.arancel.arancelCard.link2' | translate}}</span> 
        </span>
    </div>
</form>