import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { SharedDataService } from '../../../services/sharedData/shared-data.service';
import { MatStepper } from '@angular/material/stepper'
import { MobileDetectorService } from '../../../services/mobileDetector/mobile-detector.service';
import { of, Subscription } from 'rxjs';
import { StepperEnum } from 'src/app/enums/stepper';
import { RegisterService } from 'src/app/api/services/register/register.service';
import { PasosHabilitados } from 'src/app/api/interfaces/register';
import { ParametrosService } from 'src/app/api/services/parametros/parametros.service';



@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit, OnDestroy {

  isLinear: boolean = true;
  isMobile: boolean = false;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  firstTimeStepEnd: boolean = true;
  objetivesAchievementsFormGroup: FormGroup;

  pasosHabilitados: PasosHabilitados;

  loaded: boolean = false;

  @ViewChild('stepper') private myStepper: MatStepper;

  private sharedData$: Subscription;

  cantidadPasosHabilitados: number = 2;
  posicionGrupoEntrenamiento: number = 2;
  posicionAntecedentes: number = 2;
  posicionMarcasObjetivos: number = 2;
  posicionBancario: number = 2;
  posicionEnd: number = 2;

  constructor(
    private _formBuilder: FormBuilder,
    private sharedData: SharedDataService,
    private mobileDetectorService: MobileDetectorService,
    private registerService: RegisterService,
    private parametrosService: ParametrosService
    ) { }

  ngOnInit(): void {
    let foundMode = sessionStorage.getItem('mode');
    this.sharedData.changeMessage("");
    if(foundMode) {
      sessionStorage.clear();
      window.location.reload();
    }
    this.isMobile = this.mobileDetectorService.isMobile();
    this.getPasosHabilitados();

    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['',Validators.required]
    });

    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required]
    });

    this.thirdFormGroup = this._formBuilder.group({
      thirdCtrl: ['', Validators.required]
    });

    this.fourthFormGroup = this._formBuilder.group({
      fourthCtrl: ['', Validators.required]
    });

    this.objetivesAchievementsFormGroup = this._formBuilder.group({
      objetivesAchievementsCtrl: ['', Validators.required]
    });
   

    this.sharedData$ = this.sharedData.currentMessage.subscribe(message => {

      if(message === StepperEnum.SAVE_STEP1 ) {
        this.saveStep('firstCtrl');
      }
      if(message==="step1valid") {
        this.removeValidators('firstCtrl');
      }
      if(message==="step1invalid") {
        this.addValidators('firstCtrl');
      }


      if(message === StepperEnum.SAVE_STEP2) {
        this.saveStep('secondCtrl');
      }
      if(message==="step2valid") {
        this.removeValidators('secondCtrl');
      }
      if(message==="step2invalid") {
        this.addValidators('secondCtrl');
      }

      if(message === StepperEnum.SAVE_STEP3) {
        this.saveStep('thirdCtrl');
      }
      if(message==="step3valid") {
        this.removeValidators('thirdCtrl');
      }
      if(message==="step3invalid") {
        this.addValidators('thirdCtrl');
      }

      if(message === StepperEnum.SAVE_STEP4) {
        this.saveStep('fourthCtrl');
      }
      if(message==="step4valid") {
        this.removeValidators('fourthCtrl');
      }
      if(message==="step4invalid") {
        this.addValidators('fourthCtrl');
      }

      if(message === StepperEnum.SAVE_STEP_OBJ_ACH) {
        this.saveStep('objetivesAchievementsCtrl');
      }
      if(message === StepperEnum.OBJ_VALID || message === StepperEnum.ACH_VALID) {
        this.removeValidators('objetivesAchievementsCtrl');
      }
      if(message === StepperEnum.OBJ_INVALID || message === "objetivesinvalid" || message === StepperEnum.ACH_INVALID) {
        this.addValidators('objetivesAchievementsCtrl');
      }
      
    });
  }

  /**
   * Calls remove validators and move to the next step.
   * @param form - string, form's name.
   */
  saveStep(form: string) {
    this.removeValidators(form);
    this.myStepper.next();
  }

  addValidators(form: string) {
    if(form==='firstCtrl') {
      this.firstFormGroup.get(form).addValidators(Validators.required);
      this.firstFormGroup.get(form).updateValueAndValidity();
    }
    if(form==='secondCtrl') {
      this.secondFormGroup.get(form).addValidators(Validators.required);
      this.secondFormGroup.get(form).updateValueAndValidity();
    }
    if(form==='thirdCtrl') {
      this.thirdFormGroup.get(form).addValidators(Validators.required);
      this.thirdFormGroup.get(form).updateValueAndValidity();
    }
    if(form==='fourthCtrl') {
      this.fourthFormGroup.get(form).addValidators(Validators.required);
      this.fourthFormGroup.get(form).updateValueAndValidity();
    }
    if(form==='objetivesAchievementsCtrl') {
      this.objetivesAchievementsFormGroup.get(form).addValidators(Validators.required);
      this.objetivesAchievementsFormGroup.get(form).updateValueAndValidity();
    }
  }

  /**
   * Remove validator required and update value and validity for the form received as param
   * @param form - string, form's name to update
   */
  removeValidators(form: string) {

    if(form==='firstCtrl') {
      this.firstFormGroup.get(form).clearValidators();
      this.firstFormGroup.get(form).updateValueAndValidity();
    }
    if(form==='secondCtrl') {
      this.secondFormGroup.get(form).clearValidators();
      this.secondFormGroup.get(form).updateValueAndValidity();
    }
    if(form==='thirdCtrl') {
      this.thirdFormGroup.get(form).clearValidators();
      this.thirdFormGroup.get(form).updateValueAndValidity();
    }
    if(form==='fourthCtrl') {
      this.fourthFormGroup.get(form).clearValidators();
      this.fourthFormGroup.get(form).updateValueAndValidity();
    }
    if(form==='objetivesAchievementsCtrl') {
      this.objetivesAchievementsFormGroup.get(form).clearValidators();
      this.objetivesAchievementsFormGroup.get(form).updateValueAndValidity();
    }
  }

  async onSelectionChange(event) {
    await this.sleep(50);
    this.sharedData.changeMessage(StepperEnum.SAVE_STEP);
    
    this.checkUpdateRequired(event);
  }

  /**
   * Ticket 3349 if user is in the stepEnd and is not the first time in that step, trigger update all data in  ButtonsComponent
   * Required only in desktop.
   *
   * @param event - StepperSelectionEvent 
   */
  checkUpdateRequired(event) {
    if(!this.isMobile) {
      const selectedEndStep: number = this.posicionEnd - 1;
      const userIsInStepEnd: boolean = event.selectedIndex == selectedEndStep;

      if(userIsInStepEnd) {
        //prevent trigger update data if its the first time in stepEnd.
        if(!this.firstTimeStepEnd) {
          this.firstTimeStepEnd = false;
          this.sharedData.changeMessage(StepperEnum.SAVE_ALL_STEPS_DATA);
        } 
  
        if(this.firstTimeStepEnd) {
          this.firstTimeStepEnd = false;
        }
      }
    }
  }

  /**
   * Read from parametros endpoint steps enabled values, and update to show them to user.
   */
  getPasosHabilitados() {
    const todosHabilitados: PasosHabilitados = {
      informacionPersonal: true,
      grupoEntrenamiento: true,
      antecedentes: true,
      bancario: true,
      marcasObjetivos: true
    };
    this.pasosHabilitados = todosHabilitados;
    
    this.parametrosService.getParametros('Pasos Habilitados').subscribe( response=> {

      const indexPersonal: number = response.findIndex(parametro => parametro.clave == "informacionPersonal");
      const indexGrupo: number = response.findIndex(parametro => parametro.clave == "grupoEntrenamiento");
      const indexAntecedentes: number = response.findIndex(parametro => parametro.clave == "antecedentes");
      const indexBancario: number = response.findIndex(parametro => parametro.clave == "bancario");
      const indexMarcasObjetivos: number = response.findIndex(parametro => parametro.clave == "marcasObjetivos");

      response[indexPersonal].valor == '1' ? this.pasosHabilitados.informacionPersonal = true : this.pasosHabilitados.informacionPersonal = false;
      response[indexGrupo].valor == '1' ? this.pasosHabilitados.grupoEntrenamiento = true : this.pasosHabilitados.grupoEntrenamiento = false;
      response[indexAntecedentes].valor == '1' ? this.pasosHabilitados.antecedentes = true : this.pasosHabilitados.antecedentes = false;
      response[indexBancario].valor == '1' ? this.pasosHabilitados.bancario = true : this.pasosHabilitados.bancario = false;
      response[indexMarcasObjetivos].valor == '1' ? this.pasosHabilitados.marcasObjetivos = true : this.pasosHabilitados.marcasObjetivos = false;

      sessionStorage.setItem('pasosHabilitados',JSON.stringify(response));

      this.updateCantidadesYPosiciones();
      this.loaded = true;
    },
      error => {
        console.error(error);
        this.pasosHabilitados = todosHabilitados;

        this.loaded = true;
      });
  }

  /**
   * Change the current step based on the step the user choosed in stepEnd component.
   * @param indexStepToEdit step user wants to edit. Index is setted in {@link updateCantidadesYPosiciones}
   */
  changeCurrentStep(indexStepToEdit: number) {
    for (let index = 0; index < (this.posicionEnd - indexStepToEdit); index++) {
      setTimeout(() => (this.myStepper.previous()), 0); 
    }
  }

  /**
   * Increase steps postion values and amount of steps enabled (cantidadPasosHabilitados)
   * according to which steps (pasosHabilitados) are enabled.
   */
  updateCantidadesYPosiciones() {
    if(this.pasosHabilitados.grupoEntrenamiento) {
      this.cantidadPasosHabilitados++;
      this.posicionAntecedentes++;
      this.posicionMarcasObjetivos++;
      this.posicionBancario++;
      this.posicionEnd++;
    }
    if(this.pasosHabilitados.antecedentes) {
      this.cantidadPasosHabilitados++;
      this.posicionMarcasObjetivos++;
      this.posicionBancario++;
      this.posicionEnd++;
    }
    if(this.pasosHabilitados.marcasObjetivos) {
      this.cantidadPasosHabilitados++;
      this.posicionBancario++;
      this.posicionEnd++;
    }
    if(this.pasosHabilitados.bancario) {
      this.cantidadPasosHabilitados++;
      this.posicionEnd++;
    }
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  public ngOnDestroy(): void {
    this.sharedData$.unsubscribe();
  }

}