
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../environments/environment';

import { ResetPassword, ResetPasswordRepeat, ReturnUser, NavbarButton, LoginUser, ResetNeeded, LoginData, ResetPasswordForgotten, RequestChange } from '../interfaces/login';
import { SnackbarService } from 'src/app/utils/snackbar/snackbar.service';
import { Router } from '@angular/router';
import { RoleString } from '../../enums/role';
import { StatusEnum } from 'src/app/enums/status';
import { EntrenadorAsignado } from '../interfaces/trainers';
import { MessagesService } from './messages/messages.service';
import { LogoutService } from 'src/app/services/logout/logout.service';
import { AlertTriggerService } from 'src/app/api/services/alertTrigger/alert-trigger.service';
import { TrainersService } from './trainers/trainers.service';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';
import { FlagsColor } from 'src/app/enums/sharedDataFlag';


@Injectable({
  providedIn: 'root'
})
export class LoginService {

  private url = environment.apiUrl + '/';
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json'
    }),
    withCredentials: true,
  };
  httpOptionsJson = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }),
    withCredentials: true,
  };
  multipartOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'multipart/form-data'
    })
  };
  constructor(
    private http: HttpClient,
    private snack: SnackbarService,
    private router: Router,
    private messagesService: MessagesService,
    private logOut: LogoutService,
    private alertTriggerService: AlertTriggerService,
    private trainersService: TrainersService,
    private sharedData: SharedDataService
  ) { }

  requestChangePassword(email: RequestChange): Observable<ResetPassword> {
    return this.http.post<ResetPassword>(this.url + 'api/usuarios/clave/nueva', email, this.httpOptionsJson);
  }

  getRequest(id: number): Observable<ReturnUser> {
    return this.http.get<ReturnUser>(this.url + `cuenta/requestChangePassword/${id}`, this.httpOptions);
  }

  LoginData(id: number): Observable<ReturnUser> {
    return this.http.get<ReturnUser>(this.url + `cuenta/requestChangePassword/${id}`, this.httpOptions);
  }

  getResetNeeded(): Observable<ResetNeeded> {
    return this.http.get<ResetNeeded>(this.url + `cuenta/resetNeeded/`, this.httpOptions)
  }

  setNewPass(resetPasswordRepeat: ResetPasswordRepeat): Observable<ReturnUser> {
    return this.http.post<ReturnUser>(this.url + 'api/perfil/cambioclave', resetPasswordRepeat, this.httpOptionsJson);
  }

  setNewPassForgotten(resetPasswordRepeat: ResetPasswordForgotten): Observable<ReturnUser> {
    return this.http.post<ReturnUser>(this.url + 'api/usuarios/clave', resetPasswordRepeat, this.httpOptionsJson);
  }

  loginUser(loginUser: LoginUser): Observable<any> {

    let body = new URLSearchParams();
    body.set('username', loginUser.username);
    body.set('password', loginUser.password);

    return this.http.post<any>(this.url + 'login', body, this.httpOptions);
  }

  getNavbarOptions(): Observable<NavbarButton[]> {
    return this.http.get<NavbarButton[]>(this.url + 'api/navbar', this.httpOptions);
  }

  getUserData(): Observable<LoginData> {
    return this.http.get<LoginData>(this.url + 'api/perfil', this.httpOptions);
  }

  public handleUserData() {
    this.getUserData().subscribe(response => {

      // ticket 3548
      sessionStorage.removeItem(FlagsColor.setDefaultTheme);
      const rol: string = response.rol;


      localStorage.setItem('equipoNombre',response.equipoNombre);
      localStorage.setItem('idUsuario',response.idUsuario.toString());
      if(response.perfilAlumno) {
        localStorage.setItem('idAlumno',response.perfilAlumno.id.toString());
        if(response.perfilAlumno.estado) {
          const nombreCompleto: string = `${response.perfilAlumno.nombre} ${response.perfilAlumno.apellido} ${response.perfilAlumno.segundoApellido ? response.perfilAlumno.segundoApellido : ''}`;
          localStorage.setItem("descripcionUsuario",nombreCompleto);
          localStorage.setItem('estadoAlumno',response.perfilAlumno.estado);
          let entrenadorAsignado: EntrenadorAsignado = {
            entrenadorId:response.perfilAlumno.entrenador.id,
            usuarioId: response.perfilAlumno.entrenador.usuarioId,
          }
          localStorage.setItem('entrenadorAsignado',JSON.stringify(entrenadorAsignado));
          localStorage.setItem('equipoId',response.perfilAlumno.equipoId.toString());
        }
      } else {
        localStorage.setItem("descripcionUsuario",response.descripcionUsuario);
        localStorage.setItem('equipoId',response.perfilEntrenador.equipoId.toString());
      }

      if(response.perfilEntrenador) {
        localStorage.setItem('idEntrenador',response.perfilEntrenador.id.toString());
      }
      localStorage.setItem('rol',response.rol);

      if (response.claveDefecto) {
        // sessionStorage.setItem('id', response.idResetPass.toString());
        this.router.navigateByUrl("/change-password/");
      } else {
        this.sharedData.changeMessage(FlagsColor.changeColor);
      }

      if (rol === RoleString.ADMIN && !response.claveDefecto) {
        this.router.navigate(['/admin/alumnos']);
        this.snack.snackTranslate('snacks.sucess.login.admin');
      }

      if (rol === RoleString.STUDENT && response.perfilAlumno.estado === StatusEnum.CREATED && !response.claveDefecto) {
        this.router.navigate(['/welcome/']);
        this.snack.snackTranslate('snacks.sucess.login.user');
      }

      if (rol === RoleString.STUDENT && ( response.perfilAlumno.estado === StatusEnum.ACTIVADO  ) ) {
        // enviar mensaje cuando el alumno da feedback
        this.getDatosCanal(response.perfilAlumno.entrenador.id);
        this.router.navigate(['/planes/semana/']);
        this.snack.snackTranslate('snacks.sucess.login.user');
      }

      if (rol === RoleString.STUDENT &&  response.perfilAlumno.estado === StatusEnum.SUSPENDIDO  ) {
        this.router.navigate(['alumno/mi-perfil']);
        this.snack.snackTranslate('snacks.sucess.login.user');
      }

      if (rol === RoleString.STUDENT && response.perfilAlumno.estado === StatusEnum.PENDING) {
        this.router.navigate(['/thanks']);
        this.snack.snackTranslate('snacks.sucess.login.user');
      }
      
      if (rol === RoleString.TRAINER && !response.claveDefecto && response.perfilEntrenador.estado == StatusEnum.ACTIVADO) {
        this.router.navigate(['/entrenador/alumnos']);
        this.snack.snackTranslate('snacks.sucess.login.user');
      }

      if (rol === RoleString.TRAINER && !response.claveDefecto && response.perfilEntrenador.estado != StatusEnum.ACTIVADO) {
        this.logOut.blocked();
      }

      this.alertTriggerService.handleAlertsByRolAndStatus();

    }, error => {
      console.error(error);
      if(error.status != 401) {
        this.snack.snackTranslate('snacks.error.generic');
      }
    });
  }

  /**
   * Save in localStorage canalId with the student's trainer.
   * @param entrenadorId 
   */
  getDatosCanal(entrenadorId: number) {
    // this.parametrosService.getProcesos('EntrenadorUsuario',entrenadorId.toString()).subscribe( response=> {
    this.trainersService.getUsuarioIdFromEntrenadorId(entrenadorId).subscribe( usuarioId=> {
      this.messagesService.getCanalUsuarioId(usuarioId).subscribe(canal => {
        localStorage.setItem('canalIdEntrenador',canal.content[0].id.toString());
      },
        error => {
          console.error(error);
          this.snack.snackTranslate('snacks.error.generic');
        });
    },
    error => {
      console.error(error);
    });
  }


}
