<br/>
<div class="responsiveFlex">
    <div *ngIf="!isTrainer">
        <app-inactividad-card 
            [estado]="estado" 
            [alumnoId]="alumnoId"
            *ngIf="!isAdminOrTrainer" >
        </app-inactividad-card>

        <app-disable 
            [estado]="estado" 
            [usuarioId]="usuarioId" 
            [motivoBaja]="motivoBaja"
            [entrenadorId]="entrenadorId"
            [isAdminOrTrainer]="isAdminOrTrainer"
            [entrenadorIsAdmin]="entrenadorIsAdmin">
        </app-disable>
    </div>
        <app-status-history 
            [entrenadorId]="entrenadorId"
            [alumnoId]="alumnoId"
            [isAdminOrTrainer]="isAdminOrTrainer">
        </app-status-history>
</div>

