import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuxHideButtons, ItemSeccion } from '../../../../api/interfaces/sections';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SharedDataService } from '../../../../services/sharedData/shared-data.service';
import { SeccionesService } from '../../../../api/services/secciones/secciones.service';
import { SnackbarService } from '../../../../utils/snackbar/snackbar.service';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent} from '../../../generic-dialog/generic-dialog.component';
import { NewEditElementComponent } from '../new-edit/new-edit-element/new-edit-element.component';
import { RolService } from '../../../../services/rol/rol.service';
import { MobileDetectorService } from 'src/app/services/mobileDetector/mobile-detector.service';

/**
 * ItemComponent
 * @param item interface ItemSeccion
 * @param itemClass - css class for div item, string
 * @param disableDragDrop - hide action buttons if drag and drop is enabled, boolean
 * @param disableDragDropItems - hide action buttons if drag and drop items is enabled, boolean
 * @param seccionId number
 * @param index number
 */
@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.scss']
})
export class ItemComponent implements OnInit, OnDestroy {

  @Input('item') item: ItemSeccion;
  @Input('index') index: number;
  @Input('itemClass') itemClass: string;
  @Input('seccionId') seccionId: number;
  @Input('seccionNombre') seccionNombre: string;
  @Input('disableDragDrop') disableDragDrop: boolean;
  @Input('disableDragDropItems') disableDragDropItems: boolean;

  @Output() toogleDragDropItemsEvent = new EventEmitter<any>();

  hideButtons : AuxHideButtons = {
    hide: true,
    index: 0,
  };

  isAdmin: boolean = false;
  isMobile: boolean = false;

  icon: string = "";

  private sharedData$: Subscription;
  
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private sharedData: SharedDataService,
    private seccionesService: SeccionesService,
    private snack: SnackbarService, 
    public dialog: MatDialog,
    private rolService: RolService,
    private mobileDetectorService: MobileDetectorService,
  ) { 
    this.matIconRegistry.addSvgIcon(
      `documento`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../assets/documento.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `video`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../assets/video.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `delete`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../assets/delete.svg')
    );
  }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    this.isAdmin = this.rolService.isAdmin();
    switch(this.item.itemTipo) {
      case "P":
          this.icon = "documento";
        break;
      case "V":
          this.icon = "video";
        break;
    }
    this.sharedData$ =  this.sharedData.currentMessage.subscribe(message => {
      if (message === "hideButtons") {
        this.hideButtons = JSON.parse(sessionStorage.getItem(('hideButtons')));
      }
      if (message === "postItem") {
        let aux: ItemSeccion = JSON.parse(sessionStorage.getItem('postItem'));
        let auxSection: number = parseInt(sessionStorage.getItem('inSection'),10);
        if(aux.itemNombre == this.item.itemNombre && this.seccionId == auxSection) {
          sessionStorage.removeItem('postItem');
          sessionStorage.removeItem('inSection');
          this.changeItem();
        }
      }
    });
  }

  changeItem() {
    sessionStorage.setItem('item',JSON.stringify(this.item));
    sessionStorage.setItem('seccionNombre',this.seccionNombre);
    this.sharedData.changeMessage("itemChanged");
    this.item.selected = !this.item.selected;
  }

  removeItem() {
    let title: string = "¿Está seguro que desea eliminar "
    if(this.item.itemTipo == "V") {
      title = title+ "el video?"
    } else {
      title = title+ "el documento?"
    }

    const dialogRef = this.dialog.open(GenericDialogComponent, {
      data: {
        title: title,
        iconClass: "bigBlueIcon",
        text: "",
        icon: "error_outlined",
        confirm: "ELIMINAR",
        cancel: "NO"
      }
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.seccionesService.deleteItem(this.item.itemId).subscribe( response=> {
          if(this.item.itemTipo == "V") {
            this.snack.snackTranslate('snacks.sucess.sections.deleteVideo');
          } else {
            this.snack.snackTranslate('snacks.sucess.sections.deletePdf');
          }
          sessionStorage.removeItem('item');
          this.sharedData.changeMessage("reloadSections");
        },
        error => {
          console.error(error);
          this.snack.openSnackBar(error.error.message);
        });
      }
    });
  }

  edit() {
    this.dialog.open(NewEditElementComponent, {
      data:  {
        itemTipo: this.item.itemTipo,
        itemId: this.item.itemId,
        itemNombre: this.item.itemNombre,
        itemUbicacion: this.item.itemUbicacion,
        seccionId: this.seccionId
      }
    });
  }

  toogleDrag() {
    this.toogleDragDropItemsEvent.emit();
  }

  public ngOnDestroy(): void {
    this.sharedData$.unsubscribe();
  }

}
