import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ItemSeccion } from '../../../../api/interfaces/sections';
import { SharedDataService } from '../../../../services/sharedData/shared-data.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FileData } from 'src/app/api/interfaces/fileData';
import { saveAs } from 'file-saver';
import { DownloadService } from 'src/app/api/services/download/download.service';
import { MobileDetectorService } from 'src/app/services/mobileDetector/mobile-detector.service';

/**
 * Read from sessionStorage selected item's data and display it.
 * Show video using 'iframe', show pdf using pdf-viewer from 'ng2-pdf-viewer'
 * 
 * @param item ItemSeccion
 */
@Component({
  selector: 'app-show-item-selected',
  templateUrl: './show-item-selected.component.html',
  styleUrls: ['./show-item-selected.component.scss']
})
export class ShowItemSelectedComponent implements OnInit, OnDestroy {

  public safeURL: SafeResourceUrl;
  
  loadedPdf: boolean = false;
  isMobile: boolean = false;
  
  item: ItemSeccion;
  
  buttonClass: string = "";
  seccionNombre: string = "";
  pdfPath: string = "";
  icon: string = "";

  zoom: number = 0.9;
  zoom2: number = 1.2;

  private sharedData$: Subscription;

  constructor(
    private sharedData: SharedDataService,
    private sanitizer: DomSanitizer,
    private downloadService: DownloadService,
    private mobileDetectorService: MobileDetectorService,
  ) { }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    if(this.isMobile) {
      this.zoom = 1;
      this.buttonClass = "tinyButtonMobile";
    }
    
    this.sharedData$ = this.sharedData.currentMessage.subscribe(message => {
      if (message === "itemChanged") {
        this.item = JSON.parse(sessionStorage.getItem('item'));
        this.seccionNombre = sessionStorage.getItem('seccionNombre');
        if(this.item) {

          switch (this.item.itemTipo) {
            case "P":
              this.loadedPdf = false;
              this.icon = "documento";
              this.getPreview();
              break;
              case "V":
                this.icon = "video";
                this.getVideoIframe(this.item.itemUbicacion);
                break;
          }
        }
      }
      if (message === "reloadSections") {
        this.item = JSON.parse(sessionStorage.getItem('postItem'));
        if(this.item) {
          this.sharedData.changeMessage("postItem");
        } else {
          this.item = JSON.parse(sessionStorage.getItem('item'));
        }
      }
    });
  }

  getVideoIframe(url: string) {
    var video, results;
    if (url === null) {
      return '';
    }
    results = url.match('[\\?&]v=([^&#]*)');
    video = (results === null) ? url : results[1];
    return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + video);
  }

  async getPreview() {
    this.pdfPath = await this.downloadService.preview(this.item.itemId);
    this.loadedPdf = true;
  }

  download() {
    const fileData: FileData = {
      filename: this.item.itemNombre + ".pdf",
    }
    this.downloadService.downloadFileNutricionComplementos(this.item.itemId)
      .subscribe(archivo => {
        saveAs(archivo, fileData.filename);
      });
  }

  public ngOnDestroy(): void {
    this.sharedData$.unsubscribe();
  }

  back() {
    sessionStorage.removeItem('item');
    this.sharedData.changeMessage("itemChanged");
    this.item.selected = !this.item.selected;
  }

}
