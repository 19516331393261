import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MobileDetectorService } from 'src/app/services/mobileDetector/mobile-detector.service';
import { RolService } from 'src/app/services/rol/rol.service';
import { SupportService } from '../../../../api/services/support/support.service';
import { SupportDialogComponent } from '../support-dialog/support-dialog.component';

@Component({
  selector: 'app-support-button',
  templateUrl: './support-button.component.html',
  styleUrls: ['./support-button.component.scss']
})
export class SupportButtonComponent implements OnInit {

  isAdmin: boolean = false;
  isMobile: boolean = false;

  constructor(
    public dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private supportService: SupportService,
    private rolService: RolService,
    private mobileService: MobileDetectorService
  ) { 
    this.matIconRegistry.addSvgIcon(
      `ticket`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/ticket.svg')
    );
  }

  ngOnInit(): void {
    this.isMobile = this.mobileService.isMobile();
    this.isAdmin = this.rolService.isAdmin();

    window.addEventListener('unload', function(event) {
      sessionStorage.removeItem('supportDialogOpen')
    });
    
  }

  openDialog() {
    // ticket 3116 
    const settingsMobile = {       
      disableClose: true, 
      hasBackdrop: false,  
      maxWidth: '95vw',
      width: '95%'
    };

    const settingsDesktop = {
      disableClose: true, 
      hasBackdrop: false
    }

    if(!JSON.parse(sessionStorage.getItem('supportDialogOpen'))) {
      sessionStorage.setItem('supportDialogOpen','true');
      let dialogRef = this.dialog.open(SupportDialogComponent, this.isMobile ? settingsMobile : settingsDesktop);
    
      dialogRef.afterClosed().subscribe(result => {
        sessionStorage.removeItem('supportDialogOpen');
        sessionStorage.removeItem('navegacion');
        sessionStorage.removeItem('navegacionCompleta');
        const postUsefullRequired: boolean = JSON.parse(sessionStorage.getItem('postUsefullRequired'));
        if(postUsefullRequired) {
          this.supportService.postUsefull();
        }
      });
    }
  }
}
