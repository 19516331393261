import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { ChangeUserStatus, EntrenamientoDiaIndividual, EntrenamientoModelBroken, Level, Personal, PersonalTrainer, Training} from '../../../api/interfaces/user';
import { ImageService } from '../../../api/services/image/image.service';
import { TrainingGroupEnum, TrainingTypeEnum } from '../../../enums/training-type';
import { StatusEnum } from '../../../enums/status';
import {MatDialog} from '@angular/material/dialog';
import { AssignDialogComponent } from '../assign-dialog/assign-dialog.component';
import { FormControl, FormGroup } from '@angular/forms';
import { SnackbarService } from '../../../utils/snackbar/snackbar.service';
import { TrainingService } from '../../../api/services/training/training.service';
import { GenericDialogComponent } from '../../generic-dialog/generic-dialog.component';
import { UserService } from '../../../api/services/user/user.service';
import { MobileDetectorService } from '../../../services/mobileDetector/mobile-detector.service';
import { PlanDatosDia } from '../../../api/interfaces/plans';
import { RegisterService } from '../../../api/services/register/register.service';
import { TrainingDays } from '../../../api/interfaces/register';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { saveAs } from 'file-saver';
import { RolService } from 'src/app/services/rol/rol.service';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { ResendMailComponent } from '../resend-mail/resend-mail.component';
import { TrainersService } from '../../../api/services/trainers/trainers.service';
import { AlertTriggerService } from 'src/app/api/services/alertTrigger/alert-trigger.service';
import { NivelesEnum } from 'src/app/enums/niveles';
@Component({
  selector: 'app-profile-header',
  templateUrl: './profile-header.component.html',
  styleUrls: ['./profile-header.component.scss']
})
export class ProfileHeaderComponent implements OnInit {

  ADMIN_ID : number = 1;
  cantAlumnos: number = 0;

  isMobile: boolean = false;
  isValid: boolean = false;
  isTrainer: boolean = false;
  showLevel: boolean = false;
  userHasImage: boolean = false;
  showBack: boolean = true;

  TrainingTypeEnum = TrainingTypeEnum;
  StatusEnum = StatusEnum;
  
  groups: TrainingDays[] = [];
  
  @Input('status') status: string;
  @Input('user_id') user_id: number;
  @Input('isAdmin') isAdmin: boolean;
  @Input('avatarUrl') avatarUrl: string;
  @Input('training') training: Training;
  @Input('isStudent') isStudent: boolean;
  @Input('disableSave') disableSave: boolean;
  @Input('personalData') personalData: Personal;
  @Input('isAdminOrTrainer') isAdminOrTrainer: boolean;
  @Input('datosTraining') datosTraining: EntrenamientoModelBroken;
  @Input('usuarioId') usuarioId: number;
  @Input('email') email: string;

  @Input('entrenadorId') entrenadorId: number;
  
  @ViewChild('levelSelect') levelSelect;
  @ViewChild('trainerSelect') trainerSelect;
  @Output() updateDataEvent = new EventEmitter<any>();

  levels: Level[] = [];
  trainers: PersonalTrainer[] = [];

  userForm = new FormGroup({
    level: new FormControl(),
    trainer: new FormControl(),
    group: new FormControl()
  });

  name = 'Test display image';
  thumbnail: any;

  constructor(
    private imageService: ImageService,
    public dialog: MatDialog,
    private snack: SnackbarService,
    private trainingService: TrainingService,
    private userService: UserService,
    private mobileDetectorService: MobileDetectorService,
    private registerService: RegisterService,
    private sharedData: SharedDataService,
    private sanitizer: DomSanitizer,
    private roleService: RolService,
    private activatedrouter: ActivatedRoute,
    private trainersService: TrainersService,
    private alertTriggerService: AlertTriggerService
  ) { }

  ngOnInit(): void {
    const url = this.activatedrouter.snapshot.routeConfig.path;
    if(url == "entrenador/perfil") {
      this.showBack = false;
    }
    this.isTrainer = this.roleService.isTrainer();
    // this.getAvatar();
    this.isMobile = this.mobileDetectorService.isMobile();
    if(this.avatarUrl != null) {
      this.userHasImage = true;
    }
    this.loadLevels();
    this.loadTrainers();
    this.loadGroups();

    if(this.isAdminOrTrainer) {
        this.trainersService.getCantidadAlumnos(this.entrenadorId).subscribe(response => {
          this.cantAlumnos = response;
        })
        error => {
          this.snack.snackTranslate('snacks.error.generic');
        }
      }
      
    if(this.training) {
      this.userForm.patchValue({level: this.training.level});
      this.userForm.patchValue({trainer: this.training.trainer});
      this.userForm.patchValue({group: this.datosTraining.grupo});
    }
    this.checkValidSaveButton();
  }

  // async selectFiles(event) {
  //   if(!this.isAdmin) {
  //     try {
  //       await this.imageService.uploadFile(event);
  //       this.getAvatar();
  //     } catch {
  //       this.avatarUrl="";
  //       this.userHasImage = false;
  //     }
  //   }
  // }

  // async getAvatar() {
  //   this.avatarUrl = await this.imageService.getAvatarUrl(this.usuarioId);
  //   if(this.avatarUrl) {
  //     this.userHasImage = true;
  //   }
  // }

  openDialog(){
    const dialogRef = this.dialog.open(AssignDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      //api call
    });
  }

  loadGroups() {
    this.registerService.getTrainingDays().subscribe( response=> {
      this.groups = response;
      let index = this.groups.findIndex(element => element.grupoDescripcion == TrainingGroupEnum.noDefinido);
      if(index != -1) {
        this.groups.splice(index,1);
      }
    },
    error => {
      console.error(error);
    });
  }

  loadLevels() {
    this.trainingService.getLevels().subscribe( response=> {
      this.levels = response;
      let remover: number = this.levels.findIndex(option => option.entrenamientoNivelDescripcion == NivelesEnum.noDefinido);
      if(remover != -1) {
        this.levels.splice(remover, 1);
      }
    },
    error => {
      console.error(error);
    });
  }

  loadTrainers() {
    this.trainingService.getTrainers().subscribe( response=> {
      this.trainers = response;
    },
    error => {
      console.error(error);
    });
  }

  openLevelSelect() {
    this.levelSelect.open();
    // this.showLevel = true;
  }

  openTrainerSelect() {
    this.trainerSelect.open();
  }

  saveLevel(level: Level) {
    this.userForm.patchValue({level: level});
    this.checkValidSaveButton();
  }

  saveTrainer(trainer: PersonalTrainer) {
    this.userForm.patchValue({trainer: trainer});
    this.checkValidSaveButton();
  }

  saveGroup(group: TrainingDays) {
    this.userForm.patchValue({group: group});
    this.checkValidSaveButton();
  }

  /**
   * Open GenericDialogComponent and request admin to confirm "activar" student. Show warning text if admin is missing training days
   * Call activateStudent after admin confirmation.
   */
  openSaveDialog() {
    const entrenamientoDiasPlan: PlanDatosDia[] = JSON.parse(sessionStorage.getItem('entrenamientoDiasPlan'));
    let text: string = "";
    if(entrenamientoDiasPlan) {
      const entrenamientoDiasPlanAdicionales: PlanDatosDia[] = entrenamientoDiasPlan.filter(dia => (dia.tipoDia == 'A' && dia.detalleDia == 'Descanso'));
      if(entrenamientoDiasPlanAdicionales.length > 0) {
        // warning text
        text = "Hay uno o varios días donde el alumno indicó su disponibilidad y no fueron completados.";
      }
    }

    const dialogRef = this.dialog.open(GenericDialogComponent, {
      data: {
        title: "¿Desea activar al alumno?",
        iconClass: "bigBlueIcon",
        text: text,
        icon: "error_outlined",
        confirm: "SI, ACTIVAR",
        cancel: "NO"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.createNotificacionAndActivateStudent();
      }
    });
  }

  /**
   * Send data to backend to activate student.
   * 
   * Interface {@link ChangeUserStatus}
   */
  activateStudent() {
    let today = new Date().toISOString();
    const entrenadorId: number = this.userForm.value.trainer.id;
    const entrenamientoNivelId: number = this.userForm.value.level.id;
    const entrenamientoGrupoId: number = this.userForm.value.group.entrenamientoGrupoId ? this.userForm.value.group.entrenamientoGrupoId:  this.datosTraining.idGrupo;
    let changeUserStatus: ChangeUserStatus = {
      entrenamientoNivelId: entrenamientoNivelId,
      entrenadorId: entrenadorId,
      entrenamientoGrupoId: entrenamientoGrupoId,
      estado: "A",
      ultimaModificacion: today,
      ultimaModificacionPor: this.ADMIN_ID,
      usuarioId: this.usuarioId
    };

    this.userService.changeUserStatus(changeUserStatus, this.user_id).subscribe(response => {
      this.snack.snackTranslate('snacks.sucess.updatingData.activate');
      this.afterSubmit();
    },
    error => {
      console.error(error);
      if(error.error.message ) {
        this.snack.openSnackBar(error.error.message);
      } else {
        this.snack.snackTranslate('snacks.error.activate');
      }
    });
  }

  createNotificacionAndActivateStudent() {
    this.alertTriggerService.createNotificacion(this.usuarioId, 'A').subscribe(response  => {
      this.activateStudent();
    },
    error => {
      console.error(error);
      if(error.error.message ) {
        this.snack.openSnackBar(error.error.message);
      } else {
        this.snack.snackTranslate('snacks.error.notification');
      }
    });
  }

  afterSubmit() {
    this.updateDataEvent.emit();
    this.isValid = false;
    sessionStorage.setItem('alumnoIdCheck',this.user_id.toString());
    this.sharedData.changeMessage("reloadWeek2");
  }

  /**
   * Open GenericDialogComponent and request admin to confirm update student data.
   * Call updateStudent after admin confirmation.
   */
  openEditDialog() {

    const dialogRef = this.dialog.open(GenericDialogComponent, {
      data: {
        title: "¿Desea guardar los cambios?",
        iconClass: "bigBlueIcon",
        text: "",
        icon: "error_outlined",
        confirm: "SI",
        cancel: "NO",
        showConfirmAsPrimary: true
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.updateStudent();
      }
    });
  }

  /**
   * Update student data and call afterSubmit.
   * 
   * Interface {@link ChangeUserStatus}
   */
  updateStudent() {
    let today = new Date().toISOString();
    const entrenadorId: number = this.userForm.value.trainer.id;
    const entrenamientoNivelId: number = this.userForm.value.level.id;
    const entrenamientoGrupoId: number = this.userForm.value.group.entrenamientoGrupoId ? this.userForm.value.group.entrenamientoGrupoId:  this.datosTraining.idGrupo;
    const changeUserStatus: ChangeUserStatus = {
      entrenamientoNivelId: entrenamientoNivelId,
      entrenadorId: entrenadorId,
      entrenamientoGrupoId: entrenamientoGrupoId,
      estado: null,
      ultimaModificacion: today,
      ultimaModificacionPor: this.ADMIN_ID,
      usuarioId: this.usuarioId
    };
    this.userService.changeUserStatus(changeUserStatus, this.user_id).subscribe(response => {
      this.snack.snackTranslate('snacks.sucess.updatingData.generic');
      this.afterSubmit();
    },
    error => {
      console.error(error);
      if(error.error.message ) {
        this.snack.openSnackBar(error.error.message);
      } else {
        this.snack.snackTranslate('snacks.error.trainingDataPatch');
      }
    });
  }

  /**
   * Check {@link level}, {@link trainer} and {@link group} to update {@link isValid}, which disable save button
   */
  checkValidSaveButton() {
    const {level, trainer, group} = this.userForm.getRawValue();
    if(level) {

      if( level.id != 1 && trainer && group ) {
        this.isValid = true;
      } else {
        this.isValid = false;
      }
    } else {
      this.isValid = false;
    }
  }  

  openResendMailDialog() {
    this.dialog.open(ResendMailComponent, {
      data: {
        email: this.email
      }
    });
  }

}
