import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog'

/**
 * Display a warning and request user's confirmation before do something.
 * 
 * @param title string, popup title
 * @param iconClass string, css class for icon
 * @param text string, popup text a tiny hint about the action. If no needed then value should be ""
 * @param icon string, material icon's name
 * @param confirm string, text to display in confirm button
 * @param cancel string, text to display in cancel button
 * 
 * 
 * @example calling the dialog and doing something after confirmation
 * ```ts
 * const dialogRef = this.dialog.open(GenericDialogComponent, {
 *     data: {
 *       title: "¿Desea guardar los cambios?",
 *       iconClass: "bigBlueIcon",
 *       text: "Esto no es reversible",
 *       icon: "error_outlined",
 *       confirm: "CONFIRMAR",
 *       cancel: "NO"
 *     }
 *   });
 *
 *   dialogRef.afterClosed().subscribe(result => {
 *     let today = new Date().toISOString();
 *     if(result) {
 *       //do something
 *   });
 * ```
 * 
 * @example showing the primary color on confirm button.  Add showConfirmAsPrimary: true in data. Not required in the previous case.
 * ```ts
 * const dialogRef = this.dialog.open(GenericDialogComponent, {
 *     data: {
 *       title: "¿Desea guardar los cambios?",
 *       iconClass: "bigBlueIcon",
 *       text: "",
 *       icon: "error_outlined",
 *       confirm: "SI",   
 *       cancel: "NO"
 *       showConfirmAsPrimary: true //notice this
 *     }
 *   });
 * ```
  * @returns result: boolean
 */
@Component({
  selector: 'app-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['./generic-dialog.component.scss']
})
export class GenericDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
  }

}
