import { Component, OnInit, Input } from '@angular/core';
import { PreguntaFrecuente } from 'src/app/api/interfaces/help';
import { MobileDetectorService } from 'src/app/services/mobileDetector/mobile-detector.service';

/**
 * Display one FAQ
 * @param pregunta - PreguntaFrecuente this is the FAQ for user to read
 */
@Component({
  selector: 'app-pregunta-help',
  templateUrl: './pregunta-help.component.html',
  styleUrls: ['./pregunta-help.component.scss']
})
export class PreguntaHelpComponent implements OnInit {

  isMobile: boolean = false;
  panelOpenState: boolean = false;

  @Input('pregunta') pregunta: PreguntaFrecuente;

  constructor(
    private mobileDetectorService: MobileDetectorService,
  ) { }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
  }

}
