import { Component, OnInit, Input } from '@angular/core';

/**
 * shows a loading skeleton
 * @param class - string. Css class.
 * @example
 * 
 * html
 * ```
 * <app-skeleton *ngIf="!loadedAvatar" [class]="class"></app-skeleton>
 * ```
 * ts
 * ```
 * class: string = "imgRounded";
 * ```
 * 
 * @example
 * html
 * ```
 *  <app-skeleton *ngIf="!loadedAvatar" [class]="'imgRoundedTable marginRigthOnly'"></app-skeleton>
 * ```
 */

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss']
})
export class SkeletonComponent implements OnInit {

  @Input('class') class: string;

  skeletonClass: string = null;

  constructor() { }

  ngOnInit(): void {
    // add loading animation to class
    this.skeletonClass = this.class + " skeleton";
  }

}
