<div class="dialogContainer450">
  <div class="flexBeetwen">
    <div class="headline6"> {{'admin.domiciliacion.message.title' | translate}} </div>
    <button
        mat-button mat-dialog-close 
        class="buttonDialogClose">
        <mat-icon class="clearIcon">
            close
        </mat-icon>
    </button>
  </div>
  <br/>

  <p class="body2">{{'admin.domiciliacion.message.subtitle'  | translate}}</p>

      
  <form [formGroup]="newForm" class="paddingMobile">  
      <mat-form-field appearance="fill" class="inputTwoLine">
        <mat-label>{{'messages.massive.destinatarios.type' | translate}}</mat-label>
        <mat-select 
          [(ngModel)]="type" 
          (selectionChange)="onChangeType()"
          [ngModelOptions]="{standalone: true}"
          #typeSelect="ngModel"
          multiple>
          <mat-select-trigger>
            <span *ngFor="let element of type">
              {{element.entrenamientoTipoDescripcion}}
            </span>
          </mat-select-trigger>

          <div class="filter-option">
            <button
              mat-button 
              color="primary"
              (click)="selectAll(typeSelect, allTypes); onChangeType()">
              {{'messages.massive.destinatarios.all' | translate }} 
            </button>

            <button 
              mat-button
              (click)="deselectAll(typeSelect); onChangeType()">
              {{'admin.studentsTable.filters.clear' | translate}}
            </button>
          </div>

          <mat-option 
            *ngFor="let type of allTypes" 
            [value]="type">
            {{type.entrenamientoTipoDescripcion}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="inputTwoLine">
        <mat-label>{{'messages.massive.destinatarios.group' | translate}}</mat-label>
        <mat-select 
          [disabled]="!enabledTrainingDaysSelect" 
          (selectionChange)="checkDisabled()"
          [(ngModel)]="traningDays" 
          #trainingDaysSelect="ngModel"
          [ngModelOptions]="{standalone: true}"
          multiple>
          <mat-select-trigger>
            {{traningDays && traningDays[0] ? traningDays[0].grupoDescripcion : ''}}
            <span *ngIf="traningDays?.length > 1">
              ( + {{ (traningDays?.length === 2 ? 'messages.massive.other' : 'messages.massive.others') | translate }}
                  {{ traningDays?.length > 2 ? traningDays.length - 1 : ''}} )
            </span>
          </mat-select-trigger>

          <div class="filter-option"> 
            <button
              mat-button
              color="primary"
              (click)="selectAll(trainingDaysSelect, allTrainingDays)">
              {{'messages.massive.destinatarios.all' | translate }} 
            </button>

            <button
              mat-button
              (click)="deselectAll(trainingDaysSelect)">
              {{'admin.studentsTable.filters.clear' | translate}}
            </button>
          </div>

          <mat-option 
            *ngFor="let trainingDay of allTrainingDays" 
            [value]="trainingDay">
            {{trainingDay.grupoDescripcion}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <br/>
      <p class="subtitle2"> {{'messages.massive.field1' | translate}}</p>
      
      <mat-form-field class="inputfullWidth" appearance="fill">
          <mat-label>{{ 'messages.massive.placeholder1' | translate}}</mat-label>
          <input id="asuntoInput" formControlName="asunto" type="text" matInput required>
      </mat-form-field>
      
      <p class="subtitle2"> {{'messages.massive.field2' | translate}}</p>
      
      <mat-form-field class="inputfullWidth" appearance="fill">
          <mat-label>{{ 'messages.massive.placeholder2' | translate}}</mat-label>
          <textarea  maxLength={{longitudMensaje}} matInput id="mensajeInput" formControlName="mensaje" type="text" required></textarea>
          <mat-hint align="end">{{newForm.value.mensaje?.length || 0}} / {{longitudMensaje}}</mat-hint>
      </mat-form-field>

      
      <button 
        [disabled]="!newForm.valid || loading" 
        mat-raised-button color="accent" 
        class="buttonFullWidth" 
        (click)="onSubmit()">
        {{ 'admin.domiciliacion.message.button' | translate }}
      </button>

      <p class="body4">{{ 'admin.domiciliacion.message.hint' | translate }}</p>


  </form>
</div>