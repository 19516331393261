import { Component, OnInit, Output, EventEmitter } from '@angular/core';

/**
 * Show options to user:
 * create bug ticket, create suggestion ticket, toogle support tree to see FAQ.
 * Events listening by SupportDialogComponent
 */
@Component({
  selector: 'app-support-type-option',
  templateUrl: './support-type-option.component.html',
  styleUrls: ['./support-type-option.component.scss']
})
export class SupportTypeOptionComponent implements OnInit {

  @Output() toggleBugEvent = new EventEmitter<boolean>();
  @Output() toggleTreeEvent = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Change mode to create ticket and remove navegacion,
   * @param bug - boolean. If true, open ticket in bug mode, otherwise in "incidente" mode
   */
  toggleBugMode(bug: boolean) {
    sessionStorage.removeItem('navegacion');
    this.toggleBugEvent.emit(bug);
  }

}
