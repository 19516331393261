import { Component, OnInit } from '@angular/core';
import { MobileDetectorService } from 'src/app/services/mobileDetector/mobile-detector.service';
import { MatDialog } from '@angular/material/dialog';
import { CreateTrainerComponent } from '../create-trainer/create-trainer.component';

@Component({
  selector: 'app-mainpage-trainers',
  templateUrl: './mainpage-trainers.component.html',
  styleUrls: ['./mainpage-trainers.component.scss']
})
export class MainpageTrainersComponent implements OnInit {

  isMobile: boolean = false;
  constructor(
    private mobileDetectorService: MobileDetectorService,
    public dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    sessionStorage.setItem('active', 'entrenadores');
  }

  openDialogCreateTrainer() {
    this.dialog.open(CreateTrainerComponent, {
      backdropClass: 'backdropBackground',
    });
  }

}
