<div class="paddingLeftOnly">
  <br/>
  <div class="flexBeetwen">
    <p class="headline6">{{'messages.massive.title' | translate}}</p>
    <button mat-button (click)="close()">
      <mat-icon class="clearIcon">close</mat-icon>
  </button>
  </div>
  <br/>
    
  <form [formGroup]="newForm" class="paddingMobile">  

      <mat-form-field appearance="fill" class="inputsFilter2">
        <mat-label>{{'messages.massive.destinatarios.level' | translate}}</mat-label>
        <mat-select 
          multiple
          [(ngModel)]="levels" 
          [ngModelOptions]="{standalone: true}"
          (selectionChange)="checkDisabled()"
          #levelSelect="ngModel">
          <!-- display names and others -->
          <mat-select-trigger>
            {{levels && levels[0] ? levels[0].entrenamientoNivelDescripcion : ''}}
            <span *ngIf="levels?.length > 1">
              ( + {{ (levels?.length === 2 ? 'messages.massive.other' : 'messages.massive.others') | translate }} 
                {{ levels?.length > 2 ? levels.length - 1 : ''}} )
            </span>
          </mat-select-trigger>
          <div class="filter-option"> 
            <button 
              mat-button 
              color="primary"
              (click)="selectAll(levelSelect, allLevels)">
              {{'messages.massive.destinatarios.all' | translate }} 
            </button>

            <button
              mat-button
              (click)="deselectAll(levelSelect)">
              {{'admin.studentsTable.filters.clear' | translate}}
            </button>

          </div>
             
          <mat-option 
            *ngFor="let level of allLevels" 
            [value]="level">
            {{level.entrenamientoNivelDescripcion}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="inputsFilter2">
        <mat-label>{{'messages.massive.destinatarios.type' | translate}}</mat-label>
        <mat-select 
          [(ngModel)]="type" 
          (selectionChange)="onChangeType()"
          [ngModelOptions]="{standalone: true}"
          #typeSelect="ngModel"
          multiple>
          <mat-select-trigger>
            <span *ngFor="let element of type">
              {{element.entrenamientoTipoDescripcion}}
            </span>
          </mat-select-trigger>

          <div class="filter-option">
            <button
              mat-button 
              color="primary"
              (click)="selectAll(typeSelect, allTypes); onChangeType()">
              {{'messages.massive.destinatarios.all' | translate }} 
            </button>

            <button 
              mat-button
              (click)="deselectAll(typeSelect); onChangeType()">
              {{'admin.studentsTable.filters.clear' | translate}}
            </button>
          </div>

          <mat-option 
            *ngFor="let type of allTypes" 
            [value]="type">
            {{type.entrenamientoTipoDescripcion}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="inputsFilter">
        <mat-label>{{'messages.massive.destinatarios.group' | translate}}</mat-label>
        <mat-select 
          [disabled]="!enabledTrainingDaysSelect" 
          (selectionChange)="checkDisabled()"
          [(ngModel)]="traningDays" 
          #trainingDaysSelect="ngModel"
          [ngModelOptions]="{standalone: true}"
          multiple>
          <mat-select-trigger>
            {{traningDays && traningDays[0] ? traningDays[0].grupoDescripcion : ''}}
            <span *ngIf="traningDays?.length > 1">
              ( + {{ (traningDays?.length === 2 ? 'messages.massive.other' : 'messages.massive.others') | translate }}
                 {{ traningDays?.length > 2 ? traningDays.length - 1 : ''}} )
            </span>
          </mat-select-trigger>

          <div class="filter-option"> 
            <button
              mat-button
              color="primary"
              (click)="selectAll(trainingDaysSelect, allTrainingDays)">
              {{'messages.massive.destinatarios.all' | translate }} 
            </button>

            <button
              mat-button
              (click)="deselectAll(trainingDaysSelect)">
              {{'admin.studentsTable.filters.clear' | translate}}
            </button>
          </div>

          <mat-option 
            *ngFor="let trainingDay of allTrainingDays" 
            [value]="trainingDay">
            {{trainingDay.grupoDescripcion}}
          </mat-option>
        </mat-select>
      </mat-form-field>

        <br/>
        <div class="flexBeetwen">

            <!-- <mat-checkbox (click)="toggleHolidays()" color="primary">
                <span class="subtitle2"> {{'messages.massive.holidays.title' | translate}}</span>
            </mat-checkbox> -->

                <!-- <span *ngIf="newForm.value.vacaciones" class="subtitle2" id="period"> {{'messages.massive.holidays.period' | translate}}</span>

                <mat-form-field *ngIf="newForm.value.vacaciones" appearance="fill" class="inputDate">
                    <mat-label>{{ 'messages.massive.holidays.from' | translate}}</mat-label>
                    <input id="dateInput" matInput disabled formControlName="fechaDesde" [matDatepicker]="picker">
                    <mat-datepicker-toggle class="primaryIcon"  matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker disabled="false" #picker>
                        <mat-datepicker-actions>
                            <button mat-button matDatepickerCancel>{{ 'calendar.cancel' | translate}}</button>
                            <button mat-raised-button color="accent" matDatepickerApply>{{ 'calendar.save' | translate}}</button>
                          </mat-datepicker-actions>
                    </mat-datepicker>
                </mat-form-field>
            
                <mat-form-field *ngIf="newForm.value.vacaciones" appearance="fill" class="inputDate">
                    <mat-label>{{ 'messages.massive.holidays.until' | translate}}</mat-label>
                    <input id="dateInput2" matInput disabled formControlName="fechaHasta" [matDatepicker]="picker2">
                    <mat-datepicker-toggle class="primaryIcon"  matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker disabled="false" #picker2>
                        <mat-datepicker-actions>
                            <button mat-button matDatepickerCancel>{{ 'calendar.cancel' | translate}}</button>
                            <button mat-raised-button color="accent" matDatepickerApply>{{ 'calendar.save' | translate}}</button>
                          </mat-datepicker-actions>
                    </mat-datepicker>
                </mat-form-field> -->

        </div>
        <br/>
        <p class="subtitle2"> {{'messages.massive.field1' | translate}}</p>
        
        <mat-form-field class="inputFullWidth2" appearance="fill">
            <mat-label>{{ 'messages.massive.placeholder1' | translate}}</mat-label>
            <input id="asuntoInput" formControlName="asunto" type="text" matInput required>
        </mat-form-field>
        
        <p class="subtitle2"> {{'messages.massive.field2' | translate}}</p>
        
        <mat-form-field class="inputFullWidth2" appearance="fill">
            <mat-label>{{ 'messages.massive.placeholder2' | translate}}</mat-label>
            <textarea  maxLength={{longitudMensaje}} matInput id="mensajeInput" formControlName="mensaje" type="text" required></textarea>
            <mat-hint align="end">{{newForm.value.mensaje?.length || 0}} / {{longitudMensaje}}</mat-hint>
        </mat-form-field>

        <div class="flexRigth">
            <button 
              [disabled]="!enabled" 
              mat-raised-button color="accent" 
              class="primaryButton2" 
              (click)="onSubmit()">
              <mat-icon class="whiteIcon">send</mat-icon>
              {{ 'messages.massive.send' | translate }}
        </button>
    </div>
  </form>
</div>