import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ItemSeccion, Seccion, SeccionConItems } from '../../../../api/interfaces/sections';
import { SeccionesService } from '../../../../api/services/secciones/secciones.service';
import { MobileDetectorService } from '../../../../services/mobileDetector/mobile-detector.service';
import { SharedDataService } from '../../../../services/sharedData/shared-data.service';

/**
 * Get secciones and call CardSectionComponent to display them and ShowItemSelectedComponent to display any selected item.
 * N nutricion, T trabajo complementario
 */
@Component({
  selector: 'app-main-generic',
  templateUrl: './main-generic.component.html',
  styleUrls: ['./main-generic.component.scss']
})
export class MainGenericComponent implements OnInit, OnDestroy {

  showSection: boolean = true;
  isMobile: boolean = false;
  loaded: boolean = false;

  showSelected: boolean = true;

  secciones: SeccionConItems[] = [];
  isNutricion: boolean = false;
  tipo: string = "T";

  item: ItemSeccion;

  private sharedData$: Subscription;

  constructor(
    private mobileDetectorService: MobileDetectorService,
    private seccionesService: SeccionesService,
    private sharedData: SharedDataService,
    private activatedrouter: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    const url = this.activatedrouter.snapshot.routeConfig.path;
    if(url == 'nutricion') {
      this.tipo = 'N';
    }
    sessionStorage.setItem('tipo',this.tipo);
    sessionStorage.setItem('active', url);
    //bugfix #1920
    sessionStorage.removeItem('item');
    this.getDatos();
    this.sharedData$ = this.sharedData.currentMessage.subscribe(message => {
      if (message == "reloadSections") {
        this.secciones= [];
        this.getDatos();
      }
      if (message == "itemChanged") {
       this.unselectAll();
       // si hay un item quiero verlo en mobile, y no a las secciones
       let item = JSON.parse(sessionStorage.getItem('item'));
       if(item) {
         this.showSection = false;
       } else {
         this.showSection = true;
       }
      }
    });
  }

  unselectAll() {
    this.secciones.forEach(seccion => {
      seccion.items.forEach(item => {
        item.selected = false;
      })
    });
  }

  getDatos() {
    this.loaded = false;
    this.seccionesService.getSeccionesPorTipo(this.tipo).subscribe( response=> {
      this.secciones = response.sort(((a,b) => a.seccionOrden - b.seccionOrden));
      this.secciones.forEach(seccion => {
        seccion.items.sort(((a,b) => a.itemOrden - b.itemOrden));
      });
      this.unselectAll();
      this.loaded = true;
    },
    error => {
      console.error(error);
    });
    
  }

  public ngOnDestroy(): void {
    this.sharedData$.unsubscribe();
  }

}
