import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth.guard';
import { RoleString } from 'src/app/enums/role';

import { StepperComponent } from '../stepper/stepper.component';
import { ThanksComponent } from '../thanks/thanks.component';

const routes: Routes = [
  {
    path: 'registration',
    component: StepperComponent,
    canActivate: [AuthGuard],
    data: { roles: [RoleString.STUDENT] }
  },
  {
    path: 'thanks',
    component: ThanksComponent,
    canActivate: [AuthGuard],
    data: { roles: [RoleString.STUDENT] }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class RegistrationRoutingModule { }