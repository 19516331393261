<div class="flexGrow">

    <div
        *ngIf="isMobile"
        (click)="toggleOption(1)">
        <div class="flexLeft body3Medium">
            <mat-icon class="blackIcon">
                chevron_left
            </mat-icon>
            {{'settings.options.one' | translate}}
        </div>
    </div>

    <mat-card class="cardGroup">
        <div class="flexBeetwen">
            <p class="subtitle3" *ngIf="!isMobile"> {{'settings.groups.listCard.title' | translate}} </p>
            
            <button *ngIf="!isMobile" mat-raised-button color="accent" class="primaryButton2" (click)="toggleNew()" [disabled]="showNew || edit"> 
                {{'settings.groups.listCard.add' | translate | uppercase}}
            </button>

            <p class="headline6"  *ngIf="isMobile"> {{'settings.groups.listCard.subtitle' | translate}} </p>

            <button *ngIf="isMobile" mat-raised-button color="accent" class="mobileSmallButton" (click)="toggleNew()" [disabled]="showNew || edit"> 
                +
            </button>
        </div>
        <p class="headline6"  *ngIf="!isMobile"> {{'settings.groups.listCard.subtitle' | translate}} </p>
        <app-groups-list
            [edit]="edit"
            [showNew]="showNew">
        </app-groups-list>
    </mat-card>
    
    <app-groups-new *ngIf="showNew || edit"
        (toggleNewEvent)="toggleNew()"
        (toggleEditEvent)="toggleEdit()"
        [showNew]="showNew"
        [edit]="edit">
    </app-groups-new>
</div>
