<form class="filters" [formGroup]="filtersForm">
  
      <mat-form-field appearance="outline" class="inputsFilterLarge">
        <mat-label> {{'admin.trainersTable.filters.searchTrainer' | translate}} </mat-label>
        <input matInput type="text" formControlName="nombreApellido" >
  
        <button mat-icon-button matSuffix (click)="onSubmit()">
          <mat-icon class="azul">search</mat-icon>
        </button>
        
        <button *ngIf="filtersForm.value.nombreApellido" mat-icon-button matSuffix
        (click)="clear('nombreApellido'); $event.stopPropagation()">
          <mat-icon class="clearIcon">close</mat-icon>
        </button>
  
      </mat-form-field>
  
      <br />  
  </form>

<p class="amountUsers">{{amount}} REGISTROS</p>
<!-- <app-loading *ngIf="!loaded" [showText]="false"></app-loading> -->

<div class="noTrainers" *ngIf="loaded && noTrainers">
  <div *ngIf="!firstLoad">
    <span class="headline6Light"> {{'admin.trainersTable.noTrainers.filtersEmpty' | translate}}</span>
  </div>
  <div *ngIf="firstLoad">
    <span class="headline5"> {{'admin.trainersTable.noTrainers.dontExist' | translate}}</span>
    <br/>
    <span class="body2"> {{'admin.trainersTable.noTrainers.dontExist2' | translate}}</span>
  </div>
</div>
<div class="table-container" *ngIf="loaded && trainerData.length > 0 ">
  <table *ngIf="!isMobile" mat-table [dataSource]="trainerData">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> {{'admin.trainersTable.header.name' | translate}} </th>
      <td mat-cell *matCellDef="let trainer">
        <div class="flexLeft" (click)="navigate(trainer)">

          <app-avatar
            *ngIf="trainer.usuarioId"
            [class]="'imgRoundedTable'"
            [containerClass]=""
            [usuarioId]="trainer.usuarioId">
          </app-avatar>
          
          <div class="paddingLeftOnly">
            <span class="subtitle2">
              {{trainer.nombre}} {{trainer.apellido}}
            </span>
            <br/>
            <span class="body4">
              {{trainer.mail}}
            </span>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> {{'admin.studentsTable.header.status' | translate}} </th>
      <td mat-cell *matCellDef="let trainer">
        <div class="flexLeft">
          <app-status [showComplete]="true"  [status]="trainer.estado"></app-status>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef> <span class="flexLeft">
        {{'admin.trainersTable.header.amount' | translate}}
      </span> </th>
      <td mat-cell *matCellDef="let trainer">
        <div class="flexLeft">
          <div class="levelBox">
            <p class="subtitle2Grey">{{trainer.cantAlumnos}}</p> 
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions" stickyEnd>
      <th mat-header-cell *matHeaderCellDef> {{'admin.trainersTable.header.actions' | translate}}</th>
      <td mat-cell *matCellDef="let trainer">
        <div class="flexActions">
          <button mat-icon-button (click)="navigate(trainer)">
            <mat-icon class="azul">visibility</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let trainer; columns: displayedColumns;" class="student-element-row"
      [class.student-expanded-row]="trainer.isExpanded"></tr>
  </table>
  
  <table *ngIf="isMobile" mat-table [dataSource]="trainerData">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> {{'admin.trainersTable.header.name' | translate}} </th>
      <td mat-cell *matCellDef="let trainer">
        <div class="flexLeft" (click)="navigate(trainer)">

          <app-avatar
            *ngIf="trainer.usuarioId"
            [class]="'imgRoundedTable'"
            [containerClass]=""
            [usuarioId]="trainer.usuarioId">
          </app-avatar>
          
          <div class="paddingLeftOnly">
            <span class="subtitle2">
              {{trainer.nombre}} {{trainer.apellido}}
            </span>
            <br/>
            <span class="body4">
              {{trainer.mail}}
            </span>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions" stickyEnd>
      <th mat-header-cell *matHeaderCellDef> {{'admin.trainersTable.header.actions' | translate}}</th>
      <td mat-cell *matCellDef="let trainer">
        <div class="flexActions">
          <button mat-icon-button (click)="navigate(trainer)">
            <mat-icon class="azul">visibility</mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsMobile"></tr>
    <tr mat-row *matRowDef="let trainer; columns: displayedColumnsMobile;" class="student-element-row"
      [class.student-expanded-row]="trainer.isExpanded"></tr>
  </table>

</div>

<app-paginator-complete
    *ngIf="loaded"
    [first]="first"
    [last]="last"
    [page]="page"
    [totalPages]="totalPages"
    [size]="size"
    [totalElements]="totalPerPage"
    (loadMoreEvent)="loadMore($event)">
</app-paginator-complete>