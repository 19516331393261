import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DatoPorEtiqueta, FormularioDia, PlanDetalle } from '../../../../api/interfaces/plans';

@Component({
  selector: 'app-day-form',
  templateUrl: './day-form.component.html',
  styleUrls: ['./day-form.component.scss']
})
export class DayFormComponent implements OnInit {

  @Output() notValidEvent = new EventEmitter<boolean>();
  @Output() editFormEvent = new EventEmitter<FormularioDia>();

  @Input('planDetalleEtiqueta') planDetalleEtiqueta: DatoPorEtiqueta [];
  @Input('detalles') detalles: PlanDetalle[];
  @Input('edit') edit: boolean;

  planForm = new FormGroup({
    fase1: new FormControl('', Validators.required),
    fase2: new FormControl('', Validators.required),
    fase3: new FormControl('', Validators.required),
    invisible: new FormControl('', Validators.required),
    especificaciones: new FormControl('', Validators.required)
  });
  constructor(
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.cdr.detectChanges();
    this.planForm.statusChanges
    .subscribe(val => {
      const { fase1, fase2, fase3, invisible, especificaciones } = this.planForm.getRawValue();
      if(this.planForm.valid) {
        this.editFormEvent.emit(this.planForm.value);
      } else {
        this.notValidEvent.emit(false);
      }
    });
    
    if(this.edit) {
      let index: number = this.detalles.findIndex(detalle => detalle.planDetalleTitulo == "Fase 1");
      this.planForm.patchValue({fase1: this.detalles[index].actividad});

      index = this.detalles.findIndex(detalle => detalle.planDetalleTitulo == "Fase 2");
      this.planForm.patchValue({fase2: this.detalles[index].actividad});

      index = this.detalles.findIndex(detalle => detalle.planDetalleTitulo == "Fase 3");
      this.planForm.patchValue({fase3: this.detalles[index].actividad});

      index = this.detalles.findIndex(detalle => detalle.planDetalleTitulo == "Entrenamiento Invisible");
      
      let aux = this.detalles[index].actividad;
      //todo: parsear aca entrenamiento invisible o en base de datos?
      // aux = aux.replace(/ -/g,'\r - ');

      this.planForm.patchValue({invisible: aux});

      index = this.detalles.findIndex(detalle => detalle.planDetalleTitulo == "Especificaciones del Entrenamiento");
      this.planForm.patchValue({especificaciones: this.detalles[index].actividad});
    } else {
      let index: number = this.planDetalleEtiqueta.findIndex(detalle => detalle.clave == "Fase 1");
      this.planForm.patchValue({fase1: this.planDetalleEtiqueta[index].valor});

      index = this.planDetalleEtiqueta.findIndex(detalle => detalle.clave == "Fase 2");
      if(index != -1) {
        this.planForm.patchValue({fase2: this.planDetalleEtiqueta[index].valor});
      }

      index = this.planDetalleEtiqueta.findIndex(detalle => detalle.clave == "Fase 3");
      this.planForm.patchValue({fase3: this.planDetalleEtiqueta[index].valor});

      index = this.planDetalleEtiqueta.findIndex(detalle => detalle.clave == "Entrenamiento Invisible");
      let aux = this.planDetalleEtiqueta[index].valor;
      //todo: parsear aca entrenamiento invisible o en base de datos?
      // aux = aux.replace(/ -/g,'\r - ');
      // aux = " - "+aux;
      this.planForm.patchValue({invisible: aux});

      index = this.planDetalleEtiqueta.findIndex(detalle => detalle.clave == "Especificaciones del Entrenamiento");
      this.planForm.patchValue({especificaciones: this.planDetalleEtiqueta[index].valor});
    }
  }

}
