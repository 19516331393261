import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { MessageListOption, Message, MassiveMessage, CanalesPageable, Canal, MensajesPageable, EnviarMensaje, Mensaje, MensajesPendientes } from '../../interfaces/messages';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {

  private url = environment.apiUrl + '/';

  httpOptionsJson = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }),
    withCredentials: true,
  };

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json'
    }),
    withCredentials: true,
  };
  multipartOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'multipart/form-data'
    })
  };
  constructor(private http: HttpClient) { }

  getNewMessages(): Observable<boolean> {
    return this.http.get<boolean>(this.url + `api/mensajes/nuevo`,  this.httpOptionsJson);
  }

  setUnreadRead(canalId: number): Observable<any> {
    let body = {
      algo: "algo"
    }
    return this.http.patch<any>(this.url + `api/canales/${canalId}`,  body,this.httpOptionsJson);
  }

  getUnreadMessages(cantidadPorPagina:number, pagina: number): Observable<MessageListOption[]> {
    return this.http.get<MessageListOption[]>(this.url + `api/mensajes/listado?pag=${pagina}&reg=${cantidadPorPagina}`,  this.httpOptions);
  }

  getConversation(cantidadPorPagina:number, pagina: number, idConversacion: number): Observable<Message[]> {
    return this.http.get<Message[]>(this.url + `api/mensajes/listado?idConversacion=${idConversacion}&pag=${pagina}&reg=${cantidadPorPagina}`,  this.httpOptions);
  }

  getTodosMensajesMasivos(size: number, page: number): Observable<MensajesPageable> {
    return this.http.get<MensajesPageable>(this.url + `api/masivos/mensajes?size=${size}&page=${page}`,  this.httpOptions);
  }

  getUltimoMensajeMasivo(): Observable<Mensaje> {
    return this.http.get<Mensaje>(this.url + `api/masivos`,  this.httpOptions);
  }

  sendMasivoFiltros(mensaje: EnviarMensaje, nivelId: number, grupoId: number): Observable<Mensaje> {
    return this.http.post<Mensaje>(this.url + `api/masivos/mensajes?nid=${nivelId}&gid=${grupoId}`, mensaje, this.httpOptionsJson);
  }

  sendMasivoFiltrosDinamicos(mensaje: EnviarMensaje, nid: string, gid: string, tid: string): Observable<Mensaje> {
    return this.http.post<Mensaje>(this.url + `api/masivos/mensajes?${gid}&${nid}&${tid}`, mensaje, this.httpOptionsJson);
  }

  sendMasivoCobranzas(mensaje: EnviarMensaje, gid: string, tid: string, remesaId: number): Observable<Mensaje> {
    return this.http.post<Mensaje>(this.url + `api/masivos/mensajes/cobranzas?${gid}&${tid}&remesaId=${remesaId}`, mensaje, this.httpOptionsJson);
  }

  sendMasivoSinFiltros(mensaje: EnviarMensaje): Observable<Mensaje> {
    return this.http.post<Mensaje>(this.url + `api/masivos/mensajes`, mensaje, this.httpOptionsJson);
  }

  deleteSuspension(suspensionId: number): Observable<any> {
    return this.http.delete<any>(this.url + `api/alumnos/suspensiones?suspensionId=${suspensionId}`,  this.httpOptionsJson);
  }

  getCantidadNoLeidos(): Observable<MensajesPendientes> {
    return this.http.get<MensajesPendientes>(this.url + `api/mensajesPendientes`,  this.httpOptions);
  }

  //endpoints reales

  /**
   * 
   * @param page {number} page
   * @param size {size} amount of canals per page
   * @returns array Canales and pageable data
   * @interface CanalesPageable
   */
  getCanalesYMensajes(page: number, size: number, sinLeer: boolean): Observable<CanalesPageable> {
    return this.http.get<CanalesPageable>(this.url + `api/canales?page=${page}&size=${size}&sinLeer=${sinLeer}`,  this.httpOptions);
  }

  /**
   * Get canal's data
   * @param canalId number, id del canal
   * @returns interface Canal
   */
  getCanal(canalId: number): Observable<Canal> {
    return this.http.get<Canal>(this.url + `api/canales/${canalId}`,  this.httpOptions);
  }

  getMensajesPorCanal(canalId: number,size: number, page: number): Observable<MensajesPageable> {
    return this.http.get<MensajesPageable>(this.url + `api/canales/${canalId}/mensajes?size=${size}&page=${page}`,  this.httpOptions);
  }

  postMensaje(canalId: number, enviarMensaje: EnviarMensaje): Observable<any> {
    return this.http.post<any>(this.url + `api/canales/${canalId}/mensajes`, enviarMensaje, this.httpOptionsJson);
  }

  /**
   * Get canal's data, associated with user id
   * @param usuarioId number, user's id
   * @returns interface CanalesPageable
   */
  getCanalUsuarioId(usuarioId: number): Observable<CanalesPageable> {
    return this.http.get<CanalesPageable>(this.url + `api/usuarios/${usuarioId}/canales`,  this.httpOptions);
  }

  searchCanalsPageable(ape: string, page: number, size: number): Observable<CanalesPageable> {
    return this.http.get<CanalesPageable>(this.url + `api/usuarios/canales?ape=${ape}&page=${page}&size=${size}`,  this.httpOptions);
  }

}
