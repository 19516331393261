<app-header [registration]="true"></app-header>
<div class="backgroundGrey" *ngIf="loaded">
  <mat-stepper (selectionChange)="onSelectionChange($event)" labelPosition="right" linear #stepper>
    <!-- datos personales -->
    <mat-step *ngIf="pasosHabilitados.informacionPersonal" [stepControl]="firstFormGroup" >
      <ng-template matStepLabel>
        <p class="stepperLabel">
          {{ 'registration.steps.one' | translate}}
        </p>
      </ng-template>
      <p *ngIf="isMobile" class="body3Ligth">{{'registration.header' | translate}}</p>
      <app-step-one 
        [cantidadPasosHabilitados]="cantidadPasosHabilitados">
      </app-step-one>
    </mat-step>

    <!-- grupos de entrenamiento -->
    <mat-step *ngIf="pasosHabilitados.grupoEntrenamiento" [stepControl]="secondFormGroup" >
      <ng-template matStepLabel>
        <p class="stepperLabel">
          {{ 'registration.steps.two' | translate}}
        </p>
      </ng-template>
      <p *ngIf="isMobile" class="body3Ligth">{{'registration.header' | translate}}</p>
      <app-step-two
        [cantidadPasosHabilitados]="cantidadPasosHabilitados"
        [posicion]="posicionGrupoEntrenamiento">
      </app-step-two>
    </mat-step>

    <!-- antecedentes, formulario dinamico 2670 -->
    <mat-step *ngIf="pasosHabilitados.antecedentes" [stepControl]="thirdFormGroup">
      <ng-template matStepLabel>
        <p class="stepperLabel">
          {{ 'registration.steps.three' | translate}}
        </p>
      </ng-template>
      <p *ngIf="isMobile" class="body3Ligth">{{'registration.header' | translate}}</p>
      <app-step-three
        [cantidadPasosHabilitados]="cantidadPasosHabilitados"
        [posicion]="posicionAntecedentes">
      </app-step-three>
    </mat-step>

    <!-- marcas y objetivos, 2670 -->
    <mat-step *ngIf="pasosHabilitados.marcasObjetivos" [stepControl]="objetivesAchievementsFormGroup">
      <ng-template matStepLabel>
        <p class="stepperLabel">
          {{ 'registration.steps.achievementsObjetives' | translate}}
        </p>
      </ng-template>
      <p *ngIf="isMobile" class="body3Ligth">{{'registration.header' | translate}}</p>
      <app-step-achievements-objetives 
        [cantidadPasosHabilitados]="cantidadPasosHabilitados"
        [posicion]="posicionMarcasObjetivos">
      </app-step-achievements-objetives>
    </mat-step>

    <!-- datos bancarios -->
    <mat-step *ngIf="pasosHabilitados.bancario" [stepControl]="fourthFormGroup">
      <ng-template matStepLabel>
        <p class="stepperLabel">
          {{ 'registration.steps.four' | translate}}
        </p>
      </ng-template>
      <p *ngIf="isMobile" class="body3Ligth">{{'registration.header' | translate}}</p>
      <app-step-four
        [cantidadPasosHabilitados]="cantidadPasosHabilitados"
        [posicion]="posicionBancario">
      </app-step-four>
    </mat-step>

     <!-- paso final -->
     <mat-step>
      <ng-template matStepLabel>
        <p class="stepperLabel">
          {{ 'registration.steps.end' | translate}}
        </p>
      </ng-template>
      <p *ngIf="isMobile" class="body3Ligth">{{'registration.header' | translate}}</p>

      <app-step-end
        [cantidadPasosHabilitados]="cantidadPasosHabilitados"
        [posicion]="posicionEnd"
        
        [posicionStepOne]="'1'"
        [posicionGrupoEntrenamiento]="posicionGrupoEntrenamiento"
        [posicionAntecedentes]="posicionAntecedentes"
        [posicionMarcasObjetivos]="posicionMarcasObjetivos"
        [posicionBancario]="posicionBancario"
        (changeCurrentStepEvent)="changeCurrentStep($event)" >
      </app-step-end>
    </mat-step>

  </mat-stepper>
</div>