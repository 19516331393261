export enum TrainingTypeEnum {
    PRESENTIAL = 0,
    ONLINE = 1
}

export enum trainingDaysEnum {
    ADITIONAL = 'A',
    IN_GROUP = 'P',
    NULL= 'N',
    DESCANSO = 'D'
}

export enum DescansoEnum {
    DESCANSO = 'Descanso'
}

export enum TrainingGroupEnum {
    noDefinido = "No definido",
    online = "Online"
}