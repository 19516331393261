import { Component, OnInit, Input } from '@angular/core';
import { StudentService } from 'src/app/services/admin/student/student.service';
import { RolService } from 'src/app/services/rol/rol.service';
import { Antecedente } from '../../../../api/interfaces/register';

@Component({
  selector: 'app-background',
  templateUrl: './background.component.html',
  styleUrls: ['./background.component.scss']
})
export class BackgroundComponent implements OnInit {

  antecedente:  Antecedente[] = [];
  isStudent: boolean = false;

  length: number = 0;

  constructor(
    private studentService: StudentService,
    private rolService: RolService
  ) { }

  ngOnInit(): void {  
    
    this.isStudent = this.rolService.isStudent();
    this.loadData();
  }

  loadData() {
    const id: number = !this.isStudent ? parseInt(sessionStorage.getItem('id_usuario'),10) : parseInt(localStorage.getItem('idAlumno'),10) ;
    this.studentService.getAntecedentes(id).subscribe(response => {
      this.antecedente = response;
      this.length = this.antecedente.length;
    },
    error => {
      console.error(error);
    })
  }

}
