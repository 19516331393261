<app-title 
    [personal]="true" 
    [estado]="estado" 
    [disable]="disable" 
    [valid]="userForm.valid" 
    (onSubmitEvent)='onSubmit()' 
    [valid]="userForm.valid" 
    (editEvent)='edit()'>
</app-title>

<form [formGroup]="userForm">
    <mat-form-field class="inputTwoLine" appearance="fill">
        <mat-label>{{ 'forms.user.name' | translate}}</mat-label>
        <input required [attr.disabled]="disable == true ? true : null"  id="nameInput" formControlName="nombre" type="text" matInput>
    </mat-form-field>

    <mat-form-field class="inputTwoLine" appearance="fill">
        <mat-label>{{ 'forms.user.lastName' | translate}}</mat-label>
        <input required [attr.disabled]="disable == true ? true : null" id="lastNameInput" formControlName="apellido" type="text" matInput>
    </mat-form-field>

    <mat-form-field class="inputTwoLine" appearance="fill">
        <mat-label>{{ 'forms.user.secondLastname' | translate}}</mat-label>
        <input [attr.disabled]="disable == true ? true : null" id="secondlastNameInput" formControlName="segundoApellido" type="text" matInput>
    </mat-form-field>
    
    <mat-form-field class="inputTwoLine" appearance="fill">
        <mat-label>{{ 'forms.user.birthDate' | translate}}</mat-label>
        <input disabled id="birthDateInput" matInput [max]="maxDate" formControlName="fechaNacimiento" [matDatepicker]="picker" required>
        <mat-datepicker-toggle class="primaryIcon" matSuffix [for]="picker" ></mat-datepicker-toggle>
        <mat-datepicker [disabled]="disable == true ? true : null" #picker>
            <mat-datepicker-actions>
                <button mat-button matDatepickerCancel>{{ 'calendar.cancel' | translate}}</button>
                <button mat-raised-button color="accent" matDatepickerApply>{{ 'calendar.save' | translate}}</button>
              </mat-datepicker-actions>
        </mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="fill" class="inputTwoLine">
        <mat-label>{{ 'forms.user.documentType' | translate}}</mat-label>
        <mat-select 
            required 
            [disabled]="disable" 
            id="documentTypeInput" 
            formControlName="documentoTipo">
                <mat-option  
                    *ngFor="let documento of documentos" 
                    [value]="documento.documento">
                    {{documento.documento}}
                </mat-option>
          </mat-select>
    </mat-form-field>

    <mat-form-field class="inputTwoLine" appearance="fill">
        <!-- todo: revisar documento, si no funciona reemplazar label con  userForm.value.documentoTipo -->
        <mat-label>{{ (personalData.documentoTipo && personalData.documentoTipo.documento) ? personalData.documentoTipo.documento : userForm.value.documentoTipo.documento}}</mat-label>
        <input required [attr.disabled]="disable == true ? true : null" id="dniInput" formControlName="numeroDocumento" type="text" matInput>
    </mat-form-field>

    <mat-form-field appearance="fill" class="inputTwoLine">
        <mat-label>{{'forms.user.country' | translate}}</mat-label>
        <mat-select 
            required 
            [disabled]="disable" 
            id="countryInput"
            formControlName="pais">
                <mat-option  
                    *ngFor="let country of countrys" 
                    [value]="country.pais">
                    {{country.pais}}
                </mat-option>
          </mat-select>
    </mat-form-field>
    
    <mat-form-field appearance="fill" class="inputTwoLine">
        <mat-label>{{ 'forms.user.residence' | translate}}</mat-label>
        <input required [attr.disabled]="disable == true ? true : null" id="residenceInput" formControlName="residencia" type="text" matInput required>
    </mat-form-field>
</form>