import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { FormularioDinamicoPregunta, TipoEntrenamientoDinamico } from '../../interfaces/formulario-dinamico';

@Injectable({
  providedIn: 'root'
})
export class FormsDinamicService {

  private url = environment.apiUrl + '/';

  httpOptionsJson = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }),
    withCredentials: true,
  };

  constructor(private http: HttpClient) { }

  postRespuestas(formularioDinamicoPregunta: FormularioDinamicoPregunta[]): Observable<any> {
    return this.http.post<any>(this.url + 'api/respuestas', formularioDinamicoPregunta, this.httpOptionsJson);
  }

  getPreguntas(): Observable<FormularioDinamicoPregunta[]> {
    return this.http.get<FormularioDinamicoPregunta[]>(this.url + `AntecedentesPregunta/getPreguntas`,  this.httpOptionsJson);
  }

  getTipoEntrenamientoDinamico(): Observable<TipoEntrenamientoDinamico[]> { 
    return this.http.get<TipoEntrenamientoDinamico[]>(this.url + 'unaUrlParaDefinir', this.httpOptionsJson);
  }
}
