<mat-card *ngIf="loaded">
    <form [formGroup]="planForm" class="feedbackFlex">
        <div>
            <div class="headline6Primary">{{fechaFeedback | date:'dd/MM/yyy'}}</div>
            <br />

            <div *ngIf="!edit">
                <div *ngFor="let dato of datos">
                    <!-- todo: parsear aca el entrenamiento invisible? -->
                    <div [class]="dato.clase">{{dato.planDetalleTitulo}}</div>
                    <div class="body2" [innerHTML]="dato.actividad"></div>
                    <br /><br />
                </div>
                <div *ngIf="!datos || datos.length == 0">
                    <p class="subtitle1">Éste día aún no registra actividades. </p>
                </div>
            </div>

            <form [formGroup]="dayForm" *ngIf="edit">
                <div class="firstColumn">
                    <span class="headline6">{{'planes.forms.description' | translate}}</span>
                    <br />

                    <span class="subtitle3Blue">{{'planes.forms.fase1' | translate}}</span>
                    <br />
                    <mat-form-field appearance="fill" class="inputPlan">
                        <textarea matInput id="prefix1Input" formControlName="fase1" type="text"></textarea>
                    </mat-form-field>
                    <br />

                    <span class="subtitle3Blue">{{'planes.forms.fase2' | translate}}</span>
                    <br />
                    <mat-form-field appearance="fill" class="inputPlan">
                        <textarea matInput id="prefix1Input" formControlName="fase2" type="text"></textarea>
                    </mat-form-field>
                    <br />

                    <span class="subtitle3Blue">{{'planes.forms.fase3' | translate}}</span>
                    <br />
                    <mat-form-field appearance="fill" class="inputPlan">
                        <textarea matInput id="prefix1Input" formControlName="fase3" type="text"></textarea>
                    </mat-form-field>
                    <br />
                </div>

                <div>
                    <span class="subtitle3">{{'planes.forms.invisible' | translate}}</span>
                    <br />
                    <mat-form-field appearance="fill" class="inputPlan">
                        <textarea matInput id="prefix1Input" formControlName="invisible" type="text"></textarea>
                    </mat-form-field>
                    <br />

                    <span class="subtitle3">{{'planes.forms.especificaciones' | translate}}</span>
                    <br />
                    <mat-form-field appearance="fill" class="inputPlan">
                        <textarea matInput id="prefix1Input" formControlName="especificaciones" type="text"></textarea>
                    </mat-form-field>
                    <br />
                </div>



            </form>
        </div>

        <div class="secondColumnBorderLeft" *ngIf="datos || isAdmin">
            <br />
            <div class="headline5" *ngIf="isStudent">{{'planes.feedback.status' | translate}}</div>
            <div class="headline5" *ngIf="!isStudent">{{'planes.feedback.statusAdmin' | translate}}</div>
            <br />

            <app-feedback-option *ngIf="canGiveFeedback && !hasFeedback" [disabled]="false" [option]="planForm.value.feedbackOpcion" (saveButtonEvent)="saveButton($event)"></app-feedback-option>           
            <app-feedback-option *ngIf="(!canGiveFeedback || hasFeedback)" [disabled]="true" [option]="planForm.value.feedbackOpcion" (saveButtonEvent)="saveButton($event)"></app-feedback-option>      
            
            <div class="body2" *ngIf="!canGiveFeedback && future && !hasFeedback">{{'planes.feedback.pastEmpty' | translate}}</div>

            <br />
            <div *ngIf="isStudent">
                <div *ngIf="planForm.value.feedbackOpcion == 1 || planForm.value.feedbackOpcion == 2" class="headline6">
                    {{'planes.feedback.happened' | translate}} <span *ngIf="!hasFeedback">*</span>
                </div>
                    <div *ngIf="planForm.value.feedbackOpcion == 3 || planForm.value.feedbackOpcion == 4" class="headline6">
                        <span *ngIf="!noComments">
                            {{'planes.feedback.comments' | translate}}
                        </span>
                </div>
                <br />
            </div>
            <div *ngIf="planForm.value.feedbackOpcion && !isStudent && respuesta" class="headline6"> {{'planes.feedback.commentsAdmin' | translate}} </div>

            <mat-form-field *ngIf="planForm.value.feedbackOpcion && isStudent && !respuesta &&!noComments" appearance="fill" class="inputPlan">
                <mat-label>Escribe aquí</mat-label>
                <textarea matInput id="prefix1Input" formControlName="feedbackRespuesta" type="text" maxlength = {{longitudFeedback}}></textarea>
                <mat-hint align="end">{{planForm.value.feedbackRespuesta?.length || 0}} / {{longitudFeedback}}</mat-hint>
            </mat-form-field>

            <br/>
            <div *ngIf="(planForm.value.feedbackOpcion && respuesta) || noComments" class="flexLeft">
                
                <img *ngIf="!avatarUrl && loadedAvatar" class="imgRoundedTable marginRigthOnly" src="./../../../assets/user.svg">
                <img *ngIf="avatarUrl && loadedAvatar" class="imgRoundedTable marginRigthOnly" [src]="avatarUrl | secure | async">
                <app-skeleton *ngIf="!loadedAvatar" [class]="'imgRoundedTable marginRigthOnly'"></app-skeleton>


                <p class="body2" *ngIf="planForm.value.feedbackOpcion && respuesta">{{respuesta}}</p>

                <p class="body2" *ngIf="(isAdmin || isTrainer) && noComments">{{ 'planes.forms.noCommentsAdmin' | translate}}</p>
                <p class="body2" *ngIf="isStudent && noComments">{{ 'planes.forms.noCommentsStudent' | translate}}</p>
            </div>


            <br />

            <div class="flexRigth" *ngIf="canGiveFeedback">
                <button *ngIf="canSave" (click)="onSubmit()" mat-raised-button color="accent" class="primaryButton2"
                    [disabled]="!planForm.valid || respuestaRequired">
                    <mat-icon class="whiteIcon">save</mat-icon>
                    {{ 'planes.forms.save' | translate | uppercase }}
                </button>
            </div>
            
            
        </div>

    </form>
</mat-card>
<div class="flexRigth" *ngIf="edit && loaded">
    <button (click)="onSubmitDayChanges()" mat-raised-button color="accent" class="primaryButton2"
        [disabled]="!dayForm.valid">
        <mat-icon class="whiteIcon">save</mat-icon>
        {{ 'planes.forms.save' | translate | uppercase }}
    </button>
</div>