<div *ngIf="estado == 'N'" class="inlineFlex">
    <mat-icon id="editButton" class="noCobradoIcono">
        warning
    </mat-icon>
    <span class="subtitle2 noCobrado"> {{'status.N' | translate}}</span>
</div>

<div *ngIf="estado == 'E'" class="inlineFlex">
    <mat-icon id="editButton" class="cobradoIcono">
        check_circle
    </mat-icon>
    <span class="subtitle2 cobrado"> {{'status.E' | translate}}</span>
</div>
