import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { SelectedMode, User, DatosBancario, PasosHabilitados } from '../../../api/interfaces/register';
import { RegisterService } from '../../../api/services/register/register.service';
import { MobileDetectorService } from '../../../services/mobileDetector/mobile-detector.service';
import { SharedDataService } from '../../../services/sharedData/shared-data.service';
import { SnackbarService } from '../../../utils/snackbar/snackbar.service';
import { StepperEnum } from 'src/app/enums/stepper';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss']
})
export class ButtonsComponent implements OnInit, OnDestroy {

  private sharedData$: Subscription;

  @Input('end') end?: boolean;
  @Input('disabled') disabled?: boolean;
  @Input('bank') bank?: DatosBancario;
  @Input('mode') mode?: SelectedMode;
  @Input('user') user?: User;
  @Input('antecendentesRespuesta') antecendentesRespuesta?: any;
  @Input('enablePrevious') enablePrevious?: boolean;
  @Input('objetivesAchievements') objetivesAchievements?: boolean;

  pasosHabilitados: PasosHabilitados;

  isMobile: boolean = false;
  ALUMNO_ID: number;

  constructor(
    private router: Router,
    private registerService: RegisterService,
    private snack: SnackbarService,
    private sharedData: SharedDataService,
    private mobileDetectorService: MobileDetectorService,
  ) { 
   
  }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    this.ALUMNO_ID = parseInt(localStorage.getItem('idAlumno'),10);

    this.sharedData$ = this.sharedData.currentMessage.subscribe(message => {
      if( message === StepperEnum.SAVE_ALL_STEPS_DATA) {
          this.triggerAllUpdates();
        }
    });
  }

  /**
   * Ticket 3349. This function is called only when user change step to stepEnd, using the if inside subscribe
   * Call saveStep functions to update every available step.
   */
  triggerAllUpdates() {
    if(this.user) {
      this.saveStep1();
    }
    if(this.mode) {
      this.saveStep2();
    }
    if(this.antecendentesRespuesta) {
      this.saveStep3();
    }
    if(this.bank) {
      this.saveStep4();
    }
    if(this.objetivesAchievements) {
      this.saveStepObjAch();
    }
  }

  /**
   * Save data from step1 in sessionStorage and triggers functions in stepper.component.ts to allow user to continue.
   */
  saveStep1() {
    sessionStorage.setItem('user', JSON.stringify(this.user));
    this.sharedData.changeMessage(StepperEnum.SAVE_STEP1);
  }

   /**
   * Save data from step2 in sessionStorage and triggers functions in stepper.component.ts to allow user to continue.
   */
  saveStep2() {
    sessionStorage.setItem('mode', JSON.stringify(this.mode));
    this.sharedData.changeMessage(StepperEnum.SAVE_STEP2);
  }

   /**
   * Triggers functions in stepper.component.ts to allow user to continue from step3.
   */
  saveStep3() {
    this.sharedData.changeMessage(StepperEnum.SAVE_STEP3);
  }

   /**
   * Save data from step4 in sessionStorage and triggers functions in stepper.component.ts to allow user to continue.
   */
  saveStep4() {
    sessionStorage.setItem('bank', JSON.stringify(this.bank));
    this.sharedData.changeMessage(StepperEnum.SAVE_STEP4);
  }

 /**
   * Triggers functions in stepper.component.ts to allow user to continue from step Objetives Achievements
   */
  saveStepObjAch() {
    this.sharedData.changeMessage(StepperEnum.SAVE_STEP_OBJ_ACH);
  }

  /**
   * save data in sessionStorage and changeMessage from sharedDataService
   */
  async save() {
    if(this.user) {
      this.saveStep1();
    }
    
    if(this.mode) {
      this.saveStep2();
      
    }
    if(this.antecendentesRespuesta) {
      this.saveStep3();
    }

    if(this.bank) {
      this.saveStep4();
    }

    //app-objetives-component and app-achievements-component save the data in sessionStorage
    if(this.objetivesAchievements) {
      this.saveStepObjAch();
    }
  }

  /**
   * Envia a backend los datos creados por step-end-component
   */
  onSubmit() {
    const data = JSON.parse(sessionStorage.getItem('data'));

    this.registerService.registerUser(data, this.ALUMNO_ID)
      .subscribe(response => {
          this.router.navigate(['/thanks']);
        },
        error => {
          console.error(error);
          this.snack.snackTranslate('snacks.error.generic');
        });
  }

  public ngOnDestroy(): void {
    this.sharedData$.unsubscribe();
  }
  
}