<div class="flexLeftBaseline">

    <p class="headline6" *ngIf="personal">{{ 'profile.data.personal' | translate }}</p>
    <p class="headline6" *ngIf="bank">{{ 'profile.data.bank' | translate }}</p>
    <p class="headline6" *ngIf="contact">{{ 'profile.data.contact' | translate }}</p>
    <p class="headline6" *ngIf="physiological">{{ 'profile.data.physiological' | translate }}</p>
    <p class="headline6" *ngIf="background">{{ 'registration.stepThree.background' | translate}}</p>
    
    


    <button *ngIf="disable && showEdit" mat-button color="primary" class="editButton" (click)="edit()">
        <mat-icon>edit</mat-icon> <span *ngIf="!isMobile">{{'profile.data.edit' | translate }} </span>
    </button>
    <button *ngIf="!disable" [disabled]="!valid" mat-button color="primary" class="editButton" (click)="onSubmit()">
        <mat-icon *ngIf="!valid" class="greyIcon">save</mat-icon>
        <mat-icon *ngIf="valid">save</mat-icon>  
        {{'profile.data.save' |translate }}
    </button>
</div>
