<div *ngIf="isAdmin && status == 'P'" class="pendingContainer">
    <p class="body2White">{{'status.textPending' | translate}}</p>
</div>
<br />
<div class="back" *ngIf="isAdmin ">
    <app-navigation-button [isAdminOrTrainer]="false" [path]="'/admin/alumnos'"></app-navigation-button>
</div>

<div class="back" *ngIf="isAdminOrTrainer && !isTrainer && showBack">
    <app-navigation-button [isAdminOrTrainer]="true" [path]="'admin/entrenadores'"></app-navigation-button>
</div>

<div class="back" *ngIf="isTrainer && showBack">
    <app-navigation-button [isAdminOrTrainer]="false" [path]="'entrenador/alumnos'"></app-navigation-button>
</div>


<div class="back" *ngIf="(isTrainer && !showBack && !isMobile) || (isAdminOrTrainer && !isTrainer && !showBack)">
    <span class="headline4">{{ 'profile.header.myProfile' | translate}}</span>
   
</div>


<form [formGroup]="userForm">
    <mat-grid-list *ngIf="!isMobile" cols="5" rowHeight="200px" class="grid1500">
        <mat-grid-tile class="tile200">
            <!-- <div class="uploadDivProfile">
                <label *ngIf="!userHasImage" for="file-upload">
                    <div class="upload">
                        <img *ngIf="!avatarUrl" class="imgRounded" src="../../../../assets/user.svg">
                    </div>
                </label>
                <label *ngIf="avatarUrl" for="file-upload">
                    <img  class="imgRounded" [src]="avatarUrl  | secure | async">

                </label>
                <input *ngIf="!isAdmin" accept="image/*" required id="file-upload" type="file" (change)="selectFiles($event)" />
            </div> -->
            <app-avatar
                *ngIf="usuarioId"
                [class]="'imgRounded'"
                [containerClass]="'uploadDivProfile'"
                [usuarioId]="usuarioId"
                [uploadAvailable]="true"
                [showCameraIcon]="false">
            </app-avatar>
        </mat-grid-tile>

        <mat-grid-tile class="tileWithName">
            <div class="tileContentLeft">
                <br/>
                <p class="headline5">{{personalData.nombre}} {{personalData.apellido}} {{personalData.segundoApellido}}</p>
                <div *ngIf="!isAdminOrTrainer" class="flexLeftBaseline">
                    <p class="subtitle2">{{ 'profile.header.trainer' | translate}}: </p>

                    <button *ngIf="isAdmin" mat-button class="menuButton"
                        #trainerSelect id="trainerInput"
                        [disabled]=" status == 'C' || status == 'I' || status == 'S'" [matMenuTriggerFor]="menu" class="menuButton">
                        <span class="subtitle2" id="trainer">
                            {{userForm.value.trainer.entrenadorNombre ? (userForm.value.trainer.entrenadorNombre | reduceText: 17) : ( userForm.value.trainer  | reduceText: 17)}}
                        </span>

                        <mat-icon class="iconMenu" *ngIf="isAdmin && (status == 'P' || status == 'A') ">
                            edit
                        </mat-icon>

                        <mat-icon class="iconMenu greyIcon" *ngIf="(isAdmin && status == 'C') || (isAdmin && status == 'I') ">
                            edit 
                        </mat-icon>
                    </button>

                    <span *ngIf="isStudent  || isTrainer" class="subtitle2" id="trainer">
                        {{userForm.value.trainer.entrenadorNombre ? userForm.value.trainer.entrenadorNombre : userForm.value.trainer}}
                    </span>
                    <mat-menu #menu="matMenu">
                        <div *ngFor="let trainer of trainers">
                        <button (click)="saveTrainer(trainer)" mat-menu-item>
                            <span class="body4">{{trainer.entrenadorNombre }}</span>
                        </button>
                        </div>
                    </mat-menu>
                </div>

                <div class="flexLeftBaseline"  *ngIf="!isStudent&& !isAdminOrTrainer">
                    <p class="subtitle2">{{ 'profile.header.level' | translate}}: </p>
                    <app-level 
                        [level]="userForm.value.level"
                        [edit]="isAdmin"
                        [levels]="levels"
                        [isAdmin]="isAdmin"
                        [userForm]="userForm"
                        [status]="status"
                        (saveLevelEvent)="saveLevel($event)">
                    </app-level>
                </div>

                <div class="flexLeftBaseline" *ngIf="isStudent">
                    <div class="adjustTop">
                        <p class="subtitle2">{{ 'profile.header.status' | translate}}: </p>
                    </div>

                    <app-status 
                        [showComplete]="true" 
                        [status]="status">
                    </app-status>
                </div>

                <!-- trainer profile -->

                <div *ngIf="isAdminOrTrainer" class="flexBeetwenBaseline">
                    <p class="subtitle2">{{ 'profile.header.amount' | translate}} {{cantAlumnos}}</p>
                </div>

                <div *ngIf="isAdminOrTrainer" class="flexBeetwenBaseline">
                    <app-status 
                        [showComplete]="true" 
                        [status]="status">
                    </app-status>
                </div>
            </div>
        </mat-grid-tile>

        <mat-grid-tile *ngIf="!isAdminOrTrainer" class="tile500">
            <div>
                <div class="flexLeft" id="center">
                    <p class="subtitle2">{{ 'profile.header.type' | translate}}: </p>
                    <app-training-type 
                        [training]="datosTraining"
                        [edit]="isAdmin"
                        [groups]="groups"
                        [isAdmin]="isAdmin"
                        [userForm]="userForm"
                        [status]="status"
                        (saveGroupEvent)="saveGroup($event)">
                    </app-training-type>
                </div>
                <br />
                <div class="flexLeft" id="statusLine">
                    <div class="adjustTop">
                        <p class="subtitle2"  >
                            <span *ngIf="!isStudent">
                                {{ 'profile.header.status' | translate}}: 
                            </span>
                            <span *ngIf="isStudent">
                                <br/>
                            </span>
                        </p>
                    </div>

                    <app-status 
                        *ngIf="!isStudent"
                        [showComplete]="true" 
                        [status]="status">
                    </app-status>

                    <button *ngIf="isAdmin && status == 'P'" [disabled]="!isValid" mat-raised-button color="accent" 
                        class="primarySmall" (click)="openSaveDialog()">
                        {{'profile.header.save'| translate}}
                    </button>
                    <button *ngIf="isAdmin && status == 'A'" [disabled]="!isValid" mat-raised-button color="accent" 
                        class="primarySmall" (click)="openEditDialog()">
                        {{'profile.header.saveOnly'| translate}}
                    </button>
                    <button 
                        *ngIf="isAdmin && status == 'C'"
                        mat-raised-button color="accent" 
                        class="primarySmall" 
                        (click)="openResendMailDialog()">
                        <!-- <mat-icon class="whiteIcon small-MediumIcon">
                            mail_outline
                        </mat-icon> -->
                        {{'profile.resendMail.button'| translate | uppercase}}
                    </button>
                </div>
            </div>
        </mat-grid-tile>
    </mat-grid-list>


<div *ngIf="isMobile">
    <div class="center">
        <!-- <div class="uploadDivProfile">
            <label *ngIf="!userHasImage" for="file-upload">
                <div class="upload">
                    <img class="imgRounded" src="../../../../assets/user.svg">
                </div>
            </label>
            <label *ngIf="userHasImage" for="file-upload">
                <img *ngIf="userHasImage" class="imgRounded" [src]="avatarUrl | secure | async">

            </label>
            <input *ngIf="!isAdmin" required id="file-upload" type="file" (change)="selectFiles($event)" />
        </div> -->

        <app-avatar
            *ngIf="usuarioId"
            [class]="'imgRounded'"
            [containerClass]="'uploadDivProfile'"
            [usuarioId]="usuarioId"
            [uploadAvailable]="true"
            [showCameraIcon]="false">
        </app-avatar>
    </div>
    <p class="headline5">{{personalData.nombre}} {{personalData.apellido}} {{personalData.segundoApellido}}</p>

    <br/>
    <div class="flexBeetwen2" *ngIf="!isAdminOrTrainer">
        <p class="subtitle2">{{ 'profile.header.level' | translate}}: </p>
            <app-level 
                [level]="userForm.value.level"
                [edit]="false"
                [levels]="levels"
                [isAdmin]="isAdmin"
                [userForm]="userForm"
                [status]="status"
                (saveLevelEvent)="saveLevel($event)">
            </app-level>
    </div>

    <!-- mostrar entrenador asignado -->
    <div *ngIf="!isAdminOrTrainer" class="flexBeetwen2">
        <p class="subtitle2">{{ 'profile.header.trainer' | translate}}:</p>

        <div class="flexRigth">
            <button *ngIf="isAdmin" mat-button class="menuButton"
                #trainerSelect id="trainerInput"
                [disabled]=" status == 'C' || status == 'I' || status == 'S'" [matMenuTriggerFor]="menu" class="menuButton">
                <span class="subtitle2" id="trainer">
                    {{userForm.value.trainer.entrenadorNombre ? (userForm.value.trainer.entrenadorNombre | reduceText: 17) : ( userForm.value.trainer  | reduceText: 17)}}
                </span>

                <mat-icon class="iconMenu" *ngIf="isAdmin && (status == 'P' || status == 'A') ">
                    edit
                </mat-icon>

                <mat-icon class="iconMenu greyIcon" *ngIf="(isAdmin && status == 'C') || (isAdmin && status == 'I') ">
                    edit 
                </mat-icon>
            </button>
            <span *ngIf="isStudent || isTrainer" class="subtitle2" id="trainer">
                {{userForm.value.trainer.entrenadorNombre ? userForm.value.trainer.entrenadorNombre : userForm.value.trainer}}
            </span>
            <mat-menu #menu="matMenu">
                <div *ngFor="let trainer of trainers">
                <button (click)="saveTrainer(trainer)" mat-menu-item>
                    <span class="body4">{{trainer.entrenadorNombre }}</span>
                </button>
                </div>
            </mat-menu>
        </div>
    </div>

    <div class="flexBeetwen2" *ngIf="!isAdminOrTrainer">
        <p class="subtitle2">{{ 'profile.header.type' | translate}}: </p>
        <app-training-type 
            [training]="datosTraining"
            [edit]="isAdmin"
            [groups]="groups"
            [isAdmin]="isAdmin"
            [userForm]="userForm"
            [status]="status"
            (saveGroupEvent)="saveGroup($event)">
        </app-training-type>
    </div>

    <div *ngIf="isAdminOrTrainer" class="flexBeetwenBaseline">
        <p class="subtitle2">{{ 'profile.header.amount' | translate}} {{cantAlumnos}}</p>
    </div>

    <div  class="flexBeetwen2">
        <div class="adjustTop">
            <p class="subtitle2">{{ 'profile.header.status' | translate}}: </p>
        </div>
        <app-status [showComplete]="true" [status]="status"></app-status>
    </div>

    <button *ngIf="isAdmin && status == 'P' " mat-raised-button color="accent" class="buttonDialog" (click)="openSaveDialog()"
        [disabled]="!isValid">
        {{'profile.header.save' | translate}}
    </button>

    <button *ngIf="isAdmin && status == 'A' " mat-raised-button color="accent" class="primaryButton" (click)="openEditDialog()"
        [disabled]="!isValid">
        {{'profile.header.saveOnly' | translate}}
    </button>

    <button  *ngIf="isAdmin && status == 'C'" mat-raised-button color="accent"  class="primaryButton" (click)="openResendMailDialog()">
        {{'profile.resendMail.button'| translate | uppercase}}
    </button>
</div>
</form>
