<div *ngIf="!isMobile" class="buttonsStepper">
    <button class="primaryButton2" *ngIf="enablePrevious" color="primary" mat-stroked-button matStepperPrevious>{{'registration.buttonsStepper.back' | translate }}</button>
    <button class="primaryButton2" *ngIf="!end" [disabled]="disabled" color="accent" mat-raised-button matStepperNext (click)="save()">{{'registration.buttonsStepper.next' | translate }}</button>
    <button class="primaryButton2" *ngIf="end"  color="accent" mat-raised-button (click)="onSubmit()">{{'registration.buttonsStepper.end' | translate }}</button>
</div>

<div *ngIf="isMobile" class="flexColumn">
    <button class="primaryButton2" *ngIf="enablePrevious" color="primary" mat-stroked-button matStepperPrevious>{{'registration.buttonsStepper.back' | translate }}</button>
    <button class="primaryButton2" *ngIf="!end" [disabled]="disabled" color="accent" mat-raised-button matStepperNext (click)="save()">{{'registration.buttonsStepper.next' | translate }}</button>
    <button class="primaryButton2" *ngIf="end"  color="accent" mat-raised-button (click)="onSubmit()">{{'registration.buttonsStepper.end' | translate }}</button>
    <br/>
</div>
