<app-header></app-header>
<app-navbar *ngIf="!isMobile"></app-navbar>
<section>
    <div class="plans">
        <div class="flexLeft">
            <button mat-button (click)="navigate()">
                <div class="flexBeetwenCenter">
                    <mat-icon class="blackIcon">
                        chevron_left
                    </mat-icon>
                </div>
            </button>
            <p class="headline4">{{ 'admin.StudentMainPage.title' | translate }}</p>
        </div>

        <br />
        <app-feedback [canGiveFeedback]="false" [edit]="true"></app-feedback>
    </div>
</section>
