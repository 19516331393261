import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NivelesEnum } from 'src/app/enums/niveles';
import { MobileDetectorService } from 'src/app/services/mobileDetector/mobile-detector.service';
import { WeekService } from 'src/app/utils/week/week.service';
import { DatoPorEtiqueta, FormularioDia, Plan, PlanDetalle } from '../../../../api/interfaces/plans';
import { Level } from '../../../../api/interfaces/user';
import { PlansService } from '../../../../api/services/plans/plans.service';
import { TrainingService } from '../../../../api/services/training/training.service';
import { StatusEnum } from '../../../../enums/status';
import { SnackbarService } from '../../../../utils/snackbar/snackbar.service';
@Component({
  selector: 'app-new',
  templateUrl: './new.component.html',
  styleUrls: ['./new.component.scss']
})
export class NewComponent implements OnInit {

  myFilter = (d: any | null): boolean => {
    const day = new Date(d._d).getDay();
    return day == 1;
  };

  minDate: Date;

  planDetalleEtiqueta: DatoPorEtiqueta[] = [];
  planDetalle : PlanDetalle[] = [];
  detallesDia1: PlanDetalle [] = [];
  detallesDia2: PlanDetalle [] = [];
  planEditable: Plan;

  levels: Level[] = [];

  notDisabled: boolean = false;
  loaded: boolean = false;
  isMobile: boolean = false;
  edit: boolean = false;

  id_usuario: number = 0;
  planId: number = 0;

  today: string = "";

  planForm = new FormGroup({
    level: new FormControl(),
    semana: new FormControl()
  });
  
  constructor(
    private mobileDetectorService: MobileDetectorService,
    private trainingService: TrainingService,
    private plansService: PlansService,
    private router: Router,
    private activatedrouter: ActivatedRoute,
    private snack: SnackbarService,
    private weekService: WeekService

  ) { }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    sessionStorage.setItem('active', 'planes');
    this.loadData();
    this.planForm.statusChanges
    .subscribe(val => {
      if(this.planForm.valid) {
        this.checkValidSaveButton();
      }
    });
    //crear array de datos y precargar
    this.today = new Date().toISOString();
    this.id_usuario = parseInt(localStorage.getItem('idUsuario'),10);
    const semana: string[] = this.weekService.getWeek(new Date());
    this.minDate = new Date(semana[0]);
  }

  loadData() {
    const url = this.activatedrouter.snapshot.routeConfig.path;
    if(url == "planes/editar") {
      this.edit = true;
      this.planId = parseInt(sessionStorage.getItem('planId'),10);
      if(isNaN(this.planId)) {
        this.router.navigateByUrl('planes/lista');
      } else {
        this.plansService.getPlanId(this.planId).subscribe(response => {
          this.planEditable = response;
          this.planForm.patchValue({semana: response.planSemana});
          //asincronismo
          this.loadLevels();
          response.planDetalles.forEach(detalle => {
            if(detalle.dia == 1) {
              this.detallesDia1.push(detalle);
            }
          });
          response.planDetalles.forEach(detalle => {
            if(detalle.dia == 2) {
              this.detallesDia2.push(detalle);
            }
          });
          this.planDetalle = response.planDetalles;
        },
        error => {
          console.error(error);
        });
      }
      //aca
    } else {
      this.planDetalle = [
        {
        planDetalleTitulo: "Fase 1",
        dia: 1,
        actividad: "",
        activo: true,
        creado: this.today,
        creadoPor: this.id_usuario,
        ultima_modificacion: this.today,
        modificadoPor: this.id_usuario
      },
      {
        planDetalleTitulo: "Fase 2",
        dia: 1,
        actividad: "",
        activo: true,
        creado: this.today,
        creadoPor: this.id_usuario,
        ultima_modificacion: this.today,
        modificadoPor: this.id_usuario
      },
      {
        planDetalleTitulo: "Fase 3",
        dia: 1,
        actividad: "",
        activo: true,
        creado: this.today,
        creadoPor: this.id_usuario,
        ultima_modificacion: this.today,
        modificadoPor: this.id_usuario
      },
      {
        planDetalleTitulo: "Entrenamiento Invisible",
        dia: 1,
        actividad: "",
        activo: true,
        creado: this.today,
        creadoPor: this.id_usuario,
        ultima_modificacion: this.today,
        modificadoPor: this.id_usuario
      },
      {
        planDetalleTitulo: "Especificaciones del Entrenamiento",
        dia: 1,
        actividad: "",
        activo: true,
        creado: this.today,
        creadoPor: this.id_usuario,
        ultima_modificacion: this.today,
        modificadoPor: this.id_usuario
      },
      //dia 2
      {
        planDetalleTitulo: "Fase 1",
        dia: 2,
        actividad: "",
        activo: true,
        creado: this.today,
        creadoPor: this.id_usuario,
        ultima_modificacion: this.today,
        modificadoPor: this.id_usuario
      },
      {
        planDetalleTitulo: "Fase 2",
        dia: 2,
        actividad: "",
        activo: true,
        creado: this.today,
        creadoPor: this.id_usuario,
        ultima_modificacion: this.today,
        modificadoPor: this.id_usuario
      },
      {
        planDetalleTitulo: "Fase 3",
        dia: 2,
        actividad: "",
        activo: true,
        creado: this.today,
        creadoPor: this.id_usuario,
        ultima_modificacion: this.today,
        modificadoPor: this.id_usuario
      },
      {
        planDetalleTitulo: "Entrenamiento Invisible",
        dia: 2,
        actividad: "",
        activo: true,
        creado: this.today,
        creadoPor: this.id_usuario,
        ultima_modificacion: this.today,
        modificadoPor: this.id_usuario
      },
      {
        planDetalleTitulo: "Especificaciones del Entrenamiento",
        dia: 2,
        actividad: "",
        activo: true,
        creado: this.today,
        creadoPor: this.id_usuario,
        ultima_modificacion: this.today,
        modificadoPor: this.id_usuario
      },
    ];

    this.plansService.getDatosPorEtiqueta("Actividad Default").subscribe(response => {
      this.planDetalleEtiqueta = response;
      this.loadLevels();
    },
    error => {
      console.error(error);
    });
    }
  }

  loadLevels() {
    this.trainingService.getLevels().subscribe( response=> {
      this.levels = response;
      let remover: number = this.levels.findIndex(option => option.entrenamientoNivelDescripcion == NivelesEnum.noDefinido);
      if(remover != -1) {
        this.levels.splice(remover, 1);
      }
      if(this.edit) {
        let level = this.levels.find(level => level.id == this.planEditable.entrenamientoNivelId);
        this.planForm.patchValue({level: level});
      }
      //asincronismo
      this.loaded = true;
    },
    error => {
      console.error(error);
    });
  }

  saveLevel(level: Level) {
    this.planForm.patchValue({level: level});
  }

  checkValidSaveButton() {
    let planDetalleCompleto: boolean = this.planDetalle.length == 10 && this.planDetalle.every(detalle => (detalle.actividad != null && detalle.actividad != ''));
    this.notDisabled = planDetalleCompleto && this.planForm.valid;
  }

  onSubmit() {
    const aux: Date = new Date(this.planForm.value.semana);
      let semana: string = aux.toISOString();
      const plan: Plan = {
        planDescripcion : `Plan ${this.planForm.value.level.entrenamientoNivelDescripcion} Semana ${aux.toLocaleDateString()}`,
        entrenamientoNivelId : this.planForm.value.level.id,
        planSemana: semana,
        estado : StatusEnum.PENDING,
        activo: true,
        creado: this.today,
        creadoPor: this.id_usuario,
        ultima_modificacion: this.today,
        modificadoPor: this.id_usuario,
        planDetalle: this.planDetalle
      };
    this.plansService.postPlan(plan).subscribe( response=> {
      this.snack.snackTranslate('snacks.sucess.plan.save');
      this.navigate();
    },
    error => {
      console.error(error);
      this.snack.openSnackBar(error.error.message);
    });
  }

  onSubmitEdit() {
    const aux: Date = new Date(this.planForm.value.semana);
      let semana: string = aux.toISOString();
      const plan: Plan = {
        planDescripcion : `Plan ${this.planForm.value.level.entrenamientoNivelDescripcion} Semana ${aux.toLocaleDateString()}`,
        entrenamientoNivelId : this.planForm.value.level.id,
        planSemana: semana,
        estado : StatusEnum.PENDING,
        activo: true,
        modificadoPor: this.id_usuario,
        planDetalle: this.planDetalle
      };
    this.plansService.editDetelePlan(this.planId, plan).subscribe( response=> {
      this.snack.snackTranslate('snacks.sucess.plan.save');
      this.navigate();
    },
    error => {
      console.error(error);
      this.snack.snackTranslate('snacks.error.plan.save');
    });
  }

  notValid(aux: boolean) {
    this.notDisabled = aux;
  }

  editFormDays(aux: FormularioDia, day: number) {
    if(day == 1) {
      this.planDetalle[0].actividad = aux.fase1;
      this.planDetalle[1].actividad = aux.fase2;
      this.planDetalle[2].actividad = aux.fase3;
      this.planDetalle[3].actividad = aux.invisible;
      this.planDetalle[4].actividad = aux.especificaciones;
    }
    if(day == 2) {
      this.planDetalle[5].actividad = aux.fase1;
      this.planDetalle[6].actividad = aux.fase2;
      this.planDetalle[7].actividad = aux.fase3;
      this.planDetalle[8].actividad = aux.invisible;
      this.planDetalle[9].actividad = aux.especificaciones;
    }

    this.checkValidSaveButton();
  }

  navigate() {
    this.router.navigate(['planes/lista']);
  }

}
