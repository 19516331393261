import { Injectable } from '@angular/core';
import { RoleString } from 'src/app/enums/role';

@Injectable({
  providedIn: 'root'
})
export class RolService {

  constructor() { }

  isAdmin(): boolean {
    const rol: string = localStorage.getItem('rol');
    if(rol != RoleString.ADMIN) {
      return false;
    }
    return true;
  }

  isStudent(): boolean {
    const rol: string = localStorage.getItem('rol');
    if(rol != RoleString.STUDENT) {
      return false;
    }
    return true;
  }

  isTrainer(): boolean {
    const rol: string = localStorage.getItem('rol');
    if(rol != RoleString.TRAINER) {
      return false;
    }
    return true;
  }
}
