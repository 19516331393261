<!-- 
    no disabled:
        option
            no selected
            selected
            tex
                selected

    disabled:
        option
            no selected
            selected
            text
                no selected
                selected
-->

<div *ngIf="!disabled">
    <div class="formLine">
        <div *ngIf="option != 1" (click)="saveButton(1)" class="feedbackButtonMal"></div>
        <div *ngIf="option == 1" class="feedbackButtonMalActive"></div>

        <div *ngIf="option != 2" (click)="saveButton(2)" class="feedbackButtonRegular"></div>
        <div *ngIf="option == 2" class="feedbackButtonRegularActive"></div>

        <div *ngIf="option != 3" (click)="saveButton(3)" class="feedbackButtonBien"></div>
        <div *ngIf="option == 3" class="feedbackButtonBienActive"></div>

        <div *ngIf="option != 4" (click)="saveButton(4)" class="feedbackButtonFenomenal"></div>
        <div *ngIf="option == 4" class="feedbackButtonFenomenalActive"></div>
    </div>
    <div class="formLine">
        <div class="feedbackTextBox body3">{{'planes.feedback.mal' | translate}}</div>
        <div class="feedbackTextBox body3">{{'planes.feedback.regular' | translate}}</div>
        <div class="feedbackTextBox body3">{{'planes.feedback.bien' | translate}}</div>
        <div class="feedbackTextBox body3">{{'planes.feedback.fenomenal' | translate}}</div>
    </div>
</div>

<div *ngIf="disabled">
    <div class="formLine">
        <div *ngIf="option != 1" class="feedbackButtonDisabled"></div>
        <div *ngIf="option == 1" class="feedbackButtonMalActive"></div>

        <div *ngIf="option != 2" class="feedbackButtonDisabled"></div>
        <div *ngIf="option == 2" class="feedbackButtonRegularActive"></div>

        <div *ngIf="option != 3" class="feedbackButtonDisabled"></div>
        <div *ngIf="option == 3" class="feedbackButtonBienActive"></div>

        <div *ngIf="option != 4" class="feedbackButtonDisabled"></div>
        <div *ngIf="option == 4" class="feedbackButtonFenomenalActive"></div>
    </div>
    <div class="formLine">
        <div class="feedbackTextBoxDisabled body3" *ngIf="option != 1" >{{'planes.feedback.mal' | translate}}</div>
        <div class="feedbackTextBox body3" *ngIf="option == 1">{{'planes.feedback.mal' | translate}}</div>

        <div class="feedbackTextBoxDisabled body3" *ngIf="option != 2" >{{'planes.feedback.regular' | translate}}</div>
        <div class="feedbackTextBox body3" *ngIf="option == 2">{{'planes.feedback.regular' | translate}}</div>

        <div class="feedbackTextBoxDisabled body3" *ngIf="option != 3" >{{'planes.feedback.bien' | translate}}</div>
        <div class="feedbackTextBox body3" *ngIf="option == 3">{{'planes.feedback.bien' | translate}}</div>

        <div class="feedbackTextBoxDisabled body3" *ngIf="option != 4" >{{'planes.feedback.fenomenal' | translate}}</div>
        <div class="feedbackTextBox body3" *ngIf="option == 4">{{'planes.feedback.fenomenal' | translate}}</div>          
    </div>           
</div>