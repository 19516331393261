<div class="background">
    <app-header></app-header>
    <app-navbar *ngIf="!isMobile"></app-navbar>
    <section>
        <div class="fakeBackground"></div>
        <mat-card class="message">
            
           <app-messages-list 
                *ngIf="!isMobile || (isMobile && showList)"
                (changeCurrentMessageEvent)="changeCurrentMessageEvent($event)"
                (massiveMessageEvent)="massiveMessageEvent($event)">
            </app-messages-list>

           <app-conversation 
                *ngIf="showConversation" 
                [canal]="canal">
            </app-conversation>

           <div class="noSelected" *ngIf="!showConversation && !showMassive && !isMobile">
                <mat-icon class="noSelectedIcon">email</mat-icon>
                <p class="headline6Light">{{'messages.noSelected' | translate}}</p>
                <p class="body2 black06alpha">{{'messages.noSelected2' | translate}}</p>
            </div>

           <app-conversation-massive *ngIf="showMassive"></app-conversation-massive>
        </mat-card>
    </section>
</div>