import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Archivo, ArchivosPageable, ArchivosPorSeccion, TipoArchivo } from '../../interfaces/archivos';


@Injectable({
  providedIn: 'root'
})
export class GalleryFilesService {

  constructor(private http: HttpClient) { }

  private url = environment.apiUrl + '/';

  httpOptionsJson = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }),
    withCredentials: true,
  };

  
  getTiposArchivo(): Observable<TipoArchivo[]> {
    return this.http.get<TipoArchivo[]>(this.url + `api/item/allarchivotipo`,  this.httpOptionsJson);
  }

  getArchivosPorSeccion(): Observable<ArchivosPorSeccion> {
    return this.http.get<ArchivosPorSeccion>(this.url + `api/archivos/porSeccion`,  this.httpOptionsJson);
  }

  getSeccionesArchivos(): Observable<Archivo[]> {
    return this.http.get<Archivo[]>(this.url + `api/archivos/secciones`,  this.httpOptionsJson);
  }

  downloadFile(archivoId: number, tipoArchivoId: number): Observable<Blob> {
    return this.http.get(this.url + `api/item/archivotipo?archivoId=${archivoId}&tipoArchivoId=${tipoArchivoId}`, {
      withCredentials: true,
      responseType: 'blob'
    });
  }

  deleteItem(archivoId: number, tipoArchivoId: number): Observable<any> {
    return this.http.delete<any>(this.url + `api/item/archivotipo?archivoId=${archivoId}&tipoArchivoId=${tipoArchivoId}`,  this.httpOptionsJson);
  }

  postItem(tipoArchivoId: number, fechaVigencia: string, nombreArchivo: string, files: File[]): Observable<any> {
    const formData = new FormData();
    if (files && files.length > 0) {
      files.forEach(file => {
        formData.append('file', file);
      });
    }
    const multipartOptions = {
      withCredentials: true,
    };
    return this.http.patch<any>(this.url + `api/item/archivotipo?&tipoArchivoId=${tipoArchivoId}&fechaVigencia=${fechaVigencia}&nombreArchivo=${nombreArchivo}`, formData, multipartOptions);
  }

  getArchivosPageable(page: number, size: number, usuarioId: number): Observable<any> {
    return this.http.get<any>(this.url + `api/item/listarchivotipo?usuarioId=${usuarioId}&pag=${page}&reg=${size}`,  this.httpOptionsJson);
  }

  getArchivosPageableFiltrable(page: number, size: number, nombre: string, tipoId: string): Observable<ArchivosPageable> {
    return this.http.get<ArchivosPageable>(this.url + `api/archivos/listado?page=${page}&size=${size}&nombre=${nombre}${tipoId}`,  this.httpOptionsJson);
  }

  patchFile(files, itemId: number): Observable<any> {
    const formData = new FormData();
    if (files && files.length > 0) {
      files.forEach(file => {
        formData.append('file', file);
      });
    }
    const multipartOptions = {
      withCredentials: true,
    };
    return this.http.patch<any>(this.url + `api/informacion/item/archivo?itemId=${itemId}`, formData, multipartOptions);
  }

  preview(archivoId: number, tipoArchivoId: number): Promise<string> {
    return new Promise((resolve, reject) => {
      this.downloadFile(archivoId, tipoArchivoId).subscribe(response => {
          resolve(URL.createObjectURL(response));
      },
        error => {
          console.error(error);
          reject();
        });
    });
  }
}
