<div class="background">

    <app-header></app-header>
    <app-navbar *ngIf="!isMobile"></app-navbar>
    <section>
        <div class="flexStudents">


           <!--  <p class="headline4">{{ 'admin.StudentMainPage.title' | translate }}</p> -->
            <!-- <div *ngIf="!isMobile && !isTrainer"> -->

            <p class="headline4" *ngIf="!isMobile">{{ 'admin.StudentMainPage.title' | translate }}</p>
            <p class="headline5" *ngIf="isMobile">{{ 'admin.StudentMainPage.title' | translate }}</p>
            <div *ngIf="!isMobile && !isTrainer">
                <!-- <button mat-stroked-button class="primaryButton2" color="primary">
                    <mat-icon>
                        supervisor_account
                    </mat-icon>
                    {{ 'admin.StudentMainPage.editButton' | translate }}
                </button> -->

                <button mat-stroked-button class="buttonSmall3" color="primary" (click)="process(true)">
                    <mat-icon>
                        content_copy
                    </mat-icon>
                </button>

                <button 
                    mat-stroked-button 
                    class="primaryButton2" 
                    color="primary" 
                    (click)="process(false)">
                    <mat-icon>
                        send
                    </mat-icon>
                    {{ 'admin.StudentMainPage.processButton' | translate }}
                </button>
                
                <button mat-raised-button class="primaryButton2" color="accent" (click)="openDialogCreateStudent()">
                    <mat-icon class="whiteIcon">
                        add
                    </mat-icon>
                    {{ 'admin.StudentMainPage.createButton' | translate }}
                </button>
                <br/>
                <p class="body5">Último envío: {{ultimaEjecucion | date: 'dd/MM/YYY'}}</p>
            </div>
            <div *ngIf="isMobile && !isTrainer">
                <button mat-stroked-button class="mobileSmallButton" color="primary" (click)="process(true)">
                    <mat-icon>
                        content_copy
                    </mat-icon>
                </button>
                <button 
                    mat-stroked-button 
                    class="mobileSmallButton" 
                    color="primary" 
                    (click)="process(false)">
                    <mat-icon>
                        send
                    </mat-icon>
                </button>
                <button mat-raised-button class="mobileSmallButton" color="accent" (click)="openDialogCreateStudent()">
                    <mat-icon class="whiteIcon">
                        add
                    </mat-icon>
                </button>
            </div>
        </div>
        <app-students-table></app-students-table>
    </section>
</div>