import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ParametroEtiqueta } from 'src/app/api/interfaces/parametros';
import { ParametrosService } from 'src/app/api/services/parametros/parametros.service';
import { MobileDetectorService } from 'src/app/services/mobileDetector/mobile-detector.service';
import { SnackbarService } from 'src/app/utils/snackbar/snackbar.service';
import { SettingsArancelDialogComponent } from '../settings-arancel-dialog/settings-arancel-dialog.component';

@Component({
  selector: 'app-settings-arancel-card',
  templateUrl: './settings-arancel-card.component.html',
  styleUrls: ['./settings-arancel-card.component.scss']
})
export class SettingsArancelCardComponent implements OnInit {

  @Output() toggleOptionEvent = new EventEmitter<number>();
  isMobile: boolean = false;
  parametros: ParametroEtiqueta[] = [];

  constructor(
    private mobileDetectorService: MobileDetectorService,
    private parametrosService: ParametrosService,
    public dialog: MatDialog,
    private snack: SnackbarService
  ) { }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    this.getParametros();
  }

  toggleOption(option: number) {
    this.toggleOptionEvent.emit(option);
  }

  getParametros() {
    this.parametrosService.getParametros('Aranceles').subscribe(response=> {
      this.parametros = response;
    },
    error => {
      console.error(error);
    });
  }

  openArancelDialog(parametro: ParametroEtiqueta) {
    const dialogRef = this.dialog.open(SettingsArancelDialogComponent, {
      data: parametro
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.snack.snackTranslate('snacks.sucess.settings.arancel');
        this.getParametros();
      }
    });
  }

}
