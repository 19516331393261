export enum FlagsEnum  {
    editObjetives = "editObjetives",
    editAchievements = "editAchievements"
}

export enum FlagsSupport {
    showHome = "showHome",
    onElement = "onElement",
    closeSupportDialog = "closeSupportDialog"
}

export enum FlagsColor {
    changeColor = "changeColor",
    setDefaultColor = "setDefaultColor",
    setDefaultTheme = "setDefaultTheme"
}

export enum FlagsGroups {
    editGroup = "editGroup"
}