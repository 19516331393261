<form class="filters" [formGroup]="filtersForm">
    <div *ngIf="!isMobile">
  
      <mat-form-field appearance="outline" class="inputsFilter">
        <mat-label> {{'admin.studentsTable.filters.searchSmall' | translate}} </mat-label>
        <input matInput type="text" formControlName="nombreApellido" >
        <button mat-icon-button matSuffix (click)="onSubmit()">
          <mat-icon class="azul">search</mat-icon>
        </button>
        <button *ngIf="filtersForm.value.nombreApellido" mat-icon-button matSuffix
        (click)="clear('nombreApellido'); $event.stopPropagation()">
          <mat-icon class="clearIcon">close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field appearance="outline" class="inputsFilter2">
        <mat-label>{{'admin.studentsTable.filters.status' | translate}}</mat-label>
        <mat-select #statusSelect id="levelInput" placeholder="status" formControlName="status">
          <mat-option *ngFor="let status of allStatus" [value]="status.est">{{status.name}}
          </mat-option>
        </mat-select>
        <button *ngIf="filtersForm.value.status" mat-icon-button matSuffix 
        (click)="clear('status'); $event.stopPropagation()">
          <mat-icon class="clearIcon">close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field appearance="outline" class="inputsFilter">
        <mat-label>{{'admin.studentsTable.filters.type' | translate}}</mat-label>
        <mat-select #typeSelect id="typeInput" formControlName="type">
          <mat-option class="body2" *ngFor="let option of trainingDays" [value]="option.entrenamientoGrupoId">
            {{option.grupoDescripcion}}
          </mat-option>
        </mat-select>
        <button *ngIf="filtersForm.value.type" mat-icon-button matSuffix 
        (click)="clear('type'); $event.stopPropagation()">
          <mat-icon class="clearIcon">close</mat-icon>
        </button>
      </mat-form-field>
  
      <button mat-button color="primary" (click)="onSubmit()" [disabled]="disabled">{{'admin.studentsTable.filters.ok' | translate}}</button>
      <button mat-button color="primary" (click)="clearAll()" [disabled]="disabled">{{'admin.studentsTable.filters.clear' | translate}}</button>
    </div>
  
    <div *ngIf="isMobile">

      <mat-form-field appearance="outline" class="inputsFilter">
        <mat-label> {{'admin.studentsTable.filters.searchSmall' | translate}} </mat-label>
        <input matInput type="text" formControlName="nombreApellido" >
        <button mat-icon-button matSuffix (click)="onSubmit()">
          <mat-icon class="azul">search</mat-icon>
        </button>
        <button *ngIf="filtersForm.value.nombreApellido" mat-icon-button matSuffix
        (click)="clear('nombreApellido'); $event.stopPropagation()">
          <mat-icon class="clearIcon">close</mat-icon>
        </button>
      </mat-form-field>
  
      <mat-accordion class="example-headers-align">
        <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title class="headline5NoCenter">
              <mat-icon class="blackIcon">
                filter_list
              </mat-icon>
              <span class="body3">
                {{'admin.studentsTable.filters.filters' | translate}}
              </span>
              <!-- <span *ngIf="contador > 0" class="contador">{{contador}}</span> -->
            </mat-panel-title>
  
          </mat-expansion-panel-header>

          <mat-form-field appearance="outline" class="inputsFilter2">
            <mat-label>{{'admin.studentsTable.filters.status' | translate}}</mat-label>
            <mat-select #statusSelect id="levelInput" placeholder="status" formControlName="status">
              <mat-option *ngFor="let status of allStatus" [value]="status.est">{{status.name}}
              </mat-option>
            </mat-select>
            <button *ngIf="filtersForm.value.status" mat-icon-button matSuffix 
            (click)="clear('status'); $event.stopPropagation()">
              <mat-icon class="clearIcon">close</mat-icon>
            </button>
          </mat-form-field>
    
          <mat-form-field appearance="outline" class="inputsFilter">
            <mat-label>{{'admin.studentsTable.filters.type' | translate}}</mat-label>
            <mat-select #typeSelect id="typeInput" formControlName="type">
              <mat-option class="body2" *ngFor="let option of trainingDays" [value]="option.entrenamientoGrupoId">
                {{option.grupoDescripcion}}
              </mat-option>
            </mat-select>
            <button *ngIf="filtersForm.value.type" mat-icon-button matSuffix 
            (click)="clear('type'); $event.stopPropagation()">
              <mat-icon class="clearIcon">close</mat-icon>
            </button>
          </mat-form-field>
      
          <button mat-button color="primary" (click)="onSubmit()" [disabled]="disabled">{{'admin.studentsTable.filters.ok' | translate}}</button>
          <button mat-button color="primary" (click)="clearAll()" [disabled]="disabled">{{'admin.studentsTable.filters.clear' | translate}}</button>
  
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  
  </form>