import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { MessagesService } from 'src/app/api/services/messages/messages.service';
import { RoleString } from 'src/app/enums/role';
import { NavbarButton } from '../../../api/interfaces/login';
import { LoginService } from '../../../api/services/login-service.service';
import { MobileDetectorService } from '../../../services/mobileDetector/mobile-detector.service';
import { SnackbarService } from '../../../utils/snackbar/snackbar.service';


/**
 * Create array of navbarButtons for each role
 */
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  navbarButton: NavbarButton[] = [];
  loaded: boolean = false;

  @Input ('active') active! : string;
  rol: string = "";
  backdropBackground: string = "backdropBackground";
  isMobile: boolean = false;
  showCircle: boolean = false;

  constructor(
    private snack: SnackbarService,
    private loginApiService: LoginService,
    private mobileDetectorService: MobileDetectorService,
    private router: Router,
    private messagesService: MessagesService,
  ) { }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    this.rol = localStorage.getItem('rol');
    // this.loginApiService.getNavbarOptions()
    //   .subscribe(response => {
    //     this.navbarButton = response;
    //     this.loaded = true;
    //   },
    //     error => {
          // this.navbarButton = [];
          
          if(this.rol === RoleString.ADMIN) {
            this.navbarButton = [
              {icon: "supervisor_account", name:"Alumnos", path: 'admin/alumnos', title: "Alumnos"},
              {icon: "sports", name:"Entrenadores", path: 'admin/entrenadores', title: "Entrenadores"},
              {icon: "date_range", name:"Planes", path: 'planes/lista', title: "Planes"},
              {icon: "question_answer", name:"Mensajes", path: 'mensajes', title: "Mensajes"},
              {icon: "fitness_center", name:"Trabajo comp.", path: 'complementario', title: "Trabajo comp."},
              {icon: "restaurant_menu", name:"Nutrición", path: 'nutricion', title: "Nutrición"},
              {icon: "attach_money", name:"Cobranzas", path: 'admin/cobranzas', title: "Cobranzas"},
              {icon: "settings", name:"Configuración", path: 'admin/configuracion', title: "Configuración"}
            ];
          }
          if(this.rol === RoleString.STUDENT) {
           
            const estado: string = localStorage.getItem('estadoAlumno');
            let pathPlan: string = 'planes/semana';
            if(estado == 'S' ){
              pathPlan = null
            }
            this.navbarButton = [
              
              {icon: "date_range", name:"Mis planes", path: pathPlan, title: "Mis planes"},
              {icon: "format_list_numbered", name:"Marcas y objetivos", path: 'alumno/metas-objetivos', title: "Marcas y objetivos"},
              {icon: "question_answer", name:"Mensajes", path: 'mensajes', title: "Mensajes"},
              {icon: "fitness_center", name:"Trabajo comp.", path: 'complementario', title: "Trabajo comp."},
              {icon: "restaurant_menu", name:"Nutrición", path: 'nutricion', title: "Nutrición"},
              // {icon: "account_circle", name:"Mi perfil", path: 'alumno/mi-perfil', title: "Mi perfil"},
              {icon: "help", name:"Ayuda", path: 'ayuda', title: "Ayuda"},
            ];
          }

          if(this.rol === RoleString.TRAINER) {
            this.navbarButton = [
              {icon: "supervisor_account", name:"Mis alumnos", path: 'entrenador/alumnos', title: "Alumnos"},
              {icon: "question_answer", name:"Mensajes", path: 'mensajes', title: "Mensajes"},
              {icon: "fitness_center", name:"Trabajo comp.", path: 'complementario', title: "Trabajo comp."},
              {icon: "restaurant_menu", name:"Nutrición", path: 'nutricion', title: "Nutrición"},
              {icon: "help", name:"Ayuda", path: 'ayuda', title: "Ayuda"},
              // {icon: "account_circle", name:"Mi perfil", path: 'entrenador/perfil', title: "Mi perfil"},
            ];
          }
        
          // this.snack.snackTranslate('snacks.error.generic');
          // console.error(error);
        // });
   
    this.setCircle();
  }

  navigate(path: string) {
    if(path) {
      this.router.navigate([path]);
    }
  }

  setCircle() {
      this.messagesService.getCantidadNoLeidos().subscribe(response => {
        if(response.totalPendientes > 0) {
          this.showCircle = true;
        } else {
          this.showCircle = false;
        }
        this.loaded = true;
      }, error => {
        console.error(error);
        this.loaded = true;
        this.showCircle = false;
      });
  }
}
