<mat-card class="cardProfile">
    <div class="flexBeetwen">
        <p class="headline6"> {{'profile.statusHistoryTab.disabled.title' | translate}}</p>
        <button color="warn" [disabled]="estado == 'C' || entrenadorIsAdmin" *ngIf="estado != 'I' && !showForm" mat-raised-button class="primaryButton2 mal" (click)="toggleForm()">{{'profile.statusHistoryTab.disabled.button1' | translate}}</button>
        
    </div>

    <p class="body3" *ngIf="motivoBaja && estado != 'A'">{{'profile.statusHistoryTab.disabled.text' | translate}} {{motivoBaja.descripcionMotivoBaja}}</p>
    <button *ngIf="estado == 'I'" mat-raised-button color="accent" class="primaryButton2" (click)="chooseSubmitByRol()">{{'profile.statusHistoryTab.disabled.button2' | translate}}</button>
    
    <form *ngIf="showForm" [formGroup]="disableForm">
        <mat-form-field appearance="fill" class="inputfullWidth">
            <mat-label>{{'profile.statusHistoryTab.reasons' | translate}}</mat-label>
            <mat-select  #reasonSelect id="reasonInput" placeholder="reason" formControlName="motivoId" required>
              <mat-option *ngFor="let motivo of motivosBaja" [value]="motivo.id">{{motivo.descripcionMotivoBaja}}
              </mat-option>
            </mat-select>
        </mat-form-field>

        <br/>
        
        <div class="flexRigth">
            <button color="warn" [disabled]="!disableForm.valid" id="confirm" mat-raised-button class="primaryButton2  mal" (click)="onSubmit()">{{'profile.statusHistoryTab.disabled.button3' | translate}}</button>
        </div>
    </form>
</mat-card>