import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StudentService } from '../../../../services/admin/student/student.service';
import { UserFromAdmin, StudentFromAdmin, InsertUser, ControlCantidad } from '../../../../api/interfaces/register';
import { SnackbarService } from '../../../../utils/snackbar/snackbar.service';
import { SharedDataService } from '../../../../services/sharedData/shared-data.service';

import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from "@angular/material/icon";

@Component({
  selector: 'app-create-student',
  templateUrl: './create-student.component.html',
  styleUrls: ['./create-student.component.scss']
})
export class CreateStudentComponent implements OnInit {

  createStudent: boolean = true;
  success: boolean = false;
  waiting: boolean = false;
  studentForm = new FormGroup({
    email: new FormControl(),
    nombre: new FormControl(),
    apellido: new FormControl(),
    segundoApellido: new FormControl()
  });

  controlCantidad : ControlCantidad;

  constructor(
    public dialog: MatDialog,
    private service: StudentService,
    private snack: SnackbarService,
    private sharedData: SharedDataService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) { 
    this.matIconRegistry.addSvgIcon(
      `done`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../assets/check.svg')
    );
  }

  ngOnInit(): void {
    this.controlarCantidad();
  }

  /**
   * Solicita la cantidad total de alumnos con estado activo y el límite por equipo, para mostrar información al administrador en el popup de crear alumno
   */
  controlarCantidad() {
    this.service.getCantidadAlumnos().subscribe(response => {
      this.controlCantidad = response;
    },
      error => {
        console.error(error);
        if(error.error.message) {
          this.snack.openSnackBar(error.error.message);
        } else {
          this.snack.snackTranslate('snacks.error.generic');
        }
      });
  }

  onSubmit() {
    this.waiting = true;
    const userId: number = 1;
    let aux1: InsertUser = this.service.completeUserData(this.studentForm.value, userId);

    this.service.insertUser(aux1).subscribe(usuarioId => {
      let aux2 = this.service.completeStudentData(this.studentForm.value, usuarioId);
      this.service.createStudentPost(aux2).subscribe(response => {
        this.success = true;
        this.createStudent = !this.createStudent;
        this.sharedData.changeMessage("reloadStudentData");
        this.waiting = false;
      },
        error => {
          if (error.status === 409) {
            this.snack.snackTranslate('snacks.error.duplicatedMail');
          } else {
            this.snack.snackTranslate('snacks.error.generic');
          }
          console.error(error);
          this.success = false;
          this.waiting = false;
        });
    },
      error => {
        console.error(error);
        //ticket 2106, close dialog if session is expired
        if(error.status === 401 ) {
          this.dialog.closeAll();
          this.snack.snackTranslate('snacks.logout.expired');
        } else {
          this.snack.openSnackBar(error.error.message);
        }
        this.success = false;
        this.waiting = false;
      });
  }

}