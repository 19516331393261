import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from "@angular/material/icon";
import { SupportService } from 'src/app/api/services/support/support.service';
import { trigger, transition, state, animate, style } from '@angular/animations';
import { FormGroup, FormControl } from '@angular/forms';
import { ParametroEtiqueta } from 'src/app/api/interfaces/parametros';
import { NewTicket } from 'src/app/api/interfaces/support';
import { SnackbarService } from 'src/app/utils/snackbar/snackbar.service';
import { of, Subscription } from 'rxjs';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';
import { SupportUsefull } from '../../../../api/interfaces/support';
import { MobileDetectorService } from 'src/app/services/mobileDetector/mobile-detector.service';
import { FlagsSupport } from 'src/app/enums/sharedDataFlag';


/**
 * Calls all the support components according to user actions: create ticket, or navigate in support tree options.
 */
@Component({
  selector: 'app-support-dialog',
  templateUrl: './support-dialog.component.html',
  styleUrls: ['./support-dialog.component.scss'],
  animations: [
    trigger('flyInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [
        style({ transform: 'translateX(100%)' }),
        animate(450),
      ]),
     
    ])
  ],
})
export class SupportDialogComponent implements OnInit, OnDestroy {

  showReturnSelectOnTree: boolean = true;
  createTicket: boolean = false;
  suggestion: boolean = false;
  onElement: boolean = false;
  showHome: boolean = false;
  isMobile: boolean = false;
  question: boolean = false;
  success: boolean = false;
  waiting: boolean = false;
  select: boolean = true;
  tree: boolean = false;
  bug: boolean = false;

  backHome: number = 0;
  id: number;

  ticketForm = new FormGroup({
    asunto: new FormControl(),
    descripcion: new FormControl(),
    dispositivos: new FormControl()
  });

  archivos: File[] = [];
  categorias: ParametroEtiqueta[] = [];

  private sharedData$: Subscription;
  private sharedDataBoolean$: Subscription;
  
  constructor(
    public dialog: MatDialog,
    private supportService: SupportService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private snack: SnackbarService,
    public dialogRef: MatDialogRef<SupportDialogComponent>,
    private sharedData: SharedDataService,
    private mobileService: MobileDetectorService
  ) { 
    this.matIconRegistry.addSvgIcon(
      `check`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../assets/check.svg')
    );
  }

  ngOnInit(): void {
    this.isMobile = this.mobileService.isMobile();
    this.sharedData.changeFlag(FlagsSupport.onElement,false);
    this.sharedData.changeMessage("");
    if(!this.isMobile) {
      this.dialogRef.updatePosition({ 
        top: `54px`,
        right: `100px`});
    }

    this.sharedData$ = this.sharedData.currentMessage.subscribe(message => {
      if (message === "notUsefull") {
        this.toggleQuestionMode();
      }
      if(message === FlagsSupport.closeSupportDialog) {
        this.dialog.closeAll();
      }
    });
    this.sharedDataBoolean$ = this.sharedData.currentFlag.subscribe(flag => {
      if(flag) {

        if(flag.key == FlagsSupport.onElement) {
          this.onElement = flag.value;
        }

        if(flag.key == FlagsSupport.showHome) {
          this.showHome = flag.value;
        }
      }
    });
  }

  nullFiles(event) {
    event.target.value = null;
  }


  /**
   * Open ticket form so user can submit a ticket.
   * @param bug - boolean indicate if user choosed bug or support
   */
  toggleBugMode(bug: boolean) {
    bug? this.bug = true : this.suggestion = true;
    this.createTicket = true;
    this.select = false;
    this.tree = false;
    this.question = false;
  }

  /**
   * Open SupportTreeMainComponent so user can navigate and read support elements
   * @param tree 
   */
  toggleTreeMode(tree: boolean) {
    this.tree = tree;
    this.createTicket = false;
    this.select = false;
    this.suggestion = false;
    this.waiting = false;
    this.question = false;

    sessionStorage.removeItem('navegacion');
  }

  /**
   * Open ticket form so user can submit a ticket after choosing help was not usefull
   */
  toggleQuestionMode() {
    this.question = true;
    this.createTicket = true;
    this.select = false;
    this.tree = false;
  }

  /**
   * Allows user to navigate back to SupportTypeOptionComponent, where user can choose submit a new ticket or open SupportTreeMainComponent
   */
  returnToSelect() {
    this.select = true;
    this.bug = false;
    this.suggestion = false;
    this.createTicket = false;
    this.tree = false;
    this.question = false;
    this.onElement = false;
    this.success = false;

    this.backHome++;
    sessionStorage.setItem('navegacion',JSON.stringify([]));
    this.patchForm();
  }

  /**
   * Removes archivos and all data in ticketForm.
   * Ticket 3126
   */
  patchForm() {
    this.archivos = [];
    this.ticketForm.patchValue({asunto: ''});
    this.ticketForm.patchValue({descripcion: ''});
    this.ticketForm.patchValue({dispositivos: ''});
    this.ticketForm.markAsUntouched();
  }

  /**
   * Post user ticket and files
   */
  async onSubmit() {
    this.waiting = true;
    const { asunto, descripcion , dispositivos } = this.ticketForm.getRawValue();
    let descripcionCompleta: string = descripcion;

    if(dispositivos && dispositivos.length > 0) {
      descripcionCompleta =  `+Descripción del usuario+  
      ${descripcion}  
      +Dispositivos+  
      ${dispositivos}`;
    }
    let ticket: NewTicket = {
      asunto: asunto,
      descripcion: descripcionCompleta,
      tipoIncidencia: await this.supportService.getTipoIncidente(this.bug)
    };

    // of(256).subscribe(response => {
      // this.id = response;
    this.supportService.postTicket(this.archivos, ticket).subscribe(response => {
      this.id = response.id;
      this.waiting = false;
      this.success = true;
      this.createTicket = false;

      let datos: SupportUsefull = JSON.parse(sessionStorage.getItem('usefullData'));
      datos.ticketId = response.id;
      sessionStorage.setItem('usefullData',JSON.stringify(datos));

      this.supportService.postUsefull();

      this.patchForm();
    },
      error => {
        console.error(error);
        if(error.status != 500) {

          this.snack.openSnackBar(error.error.message);
        } else {
          this.snack.snackTranslateWithAction('support.error','X');
        }
        this.success = false;
        this.waiting = false;
        this.createTicket = true;

        this.supportService.postUsefull();
      });
  }

  selectFiles(event) {
    if(event.target.files.length != 0) {
      for (var i = 0; i < event.target.files.length; i++) {
        this.archivos.push(event.target.files[i]);
      }
    }
  }

  deleteFiles(index: number) {
    this.archivos.splice(index,1);
  }


  /**
   * User can return to select mode, main tree elements, or to the last support element.
   * Remove last element from sessionStorage navegacion[], 
   */
  treeNavigation() {
    this.success = false;
    /**
    * array that contains all the elements ids that user opened
    */
    let navegacion: number[] = JSON.parse(sessionStorage.getItem('navegacion'));
    if(!navegacion || navegacion.length == 0) {
      this.returnToSelect();
    } else {
      this.tree = true;
      this.createTicket = false;
      const ultimoId: number = navegacion.pop();
      sessionStorage.setItem('navegacion',JSON.stringify(navegacion));
      if(navegacion.length == 0) {
        this.returnToSelect();
      } else {
        sessionStorage.setItem('ultimoId',JSON.stringify(ultimoId));
        this.sharedData.changeMessage("backHistorial");
      }
    }
  }

  public ngOnDestroy(): void {
    this.sharedData$.unsubscribe();
    this.sharedDataBoolean$.unsubscribe();
  }


}