import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { RamaConHijos, RamaPrincipal } from '../../../../../api/interfaces/support';
import { SupportService } from 'src/app/api/services/support/support.service';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';
import { SnackbarService } from 'src/app/utils/snackbar/snackbar.service';
import { FlagsSupport } from 'src/app/enums/sharedDataFlag';

@Component({
  selector: 'app-support-tree-iterable-elements',
  templateUrl: './support-tree-iterable-elements.component.html',
  styleUrls: ['./support-tree-iterable-elements.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('flyInOut', [
      state('in', style({ transform: 'translateX(0)' })),
      transition('void => *', [
        style({ transform: 'translateX(100%)' }),
        animate(450),
      ]),
     
    ])
  ],
})
export class SupportTreeIterableElementsComponent implements OnInit, OnChanges {

  elements: RamaConHijos = {
    padre: null,
    hijos: null
  };

  @Input('elementsSearchPrincipales') elementsSearchPrincipales: RamaPrincipal[];
  /**
   * Currently open element, choosed by user
   */
  @Input('choosedElementPrincipal') choosedElementPrincipal: RamaPrincipal;
  @Input('showMainText') showMainText: boolean;

  @Output() clearEvent = new EventEmitter<null>();

  firstChange: boolean = true;
  showRamaPrincipal: boolean = false;
  loading: boolean = false;

  private sharedData$: Subscription;

  constructor(
    private sharedData: SharedDataService,
    private snack: SnackbarService,
    private supportService: SupportService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.sharedData$ = this.sharedData.currentMessage.subscribe(message => {
      if (message === "backHistorial") {
        const id: number = parseInt(sessionStorage.getItem('ultimoId'),10);
        if(!isNaN(id)) {
          this.showRamaPrincipal = false;
          sessionStorage.removeItem('ultimoId');
          if( !(this.choosedElementPrincipal) || ((this.choosedElementPrincipal && this.choosedElementPrincipal.id) && (id != this.choosedElementPrincipal.id)) ) {
            this.getElementosDeRama( id, false);
          } else {
            let navegacion: number[] = JSON.parse(sessionStorage.getItem('navegacion'));
            const ultimoId: number = navegacion.pop();
            this.getElementosDeRama( ultimoId , false);
          }
        }
      }
    });
  }

  toggleRamas(element: RamaPrincipal) {
    this.choosedElementPrincipal = element;
    this.showRamaPrincipal = false;

    this.clearEvent.emit();

    this.getElementosDeRama(element.id, true);
  }

  /**
   * 
   * @param id - number, padre id, request to backend all the children with padre = id
   * @param save - boolean, prevent id to be saved in sessionStorage navegacion
   */
  getElementosDeRama(id: number, save: boolean) {

    this.loading = true;
    this.sharedData.changeFlag(FlagsSupport.onElement,true);
    /**
     * true if element's id is the last element in navegacion[]
     * Used to prevent unnecesary request to backend
     */
    let duplicate: boolean = false;
    if(save) {
      /**
       * array that contains all the elements ids that user opened
       */
      let navegacion: number[] = JSON.parse(sessionStorage.getItem('navegacion'));

      /**
       * array that contains all the elements ids that user opened, go back, and repeat. 
       */
      let navegacionCompleta: number [] = JSON.parse(sessionStorage.getItem('navegacionCompleta'));
      if(!navegacionCompleta) {
        navegacionCompleta = [];
      }
      navegacionCompleta.push(id);
      sessionStorage.setItem('navegacionCompleta',JSON.stringify(navegacionCompleta));


      if(!navegacion) {
        navegacion = [];
        navegacion.push(id);
        sessionStorage.setItem('actualId',JSON.stringify(id));
        sessionStorage.setItem('navegacion',JSON.stringify(navegacion));
      } else {
        if(id != navegacion[navegacion.length - 1]) {
          navegacion.push(id);
          sessionStorage.setItem('navegacion',JSON.stringify(navegacion));
          sessionStorage.setItem('actualId',JSON.stringify(id));
        } else {
          duplicate = true;
        }
      }
    }
    if(!duplicate || !save) {

      this.supportService.getRamasPorIdPrincipal(id)
      .subscribe(response => {
        this.elements.padre = response;
        //no mostrar el icono home si el padreId es cero
        this.sharedData.changeFlag(FlagsSupport.showHome, (response.padreId != 0) )
        if(response.contenido) {

          this.elements.padre.contenidoParseado = {
            texto : "",
            videoUrl : [],
          }

          const array: string [] = response.contenido.split('|');
          this.elements.padre.contenidoParseado.texto = array[0];
          if(array[1]) {
            this.elements.padre.contenidoParseado.videoUrl = JSON.parse(array[1]);
          }

        } else {
          this.elements.padre.contenidoParseado = null;
        }
        if(!save) {
          if(this.elements && this.elements.padre) {
            if(!this.choosedElementPrincipal) {
              this.choosedElementPrincipal = {
                id: null,
                titulo: null,
                padreId: null,
                icono: null
              };
            }
            this.choosedElementPrincipal.id = this.elements.padre.id;
            this.choosedElementPrincipal.padreId = this.elements.padre.padreId;
            this.choosedElementPrincipal.titulo = this.elements.padre.titulo;
            this.choosedElementPrincipal.icono = this.elements.padre.icono;
          }
        }
        this.getHijos(id);
      },
      error => {
        console.error(error);
        this.snack.openSnackBar(error.error.message);
        this.loading = false;
        
      });
    }
  }

  getHijos(id: number) {
    this.supportService.getHijosPorIdPadre(id)
    .subscribe(response => {
      this.elements.hijos = response;
      this.loading = false;
    },
    error => {
      console.error(error);
      this.snack.openSnackBar(error.error.message);
      this.elements.hijos = null;
      this.loading = false;
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.firstChange = changes.elementsSearchPrincipales && changes.elementsSearchPrincipales.firstChange ? changes.elementsSearchPrincipales.firstChange : true;
    if(changes.elementsSearchPrincipales && !changes.elementsSearchPrincipales.firstChange) {
      this.choosedElementPrincipal = changes.choosedElementPrincipal.currentValue;
      this.showRamaPrincipal = false;
      this.getElementosDeRama(this.choosedElementPrincipal.id, true);
    }
  }

  getVideoIframe(url: string) {
    var video, results;
    if (url === null) {
      return '';
    }
    results = url.match('[\\?&]v=([^&#]*)');
    video = (results === null) ? url : results[1];
    return this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + video);
  }

  public ngOnDestroy(): void {
    this.sharedData$.unsubscribe();
  }

}
