<mat-card class="cardProfile">

  <div class="deleteDiv">

      <button 
        *ngIf="isStudent && !isMobile"
        mat-raised-button color="accent" 
        class="primarySmall" 
        (click)="openNewElementDialog()"> 
        {{'profile.gallery.new.buttonOpen' | translate}}
      </button>

  </div>

  <!-- <app-gallery-filters
    (filtersChangeEvent)="filtersChange($event)">
  </app-gallery-filters> -->

  <p class="subtitle3">{{'profile.gallery.title' | translate}}</p>


  <p class="amountUsers">{{totalElements}} REGISTROS</p>
  
  <table *ngIf="!waiting && !noData && !isMobile" mat-table [dataSource]="archivosTabla">
    
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> {{'profile.gallery.table.name' | translate}} </th>
      <td mat-cell *matCellDef="let archivo">
        <span class="body3Ligth">
          {{archivo.nombre}}
        </span>
      </td>
    </ng-container>
    
    
    <ng-container matColumnDef="uploadDate">
      <th mat-header-cell *matHeaderCellDef> {{'profile.gallery.table.uploadDate' | translate}} </th>
      <td mat-cell *matCellDef="let archivo">
        <span class="body3Ligth">
          {{archivo.creado| date:'dd/MM/yyy hh:mm'}}
        </span>
      </td>
    </ng-container>
    
    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef> {{'profile.gallery.table.type' | translate}} </th>
      <td mat-cell *matCellDef="let archivo">
        <span class="body3Ligth">
          {{archivo.tipoArchivo }}
        </span>
      </td>
    </ng-container>
    
    <ng-container matColumnDef="endDate">
      <th mat-header-cell *matHeaderCellDef> {{'profile.gallery.table.emision' | translate}} </th>
      <td mat-cell *matCellDef="let archivo">
        <span class="body3Ligth">
          {{archivo.vigencia | date:'dd/MM/yyy'}}
        </span>
      </td>
    </ng-container>
    
    
    <ng-container matColumnDef="actions" stickyEnd>
      <th mat-header-cell *matHeaderCellDef> {{'planes.table.actions' | translate}}</th>
      <td mat-cell *matCellDef="let archivo">
        <div class="flexActions">
          <button *ngIf="isStudent" mat-icon-button (click)="delete(archivo)">
              <mat-icon svgIcon="delete" id="deleteButton" class="delete"></mat-icon>
          </button>
          <button mat-icon-button (click)="download(archivo)">
              <mat-icon id="downloadButton" class="delete">file_download</mat-icon>
          </button>
          <!-- <button mat-icon-button (click)="getPreview(archivo)">
              <mat-icon *ngIf="!showPdf" id="visibilityButton" class="delete">visibility</mat-icon>
              <mat-icon *ngIf="showPdf" id="visibilityButton" class="delete">visibility_off</mat-icon>
          </button> -->
        </div>
        
      </td>
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  
  <div *ngIf="noData" class="flexCenter">
    <span class="subtitle1"> {{'profile.gallery.table.empty' | translate}}</span>
  </div>

  <div *ngIf="!waiting && !noData && isMobile">
    <div *ngFor="let archivo of archivosTabla">
      <mat-card class="cardProfile">

        <div class="flexBeetwen">
          <p class="subtitle3">{{archivo.nombre}}</p>

          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
  
          <mat-menu #menu="matMenu">
            
            <!-- <button (click)="getPreview(archivo)" id="openPopupArancel" mat-menu-item>
              <span>{{'profile.gallery.table.visibility' | translate}}</span>
            </button> -->

            <button (click)="download(archivo)" mat-menu-item>
              <span>{{'profile.gallery.table.download' | translate}} </span>
            </button>

            <button (click)="delete(archivo)" mat-menu-item>
              <span>{{'profile.gallery.table.delete' | translate}} </span>
            </button>
  
          </mat-menu>
        </div>

        <div>
          <span class="body3Ligth">{{'profile.gallery.table.uploadDate' | translate}}: </span>
          <span class="body3"> {{archivo.creado | date:'dd/MM/yyy hh:mm'}}</span>
        </div>

        <div>
          <span class="body3Ligth">{{'profile.gallery.table.emision' | translate}}: </span>
          <span class="body3"> {{archivo.vigencia | date:'dd/MM/yyy hh:mm'}}</span>
        </div>

        <div>
          <span class="body3Ligth">{{'profile.gallery.table.type' | translate}}: </span>
          <span class="body3">  {{archivo.tipoArchivo }}</span>
        </div>
      </mat-card>
    </div>
  </div>

  <div class="flexBeetwen">

    <button 
      *ngIf="isStudent && isMobile"
      mat-raised-button color="accent" 
      class="primarySmall" 
      (click)="openNewElementDialog()"> 
      {{'profile.gallery.new.buttonOpen' | translate}}
    </button>
    
    <app-paginator-complete *ngIf="!waiting" 
      [first]="first" 
      [last]="last" 
      [page]="page" 
      [totalPages]="totalPages"
      [size]="size" 
      [totalElements]="totalElements" 
      (loadMoreEvent)="loadMore($event)">
    </app-paginator-complete>
  </div>

</mat-card>