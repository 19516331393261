<div class="dialogContainer450">
    <div class="flexBeetwen">
        <p class="headline5"> {{'admin.domiciliacion.editArancel.title'  | translate}} </p>

        <button mat-button class="buttonDialogClose" mat-dialog-close>
            <mat-icon class="clearIcon">close</mat-icon>
        </button>
    </div>
    <form [formGroup]="arancelForm">
        <mat-form-field appearance="fill" class="inputfullWidth">
            <mat-label> {{ 'admin.domiciliacion.editArancel.input' | translate}} </mat-label>
            <input required matInput type="number" formControlName="arancel">      
        </mat-form-field>
    </form>
    <button mat-raised-button color="accent" [disabled]="!arancelForm.valid" (click)="onSubmit()" id="submit" class="primaryButton"> {{'section.dialogs.confirmButton' | translate}}</button>
</div>
