import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { environment } from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  private url = environment.apiUrl + '/';
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
    withCredentials: true,
  };
  multipartOptions = {
    /*headers: new HttpHeaders({
      'Content-Type': 'multipart/form-data'
    }),*/
    withCredentials: true,
  };
  constructor(private http: HttpClient) { }

  getProfile(): Observable<any> {
    return this.http.get<any>(this.url + 'api/usuario/miPerfil', this.httpOptions);
  }

  patchAvatar(files): Observable<any> {
    const formData = new FormData();
    if (files && files.length > 0) {
      files.forEach(file => {
        formData.append('file', file);
      });
    }
    const multipartOptions = {
      withCredentials: true,
    };
    return this.http.patch<any>(this.url + 'api/usuario/avatar', formData, this.multipartOptions);
  }

  uploadFile(event): Promise<string> {
    return new Promise((resolve, reject) => {
    let archivos: string[] = [];
    for (var i = 0; i < event.target.files.length; i++) {
      archivos.push(event.target.files[i]);
    }
    this.patchAvatar(archivos)
      .subscribe(async response => {
        // let avatarUrl = await this.getAvatarUrl();
        resolve("avatarUrl");
      },
        error => {
          console.error(error);
          reject();
        });
      })
  }

  /**
   * 
   * @param id - number, userId
   * @returns string, path to avatarUrl crated with createObjectUrl
   * ```ts
   * async getAvatar(usuarioId: number) {
   * this.avatarUrl = await this.imageService.getAvatarUrl(usuarioId);
   * }
   * ```
   * 
   * ```html
   * <img *ngIf="!avatarUrl" class="imgRoundedTable" src="../../../../../assets/user.svg">
   * <img *ngIf="avatarUrl" class="imgRoundedTable" [src]="avatarUrl | secure | async">
   * ```
   */
  public getAvatarUrl(id: number): Promise<string> {
    return new Promise((resolve, reject) => {
      let avatarUrl = "";
      this.downloadAvatarId(id).subscribe(response => {
        avatarUrl = URL.createObjectURL(response );
        resolve(avatarUrl);
      },
        error => {
          // console.error(error);
          reject();
        });
    });
    // return "";
  }

  public completeAvatarUrl(response) {
    return environment.apiUrl + response.avatarUrl;
  }
  public userHasImage(avatarUrl: string): boolean {
    let result: boolean = false;
    if (avatarUrl != "") {
      result = true;
    }
    return result;
  }

  downloadAvatar(): Observable<Blob> {
    return this.http.get(this.url + `api/usuario/avatar`, {
      withCredentials: true,
      responseType: 'blob'
    });
  }

  downloadAvatarId(id: number): Observable<Blob> {
    return this.http.get(this.url + `api/usuario/${id}/avatar`, {
      withCredentials: true,
      responseType: 'blob'
    });
  }

}
