<p *ngIf="isMobile" class="headline6Light"> {{posicion}}/{{cantidadPasosHabilitados}} {{ 'registration.steps.end' | translate}}</p>

<div class="flexCenter">
    <br />
    <div class="card2">
        <p class="headline5NoCenter">{{'registration.stepEnd.title'|translate}}</p>
        <p class="body3">{{'registration.stepEnd.text'|translate}}</p>
    </div>
    <br />
</div>
<br />

<mat-card *ngIf="pasosHabilitados.informacionPersonal && data && data.nombre" 
    class="cardCenter2" 
    color="primary">

        <div class="flexBeetwen">
            <p class="subtitle3">{{ 'forms.user.dataTitle' | translate}}</p>
            <button mat-icon-button (click)="changeCurrentStep(posicionStepOne)">
                <mat-icon >
                    edit
                </mat-icon>
            </button>
        </div>
        <p class="body2">Nombre y apellido: {{data.nombre}} {{data.apellido}}</p>
        <p class="body2">Fecha de nacimiento: {{data.fechaNacimiento | date: 'dd/MM/YYYY' }} </p>
        <p class="body2">Celular: {{data.prefijoCelular}} - {{data.numeroCelular}}</p>
</mat-card>

<mat-card  *ngIf="pasosHabilitados.grupoEntrenamiento"
    class="cardCenter2" 
    color="primary">
    <div class="flexBeetwen">
        <p class="subtitle3">{{'registration.stepTwo.titleMobile'|translate}}</p>
        <button mat-icon-button (click)="changeCurrentStep(posicionGrupoEntrenamiento)">
            <mat-icon >
                edit
            </mat-icon>
        </button>
    </div>
    <p class="body2"> 
        <span *ngIf="!online"> {{'registration.stepEnd.dayHour'|translate}}</span> 
        <span *ngIf="online"> {{'registration.stepEnd.type'|translate}}</span>
        :  {{grupoEntrenamiento}}
    </p>
    <div *ngIf="longDiasAdicionales > 0">
        <p class="body2"> {{'registration.stepEnd.days'|translate}}
            <span *ngIf="!online">{{'registration.stepEnd.extra'|translate}} </span> 
            : {{diasAdicionalesText}}
        </p>
    </div>
</mat-card>

<mat-card *ngIf="pasosHabilitados.antecedentes" class="cardCenter2" color="primary">
    <div class="flexBeetwen">
        <p class="subtitle3">{{ 'registration.stepThree.background' | translate}}</p>
        <button mat-icon-button (click)="changeCurrentStep(posicionAntecedentes)">
            <mat-icon >
                edit
            </mat-icon>
        </button>
    </div>

    <p class="body2">{{ 'registration.stepThree.done' | translate}}</p>
</mat-card>

<mat-card *ngIf="pasosHabilitados.marcasObjetivos" class="cardCenter2" color="primary">
    <div class="flexBeetwen">
        <p class="subtitle3">{{ 'profile.tabsTitles.achievementsObjetives' | translate}}</p>
        <button mat-icon-button (click)="changeCurrentStep(posicionMarcasObjetivos)">
            <mat-icon >
                edit
            </mat-icon>
        </button>
    </div>

    <div class="flexBeetwen" *ngFor="let marca of marcas">
        <p class="body2 marca">{{marca.distanciaMarca}} {{marca.distanciaDescripcion}}</p>
        <p class="body2 time">{{marca.tiempoMarcaHoras}}:{{marca.tiempoMarcaMinutos}}:{{marca.tiempoMarcaSegundos}}</p>
        <p class="body2">{{ marca.fechaMarca? (marca.fechaMarca | date: 'dd/MM/yyyy') : ('planes.objetivesPopup.noDate' | translate)}}</p>
    </div>

    <p class="body2" *ngIf="marcas.length == 0">{{'registration.stepAchObj.emptyMarcas' | translate}}</p>

    <br/>
    <mat-divider></mat-divider>
    <br/>

    <div class="flexBeetwen" *ngFor="let objetive of objetives">
        <p class="body2 marca">{{ objetive.fechaObjetivo? (objetive.fechaObjetivo | date: 'dd/MM/yyyy') : ('planes.objetivesPopup.noDate' | translate)}}</p>
        <p class="body2 time" id="objDescripcion">{{objetive.objetivoDescripcion}}</p>
    </div>

    <p class="body2" *ngIf="objetives.length == 0">{{'registration.stepAchObj.emptyObjetives' | translate}}</p>

</mat-card>

<mat-card *ngIf="pasosHabilitados.bancario" class="cardCenter2" color="primary">
    <div class="flexBeetwen">
        <p class="subtitle3">{{'registration.stepFour.title'|translate}}</p>
        <button mat-icon-button (click)="changeCurrentStep(posicionBancario)">
            <mat-icon >
                edit
            </mat-icon>
        </button>
    </div>

    <p class="body2">Banco: {{data.alumnosDatosBancario[0].entidad}} </p>
    <p class="body2">IBAN: {{data.alumnosDatosBancario[0].claveBancaria}}</p>
</mat-card>

<div *ngIf="!isMobile" class="cardCenter3">
    <div class="buttonInsideCard">
        <app-buttons 
            [enablePrevious]="true" 
            [end]="true">
        </app-buttons>
    </div>
</div>


<app-buttons 
    *ngIf="isMobile" 
    [enablePrevious]="true" 
    [end]="true">
</app-buttons>
    