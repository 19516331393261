import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DetalleFormularioDinamico, FormularioDinamicoPregunta, OpcionesMultiplesFormularioDinamicoPregunta, OpcionTextoFormularioDinamicoPregunta, OpcionCompuesta } from 'src/app/api/interfaces/formulario-dinamico';
import { AccionesOpcion, TiposPreguntaEnum } from 'src/app/enums/tiposPregunta';

@Component({
  selector: 'app-radio-checkbox-text-area-input',
  templateUrl: './radio-checkbox-text-area-input.component.html',
  styleUrls: ['./radio-checkbox-text-area-input.component.scss']
})
export class RadioCheckboxTextAreaInputComponent implements OnInit {

  @Input('requerido') requerido: boolean;
  @Input('opciones') opciones: DetalleFormularioDinamico[];
  @Input('pregunta') pregunta: FormularioDinamicoPregunta;
  @Input('cantCaracteres') cantCaracteres: number;

  @Output() textoIngresadoEvent = new EventEmitter<OpcionTextoFormularioDinamicoPregunta>();
  @Output() opcionesSeleccionadasEvent = new EventEmitter<OpcionesMultiplesFormularioDinamicoPregunta>();

  opcionesCompuestas: OpcionCompuesta[] = [];

  showTextAreaOthers: boolean = false;
  showTextArea: boolean = false;
  showCheckbox: boolean = false;

  radioGroupClass: string = "radioGroupColumn";
  radioButtonClass: string = "example-radio-button";

  placeholder: string = "";
  placeholderOthers: string = "";

  optionsForm = new FormGroup({
   valor: new FormControl(),
   others: new FormControl()
  });

  accionesOpcion = AccionesOpcion;
  tiposPreguntasEnum = TiposPreguntaEnum;

  constructor() { }

  ngOnInit(): void {

    this.opciones.forEach(opcion => {
      let opcionCompuesta: OpcionCompuesta = JSON.parse(opcion.detalleDescripcion);
      opcionCompuesta.seleccionado = false;
      this.opcionesCompuestas.push(opcionCompuesta);
    });

    if(this.pregunta.tipo == TiposPreguntaEnum.RADIO_HORIZONTAL) {
      this.radioGroupClass = "radioGroupRowWithMargin"
      this.radioButtonClass = "radioButtonHorizontal";
    }

    this.optionsForm.statusChanges
    .subscribe(val => {
      this.emitirTodosSeleccionados();
    });

    if(!this.cantCaracteres) {
      this.cantCaracteres = 200;
    }
  }

  /**
   * Emits null data, mainFormDinamico.component will use this to emit puedeContinuarEvent as false
   */
  emitirRespuesta() {
    const { valor, others } = this.optionsForm.getRawValue();
    const index: number = this.opcionesCompuestas.findIndex ( opcion  => opcion.accion == AccionesOpcion.HIDE_CHECK);
    const opcionTexto: OpcionTextoFormularioDinamicoPregunta = {
      preguntaId: this.pregunta.id,
      respuestaTexto: this.showTextArea ? valor + others:  this.opcionesCompuestas[index].texto 
    }
    this.textoIngresadoEvent.emit(opcionTexto);
  }

  emitirRespuestaNoValida() {
    const opcionTexto: OpcionTextoFormularioDinamicoPregunta = {
      preguntaId: this.pregunta.id,
      respuestaTexto: null
    }
    this.textoIngresadoEvent.emit(opcionTexto);
  }

  toggleCheckbox(aux: boolean) {
    this.showCheckbox = aux;
    aux ? this.optionsForm.value.valor = null : this.optionsForm.value.valor =  "No";
    this.opcionesCompuestas.forEach(opcion => opcion.seleccionado = false);
    if(!aux) {
      this.removeAllElements();
      this.emitirRespuesta();
    } else {
      this.emitirRespuestaNoValida();
    }
  }

  removeAllElements() {
    this.showCheckbox = false;
    this.showTextArea = false;
    this.showTextAreaOthers = false;
    this.optionsForm.patchValue({valor: ""});
    this.optionsForm.patchValue({others: ""});
  }

  /**
   * Change seleccionado value in selected opcion
   * @param i number, index opciones array
   */
   clickCheckbox(i: number, opcion: OpcionCompuesta) {
    this.opcionesCompuestas[i].seleccionado = !this.opcionesCompuestas[i].seleccionado;
    if(opcion.accion == AccionesOpcion.SHOW_TEXT_AREA2) {
      this.placeholderOthers = opcion.placeholder;
      this.showTextAreaOthers = this.opcionesCompuestas[i].seleccionado;
      this.showTextArea = this.opcionesCompuestas[i].seleccionado || this.showTextAreaFromOpciones();
    }
    if(opcion.accion == AccionesOpcion.SHOW_TEXT_AREA) {
      this.placeholder = opcion.placeholder;
      this.showTextArea = this.showTextAreaFromOpciones();
    }
    this.emitirTodosSeleccionados();
  }

  /**
   * 
   * @returns true if any checkbox is selected
   */
  showTextAreaFromOpciones(): boolean {
    const someCheckIsSelected: boolean = this.opcionesCompuestas.some( function(opcionCompuesta)  {
       return (opcionCompuesta.seleccionado && opcionCompuesta.accion == AccionesOpcion.SHOW_TEXT_AREA)
    });
    return this.showTextAreaOthers || someCheckIsSelected;
  }

  /**
   * Filter opciones and emit an string with all the selected options
   * 3313: changed array to string
   * If "others" input is visible and there is no value, call emitirRespuestaNoValida to prevent continue
   */
  emitirTodosSeleccionados() {
    const { others, valor} = this.optionsForm.getRawValue();
    const opcionesSeleccionadas: OpcionCompuesta[] = this.opcionesCompuestas.filter(opcion => opcion.seleccionado == true);
    let stringsSeleccionados: string = "";
    opcionesSeleccionadas.forEach( (opcion,index) => {
      if(opcion.accion != AccionesOpcion.SHOW_TEXT_AREA2) {
        if(index != 0) {
          stringsSeleccionados+= ", ";
        }
        stringsSeleccionados += opcion.texto;
      }
    });
    if(others) {
      stringsSeleccionados = stringsSeleccionados + ", " + others
    }
    if(valor) {
      const index: number = this.opcionesCompuestas.findIndex(opcion => opcion.accion == AccionesOpcion.SHOW_CHECK);
      // ejemplo:
      // Check1, Check2
      // Separador (placeholder en la opción que tiene el showCheck): entrada que ingresó el usuario
      stringsSeleccionados = stringsSeleccionados + ".<br/>" + this.opcionesCompuestas[index].placeholder + valor;
    }
    const opcionesMultiples: OpcionesMultiplesFormularioDinamicoPregunta = {
      preguntaId: this.pregunta.id,
      respuestas: stringsSeleccionados
    }
    this.opcionesSeleccionadasEvent.emit(opcionesMultiples);
    if(this.showTextAreaOthers && !others) {
      this.emitirRespuestaNoValida();
    }
  }
}
