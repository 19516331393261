import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog,  MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StudentService } from 'src/app/services/admin/student/student.service';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';
import { SnackbarService } from 'src/app/utils/snackbar/snackbar.service';

@Component({
  selector: 'app-resend-mail',
  templateUrl: './resend-mail.component.html',
  styleUrls: ['./resend-mail.component.scss']
})
export class ResendMailComponent implements OnInit {

  waiting: boolean = false;

  studentForm = new FormGroup({
    email: new FormControl(),
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private studentService: StudentService,
    private snack: SnackbarService,
    private sharedData: SharedDataService
  ) { }

  ngOnInit(): void {
    this.studentForm.patchValue({email: this.data.email});
  }

  onSubmit() {
    this.waiting = true;
    const userId: number = parseInt(sessionStorage.getItem('id_usuario'),10);
    const { email } = this.studentForm.getRawValue();

    this.studentService.resendEmail(email, userId).subscribe(response => {
      this.snack.snackTranslate('snacks.sucess.resend');
      this.waiting = false;
      sessionStorage.setItem('email',email);
      this.sharedData.changeMessage("reloadMail");
      this.dialog.closeAll();
    },
      error => {
        console.error(error);
        this.snack.openSnackBar(error.error.message);
        this.waiting = false;
      });
  }

}
