import { Component, Input, OnInit } from '@angular/core';
import { StatusEnum } from '../../../../enums/status';
import { StudentService } from '../../../../services/admin/student/student.service';
import { SnackbarService } from '../../../../utils/snackbar/snackbar.service';
import { SharedDataService } from '../../../../services/sharedData/shared-data.service';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from '../../../generic-dialog/generic-dialog.component';
import { MotivoBaja } from 'src/app/api/interfaces/user';
import { FormGroup, FormControl } from '@angular/forms';
import { TrainersService } from 'src/app/api/services/trainers/trainers.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-disable',
  templateUrl: './disable.component.html',
  styleUrls: ['./disable.component.scss']
})
export class DisableComponent implements OnInit {

  @Input('motivoBaja') motivoBaja: MotivoBaja;
  @Input('usuarioId') usuarioId: number;
  @Input('estado') estado: string;
  @Input('entrenadorId') entrenadorId: number;
  @Input('isAdminOrTrainer') isAdminOrTrainer: boolean;
  @Input('entrenadorIsAdmin') entrenadorIsAdmin: boolean;

  motivosBaja: MotivoBaja[] = [];

  showForm: boolean = false;
  
  disableForm = new FormGroup({
    motivoId: new FormControl(),
  });

  constructor(
    private studentService: StudentService,
    private snack: SnackbarService,
    private sharedData: SharedDataService,
    public dialog: MatDialog,
    private trainersService: TrainersService
  ) { }

  ngOnInit(): void {
    this.getMotivosBaja();
  }

  getMotivosBaja() {
    this.studentService.getMotivosBaja().subscribe(response => {
      this.motivosBaja = response;

    },
    error => {
      console.error(error);
      this.snack.snackTranslate('snacks.error.generic');
    });
  }

  onSubmit() {
    let aux: string = StatusEnum.ACTIVADO;
    let title: string = "¿Desea reactivar al alumno?";
    let text: string = "El alumno recuperará el acceso a la plataforma y  a su documentación."
    let confirm: string = "ACTIVAR";
    let cancel: string = "NO";
    if(this.estado == StatusEnum.ACTIVADO) {
      aux = StatusEnum.INACTIVE;
      title = "¿Desea dar de baja al alumno?";
      text = "El alumno no tendrá acceso a la plataforma. En el futuro podrás reactivarlo si lo deseas. Su documentación no se perderá."
      confirm  = "DESACTIVAR";
      cancel  = "NO";
    }
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      data: {
        title: title,
        iconClass: "bigGreyIcon",
        text: text,
        icon: "error_outline",
        confirm: confirm,
        cancel: cancel,
        showConfirmAsPrimary: true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if(!this.disableForm.value.motivoId) {
          this.disableForm.value.motivoId = "1";
        }
        this.studentService.cambiarEstado(this.usuarioId, aux, this.disableForm.value.motivoId).subscribe(response => {
          this.snack.snackTranslate('snacks.sucess.updatingData.generic');
          this.sharedData.changeMessage("updateStatus");
          this.showForm = false;
        },
        error => {
          this.snack.snackTranslate('snacks.error.generic');
        })
      }
    }); 
  }

  onSubmitTrainer() {
    let nuevoEstado: string = "";
    let cantAlumnos : number = 0;
    this.trainersService.getCantidadAlumnos(this.entrenadorId).subscribe(response => {
      cantAlumnos = response;
      this.estado == "A" ? nuevoEstado = "I" : nuevoEstado = "A";
      const condicional: boolean = this.estado == 'A' && cantAlumnos > 0;
      if(condicional) {
        this.dialog.open(GenericDialogComponent, {
          data: {
            title: `El entrenador tiene ${cantAlumnos} alumnos asignados`,
            iconClass: "bigGreyIcon",
            text: "Asigne los alumnos a otro entrenador y luego podrá darlo de baja",
            icon: "error_outline",
            cancel: "ENTENDIDO"
          }
        });
      } else {
        let aux: string = StatusEnum.ACTIVADO;
        let title: string = "¿Desea reactivar al entrenador?";
        let text: string = "El entrenador recuperará el acceso a la plataforma y  a su documentación."
        let confirm: string = "ACTIVAR";
        let cancel: string = "NO";
        
        if(this.estado == StatusEnum.ACTIVADO) {
          aux = StatusEnum.INACTIVE;
          title = "¿Desea dar de baja al entrenador?";
          text = "El entrenador no tendrá acceso a la plataforma. En el futuro podrás reactivarlo si lo deseas. Su documentación no se perderá."
          confirm  = "DESACTIVAR";
          cancel  = "NO";
        }
        const dialogRef = this.dialog.open(GenericDialogComponent, {
          data: {
            title: title,
            iconClass: "bigGreyIcon",
            text: text,
            icon: "error_outline",
            confirm: confirm,
            cancel: cancel
          }
        });
        
        
        
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            this.trainersService.cambiarEstado(this.entrenadorId, nuevoEstado).subscribe(response => {
              this.snack.snackTranslate('snacks.sucess.updatingData.generic');
              this.sharedData.changeMessage("updateStatusTrainer");
              this.showForm = false;
            },
            error => {
              this.snack.snackTranslate('snacks.error.generic');
            })
          }
        }); 
      }
    },
    error => {
      this.snack.snackTranslate('snacks.error.generic');
    })
  }

  toggleForm() {
    if(this.isAdminOrTrainer) {
      this.onSubmitTrainer();
    } else {
      this.showForm = !this.showForm
    }
  }

  chooseSubmitByRol() {
    if(this.isAdminOrTrainer) {
      this.onSubmitTrainer();
    } else {
      this.onSubmit();
    }
  }

}
