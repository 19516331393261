export enum TiposPreguntaEnum {
    RADIO = "radio",
    RADIO_HORIZONTAL = "radio horizontal",
    RADIO_TEXT_AREA = "radio textArea",
    CHECKBOX = "checkbox",
    SELECT = "select",
    TEXT = "text",
    TEXT_AREA = "textArea",
    RADIO_CHECKBOX_TEXT_AREA = "radio checkbox textArea"
}


export enum AccionesOpcion {
    SHOW_CHECK = "showCheck",
    HIDE_CHECK = "hideCheck",
    SHOW_TEXT_AREA = "showTextArea",
    SHOW_TEXT_AREA2 = "showTextArea2"
}