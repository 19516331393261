<div class="dialogContainer450NoPadding">
    <div [class]="titleClass">
        <div *ngIf="!isFeedback">
            <app-avatar
                *ngIf="usuarioId"
                [class]="'imgDialogMessage marginLeftOnly'"
                [containerClass]=""
                [usuarioId]="usuarioId">
            </app-avatar>
        </div>
        <p *ngIf="isFeedback" class="headline5">{{data.title | dateName: 'EEEE d - MMMM'}}</p>
        <p *ngIf="!isFeedback" class="headline5" id="name">{{fullName}}</p>
        <button mat-button mat-dialog-close id="close">
            <mat-icon class="blackIcon">close</mat-icon>
        </button>
    </div>
    <div class="dialogContent">

        <div *ngIf="isFeedback">

            <div class="flexCenter">

                <app-avatar
                    *ngIf="usuarioId"
                    [class]="'imgDialogMessage'"
                    [containerClass]=""
                    [usuarioId]="usuarioId">
                </app-avatar>

            </div>
            <p class="headline5">{{fullName}}</p>
            <div class="italicBox">
                <p class="italic">"{{datos.feedbackRespuesta ? datos.feedbackRespuesta : 'planes.feedback.emptyFeedbackDialog' | translate }}"</p>
            </div>
        </div>
        
        <br/>
        
        <form [formGroup]="feedbackForm">
            <mat-form-field appearance="fill" class="inputsFilterLarge">
                <mat-label *ngIf="isFeedback"> {{'forms.placeholder' | translate}} </mat-label>
                <mat-label *ngIf="!isFeedback"> {{'messages.placeholder' | translate}} </mat-label>
                <textarea (keyup.enter)="onSubmit()" matInput id="respuestaInput" formControlName="respuesta" type="text"></textarea>
                <button 
                    mat-icon-button 
                    matSuffix 
                    (click)="onSubmit()"
                    *ngIf="!waiting && feedbackForm.value.respuesta  && feedbackForm.value.respuesta.trim().length > 0">
                    <mat-icon>
                        send
                    </mat-icon>
                </button>
                <mat-hint>{{'planes.feedback.popupFooter' | translate}}</mat-hint>
            </mat-form-field>
        </form>
    </div>
</div>