<div *ngIf="message.mensajeTipo == 'FEEDBACK'" [class]="classFeedback">
    <div class="flexLeft">
        <mat-icon class="smallIcon">event</mat-icon>
        <div *ngIf="!isAdmin" class="body4" id="feedback">
            {{'messages.feedback' | translate}} {{fechaFeedback | date: 'dd/MM/YYY'}}
        </div>
        <div *ngIf="isAdmin" class="body4" id="feedback">
            {{'messages.feedbackAdmin' | translate}} {{fechaFeedback | date: 'dd/MM/YYY'}}
        </div>
    </div>
    <div class="smallItalic">
        "{{feedback}}"
    </div>
    <input *ngIf="autofocus" type="text" autofocus class="hiddenInput" tabindex="-1">
    <div *ngIf="autofocus" tabindex="1" #lastChat></div>
</div>

<div *ngIf="message.mensajeTipo == 'AVISOFEEDBACK'" [class]="classFeedback">
    <div class="flexLeft">
        <div class="body4 flexLeft" id="feedback">
            <div [class]="circleClass"></div> 
            Feedback del {{fechaFeedback | date: 'dd/MM/YYY'}}
        </div>
    </div>
    <input *ngIf="autofocus" type="text" autofocus class="hiddenInput" tabindex="-1">
    <div *ngIf="autofocus" tabindex="1" #lastChat></div>
</div>

<div [class]="class">
    <div class="body5">
        {{message.fecha | date: 'dd/MM/YYY - HH:mm'}}
    </div>
    <div class="body3">
        {{message.mensaje}}
    </div>
    <input *ngIf="autofocus" type="text" autofocus class="hiddenInput" tabindex="-1">
    <div *ngIf="autofocus" tabindex="1" #lastChat></div>
</div>