import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NavbarComponent } from '../navbar/navbar.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { HeaderComponent } from '../../login/header/header.component';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { MaterialModule } from '../../../material/material.module';
import { NavbarButtonComponent } from '../navbar-button/navbar-button.component';
import { HeaderComponent } from '../header/header.component';
import { SecurePipe } from '../../../pipes/secure/secure.pipe';
import { ReduceTextPipe } from '../../../pipes/reduceText/reduce-text.pipe';
import { NavigationButtonComponent } from '../navigation-button/navigation-button.component';
import { StatusComponent } from '../status/status.component';
import { TrainingTypeComponent } from '../training-type/training-type.component';
import { LevelComponent } from '../level/level.component';
import { DateNamePipe } from '../../../pipes/date-name/date-name.pipe';
import {  PaginatorCompleteComponent } from '../paginator-complete/paginator-complete.component';
import { FilesComponent } from '../files/files.component';
import { AvatarComponent } from '../avatar//avatar.component';
import { SkeletonComponent } from '../skeleton/skeleton.component';
import { IconComponent } from '../icon/icon.component';
import { SupportButtonComponent } from '../support/support-button/support-button.component';
import { SupportDialogComponent } from '../support/support-dialog/support-dialog.component';
import { SupportTypeOptionComponent } from '../support/support-type-option/support-type-option.component';
import { SupportTreeMainComponent } from '../support/tree/support-tree-main/support-tree-main.component';
import { SupportTreeUsefullComponent } from '../support/tree/support-tree-usefull/support-tree-usefull.component';
import { SupportTreeIterableElementsComponent } from '../support/tree/support-tree-iterable-elements/support-tree-iterable-elements.component';

@NgModule({
  declarations: [
    NavbarComponent,
    NavbarButtonComponent ,
    HeaderComponent,
    SecurePipe,
    ReduceTextPipe,
    NavigationButtonComponent,
    StatusComponent,
    TrainingTypeComponent,
    LevelComponent,
    DateNamePipe,
    PaginatorCompleteComponent,
    FilesComponent,
    AvatarComponent,
    SkeletonComponent,
    
    SupportButtonComponent,
    SupportDialogComponent,
    SupportTypeOptionComponent,
    SupportTreeMainComponent,
    SupportTreeUsefullComponent,
    SupportTreeIterableElementsComponent,
    IconComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,

    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    })
  ],
  exports: [
    NavbarComponent,
    HeaderComponent,
    SecurePipe,
    ReduceTextPipe,
    NavigationButtonComponent,
    StatusComponent,
    TrainingTypeComponent,
    LevelComponent,
    DateNamePipe,
    PaginatorCompleteComponent,
    FilesComponent,
    AvatarComponent,
    SkeletonComponent,
    IconComponent
  ]
})
export class GlobalModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}