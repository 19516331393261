<form [formGroup]="achievementForm" class="formLine" *ngIf="activo" (keydown.enter)="$event.preventDefault()">

    <mat-form-field *ngIf="isRuta" appearance="fill" class="inputFormLine2">
        <mat-label>{{ 'registration.stepThree.question8.choose' | translate}}</mat-label>
        <mat-select [disabled]="disable == true ? true : null" required formControlName="type">
            <mat-option (click)="addAchievement()" required id="routeSelectInput" class="body2" *ngFor="let option of routeOptions" [value]="option" [selected]="option.selected" >
                {{option.distanciaDescripcion}}
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="!isRuta" appearance="fill" class="inputFormLine2">
        <mat-label *ngIf="distanceKMeters">{{ 'registration.stepThree.question8.distanceKM' | translate}}</mat-label>
        <mat-label *ngIf="distanceMeters">{{ 'registration.stepThree.question8.distanceM' | translate}}</mat-label>
        <input [disabled]="disable == true ? true : null" required id="distanceInput" formControlName="distance" type="text"
        matInput (keyup)="addAchievement()">
    </mat-form-field>

    <div *ngIf="isMobile">
        <br/>
    </div>

    <mat-form-field appearance="fill" class="inputFormLine">
        <mat-label>{{ 'registration.stepThree.question8.time' | translate}}</mat-label>
        <input [disabled]="disable == true ? true : null" required id="distanceInput" formControlName="time" 
            type="text" 
            [mask]="timeMask"
            placeholder="hh:mm:ss"
            matInput 
            (keyup)="addAchievement()">
            <mat-hint *ngIf="achievementForm.value.time && achievementForm.value.time.length < 6"> {{'registration.stepThree.question8.timeHint' | translate}}</mat-hint>
    </mat-form-field>
    
    <mat-form-field appearance="fill" class="inputFormLine">
        <mat-label>{{ 'registration.stepThree.objetives.date' | translate}}</mat-label>
        <input id="dateInput" matInput disabled formControlName="date" [max]="maxDate"
        [matDatepicker]="picker">
        <mat-datepicker-toggle class="primaryIcon" matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker [disabled]="disable == true ? true : null" #picker>
            <mat-datepicker-actions>
                <button mat-button matDatepickerCancel>{{ 'calendar.cancel' | translate}}</button>
                <button mat-raised-button color="accent" matDatepickerApply (click)="addAchievement()">{{ 'calendar.save' | translate}}</button>
              </mat-datepicker-actions>
        </mat-datepicker>
    </mat-form-field>

    <div class="botonesObjetivos">



        <button *ngIf="disable && !isRegistration" (click)="toggleEdit()"  mat-icon-button [disabled]="lock">
            <mat-icon *ngIf="!lock" id="deleteButton" class="delete">edit</mat-icon>
            <mat-icon *ngIf="lock" class="greyIcon">edit</mat-icon>
        </button>

        <button [disabled]="!achievementForm.valid || (achievementForm.valid && achievementForm.value.time == '000000')" *ngIf="!disable && !isRegistration" (click)="saveAchievement()"  mat-icon-button>
            <mat-icon *ngIf="!achievementForm.valid || (achievementForm.valid && achievementForm.value.time == '000000')" class="greyIcon" id="deleteButton">save</mat-icon>
            <mat-icon *ngIf="achievementForm.valid && achievementForm.value.time != '000000'" id="deleteButton">save</mat-icon>  
        </button>

        <button (click)="removeAchievement()" mat-icon-button *ngIf="!isMobile">
            <mat-icon svgIcon="delete" id="deleteButton" class="delete"></mat-icon>
        </button>

        <button *ngIf="isMobile" class="deleteButton" mat-button (click)="removeAchievement()"> {{'registration.stepThree.delete' | translate}}</button>
    </div>
    
</form>