
import { Component, OnInit, Input} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IsSameObjectService } from 'src/app/services/isSameObject/is-same-object.service';
import { SuspenderAlumno, SuspensionAlumno, SuspensionMotivo } from '../../../../../api/interfaces/user';
import { StudentService } from '../../../../../services/admin/student/student.service';
import { MobileDetectorService } from '../../../../../services/mobileDetector/mobile-detector.service';
import { SnackbarService } from '../../../../../utils/snackbar/snackbar.service';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from 'src/app/components/generic-dialog/generic-dialog.component';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';

@Component({
  selector: 'app-inactividad-card',
  templateUrl: './inactividad-card.component.html',
  styleUrls: ['./inactividad-card.component.scss']
})
export class InactividadCardComponent implements OnInit {

  @Input('alumnoId') alumnoId: number;

  @Input('estado') estado: string;

  suspensionesPasadas: SuspensionAlumno[] = [];
  suspensiones : SuspensionAlumno[] = [];

  motivos: SuspensionMotivo[] = [];

  backgroundForm = new FormGroup({
    suspensiones: new FormControl(),
  });

  objetivosHasContent: boolean = false;
  suspensionesValid: boolean = false;
  suspensionesEmpty: boolean = false;
  showSuspensiones: boolean = false;
  showEnded: boolean = false;
  isMobile: boolean = false;
  checked: boolean = false;
  disable: boolean = true;
  noValid: boolean = true;

  iconClass: string = "";

  constructor(
    private mobileDetectorService: MobileDetectorService,
    private isSameObject: IsSameObjectService,
    private studentService: StudentService,
    private snack: SnackbarService,
    public dialog: MatDialog,
    private sharedData: SharedDataService
  ) { }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    if(this.suspensiones.length >= 1) {
      this.backgroundForm.patchValue({suspensiones: true});
      this.checked = true;
    }
    this.checkActivos();
    this.getSuspensiones();
    this.getMotivos();
  }

  changeSuspensiones(mode: boolean) {
    this.showSuspensiones = mode;
    this.suspensionesEmpty = false;
    this.checked = !this.checked;
    if(!mode) {
      this.suspensionesValid = true;
      sessionStorage.removeItem('suspensiones');
      this.deleteAll();
    } else {
      this.suspensionesValid = false;
    }
    this.checkActivos();
  }

  deleteAll() {
    if(!this.disable) {
      this.suspensiones = null;
    }
    this.checkActivos();
  }

  edit() {
    this.disable = !this.disable;
    this.checkActivos();
    if(this.disable) {
      this.suspensiones.forEach((suspension,index) => {
        if(suspension.add) {
          this.postSuspension(index);
        }
      });
    }
  }

  emptySuspensiones(mode: boolean) {
    this.suspensionesEmpty = true;
    this.iconClass = "greyIcon";
  }

  addSuspensionesEvent(aux: SuspensionAlumno) {
    if(this.suspensiones[aux.i]) {
      this.suspensiones[aux.i].fechaDesde = aux.fechaDesde;
      this.suspensiones[aux.i].fechaHasta = aux.fechaHasta;
      this.suspensiones[aux.i].suspensionMotivoId = aux.suspensionMotivoId;
      this.suspensiones[aux.i].add = true;
      if(aux.fechaDesde && aux.fechaHasta && aux.suspensionMotivoId) {
        if(aux.fechaDesde > aux.fechaHasta) {
          this.noValid = true;
        } else {
          this.noValid = false;
        }
      }
    }
  }

  checkActivos() {
    // this.objetivosHasContent  = this.suspensiones.some(element => element.activo);
  }

  getSuspensiones() {
    this.suspensiones = [];
    this.studentService.getSuspensionesAlumno(this.alumnoId).subscribe(response => {
      if(response.length == 0) {
        this.emptySuspensiones(true);
      } else {
        this.suspensionesEmpty = false;
      }
      let suspensionesAux: SuspensionAlumno[] = response;
      suspensionesAux.forEach(suspension => {
        //flags auxiliares
        suspension.delete = false;
        suspension.add = false;
        suspension.editable = false;
        //discriminacion por fecha pasada
        if(new Date(suspension.fechaHasta) < new Date()) {
          this.suspensionesPasadas.push(suspension);
        } else {
          this.suspensiones.push(suspension);
        }
      });
    },
    error => {
      this.snack.snackTranslate('snacks.error.generic');
    });
  }

  getMotivos() {
    this.suspensiones = [];
    this.studentService.getSuspensionesMotivos().subscribe(response => {
      this.motivos = response;
    },
    error => {
      this.snack.snackTranslate('snacks.error.generic');
    });
  }

  removeSuspensionesEvent(aux) {
    if(aux.id) {
     this.delete(aux.id);
    } else {
      this.suspensiones.splice(aux.i,1);
      this.disable = !this.disable;
    }
    
  }

  addSuspension() {
    this.suspensiones.push({ fechaDesde: "", fechaHasta: "", suspensionMotivoId: 0, editable: true });
  }

  delete(i: number) {
    this.studentService.deleteSuspension(i).subscribe(response => {
      this.snack.snackTranslate('snacks.sucess.removeSuspension');
      this.getSuspensiones();
      this.sharedData.changeMessage("updateStatus");
    },
    error => {
      this.snack.snackTranslate('snacks.error.generic');
    });    
  }

  postSuspension(i: number) {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      data: {
        title: "¿Desea crear una baja parcial?",
        iconClass: "bigGreyIcon",
        text: "El alumno dejará de recibir el plan de entrenamiento durante el plazo seleccionado. Luego del mismo, la reactivación será automática.",
        icon: "error_outlined",
        cancel: "NO",
        confirm: "CREAR"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        const suspender: SuspenderAlumno = {
          fechaDesde: this.suspensiones[i].fechaDesde,
          fechaHasta: this.suspensiones[i].fechaHasta,
          suspensionMotivoId: this.suspensiones[i].suspensionMotivoId,
        }
        this.studentService.postSuspenderAlumno(this.alumnoId,suspender).subscribe(response => {
          this.snack.snackTranslate('snacks.sucess.addSuspension');
          this.getSuspensiones();
        },
        error => {
          this.snack.openSnackBar(error.error.message);
          this.disable = false;
        });    
      }
    });
  }

  toggleEnded() {
    this.showEnded = !this.showEnded;
  }

}

