import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Level } from '../../../api/interfaces/user';

@Component({
  selector: 'app-level',
  templateUrl: './level.component.html',
  styleUrls: ['./level.component.scss']
})
export class LevelComponent implements OnInit {

  @Input('level') level: string;
  @Input('edit') edit: boolean;
  @Input('levels') levels?: Level[];
  @Input('isAdmin') isAdmin?: boolean;
  @Input('userForm') userForm?: any;
  @Input('status') status?: string;

  @Output() saveLevelEvent = new EventEmitter<Level>();

  constructor() { }

  ngOnInit(): void {
  }

  saveLevel(level: Level) {
    this.saveLevelEvent.emit(level);
  }

}
