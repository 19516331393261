<form [formGroup]="optionsForm">
    <p class="subtitle1 textLeft">{{pregunta.titulo}} <span *ngIf="requerido"> *</span></p>

    <mat-radio-group 
        aria-labelledby="radioGroupColumn" 
        [class]="radioGroupClass">

            <mat-radio-button 
                color="primary" 
                value="null" 
                (click)="toggleTextArea(true)">
                    {{'registration.stepThree.genericAnswers.yes' | translate}}
            </mat-radio-button>

            <mat-form-field *ngIf="showTextArea" appearance="fill" class="inputfullWidth">
                <mat-label>{{pregunta.placeholder}}</mat-label>
                <textarea 
                    *ngIf="showTextArea"
                    maxLength={{cantCaracteres}}
                    aria-labelledby="textAreaInput" 
                    id="textAreaInput" 
                    formControlName="valor"
                    type="text"
                    matInput 
                    [required]="requerido">
                </textarea>
            
                <mat-hint align="end">{{optionsForm.value.valor?.length || 0}} / {{cantCaracteres}}</mat-hint>
                <mat-hint *ngIf="pregunta.textoAsistivo">{{pregunta.textoAsistivo}}</mat-hint>
            </mat-form-field>
            
            <mat-radio-button 
                color="primary" 
                value="no" 
                (click)="toggleTextArea(false)">
                {{'registration.stepThree.genericAnswers.no' | translate}}
            </mat-radio-button>
                
        </mat-radio-group>

</form>