import { Component, OnInit } from '@angular/core';
import { MessageListOption, Message, MassiveMessage, Canal, Mensaje } from '../../../../api/interfaces/messages';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from "@angular/material/icon";
import { RoleString } from 'src/app/enums/role';
import { RolService } from 'src/app/services/rol/rol.service';
import { SnackbarService } from 'src/app/utils/snackbar/snackbar.service';
import { MessagesService } from 'src/app/api/services/messages/messages.service';
import { TrainingDays } from 'src/app/api/interfaces/register';
import { Level, TType } from 'src/app/api/interfaces/user';
import { RegisterService } from 'src/app/api/services/register/register.service';
import { TrainingService } from 'src/app/api/services/training/training.service';
import { TrainingGroupEnum } from 'src/app/enums/training-type';
import { NivelesEnum } from 'src/app/enums/niveles';

@Component({
  selector: 'app-conversation-massive',
  templateUrl: './conversation-massive.component.html',
  styleUrls: ['./conversation-massive.component.scss']
})
export class ConversationMassiveComponent implements OnInit {

  message: MessageListOption =  null;

  /**
   * 4050, prevent open massive form if an error ocurred
   */
  preventOpenMassive: boolean = false;
  moreMessagesAvalaible: boolean = true;
  showForm: boolean = false;
  isAdmin: boolean = true;
  loaded: boolean = false;

  CANTIDAD_ELEMENTOS: number = 10;
  pagina: number = 0;

  canal: Canal = null;

  messages: Mensaje [] = [];

  allLevels: Level[] = [];
  allTrainingDays: TrainingDays[] = [];
  allTypes: TType[] = [];

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private rol: RolService,
    private messagesService: MessagesService,
    private snack: SnackbarService,
    private registerService: RegisterService,
    private trainingService: TrainingService
  ) { 
    this.matIconRegistry.addSvgIcon(
      `megaphone`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../assets/megaphone.svg')
    );
  }

  ngOnInit(): void {
    this.isAdmin = this.rol.isAdmin();
    this.loadMessages();
  }

  loadMessages() {
    if(this.pagina == 0) {
      this.messages = [];
    }
    this.messagesService.getTodosMensajesMasivos(this.CANTIDAD_ELEMENTOS, this.pagina).subscribe(response => {
      const messages: Mensaje[] = response.content;
      this.moreMessagesAvalaible = false;

      //si pagina es cero quiero que el array sea la respuesta, sino debo agregar mas al final y conservar los anteriores
      if(this.pagina == 0) {
        this.messages = messages.reverse();
      } else {
        messages.forEach(aux => this.messages.unshift(aux));
      }

      if(response.totalPages > (this.pagina + 1)) {
        this.moreMessagesAvalaible = true;
      } else {
        this.moreMessagesAvalaible = false;
      }

      // this.messagesService.getMensajesMasivos(this.CANTIDAD_ELEMENTOS,this.pagina+1).subscribe(response => {
      //   if(response.length > 0) {
      //     this.moreMessagesAvalaible = true;
      //   } else {
      //     this.moreMessagesAvalaible = false;
      //   }
      // }, error => {
      //   console.error(error);
      //   this.snack.snackTranslate('snacks.error.generic');
      // });
      this.getTrainingDays();
    }, error => {
      console.error(error);
      this.preventOpenMassive = true;
      if(error.error && error.error.message) {
        this.snack.openSnackBarWithAction(error.error.message, 'X');
      } else {
        this.snack.snackTranslate('snacks.error.generic');
      }
    });
  }

  newMessageEvent(message: Mensaje) {
    this.messages.push(message);
    this.toggleNewMassive();
  }

  toggleNewMassive() {
    if(!this.preventOpenMassive) {
      this.showForm = ! this.showForm;
    }
  }

  onUpMessages() {
    if(this.moreMessagesAvalaible) {
      this.pagina++;
      this.loadMessages();
      }
  }

  closeEvent() {
    this.showForm = false;
  }

  loadLevels() {
    this.trainingService.getLevels().subscribe( response=> {
      this.allLevels = response;
      let remover: number = this.allLevels.findIndex(option => option.entrenamientoNivelDescripcion == NivelesEnum.noDefinido);
      if(remover != -1) {
        this.allLevels.splice(remover, 1);
      }
      this.getTrainingTypes();
    },
    error => {
      console.error(error);
    });
  }

  getTrainingTypes() {
    this.registerService.getTrainingTypes()
    .subscribe(response => {
      this.allTypes= response;
      this.loaded = true;
    },
      error => {
        console.error(error);
      });
  }

  getTrainingDays () {
    this.registerService.getTrainingDays()
    .subscribe(response => {
      this.allTrainingDays = response;
      //remover no definido
      let index = this.allTrainingDays.findIndex(element => element.grupoDescripcion == TrainingGroupEnum.noDefinido);
      if(index != -1) {
        this.allTrainingDays.splice(index,1);
      }
      this.loadLevels();
    },
      error => {
        console.error(error);
      });
  }
}
