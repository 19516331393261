import { Injectable } from '@angular/core';
import { SnackbarService } from 'src/app/utils/snackbar/snackbar.service';

@Injectable({
  providedIn: 'root'
})
export class DetectConnectionService {

  constructor(
    private snack: SnackbarService,
  ) { }

  detector() {
    window.addEventListener('offline', () => {
      this.snack.snackTranslateWithAction('connection.offline','X');
    });
    
    window.addEventListener('online', () => {
      this.snack.snackTranslateWithAction('connection.online','X');
      sessionStorage.setItem('loadingComponent','true');
      if(sessionStorage.getItem('loadingComponent')) {
        sessionStorage.removeItem('loadingComponent');
        window.location.reload();
      }
    });

   
  }
}
