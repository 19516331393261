import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog'
import { AllAchievements } from 'src/app/api/interfaces/user';
import { AntecedentesEnum } from 'src/app/enums/antecedentes';
import { MobileDetectorService } from 'src/app/services/mobileDetector/mobile-detector.service';
import { HoursDayFixedService } from 'src/app/utils/hoursDayFixed/hours-day-fixed.service';
import { ParseTimeService } from 'src/app/utils/parseTime/parse-time.service';
import { AntecedentesMarcas, FiltratedObjetives } from '../../../api/interfaces/register';
import { UserService } from '../../../api/services/user/user.service';

@Component({
  selector: 'app-objetives-dialog',
  templateUrl: './objetives-dialog.component.html',
  styleUrls: ['./objetives-dialog.component.scss']
})
export class ObjetivesDialogComponent implements OnInit {


  objetives: FiltratedObjetives[] = [];
  marcas: AntecedentesMarcas[] = [];

  allAchievements: AllAchievements = {
    route: null,
    pista: null,
    ultra: null,
    trail: null
  };
  
  fullName: string = "";
  loaded: boolean = false;
  isMobile: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,
    private parseTimeService: ParseTimeService,
    private hoursDayFixed: HoursDayFixedService,
    private mobileDetectorService: MobileDetectorService
    ) { }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    this.getUserProfileData();
  }

  getUserProfileData() {
    this.userService.getProfileUserId(this.data.alumnoId).subscribe(response => {
      let objetives: FiltratedObjetives[] = response.objetivo;
      objetives.sort(function (a, b) {
        var key1 = new Date(a.fechaObjetivo);
        var key2 = new Date(b.fechaObjetivo);
    
        if (key1 < key2) {
            return -1;
        } else if (key1 == key2) {
            return 0;
        } else {
            return 1;
        }
    });
      objetives.forEach( objetivo => {
          //check expired
          
          if(objetivo.fechaObjetivo) {
            if(new Date(objetivo.fechaObjetivo).setHours(12) < new Date().setHours(11)) {
              objetivo.activo = false;
            }
            objetivo.fechaObjetivo = this.hoursDayFixed.getDateFixed(objetivo.fechaObjetivo);
          }
          //not else due to backend can sed objetivo.activo = false
          if(objetivo.activo) {
            this.objetives.push(objetivo);
          }
      });

      this.fullName = `${response.nombre} ${response.apellido} ${response.segundoApellido}`;

      let aux: AntecedentesMarcas[] = [];
      this.allAchievements.route = [];
      this.allAchievements.pista = [];
      this.allAchievements.ultra = [];
      this.allAchievements.trail = [];

      response.antecedentesMarcas.forEach( marca => {
        if(marca.fechaMarca) {
          marca.fechaMarca = this.hoursDayFixed.getDateFixed(marca.fechaMarca);
        }
      });

      aux =  response.antecedentesMarcas.filter(antecedente => antecedente.antecedentePreguntaDetalleId == AntecedentesEnum.RUTA);
      if(aux) {
        aux.forEach(achievement => {
          achievement.valid = true;
          if(achievement.activo) {
            achievement.time = this.parseTimeService.parseToString(achievement.tiempoMarcaHoras, achievement.tiempoMarcaSegundos, achievement.tiempoMarcaMinutos);
            this.allAchievements.route.push(achievement);
          }
        });
      }

      aux =  response.antecedentesMarcas.filter(antecedente => antecedente.antecedentePreguntaDetalleId == AntecedentesEnum.PISTA);
      if(aux) {
        aux.forEach(achievement => {
          achievement.valid = true;
          if(achievement.activo) {
            achievement.time = this.parseTimeService.parseToString(achievement.tiempoMarcaHoras, achievement.tiempoMarcaSegundos, achievement.tiempoMarcaMinutos);
            this.allAchievements.pista.push(achievement);
          }
        });
    }

    aux =  response.antecedentesMarcas.filter(antecedente => antecedente.antecedentePreguntaDetalleId == AntecedentesEnum.ULTRA);
    if(aux) {
      aux.forEach(achievement => {
        achievement.valid = true;
        if(achievement.activo) {
          achievement.time = this.parseTimeService.parseToString(achievement.tiempoMarcaHoras, achievement.tiempoMarcaSegundos, achievement.tiempoMarcaMinutos);
          this.allAchievements.ultra.push(achievement);
        }
      });
    }

    aux =  response.antecedentesMarcas.filter(antecedente => antecedente.antecedentePreguntaDetalleId == AntecedentesEnum.TRAIL);
    if(aux) {
      aux.forEach(achievement => {
        achievement.valid = true;
        if(achievement.activo) {
          achievement.time = this.parseTimeService.parseToString(achievement.tiempoMarcaHoras, achievement.tiempoMarcaSegundos, achievement.tiempoMarcaMinutos);
          this.allAchievements.trail.push(achievement);
        }
      });
    }

      this.loaded = true;
    },
      error => {
        console.error(error);
      })
  }

}
