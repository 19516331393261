import { Component, OnInit, Input} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FilterDataService } from 'src/app/services/filterData/filter-data.service';
import { IsSameObjectService } from 'src/app/services/isSameObject/is-same-object.service';
import { FiltratedObjetives } from '../../../../api/interfaces/register';
import { MobileDetectorService } from '../../../../services/mobileDetector/mobile-detector.service';

@Component({
  selector: 'app-objetives-card',
  templateUrl: './objetives-card.component.html',
  styleUrls: ['./objetives-card.component.scss']
})
export class ObjetivesCardComponent implements OnInit {

  @Input('objetives') objetives : FiltratedObjetives[];
  @Input('alumnoId') alumnoId: number;
  disable: boolean = true;
  isMobile: boolean = false;
  backgroundForm = new FormGroup({
    objetives: new FormControl(),
  });
  showObjetives: boolean = false;
  objetivesValid: boolean = false;
  checked: boolean = false;
  objetivesEmpty: boolean = false;
  objetivosHasContent: boolean = false;
  constructor(
    private mobileDetectorService: MobileDetectorService,
    private filterData: FilterDataService,
    private isSameObject: IsSameObjectService,
  ) { }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    if(this.objetives.length >= 1) {
      this.backgroundForm.patchValue({objetives: true});
      this.checked = true;
    }
    this.checkActivos();
  }

  changeObjetives(mode: boolean) {
    this.showObjetives = mode;
    this.objetivesEmpty = false;
    this.checked = !this.checked;
    if(!mode) {
      this.objetivesValid = true;
      sessionStorage.removeItem('objetives');
      this.deleteAll();
    } else {
      this.objetivesValid = false;
    }
    this.checkActivos();
  }

  deleteAll() {
    if(!this.disable) {
      this.objetives = null;
    }
    this.checkActivos();
  }

  edit() {
    this.disable = !this.disable;
    this.checkActivos();
  }

  onSubmit() {
    this.edit();
    //api call
  }

  emptyObjetives(mode: boolean) {
    //not done, not required in this sprint
    this.objetivesEmpty = true;
  }

  saveObjetivesEditEvent(objetives) {
    if(objetives) {
      if(objetives.length > 0 && this.objetives.length > 0) {
        if(!this.isSameObject.isSame(objetives, this.objetives)) {
          this.objetives = objetives;
        }
      }
      if(objetives.length > 0 && this.objetives.length == 0) {
        this.objetives = objetives;
      }
    }
    if(!this.objetives) {
      this.objetives = [];
    }
    const empty = this.objetives.findIndex(objetivo => objetivo.objetivoDescripcion == '');
    //correcion si se agrega alguno vacio
    if(empty != -1) {
      this.objetives.splice(empty, 1);
    }
    this.checkActivos();
  }

  checkActivos() {
    this.objetivosHasContent  = this.objetives.some(element => element.activo);
  }

}
