import { Component, OnInit } from '@angular/core';
import { PreguntaFrecuente, SubSeccionAyuda } from 'src/app/api/interfaces/help';
import { MobileDetectorService } from '../../../../services/mobileDetector/mobile-detector.service';

/**
 * Create help arrays and call components so student can read help
 */
@Component({
  selector: 'app-main-help',
  templateUrl: './main-help.component.html',
  styleUrls: ['./main-help.component.scss']
})
export class MainHelpComponent implements OnInit {

  isMobile: boolean = false;

  preguntasFrecuentesCero : PreguntaFrecuente[] =[];
  preguntasFrecuentesPlanes : PreguntaFrecuente[] =[];
  preguntasFrecuentesComunicacion : PreguntaFrecuente[] =[];
  preguntasFrecuentesAdicional : PreguntaFrecuente[] =[];
  preguntasFrecuentesPerfil : PreguntaFrecuente[] =[];
  equipoNombre: string = "";

  constructor(
    private mobileDetectorService: MobileDetectorService,
    ) { }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    sessionStorage.setItem('active', 'ayuda');
    this.equipoNombre = localStorage.getItem('equipoNombre');
    this.loadPreguntas();
  }

  loadPreguntas() {
    const subSeccionAyudaCero: SubSeccionAyuda = {
      id: 0,
      nombre: "Marcas y objetivos"
    };
    const subSeccionAyudaPlanes: SubSeccionAyuda = {
      id: 1,
      nombre: "Entrenamiento"
    };
    const subSeccionAyudaComunicacion: SubSeccionAyuda = {
      id: 2,
      nombre: `Comunicación con ${this.equipoNombre}`
    };
    const subSeccionAyudaAdicional: SubSeccionAyuda = {
      id: 3,
      nombre: "Contenido Adicional"
    };
    const subSeccionAyudaPerfil: SubSeccionAyuda = {
      id: 4,
      nombre: "Configuración de Perfil"
    };


    //seccion marcas y objetivos
    let pregunta: PreguntaFrecuente = {
      titulo: "¿Cómo registro mis marcas y objetivos?",
      texto: "Las marcas y objetivos son de gran importancia para que podamos crear planes 100% personalizados, enfocándonos en tus metas. Si no las agregaste al registrarte, puedes hacerlo en cualquier momento <b>siguiendo los siguientes pasos</b>: <br/> 1- Ingresa a la sección <b>“Marcas y objetivos”</b> situada en el menú <br/>2- Si deseas agregar <b>“Marcas”</b> presiona en el botón <b>“Editar”</b> (situado a la derecha del título “Marcas”). Luego visualizarás distintos tipos de marcas que podrás agregar. Presiona en <b>“Agregar”</b> sobre la marca que quieras registrar. Luego podrás completar el tiempo y la fecha de la misma <br/>3-  Si deseas agregar “Objetivos” presiona en el botón “Editar” (situado al lado del título “Objetivos”). Presiona en “Agregar” y podrás nombrar tantos objetivos como lo desees colocándole una fecha límite para cumplirlos",
      subSeccionAyuda: subSeccionAyudaCero
    }
    this.preguntasFrecuentesCero.push(pregunta);

    //seccion planes, renombrada a entrenamiento
    pregunta = {
      titulo: "¿Cada cuanto tiempo recibo el plan de entrenamiento?",
      texto: `En ${this.equipoNombre} queremos que la experiencia sea 100% personalizada y de seguimiento continuo. Es por eso que recibirás un <b>plan de entrenamiento semanal</b>, que se ajustará a tus objetivos y forma física.`,
      subSeccionAyuda: subSeccionAyudaPlanes
    }

    this.preguntasFrecuentesPlanes.push(pregunta);

    pregunta = {
      titulo: "¿Cómo doy feedback sobre mi entrenamiento diario?",
      texto: `Dar feedback sobre el día de entrenamiento es fundamental para que desde ${this.equipoNombre} podamos ir ajustando el plan a tus necesidades y nivel. Solo <b>podrás dar feedback sobre días de la semana en curso.</b> Puedes hacerlo <b>siguiendo los siguientes pasos</b>:<br/> 1- Ingresa a la sección de <b>“Planes”</b> situada en el menú<br/>2- <b>Selecciona el día</b> en el cual quieres dejar feedback<br/>3- Allí visualizarás las actividades que lo conforman y el título <b>“¿Cómo te has sentido hoy?”</b> donde podrás indicar cómo te has sentido a través de un sistema de semáforos. Vaya bien, regular o mal siempre podrás dejarnos resumen y comentarios de tu entrenamiento.`,
      subSeccionAyuda: subSeccionAyudaPlanes
    }

    this.preguntasFrecuentesPlanes.push(pregunta);


    pregunta = {
      titulo: "¿Cómo descargo mi plan en PDF?",
      texto: "Si quieres tener tu plan de entrenamiento en PDF debes seguir los siguientes pasos:<br/><br/>1- Ingresa a la sección de <b>“Planes”</b> situada en el menú<br/>2- Selecciona la <b>semana de entrenamiento</b> que quieres visualizar<br/>3- Presiona sobre el botón <b>“Descargar plan”</b> situado en la parte superior derecha de la pantalla<br/>4- Tu plan se descargará y se abrirá en una pestaña nueva dentro del navegador",
      subSeccionAyuda: subSeccionAyudaPlanes
    }



    this.preguntasFrecuentesPlanes.push(pregunta);

    pregunta = {
      titulo: "¿Cómo modifico mis días de entrenamiento?",
      texto: 'Si quieres modificar la disponibilidad de tus días de entrenamiento debes seguir los siguientes pasos:<br/><br/>1- Ingresa a la sección <b>“Mensajes”</b> situada en el menú<br/>2- Una vez dentro, presiona sobre tu entrenador/a<br/>3- Presiona sobre el espacio dispuesto para escribir y solicítale los cambios en tus días de entrenamiento<br/>4- Tu entrenador/a será notificado sobre el mensaje y realizará el cambio desde su perfil administrador<br/>5- Luego de hacerlo podrás verlo reflejado en la sección "Mis planes" a partir de la próxima semana',
      subSeccionAyuda: subSeccionAyudaPlanes
    }

    this.preguntasFrecuentesPlanes.push(pregunta);


    //seccion de comunicacion
   
    pregunta = {
      titulo: "¿Cómo puedo comunicarme con mi entrenador/a?",
      texto: `En ${this.equipoNombre} la comunicación y el seguimiento contínuo son dos pilares fundamentales que nos diferencian. Por eso es importante poder responder a cada duda que te vaya surgiendo. Para comunicarte con tu entrenador/a debes seguir los siguientes pasos:<br/><br/>1- Ingresa a la sección <b>“Mensajes”</b> situada en el menú<br/>2- Una vez dentro, <b>presiona sobre tu entrenador/a</b><br/>3- Presiona sobre el espacio dispuesto para escribir y <b>envíale un mensaje</b><br/>4- Tu entrenador/a será notificado sobre el mensaje y te responderá durante el transcurso del día`,
      subSeccionAyuda: subSeccionAyudaComunicacion
    }

    this.preguntasFrecuentesComunicacion.push(pregunta);

    pregunta = {
      titulo: `¿Cómo me entero de las novedades de ${this.equipoNombre}?`,
      texto: `En ${this.equipoNombre} estamos en constante comunicación con nuestros alumnos/as. Por eso creamos las “Novedades ${this.equipoNombre}”.  Aquí notificamos a nuestros alumnos/as sobre distintas novedades de gran importancia: suspensión de clases por cierre de parque, saludos de fin de año, tips, etc. Para ingresar a verlas debes seguir los siguientes pasos:<br/><br/>1- Ingresa a la sección <b>“Mensajes”</b> situada en el menú <br/>2- Una vez dentro, presiona sobre <b>“Novedades ${this.equipoNombre}”</b><br/>3- Estas novedades son meramente informativas y <b>no puedes responder a ellas</b>. Si tienes dudas, comunicate directamente con tu entrenador/a`,
      subSeccionAyuda: subSeccionAyudaComunicacion
    }

    this.preguntasFrecuentesComunicacion.push(pregunta);


    //seccion contenido adicional

    pregunta = {
      titulo: "¿Cómo descargo el contenido de trabajo complementario y nutrición?",
      texto: "Si quieres tener los materiales de trabajo complementario y nutrición en PDF debes seguir los siguientes pasos:<br/><br/>1- Ingresa a la sección de <b>“Trabajo complementario” o “Nutrición”</b>, según lo que sea de tu interés en ese momento<br/>2- Presiona sobre el documento que quieres visualizar ubicado en la barra lateral izquierda<br/>3- Visualiza el contenido del archivo a la derecha y presiona sobre el botón <b>“Descargar PDF”</b> situado en la parte superior derecha de la pantalla<br/>4- El archivo se descargará y se abrirá en una pestaña nueva dentro del navegador",
      subSeccionAyuda: subSeccionAyudaAdicional
    }

    this.preguntasFrecuentesAdicional.push(pregunta);

    //seccion perfil

    pregunta = {
      titulo: "¿Cómo cargo mi foto de perfil?",
      texto: "Si quieres subir tu foto de perfil o quieres cambiarla, debes <b>seguir los siguientes pasos</b><br/>1- Presiona en el avatar ubicado en la parte superior derecha de la pantalla<br/>2- Se desplegarán dos opciones. Presiona sobre <b>“Mi perfil”</b><br/>3- Dentro de “Mi perfil” presiona sobre el avatar<br/>4- <b>Elige la foto</b> que quieras adjuntar, ya sea desde tu móvil u ordenador. Ten en cuenta que el archivo debe tener un formato de imagen y su peso no puede superar los 10 MB.<br/>5- Una vez seleccionada y confirmada, deberás esperar unos segundos a que se complete la carga y ya ¡Tu foto quedará actualizada!",
      subSeccionAyuda: subSeccionAyudaPerfil
    }

    this.preguntasFrecuentesPerfil.push(pregunta);

    pregunta = {
      titulo: "¿Cómo cambio mi contraseña?",
      texto: `Para cambiar tu contraseña o recuperarla debes seguir los siguientes pasos:<br/><br/>1- Ingresa a la <b>pantalla de inicio</b> de la plataforma (www.iutrein.com)<br/>2- Presiona sobre el botón <b>“Recuperar contraseña”</b><br/>3- El sistema te solicitará que ingreses el <b>email con el que fuiste registrado</b> en la plataforma. Luego de ingresarlo, presiona sobre “Continuar”.<br/>4- Unos segundos después <b>recibirás un correo</b> de ${this.equipoNombre} <b>con un link</b> para que modifiques o recuperes tu contraseña. Presiona sobre ese link<br/>5- Serás enviado a una pantalla de la plataforma que te pedirá que coloques una nueva contraseña y que la repitas por seguridad<br/>6- Presiona sobre <b>“Confirmar contraseña”</b> y listo<br/>7- El sistema te solicitará que te vuelvas a logear con las nuevas credenciales`,
      subSeccionAyuda: subSeccionAyudaPerfil
    }

    this.preguntasFrecuentesPerfil.push(pregunta);

    pregunta = {
      titulo: `¿Cómo me doy de baja en ${this.equipoNombre}?`,
      texto: "Para darte de baja debes <b>seguir los siguientes pasos:</b><br/><br/>1- Ingresa a la sección <b>“Mensajes”</b> situada en el menú<br/>2- Una vez dentro, presiona sobre tu <b>entrenador/a</b><br/>3- Presiona sobre el espacio dispuesto para escribir y <b>envíale un mensaje solicitando la baja</b><br/>4- Tu entrenador/a será notificado sobre el mensaje y te responderá durante el transcurso del día<br/><br/>Te echaremos de menos y <b>tendrás las puertas abiertas para volver cuando lo desees.</b><br/><br/><b>Consideraciones importantes luego de la baja:</b><br/><br/><ul><li>No recibirás más los planes de entrenamiento semanales y tampoco podrás ingresar a la plataforma</li><li>Si cambias de opinión, no dudes en contactarnos, ya que toda tu información (historial de planes, conversaciones, marcas y objetivos,  trabajos complementarios, etc) quedará almacenada en la plataforma y al reactivarte podrás acceder nuevamente a ella</li></ul>",
      subSeccionAyuda: subSeccionAyudaPerfil
    }

    this.preguntasFrecuentesPerfil.push(pregunta);
  }

}
