import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AuxHideButtons, OrdenarItems, Seccion, SeccionConItems } from '../../../../api/interfaces/sections';
import { MatDialog } from '@angular/material/dialog';
import { NewEditElementComponent } from '../new-edit/new-edit-element/new-edit-element.component';
import { CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { RolService } from '../../../../services/rol/rol.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { SnackbarService } from '../../../../utils/snackbar/snackbar.service';
import { SeccionesService } from '../../../../api/services/secciones/secciones.service';
import { GenericDialogComponent } from '../../../generic-dialog/generic-dialog.component';
import { SharedDataService } from '../../../../services/sharedData/shared-data.service';
import { NewEditSectionComponent } from '../new-edit/new-edit-section/new-edit-section.component';
import { MobileDetectorService } from 'src/app/services/mobileDetector/mobile-detector.service';

/**
 * 
 * @param index number
 * @param class string, css style
 * @param seccion seccion data, interface SeccionConItems
 * @param disableDragDrop  hide action buttons if drag and drop is enabled, boolean
 */
@Component({
  selector: 'app-section',
  templateUrl: './section.component.html',
  styleUrls: ['./section.component.scss']
})
export class SectionComponent implements OnInit {

  itemClass: string = "flexEvenly pointer sectionHover";

  isAdmin: boolean = false;
  isMobile: boolean = false;
  disableDragDropItems: boolean = true;

  @Input('index') index: number;
  @Input('class') class: string;
  @Input('seccion') seccion: SeccionConItems;
  @Input('disableDragDrop') disableDragDrop: boolean;
  

  @Output() toogleSectionsEvent = new EventEmitter<any>();
  @Output() blockActionButtonsEvent = new EventEmitter<any>();

  hideButtons : AuxHideButtons = {
    hide: true,
    index: 0,
  };

  constructor(
    public dialog: MatDialog,
    private rolService: RolService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private snack: SnackbarService,
    private seccionesService: SeccionesService,
    private sharedData: SharedDataService,
    private mobileDetectorService: MobileDetectorService,
  ) { 
    this.matIconRegistry.addSvgIcon(
      `delete`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../assets/delete.svg')
    );
  }

  ngOnInit(): void {
    this.isAdmin = this.rolService.isAdmin();
    this.isMobile = this.mobileDetectorService.isMobile();
    this.sharedData.currentMessage.subscribe(message => {
      if (message === "hideButtons") {
        this.hideButtons = JSON.parse(sessionStorage.getItem(('hideButtons')));
      }
    });
  }

  addElement(tipo: string) {
    this.dialog.open(NewEditElementComponent, {
      data:  {
        itemTipo: tipo,
        seccionId: this.seccion.seccionId
      }
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.seccion.items, event.previousIndex, event.currentIndex);
  }

  toggleDragDropItems(event, index: number) {
    this.disableDragDropItems = !this.disableDragDropItems;
    //reordenar con correccion para index = 0
    if(this.disableDragDropItems) {
      this.itemClass = "flexEvenly pointer sectionHover";
      this.seccion.items.forEach( (item,index) => item.itemOrden = index +1 );
      const nuevoOrden: OrdenarItems[] = [];
      this.seccion.items.forEach(item => {
        const aux: OrdenarItems = {
          id: item.itemId,
          itemOrden: item.itemOrden
        };
        nuevoOrden.push(aux);
      });
      this.seccionesService.ordenarItems(nuevoOrden, this.seccion.seccionId).subscribe( response=> {
        this.snack.snackTranslate('snacks.sucess.sections.dragItems');
      },
      error => {
        console.error(error);
        this.snack.openSnackBar(error.error.message);
      });
    } else {
      this.itemClass = "flexEvenly move sectionHover";
    }
    const aux: AuxHideButtons = {
      hide: this.disableDragDropItems,
      index: index
    }
    sessionStorage.setItem('hideButtons',JSON.stringify(aux));
    this.sharedData.changeMessage("hideButtons");
    
    this.blockActionButtonsEvent.emit(this.disableDragDropItems);
  }

  edit() {
    this.dialog.open(NewEditSectionComponent, {
      data:  {
        seccionId: this.seccion.seccionId,
        seccionNombre: this.seccion.seccionNombre,
        seccionTipo: this.seccion.seccionTipo,
      }
    });
  }

  delete() {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      data: {
        title: "¿Desea eliminar la sección?",
        iconClass: "bigBlueIcon",
        text: "",
        icon: "error_outlined",
        confirm: "ELIMINAR",
        cancel: "NO"
      }
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.seccionesService.deleteSeccion(this.seccion.seccionId).subscribe( response=> {
          this.snack.snackTranslate('snacks.sucess.sections.deleteSection');
          this.sharedData.changeMessage("reloadSections");
        },
        error => {
          console.error(error);
          this.snack.openSnackBar(error.error.message);
        });
      }
    });
  }

  toogleDragSections() {
    this.toogleSectionsEvent.emit();
  }

}
