import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ListadoPageable, Emisor } from '../../interfaces/domiciliacion';

@Injectable({
  providedIn: 'root'
})
export class DomiciliacionService {

  private url = environment.apiUrl + '/';

  httpOptionsJson = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }),
    withCredentials: true,
  };

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json'
    }),
    withCredentials: true,
  };

  constructor(private http: HttpClient) { }

  getDomiciliacionPageable(page: number, size: number): Observable<ListadoPageable> {
    return this.http.get<ListadoPageable>(this.url + `api/domiciliacion/listado?page=${page}&size=${size}`,  this.httpOptions);
  }

  crearFechaDomiciliacion(fecha: string): Observable<any> {
    let body = {
      algo: "algo"
    }
    return this.http.post<any>(this.url + `api/domiciliacion?fechaDomiciliacion=${fecha}`, body,this.httpOptionsJson);
  }

  crearArancel(domiciliacionId: number, arancel: boolean): Observable<any> {
    let body = {
      algo: "algo"
    }
    return this.http.put<any>(this.url + `api/domiciliacion/arancel?domiciliacionDetalleId=${domiciliacionId}&arancel=${arancel}`, body,  this.httpOptionsJson);
  }

  getDomiciliacionFiltrable(domiciliacionId: number, ape: string, status: string, tipoId: string, page: number, size: number): Observable<ListadoPageable> {
    return this.http.get<ListadoPageable>(this.url + `api/domiciliacion/listado/detalle?domiciliacionId=${domiciliacionId}&apenom=${ape}&estado=${status}&tipoId=${tipoId}&page=${page}&size=${size}`,  this.httpOptions);
  }

  getEmisores(): Observable<Emisor[]> {
    return this.http.get<Emisor[]>(this.url + `api/domiciliaciondatos`,  this.httpOptions);
  }

  cobrarAlumno(domiciliacionDetalleId: number, alumnoId: number): Observable<any> {
    let body = {
      algo: "algo"
    }
    return this.http.put<any>(this.url + `api/domiciliacion/cobrar/alumno?domiciliacionDetalleId=${domiciliacionDetalleId}&alumnoId=${alumnoId}`, body,  this.httpOptionsJson);
  }

  cobrarFiltrable(domiciliacionId: number, ape: string, status: string, tipoId: string): Observable<Blob> {
    let body = {
      algo: "algo"
    }
    return this.http.put<any>(this.url + `api/domiciliacion/cobrar?domiciliacionId=${domiciliacionId}&apenom=${ape}&estado=${status}&tipoId=${tipoId}`, body,  this.httpOptionsJson);
  }

  /**
   * Change current month arancel
   * @param arancel 
   * @param tipoDeEntrenamiento 
   * @returns 
   */
  modificarArancelTipoEntrenamiento(arancel: string, tipoDeEntrenamiento: number): Observable<any> {
    let body = {
      algo: "algo"
    }
    return this.http.put<any>(this.url + `api/domiciliacion/aranceles?arancel=${arancel}&tipoDeEntrenamiento=${tipoDeEntrenamiento}`, body,  this.httpOptionsJson);
  }
}
