import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Filters, FiltersStorage } from '../../../../api/interfaces/user';
import { SnackbarService } from '../../../../utils/snackbar/snackbar.service';
import { Role } from '../../../../enums/role';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from "@angular/material/icon";
import { MobileDetectorService } from '../../../../services/mobileDetector/mobile-detector.service';
import { SharedDataService } from '../../../../services/sharedData/shared-data.service';
import { FormGroup, FormControl } from '@angular/forms';
import { TrainersService } from '../../../../api/services/trainers/trainers.service';
import { Trainer, TrainerTable } from '../../../../api/interfaces/trainers';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-trainers-table',
  templateUrl: './trainers-table.component.html',
  styleUrls: ['./trainers-table.component.scss']
})
export class TrainersTableComponent implements OnInit, OnDestroy {



  totalPerPage: number = 10;
  totalPages: number = 0;
  size: number = 10;
  page: number = 0;

  first: boolean = true;
  last: boolean = false;
  done: boolean = false;

  noTrainers = false;
  firstLoad: boolean = true;
  amount: number = 68;
  paginateRight: boolean = true;
  RoleEnum = Role;
  isMobile: boolean = false;
  filters: Filters = {
    nombre: "",
    apellido: "",
    nombreApellido: "",
    estado: "",
    entrenamientoTipoId: "",
    entrenamientoNivelId: "",
    entrenadorId: "",
    leido: ""
  };

  filtersForm = new FormGroup({
    nombreApellido: new FormControl(''),
  });

  private sharedData$: Subscription;

  constructor(
    private router: Router,
    private snack: SnackbarService,
    private trainerService: TrainersService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private mobileDetectorService: MobileDetectorService,
    private sharedData: SharedDataService,
  ) {
    this.matIconRegistry.addSvgIcon(
      `delete`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../assets/delete.svg')
    );
  }
  displayedColumns: string[] = ['name', 'status', 'amount', 'actions'];
  displayedColumnsMobile: string[] = ['name', 'actions'];
  trainerData: TrainerTable[] = [];
  loaded: boolean = false;

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    const aux: number = parseInt(sessionStorage.getItem('trainersPage'),10);
    if(aux) {
      this.page = aux;
    }
    this.loadTrainers();
    this.sharedData$ = this.sharedData.currentMessage.subscribe(message => {
      if (message === "reloadTrainerData") {
        this.loadTrainers();
      }
    });

  }

  loadTrainers() {
    this.trainerData = [];
    this.noTrainers = false;
    this.loaded = false;
    this.trainerService.getTrainersWithPaginator(this.totalPerPage, this.page, this.filtersForm.value.nombreApellido)
      .subscribe(response => {
        this.totalPages = response.totalPages;
        this.first = response.first;
        this.last = response.last;
        this.amount = response.totalElements
        if (response.totalElements == 0) {
          this.noTrainers = true;
          this.loaded = true;
        } else {
          this.firstLoad = false;
          response.content.forEach(trainer => {
            trainer.avatarUrl = null;
            this.trainerData.push(trainer);
          });
          this.loaded = true;
        }
      },
        error => {
          this.snack.snackTranslate('snacks.error.generic')
          console.error(error);
          this.loaded = true;
        });
  }

  navigate(trainer: TrainerTable) {
    sessionStorage.setItem('id_entrenador', JSON.stringify(trainer.id));
    sessionStorage.setItem('cantAlumnos',JSON.stringify(trainer.cantAlumnos));
    this.router.navigate(['/admin/perfil-entrenador']);
  }

  loadMore(event) {
    this.page = event;
    this.trainerData = [];
    this.loadTrainers();
  }

  onSubmit() {
    this.trainerData = [];
    this.loadTrainers();
  }

  clear(target: string) {
    switch (target) {
      case 'nombreApellido':
        this.filtersForm.patchValue({nombreApellido: ''});
      break
    }
    this.onSubmit();
  }

  public ngOnDestroy(): void {
    this.sharedData$.unsubscribe();
  }

}