import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MaterialModule } from '../../../material/material.module';
import { GlobalModule } from '../../global/modules/global.module';
import { SectionsRoutingModule } from './sections-routing.module';

import { FileGenericComponent } from '../generic/file-generic/file-generic.component';
import { MainGenericComponent } from '../generic/main-generic/main-generic.component';
import { NewFileComponent } from '../generic/new-edit/new-file/new-file.component';
import { NewEditElementComponent } from '../generic/new-edit/new-edit-element/new-edit-element.component';
import { VideoGenericComponent } from '../generic/video-generic/video-generic.component';
import { CardSectionComponent } from '../generic/card-section/card-section.component';
import { MainNutricionComponent } from '../nutricion/main-nutricion/main-nutricion.component';
import { NewEditSectionComponent } from '../generic/new-edit/new-edit-section/new-edit-section.component';
import { SectionComponent } from '../generic/section/section.component';
import { ItemComponent } from '../generic/item/item.component';
import { ShowItemSelectedComponent } from '../generic/show-item-selected/show-item-selected.component'
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { PlansModule } from '../../plans/modules/plans.module';

import { MainHelpComponent } from '../help/main-help/main-help.component';
import { SubseccionHelpComponent } from '../help/subseccion-help/subseccion-help.component';
import { PreguntaHelpComponent } from '../help/pregunta-help/pregunta-help.component';

@NgModule({
  declarations: [
    FileGenericComponent,
    MainGenericComponent,
    NewFileComponent,
    NewEditElementComponent,
    VideoGenericComponent,
    CardSectionComponent,

    NewEditSectionComponent,
    SectionComponent,
    ItemComponent,
    ShowItemSelectedComponent,

    MainNutricionComponent,


    MainHelpComponent,
    SubseccionHelpComponent,
    PreguntaHelpComponent,
    
  ],
  imports: [
    CommonModule,
    MaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    GlobalModule,
    SectionsRoutingModule,
    PdfViewerModule,
    PlansModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
  ], exports: [

  ]
})
export class SectionsModule { }
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
