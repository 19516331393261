<form [formGroup]="formacionForm" *ngIf="formacion && formacion.activo">
    <div class="formacionInput">

        <div class="botonesFormacion" *ngIf="!isMobile">
            <button *ngIf="disable" (click)="toggleEdit()"  mat-icon-button [disabled]="lock">
                <mat-icon *ngIf="!lock" id="deleteButton" class="delete">edit</mat-icon>
                <mat-icon *ngIf="lock" class="greyIcon">edit</mat-icon>
            </button>
            <button [disabled]="!formacionForm.valid" *ngIf="!disable" (click)="saveFormacion()"  mat-icon-button>
                <mat-icon *ngIf="!formacionForm.valid" class="greyIcon" id="deleteButton">save</mat-icon>
                <mat-icon *ngIf="formacionForm.valid" id="deleteButton">save</mat-icon>  
            </button>
            <button (click)="removeFormacion()"  mat-icon-button>
                <mat-icon svgIcon="delete" id="deleteButton" class="delete"></mat-icon>
            </button>
        </div>

        <mat-form-field appearance="fill" class="inputTwoLine">
            <mat-label>{{ 'forms.trainers.title' | translate}}</mat-label>
            <input 
                [disabled]="disable == true ? true : null" 
                maxlength="50" 
                required 
                id="entrenadorFormacionTituloInput" 
                formControlName="entrenadorFormacionTitulo" 
                type="text"
                matInput 
                (change)="addFormacion()">
            <mat-hint align="end" *ngIf="!disable">{{formacionForm.value.entrenadorFormacionTitulo?.length || 0}} / 50</mat-hint>
        </mat-form-field>
        
        <mat-form-field appearance="fill" class="inputTwoLine marginButtonFormacion">
            <mat-label>{{ 'forms.trainers.description' | translate}}</mat-label>
            <textarea 
                type="text"
                matInput 
                [attr.disabled]="disable == true ? true : null" 
                maxlength="300" 
                required 
                id="entrenadorFormacionDescripcionInput" 
                formControlName="entrenadorFormacionDescripcion"
                (change)="addFormacion()">
            </textarea>
            <mat-hint align="end" *ngIf="!disable">{{formacionForm.value.entrenadorFormacionDescripcion?.length || 0}} / 300</mat-hint>
        </mat-form-field>

        <div class="botonesFormacion" *ngIf="isMobile">
            <button *ngIf="disable" (click)="toggleEdit()"  mat-icon-button [disabled]="lock">
                <mat-icon *ngIf="!lock" id="deleteButton" class="delete">edit</mat-icon>
                <mat-icon *ngIf="lock" class="greyIcon">edit</mat-icon>
            </button>
            <button [disabled]="!formacionForm.valid" *ngIf="!disable" (click)="saveFormacion()"  mat-icon-button>
                <mat-icon *ngIf="!formacionForm.valid" class="greyIcon" id="deleteButton">save</mat-icon>
                <mat-icon *ngIf="formacionForm.valid" id="deleteButton">save</mat-icon>  
            </button>
            <div class="deleteDiv">
                <button class="deleteButton" mat-button  (click)="removeFormacion()"> {{'registration.stepThree.delete' | translate}}</button>
            </div>
        </div>
    </div>
    
</form>