import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DetalleFormularioDinamico, FormularioDinamicoPregunta, OpcionesMultiplesFormularioDinamicoPregunta } from 'src/app/api/interfaces/formulario-dinamico';

@Component({
  selector: 'app-checkbox-input',
  templateUrl: './checkbox-input.component.html',
  styleUrls: ['./checkbox-input.component.scss']
})
export class CheckboxInputComponent implements OnInit {

  @Input('requerido') requerido: boolean;
  @Input('opciones') opciones: DetalleFormularioDinamico[];
  @Input('pregunta') pregunta: FormularioDinamicoPregunta;
  
  @Output() opcionesSeleccionadasEvent = new EventEmitter<OpcionesMultiplesFormularioDinamicoPregunta>();

  optionsForm = new FormGroup({
   valor: new FormControl(),
  });

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Change seleccionado value in selected opcion
   * @param i number, index opciones array
   */
  clickCheckbox(i: number) {
    this.opciones[i].seleccionado = !this.opciones[i].seleccionado;
    this.emitirTodosSeleccionados();
  }

  /**
   * Filter opciones and emit an string with all the selected options
   * 3313: changed array to string
   */
  emitirTodosSeleccionados() {
    const opcionesSeleccionadas: DetalleFormularioDinamico[] = this.opciones.filter(opcion => opcion.seleccionado == true);
    let stringsSeleccionados: string = "";
    opcionesSeleccionadas.forEach( (opcion,index) => {
      if(index != 0) {
        stringsSeleccionados+= ", ";
      }
      stringsSeleccionados += opcion.detalleDescripcion;
    });
    const opcionesMultiples: OpcionesMultiplesFormularioDinamicoPregunta = {
      preguntaId: this.pregunta.id,
      respuestas: stringsSeleccionados
    }
    this.opcionesSeleccionadasEvent.emit(opcionesMultiples);
  }

}
