import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Achievement, AchievementDynamic, AchievementRoute, AntecedentesMarcas, Distancias } from '../../../../api/interfaces/register';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from "@angular/material/icon";
import { RegisterService } from '../../../../api/services/register/register.service';
import { MobileDetectorService } from '../../../../services/mobileDetector/mobile-detector.service';
import { ParseTimeService } from 'src/app/utils/parseTime/parse-time.service';
import { ActivatedRoute } from '@angular/router';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';
import { Masks } from 'src/app/enums/mask';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from '../../../generic-dialog/generic-dialog.component';
import { FlagsEnum } from '../../../../enums/sharedDataFlag';
import { of, Subscription } from 'rxjs';
import { HoursDayFixedService } from 'src/app/utils/hoursDayFixed/hours-day-fixed.service';
import { FilterDataService } from '../../../../services/filterData/filter-data.service';
@Component({
  selector: 'app-inputs-achievements',
  templateUrl: './inputs-achievements.component.html',
  styleUrls: ['./inputs-achievements.component.scss']
})
export class InputsAchievementsComponent implements OnInit, OnDestroy {

  selected: Date;
  maxDate: Date = new Date();

  private sharedDataFlag$: Subscription;

  @Input('routeOptions') routeOptions: Distancias[];
  @Input('alumnoId') alumnoId?: number;
  @Input('i') i?: number;
  
  /**
   * Define current input mode. It can be ruta, pista, ultra or trail.
   * Defined in achievements-card-component.html and step-achievements-objetives.component.html
   */
  @Input('mode') mode?: string;
  @Input ('achievement') achievement : AchievementDynamic;

  @Output() removeAchievementEvent = new EventEmitter<any>();
  /**
   * Required to complete data in onboarding (registration, StepAchievementsObjetivesComponent)
   */
  @Output() saveAchievementEvent: EventEmitter<any> = new EventEmitter();
  achievements: Achievement[] = [];

  isMetasObjetivos: boolean = false;
  distanceKMeters: boolean = false;
  isRegistration: boolean = false;
  distanceMeters: boolean = false;
  isMobile: boolean = false;
  isRuta: boolean = false;
  disable: boolean = true;
  activo: boolean = true;
  lock: boolean = false;

  achievementsRoute: AchievementRoute[] = [];

  achievementForm = new FormGroup({
    distance: new FormControl(),
    time: new FormControl([Validators.required], Validators.minLength(6)),
    date: new FormControl(),
    type: new FormControl(),
    distanciaTipoId: new FormControl(),
    id: new FormControl(),
  });

  timeMask: string = "";

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private registerService: RegisterService,
    private mobileDetectorService: MobileDetectorService,
    private parseTimeService: ParseTimeService,
    private router: ActivatedRoute,
    private sharedData: SharedDataService,
    private dialog: MatDialog,
    private hoursDayFixed: HoursDayFixedService,
    private filterData: FilterDataService
  ) {
    this.matIconRegistry.addSvgIcon(
      `delete`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../assets/delete.svg')
    );
   }

   ngOnInit(): void {
    this.timeMask = Masks.TIME;
    this.isMobile = this.mobileDetectorService.isMobile();

    const url = this.router.snapshot.routeConfig.path;
    if(url == "alumno/metas-objetivos") {
      this.isMetasObjetivos = true;
    }
    if (url == "registration") {
      this.isRegistration = true;
    }

    this.sharedDataFlag$ = this.sharedData.currentFlag.subscribe(flag => {
      if(flag) {
        if(flag.key == FlagsEnum.editAchievements) {
          if(flag.value && this.disable) {
            this.lock = true;
          }
          if(!flag.value) {
            this.lock = false;
          }
        }
      }
    });

    this.isRuta = (this.mode == 'ruta');
    this.patchValues();

     if(this.mode === "ultra" || this.mode == "trail") {
       this.distanceKMeters = true;
     }
     if(this.mode === "pista") {
       this.distanceMeters = true;
     }

    this.addAchievement();

    if(this.disable && !this.isRegistration) {
      this.disableInputs();
    }

    //user must be able to complete the new objetive after is added in objetives.component
    if(this.achievementNotExistInDB()) {
      this.disable = false;
      this.enableInputs();
    }
  }

  /**
   * Change disable value and call disableInputs or enableInputs
   * @
   */
  toggleEdit() {
    this.disable = !this.disable;
    this.disable ? this.disableInputs() : this.enableInputs() ;
  }

  /**
   * Allow user to edit the form and set flag to true
   */
  enableInputs() {
    this.achievementForm.controls['distance'].enable();
    this.achievementForm.controls['time'].enable();
    this.achievementForm.controls['date'].enable();
    this.achievementForm.controls['type'].enable();
    if(!this.isRegistration) {
      this.sharedData.changeFlag(FlagsEnum.editAchievements, true);
    }
  }

  /**
   * Prevent user to edit the form and set flag to false
   */
  disableInputs() {
    this.achievementForm.controls['distance'].disable();
    this.achievementForm.controls['time'].disable();
    this.achievementForm.controls['date'].disable();
    this.achievementForm.controls['type'].disable();
    this.sharedData.changeFlag(FlagsEnum.editAchievements, false);
  }

  achievementNotExistInDB() : boolean {
    return (!this.achievement || (this.achievement && !this.achievement.id));
  }

  /**
   * Remove from db an existing objetive using id and active false, or remove from frontend a non-saved objetive using only removeObjetiveEvent
   */
   removeAchievement() {
    if(this.isRegistration) {
      this.emitRemoveEventAndChangeFlag();
    } else {
      const dialogRef = this.dialog.open(GenericDialogComponent, {
        data: {
          title: `¿Está seguro que desea eliminar la marca?`,
          iconClass: "bigBlueIcon",
          text: "",
          icon: "error_outlined",
          confirm: "ELIMINAR",
          cancel: "NO"
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          if(this.achievement) {
            this.achievement.activo = false;
          }
          if(this.achievementNotExistInDB()) {
            this.emitRemoveEventAndChangeFlag();
          } else {
            const marca = [this.achievement];
            this.registerService.editAchievements(this.alumnoId, marca)
            .subscribe(response => {
              this.emitRemoveEventAndChangeFlag();
            },
            error => {
              console.error(error);
            });
          }
        }
      }); 
    }
  }

  emitRemoveEventAndChangeFlag() {
    this.sharedData.changeFlag(FlagsEnum.editAchievements, false);
    this.removeAchievementEvent.emit(this.i);
  }

  /**
   * If isRegistration only call toggleEdit without saving, because its saved at stepEndComponent
   * Else this create the required object to send to backend and save in db a new achievement, or edit a existing one.
   */
   saveAchievement() {
    const { distance, time, date, type } = this.achievementForm.getRawValue();
    const timeArray: number[] = this.parseTimeService.parseTime(time);
    const aux: AchievementDynamic = {
      tiempoMarcaHoras: timeArray[0],
      tiempoMarcaMinutos: timeArray[1],
      tiempoMarcaSegundos: timeArray[2],
      fechaMarca : date ? this.hoursDayFixed.getDateFixed(new Date(date).toISOString()) : "",
      distanciaMarca : distance ? distance: "",
      id: this.achievement && this.achievement.id ? this.achievement.id : null,
      antecedentePreguntaDetalleId: this.achievement && this.achievement.antecedentePreguntaDetalleId ? this.achievement.antecedentePreguntaDetalleId : this.filterData.getIdAchievement(this.mode),
      activo: true,
      distanciaTipoId: type && type.id ? type.id : null
    }
    this.registerService.editAchievements(this.alumnoId, [aux])
    .subscribe(response => {
      this.toggleEdit();
      this.achievement.id = response;
    },
    error => {
      console.error(error);
    });
  }

  addAchievement() {
    this.achievementForm.value.i = this.i;
    if(this.achievementForm.value.date) {
      let auxDate = this.achievementForm.value.date;
      if(auxDate._d) {
        auxDate._d.setHours(12);
      }
    }
    
    if (this.isRegistration) {
      this.saveAchievementEvent.emit(this.achievementForm.value);
    }
  }

  patchValues() {
    if(this.achievement) {
      let time: string = this.parseTimeService.parseToString(this.achievement.tiempoMarcaHoras, this.achievement.tiempoMarcaSegundos, this.achievement.tiempoMarcaMinutos);
      this.achievementForm.patchValue({date: this.achievement.fechaMarca});
      this.achievementForm.patchValue({time: time != '00:00:00' ? time : ''});
      this.achievementForm.patchValue({id: this.achievement.id});
      this.activo = this.achievement.activo;

      this.isRuta ? this.patchTypeAndSetRuta() : this.patchDistanciaAndDistance();
    } else {
      this.achievementForm.patchValue({id: null});  //requerido por backend
    }

  }

  patchTypeAndSetRuta() {
    this.achievementForm.patchValue({distanciaTipoId: this.achievement.distanciaTipoId});
    let found: Distancias = this.routeOptions.find( option => {
      if(option.id == this.achievement.distanciaTipoId) {
        return option;
      }
    });
    if(found) {
      found.selected = true;
    }
    this.achievementForm.patchValue({type: found});
  }

  patchDistanciaAndDistance() {
    this.achievementForm.patchValue({distanciaTipoId: this.achievement.distanciaTipoId});
    this.achievementForm.patchValue({distance: this.achievement.distanciaMarca});
  }

  public ngOnDestroy(): void {
    this.sharedDataFlag$.unsubscribe();
  }

}