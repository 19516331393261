import { ChangeDetectorRef, Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SharedDataService } from '../../../services/sharedData/shared-data.service';
import { FormValidatorService } from '../../../services/formValidator/form-validator.service';
import { MobileDetectorService } from '../../../services/mobileDetector/mobile-detector.service';
import { FormsDinamicService } from 'src/app/api/services/formsDinamic/forms-dinamic.service';
import { StepperEnum } from 'src/app/enums/stepper';

@Component({
  selector: 'app-step-achievements-objetives',
  templateUrl: './step-achievements-objetives.component.html',
  styleUrls: ['./step-achievements-objetives.component.scss']
})
export class StepAchievementsObjetivesComponent implements OnInit {

  @Input('cantidadPasosHabilitados') cantidadPasosHabilitados: number;
  @Input('posicion') posicion: number;

  alumnoId: number;

  maxDate: Date = new Date();

  objetives: string[] = [];
  showObjetives: boolean = false;

  objetivesEmpty: boolean = false;
  objetivesValid: boolean = false;

  rutaValid: boolean = true;
  pistaValid: boolean = true;
  trailValid: boolean = true;
  ultraValid: boolean = true;

  isMobile: boolean = false;

  backgroundForm = new FormGroup({
    ruta: new FormControl(),
    pista: new FormControl(),
    trail: new FormControl(),
    ultra: new FormControl(),

    objetives: new FormControl(),
  });


  constructor(
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private formValidatorService: FormValidatorService,
    private sharedData: SharedDataService,
    private mobileDetectorService: MobileDetectorService,
    private formsDinamicService: FormsDinamicService 
  ) { }

  ngOnInit(): void {
    
    this.alumnoId = parseInt(localStorage.getItem('idAlumno'),10);
    
    this.isMobile = this.mobileDetectorService.isMobile();
    this.cdr.detectChanges();
    this.getObjetives();

    this.sharedData.currentMessage.subscribe(message => {
      if(message === StepperEnum.SAVE_STEP_OBJ_ACH) {
        sessionStorage.setItem('background', JSON.stringify(this.backgroundForm.value));
      }
      if(message==="objetivesvalid") {
        this.objetivesValid = true;
      }

      if(message==="objetivesinvalid") {
        this.objetivesValid = false;
      }

    });

    this.backgroundForm.statusChanges
    .subscribe(val => {
      // this.formValidatorService.onFormValidation(val, "step3","background");
      if(!this.backgroundForm.value.ruta) {
        sessionStorage.removeItem('ruta');
      }
      if(!this.backgroundForm.value.pista) {
        sessionStorage.removeItem('pista');
      }
      if(!this.backgroundForm.value.trail) {
        sessionStorage.removeItem('trail');
      }
      if(!this.backgroundForm.value.ultra) {
        sessionStorage.removeItem('ultra');
      }
    });
  }

  /**
   * recibe respuestas para objetivos: 
   * "Si" y "No, nada en particular"
   */
  getObjetives() {
    this.translate
            .get([
              'registration.stepThree.objetives.answer1',
              'registration.stepThree.objetives.answer2',
            ])
            .subscribe(translations => {
                this.objetives.push(translations['registration.stepThree.objetives.answer1']);
                this.objetives.push(translations['registration.stepThree.objetives.answer2']);
            });
  }

  changeObjetives(mode: boolean) {
    this.showObjetives = mode;
    this.objetivesEmpty = false;
    if(!this.showObjetives) {
      if(!mode) {
        this.objetivesValid = true;
        sessionStorage.removeItem('objetives');
      } else {
        this.objetivesValid = false;
      }
    }
  }

  changeAchievement(aux) {
    if(aux.mode === "ruta") {
      this.rutaValid = aux.valid
    }

    if(aux.mode === "pista") {
      this.pistaValid = aux.valid
    }

    if(aux.mode === "ultra") {
      this.ultraValid = aux.valid
    }

    if(aux.mode === "trail") {
      this.trailValid = aux.valid
    }

    //actualizar boton superior de stepper
    if(!this.rutaValid || !this.pistaValid || !this.ultraValid || !this.trailValid) {
      this.sharedData.changeMessage(StepperEnum.ACH_INVALID);
    }

    if(this.rutaValid && this.pistaValid && this.ultraValid && this.trailValid) {
      this.sharedData.changeMessage(StepperEnum.ACH_VALID);
    }
  }

  /**
   * Habilita el modo cuando se clickea en el checkbox para desactivarlo, previamente activado. 
   * Si el resto del step es válido puede continuar
   * @param mode 
   */
  changeFromCheckbox(mode: string) {
    if(mode == 'ruta' && !this.rutaValid) {
      this.rutaValid = true;
    }

    if(mode == 'pista' && !this.pistaValid) {
      this.pistaValid = true;
    }

    if(mode == 'trail' && !this.trailValid) {
      this.trailValid = true;
    }

    if(mode == 'ultra' && !this.ultraValid) {
      this.ultraValid = true;
    }
  }

  emptyObjetives(mode: boolean) {
    this.objetivesEmpty = true;
  }

  emptyAchievement(mode: string) {
    if(mode === "ruta") {
      this.backgroundForm.patchValue({ruta: false});
    }
    if(mode === "pista") {
      this.backgroundForm.patchValue({pista: false});
    }
    if(mode === "trail") {
      this.backgroundForm.patchValue({trail: false});
    }
    if(mode === "ultra") {
      this.backgroundForm.patchValue({ultra: false});
    }
  }

}
