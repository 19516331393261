<div class="conversation">
    <app-message-header *ngIf="loaded" [canal]="canal"></app-message-header>
    <app-loading *ngIf="!loaded" [showText]="false"></app-loading>

    <div *ngIf="messages.length == 0 && loaded" class="center">
        <div class="headline6">
            {{'messages.empty' | translate}}
        </div>
    </div>

        <div [class]="messagesClass">
            <div class="messagesContainer" 
                infinite-scroll
                infiniteScroll
                [infiniteScrollDistance]="2"
                [infiniteScrollThrottle]="50"
                [scrollWindow]="false"
                (scrolledUp)="onUpMessages()">

                    <div class="fakeMessage"></div>

                    <div *ngFor="let message of messages; let i = index">
                        <app-message [message]="message" [autofocus]="i+1 == messages.length"></app-message>
                    </div>
            </div>
        </div>

        <app-new-message 
            (newMessageEvent)="newMessageEvent($event)" 
            (classMessageEvent)="changeMessage($event)"
            [isMassive]="false"
            [canal]="canal">
        </app-new-message>
</div>