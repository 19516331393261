import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SnackbarService } from '../../../utils/snackbar/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../api/services/login-service.service';
import { environment } from '../../../../environments/environment';
import { RequestChange, ResetPassword } from '../../../api/interfaces/login';

@Component({
  selector: 'app-request-change',
  templateUrl: './request-change.component.html',
  styleUrls: ['./request-change.component.scss']
})
export class RequestChangeComponent implements OnInit {

  done: boolean = false;
  
  changeForm = new FormGroup({
    email: new FormControl(),
  });

  constructor(
    private snack: SnackbarService,
    private translate: TranslateService,
    private loginApiService: LoginService,
  ) { }

    ngOnInit() {
    }

    onSubmit() {
      const requestChange: RequestChange = {
        email: this.changeForm.value.email.toString()
      }
      this.loginApiService.requestChangePassword(requestChange)
        .subscribe(response => {
          this.done = true;
        },
          error => {
            console.error(error);
            this.snack.snackTranslate('snacks.error.generic');
          });
    }
}
