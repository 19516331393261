<form [formGroup]="optionsForm">
    <p class="subtitle1 textLeft">{{pregunta.titulo}} <span *ngIf="requerido"> *</span></p>
    <mat-form-field appearance="fill" class="inputfullWidth">
        <mat-label>{{pregunta.placeholder}}</mat-label>
        
        <textarea 
            maxLength={{cantCaracteres}}
            aria-labelledby="textAreaInput" 
            id="textAreaInput" 
            formControlName="valor"
            type="text"
            matInput 
            [required]="requerido">
        </textarea>
        
        <mat-hint align="end">{{optionsForm.value.valor?.length || 0}} / {{cantCaracteres}}</mat-hint>
        <mat-hint *ngIf="pregunta.textoAsistivo">{{pregunta.textoAsistivo}}</mat-hint>
    </mat-form-field>
    

</form>