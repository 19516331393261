<div class="background">

    <app-header></app-header>
    <app-navbar *ngIf="!isMobile"></app-navbar>
    <section>
        <div class="flexStudents">
            <p class="headline4">{{ 'admin.trainersMainPage.title' | translate }}</p>
            <div *ngIf="!isMobile">                
                <button mat-raised-button class="primaryButton2" color="accent" (click)="openDialogCreateTrainer()">
                    <mat-icon class="whiteIcon">
                        add
                    </mat-icon>
                    {{ 'admin.trainersMainPage.createButton' | translate }}
                </button>
                <br/>
            </div>
            <div *ngIf="isMobile">
                <button mat-raised-button  class="buttonSmall3"  color="accent" (click)="openDialogCreateTrainer()">
                    <mat-icon class="whiteIcon">
                        add
                    </mat-icon>
                </button>
                <br/>
            </div>
        </div>
        <app-trainers-table></app-trainers-table>
    </section>
</div>