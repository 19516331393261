import { Component, OnInit, Inject } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog'
import { FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { TrainingGroupEnum } from 'src/app/enums/training-type';
import { TrainingDays } from 'src/app/api/interfaces/register';
import { Level, TType } from 'src/app/api/interfaces/user';
import { RegisterService } from 'src/app/api/services/register/register.service';
import { TrainingService } from 'src/app/api/services/training/training.service';
import { NivelesEnum } from 'src/app/enums/niveles';
import { SnackbarService } from 'src/app/utils/snackbar/snackbar.service';
import { DateAdapter} from '@angular/material/core';
import { Destinatarios, EnviarMensaje } from 'src/app/api/interfaces/messages';
import { MessagesService } from 'src/app/api/services/messages/messages.service';
import { ParametrosService } from 'src/app/api/services/parametros/parametros.service';

@Component({
  selector: 'app-message-domiciliacion',
  templateUrl: './message-domiciliacion.component.html',
  styleUrls: ['./message-domiciliacion.component.scss']
})
export class MessageDomiciliacionComponent implements OnInit {

  newForm = new FormGroup({
    asunto: new FormControl(),
    mensaje: new FormControl('',[ 
      Validators.required,
      Validators.pattern(/^(?!\s*$).+/),])
  });

  allLevels: Level[] = [];
  allTrainingDays: TrainingDays[] = [];
  allTypes: TType[] = [];

  gid: string = "gid=";
  nid: string = "nid=";
  tid: string = "tid=";
  longitudMensaje: number = 0;

  levels: Level[] = [];
  traningDays: TrainingDays[] = [];
  type: TType[] = [];

  enabledTrainingDaysSelect: boolean = false;
  enabled: boolean = false;
  indexOnline: number = -1;

  loading: boolean = false;

  constructor(
    private snack: SnackbarService,
    private registerService: RegisterService,
    private trainingService: TrainingService,
    private messagesService: MessagesService,
    private parametrosService: ParametrosService,
    private dialogRef: MatDialog,
    private _adapter: DateAdapter<any>,
    @Inject(MAT_DIALOG_DATA) public data: any) { 
    
  }

  ngOnInit(): void {
    this.loadLevels();
    this.getTrainingDays();
    this.getTrainingTypes();
    this.loadLongitud();
    this._adapter.setLocale('es');
    this.newForm.statusChanges
    .subscribe(val => {
      this.checkDisabled();
    });
  }

  async loadLongitud() {
    try {
      const longitudMensaje: number = await this.parametrosService.getLongitudMensaje();
      this.longitudMensaje = longitudMensaje;
    } catch (error) {
      console.error(error);
    }
  }

  loadLevels() {
    this.trainingService.getLevels().subscribe( response=> {
      this.allLevels = response;
      let remover: number = this.allLevels.findIndex(option => option.entrenamientoNivelDescripcion == NivelesEnum.noDefinido);
      if(remover != -1) {
        this.allLevels.splice(remover, 1);
      }
    },
    error => {
      console.error(error);
    });
  }

  getTrainingTypes() {
    this.registerService.getTrainingTypes()
    .subscribe(response => {
      this.allTypes= response;
    },
      error => {
        console.error(error);
      });
  }

  getTrainingDays () {
    this.registerService.getTrainingDays()
    .subscribe(response => {
      this.allTrainingDays = response;
      const NOT_FOUND: number = -1;
      //remover no definido y online, guardar el id de online
      let index = this.allTrainingDays.findIndex(element => element.grupoDescripcion == TrainingGroupEnum.noDefinido);
      if(index != NOT_FOUND) {
        this.allTrainingDays.splice(index,1);
      }
      index = this.allTrainingDays.findIndex(element => element.grupoDescripcion == TrainingGroupEnum.online);
      if(index != NOT_FOUND) {
        this.indexOnline = this.allTrainingDays[index].entrenamientoGrupoId;
        this.allTrainingDays.splice(index,1);
      }
    },
      error => {
        console.error(error);
      });
  }

  onChangeType() {
    if(this.type && this.type.length > 0) {
      this.enabledTrainingDaysSelect = this.type.some( element => element.entrenamientoTipoId == 1);
      if(!this.enabledTrainingDaysSelect) {
        this.removeTrainingDays();
      }
    } else {
      this.removeTrainingDays();
    }
    this.checkDisabled();
  }

  removeTrainingDays() {
    this.enabledTrainingDaysSelect = false;
    this.traningDays = [];
    this.gid = "gid=";
  }

  onSubmit() {
    this.loading = true;
    const { asunto, mensaje} = this.newForm.getRawValue();
    if(mensaje.trim().length > 0 ) {
      let nidArray: number[] = [];
      let gidArray: number[] = [];
      let tidArray: number[] = [];

      if(this.traningDays && this.traningDays.length > 0) {
        this.traningDays.forEach(element => {
          gidArray.push(element.entrenamientoGrupoId);
        });
      }

      this.allLevels.forEach(level => {
        nidArray.push(level.id);
      });
  
      if(this.type && this.type.length > 0) {
        this.type.forEach(element => {
          tidArray.push(element.entrenamientoTipoId);
        });
      }
      let metadata: Destinatarios = {
        nid: nidArray,
        gid: gidArray,
        tid: tidArray,
      }

      const metadataString: string = JSON.stringify(metadata);
        const newMassive: EnviarMensaje = {
          mensaje: mensaje,
          mensajeTipo: "BASE",
          titulo: asunto,
          metadata: metadataString
        }

        this.messagesService.sendMasivoCobranzas(newMassive,this.gid, this.tid,this.data.domiciliacionId).subscribe(response => {
          this.snack.snackTranslate('snacks.sucess.messages.feedbackReply');
          this.loading = false;
          this.dialogRef.closeAll();
        }, error => {
          console.error(error);
          this.loading = false;
          this.snack.openSnackBar(error.error.message);
        });
      }
    
  }

  checkDisabled() {

    let trainingDaysSelected: boolean = false;
    let typeSelected: boolean = true;
    let presencialSelected: boolean = false;
    let onlineSelected: boolean = false;

    if(this.type && this.type.length > 0) {
      presencialSelected = this.type.some(element => element.entrenamientoTipoId == 1);
      onlineSelected = this.type.some(element => element.entrenamientoTipoId == 2);
      typeSelected = true;
      this.tid = "";
      this.type.forEach(element => {
        const aux: string = `&tid=${element.entrenamientoTipoId}`
        this.tid += aux;
      });
      this.tid = this.tid.substring(1);
    } else {
      this.tid = "tid=";
    }
    
    if(this.traningDays && this.traningDays.length > 0) {
      trainingDaysSelected = true;
      this.gid = "";
      this.traningDays.forEach(element => {
        const aux: string = `&gid=${element.entrenamientoGrupoId}`
        this.gid += aux;
      });
      this.gid = this.gid.substring(1);
    } else {
      this.gid = "gid=";
    }

    let gruposValid: boolean = false;
    const onlineOnly: boolean = (onlineSelected && !presencialSelected);
    const onlineAndPresencial: boolean = (onlineSelected &&  presencialSelected && trainingDaysSelected);

    if( onlineOnly || 
        (presencialSelected && trainingDaysSelected) || 
        (onlineAndPresencial)
      ) {
      gruposValid = true;
    }

    //gid ya esta construido, falta agregar online si corresponde
    if(onlineOnly && this.indexOnline != -1) {
      this.gid = "gid=" + this.indexOnline; 
    }
    if(onlineAndPresencial && this.indexOnline != -1) {
      this.gid += "&gid=" + this.indexOnline;
    }
    this.enabled  = this.newForm.valid &&  gruposValid;
  }
  
  selectAll(select: NgModel, values) {
    select.update.emit(values); 
    this.checkDisabled();
  }

  deselectAll(select: NgModel) {
    select.update.emit([]); 
    this.checkDisabled();
  }

}
