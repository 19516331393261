<div class="background">
    <app-header></app-header>
    <app-navbar *ngIf="!isMobile"></app-navbar>
    <section>
        <app-main 
            [isAdmin]="isAdmin" 
            [isStudent]="false" 
            [id]="user_id" 
            [isAdminOrTrainer]="false"></app-main>
    </section>
</div>