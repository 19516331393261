<div class="dialogContainer450">
    <div class="flexCenter">
        <mat-icon [class]="data.iconClass">{{data.icon}}</mat-icon>
    </div>
    <br/>
    <p class="headline5">{{data.title}}</p>
    <p class="subtitle1">{{data.text}}</p>
    <br/>

    <div class="flexEvenly" *ngIf="!data.showConfirmAsPrimary">
        <button *ngIf="data.confirm" class="confirmButtonDialog" [mat-dialog-close]="true" mat-stroked-button color="primary"> {{data.confirm}}</button>
        <button *ngIf="data.cancel" class="cancelButtonDialog" mat-dialog-close mat-raised-button color="accent">{{data.cancel}}</button>
    </div>

    <div class="flexEvenly" *ngIf="data.showConfirmAsPrimary">
        <button *ngIf="data.cancel" class="confirmButtonDialog" mat-dialog-close mat-stroked-button color="primary">{{data.cancel}}</button>
        <button *ngIf="data.confirm" class="cancelButtonDialog " [mat-dialog-close]="true" mat-raised-button color="accent"> {{data.confirm}}</button>
    </div>
</div>