import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reduceText'
})
export class ReduceTextPipe implements PipeTransform {

  transform(value: string, ...args: number[]): string {
    let aux = value;
    if(value.length > args[0]) {
      aux = value.substring(0, args[0]) + "...";
    }
    return aux;
  }

}
