import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { DatoPorEtiqueta, GetFeedback, NoHeredable, Plan, PlanDatosDia, PlanEditarDia, PostFeedback } from '../../interfaces/plans';
import { CambioDiaEntrenamiento } from '../../interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class PlansService {

  private url = environment.apiUrl + '/';

  httpOptionsJson = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }),
    withCredentials: true,
  };

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json'
    }),
    withCredentials: true,
  };
  multipartOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'multipart/form-data'
    })
  };
  constructor(private http: HttpClient) { }

  postPlan(plan: Plan): Observable<any> {
    return this.http.post<any>(this.url + 'api/planes', plan, this.httpOptionsJson);
  }

  getPlans(): Observable<Plan[]> {
    return this.http.get<Plan[]>(this.url + `api/planes/listado`,  this.httpOptions);
  }

  getPlanId(planId: number): Observable<Plan> {
    return this.http.get<Plan>(this.url + `api/planes?planId=${planId}`,  this.httpOptions);
  }

  editDetelePlan(planId: number, plan: Plan): Observable<Plan> {
    return this.http.put<Plan>(this.url + `api/planes?planId=${planId}`,  plan, this.httpOptionsJson);
  }

  postFeedback(feedback: PostFeedback): Observable<any> {
    return this.http.post<any>(this.url + `api/planes/feedback`,  feedback, this.httpOptionsJson);
  }

  getFeedback(fecha: string, alumnoId: number ): Observable<GetFeedback> {
    return this.http.get<GetFeedback>(this.url + `api/planes/feedback?alumnoId=${alumnoId}&fecha=${fecha}`,  this.httpOptionsJson);
  }

  getPlanDiaData(fecha: string, alumnoId: number ): Observable<PlanDatosDia[]> {
    return this.http.get<PlanDatosDia[]>(this.url + `api/planes/dia?alumnoId=${alumnoId}&fecha=${fecha}`,  this.httpOptionsJson);
  }

  getPlanSemanaData(semana: string, alumnoId: number, titulo: string): Observable<PlanDatosDia[]> {
    return this.http.get<PlanDatosDia[]>(this.url + `api/planes/alumno?alumno_id=${alumnoId}&semana=${semana}&titulo=${titulo}`,  this.httpOptionsJson);
  }

  deletePlanDia(alumnoId: number, fecha: string): Observable<any> {
    return this.http.delete<any>(this.url + `api/planes/adicional?alumnoId=${alumnoId}&fecha=${fecha}`,  this.httpOptionsJson);
  }

  editDia(alumnoId: number, fecha: string, titulo: string, actividad: string): Observable<any> {
    //objeto requerido por backend
    let datos = [
      {
        planDetalleTitulo: "",
        actividad: ""
      }
    ];
    // ticket 2563
    const actividadCodificada: string = encodeURIComponent(actividad);
    return this.http.post<any>(this.url + `api/planes/adicional?alumnoId=${alumnoId}&fecha=${fecha}&titulo=${titulo}&actividad=${actividadCodificada}`,  datos, this.httpOptionsJson)
  }


  editarDiaCompleto(alumnoId: number, fecha: string, datos: PlanEditarDia[]): Observable<any> {
    const titulo: string = "";
    const actividad: string = "";
    return this.http.post<any>(this.url + `api/planes/adicional?alumnoId=${alumnoId}&fecha=${fecha}&titulo=${titulo}&actividad=${actividad}`,  datos, this.httpOptionsJson)
  }

  getDatosPorEtiqueta(etiqueta: string): Observable<DatoPorEtiqueta[]> {
    return this.http.get<DatoPorEtiqueta[]>(this.url + `api/parametros?etiqueta=${etiqueta}`,  this.httpOptionsJson);
  }

  heredar(semanaOrigen: string): Observable<string> {
    return this.http.get(this.url + `api/planes/heredar?semanaOrigen=${semanaOrigen}`,  {
      withCredentials: true,
      responseType: 'text'
    });
  }

  habilitar(semanaOrigen: string): Observable<string> {
    return this.http.get(this.url + `api/planes/habilitar?semanaOrigen=${semanaOrigen}`,  {
      withCredentials: true,
      responseType: 'text'
    });
  }
  
  controlPlanes(semanaOrigen: string): Observable<NoHeredable[]> {
    return this.http.get<NoHeredable[]>(this.url + `api/alumnos/controlplanes?semana=${semanaOrigen}`,  this.httpOptionsJson);
  }

  
  changeDays(alumnoId: number, diasFinal: CambioDiaEntrenamiento[]): Observable<Plan> {
    return this.http.put<Plan>(this.url + `api/alumnos/cambiodias?alumnoId=${alumnoId}`,  diasFinal, this.httpOptionsJson);
  }

}
