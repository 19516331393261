import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MessagesService } from '../../../../api/services/messages/messages.service';
import { RolService } from 'src/app/services/rol/rol.service';
import { SnackbarService } from 'src/app/utils/snackbar/snackbar.service';
import { Canal, CanalesPageable, Message } from '../../../../api/interfaces/messages';
import { SharedDataService } from '../../../../services/sharedData/shared-data.service';
import { MobileDetectorService } from 'src/app/services/mobileDetector/mobile-detector.service';

@Component({
  selector: 'app-messages-list',
  templateUrl: './messages-list.component.html',
  styleUrls: ['./messages-list.component.scss']
})
export class MessagesListComponent implements OnInit {

  @Output() changeCurrentMessageEvent = new EventEmitter<Canal>();
  @Output() massiveMessageEvent = new EventEmitter<boolean>();

  nombreApellido: string = "";
  
  canales: CanalesPageable;

  CANTIDAD_ELEMENTOS: number = 10;
  unreadAmount: number = 0;
  pagina: number = 0;

  moreMessagesAvalaible: boolean = true;
  searchEmpty: boolean = false;
  unreadMassive: boolean = false;
  isStudent: boolean = false;
  isMobile: boolean = false;
  isAdmin: boolean = true;
  isTrainer: boolean = false;

  moreStudentPagesAvailable: boolean = false;
  moreTrainersPagesAvailable: boolean = false;
  scrollingDown: boolean = false;

  constructor(
    private sharedData: SharedDataService,
    private messagesService: MessagesService,
    private snack: SnackbarService,
    private rol: RolService,
    private mobileService: MobileDetectorService
  ) { }

  ngOnInit(): void {
    this.isMobile = this.mobileService.isMobile();
    this.isAdmin = this.rol.isAdmin();
    this.isStudent = this.rol.isStudent();
    this.isTrainer = this.rol.isTrainer();
    this.loadMessagesList();
    this.sharedData.currentMessage.subscribe(message => {
      if(message === 'ultimoMensaje') {
        this.pagina = 0;
        this.loadMessagesList();
      }
    });
    this.getCantidad();
  }

  /**
   * This function change the selected canal, updating selected value for every canal and sets in 
   * sessionStorage the selected canal so others components can access it.
   * @param canal selected canal {@interface Canal} 
   * @param i number, required for index
   */
  changeCurrent(canal: Canal, i:number) {
    this.canales.content.forEach(option => option.selected = false);
    this.canales.content[i].selected = true;
    sessionStorage.setItem('canal',JSON.stringify(canal));
    this.sharedData.changeMessage("changeMessageOption");
    this.changeCurrentMessageEvent.emit(canal);
  }

  selectMassive() {
    this.canales.content.forEach(option => option.selected = false);
    this.sharedData.changeMessage("changeMessageOption");
    this.massiveMessageEvent.emit(true);
  }

  loadMessagesList() {
    if(this.pagina == 0) {
      this.canales = null;
    }
    //si es estudiante tiene que traer siempre todos los mensajes, y si no es no => !this.isStudent
    this.messagesService.getCanalesYMensajes(this.pagina,this.CANTIDAD_ELEMENTOS, !this.isStudent).subscribe(response => {
      this.completeData(response);
    }, error => {
      console.error(error);
      this.snack.snackTranslate('snacks.error.generic');
    });
  }

  /**
   * Set selected true if ultimoMensaje saved in sessionStorage match any mensaje in response.
   * Set response as canales value if pagina == 0 or push the values
   * @param response 
   */
  completeData(response) {
    let options: Canal[] = response.content;

    const aux: Message = JSON.parse(sessionStorage.getItem('ultimoMensaje'));
    if(aux && aux.canalId) {
      options.forEach(option =>  {
        if(option.id == aux.canalId) {
          option.selected = true;
        }
      })
    }

    //si pagina es cero quiero que el array sea la respuesta, sino debo agregar y conservar los anteriores
    if(this.pagina == 0) {
      this.canales = response;
      this.canales.content = options;
    } else {
      options.forEach(aux => this.canales.content.push(aux));
    }
    if(response.totalPages > (this.pagina + 1)) {
      this.moreMessagesAvalaible = true;
    } else {
      this.moreMessagesAvalaible = false;
    }
  }

  getCantidad() {
    this.messagesService.getCantidadNoLeidos().subscribe(response => {
      this.unreadAmount = response.totalPendientes;
      this.unreadMassive = response.masivosPendientes > 0 ? true: false;
     }, error => {
       console.error(error);
       this.snack.snackTranslate('snacks.error.generic');
     });
  }
  
  /**
   * Change canales content. If user scrolled more canals are added to this.canales.
   * @param canales 
   */
  loadSearchList(canales: Canal[]) {
    this.searchEmpty = false;
    if(canales) {
      if(!this.scrollingDown) {
        this.canales.content = canales;
      } else {
        canales.forEach(canal => {
          this.canales.content.push(canal);
        });
        this.scrollingDown = false;
      }
    } else {
      this.pagina = 0;
      this.loadMessagesList();
    }
  }

  onScrollDown() {
    if( (this.isAdmin || this.isTrainer) && this.moreMessagesAvalaible) {
      this.pagina++;
      this.loadMessagesList();
      this.sharedData.changeMessage("scrolledDown");
      this.scrollingDown = true;
    } 
  }

  searchCanalesEmpty() {
    this.canales.content = [];
    this.searchEmpty = true;
  }

  changePagesAvailable(status: boolean) {
    this.moreMessagesAvalaible = status;
  }
}
