import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DetalleFormularioDinamico, FormularioDinamicoPregunta, OpcionesMultiplesFormularioDinamicoPregunta, OpcionTextoFormularioDinamicoPregunta, RespuestaFormularioDinamicoPregunta } from 'src/app/api/interfaces/formulario-dinamico';
import { TiposPreguntaEnum } from 'src/app/enums/tiposPregunta';


/**
 * Main component. Receive all the question to show in the form.
 * Call all the inputs components.
* @param preguntas - questions to show in form
* @param showData - allow user to see preguntas, respuestas and formValid
* @example preguntas ts
* ```
* preguntasMock: FormularioDinamicoPregunta []
* puedeContinuar(puede: boolean) {
*     //do something
*   }
* 
*   crearRespuestas(respuestas: RespuestaFormularioDinamicoPregunta[]) {
*    //do something
*   }
*
* ```
* @example html
* ```
* <app-main-form-dinamico 
*     [preguntas]="preguntasMock"
*     [showData]="true"
*     (puedeContinuarEvent)="puedeContinuar($event)"
*     (respuestasEvent)="crearRespuestas($event)">
* </app-main-form-dinamico>
* ```
*/

@Component({
  selector: 'app-main-form-dinamico',
  templateUrl: './main-form-dinamico.component.html',
  styleUrls: ['./main-form-dinamico.component.scss']
})
export class MainFormDinamicoComponent implements OnInit {

  @Input('preguntas') preguntas: FormularioDinamicoPregunta[];
  @Input('showData') showData: boolean;

  @Output() opcionSeleccionadaEvent = new EventEmitter<DetalleFormularioDinamico>();
  @Output() respuestasEvent = new EventEmitter<RespuestaFormularioDinamicoPregunta[]>();
  @Output() puedeContinuarEvent = new EventEmitter<boolean>();

  respuestas : RespuestaFormularioDinamicoPregunta[] = [];

  puedeContinuar: boolean = false;
  
  cantidadRequeridas: number = 0;
  length: number = 0;

  constructor() { }

  tiposPreguntaEnum = TiposPreguntaEnum;

  ngOnInit(): void {
    this.preguntas.forEach(pregunta => {
      const respuestaVacia: RespuestaFormularioDinamicoPregunta = {
        titulo: pregunta.titulo,
        preguntaId: pregunta.id,
        respuesta: null,
        requerido: pregunta.requerido
      }
      if(pregunta.requerido) {
        this.cantidadRequeridas++;
      }
      this.respuestas.push(respuestaVacia);
    });
    this.length = this.preguntas.length;
  }

  /**
   * Receive pregunta and opcion. Save opcion.detalleDescripcion in respuestas 
   * @param opcion DetalleFormularioDinamico
   */
  opcionSeleccionada(opcion: DetalleFormularioDinamico) {
    const index: number = this.respuestas.findIndex(respuesta => (respuesta.preguntaId == opcion.preguntaId || respuesta.preguntaId == opcion.antecedentesPreguntaId));
    if(index != -1) {
      this.respuestas[index].respuesta = opcion.detalleDescripcion;
    }
    this.controlRespuestasRequired();
  }

  /**
   * Receive pregunta and opcion. Save opcion.detalleDescripcion in respuestas 
   * @param opcion OpcionesMultiplesFormularioDinamicoPregunta
   */
  opcionesSeleccionadasEvent(opcion: OpcionesMultiplesFormularioDinamicoPregunta ) {
    const index: number = this.respuestas.findIndex(respuesta => respuesta.preguntaId == opcion.preguntaId);
    if(index != -1) {
      this.respuestas[index].respuesta = opcion.respuestas;
    }
    this.controlRespuestasRequired();
  }

  /**
   * Receive pregunta and respuesta (string). Save opcion.respuestaTexto in respuestas 
   * @param opcion OpcionesMultiplesFormularioDinamicoPregunta
   */
  textoIngresado(opcion: OpcionTextoFormularioDinamicoPregunta) {
    const index: number = this.respuestas.findIndex(respuesta => respuesta.preguntaId == opcion.preguntaId);
    if(index != -1) {
      this.respuestas[index].respuesta = opcion.respuestaTexto;
    }
    this.controlRespuestasRequired();
  }

  /**
   * Loop respuestas and check respuesta != null if is required.
   * Change puedeContinuar true if all required preguntas are done. Emit respuestas
   * Change puedeContinuar false if not all required preguntas are done
   * Emit puedeContinar
   */
  controlRespuestasRequired() {
    let cantidadCompletadas: number = 0;
    this.respuestas.forEach(respuesta => {
      if(respuesta.respuesta && respuesta.requerido) {
        cantidadCompletadas++;
      }
    });
    if(this.cantidadRequeridas == cantidadCompletadas) {
      this.puedeContinuar = true;
      this.respuestasEvent.emit(this.respuestas);
    } else {
      this.puedeContinuar = false;
    }

    this.puedeContinuarEvent.emit(this.puedeContinuar);
  }
}
