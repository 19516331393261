import { Component, OnInit, Input } from '@angular/core';
import { of } from 'rxjs';
import { SupportUsefull } from 'src/app/api/interfaces/support';
import { SupportService } from 'src/app/api/services/support/support.service';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';

/**
 * Allow user to choose whether a support element is usefull or not.
 * Trigger ticket form if not usefull
 */
@Component({
  selector: 'app-support-tree-usefull',
  templateUrl: './support-tree-usefull.component.html',
  styleUrls: ['./support-tree-usefull.component.scss']
})
export class SupportTreeUsefullComponent implements OnInit {

  @Input('id') id: number;
  showThanks: boolean = false;

  constructor(
    private sharedData: SharedDataService,
    private supportService: SupportService,
  ) { }

  ngOnInit(): void {
  }

  async usefull(aux: boolean) {
    if(this.id) {
      const navegacionCompleta: number[] = JSON.parse(sessionStorage.getItem('navegacionCompleta'));
      const datos: SupportUsefull = {
        id: this.id,
        util: aux,
        navegacion: navegacionCompleta,
        ticketId: null
      }
      sessionStorage.setItem('usefullData',JSON.stringify(datos));
      if(aux) {
        this.showThanks = await this.supportService.postUsefull();
      } else {
        sessionStorage.setItem('postUsefullRequired','true');
        this.sharedData.changeMessage("notUsefull");
      }
    }
  }

}
