<div class="flexGrow">

    <div
        *ngIf="isMobile"
        (click)="toggleOption(2)">
        <div class="flexLeft body3Medium">
            <mat-icon class="blackIcon">
                chevron_left
            </mat-icon>
            {{'settings.options.two' | translate}}
        </div>
    </div>

    <mat-card class="cardGroup">
        <div class="flexBeetwen">
            <p class="subtitle3" *ngIf="!isMobile">  {{'settings.options.two' | translate}} </p>

            <p class="headline6"  *ngIf="isMobile">  {{'settings.arancel.arancelCard.title' | translate}} </p>
        </div>
        <p class="headline6"  *ngIf="!isMobile"> {{'settings.arancel.arancelCard.title' | translate}} </p>

        <div *ngFor="let parametro of parametros">
            <div class="flexBeetwen" >
                <div>
                    <p class="body2"> {{parametro.clave}} </p>
                </div>
                <div class="flexLeft w200-70">
                    
                    <div  class="flexLeft w125 body2">
                       $ {{parametro.valor}}
                    </div>
                    
                    <div class="flexLeft w125">
                        <button mat-icon-button (click)="openArancelDialog(parametro)"> 
                            <mat-icon class="iconMenu">
                                edit
                            </mat-icon>
                        </button>
                    </div>
                    
                </div>
            </div>
        </div>

        <mat-divider></mat-divider>
       
        <div class="flexLeft alignCenter">
            <mat-icon class="mediumIcon">
                help
            </mat-icon>
            <p class="body3Ligth"> {{'settings.arancel.arancelCard.help' | translate}}</p>
        </div>
    </mat-card>
   
</div>
