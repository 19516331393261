<div class="dialogContainer450">
    <div class="flexCenter">
        <mat-icon [class]="data.iconClass">{{data.icon}}</mat-icon>
    </div>
    <br/>
    <p class="headline5">{{data.title}}</p>
    <p class="subtitle1">{{data.text}}</p>
    <br/>

    <div class="flexColumn">
        <button *ngIf="data.cancel" class="primaryButton" mat-dialog-close mat-stroked-button color="primary">{{data.cancel}}</button>

        <button 
            *ngIf="data.confirm" 
            class="primaryButton" 
            (click)="confirmAndAction(data.actionFalse)" 
            mat-raised-button color="accent"> 
            {{data.confirm}}
        </button>
        
        <button 
            *ngIf="data.confirmAndAction" 
            class="primaryButton" 
            (click)="confirmAndAction(data.actionTrue)"
            mat-raised-button color="accent"> 
            {{data.confirmAndAction}}
        </button>
    </div>

    
</div>