import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ThemeService {
	currentTheme: string;
	currentThemeSubject = new Subject<string>();

	constructor() {}

	toggleThemeFromBackend(theme: string) {
		this.currentTheme = theme;
		this.currentThemeSubject.next(theme);
	}

	getTheme(): Observable<string> {
		return this.currentThemeSubject.asObservable();
	}
}