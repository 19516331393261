import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoginRoutingModule } from './login-routing.module';
import { MaterialModule } from '../../material/material.module';
import { GlobalModule } from '../global/modules/global.module';

import { LoginComponent } from './login/login.component';
import { AsideComponent } from './aside/aside.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { FooterComponent } from './footer/footer.component';
import { OneinfoconsultingComponent } from './oneinfoconsulting/oneinfoconsulting.component';
import { WelcomeComponent } from './welcome/welcome.component';


import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { RequestChangeComponent } from './request-change/request-change.component';
import { ChangeForgottenPasswordComponent } from './change-forgotten-password/change-forgotten-password.component';
import { TerminosCondicionesComponent } from './terminos-condiciones/terminos-condiciones.component';

@NgModule({
  declarations: [
    LoginComponent,
    AsideComponent,
    ChangePasswordComponent,
    FooterComponent,
    OneinfoconsultingComponent,
    WelcomeComponent,
    RequestChangeComponent,
    ChangeForgottenPasswordComponent,
    TerminosCondicionesComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    LoginRoutingModule,
    GlobalModule,
    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    })
  ],
  exports: [
    LoginComponent,
    ChangePasswordComponent,
    WelcomeComponent,
    LoginRoutingModule
  ]
})
export class LoginModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}