import { Component, OnInit, ViewChild, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SharedDataService } from '../../../../services/sharedData/shared-data.service';
import { Filters, FiltersStorage, Level, PersonalTrainer, UserComplete } from '../../../../api/interfaces/user';
import { MobileDetectorService } from '../../../../services/mobileDetector/mobile-detector.service';
import {MatAccordion} from '@angular/material/expansion';
import { TrainingService } from 'src/app/api/services/training/training.service';
import { RegisterService } from 'src/app/api/services/register/register.service';
import { Status, TrainingDays } from 'src/app/api/interfaces/register';
import { RolService } from 'src/app/services/rol/rol.service';
import { StatusEnum } from 'src/app/enums/status';


@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {

  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild('trainerSelect') trainerSelect;
  @Output() filtersChangeEvent = new EventEmitter<Filters>();
  @Output() filtersFromSessionStorageEvent = new EventEmitter<Filters>();

  isMobile: boolean = false;
  isTrainer: boolean = false;
  trainer: PersonalTrainer = null;
  contador: number;

  filtersForm = new FormGroup({
    nombreApellido: new FormControl(),
    status: new FormControl(),
    type: new FormControl(),
    level: new FormControl(),
    trainer: new FormControl(),
    leido: new FormControl()
  });

  allStatus: Status[] = null;
  types: any;
  levels: any;
  trainers: any;
  modes: any;
  trainingDays: TrainingDays[] = [];
  disabled: boolean = true;
  statusClass = "inputsFilter";

  leidoOpciones = [
    {
      nombre: "Gestionado",
      value: true
    },
    {
      nombre: "Sin gestionar",
      value: false
    }
  ];

  constructor(
    private sharedData: SharedDataService,
    private mobileDetectorService: MobileDetectorService,
    private trainingService: TrainingService,
    private registerService: RegisterService,
    private cdr: ChangeDetectorRef,
    private roleService: RolService
  ) { }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    this.isTrainer = this.roleService.isTrainer();
    //se anidaron las llamadas a api para tener todos los datos cargados al momento de leer los filtros desde localstorage
    // optimizacion: llamados en paralelo
    this.loadLevels();
    this.loadTrainers();
    this.getTrainingDays();
    this.loadStatus();
    this.setFiltersFromLocalStorage();
    this.isMobile = this.mobileDetectorService.isMobile();
    this.cdr.detectChanges();
    this.filtersForm.statusChanges
    .subscribe(val => {
      const { nombreApellido, status, type, level, trainer, leido } = this.filtersForm.getRawValue();
      this.contador = 0;
      if(status) {
        this.contador++;
        this.statusClass = "inputsFilterWithContent"
      } else {
        this.statusClass = "inputsFilter"
      }
      if(type) {
        this.contador++;
      }
      if(level) {
        this.contador++;
      }
      if(trainer) {
        this.contador++;
      }
      if(leido != null) {
        this.contador++;
      }
      if(this.contador > 0) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    });
  }

  setFiltersFromLocalStorage() {
    const filters: FiltersStorage = JSON.parse(sessionStorage.getItem('filters'));
    const fromDay: boolean = JSON.parse(sessionStorage.getItem('fromDay'));
    if(filters) {
      this.filtersForm.patchValue({nombreApellido: filters.nombreApellido});

      if(this.trainers) {

        let index: number = this.trainers.findIndex(element => element.id == filters.trainer);
        this.filtersForm.patchValue({trainer: this.trainers[index]});
      } else {
        this.filtersForm.patchValue({trainer: filters.trainer});
      }
      if(this.allStatus) {

        const index: number = this.allStatus.findIndex(element => element.estado == filters.status);
        this.filtersForm.patchValue({status: this.allStatus[index]});
      } else {
        this.filtersForm.patchValue({status: filters.status});
      }
      
      this.filtersForm.patchValue({leido: filters.leido});
      this.filtersForm.patchValue({level: filters.level});
      this.filtersForm.patchValue({type: filters.type});
      this.filtersFromSessionStorageEvent.emit(this.filtersForm.value);
    }
    if(!filters && fromDay) {
      this.filtersFromSessionStorageEvent.emit(this.filtersForm.value);
      this.disabled = false;
    }

    if(this.isTrainer && !filters && !fromDay) {
      const idEntrenador: number = parseInt(localStorage.getItem('idEntrenador'),10);
      this.filtersForm.patchValue({trainer: idEntrenador})
      this.onSubmit();
    }
  }

  loadStatus() {
    this.trainingService.getStatus().subscribe(response => {
      if(this.isTrainer) {
        let index: number = response.findIndex(element => element.estado == StatusEnum.ACTIVADO);
        this.filtersForm.patchValue({status: response[index]});
        this.allStatus = [];
        this.allStatus.push(response[index]);
        index = response.findIndex(element => element.estado == StatusEnum.SUSPENDIDO);
        this.allStatus.push(response[index]);
      } else {
        this.allStatus = response;
      }
      const filters: FiltersStorage = JSON.parse(sessionStorage.getItem('filters'));
      if(filters && filters.status) {
        let index: number = response.findIndex(element => element.estado == filters.status);
        this.filtersForm.patchValue({status: response[index]});
      }
    },
    error => {
      console.error(error);
    });
  }

  loadLevels() {
    this.trainingService.getLevels().subscribe( response=> {
      this.levels = response;
    },
    error => {
      console.error(error);
    });
  }

  loadTrainers() {
    this.trainingService.getTrainers().subscribe( response=> {
      this.trainers = response;
      if(this.isTrainer) {
        const idEntrenadorLogueado: number = parseInt(localStorage.getItem('idEntrenador'),10);
        const index: number = this.trainers.findIndex(element => element.id == idEntrenadorLogueado);
        this.filtersForm.patchValue({trainer: this.trainers[index]});
      }
      const filters: FiltersStorage = JSON.parse(sessionStorage.getItem('filters'));
      if(filters && filters.trainer) {
        let index: number = response.findIndex(element => element.id == filters.trainer);
        this.filtersForm.patchValue({trainer: response[index]});
      }
    },
    error => {
      console.error(error);
    });
  }

  getTrainingDays () {
    this.registerService.getTrainingDays()
    .subscribe(response => {
      this.trainingDays = response;
    },
      error => {
        console.error(error);
      });
  }

  onSubmit() {
    this.filtersChangeEvent.emit(this.filtersForm.value);
  }

  clearAll() {
    this.filtersForm.patchValue({type: null});
    this.filtersForm.patchValue({level: null});
    if(!this.isTrainer) {
      this.filtersForm.patchValue({status: null});
      this.filtersForm.patchValue({trainer: null});
    }
    this.filtersForm.patchValue({leido: null});
    sessionStorage.removeItem('filters');
    this.filtersChangeEvent.emit(this.filtersForm.value);
  }

  clear(target: string) {
    switch (target) {
      case 'nombreApellido':
      this.filtersForm.patchValue({nombreApellido: null});
      break;
      
      
      case 'status':
      this.filtersForm.patchValue({status: null});
      break;
      
      case 'type':
      this.filtersForm.patchValue({type: null});
      break;
      
      case 'level':
      this.filtersForm.patchValue({level: null});
      break;
      
      case 'trainer':
      this.filtersForm.patchValue({trainer: null});
      break;

      case 'leido':
      this.filtersForm.patchValue({leido: null});
      break;
    }
    if(!this.filtersForm.value.leido && !this.filtersForm.value.level && !this.filtersForm.value.nombreApellido && !this.filtersForm.value.status && !this.filtersForm.value.trainer && !this.filtersForm.value.type) {
      sessionStorage.removeItem('filters');
    }
    this.filtersChangeEvent.emit(this.filtersForm.value);
  }

  // saveTrainingDay(option: TrainingDays) {
  //   this.filtersForm.patchValue({type: option});
  // }

}

