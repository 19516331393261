<mat-card class="cardProfile">
    <p class="headline6"> {{'profile.statusHistoryTab.history' | translate}}</p>

    <div class="flexBeetwen" *ngFor="let element of historial">
        <p class="body3">{{element.fechaModificacion | date:'dd/MM/YYY'}}</p>
        <p class="body3" *ngIf="element.estado == StatusEnum.PENDING">
            {{'status.P' | translate}}
        </p>

        <p class="body3" *ngIf="element.estado == StatusEnum.ACTIVADO">
            {{'status.A' | translate}}
        </p>

        <p class="body3" *ngIf="element.estado == StatusEnum.CREATED">
            {{'status.C' | translate}}
        </p>

        <p class="body3" *ngIf="element.estado == StatusEnum.INACTIVE">
            {{'status.I' | translate}}
        </p>

        <p class="body3" *ngIf="element.estado == StatusEnum.SUSPENDIDO">
            {{'status.S' | translate}}
        </p>
    </div>

    <div class="flexBeetwen" *ngFor="let element of historialTrainer">
        <p class="body3">{{element.fechaModificacion | date:'dd/MM/YYY'}}</p>
        <p class="body3" *ngIf="element.estado == StatusEnum.PENDING">
            {{'status.P' | translate}}
        </p>

        <p class="body3" *ngIf="element.estado == StatusEnum.ACTIVADO">
            {{'status.A' | translate}}
        </p>

        <p class="body3" *ngIf="element.estado == StatusEnum.CREATED">
            {{'status.C' | translate}}
        </p>

        <p class="body3" *ngIf="element.estado == StatusEnum.INACTIVE">
            {{'status.I' | translate}}
        </p>

        <p class="body3" *ngIf="element.estado == StatusEnum.SUSPENDIDO">
            {{'status.S' | translate}}
        </p>
    </div>
</mat-card>


  
