import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';
import { Physiological } from '../../../../api/interfaces/user';

@Component({
  selector: 'app-physiological-data',
  templateUrl: './physiological-data.component.html',
  styleUrls: ['./physiological-data.component.scss']
})
export class PhysiologicalDataComponent implements OnInit {

  disable: boolean = true;
  @Input('estado') estado: string;
  @Input('physiologicalData') physiologicalData: Physiological;
  @Output() onSubmitPhysiologicalEvent = new EventEmitter<Physiological>();

  physiologicalForm = new FormGroup({
    peso: new FormControl({ value: '', disabled: this.disable }),
    altura: new FormControl({ value: '', disabled: this.disable }),
  });
  constructor(
    private sharedData: SharedDataService
  ) { }

  ngOnInit(): void {
    this.patch();
    this.sharedData.currentMessage.subscribe(message => {
      if (message === "updateProfileData") {
        this.patch();
      }
    });
  }

  patch() {
    this.physiologicalForm.patchValue({peso: this.physiologicalData.peso});
    this.physiologicalForm.patchValue({altura: this.physiologicalData.altura});
  }

  edit() {
    this.disable = !this.disable;
    if(!this.disable) {
      this.physiologicalForm.controls['peso'].enable();
      this.physiologicalForm.controls['altura'].enable();
    } else {
      this.physiologicalForm.controls['peso'].disable();
      this.physiologicalForm.controls['altura'].disable();
    }
  }

  onSubmit() {
    this.edit();
    this.onSubmitPhysiologicalEvent.emit(this.physiologicalForm.value);
    
  }

}
