import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { SnackbarService } from '../../../utils/snackbar/snackbar.service';
import { UserFromAdmin, StudentFromAdmin, InsertUser, Antecedente, ControlCantidad  } from '../../../api/interfaces/register';
import { Filters, HistorialSuspension, MotivoBaja, StudentsPageable, SuspenderAlumno, SuspensionAlumno, SuspensionMotivo, UserComplete } from '../../../api/interfaces/user';
import { Role } from '../../../enums/role';

@Injectable({
  providedIn: 'root'
})
export class StudentService {

  private url = environment.apiUrl + '/';
  // response.setHeader("Access-Control-Allow-Headers", "Origin, X-Requested-With, Content-Type, Accept");
  httpOptionsJson = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }),
    withCredentials: true,
  };


  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json'
    }),
    withCredentials: true,
  };
  constructor(
    private http: HttpClient,
    // private tokenDecoderService: TokenDecoderService,
    private snack: SnackbarService
  ) { }

  setRead(alumnoId: number, leido: boolean): Observable<any> {
    let body = {
      algo: "algo"
    }
    return this.http.put<any>(this.url + `api/alumnos/leido?alumno_id=${alumnoId}&leido=${leido}`, body,  this.httpOptionsJson);
  }

  createStudentPost(studentComplete: StudentFromAdmin): Observable<any> {
    return this.http.post<any>(this.url + 'api/alumnos/', studentComplete, this.httpOptionsJson);
  }

  getStudents(page: number, role: number, maxPerPage: number): Observable<UserComplete[]> {
    return this.http.get<UserComplete[]>(this.url + `api/usuarios/?rol=${role}/MisCasos?pagina=${page}&maximoPorPagina=${maxPerPage}`, this.httpOptions);
  }

  getStudentsWithPaginator(cantidadPorPagina:number, pagina: number, filters: Filters): Observable<StudentsPageable> {
    return this.http.get<StudentsPageable>(this.url + `api/alumnos/listado?ape=${filters.nombreApellido}&est=${filters.estado}&nid=${filters.entrenamientoNivelId}&gid=${filters.entrenamientoTipoId}&eid=${filters.entrenadorId}&pag=${pagina}&reg=${cantidadPorPagina}&leido=${filters.leido}`,  this.httpOptions);
  }

  getAmountsApi(rol: number): Observable<any> {
    return this.http.get<any>(this.url + `api/usuarios/estadisticas/?rol=${rol}`,this.httpOptions);
  }

  getSuspensionesMotivos(): Observable<SuspensionMotivo[]> {
    return this.http.get<SuspensionMotivo[]>(this.url + `api/suspensionesmotivos`,this.httpOptions);
  }

  getSuspensionesAlumno(alumnoId: number): Observable<SuspensionAlumno[]> {
    return this.http.get<SuspensionAlumno[]>(this.url + `api/alumnos/suspensiones?alumnoId=${alumnoId}`,this.httpOptions);
  }

  postSuspenderAlumno(alumnoId: number, suspender: SuspenderAlumno): Observable<any> {
    return this.http.post<any>(this.url + `api/alumnos/suspensiones?alumnoId=${alumnoId}&mail=true`, suspender, this.httpOptionsJson);
  }

  deleteSuspension(suspensionId: number): Observable<any> {
    return this.http.delete<any>(this.url + `api/alumnos/suspensiones?suspensionId=${suspensionId}`,  this.httpOptionsJson);
  }

  cambiarEstado(usuarioId: number, estado: string, motivoId: number): Observable<any> {
    let body = {
      algo: "algo"
    }
    return this.http.put<any>(this.url + `api/usuarios/desactivar?usuarioId=${usuarioId}&estado=${estado}&motivoId=${motivoId}`, body, this.httpOptions);
  }

  getHistorialSuspensionesAlumno(alumnoId: number): Observable<HistorialSuspension[]> {
    return this.http.get<HistorialSuspension[]>(this.url + `api/alumnos/estadoslog?alumnoId=${alumnoId}`,this.httpOptions);
  }

  getMotivosBaja(): Observable<MotivoBaja[]> {
    return this.http.get<MotivoBaja[]>(this.url + `api/bajamotivos`,this.httpOptions);
  }

  getIdAlumno(usuarioId: number): Observable<number> {
    return this.http.get<number>(this.url + `api/usuarios/alumnoid?usuarioId=${usuarioId}`,this.httpOptions);
  }

  resendEmail(email: string, id: number): Observable<any> {
    return this.http.get<any>(this.url + `api/alumnos/reenvioalta?alumno_id=${id}&email=${email}`, this.httpOptionsJson);
  }

  getAntecedentes(alumnoId: number): Observable< Antecedente[]> {
    return this.http.get< Antecedente[]>(this.url + `api/alumnos/listAntecedentes/?alumnoId=${alumnoId}`,this.httpOptions);
  }

  getAmounts(rol: number): Promise <number> {
    return new Promise((resolve,reject) => {
      this.getAmountsApi(rol).subscribe(async response => {
        resolve(response);
      },
      error => {
        console.error(error);
        reject();
      })
    })
  }

  public getCantidadAlumnos(): Observable<ControlCantidad> {
    return this.http.get<ControlCantidad>(this.url + `api/alumnos/cantidadAlumnos`,this.httpOptions);
  }

  public insertUser(newUser: InsertUser): Observable<number> {
    return this.http.post<number>(this.url + 'api/usuarios', newUser, this.httpOptionsJson);
  }

  public completeUserData(student: StudentFromAdmin, userId: number): InsertUser {
    let today = new Date().toISOString();
    let newUser: InsertUser = {
      usuario: student.email,
      descripcionUsuario: student.nombre + " " + student.apellido,
      rolId: Role.STUDENT,
      activo: 1,
      creado: today,
      creadoPor: userId,
      ultimaModificacion: today,
      ultimaModificacionPor: userId
    }
    return newUser;
  }

  public completeStudentData(student: StudentFromAdmin, usuarioId: number ): StudentFromAdmin{
    
    let today = new Date().toISOString();
    const idEntrenador: number = parseInt(localStorage.getItem('idEntrenador'),10);
    const idAdmin: number = parseInt(localStorage.getItem('idUsuario'),10);

    let studentComplete: StudentFromAdmin = {
      email : student.email,
      nombre : student.nombre,
      apellido : student.apellido,
      segundoApellido : student.segundoApellido,
      estado: "C",
      fechaNacimiento: "1900-01-01T00:00:00Z",
      numeroDocumento: null,
      paisId: null,
      residencia: null,
      prefijoCelular: null,
      numeroCelular: null,
      prefijoEmergencia: null,
      numeroEmergencia: null,
      peso: null,
      altura: null,
      avatarUrl: null,
      entrenamientoTipoId: 1,
      entrenamientoGrupoId: null,
      entrenamientoNivelId: null,
      entrenadorId: idEntrenador,
      activo: true,
      creado: today,
      creadoPor: idAdmin, 
      ultimaModificacion: today,
      ultimaModificacionPor: idAdmin, 
      usuarioId: usuarioId,
    };
    return studentComplete;   
  }
}