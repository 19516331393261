<button mat-button (click)="navigate()">
    <div class="flexBeetwenCenter">
        <mat-icon class="blackIcon">
            chevron_left
        </mat-icon>
        <span *ngIf="!isAdminOrTrainer">
            {{'navigation.students' | translate}}
        </span>

        <span *ngIf="isAdminOrTrainer">
            {{'navigation.trainers' | translate}}
        </span>
    </div>
</button>
