import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'dateName'
})
export class DateNamePipe implements PipeTransform {
 
  constructor(private translateService: TranslateService) {
  }
 
  public transform(value: any, pattern: string): any {
    const datePipe = new DatePipe(this.translateService.currentLang);
    let aux: string = datePipe.transform(value, pattern);
    aux = aux.charAt(0).toUpperCase() + aux.slice(1);
    return aux;
  }
}
