import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-settings-card',
  templateUrl: './settings-card.component.html',
  styleUrls: ['./settings-card.component.scss']
})
export class SettingsCardComponent implements OnInit {

  @Input('showGroups') showGroups: boolean;
  @Input('showArancel') showArancel: boolean;

  @Output() toggleOptionEvent = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  toggleOption(option: number) {
    this.toggleOptionEvent.emit(option);
  }

}
