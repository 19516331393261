import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SnackbarService } from '../../../utils/snackbar/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../api/services/login-service.service';
import { LoginUser } from '../../../api/interfaces/login';
import { FlagsColor } from 'src/app/enums/sharedDataFlag';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  hide: boolean = true;
  public activeLang = 'es';
  constructor(
    private snack: SnackbarService,
    private translate: TranslateService,
    private loginApiService: LoginService
  ) {
    this.translate.setDefaultLang(this.activeLang);
  }

  userForm = new FormGroup({
    email: new FormControl(),
    password: new FormControl()
  });

  ngOnInit(): void {
    // ticket 3548
    sessionStorage.setItem(FlagsColor.setDefaultTheme,'true');

    //service workers: prevent login if cookies are valid
    this.loginApiService.handleUserData();
    sessionStorage.setItem("hola","hola");
  }

  onSubmit() {
    if (this.userForm.value.email && this.userForm.value.password) {
      this.iniciarSesion();
    }
  }

  iniciarSesion() {
    this.loginApiService.loginUser({
      "password": this.userForm.value.password,
      "username": this.userForm.value.email
    } as LoginUser)
      .subscribe(user => {
        this.loginApiService.handleUserData();
      },
        error => {
          if (error.status != 200) {
            console.error(error);
            if(error.error) {
              if(error.status != 504) {
                this.snack.openSnackBar(error.error);
              }
            } else {

              switch (error.status) {
                case 401:
                  this.snack.snackTranslate('snacks.error.login');
                  // this.snack.snackTranslate('snacks.error.loginEmail');
                  break;
                  // case 404:
                  //   break;
                  case 504: 
                  // message should be displayed in offline interceptor if is 504
                  break;
                  default:
                    if(error.status != undefined) {
                      //message should be displayed in offline interceptor if is undefined
                      this.snack.snackTranslate('snacks.error.generic');
                    }
                    break;
                  }
                }
          } else {
             this.loginApiService.handleUserData();
          }
        });
  }

  toggleVisibilitiy(event) {
    this.hide = !this.hide;
  }
}

/*
  401: user contraseña incorrectos
  404: no registrado/no coinciden (pero validar)


*/
