import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from '../../../utils/snackbar/snackbar.service';
import { Level, PersonalTrainer, TrainingDay, TrainingType } from '../../../api/interfaces/user';
import { RegisterService } from '../../../api/services/register/register.service';
import { TrainingService } from '../../../api/services/training/training.service';

@Component({
  selector: 'app-assign-dialog',
  templateUrl: './assign-dialog.component.html',
  styleUrls: ['./assign-dialog.component.scss']
})
export class AssignDialogComponent implements OnInit {

  levels: Level[] = [];
  trainers: PersonalTrainer[] = [];
  trainingTypes: TrainingType[] = [];
  days: TrainingDay[] = [];

  userForm = new FormGroup({
    level: new FormControl(),
    trainer: new FormControl(),
    trainingType: new FormControl(),
    days: new FormControl(),
  });

  constructor(
    public dialog: MatDialog,
    private snack: SnackbarService,
    private registerService: RegisterService,
    private trainingService: TrainingService
  ) { }

  ngOnInit(): void {
    this.loadLevels();
    this.loadTrainers();
    this.loadTrainingType();
    this.loadDays();
  }

  loadLevels() {
    // this.trainingService.getLevels().subscribe( response=> {
    //   this.levels = response;
    // },
    // error => {
    //   console.error(error);
    // });
    // this.levels = [{
    //   name: "Iniciación",
    //   id_level: 1,
    // },
    // {
    //   name: "Intermedio",
    //   id_level: 2,
    // }];
  }

  loadTrainers() {
    // this.trainingService.getTrainers().subscribe( response=> {
    //   this.trainers = response;
    // },
    // error => {
    //   console.error(error);
    // });
    
    // this.trainers = [{
    //   name: "Entrenador",
    //   lastName: "Apellido",
    //   secondLastName: "",
    //   user_id: 1
    // },
    // {
    //   name: "Entrenador",
    //   lastName: "Apellido",
    //   secondLastName: "",
    //   user_id: 2
    // }];
  }

  loadTrainingType() {
    // this.trainingService.getTrainingType().subscribe( response=> {
    //   this.trainingTypes = response;
    // },
    // error => {
    //   console.error(error);
    // });
    // this.trainingTypes = [{
    //   mode:1,
    //   name: "Online"
    // },
    // {
    //   mode: 2,
    //   name: "Presencial"
    // }
    // ]
  }

  loadDays() {
    
    // this.registerService.getTrainingDays().subscribe( response=> {
    //   this.days = response;
    // },
    // error => {
    //   console.error(error);
    // });
    
    this.days = [{
      value: "Lunes y miércoles",
      hour: "19:00hs",
      days: [1,3],
      id_trainingDay: 1,
    },
    {
      value: "Miercoles y sábados",
      hour: "15:00hs",
      days: [3,6],
      id_trainingDay: 2,
    }]
  }

  onSubmit() {
    // const { days, level, trainer, trainingType } = this.userForm.getRawValue();
    // let aux = {
    //   id_trainingDay: days.id_trainingDay,
    //   id_level: level.id_level,
    //   id_trainer: trainer.user_id,
    //   mode: trainingType.mode,
    // }

    // this.trainingService.patchStudentTrainingData(aux)
    //   .subscribe(response => {
    //     this.snack.snackTranslate('snacks.error.trainingDataPatch');
    //   },
    //   error => {
    //     console.error(error);
    //     this.snack.snackTranslate('snacks.sucess.updatingData.generic');
    //   });
  }

}
