import { Injectable } from '@angular/core';
import { SharedDataService } from '../sharedData/shared-data.service';

@Injectable({
  providedIn: 'root'
})
export class FormValidatorService {

  constructor(
    private sharedData: SharedDataService
  ) { }


  public onFormValidation(validity: string, message: string, mode: string) {
    switch (validity) {
      case "VALID":
        this.sharedData.changeMessage(message+"valid");
        break;
      case "INVALID":
        let aux = sessionStorage.getItem(mode)
        if(aux) {
          this.sharedData.changeMessage(message+"invalid");
        }
        break;
    }
  }

  public onInputValidation(validity: string, message: string, index: number) {
    switch (validity) {
      case "VALID":
        this.sharedData.changeMessage(message+"valid"+","+index);
        break;
      case "INVALID":
          this.sharedData.changeMessage(message+"invalid"+","+index);
        break;
    }
  }

  public onDynamicValidation(validity: string, message: string) {
    switch (validity) {
      case "VALID":
        this.sharedData.changeMessage(message+"valid");
        break;
      case "INVALID":
          this.sharedData.changeMessage(message+"invalid");
        break;
    }
  }

}
