<form [formGroup]="optionsForm">
    <p class="subtitle1 textLeft">{{pregunta.titulo}} <span *ngIf="requerido"> *</span></p>

    <mat-radio-group 
            aria-labelledby="radioGroupColumn" 
            [class]="radioGroupClass" 
            formControlName="valor"
            [required]="requerido">
            
            <mat-radio-button 
            color="primary"
            [class]="radioButtonClass"
            *ngFor="let opcion of opciones"
            [value]="opcion">
            {{opcion.detalleDescripcion}}
        </mat-radio-button>
    </mat-radio-group>

</form>