<br/>
<form [formGroup]="planForm" class="responsiveFlex">
    <div class="firstColumn">
        <span class="headline6">{{'planes.forms.description' | translate}}</span>
        <br/>
                
        <span class="subtitle3Blue">{{'planes.forms.fase1' | translate}}</span>
        <br/>
        <mat-form-field appearance="fill" class="inputPlan">
            <textarea matInput id="fase1Input" formControlName="fase1" type="text"></textarea>
        </mat-form-field>
        <br/>
        
        <span class="subtitle3Blue">{{'planes.forms.fase2' | translate}}</span>
        <br/>
        <mat-form-field appearance="fill" class="inputPlan">
            <textarea matInput id="fase2Input" formControlName="fase2" type="text"></textarea>
        </mat-form-field>
        <br/>
        
        <span class="subtitle3Blue">{{'planes.forms.fase3' | translate}}</span>
        <br/>
        <mat-form-field appearance="fill" class="inputPlan">
            <textarea matInput id="fase3Input" formControlName="fase3" type="text"></textarea>
        </mat-form-field>
        <br/>
    </div>

    <div>
        <div class="spaceTop"></div>
        <span class="subtitle3">{{'planes.forms.invisible' | translate}}</span>
        <br/>
        <mat-form-field appearance="fill" class="inputPlan">
            <textarea class="inputInvisible" matInput id="invisibleInput" formControlName="invisible" type="text" ></textarea>
        </mat-form-field>
        
        <div class="spaceTop2"></div>
        <span class="subtitle3">{{'planes.forms.especificaciones' | translate}}</span>
        <br/>
        <mat-form-field appearance="fill" class="inputPlan">
            <textarea matInput id="especificacionesInput" formControlName="especificaciones" type="text"></textarea>
        </mat-form-field>
        <br/>
    </div>
    
</form>
