import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'
import { DomiciliacionService } from '../../../../api/services/domiciliacion/domiciliacion.service';
import { SnackbarService } from '../../../../utils/snackbar/snackbar.service';

/**
 * Admin can create a new remesa
 */
@Component({
  selector: 'app-new-popup-domiciliacion',
  templateUrl: './new-popup-domiciliacion.component.html',
  styleUrls: ['./new-popup-domiciliacion.component.scss']
})
export class NewPopupDomiciliacionComponent implements OnInit {


  today: Date;
  constructor(
    private domiciliacionService: DomiciliacionService,
    private snack: SnackbarService,
    private dialogRef: MatDialog,
  ) { }

  ngOnInit(): void {
    this.today = new Date();
  }

  onSubmit() {
    const aux = new Date();
    let month  = aux.getMonth().toString();
    //parse month
    month = (parseInt(month,10) + 1).toString();
    if(parseInt(month,10) < 10) {
      month = "0" + month;
    }
    //parse date
    let fecha: string = `${aux.getDate()}-${month}-${aux.getFullYear()}`;
    this.domiciliacionService.crearFechaDomiciliacion(fecha).subscribe( response => {
      this.snack.snackTranslate('snacks.sucess.domiciliacion.newDate');
      this.dialogRef.closeAll();
    },
    error => {
      console.error(error);
      this.snack.openSnackBar(error.error.message);
    });
  }

}
