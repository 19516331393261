import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BankDataComponent } from './data/bank-data/bank-data.component';
import { ContactDataComponent } from './data/contact-data/contact-data.component';
import { DomicileDataComponent } from './data/domicile-data/domicile-data.component';
import { PersonalDataComponent } from './data/personal-data/personal-data.component';
import { PhysiologicalDataComponent } from './data/physiological-data/physiological-data.component';
import { ProfileHeaderComponent } from './profile-header/profile-header.component';
import { MainComponent } from './main/main.component';
import { MaterialModule } from '../../material/material.module';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { TitleComponent } from './title/title.component';
import { AssignDialogComponent } from './assign-dialog/assign-dialog.component';
import { BackgroundObjetivesComponent } from './backgroundAndObjetives/background-objetives/background-objetives.component';
import { BackgroundComponent } from './backgroundAndObjetives/background/background.component';
import { ObjetivesCardComponent } from './backgroundAndObjetives/objetives-card/objetives-card.component';
import { RegistrationModule } from '../registration/modules/registration.module';
import { AllDataComponent } from './all-data/all-data.component';
import { GlobalModule } from '../global/modules/global.module';
import { PlansModule } from '../plans/modules/plans.module';
import { AchievementsCardComponent } from './backgroundAndObjetives/achievements-card/achievements-card.component';
import { FormacionCardComponent } from './formacion/formacion-card/formacion-card.component';
import { FormacionInputsComponent } from './formacion/formacion-inputs/formacion-inputs.component';
import { StatusHistoryComponent } from './status-register-tab/status-history/status-history.component';
import { InactividadCardComponent } from './status-register-tab/inactividad/inactividad-card/inactividad-card.component';
import { InactividadInputsComponent } from './status-register-tab/inactividad/inactividad-inputs/inactividad-inputs.component';
import { DisableComponent } from './status-register-tab/disable/disable.component';
import { StatusRegisterCardComponent } from './status-register-tab/status-register-card/status-register-card.component';
import { TrainerDataComponent } from './data/trainer-data/trainer-data.component';
import { ResendMailComponent } from './resend-mail/resend-mail.component';
import { GalleryMainComponent } from './gallery/gallery-main/gallery-main.component';
import { GalleryElementNewComponent } from './gallery/gallery-element-new/gallery-element-new.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { GalleryFiltersComponent } from './gallery/gallery-filters/gallery-filters.component';


@NgModule({
  declarations: [
    BankDataComponent,
    ContactDataComponent,
    DomicileDataComponent,
    PersonalDataComponent,
    ProfileHeaderComponent,
    PhysiologicalDataComponent,
    MainComponent,
    TitleComponent,
    AssignDialogComponent,
    BackgroundObjetivesComponent,
    BackgroundComponent,
    ObjetivesCardComponent,
    AllDataComponent,
    AchievementsCardComponent,
    FormacionCardComponent,
    FormacionInputsComponent,
    StatusHistoryComponent,
    InactividadCardComponent,
    InactividadInputsComponent,
    DisableComponent,
    StatusRegisterCardComponent,
    TrainerDataComponent,
    ResendMailComponent,
    GalleryMainComponent,
    GalleryElementNewComponent,
    GalleryFiltersComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    RegistrationModule,
    GlobalModule,
    PdfViewerModule,
    PlansModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
  ],
  exports: [
    MainComponent
  ]
})
export class ProfileModule { }
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}