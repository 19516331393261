import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Formacion } from '../../../../api/interfaces/trainers';
import { Subscription } from 'rxjs';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';

@Component({
  selector: 'app-formacion-card',
  templateUrl: './formacion-card.component.html',
  styleUrls: ['./formacion-card.component.scss']
})
export class FormacionCardComponent implements OnInit, OnDestroy {

  private sharedData$: Subscription;
  disable: boolean = false;

  @Input('formacion') formacion: Formacion[];
  @Input('entrenadorId') entrenadorId: number;

  constructor(
    private sharedData: SharedDataService
  ) { }

  ngOnInit(): void {
    this.sharedData$ = this.sharedData.currentFlag.subscribe(flag => {
      if(flag) {
        if(flag.key == "editFormacion") {
          this.disable = flag.value;
        }
      }
    });
  }

  addFormacion() {
    let aux: Formacion = {
      id: -1,
      entrenadorFormacionTitulo: null,
      entrenadorFormacionDescripcion: null,
      entrenadorId: null,
      creado: null,
      creadoPor: null,
      ultimaModificacion: null,
      ultimaModificacionPor: null,
      activo: true,
      valid: false
    }
    this.formacion.push(aux);
  }

  /**
   * Delete one formacion element if it don't exist in db
   * @param i - number, index to delete
   * 
   */
  removeFormacion(i: number) {
    this.formacion.splice(i,1);
  }

  public ngOnDestroy(): void {
    this.sharedData$.unsubscribe();
  }

}
