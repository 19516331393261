import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-navigation-button',
  templateUrl: './navigation-button.component.html',
  styleUrls: ['./navigation-button.component.scss']
})
export class NavigationButtonComponent implements OnInit {

  @Input('path') path: string;
  @Input('isAdminOrTrainer') isAdminOrTrainer: boolean;

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  navigate() {
    this.router.navigate([this.path]);
  }

}
