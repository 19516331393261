<form [formGroup]="userForm">

    <p *ngIf="isMobile" class="headline6Light"> 1/{{cantidadPasosHabilitados}} {{ 'forms.user.dataTitle' | translate}}</p>
    <mat-card class="cardCenter2">
        <p class="subtitle3">{{ 'forms.user.dataTitle' | translate}}</p>

        <app-avatar
            *ngIf="usuarioId"
            [class]="'imgRounded'"
            [containerClass]="'uploadDiv'"
            [usuarioId]="usuarioId"
            [uploadAvailable]="true"
            [showCameraIcon]="true">
        </app-avatar>

        <mat-form-field appearance="fill" class="inputTwoLine">
            <mat-label *ngIf="userForm.value.nombre"> {{ 'forms.user.name' | translate}}</mat-label>
            <!-- <mat-label *ngIf="userForm.value.nombre"> {{userForm.value.nombre}}</mat-label> -->
            <input id="nameInput" formControlName="nombre" type="text" matInput placeholder={{nombre}} value={{nombre}}>
        </mat-form-field>

        <mat-form-field appearance="fill" class="inputTwoLine">
            <mat-label *ngIf="userForm.value.apellido"> {{ 'forms.user.lastName' | translate}}</mat-label>
            <!-- <mat-label *ngIf="userForm.value.apellido"> {{userForm.value.apellido}}</mat-label> -->
            <input id="lastNameInput" formControlName="apellido" type="text" matInput placeholder={{apellido}} value={{apellido}}>
        </mat-form-field> 

        <mat-form-field appearance="fill" class="inputTwoLine">
            <mat-label> {{ 'forms.user.secondLastname' | translate}}</mat-label>
            <!-- <mat-label *ngIf="userForm.value.segundoApellido"> {{userForm.value.segundoApellido}}</mat-label> -->
            <input id="secondLastnameInput" formControlName="segundoApellido" type="text" matInput placeholder={{segundoApellido}} value={{segundoApellido}}>
        </mat-form-field> 
        
        <mat-form-field appearance="fill" class="inputTwoLine">
            <mat-label>{{ 'forms.user.birthDate' | translate}}</mat-label>
            <input disabled id="birthDateInput" matInput [max]="maxDate" formControlName="fechaNacimiento" [matDatepicker]="picker" required>
            <mat-datepicker-toggle class="primaryIcon" matSuffix [for]="picker" ></mat-datepicker-toggle>
            <mat-datepicker disabled="false" #picker>
                <mat-datepicker-actions>
                    <button mat-button matDatepickerCancel>{{ 'calendar.cancel' | translate}}</button>
                    <button mat-raised-button color="accent" matDatepickerApply>{{ 'calendar.save' | translate}}</button>
                  </mat-datepicker-actions>
            </mat-datepicker>
        </mat-form-field>
        
        <mat-form-field appearance="fill" class="inputTwoLine">
            <mat-label>{{ 'forms.user.documentType' | translate}}</mat-label>
            <mat-select required id="documentTypeInput" placeholder="userForm.value.documentoTipo.documento" required formControlName="documentoTipo">
                <mat-option  *ngFor="let documento of documentos" [value]="documento">{{documento.documento}}
                </mat-option>
              </mat-select>
        </mat-form-field>
    
        <mat-form-field class="inputTwoLine" appearance="fill">
            <mat-label>{{ userForm.value.documentoTipo && userForm.value.documentoTipo.documento? userForm.value.documentoTipo.documento : 'forms.user.DNI' | translate }}</mat-label>
            <input required id="dniInput" formControlName="DNI" type="text" matInput>
        </mat-form-field>
        
        <mat-form-field appearance="fill" class="inputTwoLine">
            <mat-label>{{ 'forms.user.country' | translate}}</mat-label>
            <mat-select id="countryInput" required formControlName="pais">
                <mat-option *ngFor="let country of countrys" [value]="country">{{country.pais}}
                </mat-option>
              </mat-select>
        </mat-form-field>
        
        <mat-form-field appearance="fill" class="inputTwoLine">
            <mat-label>{{ 'forms.user.residence' | translate}}</mat-label>
            <input id="residenceInput" formControlName="residencia" type="text" matInput required>
        </mat-form-field>
        
    </mat-card>
    
    <mat-card class="cardCenter2">
        <p class="subtitle3">{{ 'forms.user.contactTitle' | translate}}</p>
        <mat-form-field appearance="fill" class="inputPrefix">
            <mat-label>{{ 'forms.user.prefix' | translate}}</mat-label>
            <input min="1" id="prefix1Input" formControlName="prefijoCelular" type="number" matInput required>
        </mat-form-field>
        
        <mat-form-field appearance="fill" class="inputPhone">
            <mat-label>{{ 'forms.user.phone' | translate}}</mat-label>
            <input min="1" id="phoneInput" formControlName="numeroCelular" type="number" matInput required>
        </mat-form-field>
        
        <mat-form-field appearance="fill" class="inputPrefix">
            <mat-label>{{ 'forms.user.prefix' | translate}}</mat-label>
            <input min="1" id="prefix2Input" formControlName="prefijoEmergencia" type="number" matInput required>
        </mat-form-field>
        
        <mat-form-field appearance="fill" class="inputPhone">
            <mat-label>{{ 'forms.user.emergencyPhone' | translate}}</mat-label>
            <input min="1" id="emergencyPhoneInput" formControlName="numeroEmergencia" type="number" matInput required>
        </mat-form-field>
    </mat-card>
    
    <mat-card class="cardCenter2">
        <p class="subtitle3">{{ 'forms.physiological.title' | translate}}</p>
        
        <mat-form-field appearance="fill" class="inputTwoLineFixed">
            <mat-label>{{ 'forms.physiological.weight' | translate}}</mat-label>
            <input min="1" max="999" id="weightInput" formControlName="peso" type="number" matInput required>
            <mat-hint matSuffix>kg</mat-hint>
        </mat-form-field>
        
        <mat-form-field appearance="fill" class="inputTwoLineFixed">
            <mat-label>{{ 'forms.physiological.height' | translate}}</mat-label>
            <input min="1" max="999" id="heightInput" formControlName="altura" type="number" matInput required>
            <mat-hint matSuffix>cm</mat-hint>
        </mat-form-field>
    </mat-card>
</form>

<div *ngIf="!isMobile" class="cardCenter3">
    <div class="buttonInsideCard">
        <app-buttons 
            [enablePrevious]="false" 
            [user]="userForm.value" 
            [disabled]="!userForm.valid || !userHasName || !userHasLastName">
        </app-buttons>
    </div>
</div>

<app-buttons 
    *ngIf="isMobile" 
    [enablePrevious]="false" 
    [user]="userForm.value" 
    [disabled]="!userForm.valid || !userHasName || !userHasLastName">
</app-buttons>