<div class="background">

    <app-header></app-header>
    <app-navbar *ngIf="!isMobile"></app-navbar>
    <section>
        <div class="plans">
            <div class="flexStudents">
                <p class="headline4" *ngIf="!isMobile">{{ 'planes.list.title' | translate }}</p>
                
                <button *ngIf="!isMobile"
                    mat-raised-button color="accent" 
                    class="primaryButton2" 
                    (click)="createPlan()">
                    {{ 'planes.new.title' | translate | uppercase }}
                </button>
            
                <p class="headline5" *ngIf="isMobile">{{ 'planes.list.title' | translate }}</p>

                <button  *ngIf="isMobile"
                    mat-raised-button
                    class="mobileSmallButton" 
                    color="accent" 
                    (click)="createPlan()">
                    <mat-icon class="whiteIcon">
                        add
                    </mat-icon>
                </button>
            </div>
        </div>

        <!-- en curso -->

        <div class="formLine">
            <mat-icon class="statusAcceptedIcon">check_circle_outline</mat-icon>
            <p class="headline6">{{ 'planes.list.curso' | translate }}</p>
        </div>
        <br />
        <p *ngIf="loaded && planDataCurrent.length == 0" class="body1">{{ 'planes.list.emptyCurso' | translate }}</p>
        <app-table *ngIf="loaded && planDataCurrent.length > 0" [current]="true" [schedule]="false"
            [planData]="planDataCurrent" (deleteEvent)="updateAfterDelete()"></app-table>
        <br />

        <!-- programados -->

        <div class="marginTop"></div>
        <div class="formLine">
            <mat-icon class="statusPendingIcon">update</mat-icon>
            <p class="headline6">{{ 'planes.list.programados' | translate }}</p>
        </div>
        <p *ngIf="loaded && semanas.length == 0" class="body3Ligth">{{ 'planes.list.emptyProgramados' | translate }}</p>
        <p class="body3Ligth">{{'planes.list.explain' | translate}}</p>
        <div *ngIf="loaded">
            <div *ngFor="let semana of semanas">
                <br />
                <span class="subtitle3Blue">SEMANA {{semana}}</span>
                <app-table [current]="false" [schedule]="true" [planData]="planData" [semana]="semana"
                    (deleteEvent)="updateAfterDelete()"></app-table>
            </div>
        </div>
</section>
</div>