import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MaterialModule } from '../../../material/material.module';
import { GlobalModule } from '../../global/modules/global.module';
import { CalendarComponent } from '../calendar/calendar.component';
import { DayComponent } from '../day/day.component';
import { EditComponent } from '../edit/edit/edit.component';
import { ListComponent } from '../list/list.component';
import { DayFormComponent } from '../new/day-form/day-form.component';
import { NewComponent } from '../new/new/new.component';
import { TableComponent } from '../table/table.component';
import { PlansRoutingModule } from './plans-routing.module';
import { FeedbackComponent } from '../feedback/feedback.component';
import { WeekComponent } from '../week/week.component';
import { StudentPlansComponent } from '../student-plans/student-plans.component';
import { AdminFeedbackComponent } from '../admin-feedback/admin-feedback.component';
import { ObjetivesDialogComponent } from '../objetives-dialog/objetives-dialog.component'
import { DialogComponent } from '../process/dialog/dialog.component';
import { LoadingComponent } from '../process/loading/loading.component';
import { FeedbackOptionComponent } from '../feedback-option/feedback-option.component';
import { ChangeDaysDialogComponent } from '../change-days-dialog/change-days-dialog.component';

@NgModule({
  declarations: [
    CalendarComponent,
    DayComponent,
    EditComponent,
    TableComponent,
    ListComponent,
    NewComponent,
    DayFormComponent,
    WeekComponent,
    FeedbackComponent,
    StudentPlansComponent,
    AdminFeedbackComponent,
    ObjetivesDialogComponent,
    DialogComponent,
    LoadingComponent,
    FeedbackOptionComponent,
    ChangeDaysDialogComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    GlobalModule,
    PlansRoutingModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
  })
  ], exports: [
    CalendarComponent,
    WeekComponent,
    FeedbackComponent,
    AdminFeedbackComponent,
    DialogComponent,
    LoadingComponent,

  ]
})
export class PlansModule { }
export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
