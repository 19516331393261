import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class DownloadService {

  constructor(private http: HttpClient) {
  }

  private url = environment.apiUrl + '/';

  download(alumnoId: number, semana: string): Observable<Blob> {
    return this.http.get(this.url + `api/planes/exportar?alumnoId=${alumnoId}&semana=${semana}`, {
      withCredentials: true,
      responseType: 'blob'
    });
  }

  downloadHolidays(): Observable<Blob> {
    return this.http.get(this.url + `api/mensajes/descargarRespuestas`, {
      withCredentials: true,
      responseType: 'blob'
    });
  }

  exportarId(domiciliacionId: number, alumnoId: number, arancel: number, emisorId: number): Observable<Blob> {
    return this.http.get(this.url + `api/domiciliacion/exportar/alumno?domiciliacionDetalleId=${domiciliacionId}&alumnoId=${alumnoId}&arancel=${arancel}&emisorId=${emisorId}`, {
      withCredentials: true,
      responseType: 'blob',
    });
  }

  exportarFiltrable(domiciliacionId: number, ape: string, status: string, tipoId: string, emisorId: number): Observable<Blob> {
    return this.http.get(this.url + `api/domiciliacion/exportar?domiciliacionId=${domiciliacionId}&apenom=${ape}&estado=${status}&tipoId=${tipoId}&emisorId=${emisorId}`, {
      withCredentials: true,
      responseType: 'blob'
    });
  }

  downloadFileNutricionComplementos(itemId: number): Observable<Blob> {
    return this.http.get(this.url + `api/informacion/item/archivo?itemId=${itemId}`, {
      withCredentials: true,
      responseType: 'blob'
    });
  }

  preview(itemId: number): Promise<string> {
    return new Promise((resolve, reject) => {
      this.downloadFileNutricionComplementos(itemId).subscribe(response => {
          resolve(URL.createObjectURL(response));
      },
        error => {
          console.error(error);
          reject();
        });
    });
  }
}