<div *ngFor="let pregunta of preguntas; let i = index">

    <app-radio-input
        *ngIf="pregunta.tipo == tiposPreguntaEnum.RADIO || pregunta.tipo ==  tiposPreguntaEnum.RADIO_HORIZONTAL"
        [requerido]="pregunta.requerido" 
        [opciones]="pregunta.detalleList"
        [pregunta]="pregunta"
        (opcionSeleccionadaEvent)="opcionSeleccionada($event)">
    </app-radio-input>

    <app-radio-text-area-input
        *ngIf="pregunta.tipo == tiposPreguntaEnum.RADIO_TEXT_AREA"
        [requerido]="pregunta.requerido" 
        [opciones]="pregunta.detalleList"
        [pregunta]="pregunta"
        (textoIngresadoEvent)="textoIngresado($event)">
    </app-radio-text-area-input>

    <app-checkbox-input 
        *ngIf="pregunta.tipo == tiposPreguntaEnum.CHECKBOX"
        [requerido]="pregunta.requerido" 
        [opciones]="pregunta.detalleList"
        [pregunta]="pregunta"
        (opcionesSeleccionadasEvent)="opcionesSeleccionadasEvent($event)">
    </app-checkbox-input>

    <app-select-input
        *ngIf="pregunta.tipo == tiposPreguntaEnum.SELECT"
        [requerido]="pregunta.requerido" 
        [opciones]="pregunta.detalleList"
        [pregunta]="pregunta"
        (opcionSeleccionadaEvent)="opcionSeleccionada($event)">
    </app-select-input>

    <app-text-input
        *ngIf="pregunta.tipo == tiposPreguntaEnum.TEXT"
        [requerido]="pregunta.requerido" 
        [pregunta]="pregunta"
        [cantCaracteres]="pregunta.cantCaracteres"
        (textoIngresadoEvent)="textoIngresado($event)">
    </app-text-input>

    <app-text-area-input
        *ngIf="pregunta.tipo == tiposPreguntaEnum.TEXT_AREA"
        [requerido]="pregunta.requerido" 
        [pregunta]="pregunta"
        [cantCaracteres]="pregunta.cantCaracteres"
        (textoIngresadoEvent)="textoIngresado($event)">
    </app-text-area-input>

    <app-radio-checkbox-text-area-input
        *ngIf="pregunta.tipo == tiposPreguntaEnum.RADIO_CHECKBOX_TEXT_AREA"
        [requerido]="pregunta.requerido" 
        [opciones]="pregunta.detalleList"
        [pregunta]="pregunta"
        (textoIngresadoEvent)="textoIngresado($event)"
        (opcionesSeleccionadasEvent)="opcionesSeleccionadasEvent($event)">
    </app-radio-checkbox-text-area-input>

    <div *ngIf="i < (length - 1) && pregunta.tipo">
        <br/>
        <mat-divider ></mat-divider>
        <br/>
    </div>

</div>

<div *ngIf="showData">
    <br/><br/><br/>
    {{puedeContinuar | json}}
    <br/><br/>
    <ul *ngFor="let respuesta of respuestas">
        <li>
            {{respuesta | json}}
        </li>
    </ul>
</div>