<div *ngIf="!edit">
  <div class="levelBox" *ngIf=" level && (level != 'NO DEFINIDO')">
    <p class="subtitle2" *ngIf="userForm">
      {{userForm.value.level.entrenamientoNivelDescripcion ? userForm.value.level.entrenamientoNivelDescripcion : userForm.value.level}}  
    </p>
    <p class="subtitle2" *ngIf="!userForm">
      {{level}}  
    </p>
  </div>
  <span class="subtitle2Grey" *ngIf="(level == 'NO DEFINIDO') ">{{'admin.studentsTable.header.undefined' |
    translate}}</span>
</div>

<div *ngIf="edit">
  <button mat-button class="menuButton"
    [disabled]="!isAdmin || status == 'C' || status == 'I' || status == 'S'" [matMenuTriggerFor]="menu" class="menuButton">
    <span class="subtitle2 levelBox">
      {{userForm.value.level.entrenamientoNivelDescripcion ? userForm.value.level.entrenamientoNivelDescripcion : userForm.value.level}}
    </span>
    
    <mat-icon class="iconMenu" *ngIf="isAdmin && (status == 'P' || status == 'A') ">
      edit
  </mat-icon>

  <mat-icon class="iconMenu greyIcon" *ngIf="(isAdmin && status == 'C') || (isAdmin && status == 'I')">
      edit 
  </mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <div *ngFor="let level of levels">
      <button (click)="saveLevel(level)" mat-menu-item>
        <span class="body4">{{level.entrenamientoNivelDescripcion}}</span>
      </button>
    </div>
  </mat-menu>
</div>