<app-filters 
  (filtersChangeEvent)="filtersChange($event)"
  (filtersFromSessionStorageEvent)="setAndLoad($event)">
</app-filters>


<p class="amountUsers">{{amount}} REGISTROS</p>
<!-- <app-loading *ngIf="!loaded" [showText]="false"></app-loading> -->

<div class="noStudents" *ngIf="loaded && noStudents">
  <div *ngIf="!firstLoad">
    <span class="headline6Light"> {{'admin.studentsTable.noStudents.filtersEmpty' | translate}}</span>
  </div>
  <div *ngIf="firstLoad">
    <span class="headline5"> {{'admin.studentsTable.noStudents.dontExist' | translate}}</span>
    <br/>
    <span class="body2"> {{'admin.studentsTable.noStudents.dontExist2' | translate}}</span>
  </div>
</div>
<div class="table-container" *ngIf="loaded && !noStudents">
  <table *ngIf="!isMobile" mat-table [dataSource]="studentData" multiTemplateDataRows>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef> {{'admin.studentsTable.header.name' | translate}} </th>
      <td mat-cell *matCellDef="let student">
        <div class="flexLeft" (click)="navigate(student)">

          <app-avatar
            *ngIf="student.usuarioId"
            [class]="'imgRoundedTable'"
            [containerClass]=""
            [usuarioId]="student.usuarioId">
          </app-avatar>

          <mat-icon 
            *ngIf="student.notificacionCertificadoMedico" 
            class="circle"
            matTooltip="Alerta Apto Médico"
            svgIcon="cruz_medica">
          </mat-icon>

          <div class="paddingLeftOnly">
            <span class="subtitle2">
              {{ student.fullName | reduceText:42 }}
            </span>
            <div class="body4" id="emailClass">
              {{student.email}}
            </div>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef> {{'admin.studentsTable.header.status' | translate}} </th>
      <td mat-cell *matCellDef="let student">
        <app-status [showComplete]="true" [status]="student.status"></app-status>
      </td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef> {{'admin.studentsTable.header.type' | translate}} </th>
      <td mat-cell *matCellDef="let student">
       <app-training-type [training]="student.training" [edit]="false"></app-training-type>
      </td>
    </ng-container>

    <ng-container matColumnDef="level">
      <th mat-header-cell *matHeaderCellDef> {{'admin.studentsTable.header.level' | translate}} </th>
      <td mat-cell *matCellDef="let student">
        <app-level 
          [level]="student.level"
          [edit]="false"
          [isAdmin]="isAdmin"
          [status]="status">
        </app-level>
      </td>
    </ng-container>

    <ng-container matColumnDef="trainer">
      <th mat-header-cell *matHeaderCellDef> {{'admin.studentsTable.header.trainer' | translate}} </th>
      <td mat-cell *matCellDef="let student">
        <div *ngIf="student.entrenador != 'NO DEFINIDO'" class="menuText">
          {{student.entrenador}}
        </div>
        <div class="subtitle2Grey" *ngIf="(student.entrenador == 'NO DEFINIDO') || !student.entrenador">
          {{'admin.studentsTable.header.undefined' | translate}}
        </div>

      </td>
    </ng-container>



    <ng-container matColumnDef="actions" stickyEnd>
      <th mat-header-cell *matHeaderCellDef> {{'admin.studentsTable.header.actions' | translate}}</th>
      <td mat-cell *matCellDef="let student">
        <div class="flexActions">
          <button mat-icon-button (click)="openDialogChat(student)">
            <mat-icon class="azul">chat</mat-icon>
          </button>
          <button mat-icon-button (click)="navigate(student)">
            <mat-icon class="azul">visibility</mat-icon>
          </button>
          <button (click)="toggleExpanded(student)" mat-icon-button>
            <mat-icon class="azul" *ngIf="!student.isExpanded">expand_more</mat-icon>
            <mat-icon class="azul" *ngIf="student.isExpanded">expand_less</mat-icon>
          </button>
          <mat-checkbox (click)="changeFromCheckbox(student)" color="primary" [checked]="student.leido"></mat-checkbox>
        </div>
      </td>
    </ng-container>

    <!-- expandible row -->
  <ng-container matColumnDef="expandedDetail">
    <td mat-cell *matCellDef="let student" [attr.colspan]="displayedColumns.length">
      <div class="row student-element-detail" [@detailExpand]="student.isExpanded ? 'expanded' : 'collapsed'">
        <p *ngIf="student.status != 'A'" class="italic"> {{'admin.studentsTable.pending' | translate}}</p>
        <app-week *ngIf="student.isExpanded && student.status == 'A' " [alumnoId]="student.user_id" [isAdmin]="true"></app-week>
      </div>

    </td>
  </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let student; columns: displayedColumns;" class="student-element-row"
      [class.student-expanded-row]="student.isExpanded"></tr>

    <!-- Extra row to show detail content column -->
    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="student-detail-row"></tr>
    
  </table>

  <div *ngIf="isMobile" class="tableContainer">
    <table  mat-table [dataSource]="studentData"  multiTemplateDataRows>
      
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td (click)="navigate(student)" mat-cell *matCellDef="let student">
          <div class="flexLeft">

            <app-avatar
                *ngIf="student.usuarioId"
                [class]="'imgRoundedTable'"
                [containerClass]=""
                [usuarioId]="student.usuarioId">
            </app-avatar>

            <mat-icon 
              *ngIf="student.notificacionCertificadoMedico" 
              class="circle"
              matTooltip="Alerta Apto Médico"
              svgIcon="cruz_medica">
            </mat-icon>

            <div class="paddingLeftOnly">
              <span class="subtitle2">
                {{ student.fullName | reduceText:23 }}
              </span>
              <br/>
              <span class="body2">
                {{student.email | reduceText:20 }}
              </span>
            </div>
          </div>
        </td>
      </ng-container>
      
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let student">
          <div class="flexRigth">
            <app-status [showComplete]="false"  [status]="student.status"></app-status>
          </div>
        </td>
      </ng-container>
      
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let student">
          <div class="flexActions">
            <button (click)="toggleExpanded(student)" mat-icon-button>
              <mat-icon class="azul" *ngIf="!student.isExpanded">expand_more</mat-icon>
              <mat-icon class="azul" *ngIf="student.isExpanded">expand_less</mat-icon>
            </button>
            <mat-checkbox (click)="changeFromCheckbox(student)" color="primary" [checked]="student.leido"></mat-checkbox>
          </div>
          
        </td>
      </ng-container>

      <!-- expandible row -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let student" [attr.colspan]="displayedColumns.length">
          <div class="row student-element-detail" [@detailExpand]="student.isExpanded ? 'expanded' : 'collapsed'">
            <p *ngIf="student.status != 'A'" class="italic"> {{'admin.studentsTable.pending' | translate}}</p>
            <app-week *ngIf="student.isExpanded && student.status == 'A' " [alumnoId]="student.user_id" [isAdmin]="true"></app-week>
          </div>

        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsMobile"></tr>
      <tr mat-row *matRowDef="let student; columns: displayedColumnsMobile;" class="student-element-row"
        [class.student-expanded-row]="student.isExpanded"></tr>

      <!-- Extra row to show detail content column -->
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="student-detail-row"></tr>


      
      <!-- <tr mat-header-row *matHeaderRowDef="displayedColumnsMobile"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsMobile;"></tr> -->
    </table>
  </div >

</div>

<app-paginator-complete
    *ngIf="loaded"
    [first]="first"
    [last]="last"
    [page]="page"
    [totalPages]="totalPages"
    [size]="size"
    [totalElements]="totalElements"
    (loadMoreEvent)="loadMore($event)">
</app-paginator-complete>

<!-- <app-paginator [rol]="RoleEnum.STUDENT" [paginateRight]="paginateRight" [amount]="amount" [totalPerPage]="totalPerPage" (changePageEvent)="changePage($event)"></app-paginator> -->