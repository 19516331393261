import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Canal, Message, MessageListOption, Participante } from '../../../../api/interfaces/messages';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from "@angular/material/icon";
import { RolService } from 'src/app/services/rol/rol.service';
import { ParticipanteService } from 'src/app/services/participante/participante.service';
import { StudentService } from 'src/app/services/admin/student/student.service';
import { MobileDetectorService } from 'src/app/services/mobileDetector/mobile-detector.service';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';
import { RoleString } from 'src/app/enums/role';

@Component({
  selector: 'app-message-header',
  templateUrl: './message-header.component.html',
  styleUrls: ['./message-header.component.scss']
})
export class MessageHeaderComponent implements OnInit {

  @Input('canal') canal: Canal;
  
  isAdmin: boolean = false;  
  isStudent: boolean = false;
  isMobile: boolean = false;
  showNavigate: boolean = false;
  
  otroParticipante: Participante = null;
  avatarUrl: string = "";
  equipoNombre: string = "";
  
  constructor(
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private rol: RolService,
    private participanteService: ParticipanteService,
    private studentService: StudentService,
    private mobileDetectorService: MobileDetectorService,
    private sharedData: SharedDataService
  ) { 
    this.matIconRegistry.addSvgIcon(
      `massive`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../../assets/massive.svg')
    );
  }

  ngOnInit(): void {
    this.equipoNombre = localStorage.getItem('equipoNombre');
    this.isAdmin = this.rol.isAdmin();
    this.isStudent = this.rol.isStudent();
    this.isMobile = this.mobileDetectorService.isMobile();
    this.otroParticipante = this.participanteService.getParticipante(this.canal);
    if(this.otroParticipante.rol && this.otroParticipante.rol.toLocaleUpperCase() == RoleString.STUDENT && !this.isStudent) {
      this.showNavigate = true;
    }
  }

  navigate() {
    this.studentService.getIdAlumno(this.otroParticipante.idUsuario).subscribe( response=> {
      sessionStorage.setItem('id_usuario', JSON.stringify(response));
      this.router.navigate(['/admin/perfil']);
    },
    error => {
      console.error(error);
    });
  }

  back() {
    this.sharedData.changeMessage("back");
  }

}
