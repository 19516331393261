<mat-card class="cardProfile">
    <div class="flexLeftBaseline">

        <p class="headline6"> {{'profile.statusHistoryTab.inactivity.title' | translate}}</p>
        <button [disabled]="estado == 'C'" *ngIf="disable" mat-button color="primary" class="editButton" (click)="edit()" (click)="addSuspension()">
            {{ 'registration.stepThree.objetives.add' | translate}}
        </button>

        <button *ngIf="!disable" mat-button color="primary" class="editButton" [disabled]="noValid" (click)="edit()">
            <mat-icon>save</mat-icon> {{'profile.data.save' |translate }}
        </button>
    </div>

    <div class="subtitle3" *ngIf="!suspensionesEmpty">{{'profile.statusHistoryTab.inactivity.notEnded' | translate}}</div>
    <div *ngFor="let suspension of suspensiones; let i = index">
        <app-inactividad-inputs *ngIf="!suspension.delete"
            (emptyEvent)="emptySuspensiones($event)"
            (addSuspensionesEvent)="addSuspensionesEvent($event)"
            (removeSuspensionesEvent)="removeSuspensionesEvent($event)"
            [disable]="disable"
            [suspensiones]="suspension"
            [motivos]="motivos"
            [i]="i">
        </app-inactividad-inputs>
    </div>

    <div *ngIf="showEnded">
        <div class="subtitle3">{{'profile.statusHistoryTab.inactivity.ended' | translate}}</div>
        <div *ngFor="let suspension of suspensionesPasadas; let i = index">
            <app-inactividad-inputs *ngIf="!suspension.delete"
                [disable]="true"
                [suspensiones]="suspension"
                [motivos]="motivos"
                [i]="i">
            </app-inactividad-inputs>
        </div>
    </div>

    <button mat-button color="primary"  id="toggleButton" [disabled]="suspensionesEmpty" (click)="toggleEnded()">
        <mat-icon *ngIf="!showEnded" [class]="iconClass">expand_more</mat-icon>
        <mat-icon *ngIf="showEnded" >expand_less</mat-icon>
        {{'profile.statusHistoryTab.inactivity.toggleButton' | translate}}
    </button>
    

   
    <p class="body2" *ngIf="suspensionesEmpty">
        {{'profile.statusHistoryTab.inactivity.empty' | translate}}
    </p>
</mat-card>