<button (click)="cobrar()"
    *ngIf="estado == 'N'"
    mat-raised-button color="accent" 
    class="primaryButton2">
    <mat-icon *ngIf="mostrarIconos" class="whiteIcon">
        attach_money
      </mat-icon>
    {{'admin.domiciliacion.monthTable.pay' | translate}}
</button>

<button (click)="cobrar()"
    *ngIf="estado == 'E'"
    mat-stroked-button color="primary" 
    class="primaryButton2" >
    <mat-icon *ngIf="mostrarIconos">
        money_off
      </mat-icon>
    {{'admin.domiciliacion.monthTable.revert' | translate}}
</button>