import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { LogoutService } from '../app/services/logout/logout.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private logOut: LogoutService,
) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user= localStorage.getItem('rol');

    if (user) {
      const foundRol: boolean = route.data.roles.some(rol => rol == user);
      if(foundRol) {
        return true;
      } else {
        this.logOut.notAllowed();
        return false;
      }
    }
    this.logOut.notAllowed();
    return false;
  } 
}