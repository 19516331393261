<mat-card class="cardSection">
    <p class="headline6"> {{'settings.title' | translate}} </p>

    <!-- grupos -->
    <p *ngIf="showGroups"
        class="body3Medium" 
        (click)="toggleOption(1)"> 
            {{'settings.options.one' | translate}}  
        <mat-icon class="iconSelected">chevron_right</mat-icon>
    </p>

    <p *ngIf="!showGroups"
        class="body3 pointer" 
        (click)="toggleOption(1)"> 
            {{'settings.options.one' | translate}}  
        <mat-icon class="iconNoSelected">chevron_right</mat-icon>
    </p>

    <!-- arancel -->
    <p *ngIf="showArancel"
        class="body3Medium" 
        (click)="toggleOption(2)"> 
            {{'settings.options.two' | translate}}  
        <mat-icon class="iconSelected">chevron_right</mat-icon>
    </p>
    <p *ngIf="!showArancel"
        class="body3 pointer" 
        (click)="toggleOption(2)"> 
            {{'settings.options.two' | translate}}  
        <mat-icon class="iconNoSelected">chevron_right</mat-icon>
    </p>
</mat-card>
