import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IsSameObjectService {

  constructor() { }

  public isSame (aux1, aux2) {
    const keys1 = Object.keys(aux1);
    const keys2 = Object.keys(aux2);
    let aux: boolean = true;
    if (keys1.length !== keys2.length) {
      aux = false;
    }
    for (let key of keys1) {
      if(aux1[key] != aux2[key]) {
        aux = false;
       
      }
    }
    return aux;
  }
}
