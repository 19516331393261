<form [formGroup]="optionsForm">
    <p class="subtitle1 textLeft">{{pregunta.titulo}} <span *ngIf="requerido"> *</span></p>

    <mat-radio-group
        aria-labelledby="radioGroupColumn" 
        [class]="radioGroupClass">

            <div  *ngFor="let opcion of opcionesCompuestas">
                <mat-radio-button 
                    *ngIf="opcion.tipo == tiposPreguntasEnum.RADIO && opcion.accion == accionesOpcion.SHOW_CHECK "
                    color="primary" 
                    value="null" 
                    (click)="toggleCheckbox(true)">
                    {{opcion.texto}}
                </mat-radio-button>
            </div>

            <div *ngIf="showCheckbox" class="checkboxGroupDynamic">
                <div 
                    *ngFor="let opcion of opcionesCompuestas; let i = index"
                    aria-labelledby="radioGroupColumn" 
                    [required]="requerido">
                    <mat-checkbox 
                        *ngIf="opcion.tipo == 'checkbox'"
                        color="primary" 
                        class="body2" 
                        (click)="clickCheckbox(i, opcion)">
                        {{opcion.texto}}
                    </mat-checkbox>
                    
                </div>
            </div>
            
            <mat-form-field *ngIf="showTextAreaOthers" appearance="fill" class="inputfullWidth">
                <mat-label> {{placeholderOthers}}</mat-label>
                <textarea
                    maxLength={{cantCaracteres}}
                    aria-labelledby="textAreaOthers" 
                    id="textAreaOthers" 
                    formControlName="others"
                    type="text"
                    matInput
                    required>
                </textarea>
            
                <mat-hint align="end">{{optionsForm.value.others?.length || 0}} / {{cantCaracteres}}</mat-hint>
                <mat-hint *ngIf="pregunta.textoAsistivo">{{pregunta.textoAsistivo}}</mat-hint>
            </mat-form-field>

            <mat-form-field *ngIf="showTextArea" appearance="fill" class="inputfullWidth">
                <mat-label>{{placeholder}}</mat-label>
                <textarea 
                    maxLength={{cantCaracteres}}
                    aria-labelledby="textAreaInput" 
                    id="textAreaInput" 
                    formControlName="valor"
                    type="text"
                    matInput >
                </textarea>
            
                <mat-hint align="end">{{optionsForm.value.valor?.length || 0}} / {{cantCaracteres}}</mat-hint>
                <mat-hint *ngIf="pregunta.textoAsistivo">{{pregunta.textoAsistivo}}</mat-hint>
            </mat-form-field>

            
            <div *ngFor="let opcion of opcionesCompuestas">

                <mat-radio-button 
                    *ngIf="opcion.tipo == tiposPreguntasEnum.RADIO && opcion.accion == accionesOpcion.HIDE_CHECK "
                    color="primary" 
                    [value]="opcion.texto" 
                    (click)="toggleCheckbox(false)">
                    {{opcion.texto}}
                </mat-radio-button>
            </div>
                
        </mat-radio-group>

</form>