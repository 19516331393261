import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RespuestaFormularioDinamicoPregunta } from 'src/app/api/interfaces/formulario-dinamico';
import { Distancias, PasosHabilitados, Antecedente, FilteredMode, FiltratedUser, EntrenamientoDia, Objetive, FiltratedObjetives, DatosBancario, AntecedentesMarcas, SelectedMode } from 'src/app/api/interfaces/register';
import { RegisterService } from 'src/app/api/services/register/register.service';
import { AntecedentesEnum } from 'src/app/enums/antecedentes';
import { StepperEnum } from 'src/app/enums/stepper';
import { trainingDaysEnum } from 'src/app/enums/training-type';
import { FilterDataService } from 'src/app/services/filterData/filter-data.service';
import { MobileDetectorService } from 'src/app/services/mobileDetector/mobile-detector.service';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';
import { HoursDayFixedService } from 'src/app/utils/hoursDayFixed/hours-day-fixed.service';
import { ParseTimeService } from 'src/app/utils/parseTime/parse-time.service';

@Component({
  selector: 'app-step-end',
  templateUrl: './step-end.component.html',
  styleUrls: ['./step-end.component.scss']
})
export class StepEndComponent implements OnInit {


  @Input('cantidadPasosHabilitados') cantidadPasosHabilitados: number;
  @Input('posicion') posicion: number;

  @Input('posicionStepOne') posicionStepOne: number;
  @Input('posicionGrupoEntrenamiento') posicionGrupoEntrenamiento: number;
  @Input('posicionAntecedentes') posicionAntecedentes: number;
  @Input('posicionMarcasObjetivos') posicionMarcasObjetivos: number;
  @Input('posicionBancario') posicionBancario: number;

  @Output() changeCurrentStepEvent = new EventEmitter<number>();

  bank: DatosBancario;
  
  pasosHabilitados: PasosHabilitados;
  
  isMobile: boolean = false;
  online: boolean = false;
  
  longDiasAdicionales: number = 0;
  ALUMNO_ID: number;
  
  routeOptions: Distancias[] = [];
  metersOption: Distancias;
  kmsOption: Distancias;

  mode: SelectedMode;
  data: any;

  diasAdicionalesText: string = null;
  grupoEntrenamiento: string = "";
  diasAdicionales: string[] = [];

  objetives: FiltratedObjetives[] = [];
  marcas: AntecedentesMarcas[] = [];

  constructor(
    private registerService: RegisterService,
    private sharedData: SharedDataService,
    private mobileDetectorService: MobileDetectorService,
    private parseTime: ParseTimeService,
    private filterData: FilterDataService,
    private hoursDayFixedService: HoursDayFixedService
  ) { }


  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    this.ALUMNO_ID = parseInt(localStorage.getItem('idAlumno'),10);
    this.getOptions();

    this.pasosHabilitados = {
      informacionPersonal: false,
      grupoEntrenamiento: false,
      antecedentes: false,
      bancario: false,
      marcasObjetivos: false
    }


    this.sharedData.currentMessage.subscribe(message => {

      if(message === StepperEnum.SAVE_STEP1 ) {
        this.pasosHabilitados.informacionPersonal = true;
        this.createDataObject();
      }
     
      if(message === StepperEnum.SAVE_STEP2) {
        this.pasosHabilitados.grupoEntrenamiento = true;
        this.getDiasYGrupo();
        this.createDataObject();
      } 

      if(message === StepperEnum.SAVE_STEP3) {
        this.pasosHabilitados.antecedentes = true;
        this.createDataObject();
      }
     
      if(message === StepperEnum.SAVE_STEP4) {
        this.pasosHabilitados.bancario = true;
        this.createDataObject();
      }
    
      if(message === StepperEnum.SAVE_STEP_OBJ_ACH) {
        this.waitingForObjAch();
      }
    });
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  
  async waitingForObjAch() {
    await this.sleep(200);
    this.pasosHabilitados.marcasObjetivos = true;
    this.createDataObject();
  }

  /**
   * Arma diasAdicionalesText y grupo de entrenamiento para mostrar al usuario
   */
  getDiasYGrupo() {
    const mode = JSON.parse(sessionStorage.getItem('mode'));

    if (mode.trainingDays && mode.trainingDays.grupoDescripcion) {
      this.grupoEntrenamiento = mode.trainingDays.grupoDescripcion;
      this.online = false;
    } else {
      this.grupoEntrenamiento = "Online"
      this.online = true;
    }

    this.diasAdicionales = [];
    this.diasAdicionalesText = "";
    mode.days.forEach(day => {
      this.diasAdicionales.push(day.name);
    });
    this.longDiasAdicionales = this.diasAdicionales.length;

    this.diasAdicionales.forEach((day, i) => {
      this.diasAdicionalesText += day;
      if (i < (this.longDiasAdicionales - 1)) {
        this.diasAdicionalesText += ", ";
      }
    });
  }

  /**
   * Llama a funciones de filtrado de datos del frontend, asocia los resultados en entidad data y guarda el resultado en sessionStorage.
   * Pregunta por pasos habilitados retornando null en vez de llamar al filtro si no está habilitado el paso respectivo.
   */
  createDataObject() {
    let aux = sessionStorage.getItem('mode') as string;

    let background = JSON.parse(sessionStorage.getItem('background'));
    this.bank = JSON.parse(sessionStorage.getItem('bank'));

    // 2670: 
    let antecendentesRespuesta: RespuestaFormularioDinamicoPregunta[] = JSON.parse(sessionStorage.getItem('antecendentesRespuesta'));
    let antecedente: Antecedente[] = this.pasosHabilitados.antecedentes ? this.filterData.filterBackground(antecendentesRespuesta) : null;


    let filteredMode: FilteredMode = this.pasosHabilitados.grupoEntrenamiento ? this.filterData.filterDataMode(JSON.parse(sessionStorage.getItem('mode'))) : null;
    let filteredUser: FiltratedUser = this.filterData.filterUserData(JSON.parse(sessionStorage.getItem('user')), filteredMode, this.ALUMNO_ID);
    let entrenamientoDia: EntrenamientoDia[] = this.pasosHabilitados.grupoEntrenamiento ? this.createEntrenamientoDia(JSON.parse(sessionStorage.getItem('mode'))) : null;
    
    // objetivos
    let objetives: Objetive[] = JSON.parse(sessionStorage.getItem('objetives'));
    let objetivo: FiltratedObjetives[] = [];
    if(objetives && this.pasosHabilitados.marcasObjetivos) {
      objetivo = this.filterData.filterObjetives(objetives);
      this.objetives =  this.filterData.filterObjetives(objetives);
    }

    // marcas: ruta pista pista ultra trail
    let AntecedentesMarcas: AntecedentesMarcas[] = [];
    let antecedentesMarcas: any = [];
    let filteredPista: AntecedentesMarcas[] =  [];
    let filteredTrail: AntecedentesMarcas[] =  [];
    let filteredUltra: AntecedentesMarcas[] =  [];
    if(background) {
      AntecedentesMarcas = this.filterAchievementsDynamic(JSON.parse(sessionStorage.getItem('ruta')),'ruta',background.ruta);
      filteredPista = this.filterAchievementsDynamic(JSON.parse(sessionStorage.getItem('pista')),'pista',background.pista);
      filteredTrail = this.filterAchievementsDynamic(JSON.parse(sessionStorage.getItem('trail')),'trail',background.trail);
      filteredUltra = this.filterAchievementsDynamic(JSON.parse(sessionStorage.getItem('ultra')),'ultra',background.ultra);
      
      antecedentesMarcas = [];
      if(AntecedentesMarcas && AntecedentesMarcas.length > 0 && this.pasosHabilitados.marcasObjetivos) {
        AntecedentesMarcas.forEach(antecedente => {
          antecedentesMarcas.push(antecedente)
        });
      };
      if(filteredTrail && filteredTrail.length > 0 && this.pasosHabilitados.marcasObjetivos) {
        filteredTrail.forEach(antecedente => {
          antecedentesMarcas.push(antecedente)
        });
      };
      if(filteredPista && filteredPista.length > 0 && this.pasosHabilitados.marcasObjetivos) {
        filteredPista.forEach(antecedente => {
          antecedentesMarcas.push(antecedente)
        });
      };
      if(filteredUltra && filteredUltra.length > 0 && this.pasosHabilitados.marcasObjetivos) {
        filteredUltra.forEach(antecedente => {
          antecedentesMarcas.push(antecedente)
        });
      };
    }

    this.marcas = antecedentesMarcas;

    if(aux) {
      this.mode = JSON.parse(aux);
    }

    let alumnosDatoBancario: DatosBancario = {
      entidad: this.pasosHabilitados.bancario ? this.bank.entidad : null,
      claveBancaria: this.pasosHabilitados.bancario ? this.bank.claveBancaria : null
    }

    let alumnosDatosBancario: DatosBancario [] = [];
    alumnosDatosBancario.push(alumnosDatoBancario);

    //datos del paso 1, siempre estan
    let email = filteredUser.email;
    let nombre = filteredUser.nombre;
    let apellido = filteredUser.apellido;
    let segundoApellido = filteredUser.segundoApellido;
    let fechaNacimiento = filteredUser.fechaNacimiento;
    let numeroDocumento = filteredUser.numeroDocumento;
    let paisId = filteredUser.paisId;
    let residencia = filteredUser.residencia;
    let prefijoCelular = filteredUser.prefijoCelular;
    let numeroCelular = filteredUser.numeroCelular;
    let prefijoEmergencia = filteredUser.prefijoEmergencia;
    let numeroEmergencia = filteredUser.numeroEmergencia;
    let peso = filteredUser.peso;
    let altura = filteredUser.altura;
    let activo = filteredUser.activo;
    let ultimaModificacion = filteredUser.ultimaModificacion;
    let ultimaModificacionPor = filteredUser.ultimaModificacionPor;
    let estado = filteredUser.estado;
    let documentoTipoId = filteredUser.documentTipoId;
    let avatarUrl = filteredUser.avatarUrl;


    //datos que dependen de pasos Habilitados, si no estuvo habilitado devolveran null
    const grupo = JSON.parse(sessionStorage.getItem('mode'));
    let grupoId: number = -1;
    if(grupo) {
      if(grupo.trainingDays) {
        grupoId = grupo.trainingDays.entrenamientoGrupoId;
      } else {
        grupoId = grupo.mode;
      }
    } else {
      grupoId = null;
    }
    let entrenamientoTipoId = filteredUser.entrenamientoTipoId;
    let entrenamientoGrupoId = grupoId;
    let entrenamientoNivelId = filteredUser.entrenamientoNivelId;
    let entrenadorId = filteredUser.entrenadorId;

    this.data = {
      objetivo,
      antecedentesMarcas,
      antecedente,
      email,
      nombre,
      apellido,
      segundoApellido,
      fechaNacimiento,
      numeroDocumento,
      paisId,
      residencia,
      prefijoCelular,
      numeroCelular,
      prefijoEmergencia,
      numeroEmergencia,
      peso,
      altura,
      entrenamientoTipoId,
      entrenamientoGrupoId,
      entrenamientoNivelId,
      entrenadorId,
      activo,
      ultimaModificacion,
      ultimaModificacionPor,
      estado,
      documentoTipoId,
      avatarUrl,
      alumnosDatosBancario,
      entrenamientoDia
    };

    sessionStorage.setItem('data',JSON.stringify(this.data));
  }

  /**
   * Filtra datos del front para enviarlos al backend
   * @param mode - datos del modo de entrenamiento
   * @returns días de entrenamiento como solicita el backend
   */
  createEntrenamientoDia(mode: any) : EntrenamientoDia[] {
    let entrenamientoDias: EntrenamientoDia[] = [];
    if(mode.days) {
      mode.days.forEach(day => {
        let aux: EntrenamientoDia = {
          entrenamientoDiaTipo: trainingDaysEnum.ADITIONAL,
          diaSemana: day.index
        };
        entrenamientoDias.push(aux);
      });
    }
    if(mode.trainingDays) {
      mode.trainingDays.entrenamientoGruposDias.forEach(day => {
        let aux: EntrenamientoDia = {
          entrenamientoDiaTipo: trainingDaysEnum.IN_GROUP,
          diaSemana: day.diaSemana
        };
        entrenamientoDias.push(aux);
      });
    }
    return entrenamientoDias;
  }

  getOptions() {
    this.registerService.getRouteOptions()
    .subscribe(response => {
      this.routeOptions = response;
      let km: number = response.findIndex(option => option.distanciaDescripcion == "Kilómetros");
      let meters = this.routeOptions.findIndex(option => option.distanciaDescripcion == "Metros");
      this.routeOptions.forEach(option => {
        option.selected = false;
      });
      this.kmsOption = this.routeOptions[km];
      this.metersOption = this.routeOptions[meters];
    },
      error => {
        console.error(error);
      });
  }

  /**
   * Filtra datos de marcas del front para enviar al backend
   * @param input - datos de la marca
   * @param mode - si es una ruta, pista, ultra, trail
   * @param valid -
   * @returns datos filtrados para el backend
   */
  filterAchievementsDynamic(input: any, mode: string, valid: boolean) {
    let arrayAux = Array();
    if(input && valid) {
      if(mode === "pista" || mode === "ultra" || mode === "trail") {
        input.forEach(element => {
          let id: number;
          let optionId: number;
            switch (mode) {
              case "pista":
                id = AntecedentesEnum.PISTA;
                // optionId = this.metersOption.id;
                break;
              case "ultra":
                id = AntecedentesEnum.ULTRA;
                // optionId = this.kmsOption.id;
                break;
              case "trail":
                id = AntecedentesEnum.TRAIL;
                // optionId = this.kmsOption.id;
                break;
            }
            let time: number [] = this.parseTime.parseTime(element.time);
             // bugfix date
            let fechaCorregida: string = this.hoursDayFixedService.getDateWithConverted(element.date);

            let aux: AntecedentesMarcas = {
              tiempoMarcaHoras: time[0],
              tiempoMarcaMinutos: time[1],
              tiempoMarcaSegundos: time[2],
              distanciaMarca: element.distance,
              fechaMarca: fechaCorregida,
              antecedentePreguntaDetalleId: id,
              // distanciaTipoId: optionId, 
              id: null,
            };
            arrayAux.push(aux);
          });
      } else {
          input.forEach(element => {
            let time: number [] = this.parseTime.parseTime(element.time);
            
              // bugfix date
              let fechaCorregida: string = this.hoursDayFixedService.getDateWithConverted(element.date);
              let aux: AntecedentesMarcas = {
              distanciaTipoId: element.routeType.id,
              tiempoMarcaHoras: time[0],
              tiempoMarcaMinutos: time[1],
              tiempoMarcaSegundos: time[2],
              fechaMarca: fechaCorregida,
              antecedentePreguntaDetalleId: AntecedentesEnum.RUTA,
              distanciaDescripcion: element.routeType.distanciaDescripcion,
              id: null
            }
            arrayAux.push(aux);
          });
      } 
      return arrayAux;

    } else {
      return null;
    }
  }

  changeCurrentStep(step: number) {
    this.changeCurrentStepEvent.emit(step);
  }

}
