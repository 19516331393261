
    <br/>
    <div class="responsiveFlex">
        <mat-card class="cardProfile">
            <app-personal-data 
                [personalData]="personalData"
                [estado]="estado"
                (onSubmitPersonalEvent)='onSubmitPersonal($event)'>
            </app-personal-data>
        </mat-card>
        <mat-card class="cardProfile">
            <app-contact-data 
                [showComplete]="showComplete" 
                [contactData]="contactData" 
                [estado]="estado"
                (onSubmitContactEvent)='onSubmitContact($event)'>
            </app-contact-data>
        </mat-card>
        <mat-card *ngIf="showComplete" class="cardProfile">
            <app-physiological-data  
                [physiologicalData]="physiologicalData" 
                [estado]="estado"
                (onSubmitPhysiologicalEvent)='onSubmitPhysiological($event)'>
            </app-physiological-data>
        </mat-card>
        <!-- <mat-card class="cardProfile">
            <app-domicile-data [domicileData]="domicileData" (onSubmitDomicileEvent)='onSubmitDomicile($event)'></app-domicile-data>
        </mat-card> -->
        <!-- 4088 removido   && pasosHabilitados.bancario -->
        <mat-card *ngIf="showComplete && !isTrainer" class="cardProfile">
            <app-bank-data 
                [alumnosDatosBancario]="alumnosDatosBancario" 
                [estado]="estado"
                (onSubmitBankEvent)='onSubmitBank($event)'>
            </app-bank-data>
        </mat-card>
        
    </div>