import { Injectable } from '@angular/core';
import { StatusEnum } from 'src/app/enums/status';
import { RolService } from '../../../services/rol/rol.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { Alerts, Notificacion } from '../../interfaces/alerts';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from 'src/app/components/generic-dialog/generic-dialog.component';
import { Router } from '@angular/router';
import { NotificacionAlarmaTipo } from 'src/app/enums/notificaciones-alarmas';

@Injectable({
  providedIn: 'root'
})
export class AlertTriggerService {

  private url = environment.apiUrl + '/';

  httpOptionsJson = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }),
    withCredentials: true,
  };

  constructor(
    private rolService: RolService,
    private http: HttpClient,
    public dialog: MatDialog,
    private router: Router,
  ) { }

  getAlertas(usuarioId: number): Observable<Notificacion[]> {
    return this.http.get<Notificacion[]>(this.url + `api/notificaciones?usuarioId=${usuarioId}`,  this.httpOptionsJson);
  }

  /**
   * Cambia el tipo de alerta a C, por lo que la alerta del apto médico ya no surgiría
   * @param usuarioId 
   * @param notificacionTipo
   * @returns 
   */
  putAlertaC(usuarioId: number, notificacionTipo: string, nuevoEstadoNoti: string): Observable<any> {
    let body = {
      algo: "algo"
    }
    return this.http.put<any>(this.url + `api/notificaciones/?usuarioId=${usuarioId}&notificacionTipo=${notificacionTipo}&nuevoEstadoNoti=${nuevoEstadoNoti}`, body,  this.httpOptionsJson);
  }


  /**
   * Creates notificacion for usuarioId
   * @param usuarioId
   * @param notificacionTipo - A: Apto médico is missing
   * @returns 
   */
  createNotificacion(usuarioId: number, notificacionTipo: string): Observable<any> {
    let body = {
      algo: "algo"
    }
    return this.http.post<any>(this.url + `api/notificaciones/?usuarioId=${usuarioId}&notificacionTipo=${notificacionTipo}`, body,  this.httpOptionsJson);
  }

  /**
   * Call handleRolAlerts functions according to user's role.
   */
  handleAlertsByRolAndStatus() {
    const isStudent: boolean = this.rolService.isStudent();
    if(isStudent) {
      const estado: string = localStorage.getItem('estadoAlumno');
      if(estado == StatusEnum.ACTIVADO) {
        this.handleAlertsByType();
      }
    } else { 
      this.handleAlertsByType();
    }

  }

  removeAlerts() {

  }

  /**
   * If any notification type is A (Apto médico), set medicalCertificateAlarm true in localStorage, so user can see alarms.
   * Ask user to upload medical Certificate. 
   * If true, redirect user to his profile and set medicalCertificateUpload true in sessionStorage, which is used to open "Apto médico" tab and open GalleryElementNewComponent.
   */
  handleAlertsByType() {
    const usuarioId: number = parseInt(localStorage.getItem('idUsuario'),10);
    this.getAlertas(usuarioId)
    .subscribe(response => { 
      const certificadoMedicoIndex: number = response.findIndex(notificacion => notificacion.notificacionTipo == NotificacionAlarmaTipo.AptoMedico);
      if(certificadoMedicoIndex != -1 && this.rolService.isStudent()) {
        localStorage.setItem('medicalCertificateAlarm','true');

        const dialogRef = this.dialog.open(GenericDialogComponent, {
          data: {
            title: response[certificadoMedicoIndex].notificacionTitulo,
            iconClass: "bigBlueIcon material-icons-outlined leftAlertIcon",
            text: response[certificadoMedicoIndex].notificacionMensaje,
            icon: "medical_information",
            confirm: "ADJUNTAR AHORA",
            cancel: "MÁS TARDE",
            showConfirmAsPrimary: true
          }
        });
  
        dialogRef.afterClosed().subscribe(result => {
          if (result) {
            sessionStorage.setItem('medicalCertificateUpload','true');
            this.router.navigate(['alumno/mi-perfil']);
          }
        });
      }
    },
      error => {
        console.error(error)
      });
  }

  /**
   * Check if student has a notification type A (Apto médico)
   * @param usuarioId - student's usuarioId
   * @returns boolean true, if student has notification
   */
  getStudentAlerts(usuarioId: number): Promise <boolean> {
    return new Promise((resolve,reject) => {
      this.getAlertas(usuarioId)
      .subscribe(async response => { 
        const certificadoMedicoIndex: number = response.findIndex(notificacion => notificacion.notificacionTipo == NotificacionAlarmaTipo.AptoMedico);
        if(certificadoMedicoIndex != -1) {
          resolve(true);
        }
        resolve(false);
      },
        error => {
          console.error(error);
          reject(false);
        });
    })
  }

  /**
   * Check medicalCertificateUpload in sessionStorage and user's rol to be student.
   * @returns tabIndex, use with mat-tab-group. 0 if conditions are false, 2 if conditions true.
   */
  getTabIndex(): number {
    let tabIndex: number = 0;
    const isStudent: boolean = this.rolService.isStudent();
    const medicalCertificateUpload: boolean = JSON.parse(sessionStorage.getItem('medicalCertificateUpload'));
    if(isStudent && medicalCertificateUpload) {
      tabIndex = 2;
    } 
    return tabIndex;
  }

  /**
   * Check in localStorage if medicalCertificateAlarm is true, used if user's role is student.
   * @returns showCertificateAlert - boolean.
   */
  showMedicalAlertCircle(): boolean {
    const showCertificateAlert: boolean = JSON.parse(localStorage.getItem('medicalCertificateAlarm'));
    return showCertificateAlert;
  }


  handleTrainerAlerts() {

  }
}
