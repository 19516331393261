<div>

    <p *ngIf="isMobile" class="headline6Light"> {{posicion}}/{{cantidadPasosHabilitados}} {{ 'registration.stepThree.titleMobile' | translate}}</p>

    <mat-card class="cardCenter2">
        <p class="headline5NoCenter">{{ 'registration.stepThree.background' | translate}}</p>

        <app-main-form-dinamico 
            *ngIf="preguntas"
            [preguntas]="preguntas"
            [showData]="false"
            (puedeContinuarEvent)="puedeContinuar($event)"
            (respuestasEvent)="crearRespuestas($event)">
        </app-main-form-dinamico>

    </mat-card> 
</div>

<div *ngIf="!isMobile" class="cardCenter3">
    <div class="buttonInsideCard">
        <app-buttons 
            [enablePrevious]="true" 
            [antecendentesRespuesta]="true" 
            [disabled]="!formPreguntasValid" >
        </app-buttons>
    </div>
</div>

<app-buttons 
    *ngIf="isMobile" 
    [enablePrevious]="true" 
    [antecendentesRespuesta]="true" 
    [disabled]="!formPreguntasValid" >
</app-buttons>