import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-feedback-option',
  templateUrl: './feedback-option.component.html',
  styleUrls: ['./feedback-option.component.scss']
})
export class FeedbackOptionComponent implements OnInit {

  @Input('option') option: number;
  @Input('disabled') disabled: boolean;
  @Output() saveButtonEvent = new EventEmitter<number>();
  constructor() { }

  ngOnInit(): void {
  }

  saveButton(option: number) {
    this.saveButtonEvent.emit(option);
  }

}
