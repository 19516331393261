import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Archivo, ArchivosPorSeccion, TipoArchivo} from 'src/app/api/interfaces/archivos';
import { GalleryFilesService } from '../../../../api/services/gallery-files/gallery-files.service';
import { ArchivosEnum } from 'src/app/enums/archivos';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';
import { SnackbarService } from 'src/app/utils/snackbar/snackbar.service';
import { MatDialog } from '@angular/material/dialog';
import { GalleryElementNewComponent } from '../gallery-element-new/gallery-element-new.component';
import { saveAs } from 'file-saver';
import { GenericDialogComponent } from 'src/app/components/generic-dialog/generic-dialog.component';
import { RolService } from 'src/app/services/rol/rol.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { MobileDetectorService } from '../../../../services/mobileDetector/mobile-detector.service';
import { FiltrosArchivos } from '../../../../api/interfaces/archivos';


@Component({
  selector: 'app-gallery-main',
  templateUrl: './gallery-main.component.html',
  styleUrls: ['./gallery-main.component.scss']
})
export class GalleryMainComponent implements OnInit, OnDestroy {

  @Input('usuarioId') usuarioId: number;

  filters: FiltrosArchivos = {
    nombre : "",
    tiposId : [],
    tiposIdString: "tipoId="
  };

  isMobile: boolean = false;

  totalElements: number = 0;
  totalPages: number = 0;
  size: number = 10;
  page: number = 0;

  first: boolean = true;
  last: boolean = false;

  noData: boolean = false;


  todasSeccionesArchivos: ArchivosPorSeccion[];
  waiting: boolean = false;

  displayedColumns: string[] = ['name', 'uploadDate', 'type', 'endDate', 'actions'];

  archivosTabla: Archivo[] = [];
  tiposArchivo: TipoArchivo[] = [];

  isStudent: boolean = false;
  showPdf: boolean = false;



  private sharedData$: Subscription;

  constructor(
    private galleryFilesService: GalleryFilesService,
    private snack: SnackbarService,
    private sharedData: SharedDataService,
    public dialog: MatDialog,
    private rolService: RolService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private mobileDetector: MobileDetectorService
  ) { 
    this.matIconRegistry.addSvgIcon(
      `delete`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../assets/delete.svg')
    );
  }

  ngOnInit(): void {
    this.isStudent = this.rolService.isStudent();
    this.isMobile = this.mobileDetector.isMobile();
    this.sharedData$ = this.sharedData.currentMessage.subscribe(message => {
     if(message === ArchivosEnum.updateList) {
      this.waiting = true;
      this.loadData();
     }
    });
    this.loadData();
    this.getTiposArchivos();
  }

  getTiposArchivos() {
     this.galleryFilesService.getTiposArchivo()
     .subscribe( response=> {
      this.tiposArchivo = response;
      const medicalCertificateUpload: boolean = JSON.parse(sessionStorage.getItem('medicalCertificateUpload'));
      if(medicalCertificateUpload) {
        sessionStorage.removeItem('medicalCertificateUpload');
        this.openNewElementDialog();
      }
     },
     error => {
       console.error(error);
       this.snack.openSnackBar(error.error.message);
     });
  }

  loadData() {
    this.archivosTabla =  [];
    this.noData = false;
    this.waiting = true;

    this.galleryFilesService.getArchivosPageable(this.page, this.size, this.usuarioId).subscribe( response => {
    // this.galleryFilesService.getArchivosPageableFiltrable(this.page, this.size, this.filters.nombre, this.filters.tiposIdString).subscribe( response => {
      this.totalPages = response.totalPages;
      this.first = response.first;
      this.last = response.last;
      this.totalElements = response.totalElements ? response.totalElements : 0;
      if (this.totalElements == 0) {
        this.noData = true;
        this.waiting = false;
        this.first = true;
        this.last = true;
        this.totalPages = 0;
      } else {
        this.archivosTabla = response.content;
        this.archivosTabla.forEach(archivo => {
          // ticket 3435
          const split: string[] = archivo.vigencia.split('T');
          archivo.vigencia = split[0] + "T12:00:00Z";
        })
        this.waiting = false;
      }
    },
    error => {
      this.waiting = false;
      console.error(error);
      this.snack.openSnackBar(error.error.message);
    });
  }

  // getArchivosPorSeccion() {
  //   this.galleryFilesService.getArchivosPorSeccion()
  //   .subscribe( response=> {
  //     this.todasSeccionesArchivos = response;
  //     this.waiting = false;
  //     this.noData = false;
  //   },
  //   error => {
      // this.waiting = false;
  //     console.error(error);
  //     this.snack.openSnackBar(error.error.message);
  //   });
  // }

  /**
   * Open dialog to upload certificate.
   * If success, remove notifications from tab and header
   */
  openNewElementDialog() {
    const dialogRef = this.dialog.open(GalleryElementNewComponent, {
      data: {
        tiposArchivo: this.tiposArchivo,
        usuarioId: this.usuarioId
      }
    });

  }

  download(archivo: Archivo) {
    this.galleryFilesService.downloadFile(archivo.archivoId,archivo.archivoTipoIdFK)
      .subscribe(response => {
        saveAs(response, archivo.archivoUrl);
      });
  }

  delete(archivo: Archivo) {

    const dialogRef = this.dialog.open(GenericDialogComponent, {
      data: {
        title: "¿Está seguro que desea eliminar el documento?",
        iconClass: "bigBlueIcon marginLeftNegativeBigIcon",
        text: "",
        icon: "error_outlined",
        confirm: "ELIMINAR",
        cancel: "NO"
      }
    });
    
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        this.galleryFilesService.deleteItem(archivo.archivoId, archivo.archivoTipoIdFK)
        .subscribe( response=> {

          this.snack.snackTranslate('snacks.sucess.sections.deletePdf');
          this.sharedData.changeMessage(ArchivosEnum.updateList);
        },
        error => {
          console.error(error);
          this.snack.openSnackBar(error.error.message);
        });
      }
    });
  }

  getPreview(archivo: Archivo) {
    this.showPdf = !this.showPdf;
    // if(this.showPdf) {
    //   this.pdfPath = await this.galleryFilesService.preview(this.archivo.id);
    //   this.loadedPdf = true;
    // }
  }

  loadMore(event) {
    this.page = event;
    this.loadData();
  }

  setFilters(filters: FiltrosArchivos) {
    if(filters) {
      this.filters.nombre = filters.nombre ? filters.nombre : "";
     

      this.filters.tiposIdString = "";
      if(filters.tiposId && filters.tiposId.length != 0) {
        filters.tiposId.forEach(element => {
          const aux: string = `&tipoId=${element}`
          this.filters.tiposIdString += aux;
        });
      } else {
        this.filters.tiposIdString = "tipoId=";
      }
    }
  }

  filtersChange(filters: FiltrosArchivos) {
    this.page = 0;
    this.setFilters(filters);
    // localStorage.setItem('filters',JSON.stringify(filters));
    this.loadData();
  }

  public ngOnDestroy(): void {
    this.sharedData$.unsubscribe();
  }

}
