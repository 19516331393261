import { Component, OnInit, Input } from '@angular/core';
import { MotivoBaja } from 'src/app/api/interfaces/user';

@Component({
  selector: 'app-status-register-card',
  templateUrl: './status-register-card.component.html',
  styleUrls: ['./status-register-card.component.scss']
})
export class StatusRegisterCardComponent implements OnInit {

  @Input('estado') estado: string;
  @Input('alumnoId') alumnoId: number;
  @Input('usuarioId') usuarioId: number;
  @Input('entrenadorId') entrenadorId: number;
  @Input('motivoBaja') motivoBaja: MotivoBaja;
  @Input('isAdminOrTrainer') isAdminOrTrainer: boolean;
  @Input('isTrainer') isTrainer: boolean;
  @Input('entrenadorIsAdmin') entrenadorIsAdmin: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
