import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog'

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy {

  @Input('showText') showText: boolean;
  @Input('habilitar') habilitar?: boolean;
  class: string = 'dialogContainerLoading';
  constructor() { }

  ngOnInit(): void {
    if(!this.showText) {
      this.class = "loadingTransparent";
    }
    
    sessionStorage.setItem('loadingComponent','true');
  }

  ngOnDestroy(): void {
    sessionStorage.removeItem('loadingComponent');
  }

}
