import { Component, OnDestroy, OnInit } from '@angular/core';
import { SpinnerService } from './services/spinner/spinner.service';
import { TranslateService } from "@ngx-translate/core";
import { PaletaColoresService } from './services/paletaColores/paleta-colores.service';
import { SharedDataService } from './services/sharedData/shared-data.service';
import { FlagsColor } from './enums/sharedDataFlag';
import { LogUpdateService} from './services/service-workers/LogUpdate/log-update.service';
import { DetectConnectionService } from './services/service-workers/DetectConnection/detect-connection.service';
import { ThemeService } from './theme.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs/internal/Subject';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  title = 'nr-frontend';

  class: string = "notranslate";

  private languageCodes: string[] = ["es"];

  theme: string = "";
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private spinnerService: SpinnerService,
    private translateService: TranslateService,
    private paletaColores: PaletaColoresService,
    private sharedData: SharedDataService,
    private logUpdate: LogUpdateService,
    private detectConnection: DetectConnectionService,
    private themeService: ThemeService) {
  }

  ngOnInit() {
    this.logUpdate.checkForUpdates();      
    this.logUpdate.logUpdates();
  
    this.spinnerService.showSpinner$.subscribe(display => {
      this.loading = display;
    });

    this.changeClassAndPaleta();

    this.sharedData.currentMessage.subscribe(message => {
      if (message === FlagsColor.changeColor) {
       this.changeClassAndPaleta();
      }
      if (message === FlagsColor.setDefaultColor) {
        this.paletaDefault();
       }
    });

    this.translateService.addLangs(this.languageCodes);
    this.translateService.setDefaultLang("es");
    this.selectLanguageByCode(this.translateService.getBrowserLang());
    this.detectConnection.detector();

    this.themeService.getTheme().pipe(takeUntil(this.destroy$)).subscribe((theme) => {
      this.theme = theme;
    });
  }



  private selectLanguageByCode(languageCode: string): void {
    this.translateService.use(
      this.isLanguageCodeSupported(languageCode)
        ? languageCode
        : this.translateService.getDefaultLang()
    );
  }

  public get currentLanguageCode(): string {
    return this.translateService.currentLang;
  }

  public get supportedLanguageCodes(): string[] {
    return this.languageCodes;
  }

  public onSelectLanguage(e: any): void {
    this.selectLanguageByCode(e.target.value);
  }

  public get todaysDate(): Date {
    return new Date();
  }
  
  private isLanguageCodeSupported(languageCode: string): boolean {
    return this.supportedLanguageCodes.indexOf(languageCode) > -1;
  }

  async changeClassAndPaleta() {
    const paletaColorMaterial: string = await this.paletaColores.setPaletaColores();
    if(!sessionStorage.getItem(FlagsColor.setDefaultTheme)) {
      paletaColorMaterial ? this.class = "notranslate " + paletaColorMaterial : this.class = "notranslate";
    } else {
      // ticket 3548
      this.paletaDefault();
    }
  }

  paletaDefault() {
    this.class = "notranslate";
    this.paletaColores.setPaletaDefault();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
  
}
