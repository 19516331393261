<form [formGroup]="optionsForm">
    <p class="subtitle1 textLeft">{{pregunta.titulo}} <span *ngIf="requerido"> *</span></p>
    <mat-form-field appearance="fill" class="inputfullWidth">
        <mat-label>{{pregunta.placeholder}}</mat-label>
        <mat-select
            aria-labelledby="radioGroupColumn" 
            class="radioGroupColumn" 
            formControlName="valor"
            [multiple]="multiple"
            [required]="requerido">

            <mat-option
                color="primary"
                class="example-radio-button" 
                *ngFor="let opcion of opciones"
                [value]="opcion">
                {{opcion.detalleDescripcion}}
            </mat-option>
        </mat-select>
        <mat-hint *ngIf="pregunta.textoAsistivo">{{pregunta.textoAsistivo}}</mat-hint>
    </mat-form-field>

</form>