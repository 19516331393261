<form class="newMessage" [formGroup]="messagesForm">
    <mat-form-field appearance="outline" [class]="class">
      <mat-label> {{'messages.placeholder' | translate}} </mat-label>
      <textarea 
        matInput 
        type="text"
        formControlName="mensaje"  
        (keyup.enter)="onSubmit()"
        (click)="changeSize()"
        (keyup)="changeSize()"
        (mouseout)="changeSizeSmall()"
        maxLength={{longitudMensaje}}
        cdkTextareaAutosize
        #autosize="cdkTextareaAutosize"
        [cdkAutosizeMinRows]="cdkAutosize"
        cdkAutosizeMaxRows="6">
      </textarea>

      <!-- icon only to show in massive -->
      <button
        *ngIf="enable"
        mat-icon-button 
        matSuffix>
          <mat-icon>
            send
          </mat-icon>
      </button>

      <button 
        *ngIf="!waiting && !enable && messagesForm.value.mensaje && messagesForm.value.mensaje.trim().length > 0"
        mat-icon-button 
        matSuffix 
        (click)="onSubmit()">
          <mat-icon >
            send
          </mat-icon>
      </button>

      <mat-hint align="end">{{messagesForm.value.mensaje?.length || 0}} / {{longitudMensaje}}</mat-hint>
    </mat-form-field>
</form>