import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  constructor(
    private _snackBar: MatSnackBar,
    private translateService: TranslateService
  ) { }

  public openSnackBar(message: string, setDuration?: number) {
    this._snackBar.open(message, "", { duration: setDuration ? setDuration : 5000 });
  }

  public snackTranslate(key: string) {
    this.translateService.get(key).subscribe((text: string)=> {
      this.openSnackBar(text);
     });
  }

  public openSnackBarWithAction(message: string, action: string) {
    this._snackBar.open(message, action);
  }

  public snackTranslateWithAction(key: string, action: string) {
    this.translateService.get(key).subscribe((text: string)=> {
      this.openSnackBarWithAction(text, action);
     });
  }

  public getTranslate(key: string): string {
    let textoTraducido: string = "";
    this.translateService.get(key).subscribe((text: string)=> {
      textoTraducido = text;
      return textoTraducido;
     });
     return textoTraducido;
  }
}
