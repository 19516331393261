<div *ngIf="day.fecha" [class]="dayClass" (click)="selectDay(false)">
    <div [class]="titleClass">
        <p [class]="dayTitleTextClass" *ngIf="!isMobile">{{day.fecha | dateName: 'EEEE d - MMM'}}</p>
        <p [class]="dayTitleTextClass" *ngIf="isMobile">{{day.fecha | dateName: 'EEEEE d'}}</p>
        <mat-icon [class]="dayIconClass" *ngIf="day.objetivo && !isMobile" (click)="openObjetivesDialog()">flag</mat-icon>
        <mat-icon [class]="dayIconClass" *ngIf="day.feedbackOpcion && !isStudent && !isMobile" (click)="openFeedbackDialog()">chat</mat-icon>
        <mat-icon [class]="dayIconClass">{{icono}}</mat-icon>
        
    </div>
    <div class="flexPlan" *ngIf="!isMobile">
        <p class="dayText" *ngIf="!edit">{{day.detalleDia}}</p>
        <form *ngIf="edit" [formGroup]="planForm">
            <mat-form-field (click)="$event.stopPropagation()" class="inputActividad" appearance="outline">
                <textarea matInput id="actividadInput" formControlName="actividad" type="text" required></textarea>
                <!-- <input matInput id="actividadInput" formControlName="actividad" type="text" required> -->
            </mat-form-field>
        </form>
        
        <!-- <p class="dayText">{{day.comentario}}</p> -->
        <div class="dayButtons showOnHover" *ngIf="!edit">
            <!-- <mat-icon *ngIf="isAdmin" svgIcon="delete" id="deleteButton" class="dayIcon"></mat-icon> -->
            <mat-icon 
                class="dayIcon" 
                *ngIf="( !isStudent  && day.detalleDia != 'Descanso' && !day.feedbackOpcion) 
                        && (  !isStudent && day.tipoDia != 'P'  && !day.feedbackOpcion)" 
                (click)="delete(); $event.stopPropagation()">
                delete_outline
            </mat-icon>
            <mat-icon 
                class="dayIcon" 
                *ngIf=" !isStudent  && !day.feedbackOpcion " 
                (click)="toggleEdit(); $event.stopPropagation()">
                edit
            </mat-icon>
            <mat-icon class="dayIcon2"  *ngIf="showEye" (click)="selectDay(true)">visibility</mat-icon>
        </div>
        <div class="dayButtons" *ngIf="edit">
            <button class="dayIconButton" (click)="toggleEdit(); $event.stopPropagation()" mat-icon-button>
                <mat-icon class="dayIconCancel" >close</mat-icon>
            </button>
            <button class="dayIconButton" (click)="onSubmit(); $event.stopPropagation()" mat-icon-button>
                <mat-icon class="dayIconDone" >done</mat-icon>
            </button>
        </div>
    </div>
</div>

<br/>
<div [class]="dayInfoMobileClass" *ngIf="isMobile && day.seleccionado && showEye">
    <p class="subtitle3Blue">{{day.fecha | dateName: 'EEEE d - MMM'}}</p>
    <p class="body2" *ngIf="!edit">{{day.detalleDia}}</p>

    <form *ngIf="edit" [formGroup]="planForm">
        <mat-form-field class="inputActividad" appearance="outline">
            <mat-label>Edición</mat-label>
            <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="8" id="actividadInput" formControlName="actividad" type="text"></textarea>
        </mat-form-field>
    </form>

    <div class="buttonsDayMobile" *ngIf="!edit">
        <div class="flexLeft">
            <button 
                mat-icon-button 
                *ngIf="day.objetivo"  
                (click)="openObjetivesDialog()">
                <mat-icon 
                    class="dayIconInfoMobile primaryColor" >
                    flag
                </mat-icon>
            </button>
            
            <button 
                mat-icon-button  
                *ngIf="day.feedbackOpcion && !isStudent" 
                (click)="openFeedbackDialog()">
                <mat-icon 
                    class="dayIconInfoMobile primaryColor">
                    chat
                </mat-icon>
            </button>
        </div>

        <div class="flexRigth">
            <button mat-icon-button>
                <mat-icon 
                    class="dayIconInfoMobile blackColor" 
                    *ngIf="(!isStudent && day.detalleDia != 'Descanso' && !day.feedbackOpcion) && ( !isStudent && day.tipoDia != 'P'  && !day.feedbackOpcion)"
                    (click)="delete(); $event.stopPropagation()">
                    delete_outline
                </mat-icon>
            </button>

            <button mat-icon-button>
                <mat-icon 
                    class="dayIconInfoMobile blackColor" 
                    *ngIf="!isStudent  && !day.feedbackOpcion " 
                    (click)="toggleEdit(); $event.stopPropagation()">
                    edit
                </mat-icon>
            </button>
            
            <button mat-icon-button>
                <mat-icon 
                    class="dayIconInfoMobile blackColor" 
                    (click)="selectDay(true)">
                    visibility
                </mat-icon>
            </button>
        </div>
    </div>

    <div class="buttonsDayMobile" *ngIf="edit">
        <div class="flexRigth" id="cancelDone">

            <button mat-icon-button>
                <mat-icon 
                    class="dayIconInfoMobile dayIconCancel"
                    (click)="toggleEdit()">
                    close
                </mat-icon>
            </button>
            
            <button mat-icon-button>
                <mat-icon 
                    class="dayIconInfoMobile dayIconDone" 
                    (click)="onSubmit()">
                    done
                </mat-icon>
            </button>
        </div>
    </div>
</div>