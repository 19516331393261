<div class="footerHr"></div>
<footer>
        <div class="buttonsFooter">
            <button class="overline" mat-button>
                <!-- <a class="overline" target="_blank" href="https://iutrein.godaddysites.com/t%C3%A9rminos-y-condiciones">{{'footer.legal' | translate}}</a> -->
                <a class="overline" target="_blank" href="/terminos-condiciones">{{'footer.legal' | translate}}</a>
            </button>
            <!-- <button class="overline" mat-button>
                <a class="overline" target="_blank" href="https://www.vgrunning.com/politica-de-cookies/">{{'footer.cookies' | translate}}</a>
            </button> -->
        </div>
        <br/>
</footer>

