<div class="paginatorButtons">

    <div class="flexLeftBaseline">
        <button mat-icon-button [disabled]="first"  (click)="paginators('-')" color="primary">
            <mat-icon *ngIf="!first">chevron_left</mat-icon>
            <mat-icon *ngIf="first" class="clearIcon">chevron_left</mat-icon>
        </button>
        <p> {{totalPages == 0 ? 0 : page + 1 }} / {{totalPages}}</p>
        <button mat-icon-button [disabled]="last" (click)="paginators('+')" color="primary">
            <mat-icon *ngIf="!last">chevron_right</mat-icon>
            <mat-icon *ngIf="last" class="clearIcon">chevron_right</mat-icon>
        </button>
    </div>
</div>