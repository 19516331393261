import { Component, OnInit, Input } from '@angular/core';
import { FiltratedObjetives, Antecedente, PasosHabilitados } from '../../../../api/interfaces/register';
import { AllAchievements} from '../../../../api/interfaces/user';
import { MobileDetectorService } from '../../../../services/mobileDetector/mobile-detector.service';


@Component({
  selector: 'app-background-objetives',
  templateUrl: './background-objetives.component.html',
  styleUrls: ['./background-objetives.component.scss']
})
export class BackgroundObjetivesComponent implements OnInit {

  @Input('allAchievements') allAchievements: AllAchievements;
  @Input('objetives') objetives: FiltratedObjetives;
  @Input('antecedente') antecedente: Antecedente[];
  @Input('showMarcasObjetivos') showMarcasObjetivos: boolean;
  @Input('showAntecedentes') showAntecedentes: boolean;
  @Input('alumnoId') alumnoId?: number;
  
  class: string = "flexEvenlyWrap";
  isMobile: boolean = false;

  // ticket 2752
  pasosHabilitados: PasosHabilitados;

  constructor(
    private mobileDetectorService: MobileDetectorService,
  ) { }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    if(this.showMarcasObjetivos && !this.showAntecedentes) {
      this.class = "flexEvenlyWrapLeft";
    }
    this.pasosHabilitados = JSON.parse(sessionStorage.getItem('pasosHabilitados'));
  }

}
