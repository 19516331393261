import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

/**
 * Generic Paginator
 * @param first boolean, indicate if current page is the first
 * @param last boolean, indicate if current page is the last
 * @param totalElements number
 * @param totalPages number
 * @param page number, current page
 * @param size number, length of elements per page
 * 
 * @example ListComponent.html
 * ```html
 * <app-paginator-complete
 *   *ngIf="done"
 *    [first]="first"
 *    [last]="last"
 *    [page]="page"
 *    [totalPages]="totalPages"
 *    [size]="size"
 *    [totalElements]="totalElements"
 *   (loadMoreEvent)="loadMore($event)">
 *</app-paginator-complete>
 * ```
 * 
 * @example ListComponent.ts
 * ```ts
 * getPageableData() {
 *   this.done = false;
 *   this.apiService.getData(this.page, this.size).subscribe( response => {
 *     this.content = response.content;
 *     this.totalPages = response.totalPages;
 *     this.first = response.first;
 *     this.last = response.last;
 *     this.done = true;
 *   },
 *   error => {
 *     console.error(error);
 *   });
 * }
 * 
 * loadMore(event) {
 *   this.page = event;
 *   this.getPageableData();
 * }
 * ```
 */
@Component({
  selector: 'app-paginator-complete',
  templateUrl: './paginator-complete.component.html',
  styleUrls: ['./paginator-complete.component.scss']
})
export class PaginatorCompleteComponent implements OnInit {

  @Input('first') first: boolean;
  @Input('last') last: boolean;

  @Input('totalElements') totalElements: number;
  @Input('totalPages') totalPages: number;
  @Input('page') page: number;
  @Input('size') size: number;

  @Output() loadMoreEvent = new EventEmitter<number>();

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * This function change the page value.
   * @param target string, + => add page, - => substract page
   */
  paginators(target: string) {
    if(target == "-" && this.page > 0) {
      this.page--;
    }
    if(target == "+" && this.page < this.totalPages) {
      this.page++;
    }
    this.loadMoreEvent.emit(this.page);
  }

}
