import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Dia, EntrenamientoDiaFiltrado, EntrenamientoDiaIndividual } from 'src/app/api/interfaces/user';
import { PlansService } from 'src/app/api/services/plans/plans.service';
import { UserService } from 'src/app/api/services/user/user.service';
import { trainingDaysEnum } from 'src/app/enums/training-type';
import { SharedDataService } from '../../../services/sharedData/shared-data.service';
import { SnackbarService } from 'src/app/utils/snackbar/snackbar.service';
import { WeekService } from 'src/app/utils/week/week.service';
import { ObjetivesDialogComponent } from '../objetives-dialog/objetives-dialog.component';
import { MatDialog} from '@angular/material/dialog';
import { ParametrosService } from '../../../api/services/parametros/parametros.service';
import { FileData } from 'src/app/api/interfaces/fileData';
import { saveAs } from 'file-saver';
import { DownloadService } from 'src/app/api/services/download/download.service';
import { RolService } from 'src/app/services/rol/rol.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from "@angular/material/icon";
import { ChangeDaysDialogComponent } from '../change-days-dialog/change-days-dialog.component';
import { MobileDetectorService } from 'src/app/services/mobileDetector/mobile-detector.service';
import { HoursDayFixedService } from 'src/app/utils/hoursDayFixed/hours-day-fixed.service';

/**
 * Get plan data for the week and call dayComponent to display it. 
 * Student can change the week and return to the current one.
 * Student and admin can download plan pdf
 */
@Component({
  selector: 'app-week',
  templateUrl: './week.component.html',
  styleUrls: ['./week.component.scss']
})
export class WeekComponent implements OnInit, OnDestroy {

  myFilter = (d: any | null): boolean => {
    const day = new Date(d._d).getDay();
    return day == 1;
  };

  @Input('alumnoId') alumnoId: number;
  @Input('isAdmin') isAdmin: boolean;
  @Input('showDownload') showDownload?: boolean;


  private sharedData$: Subscription;

  @Input('ultimoDiaDisponible') ultimoDiaDisponible: Date;

  @Input('entrenamientoDia') entrenamientoDia?: EntrenamientoDiaIndividual[];

  daysWeek2: EntrenamientoDiaFiltrado[] = [];
  days: EntrenamientoDiaFiltrado[] = [];

  enableDownload: boolean = false;
  showReturnWeek: boolean = false;
  showChangeDays: boolean = false;
  isStudent: boolean = false;
  loaded: boolean = false;
  isMobile: boolean = false;

  semana2: string[] = [];
  semana: string[] = [];
  selected: Date | null;

  planForm = new FormGroup({
    semana: new FormControl()
  });

  constructor(
    private plansService: PlansService,
    private snack: SnackbarService,
    private userService: UserService,
    private weekService: WeekService,
    private sharedData: SharedDataService,
    public dialog: MatDialog,
    private parametrosService: ParametrosService,
    private downloadService: DownloadService,
    private rol: RolService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private activatedrouter: ActivatedRoute,
    private mobileDetectorService: MobileDetectorService,
    private hoursDayFixed: HoursDayFixedService,
  ) { 
    this.matIconRegistry.addSvgIcon(
      `return`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/returnWeek.svg')
    );
  }

  /**
   * Request to backend parameter 'semanahabilitada', in order to allow the student to see and download the week plan.
   * Admin should always be capable of download/see/edit the week plan, no matter 'semanahabilitada' value.
   */
  async check() {
    if(!this.isAdmin) {
      try {
        const fechaFeedbackDate = new Date(this.semana[0]);
        //si es alumno debe validar, sino no
        const condicional: boolean = this.isStudent ? (fechaFeedbackDate <= this.ultimoDiaDisponible) : true;
        if(!condicional) {
          this.sharedData.changeMessage("semanaNoDisponible");
        }
        this.enableDownload = condicional;

      } catch {
      }
    } else {
      this.enableDownload = true;
    }
  }

  ngOnInit(): void {

    sessionStorage.removeItem('fecha');

    this.isStudent = this.rol.isStudent();
    this.isMobile = this.mobileDetectorService.isMobile();
    
    this.planForm.statusChanges
    .subscribe(val => {
      if(this.planForm.valid && this.planForm.value.semana) {
        this.loaded = false;
        this.semana = this.weekService.getWeek(new Date(this.planForm.value.semana));
        sessionStorage.setItem('semana',this.weekService.convertDate(this.semana[0]));
        this.days = [];
        this.getMyProfileData();
        sessionStorage.setItem('fecha',(new Date(this.semana[0])).toString());
        sessionStorage.setItem('flagCambioSemana','true');
        this.sharedData.changeMessage("updateDays"); 
        this.showReturnWeek = !this.weekService.isCurrentWeek(this.planForm.value.semana);
        this.check();
      }
    });
    let aux = new Date();
    aux.setDate(aux.getDate() + 7);
    
    this.semana = this.weekService.getWeek(new Date());
    this.semana2 = this.weekService.getWeek(aux);
    this.getMyProfileData();

    this.sharedData.changeMessage("");  //bugfix despues de editar un dia y cambiar un filtro
    this.sharedData$ =  this.sharedData.currentMessage.subscribe(message => {
      // ticket 2480: added reloadWeekAfterDayChanged
      if (message === "reloadWeek" || message === "reloadWeekAfterDayChanged") {
        this.clean();
      }
      if (message === "reloadWeek2") {
        const aux = sessionStorage.getItem('alumnoIdCheck');
        if(aux) {
          const check: number = parseInt(aux,10);
          if(check == this.alumnoId) { 
            this.clean();
          }
        }
      }
    });

    const url = this.activatedrouter.snapshot.routeConfig.path;
    if( url == 'admin/perfil') {
      this.showChangeDays = true;
    }

    this.check();
  }

  reloadEvent(event: number) {
    if(event === this.alumnoId) {
      this.clean();
    }
  }

  /**
   * set days, daysWeek2 as [], loaded as false and trigger ge profile data and create week data
   */
  clean() {
    this.loaded = false;
    this.days = [];
    this.daysWeek2 = [];
    // necesario para la tabla de estudiantes
    this.getMyProfileData();
  }

  //lunes tiene id 1 , deprecado. Lunes ahora tiene id 2
  /**
   * load profile data and call create week
   */
  getMyProfileData() {
    this.days = [];
    
    // optimizacion, no volver a pedir entrenamientoDia
    if(!this.entrenamientoDia || this.entrenamientoDia.length == 0) {
      this.userService.getProfileUserId(this.alumnoId).subscribe(response => {
        this.entrenamientoDia = response.entrenamientoDia;
       
        sessionStorage.setItem('entrenamientoDia',JSON.stringify(response.entrenamientoDia));
        sessionStorage.setItem('alumnoId',this.alumnoId.toString());
        this.createPlanWeekData();
      },
      error => {
        console.error(error);
        this.snack.snackTranslate('snacks.error.generic');
      })
    } else {
      this.createPlanWeekData();
    }
  }

  createPlanWeekData() {
    let hoy = new Date();
      let hoyDia = hoy.getDay();

      //completar dias de entrenamiento que vienen desde DB
      this.entrenamientoDia.map(dia => {
        const aux: EntrenamientoDiaFiltrado = {
          diaSemana: dia.diaSemana,
          entrenamientoDiaTipo: dia.entrenamientoDiaTipo ? dia.entrenamientoDiaTipo: trainingDaysEnum.DESCANSO,
          comentario: "",
          esHoy: false,
          fecha: hoy.toLocaleDateString(),
          seleccionado: false,
        };

        const aux2: EntrenamientoDiaFiltrado = {
          diaSemana: dia.diaSemana,
          entrenamientoDiaTipo: dia.entrenamientoDiaTipo ? dia.entrenamientoDiaTipo: trainingDaysEnum.DESCANSO,
          comentario: "",
          esHoy: false,
          fecha: hoy.toLocaleDateString(),
          seleccionado: false,
        };
        this.days.push(aux);
        this.daysWeek2.push(aux2);
      });

      //completar semana (no llega toda desde DB)
      for (let index = 1; index < 8; index++) {
        const aux: EntrenamientoDiaFiltrado = {
          diaSemana: index,
          entrenamientoDiaTipo: trainingDaysEnum.DESCANSO,
          comentario: "",
          esHoy: false,
          fecha: hoy.toLocaleDateString(),
          seleccionado: false
        };
        const aux2: EntrenamientoDiaFiltrado = {
          diaSemana: index,
          entrenamientoDiaTipo: trainingDaysEnum.DESCANSO,
          comentario: "",
          esHoy: false,
          fecha: hoy.toLocaleDateString(),
          seleccionado: false
        };

        //evitar repetidos
        if(!this.days.some(dia => dia.diaSemana == aux.diaSemana)) {
          this.days.push(aux);
        }
        if(!this.daysWeek2.some(dia => dia.diaSemana == aux.diaSemana)) {
          this.daysWeek2.push(aux2);
        }
      }
      
      //correcion de orden
      this.days.sort( (a,b) => a.diaSemana - b.diaSemana);
      this.daysWeek2.sort( (a,b) => a.diaSemana - b.diaSemana);

      //asignacion de fecha y dia de hoy, pero aun no recuadro
      this.days.forEach( (day, index) => {
        day.fecha = this.semana[index];
        let hoyDia = new Date().getDay();
        let hoyDate = new Date().getDate();
        if((day.diaSemana == hoyDia) && (hoyDate == new Date(day.fecha).getDate())) {
          day.esHoy = true;
        }
      });

      // asignar dia
      this.daysWeek2.forEach( (dia, indice) => {
        dia.fecha = this.semana2[indice];
        dia.esHoy = false;
      });
      //cargar datos de endpoint getPlanSemanaData
      this.getPlanSemanaData();
  }

  changeActiveDayEvent(day: EntrenamientoDiaFiltrado) {
    this.days.forEach(day => day.seleccionado = false);
    const index: number = this.days.findIndex(dia => dia.diaSemana == day.diaSemana);
    this.days[index].seleccionado = true;

    this.changeSeleccionadoInDaysWeek2(day);

    //trigger para los demas dias y para el componente
    sessionStorage.setItem('fecha',day.fecha);
    sessionStorage.setItem('entrenamientoDiaTipo',day.entrenamientoDiaTipo);
    sessionStorage.setItem('flagCambioSemana','false');
    this.sharedData.changeMessage("updateDays"); 
  }

  changeSeleccionadoInDaysWeek2(day: EntrenamientoDiaFiltrado) {
    if(this.daysWeek2) {
      this.daysWeek2.forEach(day => day.seleccionado = false);
      const index: number = this.daysWeek2.findIndex(dia => dia.diaSemana == day.diaSemana);
      this.daysWeek2[index].seleccionado = true;
    }
  }

  /**
   * load week data from backend. Change day.seleccionado after updated/deleted one day in student profile, prority:
   * 1) check updated deleted
   * 2) check if today is in current week
   * 3) if user edited/deleted day, read sessionStorage and set the day as seleccionado. sessionStorage openDay is removed in students-table.
   * 4) if !1 !2 and !3, then day.seleccionado = true in monday
   */
  getPlanSemanaData() {
    const titulo: string = "Fase 2";
    sessionStorage.setItem('titulo',titulo);
    this.plansService.getPlanSemanaData(this.weekService.convertDate(this.days[0].fecha), this.alumnoId, titulo).subscribe(response => {
      sessionStorage.setItem('entrenamientoDiasPlan',JSON.stringify(response));
      let semanaTieneActivo: boolean = false;

      // asigno feedback y detalle, y si es un dia activo
      this.days.forEach( (day, index) => {
        if(response && response[index]) {
          day.feedbackOpcion = response[index].feedbackOpcion;
          day.detalleDia = response[index].detalleDia;
          day.objetivo = response[index].objetivo;
          day.tipoDia = response[index].tipoDia;
          day.entrenamientoDiaTipo = response[index].entrenamientoDiaTipo;

          day.fecha = this.hoursDayFixed.getDateWithConverted(response[index].fecha);
          // ticket 2480
          // recuadro dia activo, step 1
          let fechaGuardada: string = sessionStorage.getItem('fecha');
          if(fechaGuardada && fechaGuardada == day.fecha) {
            day.seleccionado = true;
            semanaTieneActivo = true;
          }
        }
      });

      //si no hay ningun dia activo, recuadro hoy. Step 2
      let semanaTieneHoy: boolean = false;
      let daySeleccionado: EntrenamientoDiaFiltrado = null;
      if(!semanaTieneActivo) {
        this.days.forEach( (day, index) => {
          if(day.esHoy) {
            day.seleccionado = true;
            semanaTieneHoy = true;
            daySeleccionado = day;
          }
      });
      }

      // step 3, 2594 y 2698
      let openDay: EntrenamientoDiaFiltrado = JSON.parse(sessionStorage.getItem('openDay'));
      const url = this.activatedrouter.snapshot.routeConfig.path;
      if(openDay && this.isMobile && url == 'admin/alumnos') {
        semanaTieneActivo = true;
        daySeleccionado = null;
        this.changeActiveDayEvent(openDay);
      }

      //si no hay ningun dia activo y no es hoy, recuadro el lunes. Step 4
      if(!semanaTieneActivo && !semanaTieneHoy) {
        this.days[0].seleccionado = true;
      }

      if(this.isAdmin) {
        this.plansService.getPlanSemanaData(this.weekService.convertDate(this.daysWeek2[0].fecha), this.alumnoId, titulo).subscribe(response => {
          this.daysWeek2.forEach( (day, index) => {
            if(response && response[index]) {
            day.feedbackOpcion = response[index].feedbackOpcion;
            day.detalleDia = response[index].detalleDia;
            day.objetivo = response[index].objetivo;
            day.tipoDia = response[index].tipoDia;
            day.entrenamientoDiaTipo = response[index].entrenamientoDiaTipo;
            day.fecha = this.hoursDayFixed.getDateWithConverted(response[index].fecha);
            }
          });  

          if(daySeleccionado) {
            this.changeSeleccionadoInDaysWeek2(daySeleccionado);
          }
          this.loaded = true;
        },
          error => {
            console.error(error);
          });  
      } else {
        this.loaded = true;
      }
    },
      error => {
        console.error(error);
      });

  }

  openDialog() {
    this.dialog.open(ObjetivesDialogComponent, {
      data: {
        alumnoId: this.alumnoId
      }
    });
  }

  downloadFile() {
    let semana: string = sessionStorage.getItem('semana');
    if(!semana) {
      const aux: string[] = this.weekService.getWeek(new Date());
      semana = this.weekService.convertDate(aux[0]);
    }
    const fileData: FileData = {
      filename: semana+".pdf",
    }
    this.downloadService.download(this.alumnoId,semana)
      .subscribe(archivo => {
        saveAs(archivo, fileData.filename);
        this.snack.snackTranslate('snacks.sucess.plan.download');
      });
  }

  returnToCurrentWeek() {
    const today: string = new Date().toDateString();
    this.loaded = false;
    this.semana = this.weekService.getWeek(new Date());
    sessionStorage.setItem('semana',this.weekService.convertDate(this.semana[0]));
    this.days = [];
    this.getMyProfileData();
    sessionStorage.setItem('fecha',(new Date(this.semana[0])).toString());
    sessionStorage.setItem('flagCambioSemana','true');
    this.sharedData.changeMessage("updateDays");
    this.showReturnWeek = false;
    this.planForm.patchValue({semana: null});
    this.check();
  }

  openChangeDays() {
    this.dialog.open(ChangeDaysDialogComponent, {
    });
  }

  public ngOnDestroy(): void {
    this.sharedData$.unsubscribe();
  }

}
