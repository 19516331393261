<form [formGroup]="objetivesForm" class="formLine" *ngIf="(objetive && objetive.activo) || isRegistration" (keydown.enter)="$event.preventDefault()">

    <mat-form-field appearance="fill" class="inputTwoLine">
        <mat-label>{{ 'registration.stepThree.objetives.name' | translate}}</mat-label>
        <input 
            [attr.disabled]="disable == true ? true : null" 
            maxlength="50" 
            required 
            placeholder="{{ 'registration.stepThree.objetives.placeholderName' | translate}}"
            id="nameInput" 
            formControlName="name" 
            type="text"
            matInput 
            (keyup)="addObjetive()">
        <mat-hint align="end" *ngIf="!disable">{{objetivesForm.value.name?.length || 0}} / 50</mat-hint>
    </mat-form-field>
    
    <mat-form-field appearance="fill" class="inputDate">
        <mat-label>{{ 'registration.stepThree.objetives.date' | translate}}</mat-label>
        <input id="dateInput" required matInput disabled formControlName="date" [min]="minDate"
        [matDatepicker]="picker">
        <mat-datepicker-toggle class="primaryIcon"  matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker [disabled]="disable == true ? true : null" disabled="false" #picker>
            <mat-datepicker-actions>
                <button mat-button matDatepickerCancel>{{ 'calendar.cancel' | translate}}</button>
                <button mat-raised-button color="accent" matDatepickerApply (click)="addObjetive()">{{ 'calendar.save' | translate}}</button>
              </mat-datepicker-actions>
        </mat-datepicker>
    </mat-form-field>

    <div class="botonesObjetivos">
        <button *ngIf="disable && !isRegistration" (click)="toggleEdit()"  mat-icon-button [disabled]="lock">
            <mat-icon *ngIf="!lock" id="deleteButton" class="delete">edit</mat-icon>
            <mat-icon *ngIf="lock" class="greyIcon">edit</mat-icon>
        </button>

        <button [disabled]="!objetivesForm.valid" *ngIf="!disable && !isRegistration" (click)="saveObjetive()"  mat-icon-button>
            <mat-icon *ngIf="!objetivesForm.valid" class="greyIcon" id="deleteButton">save</mat-icon>
            <mat-icon *ngIf="objetivesForm.valid" id="deleteButton">save</mat-icon>  
        </button>

        <button (click)="removeObjetive()" mat-icon-button *ngIf="!isMobile">
            <mat-icon svgIcon="delete" id="deleteButton" class="delete"></mat-icon>
        </button>

        <button *ngIf="isMobile" class="deleteButton" mat-button (click)="removeObjetive()"> {{'registration.stepThree.delete' | translate}}</button>
    </div>

</form>