import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { MainpageadminComponent } from '../students/mainpageadmin/mainpageadmin.component';
import { ProfileComponent } from '../profile/profile/profile.component'
import { StudentMyProfileComponent } from '../../student/profile/student-my-profile/student-my-profile.component';
import { MainpageTrainersComponent } from '../trainers/mainpage-trainers/mainpage-trainers.component';
import { ProfileTrainerAdminComponent } from '../profile/profile-trainer-admin/profile-trainer-admin.component';

import { SettingsPageComponent } from '../settings/settings-page/settings-page.component';

import { PageDomiciliacionComponent } from '../domiciliacion/page-domiciliacion/page-domiciliacion.component';
import { AuthGuard } from 'src/app/auth.guard';
import { RoleString } from 'src/app/enums/role';

const routes: Routes = [
  {
    path: 'admin/alumnos',
    component: MainpageadminComponent,
    canActivate: [AuthGuard],
    data: { roles: [RoleString.ADMIN] }
  },
  {
    path: 'admin/perfil',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    data: { roles: [RoleString.ADMIN] }
  },
  {
    path: 'alumno/mi-perfil',
    component: StudentMyProfileComponent,
    canActivate: [AuthGuard],
    data: { roles: [RoleString.STUDENT] }
  },
  {
    path: 'alumno/metas-objetivos',
    component: StudentMyProfileComponent,
    canActivate: [AuthGuard],
    data: { roles: [RoleString.STUDENT] }
  },
  {
    path: 'admin/configuracion',
    component: SettingsPageComponent,
    canActivate: [AuthGuard],
    data: { roles: [RoleString.ADMIN] }
  },
  {
    path: 'admin/cobranzas',
    component: PageDomiciliacionComponent,
    canActivate: [AuthGuard],
    data: { roles: [RoleString.ADMIN] }
  },
  {
    path: 'admin/entrenadores',
    component: MainpageTrainersComponent,
    canActivate: [AuthGuard],
    data: { roles: [RoleString.ADMIN] }
  },
  {
    path: 'admin/perfil-entrenador',
    component: ProfileTrainerAdminComponent,
    canActivate: [AuthGuard],
    data: { roles: [RoleString.ADMIN, RoleString.TRAINER] }
  },
  {
    path: 'entrenador/alumnos',
    component: MainpageadminComponent,
    canActivate: [AuthGuard],
    data: { roles: [RoleString.TRAINER] }
  },
  {
    path: 'entrenador/perfil',
    component: ProfileTrainerAdminComponent,
    canActivate: [AuthGuard],
    data: { roles: [RoleString.ADMIN, RoleString.TRAINER] }
  },
  {
    path: 'entrenador/perfil-alumno',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    data: { roles: [RoleString.TRAINER] }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }