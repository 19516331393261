<div class="showItem" *ngIf="!item">
    <div class="centerNoItem">
        <div class="noSelectedItem && !isMobile">
            <mat-icon class="noSelectedIcon">visibility</mat-icon>
            <p class="headline6">{{'section.noItem.title' | translate}}</p>    
            <p class="body2">{{'section.noItem.subtitle' | translate}}</p>
        </div>
    </div>
</div>

<div class="showItem" *ngIf="item">

    <div
        *ngIf="isMobile"
        (click)="back()">
        <div class="flexLeft">
            <mat-icon class="blackIcon">
                chevron_left
            </mat-icon>
            {{seccionNombre}}
        </div>
    </div>

    <div class="flexLeft">
        <mat-icon [svgIcon]="icon" class="sectionIcon"></mat-icon>
        <p id="itemNombre" class="headline6">
            {{item.itemNombre}}
        </p>

        <button 
            mat-stroked-button 
            color="primary"  
            id="download" 
            [class]="buttonClass"
            *ngIf="item && item.itemTipo == 'P'" 
            (click)="download()">
                <mat-icon>download</mat-icon>
                <span *ngIf="!isMobile">
                    {{'section.download' | translate}}
                </span>
        </button>
    </div>

    <iframe *ngIf="item && item.itemTipo == 'V'" 
        class="videoContainer"
        [src]="getVideoIframe(item.itemUbicacion)"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen>
    </iframe>

    <pdf-viewer *ngIf="item && item.itemTipo == 'P' && loadedPdf"
        [src]="pdfPath"
        [render-text]="false"
        class="pdfContainer"
        [original-size]="false"
        [fit-to-page]="true"
        [show-all]="true"
        [zoom-scale]="'page-fit'"
        [autoresize]="true"
        [zoom]="zoom">
    </pdf-viewer>

    <pdf-viewer *ngIf="item && item.itemTipo == 'P' && loadedPdf"
        [src]="pdfPath"
        [render-text]="false"
        class="pdfContainerSmall"
        [original-size]="false"
        [fit-to-page]="true"
        [show-all]="true"
        [zoom-scale]="'page-fit'"
        [autoresize]="true"
        [zoom]="zoom2">
    </pdf-viewer>
</div>
