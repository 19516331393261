import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SharedDataService } from '../../../../services/sharedData/shared-data.service';
import { MobileDetectorService } from '../../../../services/mobileDetector/mobile-detector.service';
import { Canal, EnviarMensaje, Message } from '../../../../api/interfaces/messages';
import { MessagesService } from '../../../../api/services/messages/messages.service';
import { SnackbarService } from 'src/app/utils/snackbar/snackbar.service';
import { RolService } from 'src/app/services/rol/rol.service';

@Component({
  selector: 'app-new-message',
  templateUrl: './new-message.component.html',
  styleUrls: ['./new-message.component.scss']
})
export class NewMessageComponent implements OnInit {

  @Output() newMessageEvent = new EventEmitter<Message>();
  @Output() classMessageEvent = new EventEmitter<boolean>();
  @Input('canal') canal: Canal;
  @Input('enable') enable?: boolean;
  @Input('isMassive') isMassive?: boolean;

  isStudent: boolean = false;

  waiting: boolean = false;

  isMobile: boolean = false;
  longitudMensaje : number = 0;
  cdkAutosize: number = 1;
  class: string = "inputsFilterLarge";
  messagesForm = new FormGroup({
    mensaje: new FormControl(),
  });
  
  constructor(
    private sharedData: SharedDataService,
    private mobileDetectorService: MobileDetectorService,
    private messagesService: MessagesService,
    private snack: SnackbarService,
    private rol: RolService
  ) { }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    this.longitudMensaje = parseInt(sessionStorage.getItem('longitudMensaje'),10);
    this.sharedData.currentMessage.subscribe(message => {
      if (message === "changeMessageOption") {
        this.clear();
      }
    });
    this.isStudent = this.rol.isStudent();
  }

  onSubmit() {
    const { mensaje } = this.messagesForm.getRawValue();
    if(mensaje.trim().length > 0 ) {

      const aux: Message = {
        mensaje: mensaje,
        person: sessionStorage.getItem('descripcionUsuario'),
        image: null,
        hasImage: false,
        fecha: new Date(),
        // idUsuario: parseInt(localStorage.getItem('idUsuario'),10),
        remitenteId: parseInt(localStorage.getItem('idUsuario'),10),
        canalId: this.canal.id,
      };
      const enviarMensaje: EnviarMensaje = {
        mensaje: mensaje
      };
      this.messagesService.postMensaje(this.canal.id, enviarMensaje).subscribe(response => {
        this.newMessageEvent.emit(aux);
        sessionStorage.setItem('ultimoMensaje',JSON.stringify(aux));
        if(this.isStudent) {
          this.sharedData.changeMessage("ultimoMensaje");
        } else {
          this.sharedData.changeMessage("ultimoMensajeAdmin");
        }
        this.waiting = false;
        this.clear();
      }, error => {
        console.error(error);
        this.snack.snackTranslate('snacks.error.generic');
      });
    }
  }

  clear() {
    this.messagesForm.patchValue({mensaje: null});
    this.changeSizeSmall();
  }

  changeSize() {
    this.cdkAutosize = 6;
    this.class = "inputsLarge transitionMessagesInput";
    this.classMessageEvent.emit(true);
  }

  changeSizeSmall() {
    if(!this.messagesForm.value.mensaje) {
      this.cdkAutosize = 1;
      this.isMassive ? this.class = "inputsFilterLarge" : this.class = "inputsFilterLarge transitionMessagesInput";
      this.classMessageEvent.emit(false);
    }
  }

}
