import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';

import {MatStepperModule} from '@angular/material/stepper';
import {MatDatepickerModule} from '@angular/material/datepicker';

import { StepperComponent } from '../stepper/stepper.component';
import { StepOneComponent } from '../step-one/step-one.component';
import { StepTwoComponent } from '../step-two/step-two.component';
import { StepThreeComponent } from '../step-three/step-three.component';
import { StepFourComponent } from '../step-four/step-four.component';
import { ThanksComponent } from '../thanks/thanks.component';
import { MaterialModule } from '../../../material/material.module';
import { ButtonsComponent } from '../buttons/buttons.component'; 
import { GlobalModule } from '../../global/modules/global.module';
import { AchievementsComponent  } from '../dynamics/achievements/achievements.component';
import { ObjetivesComponent } from '../dynamics/objetives/objetives.component';
import { InputsComponent } from '../dynamics/inputs/inputs.component';
import { InputsAchievementsComponent } from '../dynamics/inputs-achievements/inputs-achievements.component';
import { StepAchievementsObjetivesComponent } from '../step-achievements-objetives/step-achievements-objetives.component';

import { NgxMaskModule, IConfig } from 'ngx-mask'
import { FormsDinamicosModule } from '../../formsDinamicos/modules/formsDinamicos.module';
import { StepEndComponent } from '../step-end/step-end.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  declarations: [
    StepperComponent,
    StepOneComponent,
    StepTwoComponent,
    StepThreeComponent,
    StepFourComponent,
    ThanksComponent,
    ButtonsComponent,
    AchievementsComponent,
    ObjetivesComponent,
    InputsComponent,
    InputsAchievementsComponent,
    StepAchievementsObjetivesComponent,

    StepEndComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    GlobalModule,
    MatDatepickerModule,
    NgxMaskModule.forRoot(maskConfig),

    FormsDinamicosModule,

    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    })
  ],
  exports: [
    StepperComponent,
    MatStepperModule,
    AchievementsComponent,
    ObjetivesComponent
  ]
})
export class RegistrationModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}