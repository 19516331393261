import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { TrainingDays, Day, SelectedMode } from '../../../api/interfaces/register';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { RegisterService } from 'src/app/api/services/register/register.service';
import { FormValidatorService } from '../../../services/formValidator/form-validator.service';
import { SharedDataService } from '../../../services/sharedData/shared-data.service';
import { TrainingGroupEnum, TrainingTypeEnum } from '../../../enums/training-type';
import { MobileDetectorService } from '../../../services/mobileDetector/mobile-detector.service';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { StepperEnum } from 'src/app/enums/stepper';
import { ParametrosService } from 'src/app/api/services/parametros/parametros.service';
// import { FormsDinamicService } from '../../../api/services/formsDinamic/forms-dinamic.service';
// import { EntrenamientoGrupo, TipoEntrenamientoDinamico } from '../../../api/interfaces/formulario-dinamico';
// import { of } from 'rxjs';


@Component({
  selector: 'app-step-two',
  templateUrl: './step-two.component.html',
  styleUrls: ['./step-two.component.scss']
})
export class StepTwoComponent implements OnInit {

  @Input('cantidadPasosHabilitados') cantidadPasosHabilitados: number;
  @Input('posicion') posicion: number;

  @ViewChild('matRef') matRef: MatSelect;
  cardClass1="card";
  cardClass2="card";
  trainingDays: TrainingDays[] = [];
  valid: boolean = false;
  isMobile: boolean = false;

  equipoId: number = 0;

  //movido domingo a index 1 por cambios en backend
  days: Day[] = [
    {name:"Lunes", selected: false, index: 2, disabled: false},
    {name:"Martes", selected: false, index: 3, disabled: false},
    {name:"Miércoles", selected: false, index: 4, disabled: false},
    {name:"Jueves", selected: false, index: 5, disabled: false},
    {name:"Viernes", selected: false, index: 6, disabled: false},
    {name:"Sábado", selected: false, index: 7, disabled: false},
    {name:"Domingo", selected: false, index: 1, disabled: false},
  ];

  presencial: boolean = false;
  selected: boolean = false;

  modeForm = new FormGroup({
    mode: new FormControl(),
    trainingDays: new FormControl(),
    days: new FormControl([]),
  });

  card1Text: string = "";
  card2Text: string = ""

  // tipoEntrenamiento: TipoEntrenamientoDinamico[] = [];
  
  constructor(
    private registerService: RegisterService,
    private formValidatorService: FormValidatorService,
    private sharedData: SharedDataService,
    private mobileDetectorService: MobileDetectorService,
    private parametrosService: ParametrosService
    // private formsDinamicService: FormsDinamicService
  ) { }

  ngOnInit(): void {

    this.isMobile = this.mobileDetectorService.isMobile();
    this.modeForm.statusChanges
    .subscribe(val => this.formValidatorService.onFormValidation(val, "step2","mode"));

    this.registerService.getTrainingDays()
    .subscribe(response => {
      this.trainingDays = response;
      // remover no definido y online
      let index = this.trainingDays.findIndex(element => element.grupoDescripcion == TrainingGroupEnum.noDefinido);
      if(index != -1) {
        this.trainingDays.splice(index,1);
      }
      index = this.trainingDays.findIndex(element => element.grupoDescripcion == TrainingGroupEnum.online);
      if(index != -1) {
        this.trainingDays.splice(index,1);
      }
    },
      error => {
        console.error(error);
      });

      this.sharedData.currentMessage.subscribe(message => {
        if(message === StepperEnum.SAVE_STEP) {
          sessionStorage.setItem('mode', JSON.stringify(this.modeForm.value));
        }
    });

    this.getTextos();
    this.equipoId = parseInt(localStorage.getItem('equipoId'),10);
  }

  /**
   * Load textos to show in cards from backend. If there is no value, default text will be show
   */
  getTextos() {
    this.parametrosService.getParametros('StepTwoOnboarding').subscribe(response => {
      let index: number = response.findIndex(element => element.clave == "card1Text");
      this.card1Text = response[index].valor;
      index = response.findIndex(element => element.clave == "card2Text");
      this.card2Text = response[index].valor;
     },
       error => {
         console.error(error);
       })
  }

//   entrenamientoGruposMock: EntrenamientoGrupo[] = [
//     {
//         entrenamientoGrupoId: 10,
//         grupoDescripcion: "Domingo y Martes 15:00",
//         grupoHorario: "15:00",
//         activo: true,
//         entrenamientoGruposDias: [
//             {
//                 id: 15,
//                 entrenamientoGrupoId: 10,
//                 diaSemana: 1,
//                 creado: "2022-01-29T03:03:20Z",
//                 creadoPor: 1,
//                 ultimaModificacion: "2022-01-29T03:03:20Z",
//                 ultimaModificacionPor: 1
//             },
//             {
//                 id: 16,
//                 entrenamientoGrupoId: 10,
//                 diaSemana: 3,
//                 creado: "2022-01-29T03:03:20Z",
//                 creadoPor: 1,
//                 ultimaModificacion: "2022-01-29T03:03:20Z",
//                 ultimaModificacionPor: 1
//             }
//         ]
//     },
//     {
//         entrenamientoGrupoId: 3,
//         grupoDescripcion: "Lunes y miércoles 17:00",
//         grupoHorario: "15:00",
//         activo: true,
//         entrenamientoGruposDias: [
//             {
//                 id: 17,
//                 entrenamientoGrupoId: 10,
//                 diaSemana: 2,
//                 creado: "2022-01-29T03:03:20Z",
//                 creadoPor: 1,
//                 ultimaModificacion: "2022-01-29T03:03:20Z",
//                 ultimaModificacionPor: 1
//             },
//             {
//                 id: 18,
//                 entrenamientoGrupoId: 10,
//                 diaSemana: 4,
//                 creado: "2022-01-29T03:03:20Z",
//                 creadoPor: 1,
//                 ultimaModificacion: "2022-01-29T03:03:20Z",
//                 ultimaModificacionPor: 1
//             }
//         ]
//     }
// ];

// entrenamientoGruposMascotasMock: EntrenamientoGrupo[] = [
//   {
//       entrenamientoGrupoId: 15,
//       grupoDescripcion: "Domingo y Martes 15:00",
//       grupoHorario: "15:00",
//       activo: true,
//       entrenamientoGruposDias: [
//           {
//               id: 15,
//               entrenamientoGrupoId: 10,
//               diaSemana: 1,
//               creado: "2022-01-29T03:03:20Z",
//               creadoPor: 1,
//               ultimaModificacion: "2022-01-29T03:03:20Z",
//               ultimaModificacionPor: 1
//           },
//           {
//               id: 16,
//               entrenamientoGrupoId: 10,
//               diaSemana: 3,
//               creado: "2022-01-29T03:03:20Z",
//               creadoPor: 1,
//               ultimaModificacion: "2022-01-29T03:03:20Z",
//               ultimaModificacionPor: 1
//           }
//       ]
//   },
//   {
//       entrenamientoGrupoId: 1,
//       grupoDescripcion: "Lunes y miércoles 17:00",
//       grupoHorario: "15:00",
//       activo: true,
//       entrenamientoGruposDias: [
//           {
//               id: 17,
//               entrenamientoGrupoId: 10,
//               diaSemana: 2,
//               creado: "2022-01-29T03:03:20Z",
//               creadoPor: 1,
//               ultimaModificacion: "2022-01-29T03:03:20Z",
//               ultimaModificacionPor: 1
//           },
//           {
//               id: 18,
//               entrenamientoGrupoId: 10,
//               diaSemana: 4,
//               creado: "2022-01-29T03:03:20Z",
//               creadoPor: 1,
//               ultimaModificacion: "2022-01-29T03:03:20Z",
//               ultimaModificacionPor: 1
//           }
//       ]
//   }
// ];

//   tipoEntrenamientoMock : TipoEntrenamientoDinamico[] = [
//     {
//       titulo: "Presencial",
//       logo: "place",
//       texto: "Entrena en grupo con Nombre del entrenador en Nombre del lugar.",
//       tieneSelect: true,
//       entrenamientoGrupos: this.entrenamientoGruposMock,
//       clase: null
//     },
//     {
//       titulo: "Online",
//       logo: "public",
//       texto: "Entrena con Nombre del entrenador estés donde estés.",
//       tieneSelect: false,
//       clase: null
//     },
//     {
//       titulo: "Entrenamiento con mascotas",
//       logo: "pets",
//       texto: "Realizá tus ejercicios de forma habitual mientras un entrenador de perros nos acompaña y ejercita a tu can",
//       tieneSelect: true,
//       entrenamientoGrupos: this.entrenamientoGruposMascotasMock,
//       clase: null
//     },
//   ];

//   getTipoEntrenamiento() {
//     // this.formsDinamicService.getTipoEntrenamientoDinamico()
//     of(this.tipoEntrenamientoMock)
//     .subscribe(response => {
//       this.tipoEntrenamiento = response;
//       this.tipoEntrenamiento.forEach(element => {
//         element.clase = "card";
//       })
//     },
//       error => {
//         console.error(error);
//       });
//   }

// selectTipo(tipo: TipoEntrenamientoDinamico, i: number) {
  //   this.unselectAllDays();
  //   this.tipoEntrenamiento.forEach(element => {
  //     element.clase = "card"
  //   });
  //   this.tipoEntrenamiento[i].clase = "cardSelected";
  //   if(!tipo.tieneSelect) {
  //     this.modeForm.value.mode = 2; //id 2 corresponde a online
  //     this.modeForm.value.trainingDays = null;
  //   }
  //   this.changeValid();
  // }


  /**
   * deprecated 2670
   */
  enablePresencial() {
    this.presencial = true;
    this.selected = false;
    this.unselectAllDays();
    this.saveMode(0);
  }

  /**
   * deprecated 2670
   */
  disablePresencial() {
    this.presencial = false;
    this.selected = true;
    this.matRef.options.forEach((data: MatOption) => data.deselect());
    this.unselectAllDays();
    this.saveMode(1);
  }

  /**
   * Set every day select and disabled values as false
   */
  unselectAllDays() {
    this.days.forEach(day => {
      day.selected = false;
      day.disabled = false;
    });
    this.modeForm.patchValue({days: []});
  }

  /**
   * Change day selected value.
   * Day is saved in modeForm.days if selected
   * @param day 
   */
  saveDay(day: Day) {
    const position: number = this.days.findIndex(element => element.index == day.index);
    this.days[position].selected = !day.selected;
    let selected: any = [];
    this.days.forEach(day => {
      if(day.selected) {
        selected.push(day);
      }
    });
    this.modeForm.value.days = selected;
    if(this.modeForm.value.mode != TrainingTypeEnum.ONLINE) {
      this.changeValid();
    } else {
      if(!this.days.every(day => day.selected)) {
        this.valid = true;
      }
    }
    this.changeValid();
  }

  /**
   * Called from card with select, user choose option.
   * Patch form with choosed days.
   * Change day's disabled value so user can't choose them in days option
   * @param option TrainingDays. Example: "Lunes y Miércoles 19:30"
   */
  saveTrainingDay(option: TrainingDays) {

    this.selected = true;
    this.unselectAllDays();
    // todo: 2670 remover forEach, es reiterativo con metodo unselectAllDays
    this.days.forEach(day => {
      day.selected = false;
    });

    this.modeForm.value.trainingDays = option;
    this.modeForm.value.mode = option.grupoId;
    // todo: 2670 remover forEach, es reiterativo con metodo unselectAllDays
    this.days.map(day => {
      day.disabled = false;
    })
    this.days.map(day => {
      option.entrenamientoGruposDias.forEach(dayWeek => {
        if(day.index == dayWeek.diaSemana) {
          day.disabled = true;
        }
      })
    });
    this.valid = true;
  }

  /**
   * Change boolean that allow (or not) user to continue to next step
   * Is valid if user selected online and at least one day or user selected presencial
   */
  changeValid() {
    if( (this.modeForm.value.mode == 2 && this.modeForm.value.days.length > 0) || this.modeForm.value.trainingDays) {
      this.valid = true;
    } else {
      this.valid = false;
    }
  }

  /**
   * @deprecated 2670
   * Set training mode
   * Change css class: cardClass1 and cardClass2.
   * If mode is online set training days to null
   * @param mode number, presencial 0, online 1.
   */
  saveMode(mode: number) {
    this.modeForm.value.mode = mode;
    if(mode === TrainingTypeEnum.ONLINE) {
      this.cardClass2 = "cardSelected";
      this.cardClass1 = "card";
      this.modeForm.value.mode = 2; //id 2 corresponde a online
      this.modeForm.value.trainingDays = null;
      this.valid = false;
    } else {
      this.cardClass1 = "cardSelected";
      this.cardClass2 = "card";
      this.changeValid();
    }
  }

}
