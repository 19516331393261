import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SnackbarService } from 'src/app/utils/snackbar/snackbar.service';

@Injectable()
export class OfflineInterceptor implements HttpInterceptor {

  constructor(
    private snack: SnackbarService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if (err.status == 504) {
        this.snack.snackTranslateWithAction('connection.offline','X');
      } else {
        return throwError(err);
      }
    }));
  }
}
