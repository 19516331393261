<div *ngIf="!isMobile" [class]="navbarClass" (click)="navigate(option.path )">
    <div *ngIf="showCircle" class="circle"></div>
    <!-- <mat-icon *ngIf="showCircle"  active [class]="iconClass" matBadge="." matBadgeOverlap="true" matBadgeColor="warn">{{option.icon}}</mat-icon> -->
    <!-- <mat-icon *ngIf="!showCircle" active [class]="iconClass">{{option.icon}}</mat-icon> -->
    <mat-icon  active [class]="iconClass">{{option.icon}}</mat-icon>
    <div [class]="textClass" >{{option.name}}</div>
</div>

<button *ngIf="isMobile" (click)="navigate(option.path)" mat-menu-item>
    <span class="flexLeftBaseline">
        <span class="headline6Primary">{{option.name}}</span>
        <span *ngIf="showCircle" class="circle"></span>
    </span>
</button>
