import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';
import { Contact } from '../../../../api/interfaces/user';

@Component({
  selector: 'app-contact-data',
  templateUrl: './contact-data.component.html',
  styleUrls: ['./contact-data.component.scss']
})
export class ContactDataComponent implements OnInit {

  disable: boolean = true;
  @Input('showComplete') showComplete: boolean;
  
  @Input('estado') estado: string;
  @Input('contactData') contactData: Contact;
  @Output() onSubmitContactEvent = new EventEmitter<Contact>();
  contactForm = new FormGroup({
    email: new FormControl({ value: '', disabled: this.disable }),
    prefijoCelular: new FormControl({ value: '', disabled: this.disable }),
    numeroCelular: new FormControl({ value: '', disabled: this.disable }),
    prefijoEmergencia: new FormControl({ value: '', disabled: this.disable }),
    numeroEmergencia: new FormControl({ value: '', disabled: this.disable }),
  });

  constructor(
    private sharedData: SharedDataService
  ) { }

  ngOnInit(): void {
    this.patch();
    this.sharedData.currentMessage.subscribe(message => {
      if (message === "updateProfileData") {
        this.patch();
      }
    });
  }

  patch() {
    this.contactForm.patchValue({email: this.contactData.email});
    this.contactForm.patchValue({numeroCelular: this.contactData.numeroCelular});
    this.contactForm.patchValue({numeroEmergencia: this.contactData.numeroEmergencia});
    this.contactForm.patchValue({prefijoCelular: this.contactData.prefijoCelular});
    this.contactForm.patchValue({prefijoEmergencia: this.contactData.prefijoEmergencia});
  }

  edit() {
    this.disable = !this.disable;
    if(!this.disable) {
      this.contactForm.controls['email'].enable();
      this.contactForm.controls['numeroCelular'].enable();
      this.contactForm.controls['numeroEmergencia'].enable();
      this.contactForm.controls['prefijoCelular'].enable();
      this.contactForm.controls['prefijoEmergencia'].enable();
    } else {
      this.contactForm.controls['email'].disable();
      this.contactForm.controls['numeroCelular'].disable();
      this.contactForm.controls['numeroEmergencia'].disable();
      this.contactForm.controls['prefijoCelular'].disable();
      this.contactForm.controls['prefijoEmergencia'].disable();
    }
  }

  onSubmit() {
    this.edit();
    this.onSubmitContactEvent.emit(this.contactForm.value);
  }

}
