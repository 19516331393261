<div class="usefull" *ngIf="id && !showThanks">

    <br/>
    <mat-divider></mat-divider>
    <br/>

    <p class="headline6">{{'support.usefull.title' | translate}}</p>
    
    <div class="flexCenter">
        <button mat-stroked-button (click)="usefull(true)">{{'support.usefull.yes' | translate}}</button>
        <button mat-stroked-button (click)="usefull(false)">{{'support.usefull.no' | translate}}</button>
    </div>
</div>

<div class="usefull" *ngIf="!id && !showThanks">

     <br/>
    <mat-divider></mat-divider>
    <br/>

    <p class="subtitle3">{{'support.usefull.titleSearchEmpty' | translate}}</p>
    
    <div class="flexCenter">
        <button mat-stroked-button (click)="usefull(true)">{{'support.usefull.no' | translate}}</button>
        <button mat-stroked-button (click)="usefull(false)">{{'support.usefull.yes' | translate}}</button>
    </div>
</div>

<div class="usefull" *ngIf="showThanks">
    <br/>
    <mat-divider></mat-divider>
    <br/>
    <div class="flexCenter">
        <p class="subtitle3">{{'support.usefull.titleThanks' | translate}}</p>
    </div>
</div>