<div *ngFor="let group of groups">
    <app-groups-item 
        [group]="group" 
        [showNew]="showNew"
        [edit]="edit">
    </app-groups-item>
</div>

<app-paginator-complete
    *ngIf="done"
    [first]="first"
    [last]="last"
    [page]="page"
    [totalPages]="totalPages"
    [size]="size"
    [totalElements]="totalElements"
    (loadMoreEvent)="loadMore($event)">
</app-paginator-complete>