import { Component, OnInit } from '@angular/core';
import { MobileDetectorService } from 'src/app/services/mobileDetector/mobile-detector.service';

@Component({
  selector: 'app-profile-trainer-admin',
  templateUrl: './profile-trainer-admin.component.html',
  styleUrls: ['./profile-trainer-admin.component.scss']
})
export class ProfileTrainerAdminComponent implements OnInit {

  isAdminOrTrainer: boolean = true;
  isMobile: boolean = false;

  user_id: number;
  constructor(
    private mobileDetectorService: MobileDetectorService,
  ) { }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    // este es para ver el perfil de un entrenador como admin
    this.user_id = parseInt(sessionStorage.getItem('id_entrenador'),10);
    if(this.user_id) {
      sessionStorage.setItem('active', 'entrenadores');
    } else {
      sessionStorage.setItem('active','perfil');
    }
  }

}
