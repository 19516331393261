import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ItemSeccion, OrdenarItems, OrdenarSecciones, PostEditItem, PostEditSeccion, Seccion, SeccionConItems } from '../../interfaces/sections';

@Injectable({
  providedIn: 'root'
})
export class SeccionesService {

  private url = environment.apiUrl + '/';

  httpOptionsJson = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }),
    withCredentials: true,
  };

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json'
    }),
    withCredentials: true,
  };
  multipartOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'multipart/form-data'
    }),
    withCredentials: true,
  };
  constructor(private http: HttpClient) { }

  postSeccion(seccion: PostEditSeccion): Observable<PostEditSeccion> {
    return this.http.post<PostEditSeccion>(this.url + 'api/informacion/seccion', seccion, this.httpOptionsJson);
  }

  getDatosSeccion(seccionId: number): Observable<Seccion[]> {
    return this.http.get<Seccion[]>(this.url + `api/api/informacion/seccion?seccionId=${seccionId}`,  this.httpOptions);
  }

  /**
   * 
   * @param seccionId numero
   * @param seccion PostSeccion: { seccionNombre: string;
   * seccionTipo: string;
   * activo: boolean;
   * }
   * @returns 200, 406
   */
  editarSeccion(seccionId: number, seccion: PostEditSeccion): Observable<PostEditSeccion> {
    return this.http.put<PostEditSeccion>(this.url + `api/informacion/seccion?seccionId=${seccionId}`,  seccion, this.httpOptionsJson);
  }

  deleteSeccion(seccionId: number): Observable<any> {
    return this.http.delete<any>(this.url + `api/informacion/seccion?seccionId=${seccionId}`,  this.httpOptionsJson);
  }

  getDatosListado(seccionId: number): Observable<Seccion[]> {
    return this.http.get<Seccion[]>(this.url + `api/informacion/seccion?seccionId=${seccionId}`,  this.httpOptions);
  }

  /**
   * 
   * @param tipo N: nutricion / T: complemento
   * @returns SeccionConItems[] / []
   */
  getSeccionesPorTipo(tipo: string): Observable<SeccionConItems[]> {
    return this.http.get<SeccionConItems[]>(this.url + `api/informacion/listado?tipo=${tipo}`,  this.httpOptions);
  }

  getItem(itemId: number): Observable<ItemSeccion> {
    return this.http.get<ItemSeccion>(this.url + `api/informacion/item?itemId=1=${itemId}`,  this.httpOptions);
  }

  postItem(item: PostEditItem): Observable<any> {
    return this.http.post<any>(this.url + 'api/informacion/item', item, this.httpOptionsJson);
  }

  editarItem(itemId: number, item: PostEditItem): Observable<PostEditItem> {
    return this.http.put<PostEditItem>(this.url + `api/informacion/item?itemId=${itemId}`,  item, this.httpOptionsJson);
  }

  deleteItem(itemId: number): Observable<any> {
    return this.http.delete<any>(this.url + `api/informacion/item?itemId=${itemId}`,  this.httpOptionsJson);
  }

  ordenarSecciones(nuevoOrden: OrdenarSecciones[]): Observable<any> {
    return this.http.put<any>(this.url + 'api/informacion/seccion/ordenar', nuevoOrden, this.httpOptionsJson);
  }

  ordenarItems(nuevoOrden: OrdenarItems[], seccionId: number): Observable<any> {
    return this.http.put<any>(this.url + `api/informacion/item/ordenar?seccionId=${seccionId}`, nuevoOrden, this.httpOptionsJson);
  }

  patchFile(files, itemId: number): Observable<any> {
    const formData = new FormData();
    if (files && files.length > 0) {
      files.forEach(file => {
        formData.append('file', file);
      });
    }
    const multipartOptions = {
      withCredentials: true,
    };
    return this.http.patch<any>(this.url + `api/informacion/item/archivo?itemId=${itemId}`, formData, multipartOptions);
  }
  
}
