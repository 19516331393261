import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DetalleFormularioDinamico, FormularioDinamicoPregunta } from 'src/app/api/interfaces/formulario-dinamico';

@Component({
  selector: 'app-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss']
})
export class SelectInputComponent implements OnInit {

  @Input('requerido') requerido: boolean;
  @Input('opciones') opciones: DetalleFormularioDinamico[];
  @Input('multiple') multiple: boolean;
  @Input('pregunta') pregunta: FormularioDinamicoPregunta;

  @Output() opcionSeleccionadaEvent = new EventEmitter<DetalleFormularioDinamico>();

  optionsForm = new FormGroup({
   valor: new FormControl(),
  });

  constructor() { }

  ngOnInit(): void {
    this.optionsForm.statusChanges
    .subscribe(val => {
      const { valor } = this.optionsForm.getRawValue();
      this.opcionSeleccionadaEvent.emit(valor);
    });
  }

}
