<div class="dialogContainer400" *ngIf="loaded">

    <p class="headline5 flexLeft">{{fullName}}</p>

    <div class="scrollDialog">
        <p class="headline6">{{'planes.objetivesPopup.objetives' | translate}}</p>
        <div class="flexBeetwen" *ngFor="let objetive of objetives">
            <p *ngIf="objetive.activo" class="body2 marca">{{ objetive.fechaObjetivo? (objetive.fechaObjetivo | date: 'dd/MM/yyyy') : ('planes.objetivesPopup.noDate' | translate)}}</p>
            <p *ngIf="objetive.activo" class="body2 time" id="objDescripcion">{{objetive.objetivoDescripcion}}</p>
            </div>
        <div class="flexCenter">
            <p class="subtitle1" *ngIf="objetives.length == 0">{{'planes.objetivesPopup.emptyObjetives' | translate}}</p>
        </div>

        <br/>
        <mat-divider></mat-divider>
        <br/>

        <p class="headline6">{{'planes.objetivesPopup.achievements' | translate}}</p>
        <p class="subtitle3Blue" *ngIf=" allAchievements.route.length > 0" color="primary">
            {{'registration.stepThree.question8.ruta' | translate}}
        </p>
        
            
        <div class="flexBeetwen" *ngFor="let marca of allAchievements.route">
            <p  class="body2 marca"> {{marca.distanciaMarca ? marca.distanciaMarca : marca.distanciaTipo.distanciaDescripcion}}</p>
            <p  class="body2 time"> {{marca.time}}</p>
            <p  class="body2"> {{ marca.fechaMarca? (marca.fechaMarca | date: 'dd/MM/yyyy') : ('planes.objetivesPopup.noDate' | translate)}}</p>
        </div>

        <p class="subtitle3Blue" *ngIf=" allAchievements.pista.length > 0" color="primary">
            {{'registration.stepThree.question8.pista' | translate}}
        </p>
        
            
        <div class="flexBeetwen" *ngFor="let marca of allAchievements.pista">
            <p *ngIf="isMobile" class="body2 marca"> {{marca.distanciaMarca ? (marca.distanciaMarca | reduceText:28) : marca.distanciaTipo.distanciaDescripcion}}</p>
            <p *ngIf="!isMobile" class="body2 marca"> {{marca.distanciaMarca ? marca.distanciaMarca : marca.distanciaTipo.distanciaDescripcion}}</p>
            <p  class="body2 time"> {{marca.time}}</p>
            <p  class="body2"> {{ marca.fechaMarca? (marca.fechaMarca | date: 'dd/MM/yyyy') : ('planes.objetivesPopup.noDate' | translate)}}</p>
        </div>

        <p class="subtitle3Blue" *ngIf=" allAchievements.trail.length > 0" color="primary">
            {{'registration.stepThree.question8.trail' | translate}}
        </p>
        
        <div class="flexBeetwen" *ngFor="let marca of allAchievements.trail">
            <p *ngIf="isMobile" class="body2 marca"> {{marca.distanciaMarca ? (marca.distanciaMarca | reduceText:28) : marca.distanciaTipo.distanciaDescripcion}}</p>
            <p *ngIf="!isMobile" class="body2 marca"> {{marca.distanciaMarca ? marca.distanciaMarca : marca.distanciaTipo.distanciaDescripcion}}</p>
            <p  class="body2 time"> {{marca.time}}</p>
            <p  class="body2"> {{ marca.fechaMarca? (marca.fechaMarca | date: 'dd/MM/yyyy') : ('planes.objetivesPopup.noDate' | translate)}}</p>
        </div>
        
        <p class="subtitle3Blue" *ngIf=" allAchievements.ultra.length > 0" color="primary">
            {{'registration.stepThree.question8.ultra' | translate}}
        </p>
        <div class="flexBeetwen" *ngFor="let marca of allAchievements.ultra">
            <p *ngIf="isMobile" class="body2 marca"> {{marca.distanciaMarca ? (marca.distanciaMarca | reduceText:28) : marca.distanciaTipo.distanciaDescripcion}}</p>
            <p *ngIf="!isMobile" class="body2 marca"> {{marca.distanciaMarca ? marca.distanciaMarca : marca.distanciaTipo.distanciaDescripcion}}</p>
            <p  class="body2 time"> {{marca.time}}</p>
            <p  class="body2"> {{ marca.fechaMarca? (marca.fechaMarca | date: 'dd/MM/yyyy') : ('planes.objetivesPopup.noDate' | translate)}}</p>
        </div>

    </div>
    <div class="flexCenter">
        <p class="subtitle1" *ngIf="allAchievements.route.length == 0 && allAchievements.pista.length == 0 && allAchievements.ultra.length == 0 &&allAchievements.trail.length == 0">{{'planes.objetivesPopup.emptyAchievements' | translate}}</p>
    </div>
    <br/>

    <div class="flexEvenly">
        <button mat-dialog-close mat-raised-button color="accent">{{'planes.objetivesPopup.close' | translate}}</button>
    </div>
</div>
