import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-botones-cobro-domiciliacion',
  templateUrl: './botones-cobro-domiciliacion.component.html',
  styleUrls: ['./botones-cobro-domiciliacion.component.scss']
})
export class BotonesCobroDomiciliacionComponent implements OnInit {

  @Input('estado') estado: string;
  @Input('mostrarIconos') mostrarIconos: boolean;
  @Output() cobrarEvent = new EventEmitter<boolean>();
  

  constructor() { }

  ngOnInit(): void {
  }

  cobrar() {
    this.cobrarEvent.emit(true);
  }

}
