import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';

import { MaterialModule } from '../../../material/material.module';
// import { MainpageadminComponent } from '../students/mainpageadmin/mainpageadmin.component';
import { StudentRoutingModule } from './student-routing.module';
import { GlobalModule } from '../../global/modules/global.module'
// import { ProfileComponent } from '../profile/profile/profile.component'
import { ProfileModule } from '../../profile/profile.module';
import { StudentMyProfileComponent } from '../profile/student-my-profile/student-my-profile.component';
// import { StudentMyProfileComponent } from '../profile/student-my-profile/student-my-profile.component';
@NgModule({
  declarations: [
    // MainpageadminComponent,
    // ProfileComponent,
    StudentMyProfileComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    StudentRoutingModule,
    GlobalModule,
    ProfileModule,

    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    })
  ],
  exports: [
    StudentMyProfileComponent
  ]
})
export class StudentModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}