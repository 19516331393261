<div class="flexBeetwen" *ngIf="!isMobile">
    <p class="body2 w200-70"> {{group[1]}} </p>
    <p class="body2 w200-70"> {{group[5]}} alumnos</p>

    <div class="flexLeft w200-70">
        <div *ngIf="group[2]" class="flexLeft">
        <!-- <div *ngIf="group[2]" class="flexLeft w125"> -->
            <mat-icon
                class="mediumIcon"
                matTooltip="Estás admitiendo nuevos alumnos en este grupo"
                matTooltipClass="example-tooltip-red">
                info
            </mat-icon>
            <!-- <p class="body2"> {{'settings.groups.listCard.open' | translate}} </p> -->
        </div>
        
        <div *ngIf="!group[2]" class="flexLeft">
        <!-- <div *ngIf="!group[2]" class="flexLeft w125"> -->
            <mat-icon
                class="mediumIcon"
                matTooltip="No estás admitiendo nuevos alumnos en este grupo"
                matTooltipClass="example-tooltip-red">
                info
            </mat-icon>
        <!-- <p class="body2"> {{'settings.groups.listCard.close' | translate}} </p> -->
        </div>
        <mat-slide-toggle
            (click)="toggleSlide()"
            class="example-margin"
            color="primary"
            [(ngModel)]="isChecked">
        </mat-slide-toggle>

        <!-- <button mat-icon-button (click)="toggleEdit()" [disabled]="edit || showNew"> 
            <mat-icon class="iconMenu" *ngIf="!edit && !showNew">
                edit
            </mat-icon>
            <mat-icon class="iconMenu greyIcon" *ngIf="edit || showNew">
                edit
            </mat-icon>
        </button> -->
    </div>
</div>

<div class="flexBeetwen" *ngIf="isMobile">
    <div>
        <p class="body2"> {{group[1]}} </p>
        <p class="body3"> {{group[5]}} alumnos</p>
    </div>

   

    <div class="flexLeft w125">

        <div *ngIf="group[2]" class="flexLeft w125">
            <mat-icon
                class="mediumIcon"
                matTooltip="Estás admitiendo nuevos alumnos en este grupo"
                matTooltipClass="example-tooltip-red">
                info
            </mat-icon>
        </div>
        
        <div *ngIf="!group[2]" class="flexLeft w125">
            <mat-icon
                class="mediumIcon"
                matTooltip="No estás admitiendo nuevos alumnos en este grupo"
                matTooltipClass="example-tooltip-red">
                info
            </mat-icon>
        </div>
        <mat-slide-toggle
            (click)="toggleSlide()"
            class="example-margin"
            color="primary"
            [(ngModel)]="isChecked">
        </mat-slide-toggle>

        <!-- <mat-icon class="iconMenu" *ngIf="!edit && !showNew" (click)="toggleEdit()">
            edit
        </mat-icon>
        <mat-icon class="iconMenu greyIcon" *ngIf="edit || showNew">
            edit
        </mat-icon> -->
    </div>
</div>