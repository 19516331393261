import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HoursDayFixedService {

  constructor() { }

  /**
   * How to use:
   * ```
   * const today: string = this.hoursDayFixedService.getTodayFixedString();
   * // 2022-03-16T12:00:00.000+00:00
   * ```
   * @returns string - today with T12 hours format.
   */
  public getTodayFixedString(): string {
    let auxDate =  new Date().setHours(12,0,0);
    let aux = new Date(auxDate).toISOString();
    const auxDate2: string [] = aux.split('T');
    return aux = auxDate2[0] + "T12:00:00.000+00:00";
  }

  public getDateFixed(auxDate: string): string {
    const auxDate2: string [] = auxDate.split('T');
    const dateFixed: string = auxDate2[0] + "T12:00:00.000+00:00";
    return dateFixed;
  }

  public getDateWithConverted(aux: string): string {
    if(aux.length > 0) {
      const auxDate = new Date(aux).toISOString();
      return this.getDateFixed(auxDate);
    }
    return "";
  }

  public getSplittedDate(dateToSplit: string): string {
    let aux: string[] = new Date(dateToSplit).toISOString().split('T');
    return aux[0];
  }
}
