<html class="notranslate">
    <meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">
    <meta http-equiv="Pragma" content="no-cache">
    <meta http-equiv="Expires" content="0">
    <app-translate></app-translate>
    <span [class]="theme">
        <div  *ngIf="loading" class="spinnerOverlay" >
            <mat-spinner></mat-spinner>
        </div>
        <router-outlet></router-outlet>
    </span>
</html>

<!-- 
    opciones de temas:
    nr-frontend-theme (default)
    ley-primera-theme
    prueba-frontend-theme
    dark-theme
    otro-theme
    mixto-theme

 -->