import { Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { Expanded, Filters, UserComplete, UserTable } from '../../../../api/interfaces/user';
import { StatusEnum } from '../../../../enums/status';
import { TrainingTypeEnum } from '../../../../enums/training-type';
import { StudentService } from '../../../../services/admin/student/student.service';
import { SnackbarService } from '../../../../utils/snackbar/snackbar.service';
import { Role } from '../../../../enums/role';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from "@angular/material/icon";
import { MobileDetectorService } from '../../../../services/mobileDetector/mobile-detector.service';
import { SharedDataService } from '../../../../services/sharedData/shared-data.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { FeedbackDialogComponent } from 'src/app/components/plans/feedback-dialog/feedback-dialog.component';
import { RolService } from 'src/app/services/rol/rol.service';

@Component({
  selector: 'app-students-table',
  templateUrl: './students-table.component.html',
  styleUrls: ['./students-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class StudentsTableComponent implements OnInit {

  totalElements: number = 0;
  totalPages: number = 0;
  noStudents = false;
  firstLoad: boolean = true;
  amount: number = 1000;
  totalPerPage: number = 10;
  size: number = 10;
  page: number = 0;

  first: boolean = true;
  last: boolean = false;
  done: boolean = false;

  paginateRight: boolean = true;
  isTrainer: boolean = false;

  TrainingTypeEnum = TrainingTypeEnum;
  StatusEnum = StatusEnum;
  RoleEnum = Role;
  isMobile: boolean = false;
  filters: Filters = {
    nombre: "",
    apellido: "",
    nombreApellido: "",
    estado: "",
    entrenamientoTipoId: "",
    entrenamientoNivelId: "",
    entrenadorId: "",
    leido: ""
  };

  constructor(
    private router: Router,
    private snack: SnackbarService,
    private studentService: StudentService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private mobileDetectorService: MobileDetectorService,
    private sharedData: SharedDataService,
    public dialog: MatDialog,
    private rolService: RolService
  ) {
    this.matIconRegistry.addSvgIcon(
      `delete`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../assets/delete.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `cruz_medica`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../assets/cruz_medica.svg')
    );
  }
  displayedColumns: string[] = ['name', 'status', 'type', 'level', 'trainer', 'actions'];
  displayedColumnsMobile: string[] = ['name', 'status', 'actions'];
  studentData: UserTable[];
  loaded: boolean = false;

  ngOnInit(): void {
    this.isTrainer = this.rolService.isTrainer();
    this.isMobile = this.mobileDetectorService.isMobile();
    // ticket 2476
    const page: number = parseInt(sessionStorage.getItem('page'),10);
    if(page && !isNaN(page)) {
      this.page = page;
    }

    // todo: revisar merge rompio la llamada a load student cuando volvemos de un dia

    const fromDay: boolean = JSON.parse(sessionStorage.getItem('fromDay'));
    if(!fromDay && !this.isTrainer) {
      this.loadStudents();
    }

    this.sharedData.currentMessage.subscribe(message => {
      if (message === "reloadStudentData") {
        this.loadStudents();
      }
      if (message === "savePage") {
        sessionStorage.setItem('page',this.page.toString());
        this.isTrainer ? this.router.navigate(['/entrenador/feedback']) : this.router.navigate(['/admin/feedback']);
        this.sharedData.changeMessage("");
      }
    });

  }

  /**
   * load student data acording to filter criteria and call convertAndPushStudent
   */
  async loadStudents() {
    this.studentData = [];
    this.noStudents = false;
    this.loaded = false;
    this.setFilters(JSON.parse(localStorage.getItem('filters')));
    this.studentService.getStudentsWithPaginator(this.totalPerPage, this.page, this.filters)
      .subscribe(async response => {
        this.totalPages = response.totalPages;
        this.first = response.first;
        this.last = response.last;
        this.amount = response.totalElements;

        if (response.totalElements == 0) {
          this.noStudents = true;
          this.loaded = true;
          this.paginateRight = false;
        } else {
          this.firstLoad = false;
          for (const student of response.content) {
            await this.convertAndPushStudent(student);
          }
          this.loaded = true;
        }
      },
        error => {
          this.snack.snackTranslate('snacks.error.generic')
          console.error(error);
          this.loaded = true;
        });
  }

  navigate(student: UserTable) {
    // ticket 2476
    sessionStorage.setItem('page',this.page.toString());
    sessionStorage.setItem('id_usuario', JSON.stringify(student.user_id));
    if(this.isTrainer) {
      this.router.navigate(['/entrenador/perfil-alumno']);
    } else {
      this.router.navigate(['/admin/perfil']);
    }
  }

  /**
   * Receive a student with all the data (UserComplete interface) and convert it to StudentTable interface.
   * Check and expand the weeks if user is coming back from 'admin/feedback'
   * @param student 
   */
  async convertAndPushStudent(student: UserComplete) {
        let aux: UserTable = null;
        let avatarUrl: string = "";

        let fullName = student.nombre + " " + student.apellido + " ";
        if(student.segundoApellido) {
          fullName += student.segundoApellido;
        }

        let training = {
          entrenamientoTipo: student.entrenamientoTipo,
          grupo: student.grupo
        }
        let isExpanded: boolean = false;

        const expanded: Expanded[] = JSON.parse(sessionStorage.getItem('expanded'));
        const fromDay: boolean = JSON.parse(sessionStorage.getItem('fromDay'));
        if(expanded && fromDay) {
          if(expanded[0].user_id == student.alumnoId) {
            isExpanded = expanded[0].isExpanded;
            sessionStorage.removeItem('fromDay');
          }
        }
        aux = {
          name: student.nombre,
          lastName: student.apellido,
          email: student.email,
          training: training,
          avatarUrl: avatarUrl,
          user_id: student.alumnoId,
          fullName: fullName,
          status: student.estado,
          level: student.nivel,
          entrenador: student.entrenador,
          leido: student.leido,
          isExpanded: isExpanded,
          usuarioId: student.usuarioId,
          notificacionCertificadoMedico: student.notificacionCertificadoMedico
        }

        if (aux != null) {
          this.studentData.push(aux);
        }
  }

  loadMore(event) {
    this.page = event;
    this.studentData = [];
    this.loadStudents();
  }

  setFilters(filters) {
    if(filters) {
      this.filters.nombreApellido = filters.nombreApellido ? filters.nombreApellido : "";
      this.filters.estado = filters.status ? filters.status : "";
      this.filters.entrenamientoNivelId = filters.level ? filters.level : "";
      this.filters.entrenadorId = filters.trainer ? filters.trainer : "";
      this.filters.entrenamientoTipoId = filters.type ? filters.type : "";
      this.filters.leido = filters.leido != null ? filters.leido : "";
      //validacion para mostrar texto correcto si no hay alumnos con los filtros seleccionados
      if(filters.nombreApellido || filters.status || filters.level || filters.trainer || filters.type || filters.leido ) {
        this.firstLoad = false;
      }
    }
  }

  /**
   * Reset page and call set filters
   * @param filters 
   */
  filtersChange(filters) {
    this.setFilters(filters);
    if(filters.trainer && filters.trainer.id) {
      filters.trainer = filters.trainer.id;
    }
    if(filters.status && filters.status.estado) {
      filters.status = filters.status.estado;
    }
    localStorage.setItem('filters',JSON.stringify(filters));
    this.page = 0;
    this.sharedData.changeMessage("resetPage");
    this.setAndLoad(filters);
  }

  /**
   * set filters and call endpoint to load student data with the filter criteria
   * @param filters 
   */
  setAndLoad(filters) {
    this.setFilters(filters);
    if(filters.leido || filters.level || filters.nombreApellido || filters.status || filters.trainer || filters.type) {
      sessionStorage.setItem('filters',JSON.stringify(filters));
    }
    this.loadStudents();
  }

  changeFromCheckbox(student: UserTable) {
    this.studentService.setRead(student.user_id, !student.leido)
    .subscribe(response=> {
      this.loadStudents();
    },
      error => {
        this.snack.snackTranslate('snacks.error.generic');
        this.loadStudents();
        console.error(error);
      });
  }

  toggleExpanded(student: UserTable) {
    const aux: boolean = student.isExpanded;
    this.studentData.forEach(student => {
      student.isExpanded = false;
    })
    student.isExpanded = !aux;
    let expanded: Expanded [] = [];
    this.studentData.forEach(data => {
      if(data.isExpanded) {
        const aux: Expanded = {
          user_id: data.user_id,
          isExpanded: true,
        }
        expanded.push(aux);
      }
    });
    sessionStorage.setItem('expanded',JSON.stringify(expanded));
    sessionStorage.removeItem('openDay');
  }

  openDialogChat(student: UserTable) {
    const dialogRef = this.dialog.open(FeedbackDialogComponent, {
      data: {
        alumnoId: student.user_id
      }
    });
  }

}
