<mat-card class="cardGroup">
    <div class="flexBeetwen">
        <p class="headline6" *ngIf="!edit"> {{'settings.groups.newCard.title' | translate}} </p>
        <p class="headline6" *ngIf="edit"> {{'settings.groups.newCard.titleEdit' | translate}} </p>
        <div *ngIf="!isMobile">
            <button mat-stroked-button color="primary" class="primaryButton2" (click)="close()" > {{'settings.groups.newCard.cancel' | translate | uppercase}} </button>
            <button mat-raised-button color="accent" class="primaryButton2" (click)="onSubmit()" [disabled]="disableSubmit || !groupForm.valid"> {{'settings.groups.newCard.confirm' | translate}} </button>
        </div>
    </div>

    <p class="subtitle3"> {{'settings.groups.newCard.subtitle' | translate}}  </p>

    <div class="flexLeft">
        <div *ngFor="let day of days">
            <button *ngIf="!day.selected" 
                class="marginRigth tinyButton"
                [disabled]="day.disabled" 
                (click)="saveDay(day)"
                mat-stroked-button>
                <span *ngIf="!isMobile">
                    {{day.name}}
                </span>
                <span *ngIf="isMobile">
                    {{day.name.charAt(0)}}
                </span>
            </button>
            <button *ngIf="day.selected && !day.disabled" 
                class="selectedOption marginRigth tinyButton" 
                (click)="saveDay(day)"
                mat-raised-button>
                <span *ngIf="!isMobile">
                    {{day.name}}
                </span>
                <span *ngIf="isMobile">
                    {{day.name.charAt(0)}}
                </span>
            </button>

            <button *ngIf="day.selected && day.disabled" 
                class="selectedOption marginRigth tinyButton" 
                [disabled]="true"
                mat-raised-button>
                <span *ngIf="!isMobile">
                    {{day.name}}
                </span>
                <span *ngIf="isMobile">
                    {{day.name.charAt(0)}}
                </span>
            </button>
        </div>
    </div>
    <p class="subtitle3 cantidadDiasHint" *ngIf="cantidadDiasSeleccionados > 2"> {{'settings.groups.newCard.error' | translate}}  </p>

    <p class="subtitle3"> {{'settings.groups.newCard.hour' | translate}}  </p>
    <form [formGroup]="groupForm">
        <mat-form-field appearance="fill" class="inputFormLine">
            <mat-label>{{ 'settings.groups.newCard.hourInput' | translate}}</mat-label>
            <input 
                required 
                id="hourInput" 
                formControlName="hora" 
                type="text"  
                [mask]="timeMask"
                placeholder="HH:MM"
                matInput>
                <mat-hint *ngIf="groupForm.value.hora.length < 4x">El formato es HH:MM</mat-hint>
        </mat-form-field>

        <mat-form-field appearance="fill" class="inputFormLine">
            <mat-label>{{ 'settings.groups.newCard.name' | translate}}</mat-label>
            <input
                id="name" 
                formControlName="nombre" 
                type="text"
                matInput>
        </mat-form-field>

        <!-- <mat-form-field appearance="fill" class="inputFormLine">
            <mat-label>{{ 'settings.groups.newCard.amount' | translate}}</mat-label>
            <input required id="amountInput" formControlName="capacidad" type="number" min="1"
            matInput>
        </mat-form-field> -->

        <div *ngIf="isMobile">
            <button mat-stroked-button color="primary" class="primaryButton2" (click)="close()" > {{'settings.groups.newCard.cancel' | translate | uppercase}} </button>
            <button mat-raised-button color="accent" class="primaryButton2" (click)="onSubmit()" [disabled]="disableSubmit || !groupForm.valid"> {{'settings.groups.newCard.confirm' | translate}} </button>
        </div>
    </form>


    <div id="scroll"></div>
</mat-card>
