<body>
    <app-header [showLogout]="false"></app-header>
    <article>
        <div class="changePassword">
            <div class="center">

                <h1 *ngIf="!done" class="headline5">{{'requestChange.notDone.title' | translate}}</h1>
                <p *ngIf="!done" class="subtitle1">{{'requestChange.notDone.subtitle' | translate}}</p>
                <form [formGroup]="changeForm">
                    <div *ngIf="!done">
                        
                        <mat-form-field class="inputFullWidth2" appearance="fill">
                            <mat-icon matPrefix>email</mat-icon>
                            <mat-label>{{ 'forms.user.emailInput' | translate}}</mat-label>
                            <input id="emailInput" formControlName="email" type="email" matInput required>
                        </mat-form-field>
                        <button id="changeButton" class="primaryButton" color="accent" mat-raised-button (click)="onSubmit()" [disabled]="!changeForm.valid">{{'requestChange.notDone.button' | translate}}</button>
                    </div>
                    <div class="center" *ngIf="done">
                        <mat-icon class="welcomeIcon">email</mat-icon>
                        <h1 class="headline5">{{'requestChange.done.title' | translate}}</h1>
                        <p class="subtitle1">{{'requestChange.done.subtitle' | translate}}</p>
                    </div>
                    <button id="returnLoginButton" class="buttonFullWidth" color="primary" mat-button [routerLink]="['/login']">{{'changePassword.buttonBack' | translate}}</button>
                </form>
            </div>
            <app-oneinfoconsulting></app-oneinfoconsulting>
        </div>
        <!-- <app-footer></app-footer> -->
    </article>
</body>
