import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { EntrenamientoDiaFiltrado } from 'src/app/api/interfaces/user';
import { trainingDaysEnum } from 'src/app/enums/training-type';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from 'src/app/components/generic-dialog/generic-dialog.component';
import { PlansService } from '../../../api/services/plans/plans.service';
import { SnackbarService } from '../../../utils/snackbar/snackbar.service';
import { WeekService } from '../../../utils/week/week.service';
import { FormGroup, FormControl } from '@angular/forms';
import { FeedbackDialogComponent } from '../feedback-dialog/feedback-dialog.component';
import { DescansoEnum } from '../../../enums/training-type';
import { RoleString } from '../../../enums/role';
import { MobileDetectorService } from 'src/app/services/mobileDetector/mobile-detector.service';
import { ReloadDay } from 'src/app/api/interfaces/plans';
import { IsSameObjectService } from 'src/app/services/isSameObject/is-same-object.service';
import { Subscription } from 'rxjs';
import { RolService } from 'src/app/services/rol/rol.service';
@Component({
  selector: 'app-day',
  templateUrl: './day.component.html',
  styleUrls: ['./day.component.scss']
})
export class DayComponent implements OnInit, OnDestroy {

  private _day: EntrenamientoDiaFiltrado;

  @Output() reloadEvent = new EventEmitter<number>();
  @Output() changeActiveDayEvent = new EventEmitter<EntrenamientoDiaFiltrado>();
  @Output() openDialogEvent = new EventEmitter<any>();
  @Input('alumnoId') alumnoId: number;

  // @Input('day') day: EntrenamientoDiaFiltrado;
  @Input() set day(value: EntrenamientoDiaFiltrado) {
    this._day = value;
    this.assignDayClass();
  }

  get day(): EntrenamientoDiaFiltrado {
    return this._day;
  }

  @Input('dayText') dayText: string;
  @Input('index') index?: number;

  private sharedData$: Subscription;

  allowEdit: boolean = false;
  isStudent: boolean = false;
  isMobile: boolean = false;
  showEye: boolean = false;
  isAdmin: boolean = false;
  edit: boolean = false;

  dayInfoMobileClass: string = "dayInfoMobile";
  dayTitleTextClass: string = "dayTitleText";
  dayIconClass: string = "dayIcon"
  titleClass: string = "dayTitle";
  dayClass: string = "day";
  titulo: string = "";
  icono: string = "";

  opcion: number = 0;

  planForm = new FormGroup({
    actividad: new FormControl(),
  });

  constructor(
    private sharedData: SharedDataService,
    private plansService: PlansService,
    private weekService: WeekService,
    private activatedrouter: ActivatedRoute,
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    public dialog: MatDialog,
    private snack: SnackbarService,
    private mobileDetectorService: MobileDetectorService,
    private isSameObject: IsSameObjectService,
    private rolService: RolService,
  ) {
    this.matIconRegistry.addSvgIcon(
      `delete`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../assets/delete.svg')
    );
  }

  ngOnInit(): void {

    this.isMobile = this.mobileDetectorService.isMobile();
    this.isAdmin = this.rolService.isAdmin();
    this.isStudent = this.rolService.isStudent();
    const url = this.activatedrouter.snapshot.routeConfig.path;

    if(url == 'admin/alumnos' || url == 'entrenador/alumnos') {
      this.showEye = true;
    }
    this.assignDayClass();
    this.sharedData$ =  this.sharedData.currentMessage.subscribe(message => {
      if (message == "updateDays") {
        this.assignDayClass();
        this.setClass();
      }
      
      if (message == "reloadDay") {
        const reloadDay: ReloadDay = JSON.parse(sessionStorage.getItem('reloadDay'));
        const fechaDia = {
          dia: (new Date(this.day.fecha)).getDate(),
          mes: (new Date(this.day.fecha)).getMonth(),
          año: (new Date(this.day.fecha)).getFullYear(),
        }
        const fechaDelFeedback = {
          dia: (new Date(reloadDay.fechaFeedback)).getDate(),
          mes: (new Date(reloadDay.fechaFeedback)).getMonth(),
          año: (new Date(reloadDay.fechaFeedback)).getFullYear(),
        }
        const aux: boolean = this.isSameObject.isSame(fechaDelFeedback,fechaDia);
        if(aux) {
          this.day.feedbackOpcion  = reloadDay.feedbackOpcion;
          this.setClass();
          this.dayTitleTextClass  = "dayTitleText";
          this.dayIconClass  = "dayIcon";
        }
      }
      if (message === "semanaNoDisponible") {
        this.day.detalleDia = DescansoEnum.DESCANSO;
        this.icono = "";
        this.dayTitleTextClass = "dayTitleText";
        this.disabledClass();
      }
    });
    this.setClass();

    //set icon
    if (this.day.tipoDia == 'A') {
      this.icono = "check_circle";
    }
    if (this.day.tipoDia == 'P') {
      this.icono = "place";
    }
    this.planForm.patchValue({ actividad: this.day.detalleDia });
    if(this.day.seleccionado) {
      sessionStorage.setItem('detalle',this.day.detalleDia);
    }
  }

  controlFecha(): boolean {
    if(this.day.fecha == sessionStorage.getItem('controlFecha')) {
      return true;
    }
    return false;
  }

  selectDay(button: boolean) {
    if (this.isStudent) {
      if (this.day.detalleDia != DescansoEnum.DESCANSO || this.day.detalleDia != DescansoEnum.DESCANSO) {
        this.changeDay();
      } 
    } else {
      if (button) {

        sessionStorage.setItem('fecha', this.day.fecha);
        sessionStorage.setItem('fromDay','true');
        sessionStorage.setItem('id_usuario', this.alumnoId.toString());
        this.sharedData.changeMessage("savePage");
      } else {
        const url = this.activatedrouter.snapshot.routeConfig.path;
        if( (url != "admin/alumnos") || (url == "admin/alumnos" && this.isMobile)) {
          this.titulo = sessionStorage.getItem('titulo');
          this.changeDay();
        }
      }
    }
  }

  changeDay() {
    sessionStorage.setItem('detalle',this.day.detalleDia);
    this.day.seleccionado = !this.day.seleccionado;
    this.changeActiveDayEvent.emit(this.day);
    this.setClass();
  }

  assignDayClass() {
    if (this.day.seleccionado) {
      this.dayClass = "daySelected";
    } else {
      this.dayClass = "day";
    }
    if (this.day.tipoDia == trainingDaysEnum.DESCANSO) {
      this.dayClass = "dayDisabled"
    }
  }

  /**
   * change dayClass, titleClass and dayIconClass
   */
  setClass() {
    if(this.index && this._day.seleccionado) {
      document.documentElement.style.setProperty('--indexDayLeft', this.index.toString());
      if(this.index != 0) {
        this.dayInfoMobileClass = "dayInfoMobile marginDayMobile";
      } else {
        this.dayInfoMobileClass = "dayInfoMobile";
      }
    }
    if (this.day.tipoDia != trainingDaysEnum.DESCANSO || this.day.feedbackOpcion) {
      //puede que haya dias adicionales pero que sean de descanso
      //entonces tengo que validar asi...
      if (this.day.detalleDia != DescansoEnum.DESCANSO) {
        this.dayClass = "dayActividad";
        this.titleClass = "dayTitleActividad";
      }

      switch (this.day.feedbackOpcion) {
        case 1:
          this.titleClass = "dayTitleMal";
          if (!this.day.seleccionado) {
            this.dayClass = "dayMal";
          }
          break;
        case 2:
          this.titleClass = "dayTitleRegular";
          if (!this.day.seleccionado) {
            this.dayClass = "dayRegular"
          };
          break;
        case 3:
          this.titleClass = "dayTitleBien";
          if (!this.day.seleccionado) {
            this.dayClass = "dayBien";
          }
          break;
        case 4:
          this.titleClass = "dayTitleFenomenal";
          if (!this.day.seleccionado) {
            this.dayClass = "dayFenomenal";
          }
          break;
        default:
          if (this.dayClass == "dayActividad") {
            this.dayTitleTextClass = "dayTitleTextWhite";
            this.dayIconClass = "dayIconYellow";
          }
          break;
      }
    } else {
      if (this.day.detalleDia != DescansoEnum.DESCANSO) {
        this.dayClass = "dayActividad";
        this.titleClass = "dayTitleActividad";
      } else {
        this.disabledClass();
      }
    }

    if (this.day.tipoDia == undefined || this.dayClass == "dayTitleDisabled") {
      this.disabledClass();
    }

    if (this.day.seleccionado) {
      this.dayClass = "daySelected";
    }

    if (this.dayClass == "dayActividad") {
      this.dayTitleTextClass = "dayTitleTextWhite";
      this.dayIconClass = "dayIconYellow";
    }
  }

  disabledClass() {
    // el administrador tiene hover en dia de descanso
    // y el alumno no
    if (this.isAdmin) {
      this.titleClass = "dayTitleDisabled";
    } else {
      this.titleClass = "dayTitleDisabledNoHover";
    }
    this.dayClass = "dayDisabled";
  }

  delete() {
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      data: {
        title: "¿Desea eliminar el contenido del plan?",
        iconClass: "bigGreyIcon",
        text: "Si lo hace no podrá recuperar los datos y el alumno visualizará el día como “Descanso”",
        icon: "error_outlined",
        confirm: "ELIMINAR",
        cancel: "CANCELAR"
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // ticket 2480
        const fecha: string =  this.weekService.convertDate(this.day.fecha);

        this.plansService.deletePlanDia(this.alumnoId,fecha)
          .subscribe(response => {
            this.snack.snackTranslate('snacks.sucess.plan.dayDelete');
            // ticket 2480
            this.changeDay();
            this.saveAndReload();
            
          },
            error => {
              console.error(error);
              this.snack.snackTranslate('snacks.error.plan.delete');
            });
      }
    });
  }

  saveAndReload() {
    sessionStorage.setItem('openDay',JSON.stringify(this.day));
    this.sharedData.changeMessage("reloadWeekAfterDayChanged");
  }

  toggleEdit() {
    this.edit = !this.edit;
    if(this.edit) {
      this.planForm.patchValue({actividad: this.day.detalleDia});
    }
  }

  openFeedbackDialog() {
    const dialogRef = this.dialog.open(FeedbackDialogComponent, {
      data: {
        title: this.day.fecha,
        alumnoId: this.alumnoId
      }
    });
  }

  openObjetivesDialog() {
    this.openDialogEvent.emit();
  }

  onSubmit() {
    if (this.planForm.valid) {
      // ticket 2480
      const fecha: string =  this.weekService.convertDate(this.day.fecha);

      this.plansService.editDia(this.alumnoId, fecha, sessionStorage.getItem('titulo'), this.planForm.value.actividad)
        .subscribe(response => {
          // ticket 2480
          this.changeDay();
          this.snack.snackTranslate('snacks.sucess.plan.edit');
          this.toggleEdit();
          this.saveAndReload();
          // this.reloadEvent.emit(this.alumnoId);

        },
          error => {
            console.error(error);
            this.snack.snackTranslate('snacks.error.plan.edit');
            this.toggleEdit();
          });
    }
  }

  public ngOnDestroy(): void {
    this.sharedData$.unsubscribe();
  }

}
