import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { MobileDetectorService } from '../../../../services/mobileDetector/mobile-detector.service';
import { Filters, UserTable } from 'src/app/api/interfaces/user';
import { SnackbarService } from 'src/app/utils/snackbar/snackbar.service';
import { Canal, Participante } from 'src/app/api/interfaces/messages';
import { MessagesService } from 'src/app/api/services/messages/messages.service';
import { RolService } from 'src/app/services/rol/rol.service';
import { Subscription } from 'rxjs';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {

  private sharedData$: Subscription;

  @Output() searchCanalesEvent = new EventEmitter<Canal[]>();
  @Output() searchCanalesEmptyEvent = new EventEmitter<Canal[]>();
  @Output() moreStudentPagesAvailableEvent = new EventEmitter<boolean>();
  @Output() moreTrainersPagesAvailableEvent = new EventEmitter<boolean>();

  @Output() morePagesAvailableEvent = new EventEmitter<boolean>();

  isMobile: boolean = false;
  messagesForm = new FormGroup({
    nombreApellido: new FormControl(),
  });

  filters: Filters = {
    nombre: "",
    apellido: "",
    nombreApellido: "",
    estado: "",
    entrenamientoTipoId: "",
    entrenamientoNivelId: "",
    entrenadorId: "",
    leido: ""
  };

  cantidadCanalesSolicitados: number = 0;
  totalPerPage: number = 10;
  page: number = 0;

  loaded: boolean = true;

  participantesData: Participante []= [];
  studentData: UserTable[] = [];
  canales: Canal[] = [];

  isAdmin: boolean = false;

  moreStudentPagesAvailable: boolean = false;
  moreTrainersPagesAvailable: boolean = false;
  morePagesAvailable: boolean = false;

  
  constructor(
    private mobileDetectorService: MobileDetectorService,
    private snack: SnackbarService,
    private messagesService: MessagesService,
    private rolService: RolService,
    private sharedData: SharedDataService
  ) { }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    this.isAdmin = this.rolService.isAdmin();

    this.sharedData$ = this.sharedData.currentMessage.subscribe(message => {
      if (message === "scrolledDown") {
        if(this.messagesForm.value.nombreApellido) {
          this.page++;
          this.onSubmit();
        }
      }
    });

    this.messagesForm.statusChanges
    .subscribe(val => {
      this.page = 0;
      this.moreStudentPagesAvailableEvent.emit(true);
      this.moreTrainersPagesAvailableEvent.emit(true);
    });
  }

  /**
   * Search canal by canal's nombre.
   * Removed: student search, moreStudentPagesAvailableEvent, trainer search, moreTrainersPagesAvailableEvent, ticket 2126, getCanal method and createParticipante method
   */
  onSubmit() {
    if(this.loaded) {
      this.loaded = false;
      this.studentData = [];
      this.canales = [];
      this.cantidadCanalesSolicitados = 0;
      this.filters.nombreApellido = this.messagesForm.value.nombreApellido;
      this.participantesData = [];
      
      this.messagesService.searchCanalsPageable(this.filters.nombreApellido, this.page, this.totalPerPage)
      .subscribe(response => {
        
        if (response.totalElements == 0) {
          this.loaded = true;
          this.searchCanalesEmptyEvent.emit([]);
          this.morePagesAvailableEvent.emit(false);
        } else {
          response.content.forEach( canal => {
            this.canales.push(canal);
          });
          this.searchCanalesEvent.emit(this.canales);
          this.loaded = true;
          if(response.last) {
            this.morePagesAvailableEvent.emit(false);
          } else {
            this.morePagesAvailableEvent.emit(true);
          }
        }
      },
      error => {
        this.snack.snackTranslate('snacks.error.generic')
        console.error(error);
        this.loaded = true;
      });
    }
  }

  clear() {
    this.cantidadCanalesSolicitados = 0;
    this.messagesForm.patchValue({nombreApellido: ""});
    this.loaded = true;
    this.searchCanalesEvent.emit(null);
  }

  public ngOnDestroy(): void {
    this.sharedData$.unsubscribe();
  }

}
