<div class="background">

    <app-header></app-header>
    <app-navbar *ngIf="!isMobile"></app-navbar>
    <section>
        <div class="plans">
            <div class="flexBeetwenCenter">
                <p class="headline4" *ngIf="!isMobile">¡Hola, {{descripcionUsuario}}!</p>
                <p class="headline5" *ngIf="isMobile">¡Hola, {{descripcionUsuario}}!</p>
                
                <!-- <button mat-stroked-button class="primaryButton2" color="primary" (click)="downloadFile()">
                    <mat-icon>download</mat-icon>
                    <span *ngIf="!isMobile">{{'planes.download' | translate}}</span>
                    <span *ngIf="isMobile">{{'planes.downloadMobile' | translate}}</span>
                    
                </button>  -->
            </div>
            
            <!-- fixme: 2048 -->
            <app-week 
                *ngIf="ultimoDiaDisponible" 
                [alumnoId]="alumnoId" 
                [isAdmin]="false" 
                [ultimoDiaDisponible]="ultimoDiaDisponible">
            </app-week>
            <app-feedback 
                *ngIf="ultimoDiaDisponible"
                [canGiveFeedback]="true" 
                [ultimoDiaDisponible]="ultimoDiaDisponible">
            </app-feedback>
        </div>
    </section>
</div>