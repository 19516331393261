import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PostGrupo, EntrenamientoGruposDia, EditarGrupo, GruposHorario} from '../../../../../api/interfaces/groups';
import { Day } from '../../../../../api/interfaces/register';
import { GroupsService } from '../../../../../api/services/groups/groups.service';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';
import { SnackbarService } from 'src/app/utils/snackbar/snackbar.service';
import { MobileDetectorService } from 'src/app/services/mobileDetector/mobile-detector.service';
import { Masks } from 'src/app/enums/mask';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogThreeButtonsComponent } from 'src/app/components/generic-dialog-three-buttons/generic-dialog-three-buttons.component';
import { GenericDialogThreeButtonsData } from 'src/app/api/interfaces/dialogThreeButtons';

/**
 * Form: create a new group.
 */
@Component({
  selector: 'app-groups-new',
  templateUrl: './groups-new.component.html',
  styleUrls: ['./groups-new.component.scss']
})
export class GroupsNewComponent implements OnInit {

  @Output() toggleNewEvent = new EventEmitter<boolean>();
  @Output() toggleEditEvent = new EventEmitter<boolean>();
  @Input('edit') edit: boolean;
  @Input('showNew') showNew: boolean;

  firstChange: boolean = true;

  /**
   * Enviar el id del horario a editar
   */
  gruposHorarioEdit: GruposHorario[] = [];

  cantidadDiasSeleccionados: number = 0;

  days: Day[] = [
    {name:"Lunes", selected: false, index: 2, disabled: false},
    {name:"Martes", selected: false, index: 3, disabled: false},
    {name:"Miércoles", selected: false, index: 4, disabled: false},
    {name:"Jueves", selected: false, index: 5, disabled: false},
    {name:"Viernes", selected: false, index: 6, disabled: false},
    {name:"Sábado", selected: false, index: 7, disabled: false},
    {name:"Domingo", selected: false, index: 1, disabled: false},
  ];

  groupForm = new FormGroup({
    hora: new FormControl([Validators.required], Validators.minLength(4)),
    capacidad: new FormControl(20),
    nombre: new FormControl()
  });

  grupoId: number = -1;
  disable: boolean = false;
  isMobile: boolean = false;
  disableSubmit: boolean = true;
  timeMask: string = "";

  constructor(
    private groupsService: GroupsService,
    private snack: SnackbarService,
    private sharedData: SharedDataService,
    private mobileDetectorService: MobileDetectorService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.timeMask = Masks.GROUP_HOUR;
    this.changeValid();
    this.isMobile = this.mobileDetectorService.isMobile();
    document.getElementById("scroll").scrollIntoView(true);
    if(this.edit) {
      this.patchData();
    }
  }

  patchData() {
    this.grupoId = parseInt(sessionStorage.getItem('groupId'),10);
    this.groupsService.getGrupo(this.grupoId).subscribe( response => {
      this.groupForm.patchValue({nombre: response.grupoDescripcion});
      const horaSplit: string[] = response.grupoHorario.split(':');
      this.groupForm.patchValue({hora: horaSplit[0] + horaSplit[1]});
      response.gruposHorarios.forEach(element => {
        const index: number = this.days.findIndex(day => day.index == element.diaSemana);
        this.days[index].selected = true;
        this.gruposHorarioEdit.push(element);
        this.changeValid();
      });
    },
    error => {
      console.error(error);
      this.snack.openSnackBar(error.error.message);
    });
  }

  close() {
    window.scrollTo(0,0);
    this.edit ? this.toggleEdit() : this.toggleNew();
  }

  toggleNew() {
    this.toggleNewEvent.emit(false);
  }

  toggleEdit() {
    this.toggleEditEvent.emit(false);
  }

  onSubmit() {
    const daysSelected: Day[] = this.days.filter(day => day.selected);
    let horario: string = this.groupForm.value.hora;
    let horarioFinal: string = `${horario.charAt(0)}${horario.charAt(1)}:${horario.charAt(2)}${horario.charAt(3)}`;
    const nombreDefault: string = `${daysSelected[0].name} y ${daysSelected[1].name} ${horarioFinal}`;

    this.edit ? this.editGroup(nombreDefault, horarioFinal, daysSelected) : this.postNewGroup(nombreDefault, horarioFinal, daysSelected);
  }

  postNewGroup(nombreDefault: string, horarioFinal: string, daysSelected: Day[]) {

    let entrenamientoGruposDia: EntrenamientoGruposDia[] = [];
    daysSelected.forEach(day => {
      const aux: EntrenamientoGruposDia = {
        diaSemana: day.index
      }
      entrenamientoGruposDia.push(aux)
    });

    const { capacidad, nombre } = this.groupForm.getRawValue();
    const grupo: PostGrupo = {
      entrenamientoGrupoDescripcion: nombre ? nombre : nombreDefault,
      activo: true,
      horario: horarioFinal,
      capacidad: capacidad,
      entrenamientoGruposDia: entrenamientoGruposDia
    }
    this.groupsService.postGrupo(grupo).subscribe( response => {
      this.sharedData.changeMessage("updateGroups");
      this.snack.snackTranslate('snacks.sucess.groups.post');
      this.toggleNew();
    },
    error => {
      console.error(error);
      this.snack.openSnackBar(error.error.message);
    });
  }

  editGroup(nombreDefault: string, horarioFinal: string, daysSelected: Day[]) {

    const data: GenericDialogThreeButtonsData = {
      title: "¿Desea guardar los cambios?",
      iconClass: "bigGreyIcon",
      text: null,
      icon: "error_outline",
      cancel: "CANCELAR",
      confirm: "GUARDAR",
      confirmAndAction: "GUARDAR Y NOTIFICAR",
      actionTrue: "true",
      actionFalse: "false"
    }
    const dialogRef = this.dialog.open(GenericDialogThreeButtonsComponent, {
     data: data
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let entrenamientoGruposDia: EntrenamientoGruposDia[] = [];
        daysSelected.forEach( (day, i) => {
          const aux: EntrenamientoGruposDia = {
            id: this.gruposHorarioEdit[i].horarioId,
            diaSemana: day.index
          }
          entrenamientoGruposDia.push(aux);
        });
    
        const { capacidad, nombre } = this.groupForm.getRawValue();
        
        const grupo: EditarGrupo = {
          entrenamientoGrupoDescripcion: nombre ? nombre : nombreDefault,
          horario: horarioFinal,
          capacidad: capacidad,
          entrenamientoGruposDia: entrenamientoGruposDia
        }

        const enviarMensajeString: string = sessionStorage.getItem("action")
        const enviarMensaje: boolean = enviarMensajeString == "true";
        this.groupsService.editar(this.grupoId, grupo, enviarMensaje).subscribe( response => {
          this.sharedData.changeMessage("updateGroups");
          enviarMensaje ?this.snack.snackTranslate('snacks.sucess.groups.editAndSendMessage') : this.snack.snackTranslate('snacks.sucess.groups.edit');
          sessionStorage.removeItem("action");
          this.toggleEdit();
        },
        error => {
          console.error(error);
          this.snack.openSnackBar(error.error.message);
        });
      }
    }); 
  }

  changeValid() {
    const aux: Day[] = this.days.filter(day => day.selected == true);
    this.cantidadDiasSeleccionados = aux.length;
    if(this.cantidadDiasSeleccionados == 2 ) {
      this.disableSubmit = false;
    } else {
      this.disableSubmit = true;
    }
  }

  saveDay(day: Day) {
    const position: number = this.days.findIndex(element => element.index == day.index);
    this.days[position].selected = !day.selected;
    let selected: any = [];
    this.days.forEach(day => {
      if(day.selected) {
        selected.push(day);
      }
    });
    this.changeValid();
  }

}