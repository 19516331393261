<mat-divider></mat-divider>

<mat-dialog-content class="flexBeetwen">

    
    <button color="warn" mat-button class="primaryButton" (click)="toggleBugMode(true)">
        <mat-icon class="redIcon small-MediumIcon">
            bug_report
        </mat-icon>
        {{ 'support.select.bug' | translate }}
    </button>

    <button color="primary" mat-button class="primaryButton" (click)="toggleBugMode(false)">
        <mat-icon class="primaryIcon small-MediumIcon">
            message
        </mat-icon>
        {{ 'support.select.suggestion' | translate }}
    </button>
</mat-dialog-content>