import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-oneinfoconsulting',
  templateUrl: './oneinfoconsulting.component.html',
  styleUrls: ['./oneinfoconsulting.component.scss']
})
export class OneinfoconsultingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
