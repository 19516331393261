<div class="responsiveFlex">
        <mat-card>
                <p class="headline6">{{ 'forms.trainers.formacion' | translate}}</p>
                <div *ngIf="formacion.length == 0">
                        <p class="subtitle2">{{'forms.trainers.emptyFormacion' | translate}}</p>
                </div>
                <div *ngFor="let element of formacion; let i = index">
                        <app-formacion-inputs 
                                [formacion]="element" 
                                [i]="i" 
                                (removeFormacionEvent)="removeFormacion($event)"
                                [entrenadorId]="entrenadorId">
                        </app-formacion-inputs>
                </div>
                
                <button  
                        [disabled]="disable" 
                        (click)="addFormacion()" 
                        mat-button 
                        color="primary" 
                        id="addButton">
                        + {{ 'forms.trainers.addFormacion' | translate}}
                </button>
        </mat-card>
</div>
