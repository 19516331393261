import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { StatusEnum } from '../../../enums/status';
import { SnackbarService } from '../../../utils/snackbar/snackbar.service';
import { MobileDetectorService } from '../../../services/mobileDetector/mobile-detector.service';
import { PlansService } from '../../../api/services/plans/plans.service';
import { Plan, PlanTable } from '../../../api/interfaces/plans';
import { GenericDialogComponent } from 'src/app/components/generic-dialog/generic-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Level } from '../../../api/interfaces/user';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from "@angular/material/icon";

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {

  @Output() deleteEvent = new EventEmitter<any>();

  @Input('current') current: boolean;
  @Input('schedule') schedule: boolean;
  @Input('planData') planData: PlanTable[];
  @Input('semana') semana?: string;

  isMobile: boolean = false;
  levels: Level[] = [];

  constructor(
    private router: Router,
    private snack: SnackbarService,
    private mobileDetectorService: MobileDetectorService,
    private plansService: PlansService,
    public dialog: MatDialog,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) { 
    this.matIconRegistry.addSvgIcon(
      `delete`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/delete.svg')
    );
  }

  displayedColumns: string[] = ['level', 'status', 'date', 'created', 'actions'];
  displayedColumnsMobile: string[] = ['name', 'status', 'actions'];
  

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    if(this.schedule) {
      const aux: PlanTable[] = [];
      this.planData.forEach(semana => {
        const semanaString= new Date(semana.planSemana).toLocaleDateString();
        if(this.semana == semanaString) {
          aux.push(semana);
        }
      });
      this.planData = aux;

    }
  }

  navigate(plan: Plan) {
    sessionStorage.setItem('planId', JSON.stringify(plan.planId));
    this.router.navigate(['/planes/editar']);
  }

  deletePlan(planId: number) {


    const dialogRef = this.dialog.open(GenericDialogComponent, {
      data: {
        title: "¿Desea eliminar el plan de entrenamiento?",
        iconClass: "bigGreyIcon",
        text: "Si lo hace no podrá recuperar los datos.",
        icon: "cancel_fill",
        confirm: "ELIMINAR",
        cancel: "CANCELAR"
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.plansService.getPlanId(planId).subscribe(response => {
          let planEditable: Plan = response;
          planEditable.activo = false;
          planEditable.planDetalles.forEach(detalle => detalle.activo = false);
          planEditable.planDetalle = planEditable.planDetalles;  //backend devuelve en plural y lo pide en singular...
          this.plansService.editDetelePlan(planId, planEditable).subscribe(response => {
            this.snack.snackTranslate('snacks.sucess.plan.delete');
            //trigger actualizacion de tablas de planes
            this.deleteEvent.emit();
          },
            error => {
              console.error(error);
              this.snack.snackTranslate('snacks.error.plan.delete');
            });
        },
          error => {
            console.error(error);
            this.snack.snackTranslate('snacks.error.plan.delete');
          });
      }
    });
  }

}

