import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth.guard';
import { RoleString } from 'src/app/enums/role';
import { MainGenericComponent } from '../generic/main-generic/main-generic.component';
import { MainHelpComponent } from '../help/main-help/main-help.component';


const routes: Routes = [
  {
    path: 'complementario',
    component: MainGenericComponent,
    canActivate: [AuthGuard],
    data: { roles: [RoleString.ADMIN, RoleString.STUDENT, RoleString.TRAINER] }
  },
  {
    path: 'nutricion',
    component: MainGenericComponent,
    canActivate: [AuthGuard],
    data: { roles: [RoleString.ADMIN, RoleString.STUDENT, RoleString.TRAINER] }
  },
  {
    path: 'ayuda',
    component: MainHelpComponent,
    canActivate: [AuthGuard],
    data: { roles: [RoleString.STUDENT, RoleString.TRAINER] }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class SectionsRoutingModule { }