import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SnackbarService } from '../../../../utils/snackbar/snackbar.service';
import { SharedDataService } from '../../../../services/sharedData/shared-data.service';
import { TrainersService } from '../../../../api/services/trainers/trainers.service';
import { NewTrainer, Trainer } from '../../../../api/interfaces/trainers';
import { StudentService } from '../../../../services/admin/student/student.service';
import { InsertUser } from '../../../../api/interfaces/register';

@Component({
  selector: 'app-create-trainer',
  templateUrl: './create-trainer.component.html',
  styleUrls: ['./create-trainer.component.scss']
})
export class CreateTrainerComponent implements OnInit {

  createTrainer: boolean = true;
  success: boolean = false;
  waiting: boolean = false;
  trainerForm = new FormGroup({
    entrenadorEmail: new FormControl(),
    entrenadorNombre: new FormControl(),
    entrenadorApellido: new FormControl(),
    segundoApellido: new FormControl()
  });

  constructor(
    public dialog: MatDialog,
    private trainerService: TrainersService,
    private snack: SnackbarService,
    private sharedData: SharedDataService,
    private studentService: StudentService,
  ) { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.waiting = true;
    const { entrenadorEmail, entrenadorNombre, entrenadorApellido, segundoApellido } = this.trainerForm.getRawValue();
    const newTrainer: NewTrainer = {
      usuario: entrenadorEmail,
      descripcionUsuario: entrenadorNombre + " " + entrenadorApellido,
    }
    const userId: number = parseInt(localStorage.getItem('idUsuario'),10);
    let aux1: InsertUser = this.trainerService.completeUserData(newTrainer, userId);

    this.studentService.insertUser(aux1).subscribe(usuarioId => {

      const aux2 : Trainer = {
        entrenadorNombre: entrenadorNombre,
        entrenadorApellido: entrenadorApellido,
        segundoApellido: segundoApellido ? segundoApellido : '',
        entrenadorEmail: entrenadorEmail,
        fechaNacimiento: '1900-01-01T00:00:00Z',
        documentoTipoId: null,
        numeroDocumento: null,
        pais: null,
        residencia: null,
        prefijoCelular: null,
        numeroCelular: null,
        estado: 'A',
        usuarioId: usuarioId,
        activo: true,
      };

      this.trainerService.createTrainer(aux2).subscribe(response => {
        this.success = true;
        this.createTrainer = !this.createTrainer;
        this.sharedData.changeMessage("reloadTrainerData");
        this.waiting = false;
      },
        error => {
          if (error.status === 409) {
            this.snack.snackTranslate('snacks.error.duplicatedMail');
          } else {
            this.snack.snackTranslate('snacks.error.generic');
          }
          console.error(error);
          this.success = false;
          this.waiting = false;
        });
    },
      error => {
        console.error(error);
        this.snack.openSnackBar(error.error.message);
        this.success = false;
        this.waiting = false;
      });
  }

}