<body>
    <app-header [showLogout]="false"></app-header>
    <article>
        <div class="login">
            <div class="center">

                <h1 class="headline5">{{ 'login.title' | translate }}</h1>
                <form [formGroup]="userForm">
                    <mat-form-field class="inputFullWidth2" appearance="fill">
                        <mat-icon matPrefix>email</mat-icon>
                        <mat-label>{{ 'forms.user.emailInput' | translate}}</mat-label>
                        <input id="emailInput" formControlName="email" type="email" matInput required>
                    </mat-form-field>
                    <br />
                    <mat-form-field (keyup.enter)="onSubmit()" class="inputFullWidth2" appearance="fill">
                        <mat-icon matPrefix>vpn_key</mat-icon>
                        <mat-label>{{ 'forms.user.passwordInput' | translate}}</mat-label>
                        <input id="passwordInput" formControlName="password" matInput [type]="hide ? 'password' : 'text'" required>
                        <button mat-icon-button matSuffix (mousedown)="hide = !hide" [attr.aria-pressed]="hide">
                            <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                        </button>
                    </mat-form-field>
                    <br />
                    <div class="flexBeetwen">
                        <!-- <mat-checkbox color="primary" id="rememberMe">{{'login.rememberMe' | translate}}</mat-checkbox> -->
                        <!-- <button id="reset" class="textButton" mat-button> -->
                            <a class="overline" [routerLink]="['/request-change']">{{'login.recoverPassword' | translate}}</a>
                        <!-- </button> -->
                        <!-- <a id="reset" [routerLink]="['/request-change']">{{'login.recoverPassword' | translate}}</a> -->
                    </div>
                    <button id="loginButton" (click)="onSubmit()" class="primaryButton" color="accent" mat-raised-button  [disabled]="!userForm.valid">{{'login.loginButton' | translate}}</button>
                </form>
                <!-- <p class="body3">{{'login.new' | translate}} <a id="meeting" target="_blank" href="https://www.vgrunning.com/contacto/">{{'login.here' | translate}}</a></p> -->
            </div>
            <app-oneinfoconsulting></app-oneinfoconsulting>
        </div>
        <app-footer></app-footer>
    </article>
</body>
