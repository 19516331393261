export enum Role {
    ADMIN = 1,
    STUDENT = 2,
    TRAINER = 3
}

export enum RoleString {
    ADMIN = "ADMIN",
    STUDENT = "ALUMNO",
    TRAINER = "ENTRENADOR"
}