import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ChangePasswordComponent } from './change-password/change-password.component';
import { LoginComponent } from './login/login.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { RequestChangeComponent } from './request-change/request-change.component';
import { ChangeForgottenPasswordComponent } from './change-forgotten-password/change-forgotten-password.component';
import { AuthGuard } from 'src/app/auth.guard';
import { RoleString } from 'src/app/enums/role';
import { TerminosCondicionesComponent } from './terminos-condiciones/terminos-condiciones.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    component: LoginComponent
  },
  {
    path: 'welcome',
    component: WelcomeComponent,
    canActivate: [AuthGuard],
    data: { roles: [RoleString.STUDENT] }
  },
  {
    path: 'change-password/:id',
    component: ChangePasswordComponent
  },
  {
    path: 'request-change',
    component: RequestChangeComponent
  },
  {
    path: 'forget-password/:id',
    component: ChangeForgottenPasswordComponent
  },
  {
    path: 'terminos-condiciones',
    component: TerminosCondicionesComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule { }