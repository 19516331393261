<div [class]="class">
    <div class="body5">
        {{message.fecha | date: 'dd/MM/YYY - HH:mm'}}
    </div>
    <p class="headline6">{{message.titulo}}</p>
    <div class="flexLeft">
        <!-- <div *ngFor="let etiqueta of message.etiquetas" class="flexLeft">
            <mat-icon *ngIf="etiqueta == 'PRESENCIAL'">place</mat-icon>
            <mat-icon *ngIf="etiqueta == 'ONLINE'">public</mat-icon>
            <mat-icon *ngIf="etiqueta == 'ENTRENADORES'">sports</mat-icon>
            <p class="tableTitle">{{etiqueta}}</p>
        </div> -->
        <div *ngIf="isAdmin">
            <!-- <mat-icon class="massiveIcon">place</mat-icon><p class="tableTitle">ONLINE</p>
            <mat-icon class="massiveIcon">public</mat-icon><p class="tableTitle">PRESENCIAL</p> -->
            
            
            <!-- <mat-icon *ngIf="etiqueta == 'ENTRENADORES'">sports</mat-icon> -->
            <!-- <p class="tableTitle">TODOS LOS ALUMNOS</p> -->
        </div>
    </div>
    <p class="body3"> {{message.mensaje}} </p>

    <button mat-button color="primary" *ngIf="isAdmin" (click)="toggleDestinatarios()">
        <span class="flexLeft">

            <span class="body2 primaryHint">{{'messages.massive.destinatarios.show' | translate}}</span>
            <mat-icon *ngIf="!showDestinatarios">expand_more</mat-icon>
            <mat-icon *ngIf="showDestinatarios">expand_less</mat-icon>           
        </span>
    </button>

    <div *ngIf="showDestinatarios" class="flexBeetwen">

        <div *ngIf="typesToShow.length > 0">
            <span class="body4">
                {{'messages.massive.destinatarios.type' | translate}}
            </span>
            
                <div class="body5 left" *ngFor="let element of typesToShow">
                    <li>
                        {{element.entrenamientoTipoDescripcion}}
                    </li>
                </div>
            
        </div>

        <div *ngIf="trainingDaysToShow.length > 0">
            <span class="body4">
                {{'messages.massive.destinatarios.group' | translate}}
            </span>
            
                <div class="body5 left" *ngFor="let element of trainingDaysToShow">
                    <li>
                        {{element.grupoDescripcion}}
                    </li>
                </div>
            
        </div>

        <div *ngIf="levelsToShow.length > 0">
            <span class="body4">
                {{'messages.massive.destinatarios.level' | translate}}
            </span>
            
                <div class="body5 left" *ngFor="let level of levelsToShow">
                    <li>{{level.entrenamientoNivelDescripcion}} </li>
                </div>
            
        </div>
    </div>
    <!-- <div *ngIf="message.vacaciones && !isAdmin" class="flexLeft">
        <button *ngIf="studentReply == ''" mat-stroked-button color="primary"  class="primaryButton2" (click)="choose('no')"> NO</button>
        <button *ngIf="studentReply == ''" mat-raised-button color="accent" class="primaryButton2" (click)="choose('yes')"> SI</button>
        
        <p class="subtitle1Italic" *ngIf="studentReply == 'no'"> {{'messages.holidaysReply.no' | translate}} </p>
        <p class="subtitle1Italic" *ngIf="studentReply == 'yes'"> {{'messages.holidaysReply.yes' | translate}} </p>
        <p class="subtitle1Italic" *ngIf="studentReply == 'noReply'"> {{'messages.holidaysReply.noReply' | translate}} </p>
    </div>
    <div *ngIf="message.vacaciones && isAdmin" class="flexLeft">

        <button mat-stroked-button id="download" color="primary" class="primaryButton2" (click)="download()">
            {{'messages.massive.holidays.download' | translate}}
            <mat-icon>
                download_outlined
            </mat-icon>
        </button>
    </div> -->
    <input *ngIf="autofocus" type="text" autofocus class="hiddenInput" tabindex="-1">
    <div *ngIf="autofocus" tabindex="1" #lastChat></div>
</div>