import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SpinnerService } from '../../services/spinner/spinner.service';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {

  constructor(
    private spinnerService: SpinnerService
  ) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    
    if( this.showSpinner(request)) {
      this.spinnerService.updateShowSpinner(true);
    }

    return next.handle(request)
      .pipe(catchError((err) => {
        this.spinnerService.updateShowSpinner(false);

        return throwError(err);
      }))
      .pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
        if (evt instanceof HttpResponse) {
          this.spinnerService.updateShowSpinner(false);
        }
        return evt;
      }));
  }

  /**
   * Prevent spinner to show if request url is inside of this function.
   * @example
   * With method and url
   * ```
   * (request.url.toString().includes('blob') && request.method == 'GET');
   * ```
   * @example
   * Only url
   * ```
   * (request.url.toString().includes('nodo'));
   * ```
   * @param request - request data from interceptor
   * @returns Boolean, current endpoint is allowed or not.
   */
   showSpinner(request: HttpRequest<unknown>): boolean {
    const loadingAvatar: boolean = (request.url.toString().includes('avatar') && request.method == 'GET');
    const blobAvatar: boolean = (request.url.toString().includes('blob') && request.method == 'GET');
    const nodos: boolean = (request.url.toString().includes('nodo'));
    const endpointsAllowed: boolean = !loadingAvatar && !blobAvatar && !nodos;
    return endpointsAllowed;
  }
}
