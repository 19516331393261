import { Component, OnInit, Inject } from '@angular/core';
import { GalleryFilesService } from 'src/app/api/services/gallery-files/gallery-files.service';
import { ArchivosEnum } from 'src/app/enums/archivos';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';
import { SnackbarService } from 'src/app/utils/snackbar/snackbar.service';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { AlertTriggerService } from 'src/app/api/services/alertTrigger/alert-trigger.service';
import { HoursDayFixedService } from 'src/app/utils/hoursDayFixed/hours-day-fixed.service';
import { NotificacionAlarmaTipo } from 'src/app/enums/notificaciones-alarmas';

@Component({
  selector: 'app-gallery-element-new',
  templateUrl: './gallery-element-new.component.html',
  styleUrls: ['./gallery-element-new.component.scss']
})
export class GalleryElementNewComponent implements OnInit {

  maxDate: Date = new Date();

  waiting: boolean = false;
  edit: boolean = false;

  newForm = new FormGroup({
    itemNombre: new FormControl(),
    emision: new FormControl(),
    tipoArchivo: new FormControl()
  });

  archivos: File[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private galleryFilesService: GalleryFilesService,
    private snack: SnackbarService,
    private sharedData: SharedDataService,
    private dialogRef: MatDialog,
    private alertTrigger: AlertTriggerService,
    private hoursDayFixed: HoursDayFixedService
  ) { }

  ngOnInit(): void {
    this.newForm.patchValue({tipoArchivo: this.data.tiposArchivo[0].id});
  }

  onSubmit() {
    this.waiting = true;
    let { itemNombre, emision, tipoArchivo } = this.newForm.getRawValue();
    emision = `${new Date(emision).getDate()}-${(new Date(emision).getMonth()) +1 }-${new Date(emision).getFullYear()}`;
    // emision = this.hoursDayFixed.getDateFixed(new Date(emision).toISOString())

    // bugfix 3300
    this.galleryFilesService.postItem(tipoArchivo, emision, itemNombre, this.archivos)
    .subscribe(response => {
      this.snack.snackTranslate('snacks.sucess.gallery');
      this.sharedData.changeMessage(ArchivosEnum.updateList);
      this.waiting = false;
      localStorage.removeItem('medicalCertificateAlarm');
      this.sharedData.changeMessage('medicalCertificateAlarm');
      this.dialogRef.closeAll();
      this.alertTrigger.putAlertaC(this.data.usuarioId, NotificacionAlarmaTipo.AptoMedico, NotificacionAlarmaTipo.cerrada)
        .subscribe(response => {

          
        },
          error => {
            console.error(error);
          });
      
    },
      error => {
        console.error(error);
        this.waiting = false;
        this.sharedData.changeMessage("reloadSections");
        this.snack.openSnackBar(error.error.message);
      });
  }
  
  nullFiles(event) {
    event.target.value = null;
  }


  selectFiles(event) {
    if(event.target.files.length != 0) {
      this.archivos = [];
      for (var i = 0; i < event.target.files.length; i++) {
        this.archivos.push(event.target.files[i]);
      }
    }
  }

  deleteFiles(index: number) {
    this.archivos.splice(index,1);
  }
}
