import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MobileDetectorService } from 'src/app/services/mobileDetector/mobile-detector.service';

@Component({
  selector: 'app-student-my-profile',
  templateUrl: './student-my-profile.component.html',
  styleUrls: ['./student-my-profile.component.scss']
})
export class StudentMyProfileComponent implements OnInit {

  isAdmin: boolean = false;
  isStudent: boolean = true;
  isMobile: boolean = false;
  
  constructor(
    private router: ActivatedRoute,
    private mobileDetectorService: MobileDetectorService,
  ) { }

  ngOnInit(): void {
    const url = this.router.snapshot.routeConfig.path;
    this.isMobile = this.mobileDetectorService.isMobile();
    if(url == "alumno/metas-objetivos") {
      sessionStorage.setItem('active','metas');
    } else {
      sessionStorage.setItem('active','perfil');
    }
  }

}
