<div class="flexLeft">
    <form *ngIf="!isAdmin" [formGroup]="planForm" id="form">

        <div id="box">
            <mat-form-field appearance="fill" class="inputPlanes">
                <mat-label>{{ 'planes.forms.date' | translate }}</mat-label>
                <input formControlName="semana" matInput [matDatepickerFilter]="myFilter" [matDatepicker]="picker">
                <mat-datepicker-toggle class="primaryIcon" matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
            
            <button mat-raised-button color="accent" id="return" class="primaryButton2" *ngIf="isStudent && showReturnWeek && !isMobile" (click)="returnToCurrentWeek()">
                <mat-icon svgIcon="return"></mat-icon>
                {{'planes.returnWeek' | translate}}
            </button> 
        </div>

        <button mat-button id="change" class="primaryButton2" *ngIf="showChangeDays && !isMobile" color="primary" (click)="openChangeDays()">
            <mat-icon>edit</mat-icon>
            {{'planes.changeDays.button' | translate}}
        </button> 

        <button [disabled]="!enableDownload" id="download" mat-stroked-button class="primaryButton2" color="primary" (click)="downloadFile()">
            <mat-icon *ngIf="enableDownload">download</mat-icon>
            <mat-icon *ngIf="!enableDownload" class="greyIcon">download</mat-icon>
            <span *ngIf="!isMobile">{{'planes.download' | translate}}</span>
            
        </button> 
    </form>
    

    <!-- <button mat-stroked-button id="download" class="primaryButton2" *ngIf="showDownload" color="primary" (click)="downloadFile()">
        <mat-icon>download</mat-icon>
        {{'planes.download' | translate}}
    </button>  -->
</div>

<br/>

<button mat-raised-button color="accent" id="return" class="primaryButton2" *ngIf="isStudent && showReturnWeek && isMobile" (click)="returnToCurrentWeek()">
    <mat-icon svgIcon="return"></mat-icon>
    {{'planes.returnWeek' | translate}}
</button> 

<app-loading *ngIf="!loaded" [showText]="false"></app-loading>


<div *ngIf="loaded">
    <div class="week">
        <div class="dayContainer" *ngFor="let day of days; let index = index">
            <app-day 
                [index]="index"
                [day]="day" 
                [alumnoId]="alumnoId"
                (changeActiveDayEvent)="changeActiveDayEvent($event)" 
                (reloadEvent)="reloadEvent($event)" 
                (openDialogEvent)="openDialog()">
            </app-day>
        </div>
    </div>
    <!-- segunda semana solo para admin -->
    <div *ngIf="isAdmin">
        <div class="week">
            <div class="dayContainer" *ngFor="let day2 of daysWeek2; let index = index">
                <app-day 
                    [index]="index"
                    [day]="day2" 
                    [alumnoId]="alumnoId"
                    (changeActiveDayEvent)="changeActiveDayEvent($event)" 
                    (reloadEvent)="reloadEvent($event)" 
                    (openDialogEvent)="openDialog()">
                </app-day>
            </div>
        </div>
        <div class="flexRigth">
            <button id="objetives" mat-button (click)="openDialog()">
                <div class="flexLeft">
                    <mat-icon class="azul">flag</mat-icon>
                    <div class="subtitle3Blue">
                        {{'planes.objetivesPopup.open' | translate}}
                    </div>
                </div>
            </button>
        </div>
    </div>
</div>
