<div class="flexStudents">

  
  <span class="flexLeft">
    <button mat-icon-button (click)="navigate()" class="mediumIcon">
      <mat-icon>arrow_back</mat-icon>
    </button>
    <span class="headline6">{{'admin.domiciliacion.remittance' | translate}} {{selectedMonth.date}}</span>
  </span>

  <button *ngIf="!isMobile" mat-raised-button color="accent" class="primaryButton2" (click)="openDialogMessage()">
    {{ 'admin.domiciliacion.message.openDialogButton' | translate }}
  </button>
  
  <!-- <div>
    <button [disabled]="totalElements == 0" mat-raised-button color="accent"  class="primaryButton2" (click)="export()">
      {{ 'admin.domiciliacion.export' | translate }}
    </button>
    <br/>
    <br/>
    <p *ngIf="totalElements > 0 " class="body3">{{ 'admin.domiciliacion.exportText' | translate  : {totalElements : totalElements} }}</p>
    <br/>
  </div> -->
</div>

<button *ngIf="isMobile" mat-stroked-button color="primary" class="primaryButton2" (click)="openDialogMessage()">
  <mat-icon>email</mat-icon>
  {{ 'admin.domiciliacion.message.openDialogButton' | translate }}
</button>


<app-filters-domiciliacion (filtersChangeEvent)="filtersChange($event)"></app-filters-domiciliacion>

<div class="noData" *ngIf="loaded && noData">
    <span class="headline6Light"> {{'admin.domiciliacion.mainTable.emptyAlumnos' | translate}}</span>
  </div>
  
  <div class="table-container" *ngIf="loaded && !noData">
    <table *ngIf="!isMobile" mat-table [dataSource]="domiciliacionData" multiTemplateDataRows>
  
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> {{'admin.studentsTable.header.name' | translate}} </th>
        <td mat-cell *matCellDef="let element">
          <span class="subtitle2">{{element.nombre}} {{element.apellido}}</span>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="trainingGroup">
        <th mat-header-cell *matHeaderCellDef> {{'admin.studentsTable.header.type' | translate}} </th>
        <td mat-cell *matCellDef="let element">
          <span class="subtitle2">{{element.entrenamientoTipoDescripcion}}</span>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="tariff">
        <th mat-header-cell *matHeaderCellDef> {{'admin.studentsTable.header.tariff' | translate}} </th>
        <td mat-cell *matCellDef="let element">
          <span class="subtitle2">{{element.arancel}} 
            <button mat-icon-button>
              <mat-icon id="editButton" class="delete" (click)="editarArancel(element)">
                edit
              </mat-icon>
            </button>
          </span>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> {{'admin.studentsTable.header.date' | translate}} </th>
        <td mat-cell *matCellDef="let element">
          <span class="subtitle2">{{element.creado | date: 'dd/MM/YYYY'}}</span>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef> {{'admin.studentsTable.header.status' | translate}} </th>
        <td mat-cell *matCellDef="let element">
          <app-estado-domiciliacion
            [estado]="element.estado">
          </app-estado-domiciliacion>
        </td>
      </ng-container>
  
      <ng-container matColumnDef="actions" stickyEnd>
        <th mat-header-cell *matHeaderCellDef> {{'admin.studentsTable.header.actions' | translate}}</th>
        <td mat-cell *matCellDef="let element">
          <app-botones-cobro-domiciliacion
            [estado]="element.estado"
            [mostrarIconos]="true"
            (cobrarEvent)="cobrarAlumno(element)">
          </app-botones-cobro-domiciliacion>
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <div *ngIf="isMobile">
    <div *ngFor="let element of domiciliacionData">
      <mat-card class="cardDomiciliacion">
  
        <div class="flexBeetwen">
          <div class="headline6">{{element.apellido}} {{element.nombre}} </div>
        </div>
  
        <div class="body2"> {{'admin.domiciliacion.monthTable.date' | translate}}: {{element.creado | date: 'dd/MM/YYYY'}}</div>
  
        <mat-grid-list cols="3" rowHeight="2:1">
          
          <mat-grid-tile>
            <span class="subtitle2">{{element.arancel}} 
              <button mat-icon-button>
                <mat-icon id="editButton" class="delete" (click)="editarArancel(element)">
                  edit
                </mat-icon>
              </button>
            </span>
          </mat-grid-tile>
          <mat-grid-tile>
            <app-estado-domiciliacion
              [estado]="element.estado">
            </app-estado-domiciliacion>
          </mat-grid-tile>
          <mat-grid-tile>
            <app-botones-cobro-domiciliacion
            [estado]="element.estado"
            [mostrarIconos]="false"
            (cobrarEvent)="cobrarAlumno(element)">
          </app-botones-cobro-domiciliacion>
          </mat-grid-tile>
        </mat-grid-list>
        
      </mat-card>
    </div>
  </div>
  
  <app-paginator-complete *ngIf="loaded" 
    [first]="first" 
    [last]="last" 
    [page]="page" 
    [totalPages]="totalPages"
    [size]="size" 
    [totalElements]="totalElements" 
    (loadMoreEvent)="loadMore($event)">
  </app-paginator-complete>