import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { Level, PersonalTrainer, TrainingType } from '../../interfaces/user';
import { Status } from '../../interfaces/register';

@Injectable({
  providedIn: 'root'
})
export class TrainingService {

  private url = environment.apiUrl + '/';
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    }),
    withCredentials: true,
  };
  // levels: Level[] = [];
  // trainers: PersonalTrainer[] = [];
  // modes: TrainingType[] = [];
  constructor(private http: HttpClient) { }

  getLevels(): Observable<Level[]> { 
    return this.http.get<Level[]>(this.url + 'api/niveles', this.httpOptions); 
  }

  getStatus(): Observable<Status[]> { 
    return this.http.get<Status[]>(this.url + 'api/estados', this.httpOptions); 
  }

  getTrainers(): Observable<PersonalTrainer[]> {
    return this.http.get<PersonalTrainer[]>(this.url + 'api/entrenadores/lista', this.httpOptions);
  }

  getTrainingType(): Observable<TrainingType[]> {
    return this.http.get<TrainingType[]>(this.url + 'get/modes', this.httpOptions);
  }
  
  patchLevel(id_level: number): Observable<any> {
    return this.http.patch<any>(this.url + 'patch/level',id_level, this.httpOptions);
  }

  patchTrainer(user_id): Observable<any> {
    return this.http.patch<any>(this.url + 'patch/trainer',user_id, this.httpOptions);
  }

  patchStudentTrainingData(data): Observable<any> {
    return this.http.patch<any>(this.url + 'patch/trainingData',data, this.httpOptions);
  }

}
