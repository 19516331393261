import { Component, OnInit, Input } from '@angular/core';
import { OrdenarSecciones, SeccionConItems } from '../../../../api/interfaces/sections';
import { MatDialog } from '@angular/material/dialog';
import { NewEditSectionComponent } from '../new-edit/new-edit-section/new-edit-section.component';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { RolService } from '../../../../services/rol/rol.service';
import { SeccionesService } from 'src/app/api/services/secciones/secciones.service';
import { SnackbarService } from 'src/app/utils/snackbar/snackbar.service';
import { MobileDetectorService } from 'src/app/services/mobileDetector/mobile-detector.service';

/**
 * Display sections and allow admin to call actions: create, edit, delete, drag and drop
 * @param secciones - SeccionConItems[] receives secciones from current mode
 * @param tipo - string, N nutricion, T trabajo complementario
 */
@Component({
  selector: 'app-card-section',
  templateUrl: './card-section.component.html',
  styleUrls: ['./card-section.component.scss']
})
export class CardSectionComponent implements OnInit {

  @Input('secciones') secciones: SeccionConItems[];
  @Input('tipo') tipo: string;

  blockActionButtons: boolean = true;
  disableDragDrop: boolean = true;
  isMobile: boolean = false;
  isAdmin: boolean = false;
  
  class: string = "section";

  constructor(
    public dialog: MatDialog,
    private rolService: RolService,
    private seccionesService: SeccionesService,
    private snack: SnackbarService,
    private mobileDetectorService: MobileDetectorService,
    
  ) { }

  ngOnInit(): void {
    this.isAdmin = this.rolService.isAdmin();

    this.isMobile = this.mobileDetectorService.isMobile();
  }

  addSection() {
    this.dialog.open(NewEditSectionComponent, {
      backdropClass: 'backdropBackground',
    }); 
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.secciones, event.previousIndex, event.currentIndex);
  }

  toggleDragDrop() {
    this.disableDragDrop = !this.disableDragDrop;
    //reordenar con correccion para index = 0
    if(this.disableDragDrop) {
      this.secciones.forEach( (seccion,index) => seccion.seccionOrden = index +1 );
      this.class = "section";
      const nuevoOrden: OrdenarSecciones[] = [];
      this.secciones.forEach(seccion => {
        const aux: OrdenarSecciones = {
          id: seccion.seccionId,
          seccionOrden: seccion.seccionOrden
        };
        nuevoOrden.push(aux);
      })
      this.seccionesService.ordenarSecciones(nuevoOrden).subscribe( response=> {
        this.snack.snackTranslate('snacks.sucess.sections.dragSections');
      },
      error => {
        console.error(error);
        this.snack.openSnackBar(error.error.message);
      });

    } else {
      this.class = "section move"
    }
  }

  blockActionButtonsFunction (aux: boolean) {
    this.blockActionButtons = aux;
  }

  notify() {
    //todo: conectar a backend
  }
}
