import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DialogoAgregar, ItemSeccion, PostEditItem } from '../../../../../api/interfaces/sections';
import { SeccionesService } from '../../../../../api/services/secciones/secciones.service';
import { SharedDataService } from '../../../../../services/sharedData/shared-data.service';
import { SnackbarService } from '../../../../../utils/snackbar/snackbar.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * NewEditElementComponent
 * @param data dialog data, 
 * @interface DialogAgregar
 *    @file sections.ts
 * @example add new element
 *     data:  {
        itemTipo: "V",
        seccionId: 1
      }
 * @example edit element
      data:  {
          itemTipo: "V",
          itemId: 1,
          itemNombre: "Video de ejemplo",
          itemUbicacion: "http://youtube.com/...",
          seccionId: 1
        }
 */
@Component({
  selector: 'app-new-edit-element',
  templateUrl: './new-edit-element.component.html',
  styleUrls: ['./new-edit-element.component.scss']
})
export class NewEditElementComponent implements OnInit {

  waiting: boolean = false;
  edit: boolean = false;

  newForm = new FormGroup({
    itemNombre: new FormControl(),
    itemUbicacion: new FormControl(),
  });

  archivos: File[] = [];

  constructor(
    private seccionesService: SeccionesService,
    private snack: SnackbarService,
    private sharedData: SharedDataService,
    private dialogRef: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: DialogoAgregar
  ) { }

  ngOnInit(): void {
    if(this.data.itemNombre && this.data.itemUbicacion) {
      this.newForm.patchValue({itemNombre: this.data.itemNombre});
      this.newForm.patchValue({itemUbicacion: this.data.itemUbicacion});
      this.edit = true;
    }
    // ticket 2435
    this.newForm.statusChanges.subscribe(val => {
      if(this.newForm.value && this.newForm.value.itemNombre) {
        if(this.newForm.value.itemNombre.trim().length == 0) {
          this.newForm.patchValue({itemNombre: this.newForm.value.itemNombre.trim()});
        }
      }
      if(this.newForm.value && this.newForm.value.itemUbicacion) {
        if(this.newForm.value.itemUbicacion.trim().length == 0) {
          this.newForm.patchValue({itemUbicacion: this.newForm.value.itemUbicacion.trim()});
        }
      }
    });
  }

  onSubmit() {

    this.waiting = true;
    const { itemNombre, itemUbicacion } = this.newForm.getRawValue();
    const item: PostEditItem= { 
      itemNombre : itemNombre,
      itemUbicacion: itemUbicacion,
      itemTipo: this.data.itemTipo,
      seccionId: this.data.seccionId,
      activo: true
    };
    if(this.edit) {
      this.seccionesService.editarItem(this.data.itemId, item).subscribe( response=> {
        this.snack.snackTranslate('snacks.sucess.sections.edit');

        //update show selected item component
        let aux: ItemSeccion = JSON.parse(sessionStorage.getItem('item'));
        aux.itemNombre = itemNombre;
        aux.itemUbicacion = itemUbicacion;
        sessionStorage.setItem('item',JSON.stringify(aux));
        //end of update

        this.sharedData.changeMessage("reloadSections");
        this.dialogRef.closeAll();
      },
      error => {
        console.error(error);
        this.snack.openSnackBar(error.error.message);
      });
    } else {
      if(this.data.itemTipo == 'V') {
        this.postItem(item);
      } else {
        //todo: si es un documento agregar endpoint para subirlo y luego llamar a postItem
        //image service
        this.postItem(item);
      }
    }
  }

  nullFiles(event) {
    event.target.value = null;
  }

  selectFiles(event) {
    if(event.target.files.length != 0) {
      this.archivos = [];
      for (var i = 0; i < event.target.files.length; i++) {
        this.archivos.push(event.target.files[i]);
      }
    }
  }

  postItem(item: PostEditItem) {
    this.seccionesService.postItem(item).subscribe( response=> {
      sessionStorage.setItem('postItem',JSON.stringify(item));
      sessionStorage.setItem('inSection',item.seccionId.toString());
      if(item.itemTipo == 'P') {
        this.uploadFile(response);
      } else {
        this.snack.snackTranslate('snacks.sucess.sections.video');
        this.sharedData.changeMessage("reloadSections");
        this.waiting = false;
        this.dialogRef.closeAll();
      }
    },
    error => {
      this.waiting = false;
      console.error(error);
      this.snack.openSnackBar(error.error.message);
    });
  }

  uploadFile(response: number) {
    this.seccionesService.patchFile(this.archivos, response).subscribe( response=> {
      this.snack.snackTranslate('snacks.sucess.sections.file');
      this.sharedData.changeMessage("reloadSections");
      this.waiting = false;
      this.dialogRef.closeAll();
    },
    error => {
      console.error(error);
      this.waiting = false;
      this.sharedData.changeMessage("reloadSections");
      this.snack.openSnackBar(error.error.message);
    });
  }

  deleteFiles(index: number) {
    this.archivos.splice(index,1);
  }

}
