<div *ngIf="!isRuta">
    <div *ngFor="let achievement of achievementsForEdit; let i = index">
        <app-inputs-achievements 
            [disable]="disable"  
            [alumnoId]="alumnoId"
            [achievement]="achievement" 
            [i]="i"
            [mode]="mode" 
            (removeAchievementEvent)="removeAchievementFromEdit(i)" >
        </app-inputs-achievements>
    </div>
    <div *ngFor="let achievement of achievements; let i = index">
        <app-inputs-achievements 
            [disable]="disable"
            [alumnoId]="alumnoId"
            [i]="i"
            [mode]="mode" 
            (removeAchievementEvent)="removeAchievement(i)" 
            (saveAchievementEvent)="saveAchievementFromInputComponent($event)"></app-inputs-achievements>
    </div>
</div>

<div *ngIf="isRuta && rutaLoaded">
    <div *ngFor="let achievement of achievementsRouteForEdit; let i = index">
        <app-inputs-achievements 
            [disable]="disable"
            [alumnoId]="alumnoId"
            [achievement]="achievement"
            [i]="i"
            [mode]="mode"
            [routeOptions]="routeOptionsDistancias"
            (removeAchievementEvent)="removeAchievementFromEdit(i)">
        </app-inputs-achievements>
    </div>
    <div *ngFor="let achievement of achievementsRoute; let i = index">
        <app-inputs-achievements 
            [disable]="disable" 
            [alumnoId]="alumnoId"
            [i]="i"
            [mode]="mode"
            [routeOptions]="routeOptionsDistancias"
            (removeAchievementEvent)="removeAchievement(i)"
            (saveAchievementEvent)="saveAchievementFromInputComponent($event)">
        </app-inputs-achievements>
    </div>
</div>
    
<button 
    (click)="addAchievement()" 
    (keydown.enter)="$event.preventDefault()"
    [disabled]="disable"  
    mat-button color="primary" 
    id="addButton">
    {{ 'registration.stepThree.objetives.add' |translate}}
</button>