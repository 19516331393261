<div *ngIf="!loading">

    <!-- lista de elementos de tipo "RamaPrincipal" -->
    <div [@flyInOut]="'in'" *ngIf="(firstChange && !choosedElementPrincipal) || showRamaPrincipal">
        <p class="headline6" *ngIf="showMainText">{{ 'support.tree.main' | translate}}</p>
        <div *ngFor = "let element of elementsSearchPrincipales">
            <mat-list *ngIf="element.padreId == 0">
                <mat-list-item class="elementoPrincipal" (click)="toggleRamas(element)">
                    <mat-icon mat-list-icon class="iconList">{{element.icono ? element.icono : 'description'}} </mat-icon>
                    <div mat-line> <span class="subtitle2">{{element.titulo}}</span> </div>
                </mat-list-item>
            </mat-list>
        </div>
    </div>
    
    <!-- "RamaConHijos" -->
    <div [@flyInOut]="'in'" *ngIf="choosedElementPrincipal && choosedElementPrincipal.id">
        <p [@flyInOut]="'in'" class="headline6">{{choosedElementPrincipal.titulo}} </p>
        
        <!-- titulo, texto y video del elemento principal seleccionado -->
        <span [@flyInOut]="'in'" *ngIf="elements && elements.padre && elements.padre.contenidoParseado">
            <p class="body2" *ngIf="elements.padre.contenidoParseado.texto" [innerHTML]="elements.padre.contenidoParseado.texto"></p>
            <span *ngIf="elements.padre.contenidoParseado.videoUrl" >
                <div *ngFor="let video of elements.padre.contenidoParseado.videoUrl">
                    <iframe
                        class="videoContainerSupport"
                        [src]="getVideoIframe(video)"
                        frameborder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen>
                    </iframe>
                </div>
            </span>
        </span>
            
            <!-- hijos -->
        <span *ngIf="elements && elements.hijos">
            <div [@flyInOut]="'in'" *ngFor="let aux of elements.hijos">
                <mat-list >
                    <mat-list-item class="elementoPrincipal" (click)="toggleRamas(aux)">
                        <mat-icon mat-list-icon class="iconList">{{aux.icono ? aux.icono : 'article'}}</mat-icon>
                        <div mat-line> <span class="subtitle2">{{aux.titulo}}</span> </div>
                    </mat-list-item>
                </mat-list>
                    
                <app-support-tree-iterable-elements
                    [element]="aux"
                    [showMainText]="false">
                </app-support-tree-iterable-elements>
                
            </div>
        </span>

        <app-support-tree-usefull
            [@flyInOut]="'in'"
            *ngIf="elements && elements.padre && elements.padre.esHoja"
            [id]="elements.padre.id">
        </app-support-tree-usefull>
    </div>
</div>

<div *ngIf="loading" class="flexCenter">
    <mat-spinner></mat-spinner>
</div>