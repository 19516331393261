import { Component, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginData } from 'src/app/api/interfaces/login';
import { RegisterService } from 'src/app/api/services/register/register.service';
import { StatusEnum } from 'src/app/enums/status';
import { environment } from '../../../../environments/environment';
import { Country, Documento, StudentFromAdmin, UserFromAdmin } from '../../../api/interfaces/register';
import { ImageService } from '../../../api/services/image/image.service';
import { LoginService } from '../../../api/services/login-service.service';
import { FormValidatorService } from '../../../services/formValidator/form-validator.service';
import { MobileDetectorService } from '../../../services/mobileDetector/mobile-detector.service';
import { SharedDataService } from '../../../services/sharedData/shared-data.service';

@Component({
  selector: 'app-step-one',
  templateUrl: './step-one.component.html',
  styleUrls: ['./step-one.component.scss']
})
export class StepOneComponent implements OnInit {

  @Input('cantidadPasosHabilitados') cantidadPasosHabilitados: number;

  countrys: Country[];
  documentos: Documento[] = [];
  maxDate: Date = new Date();

  userForm = new FormGroup({
    nombre: new FormControl(),
    apellido: new FormControl(),
    segundoApellido: new FormControl(),
    fechaNacimiento: new FormControl(),
    DNI: new FormControl("", [
      Validators.pattern(/^[a-zA-Z0-9]+$/),
    ]),
    documentoTipo: new FormControl(),
    pais: new FormControl(),
    residencia: new FormControl(),

    prefijoCelular: new FormControl(),
    numeroCelular: new FormControl(),
    prefijoEmergencia: new FormControl(),
    numeroEmergencia: new FormControl(),

    peso: new FormControl("", [
      Validators.pattern(/^[a-zA-Z0-9]+$/),
    ]),
    altura: new FormControl(),
    email: new FormControl()
  });

  archivosForm = new FormGroup({
    archivosSeleccionados: new FormControl(),
  });

  archivos: string[] = [];
  userFromAdmin: UserFromAdmin;
  nombre: string = null;
  apellido: string = null;
  segundoApellido: string = null;
  userHasName: boolean = true;
  userHasLastName: boolean = true;
  userHasSecondLastName: boolean = true;
  userHasImage: boolean = false;
  isMobile: boolean = false;
  avatarUrl: string = "";

  usuarioId: number = null;

  constructor(
    private registerService: RegisterService,
    private sharedData: SharedDataService,
    private formValidatorService: FormValidatorService,
    private login: LoginService,
    private imageService: ImageService,
    private mobileDetectorService: MobileDetectorService,
    private loginApiService: LoginService,
    private router: Router,
  ) { }

  ngOnInit(): void {

    this.usuarioId = parseInt(localStorage.getItem('idUsuario'),10);
    
    this.isMobile = this.mobileDetectorService.isMobile();

    
    this.loginApiService.getUserData()
    .subscribe(response => {
      if(response.perfilAlumno.estado === StatusEnum.PENDING) {
        this.router.navigateByUrl("/thanks");
      }
      this.asingDataToForm(response);
      },
        error => {
          console.error(error);
        });

    this.userForm.statusChanges
    .subscribe(val => {
      // if(val === "VALID") {
        this.formValidatorService.onFormValidation(val, "step1","user")
      // }
      this.userForm.value.nombre = this.userForm.value.nombre ? this.userForm.value.nombre : this.nombre;
      this.userForm.value.apellido = this.userForm.value.apellido ? this.userForm.value.apellido : this.apellido;
      this.userForm.value.segundoApellido = this.userForm.value.segundoApellido ? this.userForm.value.segundoApellido : this.segundoApellido;
      if(this.userForm.value.nombre === null) {
        this.userHasName = false;
      } else {
        this.userHasName = true;
      }
      if(this.userForm.value.apellido === null) {
        this.userHasLastName = false;
      }else {
        this.userHasLastName = true;
      }
    });

    this.getCountry();
    this.getTiposDocumento();

    this.getAvatar();
  }

  async selectFiles(event) {
    try {
      await this.imageService.uploadFile(event);
      this.getAvatar();
    } catch {
      this.avatarUrl="";
      this.userHasImage = false;
    }
  }

  async getAvatar() {
    const idUsuario: number = parseInt(localStorage.getItem('idUsuario'),10);
    this.avatarUrl = await this.imageService.getAvatarUrl(idUsuario);
    if(this.avatarUrl) {
      this.userHasImage = true;
    }
  }

  getCountry() {
    this.registerService.getCountry()
    .subscribe(response => {
      this.countrys = response;
    },
      error => {
        console.error(error);
      });
  }

  getTiposDocumento() {
    this.registerService.getTiposDocumento()
    .subscribe(response => {
      this.documentos = response;
    },
      error => {
        console.error(error);
      });
  }

  asingDataToForm(response: LoginData) {
    
    this.userForm.patchValue({email: response.perfilAlumno.email});
    if(response.perfilAlumno.nombre) {
      this.nombre = response.perfilAlumno.nombre;
      this.userForm.value.nombre = response.perfilAlumno.nombre;
      this.userHasName = true;
    } else {
      this.nombre = null;
      this.userHasLastName = false;
    }
    if(response.perfilAlumno.apellido) {
      this.userHasLastName = true;
      this.apellido = response.perfilAlumno.apellido;
      this.userForm.value.apellido = this.userForm.value.apellido ? this.userForm.value.apellido : this.apellido;
    } else {
      this.apellido = null;
      this.userHasLastName = false;
    }

    if(response.perfilAlumno.segundoApellido) {
      this.userHasSecondLastName = true;
      this.segundoApellido = response.perfilAlumno.segundoApellido;
      this.userForm.value.segundoApellido = this.userForm.value.segundoApellido ? this.userForm.value.segundoApellido : this.segundoApellido;
    } else {
      this.segundoApellido = null;
      this.userHasSecondLastName = false;
    }

  }

}
