import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CheckImageService {

  constructor() { }
 /**
   * Update cssVar value to show an image. If equipo don't have the request image, then the default image will show up
   * @param imgName - image's name to show
   * @param cssVar - css variable to update
   * @param path - relative path from component to image without asset folder
   * @example
   * ```
   * this.checkImage.setImageCssVar('welcome.jpg','imagen','../../../../');
   * ```
   */
  public setImageCssVar(imgName: string, cssVar: string, path: string) {
    const imgWithEquipoId = path + 'assets/equipoId_' + localStorage.getItem('equipoId').toString() + '/'+imgName;
    imgName = path + 'assets/' + imgName;
    this.checkIfImageExists(imgWithEquipoId, (exists) => {
      if (exists) {
        document.documentElement.style.setProperty('--'+cssVar, 'url('+imgWithEquipoId+')');
      } else {
        document.documentElement.style.setProperty('--'+cssVar, 'url('+imgName+')');
      }
    });
  }

  /**
   * 
   * @param url - string, img's url
   * @param callback - true if image exist. Otherwise, the callback is set to false
   */
  public checkIfImageExists (url: string, callback: (exists: boolean) => void) {
    const img = new Image();
    img.src = url;
  
    if (img.complete) {
      callback(true);
    } else {
      img.onload = () => {
        callback(true);
      };
  
      img.onerror = () => {
        callback(false);
      };
    }
  };
}
