import { Injectable } from '@angular/core';
import { Canal, Participante } from 'src/app/api/interfaces/messages';

@Injectable({
  providedIn: 'root'
})
/**
 * return participante, for messages.
 */
export class ParticipanteService {

  constructor() { }
  /**
   * Returns the other person data in the conversation
   * @param canal - Canal, message canal.
   * @returns - otroParticipante, Participante.
   */
  public getParticipante(canal: Canal): Participante {
    if(canal && canal.participantes) {

      const miId: number = parseInt(localStorage.getItem('idUsuario'));
      let otroParticipante: Participante = null;
      //fix backend typo
      if(canal.partipantes) {
        canal.participantes = canal.partipantes;
      }
      canal.participantes.forEach(participante => {
        if(participante.idUsuario != miId) {
          otroParticipante = participante;
        }
      }); 
      return otroParticipante;
    }
  }

  /**
   * Returns current user data in the conversation
   * @param canal - Canal, message canal.
   * @returns - otroParticipante, Participante.
   */
  public getMiParticipante(canal: Canal): Participante {
    const miId: number = parseInt(localStorage.getItem('idUsuario'));
    let miParticipante: Participante = null;
    //fix backend typo
    if(canal && canal.partipantes) {
      canal.participantes = canal.partipantes;
    }
    if(canal && canal.partipantes) {
      canal.participantes.forEach(participante => {
        if(participante.idUsuario == miId) {
          miParticipante = participante;
        }
      });
    }

    return miParticipante;
  }
}
