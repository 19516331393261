import { Component, OnInit,Input,Output,EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { StatusEnum } from 'src/app/enums/status';
import { MobileDetectorService } from 'src/app/services/mobileDetector/mobile-detector.service';

@Component({
  selector: 'app-title',
  templateUrl: './title.component.html',
  styleUrls: ['./title.component.scss']
})
export class TitleComponent implements OnInit, OnChanges {

  @Input('bank') bank: boolean;
  @Input('valid') valid: boolean;
  @Input('estado') estado: string;
  @Input('disable') disable: boolean;
  @Input('contact') contact: boolean;
  @Input('personal') personal: boolean;
  @Input('background') background: boolean;
  @Input('physiological') physiological: boolean;

  @Output() editEvent = new EventEmitter<boolean>();
  @Output() onSubmitEvent = new EventEmitter<any>();

  achievementsValid: boolean = false;
  showEdit: boolean = true;
  isMobile: boolean = false;

  constructor(
    private mobileDetector: MobileDetectorService
  ) { }

  ngOnInit(): void {
    this.isMobile = this.mobileDetector.isMobile();
  }

  edit() {
    this.editEvent.emit(!this.disable);
  }

  onSubmit() {
    this.onSubmitEvent.emit();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.estado) {
      this.showEdit = (changes.estado.currentValue == StatusEnum.ACTIVADO);
    }
  }

}
