<p class="headline6">{{ 'registration.stepThree.background' | translate}}</p>

<div *ngIf="length == 0" class="flexCenter">
    <span class="subtitle1" *ngIf="!isStudent"> {{ 'registration.stepThree.empty' | translate}} </span>
    <span class="subtitle1" *ngIf="isStudent"> {{ 'registration.stepThree.emptyStudent' | translate}} </span>
  </div>
<div *ngFor="let respuesta of antecedente; let i = index">
    <p class="subtitle3">{{respuesta.antecedentePregunta.titulo}}</p>
    <p class="body2" [innerHTML]="respuesta.antecedenteRespuesta"> </p>
    <div *ngIf="i < (length - 1)">
        <br/>
        <mat-divider ></mat-divider>
        <br/>
    </div>
</div>