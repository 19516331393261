import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { AutoTicket, NewTicket, RamaConHijos, RamaPrincipal, SupportUsefull, SupportUsefullConvertido, TicketResponse, TreeElement } from '../../interfaces/support';
import { ParametrosService } from '../parametros/parametros.service';
import { HoursDayFixedService } from '../../../utils/hoursDayFixed/hours-day-fixed.service';


@Injectable({
  providedIn: 'root'
})
export class SupportService {

  private url = environment.apiUrl + '/';
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json'
    }),
    withCredentials: true,
  };
  httpOptionsJson = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }),
    withCredentials: true,
  };
  multipartOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'multipart/form-data'
    })
  };
  constructor(
    private http: HttpClient,
    private parametrosService: ParametrosService,
    private hoursDayFixed: HoursDayFixedService
  ) { }

  getCategorias(key: string): Observable<any> {
    return this.http.get<any>(this.url + `api/support/trackers`, this.httpOptions);
  }

  postTicket(files, ticket: NewTicket): Observable<any> {
    const formData = new FormData();
    formData.append('asunto', ticket.asunto);
    formData.append('descripcion', ticket.descripcion);
    formData.append('tipoIncidencia', ticket.tipoIncidencia.toString());
    if (files && files.length > 0) {
      files.forEach(file => {
        formData.append('files', file);
      });
    }
    const options = {
      withCredentials: true,
    };
    return this.http.post<any>(this.url + 'redmine/createIssue', formData, options);
  }

  getRamasPrincipales(): Observable<RamaPrincipal[]> {
    return this.http.get<RamaPrincipal[]>(this.url + `api/soporte/listarnodos`, this.httpOptions);
  }

  /**
   * Save nodo_contenido in db:
   *
   * 1) build html text
   *
   * 2) if needed, add videos in array
   *
   * 3) put them together using | to separate html from video's array
   * 
   * 
   * @example
   * ````html
   * <p> hello world! </p> | ["https://www.youtube.com/watch?v=6uddGul0oAc","https://www.youtube.com/watch?v=6uddGul0oAc"]
   * ````
   * @param id 
   * @returns nodo with hijos {@link TreeElement}
   */

  getRamasPorIdPrincipal(id: number): Observable<TreeElement> {
    return this.http.get<TreeElement>(this.url + `api/soporte/nodo/?nodo_id=${id}`, this.httpOptions);
  }

  getHijosPorIdPadre(id: number): Observable<RamaPrincipal[]> {
    return this.http.get<RamaPrincipal[]>(this.url + `api/soporte/nodo/hijos/?nodo_id=${id}`, this.httpOptions);
  }

  postUsefullTreeElement(supportUsefull: SupportUsefullConvertido): Observable<any> {
    return this.http.post<any>(this.url + `api/soporte/nodo/util`, supportUsefull, this.httpOptionsJson);
  }

  /**
   * 
   * @param bug boolean, ticket's tracker is a bug?
   * @returns number, tracker id
   */
  getTipoIncidente(bug: boolean): Promise <number> {
    return new Promise((resolve, reject) => {
      this.parametrosService.getParametros('Redmine NR').subscribe(categorias => {
        
        let clave: string = "INCIDENTE";
        if(bug) {
          clave = "BUG";
        }
        let result: number = -1;
        categorias.find (categoria => {
          if (categoria.clave.toUpperCase() == clave) {
            result = parseInt(categoria.valor,10);
          }
        });
        resolve(result);
      },
      error => {
        console.error(error);
        reject();
        
      });
    });
  }

  /**
   * Create ticket data from a failed http request and post a new ticket
   * @param request - http request from AutoTicketInterceptor
   * @param err 
   */
  async postAutoTicket(request: HttpRequest<unknown>, err) {
    const datos : AutoTicket = {
      method: request.method,
      url: request.url,
      razon: err.error.message,
      idUsuario: localStorage.getItem('idUsuario'),
      status: err.status,
      message: err.message,
      error: err.error,
    };

    let datosString: string = 'Ticket generado automáticamente por error del sistema. Descripción (JSON): <pre><code class="javascript"> ' + JSON.stringify(datos) + ' </code></pre>';

    const asunto: string = datos.razon ? "[AUTO]" + datos.razon : "[AUTO]" + datos.url;
    const ticket: NewTicket = {
      asunto: asunto,
      descripcion: datosString,
      tipoIncidencia: await this.getTipoIncidente(false)
    }
     this.postTicket(null, ticket).subscribe(response => {
        
      },
        error => {
        }); 
  }

  async postUsefull(): Promise<boolean> {

    return new Promise((resolve, reject) => {
      const datos: SupportUsefull = JSON.parse(sessionStorage.getItem('usefullData'));
      const datosConvertidos: SupportUsefullConvertido = {
        nodo: datos.id,
        nodoUtilidadUtil: datos.util,
        nodoHistorial: datos.navegacion.toString(),
        ticketId: datos.ticketId ? datos.ticketId : null,
        creado: this.hoursDayFixed.getTodayFixedString()
      }
      this.postUsefullTreeElement(datosConvertidos)
      .subscribe(response => {
        sessionStorage.removeItem('usefullData');
        sessionStorage.removeItem('postUsefullRequired');
        sessionStorage.removeItem('navegacionCompleta');
        resolve(true);
      },
        error => {
          console.error(error);
          reject(false);
        });
    });
  }
}
