<form class="example-form">
  <mat-form-field class="example-full-width" appearance="fill" class="inputfullWidth">
    <mat-label>{{ 'support.tree.searchLabel' | translate}}</mat-label>

    <mat-icon matPrefix class="azul">search</mat-icon>

    <input type="text"
      placeholder="{{ 'support.tree.searchPlaceholder' | translate}}"
      matInput
      [formControl]="searchControl"
      [matAutocomplete]="auto">

    <button *ngIf="searchControl.value" mat-icon-button matSuffix
      (click)="clear(); $event.stopPropagation()">
        <mat-icon class="clearIcon">close</mat-icon>
    </button>
              
    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
      <mat-option *ngFor="let option of filteredElementsPrincipales | async" [value]="option.titulo"  (onSelectionChange)="toggleElement(option, $event)">
        <mat-icon >  {{option.icono ? option.icono : 'article'}}</mat-icon> {{option.titulo}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</form>


<div *ngIf="loading" class="flexCenter">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="!loading">

  <span *ngIf="elementsSearchPrincipales.length == 0">
    <span class="headline6Light"> {{ 'support.tree.searchEmpty' | translate}}</span>
    <app-support-tree-usefull></app-support-tree-usefull>
  </span>
  
  <div class="elementsList">
    <app-support-tree-iterable-elements
      *ngIf="elementsSearchPrincipales.length > 0"
      (clearEvent)="clear()"
      [showMainText]="true"
      [choosedElementPrincipal]="choosedElementPrincipal"
      [elementsSearchPrincipales]="elementsSearchPrincipales">
    </app-support-tree-iterable-elements>
  </div>
</div>
