import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SharedDataFlag } from 'src/app/api/interfaces/sharedDataInterfaces';

@Injectable({
  providedIn: 'root'
})
export class SharedDataService {

  constructor() { }

  public editDataDetails: any = [];
  public subject = new Subject<any>();

  private messageSource = new BehaviorSubject(this.editDataDetails);
  currentMessage = this.messageSource.asObservable();

  changeMessage(message: string) {
    this.messageSource.next(message)
  }



  public editDataFlagDetails: SharedDataFlag = null;
  public subjectFlag = new Subject<any>();

  private flagSource = new BehaviorSubject(this.editDataFlagDetails);

  /**
   * Components can subscribe and get flags
   */
  currentFlag = this.flagSource.asObservable();

  /**
   * 
   * @param key - string, name of the flag to evaluate
   * @param value - boolean, value of the flag to evaluate
   */
  changeFlag(key: string, value: boolean) {
    const flag: SharedDataFlag = {
      key, 
      value
    };
    this.flagSource.next(flag)
  }
}