import { Component, Inject, OnInit } from '@angular/core';
import { StudentTariffData } from '../../../../api/interfaces/domiciliacion';
import { MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog'
import { DomiciliacionService } from '../../../../api/services/domiciliacion/domiciliacion.service';
import { FormGroup, FormControl } from '@angular/forms';
import { SnackbarService } from '../../../../utils/snackbar/snackbar.service';


/**
 * Receive domiciliacion data and update arancel value.
 * @param data contains the id and arancel
 * @example
 * ```ts
 * data: StudentTariffData = {
 * arancel: 23,
 * estado: "E",
 * fecha: "2022-01-14T23:08:04Z",
 * grupo: "Presencial",
 * id: 2,
 * nombre: "Arturo Perez",
 * selected: false
 * }
 * ```
 */
@Component({
  selector: 'app-popup-arancel',
  templateUrl: './popup-arancel.component.html',
  styleUrls: ['./popup-arancel.component.scss']
})
export class PopupArancelComponent implements OnInit {

  arancelForm = new FormGroup({
    arancel: new FormControl(),
  });

  constructor(
    private domiciliacionService: DomiciliacionService,
    private dialogRef: MatDialog,
    private snack: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public data: StudentTariffData) { }

  ngOnInit(): void {
  }

  onSubmit() {
    const arancel = this.arancelForm.value.arancel
    this.domiciliacionService.crearArancel(this.data.id, arancel).subscribe( response => {
      this.data.arancel = arancel;
      this.snack.snackTranslate('snacks.sucess.domiciliacion.arancelUpdate');
      this.dialogRef.closeAll();
    },
    error => {
      console.error(error);
      this.snack.openSnackBar(error.error.message);
    });
  }
}
