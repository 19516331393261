import { Component, OnInit, Input } from '@angular/core';
import { Group } from '../../../../../api/interfaces/groups';
import { GroupsService } from '../../../../../api/services/groups/groups.service';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';

/**
 * Receive groups[] and send them to GroupsItemComponent
 */
@Component({
  selector: 'app-groups-list',
  templateUrl: './groups-list.component.html',
  styleUrls: ['./groups-list.component.scss']
})
export class GroupsListComponent implements OnInit {

  @Input('edit') edit: boolean;
  @Input('showNew') showNew: boolean;

  groups: Group[] = [];

  totalElements: number = 0;
  totalPages: number = 0;
  size: number = 20;
  page: number = 0;

  first: boolean = true;
  last: boolean = false;
  done: boolean = false;

  constructor(
    private groupsService: GroupsService,
    private sharedData: SharedDataService
  ) { }

  ngOnInit(): void {
    this.getGroups();
    this.sharedData.currentMessage.subscribe(message => {
      if (message === "updateGroups") {
        this.getGroups();
      }
    });
  }

  getGroups() {
    this.done = false;
    this.groupsService.getGrupos(this.page, this.size).subscribe( response => {
      this.groups = response.content;
      this.totalPages = response.totalPages;
      this.first = response.first;
      this.last = response.last;
      this.done = true;
    },
    error => {
      console.error(error);
    });
  }

  loadMore(event) {
    this.page = event;
    this.getGroups();
  }

}
