<div [class]="itemClass" (click)="changeItem()">
    <mat-icon [svgIcon]="icon" class="sectionIcon"></mat-icon>

    <!-- isAdmin -->
    <span *ngIf="item.selected && isAdmin" id="itemNombre" class="subtitle3 showOnHover">{{item.itemNombre |reduceText:20}}</span>
    <span *ngIf="item.selected && isAdmin" id="itemNombre" class="subtitle3 hideOnHover">{{item.itemNombre}}</span>
        
    <span *ngIf="!item.selected && isAdmin" id="itemNombre" class="body3 showOnHover">{{item.itemNombre |reduceText:20}}</span>
    <span *ngIf="!item.selected && isAdmin"id="itemNombre" class="body3 hideOnHover">{{item.itemNombre}}</span>

    <!-- no isAdmin -->
    <span *ngIf="item.selected && !isAdmin"  id="itemNombre" class="subtitle3">{{item.itemNombre}}</span>
    <span *ngIf="!item.selected && !isAdmin" id="itemNombre" class="body3">{{item.itemNombre}}</span>


    <div *ngIf="!isAdmin" class="buttonsItem">
        <button *ngIf="isMobile" mat-icon-button class="itemButton" (click)="changeItem()">
            <mat-icon class="delete">chevron_right</mat-icon>
        </button>
    </div>

    <div *ngIf="disableDragDrop && disableDragDropItems && isAdmin && hideButtons.hide" class="buttonsItem">
        <button mat-icon-button class="itemButton" (click)="toogleDrag(); $event.stopPropagation()">
            <mat-icon class="delete">low_priority</mat-icon>
        </button>

        <button *ngIf="isMobile" mat-icon-button class="itemButton" (click)="changeItem()">
            <mat-icon class="delete">chevron_right</mat-icon>
        </button>

        <!-- <button mat-icon-button class="itemButton" (click)="edit()">
            <mat-icon class="delete">edit</mat-icon>
        </button> -->

        <button (click)="removeItem(); $event.stopPropagation()" id="deleteButton" mat-icon-button class="itemButton">
            <mat-icon svgIcon="delete" class="delete"></mat-icon>
        </button>
    </div>

    <mat-icon *ngIf="!disableDragDropItems && isAdmin" (click)="toogleDrag(); $event.stopPropagation()">drag_indicator</mat-icon>
</div>