export enum StepperEnum {
    SAVE_STEP  = "saveStep",
    SAVE_STEP1 = "saveStep1",
    SAVE_STEP2 = "saveStep2",
    SAVE_STEP3 = "saveStep3",
    SAVE_STEP4 = "saveStep4",

    SAVE_STEP_OBJ_ACH= "saveStepObjetivesAchievements",
    OBJ_VALID = "objetivesValid",
    OBJ_INVALID = "objetivesInvalid",
    ACH_VALID = "achievementsValid",
    ACH_INVALID = "achievementsInvalid",

    SAVE_ALL_STEPS_DATA = "saveAllStepData"
}
