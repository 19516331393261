import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from "@angular/material/icon";
import { CheckImageService } from '../../../services/checkImage/check-image.service';

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.scss']
})
export class ThanksComponent implements OnInit {

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private checkImage: CheckImageService
  ) { 
    this.matIconRegistry.addSvgIcon(
      `check`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/check.svg')
    );
  }

  ngOnInit(): void {
    
    this.checkImage.setImageCssVar('thanks.jpg','bodyBackgroundImagen','../../../../');
  }

}
