<div class="background">

    <app-header></app-header>
    <app-navbar *ngIf="!isMobile"></app-navbar>
    <section>
        <div class="plans">
            <div class="flexLeft">
                <button mat-button (click)="navigate()">
                    <div class="flexBeetwenCenter">
                        <mat-icon class="blackIcon">
                            chevron_left
                        </mat-icon>
                    </div>
                </button>
                <p *ngIf="!edit" class="headline4">{{ 'planes.new.title' | translate }}</p>
                <p *ngIf="edit" class="headline4">{{ 'planes.forms.edit' | translate }}</p>
                
            </div>

            <form [formGroup]="planForm">
                <mat-card>
                    
                    <div class="margin">
                        <span *ngIf="!edit" class="headline5">{{ 'planes.new.new' | translate }}</span>
                        <br />
                        
                        <mat-form-field class="example-full-width" appearance="fill" class="inputPlanes">
                            <mat-label>{{ 'planes.forms.date' | translate }}</mat-label>
                            <input disabled id="semanaInput" matInput formControlName="semana" [matDatepickerFilter]="myFilter" [matDatepicker]="picker" [min]="minDate" required>
                            <mat-datepicker-toggle class="primaryIcon" matSuffix [for]="picker" ></mat-datepicker-toggle>
                            <mat-datepicker [disabled]="edit" #picker color="primary">
                                <mat-datepicker-actions>
                                    <button mat-button matDatepickerCancel>{{ 'calendar.cancel' | translate}}</button>
                                    <button mat-raised-button color="accent" matDatepickerApply>{{ 'calendar.save' | translate}}</button>
                                </mat-datepicker-actions>
                            </mat-datepicker>
                        </mat-form-field>
                        
                        <mat-form-field appearance="fill" class="inputPlanes">
                            <mat-label >{{ 'planes.forms.level' | translate }}</mat-label>
                            <mat-select id="levelInput" required placeholder="planForm.value.level.entrenamientoNivelDescripcion" formControlName="level" [disabled]="edit">
                                <mat-option *ngFor="let level of levels"
                                [value]="level">
                                {{level.entrenamientoNivelDescripcion}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                </div>
                
                <mat-tab-group mat-stretch-tabs mat-align-tabs="center">
                    <mat-tab label="Día 1">
                        <app-day-form *ngIf="loaded" [edit]="edit" [detalles]="detallesDia1" [planDetalleEtiqueta]="planDetalleEtiqueta" (editFormEvent)="editFormDays($event, 1)" (notValidEvent)="notValid($event)"></app-day-form>
                    </mat-tab>
                    <mat-tab label="Día 2">
                        <app-day-form *ngIf="loaded" [edit]="edit" [detalles]="detallesDia2" [planDetalleEtiqueta]="planDetalleEtiqueta" (editFormEvent)="editFormDays($event, 2)" (notValidEvent)="notValid($event)"></app-day-form>
                    </mat-tab>
                    
                </mat-tab-group>
            </mat-card>
            
            <div class="flexRigth">
                <button *ngIf="!edit" mat-raised-button color="accent" class="primaryButton2" [disabled]="!notDisabled" (click)="onSubmit()">
                    {{ 'planes.new.title' | translate | uppercase }}
                </button>
                
                <button *ngIf="edit" mat-raised-button color="accent" class="primaryButton2" [disabled]="!notDisabled" (click)="onSubmitEdit()">
                    {{ 'planes.forms.save' | translate | uppercase }}
                </button>
            </div>
            
        </form>
    </div>
</section>
</div>