import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AchievementRoute, Achievement, RouteOptions, AchievementDynamic, Distancias } from '../../../../api/interfaces/register';
import { DateAdapter } from '@angular/material/core';
import { SharedDataService } from '../../../../services/sharedData/shared-data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from "@angular/material/icon";
import { FormValidatorService } from '../../../../services/formValidator/form-validator.service';
import { MobileDetectorService } from '../../../../services/mobileDetector/mobile-detector.service'
import { ActivatedRoute } from '@angular/router';
import { FilterDataService } from 'src/app/services/filterData/filter-data.service';
import { AntecedentesEnum } from 'src/app/enums/antecedentes';
import { GenericDialogComponent } from 'src/app/components/generic-dialog/generic-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { LocalAuxData } from 'src/app/api/interfaces/user';
import { RegisterService } from 'src/app/api/services/register/register.service';
import { StepperEnum } from 'src/app/enums/stepper';
import { FlagsEnum } from '../../../../enums/sharedDataFlag';

@Component({
  selector: 'app-achievements',
  templateUrl: './achievements.component.html',
  styleUrls: ['./achievements.component.scss']
})
export class AchievementsComponent implements OnInit, OnDestroy {

  achievementsRoute: AchievementRoute[] = [];
  routeOptionsDistancias: Distancias[] = [];
  routeOptions: RouteOptions[] = [];
  achievements: Achievement[] = [];

  achievementsForm = new FormGroup({
    distance: new FormControl(),
    time: new FormControl(),
    date: new FormControl(),
    type: new FormControl()
  });

  achievementsRouteForm = new FormGroup({
    type: new FormControl(),
    id: new FormControl(),
    time: new FormControl(),
    date: new FormControl(),
  });

  isRegistration: boolean = false;
  rutaLoaded: boolean = false;
  isMobile: boolean = false;
  disable: boolean = false;
  isRuta: boolean = false;

  flag: string = "";

  private sharedDataFlag$: Subscription;
  private sharedData$: Subscription;

  @Input('route') achievementsRouteForEdit?: AchievementDynamic[];
  @Input('achievement') achievementsForEdit?: AchievementDynamic[];
  @Input('alumnoId') alumnoId?: number;
  @Input('edit') edit?: boolean;
  @Input('mode') mode?: string;

  @Output() changeAchievementEvent: EventEmitter<any> = new EventEmitter();
  @Output() emptyAchievementEvent = new EventEmitter<string>();

  constructor(
    private _adapter: DateAdapter<any>,
    private sharedData: SharedDataService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private formValidatorService: FormValidatorService,
    private mobileDetectorService: MobileDetectorService,
    private router: ActivatedRoute,
    private filterData: FilterDataService,
    public dialog: MatDialog,
    private registerService: RegisterService,
  ) {
    this.matIconRegistry.addSvgIcon(
      `delete`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../assets/delete.svg')
    );
  }

  ngOnInit(): void {
    const auxDelete: string = "localAuxData" + this.mode;
    sessionStorage.removeItem(auxDelete);
    this.isMobile = this.mobileDetectorService.isMobile();
    const url = this.router.snapshot.routeConfig.path;
    if (url == "registration") {
      this.isRegistration = true;
    }
    if (this.mode === "ruta") {
      this.isRuta = true;
      this.getOptions();
    }
    if (!this.edit) {
      this.addAchievement();
    }
    this._adapter.setLocale('es');
    this.sharedData$ = this.sharedData.currentMessage.subscribe(message => {
      this.flag = message;
      if (message === StepperEnum.SAVE_STEP_OBJ_ACH) {
        this.saveAchievement();
      }
    });

    this.sharedDataFlag$ = this.sharedData.currentFlag.subscribe(flag => {
      if(flag) {
        if(flag.key == FlagsEnum.editAchievements) {
          this.disable = flag.value;
        }
      }
    });
  }

  removeAchievement(i) {
    if (i >= 0) {
      if (!this.isRuta) {
        this.achievements.splice(i, 1);
      } else {
        this.achievementsRoute.splice(i, 1);
      }
      this.checkLength();
    }
    this.changeValidInput();
  }

  removeAchievementFromEdit(i: number) {
    if (i >= 0) {
      if (!this.isRuta) {
        if (this.achievementsForEdit[i].activo) {
          this.achievementsForEdit[i].activo = false;
          if (this.achievementsForEdit[i].id == null) {
            this.achievementsForEdit.splice(i, 1);
          }
        } else {
          this.achievementsForEdit.splice(i, 1);
        }
      } else {
        if (this.achievementsRouteForEdit[i].activo) {
          this.achievementsRouteForEdit[i].activo = false;
        } else {
          this.achievementsRouteForEdit.splice(i, 1);
        }
      }
    }
    this.changeValidInputEdit();
  }

  checkLength() {
    if (this.mode === "ruta") {
      if (this.achievementsRoute.length == 0) {
        this.emptyAchievementEvent.emit(this.mode);
      }
    } else {
      if (this.achievements.length == 0) {
        this.emptyAchievementEvent.emit(this.mode);
      }
    }
  }
  
  addAchievement() {
    this.changeAchievement(false);
    if (!this.isRuta) {
      if(!this.isRegistration) {
        this.achievementsForEdit.push({fechaMarca: "", tiempoMarcaMinutos: 0, tiempoMarcaSegundos: 0, tiempoMarcaHoras: 0, antecedentePreguntaDetalleId: 0, activo: true, id: null, valid: false  });
        this.changeValidInputEdit();
      } else {
        this.achievements.push({ distance: null, time: null, date: new Date(""), valid: false, id: null });
      }
    } else {
      if(!this.isRegistration) {
        this.achievementsRouteForEdit.push({ fechaMarca: "", tiempoMarcaMinutos: 0, tiempoMarcaSegundos: 0, tiempoMarcaHoras: 0, antecedentePreguntaDetalleId: AntecedentesEnum.RUTA, activo: true, id: null, valid: false  });
        this.changeValidInputEdit();
      } else {
      this.achievementsRoute.push({ routeType: { distanciaTipoId: null, distanciaDescripcion: null }, time: null, date: new Date(""), valid: false, id: null });
      }
    }
  }


  /**
   * Check achievement to determinate achievement.valid value, and call changeValidInput function
   * @param achievement 
   */
  saveAchievementFromInputComponent(achievement) {
    // ticket 2457
    const timeValid: boolean = (achievement.time.length == 6 && achievement.time != '000000');
    if (achievement.distance != null) {
      let position = this.achievements[achievement.i];
      position.distance = achievement.distance;
      position.time = achievement.time;
      position.date = achievement.date ? achievement.date : "";
      if (!timeValid || achievement.distance === '' || achievement.distance === null  ) {
        position.valid = false;
      } else {
        position.valid = true;
      }
    } else {
      let position = this.achievementsRoute[achievement.i];
      position.time = achievement.time;
      position.date = achievement.date ? achievement.date : "";
      position.routeType = achievement.type;
      if (!timeValid || achievement.type === '' || achievement.type === null) {
        position.valid = false;
      } else {
        position.valid = true;
      }
    }
    this.changeValidInput();
  }

  checkEntrys(): boolean {
    let contador: number = 0;
    let length: number = 0;
    let contadorRuta: number = 0;
    let lengthRuta: number = 0;

    if(this.achievementsForEdit) {
      if(this.achievementsForEdit.length > 0) {
        length = this.achievementsForEdit.length;
        this.achievementsForEdit.forEach(objetive => {
          if (objetive.valid) {
            contador++;
          }
        });
      }
    }

    if(this.achievementsRouteForEdit) {
      if(this.achievementsRouteForEdit.length > 0) {
        lengthRuta = this.achievementsRouteForEdit.length;
        this.achievementsRouteForEdit.forEach(objetive => {
          if (objetive.valid) {
            contadorRuta++;
          }
        });
      }
    }
    let valid: boolean = contador === length && contadorRuta == lengthRuta;
    // const aux: LocalAuxData = {
    //   valid: valid,
    //   mode: this.mode
    // };
    // const nombre: string = "localAuxData" + this.mode;
    // sessionStorage.setItem(nombre,JSON.stringify(aux));
    return (valid);
  }

  changeValidInputEdit() {
    if (this.checkEntrys()) {
      this.sharedData.changeMessage(StepperEnum.ACH_VALID);
    } else {
      this.sharedData.changeMessage(StepperEnum.ACH_INVALID);
    }
  }

  /**
   * Check every achievement for valid value, increasing contador value if is valid. Call changeAchievement function
   */
  changeValidInput() {
    let contador = 0;
    let length = 0;
    if (!this.isRuta) {
      length = this.achievements.length;
      this.achievements.forEach(element => {
        if (element.valid) {
          contador++;
        }
      });
    } else {
      length = this.achievementsRoute.length;
      this.achievementsRoute.forEach(element => {
        if (element.valid) {
          contador++;
        }
      });
    }
    if (contador === length) {
      this.changeAchievement(true);
    } else {
      this.changeAchievement(false);
    }
  }

  /**
   * Emits mode (ruta, pista, ultra, trail) and valid (boolean). Use in onboarding(registration).
   * @param valid
   */
  changeAchievement(valid: boolean) {
    let aux = {
      valid: valid,
      mode: this.mode
    };
    this.changeAchievementEvent.emit(aux);
  }

  /**
   * Save current achievements (ruta, ultra, pista o trail) in sessionStorage
   */
  saveAchievement() {
    let aux;
    if (!this.isRuta) {
      aux = this.achievements;
    } else {
      aux = this.achievementsRoute;
    }
    sessionStorage.setItem(this.mode, JSON.stringify(aux));
  }

  /**
   * Remove current achievements (ruta, ultra, pista o trail) from sessionStorage
   */
  deleteAchievement() {
    sessionStorage.removeItem(this.mode);
  }

  getOptions() {
    this.registerService.getRouteOptions()
    .subscribe(response => {
      this.routeOptionsDistancias = response;
      this.routeOptionsDistancias.forEach(option => {
        option.selected = false;
      });
      this.rutaLoaded = true;
    },
      error => {
        console.error(error);
      });
  }

  public ngOnDestroy(): void {
    this.sharedData$.unsubscribe();
    this.sharedDataFlag$.unsubscribe();
  }
}