import { Component, OnInit, Input } from '@angular/core';
import { MessagesService } from '../../../../api/services/messages/messages.service';
import { SnackbarService } from 'src/app/utils/snackbar/snackbar.service';
import { Canal, Mensaje, Message } from '../../../../api/interfaces/messages';
import { SharedDataService } from '../../../../services/sharedData/shared-data.service';
import { MobileDetectorService } from 'src/app/services/mobileDetector/mobile-detector.service';

/**
 * Get message from canal and send them to MessageComponent.
 * Recieve new messages from NewMessageComponent.
 * @param canal canal data, interface Canal
 */
@Component({
  selector: 'app-conversation',
  templateUrl: './conversation.component.html',
  styleUrls: ['./conversation.component.scss']
})
export class ConversationComponent implements OnInit {

  @Input('canal') canal: Canal;
  messages: Mensaje[] = [];

  loaded: boolean = false;
  isMobile: boolean = false;

  CANTIDAD_ELEMENTOS: number = 10;
  pagina: number = 0;

  moreMessagesAvalaible: boolean = true;
  canalChanged: boolean = true;

  messagesClass: string = "messages";

  constructor(
    private sharedData: SharedDataService,
    private messagesService: MessagesService,
    private snack: SnackbarService,
    private mobileDetectorService: MobileDetectorService,
  ) { }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    this.sharedData.currentMessage.subscribe(message => {
      if (message === "changeMessageOption") {
        this.canal =  JSON.parse(sessionStorage.getItem('canal'));
        this.pagina = 0;
        this.canalChanged = true;
        this.loadMessages();
      }
    });
    this.loadMessages();
  }

  loadMessages() {
    this.loaded = false;
    if(this.pagina == 0) {
      this.messages = [];
    }
    this.messagesService.getMensajesPorCanal(this.canal.id, this.CANTIDAD_ELEMENTOS, this.pagina).subscribe(response => {
      let messages: Mensaje[] = response.content;
      if(this.pagina == 0) {
        messages = messages.reverse();
        messages = this.fixMetadata(messages);
        this.messages = messages;
      } else {
        messages = this.fixMetadata(messages);
        messages.forEach(aux => this.messages.unshift(aux));
      }
      if(response.totalPages > (this.pagina + 1)) {
        this.moreMessagesAvalaible = true;
      } else {
        this.moreMessagesAvalaible = false;
      }
      this.loaded = true;
    }, error => {
      console.error(error);
      this.snack.snackTranslate('snacks.error.generic');
      this.loaded = true;
    });
  }

  fixMetadata(messages: Mensaje[]) {
    messages.forEach(message => {
      if(message.metadata && message.mensajeTipo == "FEEDBACK") {
        let aux: string [] = message.metadata.split('¬');
        let aux2: string [] = aux[0].split('T');
        aux2[1] = "T12:00:00.000+00:00¬";
        message.metadata = aux2[0]+aux2[1]+aux[1];
      }
    });
    return messages;
  }

  newMessageEvent(message: Mensaje) {
    this.messages.push(message);
  }

  onUpMessages() {
    if(this.moreMessagesAvalaible) {
      this.pagina++;
      this.loadMessages();
    }
  }

  changeMessage(value: boolean) {
    if(value) {
      this.messagesClass = "messages smallConversation transitionMessagesInput";
    } else {
      this.canalChanged? this.messagesClass = "messages largeConversation" : this.messagesClass = "messages largeConversation transitionMessagesInput";
    }
    this.canalChanged = false;
  }
}
