import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';

import { MaterialModule } from '../../../material/material.module';
import { GlobalModule } from '../../global/modules/global.module'
import { MessagesRoutingModule } from './messages-routing.module';
import { MessagePageComponent} from '../message-page/message-page.component';
import { MessageComponent } from '../conversation/message/message.component';
import { SearchComponent } from '../list/search/search.component';
import { OptionComponent } from '../list/option/option.component';
import { MessageHeaderComponent } from '../conversation/message-header/message-header.component';
import { MessagesListComponent } from '../list/messages-list/messages-list.component';
import { NewMessageComponent } from '../conversation/new-message/new-message.component';
import { ConversationComponent } from '../conversation/conversation/conversation.component';
import { NewMassiveComponent } from '../massive/new-massive/new-massive.component';
import { MessageMassiveComponent } from '../massive/message-massive/message-massive.component';
import { ConversationMassiveComponent } from '../massive/conversation-massive/conversation-massive.component';
import { UserTagComponent } from '../list/user-tag/user-tag.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import { PlansModule } from '../../plans/modules/plans.module';

@NgModule({
  declarations: [
    MessagePageComponent,
    MessageComponent,
    SearchComponent,
    OptionComponent,
    MessageHeaderComponent,
    MessagesListComponent,
    NewMessageComponent,
    ConversationComponent,
    NewMassiveComponent,
    MessageMassiveComponent,
    ConversationMassiveComponent,
    UserTagComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    GlobalModule,
    MessagesRoutingModule,
    BrowserAnimationsModule,
    InfiniteScrollModule,
    PlansModule,

    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    })
  ],
  exports: []
})
export class MessagesModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}