import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FiltratedObjetives, Objetive } from '../../../../api/interfaces/register';
import { DateAdapter } from '@angular/material/core';
import { SharedDataService } from '../../../../services/sharedData/shared-data.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from "@angular/material/icon";
import { FormValidatorService } from '../../../../services/formValidator/form-validator.service';
import { MobileDetectorService } from '../../../../services/mobileDetector/mobile-detector.service';
import { FilterDataService } from 'src/app/services/filterData/filter-data.service';
import { ActivatedRoute } from '@angular/router';
import { GenericDialogComponent } from 'src/app/components/generic-dialog/generic-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { StepperEnum } from 'src/app/enums/stepper';
import { FlagsEnum } from '../../../../enums/sharedDataFlag';


@Component({
  selector: 'app-objetives',
  templateUrl: './objetives.component.html',
  styleUrls: ['./objetives.component.scss']
})
export class ObjetivesComponent implements OnInit, OnDestroy {

  objetivesForm = new FormGroup({
    name: new FormControl(),
    date: new FormControl(),
  });

  @Input('objetives') objetivesForEdit?: FiltratedObjetives[];
  @Input('alumnoId') alumnoId?: number;
  
  @Output() emptyEvent = new EventEmitter<boolean>();
  @Output() saveObjetivesEditEvent = new EventEmitter<FiltratedObjetives[]>();

  objetives: Objetive[] = [];
  flag: string = "";

  isRegistration: boolean = false;
  isMobile: boolean = false;
  disable: boolean = false;

  private sharedData$: Subscription;
  private sharedDataFlag$: Subscription;

  constructor(
    private _adapter: DateAdapter<any>,
    private sharedData: SharedDataService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private formValidatorService: FormValidatorService,
    private mobileDetectorService: MobileDetectorService,
    private filterData: FilterDataService,
    private router: ActivatedRoute,
    public dialog: MatDialog,
  ) {
    this.matIconRegistry.addSvgIcon(
      `delete`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../assets/delete.svg')
    );
  }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    const url = this.router.snapshot.routeConfig.path;
    if(url == "registration") {
      this.isRegistration = true;
    }
    if (!this.objetivesForEdit) {
      this.addObjetive();
    }
    this._adapter.setLocale('es');

    this.sharedDataFlag$ = this.sharedData.currentFlag.subscribe(flag => {
      if(flag) {
        if(flag.key == FlagsEnum.editObjetives) {
          this.disable = flag.value;
        }
      }
    });

    this.sharedData$ = this.sharedData.currentMessage.subscribe(message => {
      this.flag = message
      if (message === StepperEnum.SAVE_STEP_OBJ_ACH) {
        this.saveObjetives();
      }
    });
  }

  removeObjetive(i: number) {
    if (i >= 0) {
      this.objetives.splice(i, 1);
      this.checkLength();
    }
    this.changeValidInput();
  }

  addObjetive() {
    if(!this.isRegistration) {
      this.objetivesForEdit.push({ objetivoDescripcion: "", fechaObjetivo: "", activo: true, id: null, valid: false, i: null });
    } else {
      this.objetives.push({ name: "", date: new Date(""), valid: false, activo: true, id: null });
    }
  }

  saveObjetiveFromInputComponent(objetive) {
    const nameInvalid: boolean = (objetive.name || objetive.name == '');
    const dateInvalid: boolean = (objetive.date || objetive.date == '');

    if(!this.isRegistration) {
      if(nameInvalid || dateInvalid) {
        const aux: FiltratedObjetives = this.filterData.filterObjetive(objetive);
        //bugfix
        let index: number = -1;
        if(aux.id) {
          index = this.objetivesForEdit.findIndex(objetive => objetive.id == aux.id);
        } else {
          index = objetive.i;
        }
        if(index != -1) {
          this.objetivesForEdit[index].fechaObjetivo = aux.fechaObjetivo;
          this.objetivesForEdit[index].objetivoDescripcion = aux.objetivoDescripcion;

          //date is no longer optional ticket 2177
          const descripcionValid: boolean = (aux.objetivoDescripcion != '' && aux.objetivoDescripcion != null);
          const fechaValid: boolean = (aux.fechaObjetivo != '' && aux.fechaObjetivo != null);

          if( descripcionValid && fechaValid) {
            this.objetivesForEdit[index].valid = true;
          } else {
            this.objetivesForEdit[index].valid = false;
          }
        } else {
          this.objetivesForEdit.push(aux);
        }
      }
    } else { 
      //onboarding
      let position = this.objetives[objetive.i]
      
      position.name = objetive.name;
      position.date = objetive.date;
      //date is optional
      const nombreNotEmpty: boolean = (objetive.name && objetive.name !== '') ? true : false;
      const dateNotEmpty: boolean = objetive.date ? true : false;
      if ( nombreNotEmpty && dateNotEmpty  ) {
        position.valid = true;
      } else {
        position.valid = false;
      }
    }
    this.changeValidInput();
  }

  changeValidInput() {
    let contador = 0;
    if(!this.isRegistration) {
      this.objetivesForEdit.forEach(objetive => {
        if (objetive.valid) {
          contador++;
        }
      });
      if (contador === this.objetivesForEdit.length) {
        this.sharedData.changeMessage(StepperEnum.OBJ_VALID);
      } else {
        this.sharedData.changeMessage(StepperEnum.OBJ_INVALID);
      }
    } else {
      this.objetives.forEach(objetive => {
        if (objetive.valid) {
          contador++;
        }
      });
      if (contador === this.objetives.length) {
        this.formValidatorService.onDynamicValidation("VALID", "objetives");
      } else {
        this.formValidatorService.onDynamicValidation("INVALID", "objetives");
      }
    }
  }

  /**
   * Save objetives in sessionStorage
   */
  saveObjetives() {
    sessionStorage.setItem('objetives', JSON.stringify(this.objetives));
  }

  removeObjetiveFromEdit(i: number) {
    this.objetivesForEdit.splice(i, 1);
  }

  checkLength() {
    if (this.objetives.length == 0) {
      this.emptyEvent.emit(true);
    }
  }

  public ngOnDestroy(): void {
    this.sharedData$.unsubscribe();
    this.sharedDataFlag$.unsubscribe();
  }
}