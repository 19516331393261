import { Component, OnInit } from '@angular/core';
import { MonthTariff } from '../../../../api/interfaces/domiciliacion';
import { MobileDetectorService } from '../../../../services/mobileDetector/mobile-detector.service';
import { SharedDataService } from '../../../../services/sharedData/shared-data.service';

/**
 * Set active domiciliacion navbar button
 * Call MainTableDomiciliacionComponent onInit and switch to MonthTableDomiciliacionComponent using changeTable()
 * Switch from MonthTableDomiciliacionComponent to Call MainTableDomiciliacionComponent using changeTable()
 */
@Component({
  selector: 'app-page-domiciliacion',
  templateUrl: './page-domiciliacion.component.html',
  styleUrls: ['./page-domiciliacion.component.scss']
})
export class PageDomiciliacionComponent implements OnInit {

  isMobile: boolean = false;
  showMain: boolean = true;
  constructor(
    private mobileDetectorService: MobileDetectorService,
    private sharedData: SharedDataService
  ) { }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    sessionStorage.setItem('active', 'cobranzas');
  }

  changeTable(element: MonthTariff) {
    this.showMain = !this.showMain;
    sessionStorage.setItem('selectedMonth',JSON.stringify(element));
  }
}
