import { Component, OnInit, Input , Output, EventEmitter } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

/**
 * Display files and allow user to select files to delete
 * @param archivos File[]
 * @example html 
 * ```html
 *  <app-files 
 *    [archivos]="archivos"
 *    (deleteFilesEvent)="deleteFiles($event)">
 *    </app-files>
 * ```
 * 
 * @example typescript
 * ```ts
 *  deleteFiles(indexs: number[]) {
 *    for (var i = indexs.length -1; i >= 0; i--) {
 *      this.archivos.splice(indexs[i],1);
 *     }
 *  }
 * ```
 */
@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss']
})
export class FilesComponent implements OnInit {

  typesOfShoes: string[] = ['Boots', 'Clogs', 'Loafers', 'Moccasins', 'Sneakers'];

  @Input('archivos') archivos: File[];
  // ticket 3113
  @Input('showDelete') showDelete: boolean;
  
  @Output() deleteFilesEvent = new EventEmitter<number>();
  constructor(

    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
   
  ) {  this.matIconRegistry.addSvgIcon(
    `delete`,
    this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/delete.svg')
  );}

  ngOnInit(): void {
  }

  delete(i: number) {
    this.deleteFilesEvent.emit(i);
  }

}
