<app-title 
    [physiological]="true" 
    [disable]="disable" 
    [valid]="physiologicalForm.valid" 
    [estado]="estado"
    (onSubmitEvent)='onSubmit()' 
    (editEvent)='edit()'>
</app-title>

<form [formGroup]="physiologicalForm">

    <mat-form-field class="inputTwoLine" appearance="fill">
        <mat-label>{{ 'forms.physiological.weight' | translate}}</mat-label>
        <input min="1" max="999" required [disabled]="disable" id="weightInput" formControlName="peso" type="number" matInput>
        <mat-hint matSuffix>kg</mat-hint>
    </mat-form-field>

    <mat-form-field class="inputTwoLine" appearance="fill">
        <mat-label>{{ 'forms.physiological.height' | translate}}</mat-label>
        <input min="1" max="999" required [disabled]="disable" id="heightInput" formControlName="altura" type="number" matInput>
        <mat-hint matSuffix>cm</mat-hint>
    </mat-form-field>
    
</form>
