import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {DateAdapter} from '@angular/material/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from "@angular/material/icon";
import { MobileDetectorService } from 'src/app/services/mobileDetector/mobile-detector.service';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';
import { SuspensionAlumno, SuspensionMotivo } from '../../../../../api/interfaces/user';
import { MatDialog } from '@angular/material/dialog';
import { GenericDialogComponent } from 'src/app/components/generic-dialog/generic-dialog.component';

@Component({
  selector: 'app-inactividad-inputs',
  templateUrl: './inactividad-inputs.component.html',
  styleUrls: ['./inactividad-inputs.component.scss']
})
export class InactividadInputsComponent implements OnInit {

  isMobile: boolean = false;
  activo: boolean = true;

  minDateEnd: Date = new Date();
  minDate: Date = new Date();
  selected: Date;

  suspensionesForm = new FormGroup({
    fechaDesde: new FormControl(),
    fechaHasta: new FormControl(),
    suspensionMotivoId: new FormControl(),
    i: new FormControl(),
    id: new FormControl(),
  });



  @Output() removeSuspensionesEvent = new EventEmitter<any>();
  @Output() addSuspensionesEvent: EventEmitter<any> = new EventEmitter();
  
  @Input('i') i?: number;
  @Input('disable') disable?: boolean;
  @Input('suspensiones') suspensiones? : SuspensionAlumno;
  @Input('motivos') motivos: SuspensionMotivo[];

  constructor(
    private _adapter: DateAdapter<any>,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private mobileDetectorService: MobileDetectorService,
    private sharedData: SharedDataService,
    public dialog: MatDialog,
  ) {
    this.matIconRegistry.addSvgIcon(
      `delete`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../assets/delete.svg')
    );
   }

   ngOnInit(): void {
    this.sharedData.currentMessage.subscribe(message => {
      if(message =="editsuspensiones") {
        this.suspensionesForm.controls['fechaDesde'].enable();
        this.suspensionesForm.controls['fechaHasta'].enable();
        this.suspensionesForm.controls['suspensionMotivoId'].enable();
      }
    });
    this.isMobile = this.mobileDetectorService.isMobile();
    this.addSuspensiones();
    this._adapter.setLocale('es');
    if(this.suspensiones) {
      this.suspensionesForm.patchValue({fechaDesde: this.suspensiones.fechaDesde});
      this.suspensionesForm.patchValue({fechaHasta: this.suspensiones.fechaHasta});
      this.suspensionesForm.patchValue({suspensionMotivoId: this.suspensiones.suspensionMotivoId});
      this.suspensionesForm.patchValue({id: this.suspensiones.id});      
      this.suspensionesForm.patchValue({i: this.i});      
    }
    if(this.disable) {
      this.suspensionesForm.controls['fechaDesde'].disable();
        this.suspensionesForm.controls['fechaHasta'].disable();
        this.suspensionesForm.controls['suspensionMotivoId'].disable();
    }
    this.suspensionesForm.statusChanges
    .subscribe(val => {
      if(this.suspensionesForm.valid) {
        this.addSuspensiones();
      }
    });
  }

  removeSuspensiones() {
    const aux : any = {
      id : this.suspensiones.id,
      i : this.i
    }
    if(!this.suspensiones.editable) {

      const dialogRef = this.dialog.open(GenericDialogComponent, {
        data: {
          title: "¿Desea eliminar la baja parcial?",
          iconClass: "bigGreyIcon",
          text: "El alumno volverá a recibir los planes de entrenamiento",
          icon: "error_outlined",
          cancel: "ELIMINAR",
          confirm: "NO"
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        if (!result) {
          this.removeSuspensionesEvent.emit(aux);
        }
      });
    } else {
      this.removeSuspensionesEvent.emit(aux);
    }
    
  }

  addSuspensiones() {
    const { fechaDesde } = this.suspensionesForm.getRawValue();
    if(fechaDesde) {
      this.minDateEnd = new Date(fechaDesde);
    } else {
      this.minDateEnd = new Date();
    }
    this.addSuspensionesEvent.emit(this.suspensionesForm.value);
  }

}
