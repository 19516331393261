import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { MaterialModule } from '../../../material/material.module';

import { RadioInputComponent } from '../types/radio-input/radio-input.component';
import { SelectInputComponent } from '../types/select-input/select-input.component';
import { TextAreaInputComponent } from '../types/text-area-input/text-area-input.component';
import { TextInputComponent } from '../types/text-input/text-input.component';
import { CheckboxInputComponent } from '../types/checkbox-input/checkbox-input.component';
import { MainFormDinamicoComponent } from '../main-form-dinamico/main-form-dinamico.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgxMaskModule, IConfig } from 'ngx-mask'
import { RadioTextAreaInputComponent } from '../types/radio-text-area-input/radio-text-area-input.component';
import { RadioCheckboxTextAreaInputComponent } from '../types/radio-checkbox-text-area-input/radio-checkbox-text-area-input.component';


const maskConfig: Partial<IConfig> = {
  validation: false,
};


@NgModule({
  declarations: [
    
    MainFormDinamicoComponent,
    TextInputComponent,
    TextAreaInputComponent,
    RadioInputComponent,
    SelectInputComponent,
    CheckboxInputComponent,
    RadioTextAreaInputComponent,
    RadioCheckboxTextAreaInputComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,

    NgxMaskModule.forRoot(maskConfig),

    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    })
  ],
  exports: [
    MainFormDinamicoComponent
  ]
})
export class FormsDinamicosModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}