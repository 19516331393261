import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FlagsGroups } from 'src/app/enums/sharedDataFlag';
import { MobileDetectorService } from 'src/app/services/mobileDetector/mobile-detector.service';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';

@Component({
  selector: 'app-groups-main',
  templateUrl: './groups-main.component.html',
  styleUrls: ['./groups-main.component.scss']
})
export class GroupsMainComponent implements OnInit, OnDestroy {

  @Output() toggleOptionEvent = new EventEmitter<number>();

  private sharedData$: Subscription;

  showNew: boolean = false;
  edit: boolean = false;
  isMobile: boolean = false;
  constructor(
    private mobileDetectorService: MobileDetectorService,
    private sharedData: SharedDataService
  ) { }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    this.sharedData$ = this.sharedData.currentMessage.subscribe(message => {
      if (message === FlagsGroups.editGroup) {
        this.toggleEdit();
        // ticket 4183 clear sharedData message
        this.sharedData.changeMessage("");
      }
    });
  }

  toggleNew() {
    this.showNew = !this.showNew;
    this.edit = false;
  }

  toggleEdit() {
    this.edit = !this.edit;
    this.showNew = false;
  }

  toggleOption(option: number) {
    this.toggleOptionEvent.emit(option);
    this.edit = false;
  }

  public ngOnDestroy(): void {
    this.sharedData$.unsubscribe();
  }

}
