import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { DomiciliacionService } from '../../../../api/services/domiciliacion/domiciliacion.service';
import { SnackbarService } from '../../../../utils/snackbar/snackbar.service';
import { ImageService } from '../../../../api/services/image/image.service';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from "@angular/material/icon";
import { MobileDetectorService } from '../../../../services/mobileDetector/mobile-detector.service';
import { SharedDataService } from '../../../../services/sharedData/shared-data.service';
import { animate, state, style, transition, trigger} from '@angular/animations';
import { MonthTariff } from '../../../../api/interfaces/domiciliacion';
import { NewPopupDomiciliacionComponent } from '../new-popup-domiciliacion/new-popup-domiciliacion.component';

import { MatDialog } from '@angular/material/dialog';

/**
 * Display the following data for every billing period:
 * month/year, amountStudents, $ total ,students not exported
 * Can navigate to MonthTableDomiciliacionComponent using changeTableEvent
 * Can open NewPopupDomiciliacionComponent
 */
@Component({
  selector: 'app-main-table-domiciliacion',
  templateUrl: './main-table-domiciliacion.component.html',
  styleUrls: ['./main-table-domiciliacion.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class MainTableDomiciliacionComponent implements OnInit {

  totalElements: number = 0;
  totalPages: number = 0;
  size: number = 10;
  page: number = 0;

  first: boolean = true;
  last: boolean = false;
  done: boolean = false;

  isMobile: boolean = false;
  noData: boolean = false;

  @Output() changeTableEvent = new EventEmitter<MonthTariff>();

  constructor(
    private router: Router,
    private domiciliacionService: DomiciliacionService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private mobileDetectorService: MobileDetectorService,
    private sharedData: SharedDataService,
    public dialog: MatDialog,
  ) {
    this.matIconRegistry.addSvgIcon(
      `delete`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../assets/delete.svg')
    );
  }
  displayedColumns: string[] = ['month/year','amountStudents','total','export','actions'];
  displayedColumnsMobile: string[] = ['month/year','export','actions'];
  domiciliacionData: MonthTariff[];
  loaded: boolean = false;

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    this.loadData();
    this.sharedData.currentMessage.subscribe(message => {
      if (message === "reloadData") {
        this.loadData();
      }
    });

  }

  loadMore(event) {
    this.page = event;
    this.loadData();
  }

  loadData() {
    this.domiciliacionData = [];
    this.noData = false;
    this.loaded = false;

    this.domiciliacionService.getDomiciliacionPageable(this.page, this.size).subscribe( response => {
      this.totalPages = response.totalPages;
      this.first = response.first;
      this.last = response.last;
      this.done = true;
      if (response.content.length == 0) {
        this.noData = true;
        this.loaded = true;
      } else {
        response.content.forEach(student => {
          this.convertAndPushColumn(student)
        });
        this.loaded = true;
      }
    },
    error => {
      console.error(error);
    });
  }

  convertAndPushColumn(element) {
    let aux: MonthTariff = {
      id: element[0],
      date: element[1],
      students: element[2],
      total: element[3],
      noExport: element[4]
    };
    if (aux != null) {
      this.domiciliacionData.push(aux);
    }
  }

  edit(element: MonthTariff) {
    this.changeTableEvent.emit(element);
  }

  openDialog() {
    const dialogRef = this.dialog.open(NewPopupDomiciliacionComponent);

    dialogRef.afterClosed().subscribe(result => {
        this.loadData();
    });
  }


}
