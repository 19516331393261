<div *ngIf="!loading && !done && !error" class="dialogContainer450">
    <div class="flexCenter">
        <mat-icon [class]="data.iconClass">{{data.icon}}</mat-icon>
    </div>
    <br/>

    <span *ngIf="data.habilitar">
        <p class="headline5">{{'planes.process.dialogHabilitar.title' | translate}}</p>
            <div class="textPopup">
                <span class="subtitle1">
                    {{'planes.process.dialogHabilitar.text' | translate}}
                    <br/>
                    <span class="primaryColor">{{data.diaSemanaActual}}</span>
                    {{'planes.process.dialogHabilitar.text2' | translate}}
                    <span class="primaryColor">{{data.dia}}</span>
                </span>
            </div>
        <br/>
    </span>

    <span *ngIf="!data.habilitar">
        <p class="headline5">{{'planes.process.dialog.title' | translate}}</p>
        <div class="textPopup">
            <p class="subtitle1">
                {{'planes.process.dialog.text' | translate}} 
                <span class="primaryColor">{{data.dia}}</span>
            </p>
        </div>
        <br/>
        
        <div class="flexCenter" *ngIf="data.pending.length != 0">
            <mat-icon class="statusPendingIcon">error_outline</mat-icon>
            <p class="statusPendingText">{{'planes.process.dialog.preLength' | translate}} {{data.pending.length}} {{'planes.process.dialog.postLength' | translate}}</p>
        </div>
        <div class="flexLeft">
            <div *ngFor="let alumno of data.pending">
                <p class="subtitle1">{{alumno.nombre}} {{alumno.apellido}}</p>
            </div>
        </div>
    </span>
    <div class="flexEvenly" *ngIf="data.habilitar">
        <button 
            mat-stroked-button
            color="primary" 
            class="confirmButtonDialog"
            mat-dialog-close >
            NO
        </button>
        
        <button 
            mat-raised-button 
            color="accent" 
            class="cancelButtonDialog"  
            (click)="onSubmit()">
            {{'planes.process.dialogHabilitar.confirmButton' | translate}}
        </button>
    </div>

    <div class="flexEvenly" *ngIf="!data.habilitar">
        <button 
        mat-stroked-button
            color="primary" 
            class="confirmButtonDialog"
            mat-dialog-close >
            NO
        </button>

        <button 
            mat-raised-button 
            color="accent"  
            class="cancelButtonDialog"  
            (click)="onSubmit()">
            {{'planes.process.dialog.confirmButton' | translate}}
        </button>
    </div>

</div>

<app-loading *ngIf="loading" [showText]="true" [habilitar]="data.habilitar"></app-loading>

<div *ngIf="done" class="dialogContainer450">
    <div class="flexCenter">
        <mat-icon *ngIf="!data.habilitar" class="bigGreenIcon">check_circle</mat-icon>
        <mat-icon *ngIf="data.habilitar" class="bigLigthPrimaryIcon">check_circle</mat-icon>
    </div>
    <br/>
    <p class="headline5">
        <span *ngIf="!data.habilitar">
            {{'planes.process.done.title' | translate}}
        </span>
        <span *ngIf="data.habilitar">
            {{'planes.process.done.titleHabilitar' | translate}}
        </span>
    </p>
    <div *ngIf="!data.habilitar" class="textPopup">
        <p class="subtitle1">
            {{'planes.process.done.text' | translate}} 
            <span class="primaryColor">{{data.dia}}</span>
        </p>
    </div>

    <div *ngIf="data.habilitar" class="textPopup">
        <span class="subtitle1">
            {{'planes.process.done.textHabilitar' | translate}} 
            <br/>
            <span class="primaryColor">{{data.diaSemanaActual}}</span>
            {{'planes.process.done.textHabilitar2' | translate}} 
            <span class="primaryColor">{{data.dia}}</span>
        </span>
    </div>
    <br/>
    <div class="flexEvenly">
        <button [mat-dialog-close]="true" mat-raised-button color="accent">{{'planes.process.done.confirmButton' | translate}}</button>
    </div>
</div>

<div *ngIf="error" class="dialogContainer450">
    <div class="flexCenter">
        <mat-icon class="bigRedIcon">warning</mat-icon>
    </div>
    <br/>
    <p class="headline5">
        <span *ngIf="!data.habilitar">
            {{'planes.process.error.titleNoHabilitar' | translate}}
        </span>
        <span *ngIf="data.habilitar">
            {{'planes.process.error.titleHabilitar' | translate}}
        </span>
    </p>
    <div class="textPopup">
        <p class="subtitle1">
            {{'planes.process.error.text' | translate}} 
        </p>
    </div>

    <br/>
    <div class="flexEvenly">
        <button [mat-dialog-close]="true" mat-raised-button color="accent">{{'planes.process.done.confirmButton' | translate}}</button>
    </div>
</div>