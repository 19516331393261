import { Component, OnInit } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '../../../utils/snackbar/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../api/services/login-service.service';
import { ResetPasswordRepeat } from '../../../api/interfaces/login';
import { TokenDecoderService } from '../../../services/tokenDecoder/token-decoder.service';
import { StatusEnum } from 'src/app/enums/status';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  hide: boolean = true;
  firstTime: boolean = false;
  hintClass: string = "hideHint";
  idResetPass: number;

  equipoNombre: string = "";

  changeForm = new FormGroup({
    password: new FormControl('', [
      Validators.required,
      Validators.pattern(/^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/),
    ]),
    repeatPassword: new FormControl('', [
      Validators.required
    ])
  });
  constructor(
    private snack: SnackbarService,
    private router: Router,
    private translate: TranslateService,
    private loginApiService: LoginService,
    private route: ActivatedRoute,
    private tokenDecoderService: TokenDecoderService,
  ) { }

    ngOnInit() {
      this.route.paramMap.subscribe(params => {
        this.idResetPass = params['params'].id;
        if(this.idResetPass !== undefined) {
          // this.idResetPass = parseInt(sessionStorage.getItem('id'),10);
          this.loginApiService.getUserData().subscribe(response => {      
            localStorage.setItem('idUsuario',response.idUsuario.toString());
            if(response.perfilAlumno) {
              localStorage.setItem('idAlumno',response.perfilAlumno.id.toString());
            }
            if (response.perfilAlumno.estado === StatusEnum.CREATED) {
              // sessionStorage.setItem('id', response.idResetPass.toString());
              this.firstTime = true;
            } else {
              this.firstTime = false;
            }
          }, error => {
            console.error(error);
            this.snack.snackTranslate('snacks.error.generic');
          });
        } else {
          this.firstTime = false;
        }
       });

      this.equipoNombre = localStorage.getItem('equipoNombre');
    }

    showHintOnClick() {
      this.hintClass = "primaryHint";
    }

    nueva() {
      let role: string = this.tokenDecoderService.getRole();
      this.loginApiService.setNewPass({
        clave: this.changeForm.value.password,
        claveVerificacion: this.changeForm.value.repeatPassword,
      } as ResetPasswordRepeat)
      .subscribe(respuesta => {
        this.snack.snackTranslate('changePassword.success');
        if(this.firstTime && role === "ROLE_ALUMNO") {
          this.router.navigate(['/welcome']);
        } else {
          this.router.navigate(['/login']);
        }
      },
        error => {
          console.error(error);
        });
    }

    onSubmit() {
      if (this.changeForm.value.password != this.changeForm.value.repeatPassword) {
        this.snack.snackTranslate('snacks.error.passwordDontMatch');
      } else {
        this.nueva();
      }
    }
}