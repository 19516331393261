<div class="flexStudents">
  <p class="headline6">{{ 'admin.domiciliacion.title' | translate }}</p>
    <div>
      <button 
        *ngIf="!isMobile" 
        mat-raised-button color="accent" 
        class="primaryButton2" 
        (click)="openDialog()">
          {{ 'admin.domiciliacion.new' | translate }}
      </button>
      <button 
        *ngIf="isMobile" 
        mat-raised-button color="accent" 
        class="primaryButton2" 
        (click)="openDialog()">
          +
      </button>
    </div>
</div>

<div class="noData" *ngIf="loaded && noData">
  <span class="headline6Light"> {{'admin.domiciliacion.mainTable.empty' | translate}}</span>
</div>

<div class="table-container" *ngIf="loaded && !noData">
  <table *ngIf="!isMobile" mat-table [dataSource]="domiciliacionData" multiTemplateDataRows>

    <ng-container matColumnDef="month/year">
      <th mat-header-cell *matHeaderCellDef> {{'admin.domiciliacion.mainTable.yearMonth' | translate}} </th>
      <td mat-cell *matCellDef="let element">
        <span class="subtitle2">{{element.date}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="amountStudents">
      <th mat-header-cell *matHeaderCellDef> {{'admin.domiciliacion.mainTable.amount' | translate}} </th>
      <td mat-cell *matCellDef="let element">
        <span class="subtitle2">{{element.students}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef> {{'admin.domiciliacion.mainTable.total' | translate}} </th>
      <td mat-cell *matCellDef="let element">
        <span class="subtitle2">{{element.total}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="export">
      <th mat-header-cell *matHeaderCellDef> {{'admin.domiciliacion.mainTable.noExport' | translate}} </th>
      <td mat-cell *matCellDef="let element">
        <span class="subtitle2">{{element.noExport}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions" stickyEnd>
      <th mat-header-cell *matHeaderCellDef> {{'admin.studentsTable.header.actions' | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <div class="flexActions">
          <button mat-icon-button>
            <mat-icon id="editButton" class="delete" (click)="edit(element)">
              edit
            </mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>


  <table *ngIf="isMobile" mat-table [dataSource]="domiciliacionData" multiTemplateDataRows>

    <ng-container matColumnDef="month/year">
      <th mat-header-cell *matHeaderCellDef> {{'admin.domiciliacion.mainTable.yearMonth' | translate}} </th>
      <td mat-cell *matCellDef="let element">
        <span class="subtitle2">{{element.date}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="export">
      <th mat-header-cell *matHeaderCellDef> {{'admin.domiciliacion.mainTable.noExport' | translate}} </th>
      <td mat-cell *matCellDef="let element">
        <span class="subtitle2">{{element.noExport}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions" stickyEnd>
      <th mat-header-cell *matHeaderCellDef> {{'admin.studentsTable.header.actions' | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <div class="flexActions">
          <button mat-icon-button>
            <mat-icon id="editButton" class="delete" (click)="edit(element)">
              edit
            </mat-icon>
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumnsMobile"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsMobile;"></tr>
  </table>

</div>

<app-paginator-complete *ngIf="loaded" 
  [first]="first" 
  [last]="last" 
  [page]="page" 
  [totalPages]="totalPages"
  [size]="size" 
  [totalElements]="totalElements" 
  (loadMoreEvent)="loadMore($event)">
</app-paginator-complete>