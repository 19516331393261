<div *ngIf="archivos.length > 0">

  <mat-list  *ngIf="showDelete" #files class="fileList">
    <div mat-subheader *ngIf="archivos.length == 1">{{ 'section.dialogs.file'| translate}}</div>
    <div mat-subheader *ngIf="archivos.length > 1">{{ 'section.dialogs.files'| translate}}</div>
    <mat-list-item *ngFor="let archivo of archivos; let i = index" [value]="i">
      <mat-icon mat-list-icon>article</mat-icon>
      <div mat-line>{{archivo.name | reduceText:20}}</div>
      <div mat-line> {{(archivo.size/1000000) | number : '1.2-2'}} MB </div>
      <button (click)="delete(i)" class="iconDelete" mat-icon-button>
        <mat-icon mat-list-icon svgIcon="delete"></mat-icon>
      </button>
    </mat-list-item>
  </mat-list>

  <mat-list  *ngIf="!showDelete" #files class="fileList">
    <div mat-subheader *ngIf="archivos.length == 1">{{ 'section.dialogs.file'| translate}}</div>
    <div mat-subheader *ngIf="archivos.length > 1">{{ 'section.dialogs.files'| translate}}</div>
    <mat-list-item *ngFor="let archivo of archivos; let i = index" [value]="i">
      <mat-icon mat-list-icon>article</mat-icon>
      <div mat-line>{{archivo.name}}</div>
      <div mat-line> {{(archivo.size/1000000) | number : '1.2-2'}} MB </div>
    </mat-list-item>
  </mat-list>

</div>