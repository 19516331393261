import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { FormularioDinamicoPregunta, OpcionTextoFormularioDinamicoPregunta } from 'src/app/api/interfaces/formulario-dinamico';

@Component({
  selector: 'app-text-area-input',
  templateUrl: './text-area-input.component.html',
  styleUrls: ['./text-area-input.component.scss']
})
export class TextAreaInputComponent implements OnInit {

  @Input('requerido') requerido: boolean;
  @Input('pregunta') pregunta: FormularioDinamicoPregunta;
  @Input('cantCaracteres') cantCaracteres: number;

  @Output() textoIngresadoEvent = new EventEmitter<OpcionTextoFormularioDinamicoPregunta>();
  
  optionsForm = new FormGroup({
   valor: new FormControl(),
  });

  constructor() { }

  ngOnInit(): void {
    this.optionsForm.statusChanges
    .subscribe(val => {
      this.emitirRespuesta();
    });
    if(!this.cantCaracteres) {
      this.cantCaracteres = 200;
    }
  }

  emitirRespuesta() {
    const { valor } = this.optionsForm.getRawValue();
    const opcionTexto: OpcionTextoFormularioDinamicoPregunta = {
      preguntaId: this.pregunta.id,
      respuestaTexto: valor
    }
    this.textoIngresadoEvent.emit(opcionTexto);
  }
}
