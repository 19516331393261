import { Component, OnInit, OnDestroy } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ParametrosService } from 'src/app/api/services/parametros/parametros.service';
import { MobileDetectorService } from 'src/app/services/mobileDetector/mobile-detector.service';
import { Canal, Message } from '../../../api/interfaces/messages';
import { Subscription } from 'rxjs';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';

@Component({
  selector: 'app-message-page',
  templateUrl: './message-page.component.html',
  styleUrls: ['./message-page.component.scss']
})
export class MessagePageComponent implements OnInit, OnDestroy {

  canal: Canal;

  showConversation: boolean = false;
  showMassive: boolean = false;
  isMobile: boolean = false;
  showList: boolean = true;

  private sharedData$: Subscription;

  constructor(
    private parametrosService: ParametrosService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private mobileDetectorService: MobileDetectorService,
    private sharedData: SharedDataService
  ) { 
    this.matIconRegistry.addSvgIcon(
      `mail`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/mail.svg')
    );
  }


  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    sessionStorage.setItem('active','mensajes');
    this.loadLongitud();
    this.sharedData$ = this.sharedData.currentMessage.subscribe(message => {
      if (message === "back") {
        this.showConversation = false;
        this.showMassive = false;
        this.showList = true;
      }
    });
  }

  changeCurrentMessageEvent(canal: Canal) {
    this.canal = canal;
    this.showConversation = true;
    this.showMassive = false;
    this.showList = false;
  }

  massiveMessageEvent(aux: boolean) {
    this.showConversation = false;
    this.showList = false;
    this.showMassive = true;

  }

  async loadLongitud() {
    try {
      const longitudMensaje: number = await this.parametrosService.getLongitudMensaje();
      sessionStorage.setItem('longitudMensaje',longitudMensaje.toString())
    } catch (error) {
      console.error(error);
      sessionStorage.setItem('longitudMensaje',"300");
    }
  }

  public ngOnDestroy(): void {
    this.sharedData$.unsubscribe();
  }
  

}
