import { Component, OnInit } from '@angular/core';
import { SettingsEnum } from 'src/app/enums/settings';
import { MobileDetectorService } from '../../../../services/mobileDetector/mobile-detector.service';

@Component({
  selector: 'app-settings-page',
  templateUrl: './settings-page.component.html',
  styleUrls: ['./settings-page.component.scss']
})
export class SettingsPageComponent implements OnInit {

  isMobile: boolean = false;
  showGroups: boolean = false;
  showArancel: boolean = false;

  constructor(
    private mobileDetectorService: MobileDetectorService,
  ) { }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    sessionStorage.setItem('active', 'configuracion');
    this.autoNavigate();
  }

  /**
   * Check session storage value for autoNavigate. If is a number, remove the flag from session storage and call {@link toggleOption}, using navegar as option parameter.
   * 
   * Ticket 4174: showGroups should be true as default if autoNavigate is NaN and user is using desktop
   * 
   * @Example
   * ```ts
   * sessionStorage.setItem('autoNavigate',SettingsEnum.ARANCEL.toString());
   * this.router.navigate(['/admin/configuracion']);
   * ```
   */
  autoNavigate() {
    const navegar: number = parseInt(sessionStorage.getItem('autoNavigate'),10);
    if(isNaN(navegar)) {
      this.showGroups = !this.isMobile;
    } else {
      sessionStorage.removeItem('autoNavigate');
      this.toggleOption(navegar);
    }
  }

  /**
   * If user is on mobile, boolean should change to diferent value when option is clicked from this component html file or from SettingsCardComponent event
   * If user is not on mobile, boolean should only change to true when option is clicked
   * @param option card to show
   */
  toggleOption(option: number) {
    switch(option) {
      case SettingsEnum.GROUPS:
        if(!this.isMobile) {
          if(!this.showGroups) {
            this.showGroups = true;
          }
        } else {
          this.showGroups = !this.showGroups;
        }
        this.showArancel = false;
      break;

      case SettingsEnum.ARANCEL:
        if(!this.isMobile) {
          if(!this.showArancel) {
            this.showArancel = true;
          }
        } else {
          this.showArancel = !this.showArancel;
        }
        this.showGroups = false;
      break;

      default:
        break;
    }

  }

}
