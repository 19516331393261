<form class="messagesSearch" [formGroup]="messagesForm">
      <mat-form-field appearance="outline" class="inputsFilterLarge">
        <mat-label> {{'messages.list.search' | translate}} </mat-label>
        <input 
          matInput 
          type="text" 
          formControlName="nombreApellido"
          (keyup.enter)="onSubmit(); $event.stopPropagation()">
  
        <button mat-icon-button matSuffix (click)="onSubmit()">
          <mat-icon class="azul">search</mat-icon>
        </button>
        
        <button *ngIf="messagesForm.value.nombreApellido" mat-icon-button matSuffix
        (click)="clear(); $event.stopPropagation()">
          <mat-icon class="clearIcon">close</mat-icon>
        </button>
  
      </mat-form-field>
</form>