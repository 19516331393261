<div class="type" *ngIf="!edit">
    <mat-icon *ngIf="(training.entrenamientoTipo == 'PRE' || training.entrenamientoTipo == 'Presencial') && training.grupo != 'No definido'">place</mat-icon>
    <mat-icon *ngIf="training.entrenamientoTipo == 'ONL' || training.entrenamientoTipo == 'Online'">public</mat-icon>
    <p *ngIf="training.grupo && training.grupo != 'NO DEFINIDO'" class="tableTitle"> {{training.grupo}}</p>

    <span class="subtitle2Grey" *ngIf="(training.entrenamientoTipo == 'NO DEFINIDO') || !training.entrenamientoTipo || (training.grupo == 'NO DEFINIDO')">{{'admin.studentsTable.header.undefined' | translate}}</span>    
</div>

<div *ngIf="edit">

    <button mat-button [disabled]="!isAdmin || status == 'C' || status == 'I' || status == 'S'" [matMenuTriggerFor]="menu" class="menuButton">
        <span *ngIf="training.grupo != 'NO DEFINIDO'">
            <mat-icon id="icon" *ngIf="userForm.value.group  != 'Online' && userForm.value.group.grupoDescripcion != 'Online'">
                place
            </mat-icon>
            <mat-icon id="icon" *ngIf="userForm.value.group  == 'Online' || userForm.value.group.grupoDescripcion == 'Online' ">
                public
            </mat-icon>
        </span>

        <span class="subtitle2">
            {{userForm.value.group.grupoDescripcion ? userForm.value.group.grupoDescripcion: userForm.value.group}}
        </span>
        
        <mat-icon class="iconMenu" *ngIf="isAdmin && (status == 'P' || status == 'A') ">
            edit
        </mat-icon>

        <mat-icon class="iconMenu greyIcon" *ngIf="(isAdmin && status == 'C') || (isAdmin && status == 'I') || (isAdmin && status == 'S')">
            edit 
        </mat-icon>
    </button>
     <mat-menu #menu="matMenu">
        <div *ngFor="let group of groups">
          <button (click)="saveGroup(group)" mat-menu-item>
            <span class="body4">{{group.grupoDescripcion }}</span>
          </button>
        </div>
      </mat-menu>
</div>