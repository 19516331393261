<body>
    <app-header [showLogoEquipo]="true" [showLogout]="false"></app-header>
    <article>
        <div class="welcome">
            <div class="centerDesktop">
                <mat-icon svgIcon="welcome_icon" class="welcomeIcon"></mat-icon>
                <p class="headline5">{{'welcome.title'|translate}}</p>
                <p class="subtitle1">{{'welcome.subtitle'|translate}}</p>

                <p class="body3">
                    {{'welcome.termsText1'|translate}} 
                    <a target="_blank" href="/terminos-condiciones">{{'welcome.termsLink' | translate}} </a>
                    {{'welcome.termsText2'| translate }}
                </p>

                <!-- <p class="body3">
                    {{'welcome.termsText1'|translate}} 
                    <a target="_blank" href="https://iutrein.godaddysites.com/t%C3%A9rminos-y-condiciones">{{'welcome.termsLink' | translate}} </a>
                    {{'welcome.termsText2'| translate }}
                </p> -->
                <button id="startButton" class="primaryButton2" color="accent" mat-raised-button (click)="navigate()">{{'welcome.button'|translate}}</button>
            </div>
        </div>
    </article>
</body>