<div class="dialogContainer485" *ngIf="loaded">
    <div class="flexRigth">
        <button
            mat-button mat-dialog-close 
            class="buttonDialogClose">
            <mat-icon class="clearIcon">
                close
            </mat-icon>
        </button>
    </div>
    <br/>
    <div class="headline6"> {{'planes.changeDays.title'  | translate}} </div>

    <p class="body2 flexCenter">{{'planes.changeDays.hint'  | translate}} <b> {{fecha}}</b></p>

    <div class="flexInLineCenter">
        <div *ngFor="let day of days">
            <button 
                mat-stroked-button
                *ngIf="!day.selected && !day.disabled" 
                (click)="saveDay(day)">
                {{day.name}}
            </button>

            <button
                mat-raised-button
                *ngIf="day.selected && !day.disabled" 
                class="selectedOption" 
                [disabled]="day.disabled" 
                (click)="saveDay(day)" >
                {{day.name}}
            </button>

            <button
                mat-raised-button
                *ngIf="day.disabled"
                [disabled]="day.disabled" 
                (click)="saveDay(day)" >
                {{day.name}}
            </button>
        </div>
    </div>

    <button
        mat-raised-button color="accent" 
        class="buttonFullWidth"
        [disabled]="valid" 
        (click)="onSubmit()" 
        id="submit"> 
        {{'planes.changeDays.save' | translate}}
    </button>

</div>

<app-loading [showText]="false" *ngIf="!loaded"></app-loading>