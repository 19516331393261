import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth.guard';
import { RoleString } from 'src/app/enums/role';

import { MessagePageComponent } from '../message-page/message-page.component';


const routes: Routes = [
  {
    path: 'mensajes',
    component: MessagePageComponent,
    canActivate: [AuthGuard],
    data: { roles: [RoleString.ADMIN, RoleString.STUDENT, RoleString.TRAINER] }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class MessagesRoutingModule { }