<div *ngIf="loaded && !isMetasObjetivos">

    <app-profile-header 
        *ngIf="profile" 
        [isAdminOrTrainer]="isAdminOrTrainer" 
        [isAdmin]="isAdmin" 
        [isStudent]="isStudent" 
        [personalData]="personalData" 
        [user_id]="id"
        [training]="training" 
        [disableSave]="disableSave" 
        [avatarUrl]="avatarUrl" 
        [status]="profile.estado"
        [usuarioId]="usuarioId"
        [datosTraining]="datosTraining" 
        [email]="profile.email"
        (updateDataEvent)='getUserProfileData()'>
    </app-profile-header>

    <app-profile-header 
        *ngIf="trainerProfile" 
        [isAdminOrTrainer]="isAdminOrTrainer" 
        [isAdmin]="isAdmin" 
        [isStudent]="isStudent" 
        [personalData]="personalData" 
        [user_id]="id"
        [training]="training" 
        [disableSave]="disableSave" 
        [avatarUrl]="avatarUrl" 
        [status]="trainerProfile.estado"
        [entrenadorId]="entrenadorId"
        [usuarioId]="usuarioId"
        (updateDataEvent)='getTrainerProfile()'>
    </app-profile-header>

    <mat-tab-group mat-stretch-tabs *ngIf="(isAdmin || isTrainer) && !isMobile && !trainerProfile" mat-align-tabs="center">
        <mat-tab *ngIf="estado != 'C' " label="Datos">
            <div class="mat-tab-background">
                <app-all-data
                *ngIf="estado"
                    (editPersonalEvent)='editPersonal($event)' 
                    (editPhysiologicalEvent)='editPhysiological($event)' 
                    (editBankEvent)='editBank($event)'
                    (editContactEvent)='editContact($event)'
                    [personalData]="personalData"
                    [contactData]="contactData"
                    [physiologicalData]="physiologicalData"
                    [alumnosDatosBancario]="alumnosDatosBancario"
                    [showComplete]="true"
                    [estado]="estado">
                </app-all-data>
            </div>
        </mat-tab>
        <mat-tab *ngIf="estado != 'C' " label="Antecedentes y objetivos">
            <div class="mat-tab-background">
                <br />
                <app-background-objetives 
                    [alumnoId]="id"
                    [showAntecedentes]="true" 
                    [showMarcasObjetivos]="true" 
                    [allAchievements]="allAchievements" 
                    [antecedente]="antecedente"
                    [objetives]="objetives">
                </app-background-objetives>
            </div>
        </mat-tab>
        <mat-tab *ngIf="estado != 'C' " label="Planes">
            <!-- <app-calendar></app-calendar> -->
            <div class="mat-tab-background">
                <div class="tabContent">
                    <app-week *ngIf="ultimoDiaDisponible" 
                        [showDownload]="true" 
                        [alumnoId]="id" 
                        [isAdmin]="false" 
                        [ultimoDiaDisponible]="ultimoDiaDisponible"
                        [entrenamientoDia]="entrenamientoDia">
                    </app-week>

                    <app-feedback *ngIf="ultimoDiaDisponible" 
                        [canGiveFeedback]="false" 
                        [edit]="true" 
                        [ultimoDiaDisponible]="ultimoDiaDisponible"
                        [usuarioId]="alumnoUsuarioId">
                    </app-feedback>
                </div>
            </div>
        </mat-tab>
        <div class="mat-tab-background">
            <br />
        </div>

        <!-- ticket 2743 -->
        <mat-tab *ngIf="estado != 'C'">
            <ng-template mat-tab-label>
                Apto médico
                <span class="circle" *ngIf="showCertificateAlert"></span>
              </ng-template>
            <div class="mat-tab-background">
                <br />
                <app-gallery-main [usuarioId]="usuarioId">
                </app-gallery-main>
            </div>
        </mat-tab>
        
        <mat-tab label="Registro de estados">
            <div class="mat-tab-background">
                <app-status-register-card 
                    [estado]="profile.estado" 
                    [alumnoId]="id" 
                    [usuarioId]="usuarioId" 
                    [motivoBaja]="motivoBaja"
                    [isTrainer]="isTrainer">
                </app-status-register-card>
            </div>
        </mat-tab>
    </mat-tab-group>

    <div *ngIf="isMobile">
        <br/>
    </div>
    <mat-tab-group mat-tabs *ngIf="(isAdmin || isTrainer )&& isMobile && !isAdminOrTrainer" mat-align-tabs="start" [disableRipple]="true">
        <mat-tab *ngIf="estado != 'C' " label="Datos">
            <div class="mat-tab-background">
                <app-all-data
                    (editPersonalEvent)='editPersonal($event)' 
                    (editPhysiologicalEvent)='editPhysiological($event)' 
                    (editBankEvent)='editBank($event)'
                    (editContactEvent)='editContact($event)'
                    [personalData]="personalData"
                    [contactData]="contactData"
                    [physiologicalData]="physiologicalData"
                    [alumnosDatosBancario]="alumnosDatosBancario"
                    [showComplete]="true"
                    [estado]="estado">
                </app-all-data>
            </div>
        </mat-tab>
        <mat-tab *ngIf="estado != 'C' " label="Antecedentes y objetivos">
            <div class="mat-tab-background">
                <br />
                <app-background-objetives 
                    [alumnoId]="id"
                    [showAntecedentes]="true" 
                    [showMarcasObjetivos]="true" 
                    [allAchievements]="allAchievements" 
                    [antecedente]="antecedente"
                    [objetives]="objetives">
                </app-background-objetives>
            </div>
        </mat-tab>
        <mat-tab *ngIf="estado != 'C' " label="Planes">
            <div class="mat-tab-background">
                <div class="tabContent">
                    <app-week *ngIf="ultimoDiaDisponible" 
                    [showDownload]="true" 
                    [alumnoId]="id" 
                    [isAdmin]="false" 
                    [ultimoDiaDisponible]="ultimoDiaDisponible"
                    [entrenamientoDia]="entrenamientoDia">
                </app-week>

                <app-feedback *ngIf="ultimoDiaDisponible" 
                    [canGiveFeedback]="false" 
                    [edit]="true" 
                    [ultimoDiaDisponible]="ultimoDiaDisponible"
                    [usuarioId]="alumnoUsuarioId">
                </app-feedback>
                </div>
            </div>
        </mat-tab>
        <div class="mat-tab-background">
            <br />
        </div>

         <!-- ticket 2743 -->
        <mat-tab *ngIf="estado != 'C'">
            <ng-template mat-tab-label>
                Apto médico
                <span class="circle" *ngIf="showCertificateAlert"></span>
              </ng-template>
            <div class="mat-tab-background">
                <br />
                <app-gallery-main [usuarioId]="usuarioId">
                </app-gallery-main>
            </div>
        </mat-tab>

        <mat-tab label="Registro de estados">
            <div class="mat-tab-background">
                <app-status-register-card 
                    [estado]="profile.estado" 
                    [alumnoId]="id" 
                    [usuarioId]="usuarioId" 
                    [motivoBaja]="motivoBaja">
                </app-status-register-card>
            </div>
        </mat-tab>

    </mat-tab-group>

    <mat-tab-group mat-stretch-tabs *ngIf="isStudent" mat-align-tabs="center" [selectedIndex]="tabIndex">
        <mat-tab label="Datos">
            <div class="mat-tab-background">
                <app-all-data
                    (editPersonalEvent)='editPersonal($event)' 
                    (editPhysiologicalEvent)='editPhysiological($event)' 
                    (editBankEvent)='editBank($event)'
                    (editContactEvent)='editContact($event)'
                    [personalData]="personalData"
                    [contactData]="contactData"
                    [physiologicalData]="physiologicalData"
                    [alumnosDatosBancario]="alumnosDatosBancario"
                    [showComplete]="true"
                    [estado]="estado">
                </app-all-data>
            </div>
        </mat-tab>
        <!-- ticket 2752 -->
        <mat-tab *ngIf="pasosHabilitados.antecedentes" [disabled]="estado != 'A' " label="Antecedentes">
            <div class="mat-tab-background">
                <br />
                <app-background-objetives [alumnoId]="id" [showAntecedentes]="true" [showMarcasObjetivos]="false" [allAchievements]="allAchievements" [antecedente]="antecedente"
                    [objetives]="objetives">
                </app-background-objetives>
            </div>
        </mat-tab>

        <!-- ticket 2743 -->
        <mat-tab *ngIf="estado != 'C'">
            <ng-template mat-tab-label>
                Apto médico
                <span class="circle" *ngIf="showCertificateAlert"></span>
              </ng-template>
            <div class="mat-tab-background">
                <br />
                <app-gallery-main [usuarioId]="usuarioId">
                </app-gallery-main>
            </div>
        </mat-tab>
        <div class="mat-tab-background">
            <br />
        </div>
    </mat-tab-group>


    <!-- trainer profle -->
    <mat-tab-group mat-stretch-tabs *ngIf="isAdminOrTrainer" mat-align-tabs="center">
        <mat-tab label="Datos">
            <div class="mat-tab-background">
                <div class="responsiveFlex">
                    <mat-card class="cardProfile">
                            <app-trainer-data
                            *ngIf="estado"
                            (onSubmitPersonalEvent)='editPersonalTrainer($event)' 
                            [personalData]="personalData"
                            [contactData]="contactData">
                        </app-trainer-data>
                    </mat-card>
                </div>
            </div>
        </mat-tab>
        <mat-tab label="Formación">
            <div class="mat-tab-background">
                <br />
                <app-formacion-card 
                    [formacion]="entrenadorFormacion"
                    [entrenadorId]="trainerProfile.entrenadorId">
                </app-formacion-card>
            </div>
        </mat-tab>
        <mat-tab *ngIf="!isTrainer" label="Registro de estados">
            <div class="mat-tab-background">
                <app-status-register-card 
                    [estado]="trainerProfile.estado" 
                    [entrenadorId]="trainerProfile.entrenadorId" 
                    [motivoBaja]="motivoBaja "
                    [isAdminOrTrainer]="isAdminOrTrainer"
                    [entrenadorIsAdmin]="entrenadorIsAdmin">
                </app-status-register-card>
            </div>
        </mat-tab>
        <div class="mat-tab-background">
            <br />
        </div>
    </mat-tab-group>

</div>

<div class="metasObjetivos" *ngIf="loaded && isMetasObjetivos">
    <div *ngIf="!isMobile">
        <p class="headline4" >{{'profile.tabsTitles.achievementsObjetives' | translate}}</p>
        <br />
    </div>
    <div *ngIf="isMobile">
        <br/>
        <p class="flexLeft headline5">{{'profile.tabsTitles.achievementsObjetives' | translate}}</p>
    </div>
    <app-background-objetives [alumnoId]="id" [showAntecedentes]="false" [showMarcasObjetivos]="true" [allAchievements]="allAchievements" [antecedente]="antecedente"
                    [objetives]="objetives">
    </app-background-objetives>
    <br />
</div>