<div class="messagesList">
    <div class="listTop">
        <p class="headline4">
            {{'messages.title' | translate}}
        </p>
        <div *ngIf="unreadAmount > 0 " class="unreadAmount">
            <p class="unreadAmountText">{{unreadAmount}}</p>
        </div>
    </div>
    <app-search *ngIf="!isStudent"
        (searchCanalesEvent)="loadSearchList($event)"
        (searchCanalesEmptyEvent)="searchCanalesEmpty()"
        (morePagesAvailableEvent)="changePagesAvailable($event)">
    </app-search>

    <app-option 
        [massive]="true" 
        [unreadMassive]="unreadMassive"
        (click)="selectMassive()">
    </app-option>

    <div *ngIf="canales" class="listMessages"
        infinite-scroll
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [scrollWindow]="false"
        (scrolled)="onScrollDown()">

        <div *ngFor="let canal of canales.content; let i = index">
            <app-option 
                [massive]="false" 
                [canal]="canal" 
                (click)="changeCurrent(canal,i)">
            </app-option>
        </div>
        <div *ngIf="canales.content.length == 0 && !searchEmpty" class="center">
            <mat-icon class="bigGreyIcon">check_circle_outline</mat-icon>
            <div class="subtitle3"> {{'messages.list.emptyTitle' | translate}} </div>
            <div class="body3Ligth"> {{'messages.list.emptyText' | translate}} </div>
        </div>

        <div *ngIf="searchEmpty" class="center">
            <div class="subtitle3"> {{'messages.list.searchEmpty' | translate}} </div>
        </div>

        
    </div>


    <div *ngIf="isStudent && !isMobile" class="space"></div>
</div>