<form [formGroup]="backgroundForm">
    <p class="headline6">{{ 'registration.stepThree.objetives.title' | translate}}</p>
    <app-objetives
        (emptyEvent)="emptyObjetives($event)"
        (saveObjetivesEditEvent)="saveObjetivesEditEvent($event)"
        [alumnoId]="alumnoId"
        [objetives]="objetives">
    </app-objetives>

    <p class="subtitle1" *ngIf="objetivesEmpty || !objetivosHasContent">
        {{'planes.objetivesPopup.emptyObjetives' | translate}}
    </p>
</form>