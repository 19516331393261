import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { PostEditSeccion } from '../../../../../api/interfaces/sections';
import { SeccionesService } from '../../../../../api/services/secciones/secciones.service';
import { SharedDataService } from '../../../../../services/sharedData/shared-data.service';
import { SnackbarService } from '../../../../../utils/snackbar/snackbar.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * NewEditSectionComponent
 * @param data con datos para el dialog
 * @example add new element, no data
 * @example edit element
      data: {
          seccionId: 1,
          seccionNombre: "Dietas",
          seccionTipo: "N",
        }
 */
@Component({
  selector: 'app-new-edit-section',
  templateUrl: './new-edit-section.component.html',
  styleUrls: ['./new-edit-section.component.scss']
})
export class NewEditSectionComponent implements OnInit {

  waiting: boolean = false;
  edit: boolean = false;

  newForm = new FormGroup({
    seccionNombre: new FormControl(),
    seccionTipo: new FormControl()
  });

  constructor(
    private seccionesService: SeccionesService,
    private snack: SnackbarService,
    private sharedData: SharedDataService,
    private dialogRef: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    if(this.data.seccionNombre && this.data.seccionId) {
      this.newForm.patchValue({seccionNombre: this.data.seccionNombre});
      this.newForm.patchValue({seccionTipo: this.data.seccionTipo});
      this.edit = true;
    }
  }

  onSubmit() {
    const tipo: string = sessionStorage.getItem('tipo');
    const seccion: PostEditSeccion = {
      seccionNombre : this.newForm.value.seccionNombre,
      seccionTipo: tipo,
      activo: true
    }
    if(!this.edit) {
      this.seccionesService.postSeccion(seccion).subscribe( response=> {
        this.snack.snackTranslate('snacks.sucess.sections.post');
        this.sharedData.changeMessage("reloadSections");
        this.dialogRef.closeAll();
      },
      error => {
        console.error(error);
        this.snack.openSnackBar(error.error.message);
      });
    } else {
      this.seccionesService.editarSeccion(this.data.seccionId , seccion).subscribe( response=> {
        this.snack.snackTranslate('snacks.sucess.sections.editSection');
        this.sharedData.changeMessage("reloadSections");
        this.dialogRef.closeAll();
      },
      error => {
        console.error(error);
        this.snack.openSnackBar(error.error.message);
      });
    }
  }
}
