<div class="dialogContainer450" *ngIf="!waiting">
    <div class="flexBeetwen">
        <p class="headline5"> {{'settings.arancel.arancelDialog.title'  | translate}} </p>
        <button mat-button class="buttonDialogClose" mat-dialog-close>
            <mat-icon class="clearIcon">close</mat-icon>
        </button>
    </div>
    <form [formGroup]="arancelForm">
        <p class="body2">{{'settings.arancel.arancelDialog.text'  | translate}}</p>
        <mat-form-field appearance="fill" class="inputfullWidth">
            <mat-label> {{'settings.arancel.arancelDialog.value'  | translate}} {{data.clave}} </mat-label>
            <input required matInput type="number" formControlName="arancel">      
        </mat-form-field>
    </form>
    <button mat-raised-button color="accent" [disabled]="!arancelForm.valid" (click)="onSubmit()" id="submit" class="buttonFullWidth"> {{'section.dialogs.confirmButton' | translate}}</button>
</div>

<app-loading *ngIf="waiting" [showText]="false"></app-loading>