<div class="dialogContainer" *ngIf="(createTicket || select || tree )&& !waiting" >

    <!-- formulario -->
    <div [@flyInOut]="'in'" *ngIf="createTicket && !waiting" >
        <!-- titulo de formulario -->
        <div class="flexBeetwen move supportDialogHeader" [cdkDrag]="isMobile" cdkDragRootElement=".cdk-overlay-pane" [cdkDragHandle]="isMobile">
            <button mat-button class="buttonDialogClose" (click)="treeNavigation()">
                <mat-icon class="whiteIcon">arrow_back</mat-icon>
            </button>
            
            <p mat-dialog-title class="headline6" *ngIf="bug">{{ 'support.bug.title' | translate }}</p>
            <p mat-dialog-title class="headline6" *ngIf="suggestion">{{ 'support.suggestion.title' | translate }}</p>
            <p mat-dialog-title class="headline6" *ngIf="question">{{ 'support.question.title' | translate }}</p>
            <button mat-button class="buttonDialogClose" mat-dialog-close>
                <mat-icon class="whiteIcon">close</mat-icon>
            </button>
        </div>

        <mat-dialog-content>
            <form [formGroup]="ticketForm">
                <mat-form-field id="subjectInput" class="inputFullWidth2" appearance="fill">
                    <mat-label *ngIf="bug">{{ 'support.bug.subject' | translate}}</mat-label>
                    <mat-label *ngIf="suggestion">{{ 'support.suggestion.subject' | translate}}</mat-label>
                    <mat-label *ngIf="question">{{ 'support.question.subject' | translate}}</mat-label>
                    <input formControlName="asunto" type="text" matInput required>
                </mat-form-field>

                <br/>
                
                
                <mat-form-field  id="descriptionInput" class="inputFullWidth2" appearance="fill">
                    <mat-label *ngIf="bug">{{ 'support.bug.description' | translate}}</mat-label>
                    <mat-label *ngIf="suggestion">{{ 'support.suggestion.description' | translate}}</mat-label>
                    <mat-label *ngIf="question">{{ 'support.question.description' | translate}}</mat-label>
                    <textarea matInput formControlName="descripcion" type="text" required></textarea>
                </mat-form-field>
    
                <br/>
    
                <mat-form-field  id="dispositivosInput" class="inputFullWidth2" appearance="fill">
                    <mat-label>{{ 'support.devices' | translate}}</mat-label>
                    <input matInput formControlName="dispositivos" type="text">
                </mat-form-field>
    
                <br/>
    
                <p class="subtitle3">{{ 'support.attachTitle' | translate}}</p>
                <p class="body4" *ngIf="question">{{ 'support.question.attachText' | translate}}</p>
                <p class="body4" *ngIf="suggestion">{{ 'support.suggestion.attachText' | translate}}</p>
                <p class="body4" *ngIf="bug">{{ 'support.bug.attachText' | translate}}</p>
                
                <app-files 
                    [archivos]="archivos"
                    [showDelete]="true"
                    (deleteFilesEvent)="deleteFiles($event)">
                </app-files>

                <div>
                    <label for="file-upload">
                    <p class="body4 fakeButton"> {{'support.attach' | translate}}</p>
                    </label>
                    <input id="file-upload" type="file" multiple (change)="selectFiles($event)" (click)="nullFiles($event)"/>
                    <p id="label" class="body4 primaryHint">{{'support.size' | translate}}</p>
                </div>
    
                
            </form>
            <button color="accent" [disabled]="!ticketForm.valid || waiting" mat-raised-button class="buttonDialog" (click)="onSubmit()">{{ 'support.send' | translate }}</button>
        </mat-dialog-content>  
    </div>

    <!-- arbol de ayuda -->
    <span *ngIf="!createTicket && !waiting && !success">

        <div [@flyInOut]="'in'"  class="flexBeetwen move supportDialogHeader" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
            
            <div *ngIf="onElement">
                <button [@flyInOut]="'in'" mat-icon-button class="buttonDialogClose" (click)="treeNavigation()" >
                    <mat-icon class="whiteIcon">arrow_back</mat-icon>
                </button>
                <button [@flyInOut]="'in'" *ngIf="showHome" mat-icon-button class="buttonDialogClose" (click)="returnToSelect()">
                    <mat-icon class="whiteIcon">home</mat-icon>
                </button>
            </div>

            <div [@flyInOut]="'in'" class="separador" *ngIf="!onElement"></div>
            
            <p mat-dialog-title class="headline6">{{ 'support.help.title' | translate }}</p>
            <button mat-button id="closeInHelp" class="buttonDialogClose" mat-dialog-close>
                <mat-icon class="whiteIcon">close</mat-icon>
            </button>
        </div>
        
        <mat-dialog-content>
            <app-support-tree-main
                [backHome]="backHome">
            </app-support-tree-main>
        </mat-dialog-content>
        
        <app-support-type-option 
            *ngIf="select && !waiting && !onElement"
            (toggleBugEvent)= "toggleBugMode($event)"
            (toggleTreeEvent)="toggleTreeMode($event)">
        </app-support-type-option>
    </span>


    <div class="move" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle> <br/> </div>
</div>

<app-loading *ngIf="waiting" [showText]="false"></app-loading>

<div [@flyInOut]="'in'" class="dialogContainer450" *ngIf="success">
    <div class="flexBeetwen move supportDialogHeader" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <button mat-button class="buttonDialogClose" (click)="returnToSelect()">
            <mat-icon class="whiteIcon">home</mat-icon>
        </button>
        
        <p mat-dialog-title class="headline6" *ngIf="bug">{{ 'support.bug.title' | translate }}</p>
        <p mat-dialog-title class="headline6" *ngIf="suggestion">{{ 'support.suggestion.title' | translate }}</p>
        <p mat-dialog-title class="headline6" *ngIf="question">{{ 'support.question.title' | translate }}</p>
        <button mat-button class="buttonDialogClose" mat-dialog-close>
            <mat-icon class="whiteIcon">close</mat-icon>
        </button>
    </div>

    <mat-dialog-content>

        <div class="centerText">
            <mat-icon *ngIf="bug" class="bigBlueIcon" >bug_report</mat-icon>
            <mat-icon *ngIf="suggestion" class="bigBlueIcon">check_circle</mat-icon>
            <mat-icon *ngIf="question" class="bigBlueIcon">help_outline</mat-icon>
            
            <div *ngIf="bug" class="headline6" id="textSuccess">{{ 'support.success.bug.title' | translate }}  </div>
            <div *ngIf="suggestion" class="headline6"  id="textSuccess">{{ 'support.success.suggestion.title' | translate }}  </div>
            <div *ngIf="question" class="headline6"  id="textSuccess">{{ 'support.success.question.title' | translate }}  </div>
        </div>
        
        <div class="centerText">
            <span *ngIf="bug" class="body2">{{ 'support.success.bug.text' | translate }}  </span>
            <span *ngIf="suggestion" class="body2">{{ 'support.success.suggestion.text' | translate }}  </span>
            <span *ngIf="question" class="body2">{{ 'support.success.question.text' | translate }}  </span>
        </div>
        
        <button mat-stroked-button color="primary"  id="close" class="buttonDialog" [mat-dialog-close]="true" cdkFocusInitial >{{ 'support.success.button' | translate }}</button>
    </mat-dialog-content>
</div>
