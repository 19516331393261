<div class="dialogContainer">
    <div class="flexBeetwen">
        <h2 mat-dialog-title class="Headline5">{{ 'profile.data.edit' | translate }}</h2> <button mat-button class="buttonDialogClose" mat-dialog-close>X</button>
    </div>
    <mat-dialog-content class="mat-typography">
        <form [formGroup]="userForm">

            <mat-form-field appearance="fill" class="inputFullWidth">
                <mat-label>{{'profile.assingDialog.level' | translate}}</mat-label>
                <mat-select id="levelInput" placeholder="userForm.value.level" required formControlName="level">
                    <mat-option  *ngFor="let level of levels" [value]="level">{{level.name}}
                    </mat-option>
                  </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="inputFullWidth">
                <mat-label>{{'profile.assingDialog.trainer' | translate}}</mat-label>
                <mat-select id="trainerInput" placeholder="userForm.value.trainer" required formControlName="trainer">
                    <mat-option  *ngFor="let trainer of trainers" [value]="trainer">{{trainer.name}}
                    </mat-option>
                  </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="inputFullWidth">
                <mat-label>{{'profile.assingDialog.mode' | translate}}</mat-label>
                <mat-select id="modeInput" placeholder="userForm.value.trainingType.name" required formControlName="trainingType">
                    <mat-option  *ngFor="let trainingType of trainingTypes" [value]="trainingType">{{trainingType.name}}
                    </mat-option>
                  </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="inputFullWidth">
                <mat-label>{{'profile.assingDialog.days' | translate}}</mat-label>
                <mat-select id="daysInput" placeholder="userForm.value.days" required formControlName="days">
                    <mat-option  *ngFor="let day of days" [value]="day">{{day.value}} {{day.hour}}
                    </mat-option>
                  </mat-select>
            </mat-form-field>
            
        </form>
    </mat-dialog-content>
        <button [disabled]="!userForm.valid" mat-raised-button color="accent" class="buttonDialog" (click)="onSubmit()">{{ 'profile.assingDialog.button' | translate }}</button>
</div>
