<div *ngIf="canal" class="messageHeader">
    <div class="imgName">

        <button *ngIf="isMobile" mat-icon-button (click)="back()">
            <mat-icon class="delete">arrow_back</mat-icon>
        </button>

        <app-avatar
            *ngIf="otroParticipante.idUsuario"
            [class]="'imgRoundedHeaderMessage marginHeaderMessage'"
            [containerClass]=""
            [usuarioId]="otroParticipante.idUsuario">
          </app-avatar>
        <div class="column">
            <span *ngIf="!isMobile" class="headline6">{{otroParticipante.descripcionUsuario}}</span>
            <span *ngIf="isMobile" class="subtitle3">{{otroParticipante.descripcionUsuario}}</span>
            <br/>
            <p *ngIf="isStudent && !isMobile" class="italic"> {{'messages.assitive' | translate}}</p>
        </div>
    </div>
    
    <button  *ngIf="showNavigate && isMobile" mat-icon-button id="navigate" (click)="navigate()">
        <mat-icon class="azul">visibility</mat-icon>
      </button>
    <button *ngIf="showNavigate && !isMobile" mat-raised-button color="accent" class="editButton" (click)="navigate()">{{'messages.list.goToProfile' | translate }}</button>
</div>

<div *ngIf="canal == null" class="messageHeader">
    <div class="imgName">

        <button *ngIf="isMobile" mat-icon-button (click)="back()">
            <mat-icon class="delete">arrow_back</mat-icon>
        </button>
        
        <div class="massiveCircle">
            <mat-icon svgIcon="massive" class="imgMassive" ></mat-icon>
        </div>
        <span class="headline6"> {{'messages.list.massive.titleNoAdmin' | translate: {equipoNombre : equipoNombre} }}</span>
    </div>
</div>