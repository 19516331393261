<div [class]="class">
    <div *ngIf="isAdmin" class="flexBeetwen sectionHover">
        <p class="headline6 showOnHover">{{seccion.seccionNombre |reduceText:15}}</p>
        <p class="headline6 hideOnHover">{{seccion.seccionNombre}}</p>
        <div *ngIf="!disableDragDrop">
            <mat-icon>drag_indicator</mat-icon>
        </div>
        <div *ngIf="disableDragDrop && hideButtons.hide" class="buttonsItem">
            <button mat-icon-button (click)="toogleDragSections()">
                <mat-icon class="delete">low_priority</mat-icon>
            </button>

            <button mat-icon-button (click)="edit()">
                <mat-icon class="delete">edit</mat-icon>
            </button>
            <button (click)="delete()" id="deleteButton" mat-icon-button>
                <mat-icon svgIcon="delete" class="delete"></mat-icon>
            </button>
        </div>
    </div>

    <div *ngIf="!isAdmin" class="flexBeetwen sectionHover">
        <p class="headline6">{{seccion.seccionNombre}}</p>
    </div>

    <div cdkDropList (cdkDropListDropped)="drop($event)">
        <div *ngFor="let item of seccion.items" cdkDrag [cdkDragDisabled]="disableDragDropItems">
            <app-item
                [item]="item"
                [seccionNombre]="seccion.seccionNombre"
                [seccionId]="seccion.seccionId"
                [disableDragDrop]="disableDragDrop"
                [disableDragDropItems]="disableDragDropItems"
                [itemClass]="itemClass"
                [index]="index"
                (toogleDragDropItemsEvent)="toggleDragDropItems($event,index)">
            </app-item>
        </div>
    </div>

    <button *ngIf="!disableDragDropItems && isAdmin" (click)="toggleDragDropItems()" mat-raised-button color="accent" class="primaryButton2" id="dragButton">
        <mat-icon class="whiteIcon">save</mat-icon>
        {{ 'planes.forms.save' | translate | uppercase }}
    </button>
    
    <button *ngIf="disableDragDrop && disableDragDropItems && isAdmin && hideButtons.hide" mat-button color="primary" [matMenuTriggerFor]="menu">{{ 'registration.stepThree.objetives.add' | translate}}</button>
        <mat-menu #menu="matMenu" id="menuButton">
            <button mat-menu-item (click)="addElement('P')" id="addPdf">{{'section.file' | translate}}</button>
            <button mat-menu-item (click)="addElement('V')" id="addVideo">{{'section.video' | translate}}</button>
        </mat-menu>
</div>