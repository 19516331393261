import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA} from '@angular/material/dialog'
import { GetFeedback } from '../../../api/interfaces/plans';
import { PlansService } from '../../../api/services/plans/plans.service';
import { UserService } from '../../../api/services/user/user.service';
import { SnackbarService } from '../../../utils/snackbar/snackbar.service';
import { WeekService } from '../../../utils/week/week.service';
import { Canal, EnviarMensaje } from '../../../api/interfaces/messages';
import { MessagesService } from '../../../api/services/messages/messages.service';

/**
 * Display either student's feedback and allows the admin to reply or admin can send a new message without feedback.
 * Use messagesService to get canal's data and send the reply
 * 
 * @param data - allow to replay feedback
 * ```
 * data = {
        title: this.day.fecha,
        alumnoId: this.alumnoId
      }
 * ```
 * @param alumnoId - number, to send new message
 */
@Component({
  selector: 'app-feedback-dialog',
  templateUrl: './feedback-dialog.component.html',
  styleUrls: ['./feedback-dialog.component.scss']
})
export class FeedbackDialogComponent implements OnInit {

  canal: Canal;

  datos: GetFeedback;

  hasImage: boolean = false;

  usuarioId: number = null;

  fullName: string = "";
  avatarUrl: string = "";
  titleClass: string = "";

  fakeReply: string = null;

  feedbackForm = new FormGroup({
    respuesta: new FormControl(),
  });

  isFeedback: boolean = true;

  waiting: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private weekService: WeekService,
    private plansService: PlansService,
    private userService: UserService,
    private snack: SnackbarService,
    private messagesService: MessagesService,
    private dialogRef: MatDialog,
    
    ) { }

  ngOnInit(): void {
    if(this.data.title) {
      this.getFeedback();
      this.getUserProfileData();
    } else {
      this.getUserProfileData();
      this.isFeedback = false;
      this.titleClass = "popupTitle";
    }
  }
  

  getUserProfileData() {
    this.userService.getProfileUserId(this.data.alumnoId).subscribe(response => {
     this.usuarioId = response.usuarioId;
      this.fullName = response.nombre + " " + response.apellido;
      this.getCanal(response.usuarioId);
    },
      error => {
        console.error(error);
        this.snack.snackTranslate('snacks.error.generic');
      });
  }

  getCanal(usuarioId: number) {
    this.messagesService.getCanalUsuarioId(usuarioId).subscribe(response => {
      this.canal = response.content[0];
    },
      error => {
        console.error(error);
        this.snack.snackTranslate('snacks.error.generic');
      });
  }

  /**
   * get student's feedback and change titleClass
   */
  getFeedback() {
    const fecha = new Date(this.data.title);
    this.plansService.getFeedback(this.weekService.convertDate(this.data.title), this.data.alumnoId).subscribe(response => {
      this.datos = response;
      switch (response.feedbackOpcion) {
        case 1: 
          this.titleClass = "popupTitleMal";
          break;
        case 2: 
          this.titleClass = "popupTitleRegular"; 
          break;
        case 3:
          this.titleClass = "popupTitleBien";
          this.fakeReply = this.snack.getTranslate('planes.feedback.bien');
          break;
        case 4: 
          this.titleClass = "popupTitleFenomenal";
          this.fakeReply = this.snack.getTranslate('planes.feedback.fenomenal');
          break;
      }
    },
      error => {
        console.error(error);
      });
  }

  onSubmit() {
    this.waiting = true;
    if(this.feedbackForm.value.respuesta.trim().length > 0 ) {
      let enviarMensaje: EnviarMensaje = {
        mensaje: this.feedbackForm.value.respuesta,
      }
      
      if(this.isFeedback) {
        const metadata: string = `${this.datos.fechaFeedback}¬${this.datos.feedbackRespuesta ?  this.datos.feedbackRespuesta : this.fakeReply}`;
        enviarMensaje.mensajeTipo = "FEEDBACK";
        enviarMensaje.metadata = metadata
      }
      
      this.messagesService.postMensaje(this.canal.id, enviarMensaje).subscribe(response => {
        this.snack.snackTranslate('snacks.sucess.messages.feedbackReply');
        this.dialogRef.closeAll();
        this.waiting = false;
      }, error => {
        console.error(error);
        this.snack.snackTranslate('snacks.error.generic');
      });
    }
  }

}