<form class="filters" [formGroup]="filtersForm">
  <div *ngIf="!isMobile">

    <mat-form-field appearance="outline" class="inputsFilterLarge">
      <mat-label> {{'admin.studentsTable.filters.searchStudent' | translate}} </mat-label>
      <input matInput type="text" formControlName="nombreApellido" >

      <button mat-icon-button matSuffix (click)="onSubmit()">
        <mat-icon class="azul">search</mat-icon>
      </button>
      
      <button *ngIf="filtersForm.value.nombreApellido" mat-icon-button matSuffix
      (click)="clear('nombreApellido'); $event.stopPropagation()">
        <mat-icon class="clearIcon">close</mat-icon>
      </button>

    </mat-form-field>

    <br />

    <p class="tableTitle">{{'admin.studentsTable.filters.filters' | translate}}</p>

    <mat-form-field appearance="outline" class="inputsFilter2">
      <mat-label>{{'admin.studentsTable.filters.status' | translate}}</mat-label>
      <mat-select #statusSelect id="levelInput" placeholder="status" formControlName="status">
        <mat-option *ngFor="let status of allStatus" [value]="status">{{status.estadoDescripcion}}
        </mat-option>
      </mat-select>
      <button *ngIf="filtersForm.value.status && !isTrainer" mat-icon-button matSuffix 
      (click)="clear('status'); $event.stopPropagation()">
        <mat-icon class="clearIcon">close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field appearance="outline" class="inputsFilter">
      <mat-label>{{'admin.studentsTable.filters.type' | translate}}</mat-label>
      <mat-select #typeSelect id="typeInput" formControlName="type">
        <mat-option *ngFor="let option of trainingDays" [value]="option.entrenamientoGrupoId">
          {{option.grupoDescripcion}}
        </mat-option>
      </mat-select>
      <button *ngIf="filtersForm.value.type" mat-icon-button matSuffix 
      (click)="clear('type'); $event.stopPropagation()">
        <mat-icon class="clearIcon">close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field appearance="outline" class="inputsFilter2">
      <mat-label>{{'admin.studentsTable.filters.level' | translate}}</mat-label>
      <mat-select #levelSelect id="levelInput" formControlName="level">
        <mat-option *ngFor="let level of levels" [value]="level.id">
          {{level.entrenamientoNivelDescripcion}}
        </mat-option>
      </mat-select>
      <button *ngIf="filtersForm.value.level" mat-icon-button matSuffix
      (click)="clear('level'); $event.stopPropagation()">
        <mat-icon class="clearIcon">close</mat-icon>
      </button>
    </mat-form-field>

    <mat-form-field appearance="outline" class="inputsFilter">
      <mat-label>{{'admin.studentsTable.filters.trainer' | translate}}</mat-label>
      <mat-select 
        #trainerSelect 
        id="trainerInput" 
        formControlName="trainer"
        [disabled]="isTrainer">
        <mat-option 
          *ngFor="let trainer of trainers" 
          [value]="trainer">
          {{trainer.entrenadorNombre }}
        </mat-option>
      </mat-select>

      <button *ngIf="filtersForm.value.trainer && !isTrainer" mat-icon-button matSuffix 
      (click)="clear('trainer'); $event.stopPropagation()">
        <mat-icon class="clearIcon">close</mat-icon>
      </button>

    </mat-form-field>

    <mat-form-field *ngIf="!isTrainer" appearance="outline" class="inputsFilter2">
      <mat-label>{{'admin.studentsTable.filters.leido' | translate}}</mat-label>
      <mat-select #leidoSelect id="leidoInput" formControlName="leido">
        <mat-option *ngFor="let opcion of leidoOpciones" [value]="opcion.value">
          {{opcion.nombre}}
        </mat-option>
      </mat-select>

      <button *ngIf="filtersForm.value.leido != null" mat-icon-button matSuffix
      (click)="clear('leido'); $event.stopPropagation()">
        <mat-icon class="clearIcon">close</mat-icon>
      </button> 
    </mat-form-field>

    <button mat-button color="primary" (click)="onSubmit()" [disabled]="disabled">{{'admin.studentsTable.filters.ok' | translate}}</button>
    <button mat-button (click)="clearAll()" [disabled]="disabled">{{'admin.studentsTable.filters.clear' | translate}}</button>
  </div>

  <div *ngIf="isMobile">

    <mat-form-field appearance="outline" class="input80">
      
      <mat-label> {{'admin.studentsTable.filters.searchStudent' | translate}} </mat-label>
      <input matInput type="text" formControlName="nombreApellido" >
      
      <button mat-icon-button matSuffix (click)="onSubmit()">
        <mat-icon class="azul">search</mat-icon>
      </button>

      <button *ngIf="filtersForm.value.nombreApellido" mat-icon-button matSuffix 
      (click)="clear('nombreApellido'); $event.stopPropagation()">
        <mat-icon class="clearIcon">close</mat-icon>
      </button>

    </mat-form-field>

    <mat-accordion class="example-headers-align">
      <mat-expansion-panel hideToggle>
        <mat-expansion-panel-header>
          <mat-panel-title class="headline5NoCenter">
            <mat-icon class="blackIcon">
              filter_list
            </mat-icon>
            <span class="body3">
              {{'admin.studentsTable.filters.filters' | translate}}
            </span>
            <span *ngIf="contador > 0" class="contador">{{contador}}</span>
          </mat-panel-title>

        </mat-expansion-panel-header>

        <mat-form-field appearance="outline" class="inputsFilter">
          <mat-label>{{'admin.studentsTable.filters.status' | translate}}</mat-label>
          <mat-select #statusSelect id="levelInput" placeholder="status" formControlName="status">
            <mat-option *ngFor="let status of allStatus" [value]="status">{{status.estadoDescripcion}}
            </mat-option>
          </mat-select>
          <button *ngIf="filtersForm.value.status && !isTrainer" mat-icon-button matSuffix 
          (click)="clear('status'); $event.stopPropagation()">
            <mat-icon class="clearIcon">close</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field appearance="outline" class="inputsFilter">
          <mat-label>{{'admin.studentsTable.filters.type' | translate}}</mat-label>
          <mat-select #typeSelect id="typeInput" formControlName="type">
            <mat-option *ngFor="let option of trainingDays" [value]="option.entrenamientoGrupoId">
              {{option.grupoDescripcion}}
            </mat-option>
          </mat-select>
          <button *ngIf="filtersForm.value.type" mat-icon-button matSuffix 
          (click)="clear('type'); $event.stopPropagation()">
            <mat-icon class="clearIcon">close</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field appearance="outline" class="inputsFilter">
          <mat-label>{{'admin.studentsTable.filters.level' | translate}}</mat-label>
          <mat-select #levelSelect id="levelInput" formControlName="level">
            <mat-option *ngFor="let level of levels" [value]="level.id">
              {{level.entrenamientoNivelDescripcion}}
            </mat-option>
          </mat-select>
          <button *ngIf="filtersForm.value.level" mat-icon-button matSuffix 
          (click)="clear('level'); $event.stopPropagation()">
            <mat-icon class="clearIcon">close</mat-icon>
          </button>
        </mat-form-field>

        <mat-form-field appearance="outline" class="inputsFilter">
          <mat-label>{{'admin.studentsTable.filters.trainer' | translate}}</mat-label>
          <mat-select 
            #trainerSelect 
            id="trainerInput" 
            formControlName="trainer"
            [disabled]="isTrainer">
            <mat-option 
              *ngFor="let trainer of trainers" 
              [value]="trainer">
              {{trainer.entrenadorNombre }}
            </mat-option>
          </mat-select>
    
          <button *ngIf="filtersForm.value.trainer && !isTrainer" mat-icon-button matSuffix 
          (click)="clear('trainer'); $event.stopPropagation()">
            <mat-icon class="clearIcon">close</mat-icon>
          </button>
    
        </mat-form-field>

        <mat-form-field appearance="outline" class="inputsFilter">
          <mat-label>{{'admin.studentsTable.filters.leido' | translate}}</mat-label>
          <mat-select #leidoSelect id="leidoInput" formControlName="leido">
            <mat-option *ngFor="let opcion of leidoOpciones" [value]="opcion.value">
              {{opcion.nombre}}
            </mat-option>
          </mat-select>

          <button *ngIf="filtersForm.value.leido != null" mat-icon-button matSuffix
          (click)="clear('leido'); $event.stopPropagation()">
            <mat-icon class="clearIcon">close</mat-icon>
          </button> 
        </mat-form-field>

        

        <button mat-button color="primary" (click)="clearAll()" [disabled]="disabled">{{'admin.studentsTable.filters.clear' | translate}}</button>
        <button mat-button color="primary" (click)="onSubmit()" [disabled]="disabled">{{'admin.studentsTable.filters.ok' | translate}}</button>

      </mat-expansion-panel>
    </mat-accordion>
  </div>

</form>