import { Component, OnInit } from '@angular/core';
import { RolService } from 'src/app/services/rol/rol.service';
import { MobileDetectorService } from 'src/app/services/mobileDetector/mobile-detector.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  isAdmin: boolean = true;
  isMobile: boolean = false;
  user_id: number;
  
  constructor(
    private rolService: RolService,
    private mobileDetectorService: MobileDetectorService,
  ) { }

  ngOnInit(): void {
    this.isAdmin = this.rolService.isAdmin();
    this.isMobile = this.mobileDetectorService.isMobile();
    this.user_id = parseInt(sessionStorage.getItem('id_usuario'),10);
    if(this.user_id) {
      sessionStorage.setItem('active','alumnos');
    } else {
      sessionStorage.setItem('active','perfil');
    }
  }

}
