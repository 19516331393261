import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AllAchievements } from '../../../../api/interfaces/user';
import { MobileDetectorService } from '../../../../services/mobileDetector/mobile-detector.service';

@Component({
  selector: 'app-achievements-card',
  templateUrl: './achievements-card.component.html',
  styleUrls: ['./achievements-card.component.scss']
})
export class AchievementsCardComponent implements OnInit {

  rutaValid: boolean = true;
  pistaValid: boolean = true;
  trailValid: boolean = true;
  ultraValid: boolean = true;
  isMobile: boolean = false;
  disable: boolean = true;

  rutaHasContent: boolean = false;
  pistaHasContent: boolean = false;
  ultraHasContent: boolean = false;
  trailHasContent: boolean = false;


  @Input('allAchievements') allAchievements: AllAchievements;
  @Input('alumnoId') alumnoId?: number;
  
  backgroundForm = new FormGroup({
    ruta: new FormControl(),
    pista: new FormControl(),
    trail: new FormControl(),
    ultra: new FormControl()
  });
  
  constructor(
    private mobileDetectorService: MobileDetectorService,
  ) { }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    this.checkActivos();

  }

  changeAchievement(aux) {
    if(aux.mode === "ruta") {
      this.rutaValid = aux.valid
    }

    if(aux.mode === "pista") {
      this.pistaValid = aux.valid
    }

    if(aux.mode === "ultra") {
      this.ultraValid = aux.valid
    }

    if(aux.mode === "trail") {
      this.trailValid = aux.valid
    }
    this.checkActivos();
  }
//on submit y edit vienen de title
  edit() {
    this.disable = !this.disable;
    this.checkActivos();
  }

  onSubmit() {
    this.edit();
  }

  checkActivos() {
    this.rutaHasContent  = this.allAchievements.route.some(element => element.activo);
    this.pistaHasContent = this.allAchievements.pista.some(element => element.activo);
    this.ultraHasContent = this.allAchievements.ultra.some(element => element.activo);
    this.trailHasContent = this.allAchievements.trail.some(element => element.activo);
  }


}
