<mat-accordion >
    <mat-expansion-panel class="preguntaCard">
      <mat-expansion-panel-header>

            <span class="headline6 primaryHint flexLeft">
                <mat-icon *ngIf="!isMobile" id="iconHelp">
                    help
                </mat-icon>
                {{pregunta.titulo}} 
            </span>
      </mat-expansion-panel-header>
      <br/>
      <p class="body2" [innerHTML]="pregunta.texto"></p>
    </mat-expansion-panel>
  </mat-accordion>