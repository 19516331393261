import { Component, OnInit, Input } from '@angular/core';
import { StatusEnum } from '../../../enums/status';

@Component({
  selector: 'app-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss']
})
export class StatusComponent implements OnInit {

  StatusEnum = StatusEnum;
  @Input('status') status: string;
  @Input('showComplete') showComplete: boolean;
  constructor(
  ) { }

  ngOnInit(): void {
  }

}
