<p *ngIf="isMobile" class="headline6Light"> {{posicion}}/{{cantidadPasosHabilitados}} {{ 'registration.stepTwo.titleMobile' | translate}}</p>
<div class="flexCenter">
    <ng-template matStepLabel>DATOS PERSONALES</ng-template>
    <br />
    <div class="card2">
        <p class="headline5NoCenter">{{'registration.stepTwo.title'|translate}}</p>
    </div>
    <br />
</div>
<br />

<form [formGroup]="modeForm">
    <div class="responsiveFlex">
        <ng-template matStepLabel>DATOS PERSONALES</ng-template>
        <mat-card (click)="enablePresencial()" [class]="cardClass1" color="primary">
            <mat-card-title class="headline6">
                <mat-icon>place</mat-icon>{{'registration.stepTwo.cardOne.title'|translate}}
            </mat-card-title>
            <p class="body2" *ngIf="!card1Text">{{'registration.stepTwo.cardOne.text'|translate}} </p>
            <p class="body2" *ngIf="card1Text">{{card1Text}}</p>
           

            <mat-form-field class="inputFullWidth2" appearance="fill">
                <mat-label class="body2">{{'registration.stepTwo.cardOne.chooseDay'|translate}}</mat-label>
                <mat-select #matRef>
                    <mat-option class="body2" *ngFor="let option of trainingDays" [value]="option.grupoDescripcion"
                        (click)="saveTrainingDay(option)">
                        {{option.grupoDescripcion}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            
        </mat-card>
        <br />

        <mat-card (click)="disablePresencial()" [class]="cardClass2">
            <mat-card-title class="headline6">
                <mat-icon>public</mat-icon>{{'registration.stepTwo.cardTwo.title'|translate}}
            </mat-card-title>
            <p class="body2" *ngIf="!card2Text">{{'registration.stepTwo.cardTwo.text'|translate}}</p>
            <p class="body2" *ngIf="card2Text">{{card2Text}}</p>
        </mat-card>
    </div>

    <!-- 2670: paso 2 dinamico -->
    <!-- <div class="responsiveFlex">

        <div *ngFor="let tipo of tipoEntrenamiento; let i = index">
            <mat-card [class]="tipo.clase" color="primary" (click)="selectTipo(tipo, i)">
                <mat-card-title class="headline6">
                    <mat-icon> {{tipo.logo}} </mat-icon> {{tipo.titulo}}
                </mat-card-title>    
                
                <p class="body2">{{tipo.texto}} </p>
                
                    <mat-form-field *ngIf="tipo.tieneSelect" class="inputFullWidth2" appearance="fill">
                        <mat-label class="body2">{{'registration.stepTwo.cardOne.chooseDay'|translate}}</mat-label>
                        <mat-select #matRef>
                            <mat-option class="body2" *ngFor="let option of tipo.entrenamientoGrupos" [value]="option"
                            (click)="saveTrainingDay(option)">
                            {{option.grupoDescripcion}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-card>
        
        </div>
    </div> -->
    <br />


    <!-- 2670: dias para que el usuario seleccione, esto queda igual que antes -->
    <div class="flexCenter">
        <ng-template matStepLabel>DATOS PERSONALES</ng-template>
        <br />
        <!-- 3441: agregado equipoId && !presencial -->
        <div *ngIf="(equipoId != 1 && selected) ||  ( equipoId == 1 && selected && !presencial)" class="card2">
            <div *ngIf="presencial">
                <p *ngIf="!isMobile" class="headline6">{{'registration.stepTwo.days'|translate}}</p>
                <p *ngIf="isMobile" class="subtitle1">{{'registration.stepTwo.days'|translate}}</p>
            </div>
            <div *ngIf="!presencial">
                <p *ngIf="!isMobile" class="headline6">{{'registration.stepTwo.days2'|translate}}</p>
                <p *ngIf="isMobile" class="subtitle1">{{'registration.stepTwo.days2'|translate}}</p>
            </div>
            <br/>

            <div class="flexInLineCenter">

                <div *ngFor="let day of days">
                    <button *ngIf="!day.selected" [disabled]="day.disabled" (click)="saveDay(day)"
                    mat-stroked-button>{{day.name}}</button>
                    <button *ngIf="day.selected && !day.disabled" class="selectedOption" (click)="saveDay(day)"
                    mat-raised-button>{{day.name}}</button>
                </div>
            </div>
        </div>
        <br />
    </div>
</form>

<div *ngIf="!isMobile" class="cardCenter3">
    <div class="buttonInsideCard">
        <app-buttons 
            [enablePrevious]="true" 
            [mode]="modeForm.value" 
            [disabled]="!valid">
        </app-buttons>
    </div>
</div>

<div *ngIf="isMobile">
    <br/>
</div>

<app-buttons 
    *ngIf="isMobile" 
    [enablePrevious]="true" 
    [mode]="modeForm.value" 
    [disabled]="!valid">
</app-buttons>