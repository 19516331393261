import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/app/auth.guard';
import { RoleString } from 'src/app/enums/role';
import {StudentMyProfileComponent } from '../profile/student-my-profile/student-my-profile.component';

const routes: Routes = [
  {
    path: 'alumno/mi-perfil',
    component: StudentMyProfileComponent,
    canActivate: [AuthGuard],
    data: { roles: [RoleString.STUDENT] }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class StudentRoutingModule { }