<div [class]="class" *ngIf="!massive && otroParticipante">

    <app-avatar
        *ngIf="idUsuario"
        [class]="'imgRoundedTable'"
        [containerClass]="'imgOptionMessage'"
        [usuarioId]="idUsuario">
    </app-avatar>

    <div class="optionBody">
        <div class="flexBeetwen">
            <span [class]="nameClass" *ngIf="otroParticipante && otroParticipante.descripcionUsuario">{{otroParticipante.descripcionUsuario | reduceText:40 }}</span>
            <app-user-tag
                *ngIf="otroParticipante.idUsuario"
                [idParticipante]="otroParticipante.idUsuario"
                [rol]="otroParticipante.rol">
            </app-user-tag>

            <span>
                <mat-icon *ngIf="miParticipante.flgLeido" class="messageOptionIcon" (click)="markUnread(); $event.stopPropagation()">email</mat-icon>
                <span class="body5 gray02Color">{{canal.fecha | date:'dd/MM/yyy'}}</span>
            </span>
        </div>
        <span class="body3" *ngIf="canal && canal.ultimoMensaje">{{canal.ultimoMensaje | reduceText:50 }}</span>
    </div>
</div>

<div [class]="class" *ngIf="massive">
    <div class="uploadDiv imgOptionMessage">
        <div *ngIf="!unreadMassive && !isAdmin" class="massiveCircleEmpty">
            <mat-icon svgIcon="massiveEmpty" class="imgMassive" ></mat-icon>
        </div>
        <div *ngIf="unreadMassive || isAdmin" class="massiveCircle">
            <mat-icon svgIcon="massive" class="imgMassive" ></mat-icon>
        </div>
    </div>

    <div class="optionBody" *ngIf="isAdmin">
        <span class="subtitle3Blue"> {{'messages.list.massive.title' | translate}} </span>
        <br/>
        <span class="body3"> {{'messages.list.massive.text' | translate}} </span>
    </div>

    <div class="optionBody" *ngIf="!isAdmin">
        <span class="subtitle3" *ngIf="!unreadMassive">{{'messages.list.massive.titleNoAdmin' | translate  : {equipoNombre : equipoNombre} }} </span>
        <span class="subtitle3Blue" *ngIf="unreadMassive">{{'messages.list.massive.titleNoAdmin' | translate  : {equipoNombre : equipoNombre} }} </span>
        <br/>
        <span class="body3"> {{'messages.list.massive.textNoAdmin' | translate}} </span>
    </div>
</div>