<div class="dialogContainer450">
    <app-loading *ngIf="waiting" [showText]="false"></app-loading>
    <div class="flexBeetwen">
        <p class="headline5">  {{'profile.gallery.new.title' | translate}} {{data.nombre}} apto médico</p>

        <button mat-button class="buttonDialogClose" mat-dialog-close>
            <mat-icon class="clearIcon">close</mat-icon>
        </button>
    </div>
    <form [formGroup]="newForm">
        <!-- <mat-form-field appearance="fill" class="inputfullWidth">
            <mat-label>{{'profile.gallery.new.type' | translate}}</mat-label>
            <mat-select disabled formControlName="tipoArchivo">
              <mat-option *ngFor="let tipoArchivo of data.tiposArchivo" [value]="tipoArchivo.id">
                {{tipoArchivo.descripcion}}
              </mat-option>
            </mat-select>
          </mat-form-field> -->

        <mat-form-field appearance="fill" class="inputfullWidth">
            <mat-label>  {{'profile.gallery.new.name' | translate}} </mat-label>
            <input required matInput type="text" formControlName="itemNombre">      
        </mat-form-field>

        <mat-form-field  appearance="fill" class="inputfullWidth">
            <mat-label> {{'profile.gallery.new.emision' | translate}} </mat-label>
            <input required id="dateInput2" matInput disabled formControlName="emision" [matDatepicker]="picker2" [max]="maxDate">
            <mat-datepicker-toggle class="primaryIcon"  matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker disabled="false" #picker2>
                <mat-datepicker-actions>
                    <button mat-button matDatepickerCancel>{{ 'calendar.cancel' | translate}}</button>
                    <button mat-raised-button color="accent" matDatepickerApply>{{ 'calendar.save' | translate}}</button>
                  </mat-datepicker-actions>
            </mat-datepicker>
        </mat-form-field>

        <app-files 
            [archivos]="archivos"
            [showDelete]="true"
            (deleteFilesEvent)="deleteFiles($event)">
        </app-files>

        <div>
            <label for="file-upload-gallery">
              <p class="body4 fakeButton"> {{'profile.gallery.new.attachments' | translate | uppercase}} </p>
            </label>
            <input id="file-upload-gallery" type="file" accept="application/pdf, image/jpeg, image/jpg, image/png" (change)="selectFiles($event)" (click)="nullFiles($event)"/>
            <p id="label" class="body4 primaryHint centerText">{{'profile.gallery.new.label1' | translate}} </p>
            <p id="label" class="body4 primaryHint centerText">{{'profile.gallery.new.label2' | translate}} </p>
        </div>
    </form>

    <button
        mat-raised-button color="accent" 
        [disabled]="!newForm.valid || archivos.length == 0" 
        (click)="onSubmit()" 
        id="submit" 
        class="buttonDialog"> 
        {{'section.dialogs.confirmButton' | translate}}
    </button>
</div>
