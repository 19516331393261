import { Component, OnInit } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackbarService } from '../../../utils/snackbar/snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from '../../../api/services/login-service.service';
import { ResetPasswordForgotten, ResetPasswordRepeat } from '../../../api/interfaces/login';


@Component({
  selector: 'app-change-forgotten-password',
  templateUrl: './change-forgotten-password.component.html',
  styleUrls: ['./change-forgotten-password.component.scss']
})
export class ChangeForgottenPasswordComponent implements OnInit {

  hide: boolean = true;
  hintClass: string = "hideHint";
  token: string;

  changeForm = new FormGroup({
    password: new FormControl('', [
      Validators.required,
      Validators.pattern(/^(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/),
    ]),
    repeatPassword: new FormControl('', [
      Validators.required
    ]),
    email: new FormControl('', [
      Validators.required
    ])
  });
  constructor(
    private snack: SnackbarService,
    private router: Router,
    private translate: TranslateService,
    private loginApiService: LoginService,
    private route: ActivatedRoute,
  ) { }

    ngOnInit() {
      this.route.paramMap.subscribe(params => {
        this.token = params['params'].id;
        if(this.token !== undefined) {

        }
       });
    }

    showHintOnClick() {
      this.hintClass = "primaryHint";
    }

    nueva() {
      this.loginApiService.setNewPassForgotten({
        token: this.token,
        email: this.changeForm.value.email,
        clave: this.changeForm.value.password,
        repetirClave: this.changeForm.value.repeatPassword,
      } as ResetPasswordForgotten)
      .subscribe(respuesta => {
        this.snack.snackTranslate('changePassword.success');
        this.router.navigate(['/login']);
      },
        error => {
          console.error(error);
          this.snack.openSnackBar(error.error.message);
        });
    }

    onSubmit() {
      if (this.changeForm.value.password != this.changeForm.value.repeatPassword) {
        this.snack.snackTranslate('snacks.error.passwordDontMatch');
      } else {
        this.nueva();
      }
    }
}
