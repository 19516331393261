<mat-card class="cardSection">

    <p class="headline4" *ngIf="tipo == 'N' && !isMobile"> {{ 'section.titles.nutr' | translate }}</p>
    <p class="headline4" *ngIf="tipo == 'T' && !isMobile"> {{ 'section.titles.comp' | translate }}</p>
    
    <div cdkDropList (cdkDropListDropped)="drop($event)">
        <div *ngFor="let seccion of secciones; let index = index" cdkDrag [cdkDragDisabled]="disableDragDrop">
            <app-section 
                [seccion]="seccion"
                [disableDragDrop]="disableDragDrop"
                [class]="class"
                [index]="index"
                (toogleSectionsEvent)="toggleDragDrop()"
                (blockActionButtonsEvent)="blockActionButtonsFunction($event)">
            </app-section>
        </div>
    </div>

    <button *ngIf="!disableDragDrop && isAdmin" (click)="toggleDragDrop()" mat-raised-button color="accent" class="primaryButton2" id="dragButton">
        <mat-icon class="whiteIcon">save</mat-icon>
        {{ 'planes.forms.save' | translate | uppercase }}
    </button>
    
    <button *ngIf="disableDragDrop && isAdmin && blockActionButtons" (click)="addSection()" mat-button color="primary" id="addButton">
        {{ 'section.new' | translate}}
    </button>
</mat-card>