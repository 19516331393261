import { Component, OnInit, Input } from '@angular/core';
import { EntrenadorAsignado } from 'src/app/api/interfaces/trainers';
import { RolService } from 'src/app/services/rol/rol.service';
import { RoleString} from '../../../../enums/role';

/**
 * show participante and user tag according to current loged user
 * Current loged user - tags to show
 * Admin - tag all trainers
 * student - his trainer
 * trainer - all admins
 */
@Component({
  selector: 'app-user-tag',
  templateUrl: './user-tag.component.html',
  styleUrls: ['./user-tag.component.scss']
})
export class UserTagComponent implements OnInit {

  @Input('idParticipante') idParticipante: number;
  @Input('rol') rol: string;

  rolToShow: string = RoleString.TRAINER;
  
  showTrainerTag: boolean = false;
  showTagFromCanal: boolean = false;

  isAdmin: boolean = false;
  isTrainer: boolean = false;

  constructor(
    private rolService: RolService
  ) { }

  ngOnInit(): void {

    this.isAdmin = this.rolService.isAdmin();
    this.isTrainer = this.rolService.isTrainer();

    const entrenadorAsignado: EntrenadorAsignado = JSON.parse(localStorage.getItem('entrenadorAsignado'));
    if(entrenadorAsignado && entrenadorAsignado.usuarioId) {
      this.showTrainerTag =  (this.idParticipante == entrenadorAsignado.usuarioId);    
    }

    if(this.rol) { 
      const adminSeeTrainerTag: boolean = (this.isAdmin && this.rol.toUpperCase() == RoleString.TRAINER);
      const trainerSeeAdminTag: boolean = (this.isTrainer && this.rol.toUpperCase() == RoleString.ADMIN);
      if(adminSeeTrainerTag || trainerSeeAdminTag) {
        this.showTagFromCanal = true;
      }
    }
  }
}
