import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { Expanded, FiltersStorage } from '../../api/interfaces/user';
import { SnackbarService } from '../../utils/snackbar/snackbar.service';
import { environment } from 'src/environments/environment';
import { SharedDataService } from '../sharedData/shared-data.service';
import { FlagsColor, FlagsSupport } from 'src/app/enums/sharedDataFlag';
import { PaletaColoresService } from '../paletaColores/paleta-colores.service';
import { ThemeService } from 'src/app/theme.service';

@Injectable({
  providedIn: 'root'
})
export class LogoutService {

  private url = environment.apiUrl + '/';

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json'
    }),
    withCredentials: true,
  };

  constructor(
    private snack: SnackbarService,
    private router: Router,
    private http: HttpClient,
    private sharedData: SharedDataService,
    private paletaColores: PaletaColoresService,
    private themeService: ThemeService
  ) { }

  logoutCookie(): Observable<any> {
    return this.http.get<any>(this.url + `logout`,  this.httpOptions);
  }

  public logOut() {
    sessionStorage.setItem('logout','true');
    this.paletaColores.setPaletaDefault();
    this.themeService.toggleThemeFromBackend('');
    this.logoutCookie().subscribe(response => { 
    },
      error => {
        this.sharedData.changeMessage(FlagsSupport.closeSupportDialog);
      });
  }

  public notAllowed() {
    sessionStorage.setItem('notAllowed','true');
    this.logoutCookie().subscribe(response => { 
    },
      error => {
        console.error(error);
      });
  }

  public blocked() {
    sessionStorage.setItem('blocked','true');
    this.logoutCookie().subscribe(response => { 
    },
      error => {
        console.error(error);
      });
  }

  public expirado() {
    const logout: boolean = JSON.parse(sessionStorage.getItem('logout'));
    const notAllowed: boolean = JSON.parse(sessionStorage.getItem('notAllowed'));
    const blocked: boolean = JSON.parse(sessionStorage.getItem('blocked'));

    if(logout || notAllowed || blocked) {
      if(logout) {
        this.snack.snackTranslate('snacks.logout.success');
      }
      if(notAllowed) {
        this.snack.snackTranslate('snacks.logout.notAllowed');
      }
      if(blocked) {
        this.snack.snackTranslate('snacks.logout.blocked');
      }
    } else {
      this.snack.snackTranslate('snacks.logout.expired');
      this.sharedData.changeMessage(FlagsSupport.closeSupportDialog);
    }
    this.clearStorage();
    this.paletaColores.setPaletaDefault();
    this.sharedData.changeMessage(FlagsColor.setDefaultColor);
    this.router.navigate(['']);
  }

  clearStorage() {
    localStorage.clear();
    sessionStorage.clear();
  }
}
