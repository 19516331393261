import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LoadingComponent } from 'src/app/components/plans/process/loading/loading.component';
import { RolService } from 'src/app/services/rol/rol.service';
import { ParametrosService } from '../../../../api/services/parametros/parametros.service';
import { PlansService } from '../../../../api/services/plans/plans.service';
import { MobileDetectorService } from '../../../../services/mobileDetector/mobile-detector.service';
import { SharedDataService } from '../../../../services/sharedData/shared-data.service';
import { SnackbarService } from '../../../../utils/snackbar/snackbar.service';
import { WeekService } from '../../../../utils/week/week.service';
import { DialogComponent } from '../../../plans/process/dialog/dialog.component';
import { CreateStudentComponent } from '../create-student/create-student.component';

@Component({
  selector: 'app-mainpageadmin',
  templateUrl: './mainpageadmin.component.html',
  styleUrls: ['./mainpageadmin.component.scss']
})
export class MainpageadminComponent implements OnInit {

  isMobile: boolean = false;
  isTrainer: boolean = false;
  ultimaEjecucion: any;
  ejecutoEstaSemana: boolean = false;

  constructor(
    public dialog: MatDialog,
    private mobileDetectorService: MobileDetectorService,
    private weekService: WeekService,
    private plansService: PlansService,
    private snack: SnackbarService,
    private parametrosService: ParametrosService,
    private sharedData: SharedDataService,
    private rolService: RolService
  ) { }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    this.isTrainer = this.rolService.isTrainer();
    sessionStorage.setItem('active', 'alumnos');
    this.getUltimoEnvio();
    this.sharedData.currentMessage.subscribe(message => {
      if (message === "reloadStudentData") {
        this.getUltimoEnvio();
      }
    });
  }

  getUltimoEnvio() {
    this.parametrosService.getProcesos('Procesos', 'ultimaejecucion').subscribe(response => {
      //bugfix pipe safari
      let aux: string = response.valor;
      let auxs = aux.split(' ');
      this.ultimaEjecucion = `${auxs[0]}T${auxs[1]}`;
      // this.getSemanaHabilitada();
    },
      error => {
        console.error(error);
        this.snack.snackTranslate('snacks.error.generic');
      })
  }

  // getSemanaHabilitada() {
  //   this.parametrosService.getProcesos('Procesos', 'semanahabilitada').subscribe(response => {
  //     this.ejecutoEstaSemana = this.weekService.isCurrentWeek(response.valor);
  //   },
  //     error => {
  //       console.error(error);
  //       this.snack.snackTranslate('snacks.error.generic');
  //     })
  // }

  openDialogCreateStudent() {
    this.dialog.open(CreateStudentComponent, {
      backdropClass: 'backdropBackground',
    });
  }

  process(habilitar: boolean) {
    // dia debe ser de la semana que viene
    
    let semanaActual = this.weekService.getWeek(new Date());
    let aux = new Date();
    let date = aux.getDate() + 7;
    aux.setDate(date);
    let semana2 = this.weekService.getWeek(aux);
    const diaSemanaActual: string = this.weekService.convertDate(semanaActual[0]);
    const dia: string = this.weekService.convertDate(semana2[0]);
    // this.dialog.open(LoadingComponent);

    if(!habilitar) {
      this.plansService.controlPlanes(diaSemanaActual).subscribe(pending=> {
        const dialogRef = this.dialog.open(DialogComponent, {
          data: {
            iconClass: "bigBlueIcon",
            dia: dia,
            diaSemanaActual: diaSemanaActual,
            icon: habilitar? "content_copy" : "send",
            pending: pending,
            habilitar: habilitar
          }
        });
  
      },
        error => {
          console.error(error);
          if(error.error.message) {
            this.snack.openSnackBar(error.error.message);
          } else {
            this.snack.snackTranslate('snacks.error.generic');
          }
        });
    } else {
      const dialogRef = this.dialog.open(DialogComponent, {
        data: {
          iconClass: "bigBlueIcon",
          dia: dia,
          diaSemanaActual: diaSemanaActual,
          icon: habilitar? "content_copy" : "send",
          pending: null,
          habilitar: habilitar
        }
      });
    }
    
  }

}

