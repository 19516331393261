import { Component, OnInit, Input } from '@angular/core';
import { SuspensionTrainer } from 'src/app/api/interfaces/trainers';
import { TrainersService } from 'src/app/api/services/trainers/trainers.service';
import { RolService } from 'src/app/services/rol/rol.service';
import { HistorialSuspension } from '../../../../api/interfaces/user';
import { StatusEnum } from '../../../../enums/status';
import { StudentService } from '../../../../services/admin/student/student.service';
import { SharedDataService } from '../../../../services/sharedData/shared-data.service';
import { SnackbarService } from '../../../../utils/snackbar/snackbar.service';

@Component({
  selector: 'app-status-history',
  templateUrl: './status-history.component.html',
  styleUrls: ['./status-history.component.scss']
})
export class StatusHistoryComponent implements OnInit {

  @Input('alumnoId') alumnoId: number;
  @Input('entrenadorId') entrenadorId: number;
  @Input('isAdminOrTrainer') isAdminOrTrainer: boolean;

  StatusEnum = StatusEnum;
  historial: HistorialSuspension[] = [];
  historialTrainer: SuspensionTrainer[] = [];

  constructor(
    private studentService: StudentService,
    private snack: SnackbarService,
    private sharedData: SharedDataService,
    private trainersService: TrainersService
  ) { }

  ngOnInit(): void {
    this.sharedData.currentMessage.subscribe(message => {
    if(message =="updateStatus" || message == "updateStatusTrainer") {
      this.getHistorial();
    }
    });
    this.getHistorial();
  }

  getHistorial() {
    if(!this.isAdminOrTrainer) {

      this.historial = [];
      this.studentService.getHistorialSuspensionesAlumno(this.alumnoId).subscribe(response => {
        this.historial = response;
      },
      error => {
        this.snack.snackTranslate('snacks.error.generic');
      })
    } else {
      this.historialTrainer = [];
      this.trainersService.historialSuspensionesEntrenador(this.entrenadorId).subscribe(response => {
        this.historialTrainer = response;
      },
      error => {
        this.snack.snackTranslate('snacks.error.generic');
      })
    }
  }

}
