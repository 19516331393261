import { ChangeDetectorRef, Component, OnInit, Input } from '@angular/core';
import { SharedDataService } from '../../../services/sharedData/shared-data.service';
import { FormValidatorService } from '../../../services/formValidator/form-validator.service';
import { MobileDetectorService } from '../../../services/mobileDetector/mobile-detector.service';
import { FormularioDinamicoPregunta, RespuestaFormularioDinamicoPregunta } from 'src/app/api/interfaces/formulario-dinamico';
import { FormsDinamicService } from 'src/app/api/services/formsDinamic/forms-dinamic.service';


@Component({
  selector: 'app-step-three',
  templateUrl: './step-three.component.html',
  styleUrls: ['./step-three.component.scss']
})
export class StepThreeComponent implements OnInit {

  @Input('cantidadPasosHabilitados') cantidadPasosHabilitados: number;
  @Input('posicion') posicion: number;

  preguntas: FormularioDinamicoPregunta[] = null;
  formPreguntasValid: boolean = false;
  respuestas: RespuestaFormularioDinamicoPregunta[] = null;

  isMobile: boolean = false;

  constructor(
    private cdr: ChangeDetectorRef,
    private formValidatorService: FormValidatorService,
    private sharedData: SharedDataService,
    private mobileDetectorService: MobileDetectorService,
    private formsDinamicService: FormsDinamicService 
  ) { }

  ngOnInit(): void {

    this.isMobile = this.mobileDetectorService.isMobile();
    this.cdr.detectChanges();
    this.getFormDinamic();
  }

  getFormDinamic() {
    this.formsDinamicService.getPreguntas()
    .subscribe(response => {
      this.preguntas = response;
      },
        error => {
          console.error(error);
        });
  }

  /**
 * Change boolean, allowing user (or not) to continue to next step
 * @param puede 
 */
  puedeContinuar(puede: boolean) {
    this.formPreguntasValid = puede;
    this.formValidatorService.onFormValidation(puede ? "VALID" : "INVALID", "step3","mode")
  }

  /**
   * Get respuestas from forms and save it in sessionStorage, 'antecedentesRespuesta'
   * @param respuestas 
   */
  crearRespuestas(respuestas: RespuestaFormularioDinamicoPregunta[]) {
    this.respuestas = respuestas;
    sessionStorage.setItem('antecendentesRespuesta', JSON.stringify(this.respuestas));
  }

}