import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { SettingsEnum } from 'src/app/enums/settings';
import { RolService } from 'src/app/services/rol/rol.service';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';
import { Bank, DatosBancario } from '../../../../api/interfaces/register';

@Component({
  selector: 'app-bank-data',
  templateUrl: './bank-data.component.html',
  styleUrls: ['./bank-data.component.scss']
})
export class BankDataComponent implements OnInit {

  // ticket 4174 removido entidad y clave bancaria

  disable: boolean = true;
  isAdmin: boolean = true;

  @Input('estado') estado: string;
  @Input('alumnosDatosBancario') alumnosDatosBancario: DatosBancario
  @Output() onSubmitBankEvent = new EventEmitter<Bank>();

  bankForm = new FormGroup({
    // entidad: new FormControl({ value: '', disabled: this.disable }),
    // claveBancaria: new FormControl({ value: '', disabled: this.disable }, [
    //   Validators.pattern(/^[a-zA-Z0-9]+$/),
    //   Validators.minLength(24),
    //   Validators.maxLength(24),
    // ]),
    arancel: new FormControl({ value: '', disabled: this.disable }),
  });

  constructor(
    private sharedData: SharedDataService,
    private rol: RolService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.patch();
    this.sharedData.currentMessage.subscribe(message => {
      if (message === "updateBank") {
        this.patch();
      }
    });
    this.isAdmin = this.rol.isAdmin();
  }

  patch() {
    // this.bankForm.patchValue({entidad: this.alumnosDatosBancario.entidad});
    // this.bankForm.patchValue({claveBancaria: this.alumnosDatosBancario.claveBancaria});
    this.bankForm.patchValue({arancel: this.alumnosDatosBancario.arancel});
  }

  edit() {
    this.disable = !this.disable;
    if(!this.disable) {
      // this.bankForm.controls['entidad'].enable();
      // this.bankForm.controls['claveBancaria'].enable();
      if(this.isAdmin) {
        this.bankForm.controls['arancel'].enable();
      }
    } else {
      // this.bankForm.controls['entidad'].disable();
      // this.bankForm.controls['claveBancaria'].disable();
      this.bankForm.controls['arancel'].disable();
    }
  }

  onSubmit() {
    this.edit();
    this.onSubmitBankEvent.emit(this.bankForm.value);
  }

  navigateConfigAranceles() {
    sessionStorage.setItem('autoNavigate',SettingsEnum.ARANCEL.toString());
    this.router.navigate(['/admin/configuracion']);
  }
}
