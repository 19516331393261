<div class="background">

    <app-header></app-header>
    <app-navbar *ngIf="!isMobile"></app-navbar>
    <section>
        <div *ngIf="!isMobile">
            <app-card-section 
                [secciones]="secciones" 
                [tipo]="tipo">
            </app-card-section>
            <app-show-item-selected></app-show-item-selected>
        </div>

        <div *ngIf="isMobile">
            <br/>
            <p class="headline5 flexLeft" *ngIf="tipo == 'N'"> {{ 'section.titles.nutr' | translate }}</p>
            <p class="headline5 flexLeft" *ngIf="tipo == 'T'"> {{ 'section.titles.comp' | translate }}</p>

            <app-card-section 
                *ngIf="showSection"
                [secciones]="secciones" 
                [tipo]="tipo">
            </app-card-section>
            <app-show-item-selected *ngIf="!showSection"></app-show-item-selected>
        </div>
    </section>
</div>