import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminFeedbackComponent } from '../admin-feedback/admin-feedback.component';
import { ListComponent } from '../list/list.component';
import { NewComponent } from '../new/new/new.component';
import { StudentPlansComponent } from '../student-plans/student-plans.component';
import { AuthGuard } from 'src/app/auth.guard';
import { RoleString } from 'src/app/enums/role';



const routes: Routes = [
  {
    path: 'planes/lista',
    component: ListComponent,
    canActivate: [AuthGuard],
    data: { roles: [RoleString.ADMIN] }
  },
  {
    path: 'planes/nuevo',
    component: NewComponent,
    canActivate: [AuthGuard],
    data: { roles: [RoleString.ADMIN] }
  },
  {
    path: 'planes/editar',
    component: NewComponent,
    canActivate: [AuthGuard],
    data: { roles: [RoleString.ADMIN] }
  },
  {
    path: 'planes/semana',
    component: StudentPlansComponent,
    canActivate: [AuthGuard],
    data: { roles: [RoleString.STUDENT] }
  },
  {
    path: 'admin/feedback',
    component: AdminFeedbackComponent,
    canActivate: [AuthGuard],
    data: { roles: [RoleString.ADMIN] }
  },
  {
    path: 'entrenador/feedback',
    component: AdminFeedbackComponent,
    canActivate: [AuthGuard],
    data: { roles: [RoleString.TRAINER] }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class PlansRoutingModule { }