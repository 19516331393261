<div class="dialogContainer" *ngIf="createTrainer">
    <div class="flexBeetwen">
        <p mat-dialog-title class="headline5">{{ 'admin.createTrainer.title' | translate }}</p>
        <button mat-button class="buttonDialogClose" mat-dialog-close>
            <mat-icon class="clearIcon">close</mat-icon>
        </button>
    </div>
    <mat-dialog-content class="mat-typography">
        <form [formGroup]="trainerForm">
            <mat-form-field class="inputFullWidth2" appearance="fill">
                <mat-label>{{ 'forms.user.emailInput' | translate}}</mat-label>
                <input id="emailInput" formControlName="entrenadorEmail" type="email" matInput required>
            </mat-form-field>
            
            <mat-form-field class="inputFullWidth2" appearance="fill">
                <mat-label>{{ 'forms.user.name' | translate}}</mat-label>
                <input id="nameInput" formControlName="entrenadorNombre" type="text" matInput required>
            </mat-form-field>
            
            <mat-form-field class="inputFullWidth2" appearance="fill">
                <mat-label>{{ 'forms.user.lastName' | translate}}</mat-label>
                <input id="lastNameInput" formControlName="entrenadorApellido" type="text" matInput required>
            </mat-form-field>

            <mat-form-field class="inputFullWidth2" appearance="fill">
                <mat-label>{{ 'forms.user.secondLastname' | translate}}</mat-label>
                <input id="secondLastNameInput" formControlName="segundoApellido" type="text" matInput>
            </mat-form-field>
            
        </form>
    </mat-dialog-content>
        
        <button color="accent" [disabled]="!trainerForm.valid || waiting" mat-raised-button class="buttonDialog" (click)="onSubmit()">{{ 'admin.createTrainer.button' | translate }}</button>
</div>
<div class="dialogContainer" *ngIf="success">
    <div class="centerText">
        <img class="logo" src="../../../../../assets/done.jpg">
        <p mat-dialog-title class="headline5">{{ 'admin.createTrainer.success.title' | translate }}</p>
    </div>
    <p class="subtitle1">{{ 'admin.createTrainer.success.text' | translate }}</p>
    <button mat-raised-button color="accent" class="buttonDialog" [mat-dialog-close]="true" cdkFocusInitial >{{ 'admin.createTrainer.success.button' | translate }}</button>
</div>