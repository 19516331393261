import { Component, OnInit, ViewChild, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormControl} from '@angular/forms';
import { Filters, FiltersStorage } from '../../../../api/interfaces/user';
import { MobileDetectorService } from '../../../../services/mobileDetector/mobile-detector.service';
import {MatAccordion} from '@angular/material/expansion';
import { RegisterService } from 'src/app/api/services/register/register.service';
import { TrainingDays } from 'src/app/api/interfaces/register';


@Component({
  selector: 'app-filters-domiciliacion',
  templateUrl: './filters-domiciliacion.component.html',
  styleUrls: ['./filters-domiciliacion.component.scss']
})
export class FiltersDomiciliacionComponent implements OnInit {

  @ViewChild(MatAccordion) accordion: MatAccordion;
  @ViewChild('trainerSelect') trainerSelect;
  @Output() filtersChangeEvent = new EventEmitter<Filters>();

  isMobile: boolean = false;
  contador: number;
  filtersForm = new FormGroup({
    nombreApellido: new FormControl(),
    status: new FormControl(),
    type: new FormControl(),
    leido: new FormControl()
  });

  allStatus = [
    {
      name: "Cobrado",
      est: "E"
    },
    {
      name: "No cobrado",
      est: "N"
    },
  ];

  types: any;
  modes: any;
  trainingDays = [
    {
      "entrenamientoGrupoId": 2,
      "grupoDescripcion": "Online"
    },
    {
      "entrenamientoGrupoId": 1,
      "grupoDescripcion": "Presencial"
    },
  ];

  
  disabled: boolean = true;
  statusClass = "inputsFilter";

  constructor(
    private mobileDetectorService: MobileDetectorService,
    private registerService: RegisterService,
    private cdr: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    this.cdr.detectChanges();
    this.filtersForm.statusChanges
    .subscribe(val => {
      const { nombreApellido, status, type,  leido } = this.filtersForm.getRawValue();
      this.contador = 0;
      if(status) {
        this.contador++;
        this.statusClass = "inputsFilterWithContent"
      } else {
        this.statusClass = "inputsFilter"
      }
      if(type) {
        this.contador++;
      }
      if(nombreApellido) {
        this.contador++;
      }
      if(leido != null) {
        this.contador++;
      }
      if(this.contador > 0) {
        this.disabled = false;
      } else {
        this.disabled = true;
      }
    });
  }

  setFiltersFromLocalStorage() {
    // const filters: FiltersStorage = JSON.parse(localStorage.getItem('filters'));
    // if(filters) {
    //   this.filtersForm.patchValue({nombreApellido: filters.nombreApellido});
    //   this.filtersForm.patchValue({status: filters.status});
    //   this.filtersForm.patchValue({leido: filters.leido});
    //   this.filtersForm.patchValue({type: filters.type});
    // }
  }

  onSubmit() {
    this.filtersChangeEvent.emit(this.filtersForm.value);
  }

  clearAll() {
    this.filtersForm.patchValue({status: null});
    this.filtersForm.patchValue({type: null});
    this.filtersForm.patchValue({leido: null});
    this.filtersForm.patchValue({nombreApellido: null});
    this.filtersChangeEvent.emit(this.filtersForm.value);
  }

  clear(target: string) {
    switch (target) {
      case 'nombreApellido':
      this.filtersForm.patchValue({nombreApellido: null});
      break;
      
      
      case 'status':
      this.filtersForm.patchValue({status: null});
      break;
      
      case 'type':
      this.filtersForm.patchValue({type: null});
      break;

      case 'leido':
      this.filtersForm.patchValue({leido: null});
      break;
    }
    this.filtersChangeEvent.emit(this.filtersForm.value);
  }

}


