import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RolService } from 'src/app/services/rol/rol.service';
import { Bank, DatosBancario, PasosHabilitados } from '../../../api/interfaces/register';
import { Physiological, Personal, Contact } from '../../../api/interfaces/user';
import { IsSameObjectService } from '../../../services/isSameObject/is-same-object.service';

@Component({
  selector: 'app-all-data',
  templateUrl: './all-data.component.html',
  styleUrls: ['./all-data.component.scss']
})
export class AllDataComponent implements OnInit {

  @Input('physiologicalData') physiologicalData: Physiological;
  @Input('personalData') personalData : Personal;
  @Input('alumnosDatosBancario') alumnosDatosBancario: DatosBancario;
  @Input('contactData') contactData: Contact;
  @Input('showComplete') showComplete: boolean;
  @Input('estado') estado: string;
  
  @Output() editPersonalEvent = new EventEmitter<Personal>();
  @Output() editPhysiologicalEvent = new EventEmitter<Physiological>();
  @Output() editBankEvent = new EventEmitter<DatosBancario>();
  @Output() editContactEvent = new EventEmitter<Contact>();

  isTrainer: boolean = false;


  // ticket 2752
  pasosHabilitados: PasosHabilitados;

  constructor(
    private isSameObject: IsSameObjectService,
    private rolService: RolService
  ) { }

  ngOnInit(): void {
    this.isTrainer = this.rolService.isTrainer();
    this.pasosHabilitados = JSON.parse(sessionStorage.getItem('pasosHabilitados'));
  }

  onSubmitPersonal(auxPersonal: Personal) {
    if(!this.isSameObject.isSame(auxPersonal, this.personalData)) {
      this.editPersonalEvent.emit(auxPersonal);
    }
  }

  onSubmitPhysiological(auxPhysiological: Physiological) {
    if(!this.isSameObject.isSame(auxPhysiological, this.physiologicalData)) {

      this.editPhysiologicalEvent.emit(auxPhysiological);
    }
  }

  // onSubmitDomicile(auxDomicile: Domicile) {
  //   this.domicileFormNotChanged = this.isSameObject.isSame(auxDomicile,this.domicileData);
  // }

  onSubmitBank(auxBank: DatosBancario) {
    if(!this.isSameObject.isSame(auxBank, this.alumnosDatosBancario)) {
      this.editBankEvent.emit(auxBank);
    }
  }

  onSubmitContact(auxContact: Contact) {
    if(!this.isSameObject.isSame(auxContact, this.contactData)) {
      this.editContactEvent.emit(auxContact);
    }
  }

}