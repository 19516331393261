import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SwUpdate } from '@angular/service-worker';
import { GenericDialogComponent } from 'src/app/components/generic-dialog/generic-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class LogUpdateService {

  constructor(
    private updates: SwUpdate,
    private swUpdate: SwUpdate,
    public dialog: MatDialog) {
   
  }

  /**
   * Ask user for permision to update the current version, if user refuse, 
   * the update will be automatically applied the next time.
   * If an error ocurred, user receive a notificacion and reload the page to fix it.
   */
  checkForUpdates() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe((event) => {

          const dialogRef = this.dialog.open(GenericDialogComponent, {
            data: {
              title: "ACTUALIZACIÓN DISPONIBLE",
              iconClass: "bigBlueIcon leftAlertIcon",
              text: "Una nueva versión está disponible para ser instalada. Por favor, confirme para aplicarla inmediatamente. Si no confirmás, se aplicará la próxima vez que ingreses",
              icon: "update",
              confirm: "ACTUALIZAR",
              cancel: "MÁS TARDE",
              showConfirmAsPrimary: true
            }
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              window.location.reload();
            }
          }); 
      });
  }

  this.swUpdate.unrecoverable.subscribe(event => {
    console.error(event.reason);
    const dialogRef = this.dialog.open(GenericDialogComponent, {
      data: {
        title: "ERROR",
        iconClass: "bigRedIcon leftAlertIcon",
        text: "Ocurrió un error intentando actualizar. Se recargará la plataforma para solucionarlo.",
        icon: "report_problem",
        confirm: "RECARGAR",
        showConfirmAsPrimary: true
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      window.location.reload();
    }); 
  });
 
}

  logUpdates() {
    this.updates.available.subscribe(event => {
      console.log('current version is', event.current);
      console.log('available version is', event.available);
    });
    this.updates.activated.subscribe(event => {
      console.log('old version was', event.previous);
      console.log('new version is', event.current);
    });
  }
}

