<div class="dialogContainer" *ngIf="!waiting">
    <div class="flexBeetwen">
        <p mat-dialog-title class="headline5"> {{'profile.resendMail.button'| translate}}</p>
        <button mat-button class="buttonDialogClose" mat-dialog-close>
            <mat-icon class="clearIcon">close</mat-icon>
        </button>
    </div>
    
    <mat-dialog-content class="mat-typography">
        <form [formGroup]="studentForm">
            <mat-form-field class="inputFullWidth2" appearance="fill">
                <mat-label>{{ 'forms.user.emailInput' | translate}}</mat-label>
                <input id="emailInput" formControlName="email" type="email" matInput required>
            </mat-form-field>
        </form>
    </mat-dialog-content>
        
    <button 
        [disabled]="!studentForm.valid || waiting" 
        mat-raised-button color="accent" 
        class="buttonDialog" 
        (click)="onSubmit()"> 
        {{'profile.resendMail.confirm'| translate | uppercase}}
    </button>
    
</div>

<!-- ticket 2397 -->
<app-loading *ngIf="waiting" [showText]="false"></app-loading>