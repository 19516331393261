<body>
    <app-header [showLogout]="false"></app-header>
    <article>
        <div class="changePassword">
            <h1 class="headline5">{{'changePassword.title' | translate}}</h1>
            <form class="center" [formGroup]="changeForm">

                <mat-form-field class="inputFullWidth2" appearance="fill">
                    <mat-icon matPrefix>email</mat-icon>
                    <mat-label>{{ 'forms.user.emailInput' | translate}}</mat-label>
                    <input id="emailInput" formControlName="email" type="email" matInput required>
                </mat-form-field>
                <br />

                <mat-form-field class="inputFullWidth2" appearance="fill">
                    <mat-icon matPrefix>vpn_key</mat-icon>
                    <mat-label>{{'forms.user.passwordInput' | translate}}</mat-label>
                    <input (click)="showHintOnClick()" id="passwordInput" formControlName="password" matInput [type]="hide ? 'password' : 'text'" type="password" matInput required>
                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>

                <br />

                <mat-hint class="primaryHint">{{'changePassword.hint' | translate}}</mat-hint>
                <mat-form-field class="inputFullWidth2" appearance="fill">
                    <mat-icon matPrefix>vpn_key</mat-icon>
                    <mat-label>{{'forms.user.repeatPasswordInput' | translate}}</mat-label>
                    <input id="repeatPasswordInput" formControlName="repeatPassword" matInput [type]="hide ? 'password' : 'text'" required>
                    <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-pressed]="hide">
                        <mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </button>
                </mat-form-field>
                <button id="changeButton" class="primaryButton" color="accent" mat-raised-button (click)="onSubmit()" [disabled]="!changeForm.valid">{{'changePassword.buttonChange' | translate}}</button>
            </form>
            <app-oneinfoconsulting></app-oneinfoconsulting>
        </div>
        <!-- <app-footer></app-footer> -->
    </article>
</body>