<form [formGroup]="optionsForm">
    <p class="subtitle1 textLeft">{{pregunta.titulo}} <span *ngIf="requerido"> *</span></p>
    <div
        *ngFor="let opcion of opciones; let i = index"
        aria-labelledby="radioGroupColumn" 
        [required]="requerido">

        <span><mat-checkbox 
            color="primary" 
            class="body2" 
            formControlName="valor"
            [value]="opcion" 
            (click)="clickCheckbox(i)">
            {{opcion.detalleDescripcion}}
        </mat-checkbox></span>
            
    </div>

</form>