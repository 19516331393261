<div *ngIf="!registration">
    <header  *ngIf="!showLogout && !showLogoEquipo">
        <img class="headerLogo" src="../../../../assets/logo-iutrein.png">
    </header> 
    <div class="headerFixed" *ngIf="showLogout && !isMobile">
        <img class="headerLogo" [src]="logoEquipo" onerror="this.src='../../../../assets/logo-iutrein.png'">
        <app-support-button></app-support-button>
        <span class="userBox pointer"  [matMenuTriggerFor]="menu">
          <span class="body2">{{descripcionUsuario}}</span>
          
          <app-avatar
            *ngIf="usuarioId"
            [class]="'imgRoundedHeader'"
            [containerClass]=""
            [usuarioId]="usuarioId">
          </app-avatar>

          <!-- ticket 2743 -->
          <span class="circle" *ngIf="showCertificateAlert"></span>

        </span>
    </div>

    <header *ngIf="showLogout && isMobile">
      <div class="flexLeft">
        <app-navbar></app-navbar>
        <img class="headerLogo" [src]="logoEquipo" onerror="this.src='../../../../assets/logo-iutrein.png'">
      </div>

      <div class="flexLeft">
        <app-support-button></app-support-button>
        
         
        <span class="headerIconMobile" [matMenuTriggerFor]="menu">
          
          <app-avatar
            *ngIf="usuarioId"
            [class]="'imgRoundedHeader'"
            [containerClass]=""
            [usuarioId]="usuarioId">
          </app-avatar>
        </span>

         <!-- ticket 2743 -->
         <span class="circle" *ngIf="showCertificateAlert"></span>
      </div>

       
    </header>
</div>
    
<header  *ngIf="registration">
  <img class="headerLogo" [src]="logoEquipo" onerror="this.src='../../../../assets/logo-iutrein.png'">
    <p *ngIf="!isMobile" class="subtitle2Header"> {{'registration.header' | translate}} </p>
    <mat-icon class="headerIcon" (click)="logout()">exit_to_app</mat-icon>
</header>

<header  *ngIf="showLogoEquipo && !showLogout">
  <img class="headerLogo" [src]="logoEquipo" onerror="this.src='../../../../assets/logo-iutrein.png'">
</header>

<mat-menu #menu="matMenu" [hasBackdrop]="true">
  <button (click)="navigateProfile()" mat-menu-item>
    <mat-icon class="miPerfilIcon">
      person
    </mat-icon>
    <span class="body3"> {{'menu.profile'|translate}} </span>
  </button>
  <mat-divider ></mat-divider>
  <button mat-menu-item>
      <a target="_blank" href="/terminos-condiciones"> 
        <span class="body3"> {{'menu.termsLink'|translate}} </span>
      </a>
  </button>
  <!-- <button mat-menu-item>
    <a target="_blank" href="https://iutrein.godaddysites.com/t%C3%A9rminos-y-condiciones"> 
      <span class="body3"> {{'menu.termsLink'|translate}} </span>
    </a>
  </button> -->
  <mat-divider ></mat-divider>
  <button (click)="logout()" mat-menu-item>
    <span class="body3 logoutColor"> {{'menu.logout'|translate}} </span>
  </button>
</mat-menu>
