import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthorizationInterceptor } from './interceptors/authorization/authorization-interceptor.interceptor';
import { ExpiredInterceptor } from './interceptors/expired/expired.interceptor';

import { MaterialModule } from './material/material.module'; 
import { LoginModule } from './components/login/login.module';
import { LoginRoutingModule } from './components/login/login-routing.module';
import { RegistrationModule } from './components/registration/modules/registration.module'; 

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { TranslateComponent } from './components/translate/translate/translate.component';
import { SpinnerInterceptor } from './interceptors/spinner/spinner.interceptor';
import { RegistrationRoutingModule } from './components/registration/modules/registration-routing.module';

import { GlobalModule } from './components/global/modules/global.module';
import { AdminModule } from './components/admin/modules/admin.module';
import { ProfileModule } from './components/profile/profile.module';
import { StudentModule } from './components/student/modules/student.module';
import { PlansModule } from './components/plans/modules/plans.module';
import { SectionsModule } from './components/sections/modules/sections.module';

import { GenericDialogComponent } from './components/generic-dialog/generic-dialog.component';
import { FeedbackDialogComponent } from './components/plans/feedback-dialog/feedback-dialog.component';

import { registerLocaleData } from '@angular/common';
import localeEs from "@angular/common/locales/es";
import { MessagesModule } from './components/messages/modules/messages.module';

import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import { AutoTicketInterceptor } from './interceptors/autoTicket/auto-ticket.interceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { OfflineInterceptor } from './interceptors/offline/offline.interceptor';


              
import { OverlayContainer } from '@angular/cdk/overlay';
import { ThemeService } from './theme.service';
import { GenericDialogThreeButtonsComponent } from './components/generic-dialog-three-buttons/generic-dialog-three-buttons.component';
// import { PdfViewerModule } from 'ng2-pdf-viewer';

registerLocaleData(localeEs);

@NgModule({
  declarations: [
    AppComponent,
    TranslateComponent,
    GenericDialogComponent,
    FeedbackDialogComponent,
    GenericDialogThreeButtonsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    LoginModule,
    FormsModule,
    ReactiveFormsModule,
    LoginRoutingModule,
    RegistrationModule,
    RegistrationRoutingModule,
    GlobalModule,
    AdminModule,
    ProfileModule,
    StudentModule,
    PlansModule,
    MessagesModule,
    InfiniteScrollModule,
    SectionsModule,
    // PdfViewerModule,

    HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
          // enabled: environment.production,
          // Register the ServiceWorker as soon as the app is stable
          // or after 30 seconds (whichever comes first).
          registrationStrategy: 'registerImmediately'
          
        })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SpinnerInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ExpiredInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: AutoTicketInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: OfflineInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  exports: [
    ProfileModule,
    PlansModule
  ]
})
export class AppModule { 
  constructor(overlayContainer: OverlayContainer, private themeService: ThemeService) {
    themeService.getTheme().subscribe(data => {
      overlayContainer.getContainerElement().classList.add(data);
    });
  }
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}