<form [formGroup]="backgroundForm">

    <p *ngIf="isMobile" class="headline6Light"> {{posicion}}/{{cantidadPasosHabilitados}} {{ 'registration.stepThree.titleMobile' | translate}}</p>

    <mat-card class="cardCenter2">
        <p class="headline5NoCenter">{{ 'registration.stepAchObj.title' | translate}}</p>

        <p class="subtitle3">{{ 'registration.stepThree.question8.title' | translate}}</p>
        <p class="body2"><mat-checkbox (click)="changeFromCheckbox('ruta')" color="primary" formControlName="ruta">{{ 'registration.stepThree.question8.ruta' | translate}}</mat-checkbox></p>
        <div *ngIf="backgroundForm.value.ruta">
            <app-achievements
                [mode]="'ruta'"
                [alumnoId]="alumnoId"
                (changeAchievementEvent)="changeAchievement($event)"
                (emptyAchievementEvent)="emptyAchievement($event)">
            </app-achievements>
        </div>

        <p class="body2"><mat-checkbox (click)="changeFromCheckbox('pista')" color="primary" formControlName="pista">{{ 'registration.stepThree.question8.pista' | translate}}</mat-checkbox></p>
        <div *ngIf="backgroundForm.value.pista">
            <app-achievements 
                [mode]="'pista'"
                [alumnoId]="alumnoId"
                (changeAchievementEvent)="changeAchievement($event)"
                (emptyAchievementEvent)="emptyAchievement($event)">
            </app-achievements>
        </div>

        <p class="body2"><mat-checkbox (click)="changeFromCheckbox('trail')" color="primary" formControlName="trail">{{ 'registration.stepThree.question8.trail' | translate}}</mat-checkbox></p>
        <div *ngIf="backgroundForm.value.trail">
            <app-achievements
                [mode]="'trail'"
                [alumnoId]="alumnoId"
                (changeAchievementEvent)="changeAchievement($event)"
                (emptyAchievementEvent)="emptyAchievement($event)">
            </app-achievements>
        </div>

        <p class="body2"><mat-checkbox (click)="changeFromCheckbox('ultra')" color="primary" formControlName="ultra">{{ 'registration.stepThree.question8.ultra' | translate}}</mat-checkbox></p>
        <div *ngIf="backgroundForm.value.ultra">
            <app-achievements
                [mode]="'ultra'"
                [alumnoId]="alumnoId"
                (changeAchievementEvent)="changeAchievement($event)"
                (emptyAchievementEvent)="emptyAchievement($event)">
            </app-achievements>
        </div>

    </mat-card> 

    <mat-card class="cardCenter2">
        <p class="headline5NoCenter">{{ 'registration.stepThree.objetives.title' | translate}}</p>
        <p class="subtitle3">{{ 'registration.stepThree.objetives.question' | translate}}</p>
        <mat-radio-group color="primary" class="radioGroupColumn" formControlName="objetives" required>
            <mat-radio-button value="yes" (click)="changeObjetives(true)">{{'registration.stepThree.objetives.answer1' | translate}}</mat-radio-button>
            <div *ngIf="showObjetives">
                <app-objetives
                    *ngIf="!objetivesEmpty"
                    [alumnoId]="alumnoId"
                    (emptyEvent)="emptyObjetives($event)">
                </app-objetives>
            </div>
            <mat-radio-button
                [checked]="objetivesEmpty" 
                value="no" 
                (click)="changeObjetives(false)">
                {{'registration.stepThree.objetives.answer2' | translate}}
            </mat-radio-button>
        </mat-radio-group>

    </mat-card>
</form>

<div *ngIf="!isMobile" class="cardCenter3">
    <div class="buttonInsideCard">
        <app-buttons 
            [enablePrevious]="true" 
            [objetivesAchievements]="true" 
            [disabled]="!backgroundForm.valid || !objetivesValid || !rutaValid || !pistaValid || !ultraValid || !trailValid" >
        </app-buttons>
    </div>
</div>

<app-buttons 
    *ngIf="isMobile" 
    [enablePrevious]="true" 
    [objetivesAchievements]="true" 
    [disabled]="!backgroundForm.valid || !objetivesValid || !rutaValid || !pistaValid || !ultraValid || !trailValid" >
</app-buttons>