<div class="dialogContainer450">
    <br/>
    <div class="popupTitle">
        <span class="headline5">{{ 'admin.domiciliacion.popup.title' | translate }}</span>
        <button mat-button mat-dialog-close id="close">
            <mat-icon class="blackIcon">close</mat-icon>
        </button>
    </div>
    <p class="subtitle1">{{ 'admin.domiciliacion.popup.subtitle' | translate }}  <b> {{today | dateName: 'MMMM/YYYY'}} </b></p>
    <br/>

    <div class="flexEvenly">
        <button mat-raised-button color="accent" class="inputsFilterLarge" (click)="onSubmit()"> {{'admin.domiciliacion.popup.confirm' | translate}}</button>
    </div>
</div>