import { Injectable } from '@angular/core';
import { ParametroEdicion, ParametroEtiqueta } from '../../interfaces/parametros';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { WeekService } from '../../../utils/week/week.service';

@Injectable({
  providedIn: 'root'
})
export class ParametrosService {

  private url = environment.apiUrl + '/';

  constructor(
    private http: HttpClient,
    private weekService: WeekService
    ) { }

  httpOptionsJson = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }),
    withCredentials: true,
  };

  getProcesos(proceso: string, clave: string): Observable<ParametroEtiqueta> {
    return this.http.get<ParametroEtiqueta>(this.url + `api/parametrosclave?etiqueta=${proceso}&clave=${clave}`,  this.httpOptionsJson);
  }

  getParametro(parametro: string): Observable<ParametroEtiqueta> {
    return this.http.get<ParametroEtiqueta>(this.url + `api/parametros?etiqueta=${parametro}`,  this.httpOptionsJson);
  }

  getParametros(parametro: string): Observable<ParametroEtiqueta[]> {
    return this.http.get<ParametroEtiqueta[]>(this.url + `api/parametros?etiqueta=${parametro}`,  this.httpOptionsJson);
  }

  /**
   * Edit parametro.valor
   * @param parametro 
   * @returns 
   */
  editarParametro(parametro: ParametroEdicion): Observable<any> {
    return this.http.put<any>(this.url + `api/actualizar_parametro`,  parametro, this.httpOptionsJson);
  }

  getUltimaSemanaDisponible(): Promise<string[]> {
    return new Promise((resolve, reject) => {
    this.getProcesos('Procesos','semanahabilitada')
      .subscribe(async response => {
        // const proceso = response.find(proceso => proceso.clave == "semanahabilitada");
        let day = new Date(response.valor);
        let dia = day.getDay();
        if(dia == 0) {
          day.setDate(day.getDate() +1);
        }
        const semana: string[] = this.weekService.getWeek(day);
        resolve(semana);
      },
        error => {
          console.error(error);
          reject();
        });
      })
  }

  public getLongitudMensaje(): Promise<number> {
    return new Promise((resolve, reject) => {
      this.getParametro('Mensajeria')
      .subscribe(async response => {
        const longitud: number = parseInt(response[0].valor,10);
        resolve(longitud);
      },
        error => {
          console.error(error);
          reject();
        });
    })
  }
}
