import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ParseTimeService {

  constructor() { }

  public parseTime(time) : number[] {
    let aux = time.split(':');
    let parsed: number[] = [];
    if(aux.length == 3) {
      aux.forEach(element => {    
        parsed.push(parseInt(element,10));
      });
    } else {
      let tiempoMarcaHoras: number = time[0]+time[1];
      let tiempoMarcaMinutos: number = time[2]+time[3];
      let tiempoMarcaSegundos: number = time[4]+time[5];
      parsed[0] = tiempoMarcaHoras;
      parsed[1] = tiempoMarcaMinutos;
      parsed[2] = tiempoMarcaSegundos;
    }
      
    return parsed;
  }

  public parseToString(tiempoMarcaHoras: number, tiempoMarcaSegundos: number, tiempoMarcaMinutos: number): string {
    
    let horaString : string = "";
    let minutosString : string = "";
    let segundosString : string = "";

    if(tiempoMarcaHoras) {
      if(tiempoMarcaHoras < 10) {
        horaString = 0 + tiempoMarcaHoras.toString();
      } else {
        horaString = tiempoMarcaHoras.toString();
      }
    } else {
      horaString = "00";
    }

    if(tiempoMarcaMinutos) {
      if(tiempoMarcaMinutos < 10) {
        minutosString = 0 + tiempoMarcaMinutos.toString();
      } else {
        minutosString = tiempoMarcaMinutos.toString();
      }
    } else {
      minutosString = "00";
    }

    if(tiempoMarcaSegundos) {
      if(tiempoMarcaSegundos < 10) {
        segundosString = 0 + tiempoMarcaSegundos.toString();
      } else {
        segundosString = tiempoMarcaSegundos.toString();
      }
    } else {
      segundosString = "00";
    }

    let time = horaString + ":" + minutosString + ":" + segundosString; 
    return time;

  }
}
