<div class="background">
    <app-header></app-header>
    <app-navbar *ngIf="!isMobile"></app-navbar>
    <section>
        <app-main 
            [isAdminOrTrainer]="isAdminOrTrainer" 
            [id]="user_id">
        </app-main>
    </section>
</div>
