import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RegisterService } from 'src/app/api/services/register/register.service';
import { AntecedentesEnum } from 'src/app/enums/antecedentes';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';
import { environment } from '../../../../environments/environment';
import { Country, FiltratedObjetives, DatosBancario, Antecedente, AntecedentesMarcas, PasosHabilitados } from '../../../api/interfaces/register';
import { Formacion, Trainer, TrainerPut } from '../../../api/interfaces/trainers';
import { Contact, Personal, Physiological, Training,  PersonalTrainer, UserComplete, AllAchievements, Level, EntrenamientoModelBroken, MotivoBaja, EntrenamientoDiaIndividual } from '../../../api/interfaces/user';
import { UserService } from '../../../api/services/user/user.service';
import { MobileDetectorService } from '../../../services/mobileDetector/mobile-detector.service';
import { SnackbarService } from '../../../utils/snackbar/snackbar.service';
import { Subscription } from 'rxjs';
import { TrainersService } from '../../../api/services/trainers/trainers.service';
import { RolService } from 'src/app/services/rol/rol.service';
import { HoursDayFixedService } from 'src/app/utils/hoursDayFixed/hours-day-fixed.service';
import { ParametrosService } from 'src/app/api/services/parametros/parametros.service';
import { AlertTriggerService } from 'src/app/api/services/alertTrigger/alert-trigger.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  loaded: boolean = false;
  isMobile: boolean = false;
  isTrainer: boolean = false;
  entrenadorIsAdmin: boolean = null;
  entrenamientoDia: EntrenamientoDiaIndividual[] = [];
  
  alumnoUsuarioId: number = null;
  entrenadorId: number = null;

  disableSave: boolean = true;
  isMetasObjetivos: boolean = false;
  
  @Input('id') id: number;
  @Input('isAdmin') isAdmin: boolean;
  @Input('isStudent') isStudent: boolean;
  @Input('isAdminOrTrainer') isAdminOrTrainer: boolean;

  avatarUrl: string = null;
  estado: string = "";

  profile: UserComplete;
  trainerProfile: Trainer;

  personalData: Personal;
  country: Country;
  physiologicalData: Physiological;
  contactData: Contact;
  personalTrainer: PersonalTrainer;
  level: Level;
  training: Training;
  alumnosDatosBancario: DatosBancario = {
    claveBancaria: "",
    entidad: ""
  };
  allAchievements: AllAchievements = {
    route: null,
    pista: null,
    ultra: null,
    trail: null
  };
  antecedente: Antecedente[];
  antecedentesMarcas: AntecedentesMarcas[] = [];
  datosTraining: EntrenamientoModelBroken = {
    grupo: "",
    entrenamientoTipo: "",
    idGrupo: -1
  };
  
  entrenadorFormacion: Formacion[] = [];

  objetives: FiltratedObjetives[] = [];

  usuarioId: number = 0;

  motivoBaja: MotivoBaja;

  private sharedData$: Subscription;

  ultimoDiaDisponible: Date = null;

  // ticket 2752
  pasosHabilitados: PasosHabilitados;
  // ticket 2743
  tabIndex: number = 0;
  showCertificateAlert: boolean = false;

  constructor(
    private userService: UserService,
    private snack: SnackbarService,
    private mobileDetectorService: MobileDetectorService,
    private router: ActivatedRoute,
    private registerService: RegisterService,
    private sharedData: SharedDataService,
    private routerRedirect: Router,
    private trainersService: TrainersService,
    private roleService: RolService,
    private hoursDayFixed: HoursDayFixedService,
    private parametrosService: ParametrosService,
    private alertTriggerService: AlertTriggerService
  ) { }

  ngOnInit(): void {
    this.isTrainer = this.roleService.isTrainer();
    const url = this.router.snapshot.routeConfig.path;
    if(url == "alumno/metas-objetivos") {
      this.isMetasObjetivos = true;
    }
    this.isMobile = this.mobileDetectorService.isMobile();

    // ticket 2752: llamar primero a getPasosHabilitados y luego a getData
    this.getPasosHabilitados();
    this.sharedData$ = this.sharedData.currentMessage.subscribe(message => {

      if(message =="updateStatus" || message == "reloadWeek2") {
        // this.profile.paisId = this.personalData.pais.id;
        this.getUserProfileData();
      }

      if(message =="updateStatusTrainer") {
        this.getTrainerProfile();
      }
      if( message == "reloadMail") {
        this.profile.email = sessionStorage.getItem('email');
        sessionStorage.removeItem('email');
      }

      if( message == "medicalCertificateAlarm") {
        this.showCertificateAlert = this.alertTriggerService.showMedicalAlertCircle();
      }
    });

    this.check();
  }

  async check() {
    const ultimaSemanaDisponible: string [] = await this.parametrosService.getUltimaSemanaDisponible();
    this.ultimoDiaDisponible = new Date(ultimaSemanaDisponible[6]);
  }

  /**
   * Check role to determine which kind of user profile request to backend
   */
  getData() {
    if(!this.isAdminOrTrainer) {
      if (this.id) {
        this.getUserProfileData();
      } else {
        this.getMyProfileData();
      }
    } else {
      this.getTrainerProfile();
    }
  }

  getTrainerProfile() {
    // intento recuperar el id de entrenador, si no existe redirijo.
    if(isNaN(this.id)) {
      this.id = parseInt(localStorage.getItem('idEntrenador'),10);
      if(isNaN(this.id)) {
        this.isAdmin ? this.routerRedirect.navigateByUrl('admin/entrenadores') : this.routerRedirect.navigateByUrl('entrenador/alumnos');
      } else {
        this.loadTrainerData();
      }
    } else {
      this.loadTrainerData();
    }
  }

  loadTrainerData() {

    this.trainersService.getTrainerById(this.id).subscribe(response => {
      this.trainerProfile = response;
      this.estado = response.estado;
      this.usuarioId = response.usuarioId;
      this.entrenadorId = response.entrenadorId;

      response.entrenadorFormacion.forEach(element => {
        if(element.activo) {
          this.entrenadorFormacion.push(element);
        }
      })

      if(response.rolId == 1) {
        this.entrenadorIsAdmin = true;
      }
      sessionStorage.setItem('idUsuarioEntrenador',response.usuarioId.toString());
      sessionStorage.setItem('estado',this.estado);

      this.personalData = {
        nombre: response.entrenadorNombre,
        apellido: response.entrenadorApellido,
        segundoApellido: response.segundoApellido,
        numeroDocumento: response.numeroDocumento,
        fechaNacimiento: response.fechaNacimiento,
        pais: response.pais,
        residencia: response.residencia,
        documentoTipo: response.documentoTipo,
      };

      this.contactData = {
        email: response.entrenadorEmail,
        numeroCelular: response.numeroCelular,
        prefijoCelular: response.prefijoCelular,
        numeroEmergencia: null,
        prefijoEmergencia: null
      }

      this.alumnosDatosBancario = null;

      if (response.avatarUrl) {
        this.avatarUrl = environment.apiUrl + response.avatarUrl;
      } else {
        this.avatarUrl = null;
      }

      this.loaded = true;


    },
    error => {
      console.error(error);
      this.snack.snackTranslate('snacks.error.generic');
    })
  }

  getUserProfileData() {
    if(isNaN(this.id)) {
      this.routerRedirect.navigateByUrl('admin/alumnos');
    } else {

      this.userService.getProfileUserId(this.id).subscribe(response => {
        this.loadData(response);
        if (response.entrenador.entrenadorNombre == "NO DEFINIDO" || response.entrenamientoNivel.entrenamientoNivelDescripcion == "NO DEFINIDO") {
          this.disableSave = true;
        }
        if (response.entrenador.entrenadorNombre !== "NO DEFINIDO" && response.entrenamientoNivel.entrenamientoNivelDescripcion !== "NO DEFINIDO") {
          this.disableSave = false;
        }
      },
      error => {
        console.error(error);
        this.snack.snackTranslate('snacks.error.generic');
      })
    }
  }

  getMyProfileData() {
    this.id = parseInt(localStorage.getItem('idAlumno'),10);
    if(isNaN(this.id)) {
      this.routerRedirect.navigateByUrl('admin/alumnos');
    } else {

      this.userService.getProfileUserId(this.id).subscribe(response => {
        this.profile = response;
        this.loadData(response);
      },
      error => {
        console.error(error);
        this.snack.snackTranslate('snacks.error.generic');
      })
    }
  }

  /**
   * ticket 2752
   * Read from parametros endpoint steps enabled values, and update to show them to user in profile.
   * Calls getData.
   */
  getPasosHabilitados() {
    const todosHabilitados: PasosHabilitados = {
      informacionPersonal: true,
      grupoEntrenamiento: true,
      antecedentes: true,
      bancario: true,
      marcasObjetivos: true
    };
    this.pasosHabilitados = todosHabilitados;
    
    this.parametrosService.getParametros('Pasos Habilitados').subscribe( response=> {

      const indexPersonal: number = response.findIndex(parametro => parametro.clave == "informacionPersonal");
      const indexGrupo: number = response.findIndex(parametro => parametro.clave == "grupoEntrenamiento");
      const indexAntecedentes: number = response.findIndex(parametro => parametro.clave == "antecedentes");
      const indexBancario: number = response.findIndex(parametro => parametro.clave == "bancario");
      const indexMarcasObjetivos: number = response.findIndex(parametro => parametro.clave == "marcasObjetivos");

      response[indexPersonal].valor == '1' ? this.pasosHabilitados.informacionPersonal = true : this.pasosHabilitados.informacionPersonal = false;
      response[indexGrupo].valor == '1' ? this.pasosHabilitados.grupoEntrenamiento = true : this.pasosHabilitados.grupoEntrenamiento = false;
      response[indexAntecedentes].valor == '1' ? this.pasosHabilitados.antecedentes = true : this.pasosHabilitados.antecedentes = false;
      response[indexBancario].valor == '1' ? this.pasosHabilitados.bancario = true : this.pasosHabilitados.bancario = false;
      response[indexMarcasObjetivos].valor == '1' ? this.pasosHabilitados.marcasObjetivos = true : this.pasosHabilitados.marcasObjetivos = false;

      sessionStorage.setItem('pasosHabilitados',JSON.stringify(this.pasosHabilitados));
      this.getData();

    },
      error => {
        console.error(error);
        this.pasosHabilitados = todosHabilitados;
      });
  }


  /**
   * assing data to objects
   * */
  async loadData(response: UserComplete) {
    
    // ticket 2743
    this.tabIndex = this.alertTriggerService.getTabIndex();
    if(this.isStudent) {
      this.showCertificateAlert = this.alertTriggerService.showMedicalAlertCircle();
      if(this.tabIndex != 0) {
        this.showCertificateAlert = true;
      }
    }
    if(this.isAdmin) {
      this.showCertificateAlert = await this.alertTriggerService.getStudentAlerts(response.usuarioId);
    }


    this.motivoBaja = response.usuario.motivoBaja;
    this.profile = response;
    this.estado = response.estado;
    this.usuarioId = response.usuarioId,
    sessionStorage.setItem('estado',this.estado);

    this.personalData = {
      nombre: response.nombre,
      apellido: response.apellido,
      segundoApellido: response.segundoApellido,
      numeroDocumento: response.numeroDocumento,
      fechaNacimiento: response.fechaNacimiento,
      pais: response.pais,
      residencia: response.residencia,
      documentoTipo: response.documentoTipo,
    };

    this.physiologicalData = {
      altura: response.altura,
      peso: response.peso
    };

    this.contactData = {
      email: response.email,
      numeroCelular: response.numeroCelular,
      numeroEmergencia: response.numeroEmergencia,
      prefijoCelular: response.prefijoCelular,
      prefijoEmergencia: response.prefijoEmergencia,
    }

    let training = {
      entrenamientoTipo: response.entrenamientoTipo,
      grupo: response.grupo
    }

    this.training = {
      level: response.entrenamientoNivel,
      trainer: response.entrenador,
      type: training,
    }

    // requiered because backend model mappers broke...
    this.datosTraining = {
      grupo : response.entrenamientoGrupo.entrenamientoGrupoDescripcion,
      idGrupo: response.entrenamientoGrupo.id,
      entrenamientoTipo : response.entrenamientoTipo.entrenamientoCodigo,
    }

    //backend provide more information than necesary for alumnosDatosBancario
    //ticket 2752 datosBancarios may not exist.
    //ticket 4088 removido pasos habilitados bancario this.pasosHabilitados.bancario &&
    if( response.alumnosDatosBancario[0]) {
      this.alumnosDatosBancario.claveBancaria = response.alumnosDatosBancario[0].claveBancaria;
      this.alumnosDatosBancario.entidad = response.alumnosDatosBancario[0].entidad;
      this.alumnosDatosBancario.alumnoId = response.alumnosDatosBancario[0].alumnoId;
      this.alumnosDatosBancario.arancel = response.alumnosDatosBancario[0].arancel;
      this.alumnosDatosBancario.id = response.alumnosDatosBancario[0].id
    }
    // this can happen if admin disable step4 registration and then enabled later, after students finished the process
    if(!response.alumnosDatosBancario[0]) {
      this.alumnosDatosBancario.claveBancaria = null;
      this.alumnosDatosBancario.entidad = null;
      this.alumnosDatosBancario.alumnoId = null;
      this.alumnosDatosBancario.arancel = null;
      this.alumnosDatosBancario.id = null;
    }

    this.antecedente = response.antecedente;
    if(response.antecedentesMarcas) {
      let aux: AntecedentesMarcas[] = [];
      this.allAchievements.route = [];
      this.allAchievements.pista = [];
      this.allAchievements.ultra = [];
      this.allAchievements.trail = [];
      response.antecedentesMarcas.forEach( marca => {
        if(marca.fechaMarca) {
          marca.fechaMarca = this.hoursDayFixed.getDateFixed(marca.fechaMarca);
        }
      });

      aux =  response.antecedentesMarcas.filter(antecedente => antecedente.antecedentePreguntaDetalleId == AntecedentesEnum.RUTA);
      aux.forEach(achievement => {
        achievement.valid = true;
        if(achievement.activo) {
          this.allAchievements.route.push(achievement);
        }
      });

      aux =  response.antecedentesMarcas.filter(antecedente => antecedente.antecedentePreguntaDetalleId == AntecedentesEnum.PISTA);
      aux.forEach(achievement => {
        achievement.valid = true;
        if(achievement.activo) {
          this.allAchievements.pista.push(achievement);
        }
      });
      
      aux =  response.antecedentesMarcas.filter(antecedente => antecedente.antecedentePreguntaDetalleId == AntecedentesEnum.ULTRA);
      aux.forEach(achievement => {
        achievement.valid = true;
        if(achievement.activo) {
          this.allAchievements.ultra.push(achievement);
        }
      });
      
      aux =  response.antecedentesMarcas.filter(antecedente => antecedente.antecedentePreguntaDetalleId == AntecedentesEnum.TRAIL);
      aux.forEach(achievement => {
        achievement.valid = true;
        if(achievement.activo) {
          this.allAchievements.trail.push(achievement);
        }
      });
    }

    this.objetives = [];

    response.objetivo.sort(function (a, b) {
      var key1 = new Date(a.fechaObjetivo);
      var key2 = new Date(b.fechaObjetivo);
  
      if (key1 < key2) {
          return -1;
      } else if (key1 == key2) {
          return 0;
      } else {
          return 1;
      }
    });
    response.objetivo.forEach( objetivo => {
      
      if(objetivo.fechaObjetivo) {
        objetivo.fechaObjetivo = this.hoursDayFixed.getDateFixed(objetivo.fechaObjetivo);
      }
      //check expired
      if(objetivo.fechaObjetivo) {
        if(new Date(objetivo.fechaObjetivo).setHours(12) < new Date().setHours(11)) {
          objetivo.activo = false;
        }
      }
      //not else due to backend can send objetivo.activo = false
      if(objetivo.activo) {
        this.objetives.push(objetivo);
      }
    });
    
    this.objetives.forEach(objetivo => objetivo.valid = true);
    if (response.avatarUrl) {
      this.avatarUrl = environment.apiUrl + response.avatarUrl;
    } else {
      this.avatarUrl = null;
    }

    if(this.isStudent) {
      const nombreCompleto: string = `${response.nombre} ${response.apellido} ${response.segundoApellido ? response.segundoApellido : ''}`;
      localStorage.setItem("descripcionUsuario",nombreCompleto);
    }

    this.entrenamientoDia = response.entrenamientoDia;
    this.alumnoUsuarioId = response.usuarioId;


    this.loaded = true;
  }

  editPersonal(auxPersonal: Personal) {
    this.profile.nombre = auxPersonal.nombre,
    this.profile.apellido = auxPersonal.apellido,
    this.profile.segundoApellido = auxPersonal.segundoApellido,
    this.profile.numeroDocumento = auxPersonal.numeroDocumento,
    this.profile.fechaNacimiento = auxPersonal.fechaNacimiento,
    this.profile.pais = auxPersonal.pais,
    this.profile.paisId = auxPersonal.pais.id,
    this.profile.residencia = auxPersonal.residencia,
    this.profile.documentoTipo = auxPersonal.documentoTipo;
    this.profile.documentoTipoId = auxPersonal.documentoTipo.id;
    this.updateProfile();
  }

  editPersonalTrainer(aux: any) {
    const trainerPut: TrainerPut = {
      entrenadorNombre:  aux.nombre,
      entrenadorApellido: aux.apellido,
      segundoApellido : aux.segundoApellido,
      entrenadorEmail : aux.email,
      fechaNacimiento : aux.fechaNacimiento,
      documentoTipoId : aux.documentoTipo.id,
      numeroDocumento : aux.numeroDocumento,
      paisId : aux.paisId,
      residencia : aux.residencia,
      prefijoCelular : aux.prefijoCelular,
      numeroCelular : aux.numeroCelular,
      usuarioId: this.usuarioId
    };

    this.trainersService.editTrainer(this.id, trainerPut)
    .subscribe(response => {
        this.getTrainerProfile();
      },
      error => {
        console.error(error);
        this.snack.snackTranslate('snacks.error.generic');
      });
  }

  editPhysiological(auxPhysiological: Physiological) {
    this.profile.peso = auxPhysiological.peso,
    this.profile.altura = auxPhysiological.altura
    this.updateProfile();
  }

  editBank(auxBank: DatosBancario) {
    let bankData: DatosBancario [] = [];
    bankData.push(auxBank)
    this.registerService.editBankData(this.profile.id, bankData)
    .subscribe(response => {
      this.snack.snackTranslate('snacks.sucess.updatingData.generic');
    },
    error => {
        this.sharedData.changeMessage("updateBank");
        console.error(error);
        if(error.error && error.error.message) {
          this.snack.openSnackBar(error.error.message);
        } else {
          this.snack.snackTranslate('snacks.error.generic');
        }
      });
  }

  editContact(auxContact: Contact) {
    this.profile.email = auxContact.email,
    this.profile.numeroCelular = auxContact.numeroCelular,
    this.profile.prefijoCelular = auxContact.prefijoCelular,
    this.profile.numeroEmergencia = auxContact.numeroEmergencia,
    this.profile.prefijoEmergencia = auxContact.prefijoEmergencia
    this.updateProfile();
  }

  updateProfile() {
    this.registerService.editUserData(this.id, this.profile)
    .subscribe(response => {
        this.getData();
      },
      error => {
        console.error(error);
        this.snack.snackTranslate('snacks.error.generic');
      });
  }

  public ngOnDestroy(): void {
    this.sharedData$.unsubscribe();
  }

}
