<div class="dialogContainer450">
    <app-loading *ngIf="waiting" [showText]="false"></app-loading>
    <div class="flexBeetwen">
        <p class="headline5 textLeft" *ngIf="data.itemTipo == 'V' && !edit"> {{'section.dialogs.newVideo'  | translate}} </p>
        <p class="headline5 textLeft" *ngIf="data.itemTipo == 'V' &&  edit"> {{'section.dialogs.editVideo' | translate}} </p>
        
        <p class="headline5 textLeft" *ngIf="data.itemTipo == 'P' && !edit"> {{'section.dialogs.newFile'  | translate}} </p>
        <p class="headline5 textLeft" *ngIf="data.itemTipo == 'P' &&  edit"> {{'section.dialogs.editFile' | translate}} </p>

        <button mat-button class="buttonDialogClose" mat-dialog-close>
            <mat-icon class="clearIcon">close</mat-icon>
        </button>
    </div>
    <form [formGroup]="newForm">
        <mat-form-field appearance="fill" class="inputfullWidth">
            <mat-label> {{ 'section.dialogs.inputVideoName'| translate}} </mat-label>
            <input required matInput type="text" formControlName="itemNombre">      
        </mat-form-field>

        <mat-form-field *ngIf="data.itemTipo == 'V'" appearance="fill" class="inputfullWidth">
            <mat-label> {{ 'section.dialogs.inputVideoPath'| translate}} </mat-label>
            <input required matInput type="text" formControlName="itemUbicacion">      
        </mat-form-field>

        <app-files 
            [archivos]="archivos"
            [showDelete]="true"
            (deleteFilesEvent)="deleteFiles($event)">
        </app-files>

        <div *ngIf="data.itemTipo == 'P'">
            <label for="file-upload">
              <p class="body4 fakeButton"> {{'section.dialogs.attach' | translate}}</p>
            </label>
            <input id="file-upload" type="file" accept="application/pdf" (change)="selectFiles($event)" (click)="nullFiles($event)" />
            <p id="label" class="body4 primaryHint">{{'section.dialogs.label' | translate}}</p>
        </div>
    </form>

    <button  *ngIf="data.itemTipo != 'P'"
        mat-raised-button color="accent" 
        [disabled]="!newForm.valid" 
        (click)="onSubmit()" id="submit" class="primaryButton"> 
        {{'section.dialogs.confirmButton' | translate}}
    </button>

    <button  *ngIf="data.itemTipo == 'P'"
    mat-raised-button color="accent" 
    [disabled]="!newForm.valid || archivos.length == 0" 
    (click)="onSubmit()" id="submit" class="primaryButton"> 
    {{'section.dialogs.confirmButton' | translate}}
</button>
</div>
