import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LogoutService } from '../../services/logout/logout.service';

@Injectable()
export class ExpiredInterceptor implements HttpInterceptor {

  constructor(
    private logOut: LogoutService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(err => {
      if ([401].indexOf(err.status) !== -1 && this.endpointsNotAllowed(request)) {
        this.logOut.expirado();
      }
      return throwError(err)
    }));
  }

   /**
   * Prevent logOut.expirado to be executed if request url is inside of this function.
   * @example
   * With method and url
   * ```
   * (request.url.toString().includes('blob') && request.method == 'GET');
   * ```
   * @example
   * Only url
   * ```
   * (request.url.toString().includes('redmine'));
   * ```
   * @param request - request data from interceptor
   * @returns Boolean, current endpoint is allowed or not. False prevent logOut.expirado
   */
    endpointsNotAllowed(request: HttpRequest<unknown>): boolean {
      const paletaColores: boolean = (request.url.toString().includes('Colores') && request.method == 'GET');
      const perfil: boolean = (request.url.toString().includes('perfil') && request.method == 'GET');
      const endpointsAllowed: boolean = !paletaColores && !perfil;
      return endpointsAllowed;
    }
  
}
