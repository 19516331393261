<form [formGroup]="bankForm">
<p *ngIf="isMobile" class="headline6Light"> {{posicion}}/{{cantidadPasosHabilitados}} {{ 'registration.stepFour.title' | translate}}</p>
<mat-card class="cardCenter" color="primary">
    <p class="subtitle3">{{'registration.stepFour.title'|translate}}</p>
        <mat-form-field appearance="fill" class="input80">
            <mat-label>{{ 'forms.bank.entityInput' | translate}}</mat-label>
            <input required id="entityInput" formControlName="entidad" type="text" matInput>
        </mat-form-field>

        <mat-form-field appearance="fill" class="input80">
            <mat-label>{{ 'forms.bank.codeInput' | translate}}</mat-label>
            <input  placeholder="{{ 'forms.bank.codePlaceholder' | translate}}" maxlength = "24" id="codeInput" formControlName="claveBancaria" type="text" matInput required>
            <mat-hint align="start"><strong> {{ 'forms.bank.hint1' | translate}} </strong> </mat-hint>
            <mat-hint align="end">{{bankForm.value.claveBancaria?.length || 0}} / 24</mat-hint>
        </mat-form-field> 
    </mat-card>
</form>

<div *ngIf="!isMobile" class="cardCenter3">
    <div class="buttonInsideCard">
        <app-buttons 
            [enablePrevious]="true"
            [bank]="bankForm.value" 
            [disabled]="!bankForm.valid">
        </app-buttons>
    </div>
</div>

<app-buttons 
    *ngIf="isMobile" 
    [enablePrevious]="true" 
    [bank]="bankForm.value" 
    [disabled]="!bankForm.valid">
</app-buttons>
