import { Component, OnInit, Input } from '@angular/core';
import { ImageService } from 'src/app/api/services/image/image.service';
import { RolService } from 'src/app/services/rol/rol.service';

/**
 * Display user avatar
 * @param usuarioId -  number, user id to fetch avatar img
 * @param class - string. Css class to show
 * @param containerClass - string. Css class for containing div, optional.
 * @param uploadAvailable - boolean. Allow user to change avatarImg.
 * @param showCameraIcon - boolean
 * 
 * @example
 * ```
 *    <app-avatar
 *        *ngIf="usuarioId"
 *        [class]="'imgRoundedTable'"
 *        [containerClass]="'imgOptionMessage'"
 *        [usuarioId]="usuarioId">
 *    </app-avatar>
 * ```
 * 
 * @example allow user to edit avatar and show camera icon
 * ```
 *  <app-avatar
 *    *ngIf="usuarioId"
 *    [class]="'imgRounded'"
 *    [containerClass]="'uploadDivProfile'"
 *    [usuarioId]="usuarioId"
 *    [uploadAvailable]="true"
 *    [showCamerIcon]="true">
 *  </app-avatar>
 * ```
 */
@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {

  @Input('usuarioId') usuarioId: number;
  @Input('class') class: string;
  @Input('containerClass') containerClass?: string;
  @Input('uploadAvailable') uploadAvailable: boolean;
  @Input('showCameraIcon') showCameraIcon: boolean;

  canChangeAvatar: boolean = false;
  isStudent: boolean = false;
  isTrainer: boolean = false;
  loadedAvatar: boolean = false;
  isAdmin: boolean = false;

  loadingSkeleton: string = "";
  avatarUrl: string = null;

  constructor(
    private imageService: ImageService,
    private rolService: RolService
  ) { }

  ngOnInit(): void {
    this.loadingSkeleton = this.class + " skeleton";
    this.setChange();
  }

  nullFiles(event) {
    event.target.value = null;
  }


  setChange() {
    this.isAdmin = this.rolService.isAdmin();
    this.isStudent = this.rolService.isStudent();
    this.isTrainer = this.rolService.isTrainer();
    const adminChangeHisOwnAvatar : boolean = this.isAdmin && (localStorage.getItem('idUsuario') ==  sessionStorage.getItem('idUsuarioEntrenador'));
    const trainerChangeHisOwnAvatar : boolean = this.isTrainer && (parseInt(localStorage.getItem('idUsuario'),10) ==  this.usuarioId );
    this.canChangeAvatar = this.isStudent || adminChangeHisOwnAvatar || trainerChangeHisOwnAvatar;
    this.getAvatar();
  }

  async getAvatar() {
    try {
      this.avatarUrl = await this.imageService.getAvatarUrl(this.usuarioId);
      this.loadedAvatar = true;
    } catch {
      this.avatarUrl = null;
      this.loadedAvatar = true;
    }
  }

  async selectFiles(event) {
    
    if(this.canChangeAvatar) {
      try {
        await this.imageService.uploadFile(event);
        this.getAvatar();
      } catch {
        this.avatarUrl = null;
      }
    }
  }

}
