import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from "@angular/material/icon";
import { CheckImageService } from '../../../services/checkImage/check-image.service';
@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {


  constructor(
    private router: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private checkImage: CheckImageService
  ) {
    this.matIconRegistry.addSvgIcon(
      `welcome_icon`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../assets/welcome_icon.svg')
    );
  }

  ngOnInit(): void {
    this.checkImage.setImageCssVar('welcome.jpg','bodyBackgroundImagen','../../../../');
  }

  navigate() {
    this.router.navigate(['/registration/']);
  }
}
