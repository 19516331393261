<div class="table-container" *ngIf="planData.length > 0">
    <table *ngIf="!isMobile" mat-table [dataSource]="planData">

        <ng-container matColumnDef="level">
            <th mat-header-cell *matHeaderCellDef> {{'planes.table.level' | translate}} </th>
            <td mat-cell *matCellDef="let plan">
              <app-level [level]="plan.nivelDescripcion"></app-level>
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef id="a"> {{'planes.table.status' | translate}} </th>
            <td mat-cell *matCellDef="let plan">
              <app-status [showComplete]="true" [status]="plan.estado"></app-status>
            </td>
          </ng-container>
  
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef> {{'planes.table.date' | translate}} </th>
        <td mat-cell *matCellDef="let plan">
          <span class="body3Ligth">
            {{plan.fechaCreacion | date:'dd/MM/yyy hh:mm'}}
          </span>
        </td>
      </ng-container>

      <ng-container matColumnDef="created">
        <th mat-header-cell *matHeaderCellDef> {{'planes.table.created' | translate}} </th>
        <td mat-cell *matCellDef="let plan">
          <span class="body3Ligth">
            {{plan.creadoPor}}
          </span>
        </td>
      </ng-container>
  
  
      <ng-container matColumnDef="actions" stickyEnd>
        <th mat-header-cell *matHeaderCellDef> {{'planes.table.actions' | translate}}</th>
        <td mat-cell *matCellDef="let plan">
          <div class="flexActions">
            <button mat-icon-button (click)="navigate(plan)">
              <mat-icon class="azul">edit</mat-icon>
            </button>
            <button *ngIf="!current" mat-icon-button (click)="deletePlan(plan.planId)">
              <mat-icon svgIcon="delete" id="deleteButton" class="delete"></mat-icon>
            </button>
          </div>
  
        </td>
      </ng-container>
  
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  
    <div *ngIf="isMobile">
      <div class="mobilePlan" *ngFor="let plan of planData">
     
        <div class="flexBeetwen">
          <app-level [level]="plan.nivelDescripcion"></app-level>
          <div class="marginLeftOnly">
            <app-status [showComplete]="true" [status]="plan.estado"></app-status>
          </div>
          <div class="flexActions">
            <button mat-icon-button (click)="navigate(plan)" class="mediumIcon">
              <mat-icon class="mediumIcon">edit</mat-icon>
            </button>
            <button *ngIf="!current" mat-icon-button (click)="deletePlan(plan.planId)" class="mediumIcon">
              <mat-icon svgIcon="delete" id="deleteButton" class="mediumIcon"></mat-icon>
            </button>
          </div>
        </div>

        <div class="flexLeft">
          <span class="body3Ligth">
            {{plan.fechaCreacion | date:'dd/MM/yyy hh:mm'}} - {{plan.creadoPor}}
          </span>
        </div>
    </div>
  </div>

</div>
