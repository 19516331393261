import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { Country, TrainingDays, StudentFromAdmin, Distancias, DatosBancario, Documento, PasosHabilitados, FiltratedObjetives, AntecedentesMarcas, AchievementDynamic } from '../../interfaces/register';
import { TType } from '../../interfaces/user';



@Injectable({
  providedIn: 'root'
})
export class RegisterService {

  private url = environment.apiUrl + '/';
  // httpOptions = {
  //   headers: new HttpHeaders({
  //     'Content-Type': 'application/json'
  //   })
  // };

  httpOptionsJson = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }),
    withCredentials: true,
  };

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json'
    }),
    withCredentials: true,
  };
  multipartOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'multipart/form-data'
    })
  };
  constructor(private http: HttpClient) { }

  registerUser(data, id: number): Observable<any> {
    return this.http.put<any>(this.url + `api/alumnos/?alumnoId=${id}`, data, this.httpOptionsJson);
  }

  getCountry(): Observable<Country[]> {
    return this.http.get<Country[]>(this.url + 'api/paises', this.httpOptions);
  }

  getTrainingDays(): Observable<TrainingDays[]> {
    return this.http.get<TrainingDays[]>(this.url + 'api/entrenamientogrupos', this.httpOptions);
  }

  getTrainingTypes(): Observable<TType[]> {
    return this.http.get<TType[]>(this.url + 'api/entrenamientotipos', this.httpOptions);
  }

  getRouteOptions(): Observable<Distancias[]> {
    return this.http.get<Distancias[]>(this.url + 'api/distancias', this.httpOptions);
  }

  getUserData(): Observable<StudentFromAdmin> {
    return this.http.get<StudentFromAdmin>(this.url + 'getuserdata', this.httpOptions);
  }

  editBankData(alumnoId: number, bankData: DatosBancario[]): Observable<StudentFromAdmin> {
    return this.http.put<StudentFromAdmin>(this.url + `api/alumnos/datosbancarios?alumnoId=${alumnoId}`, bankData, this.httpOptionsJson);
  }

  editUserData(alumnoId: number, datos):  Observable<StudentFromAdmin> {
    return this.http.put<StudentFromAdmin>(this.url + `api/alumnos?alumnoId=${alumnoId}`, datos, this.httpOptionsJson);
  }
  
  editObjetives(alumnoId: number, datos):  Observable<number> {
    return this.http.put<number>(this.url + `api/alumnos/objetivos?alumnoId=${alumnoId}`, datos, this.httpOptionsJson);
  }

  editAchievements(alumnoId: number, datos: AchievementDynamic[]):  Observable<number> {
    return this.http.put<number>(this.url + `api/alumnos/marcas?alumnoId=${alumnoId}`, datos, this.httpOptionsJson);
  }
  
  getTiposDocumento(): Observable<Documento[]> { 
    return this.http.get<Documento[]>(this.url + 'api/tiposdocumento', this.httpOptions);
  }

  editOneObjetive(objetivo: FiltratedObjetives, objetivoId: number): Observable<any> {
    return this.http.put<any>(this.url + `api/alumnos/objetivos?objetivoId=${objetivoId}`, objetivo, this.httpOptionsJson);
  }

  createObjetive(objetive: FiltratedObjetives, alumnoId: number): Observable<any> {
    let body = {
      algo: "algo"
    }
    return this.http.post<any>(this.url + `api/alumnos/objetivos?alumnoId=${alumnoId}&fecha=${objetive.fechaObjetivo}&descripcion=${objetive.objetivoDescripcion}`, body, this.httpOptionsJson);
  }
  
  getPasosHabilitados(): Observable<PasosHabilitados> {
    return this.http.get<PasosHabilitados>(this.url + 'pasosHabilitados', this.httpOptions);
  }

}
