<div *ngIf="objetivesForEdit">
    <div *ngFor="let objetive of objetivesForEdit; let i = index">
        <app-inputs 
            [objetive]="objetive" 
            [i]="i" 
            [alumnoId]="alumnoId"
            (removeObjetiveEvent)="removeObjetiveFromEdit(i)" 
            (saveObjetiveEvent)="saveObjetiveFromInputComponent($event)">
        </app-inputs>
    </div>
</div>

<div *ngFor="let objetive of objetives; let i = index">
    <app-inputs
        [i]="i" 
        [alumnoId]="alumnoId"
        (removeObjetiveEvent)="removeObjetive(i)" 
        (saveObjetiveEvent)="saveObjetiveFromInputComponent($event)">
    </app-inputs>
</div>
 
<button mat-button color="primary" 
    (keydown.enter)="$event.preventDefault()" 
    [disabled]="disable"
    (click)="addObjetive()" 
    id="addButton">
    {{ 'registration.stepThree.objetives.add' | translate}}
</button>