import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { PlansService } from 'src/app/api/services/plans/plans.service';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';


/**
 * Display a warning text and request user's confirmation before process plans.
 * After user's confirmation display a spinner and waiting text.
 */
@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sharedData: SharedDataService,
    private plansService: PlansService,
  ) { }

  loading: boolean = false;
  done: boolean = false;
  error: boolean = false;

  ngOnInit(): void {
  }

  onSubmit() {
    this.loading = true;
    if(!this.data.habilitar) {
      this.plansService.habilitar(this.data.diaSemanaActual).subscribe(response => {
        this.sharedData.changeMessage("reloadStudentData");
        this.loading = false;
        this.done = true;
        
      },
      error => {
        console.error(error);
        this.error = true;
        this.loading = false;
      })
    } else {
      this.plansService.heredar(this.data.diaSemanaActual).subscribe(response => {
        this.sharedData.changeMessage("reloadStudentData");
        this.loading = false;
        this.done = true;
        
      },
      error => {
        console.error(error);
        this.loading = false;
        this.error = true;
      })
    }
  }

}
