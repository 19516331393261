import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IsSameObjectService } from '../../services/isSameObject/is-same-object.service';

@Injectable({
  providedIn: 'root'
})
export class WeekService {

  constructor(
    private isSameObject: IsSameObjectService
  ) { }

  public getWeek(current: Date): string[] {
    var week = new Array();
    //bugfix internacional
    current.setHours(12);

    //bugfix domingo
    if(current.getDay() == 0) {
      current.setDate((current.getDate() - 6))
    } else {
      current.setDate((current.getDate() - current.getDay() +1))
    }

    for (var i = 0; i < 7; i++) {
      week.push(
        new Date(current)
      ); 
      current.setDate(current.getDate() +1);
    }
    return week;
  }

  public convertToday(): string {
    return this.convertDate(new Date().toString());
  }

  public convertDate(dateString: string): string {
    const date = new Date(dateString);
    const day: number = date.getDate();
    const month: number = date.getMonth()+1;
    const year: number = date.getFullYear();
    return day.toString() + '-' + month.toString() + '-' + year.toString();
    // return  year.toString() + '-' + month.toString() + '-' + day.toString() ;
  }

  public isCurrentWeek(fechaFeedback : string): boolean {
    let semanaCompleta: string[] = [];
    let dia = new Date(fechaFeedback);
    
    //correcion para domingos
    if(dia.getDay() != 0) {
      semanaCompleta = this.getWeek(dia);
    } else {
      let aux = new Date(fechaFeedback);
      aux.setDate(aux.getDate() - 1);
      semanaCompleta = this.getWeek(aux);
    }

    const hoy = {
      dia: (new Date()).getDate(),
      mes: (new Date()).getMonth(),
      año: (new Date()).getFullYear(),
    }
    let encontre: boolean = false;
    semanaCompleta.forEach( dia => {
      const diaSemanaCompleta = {
        dia: (new Date(dia)).getDate(),
        mes: (new Date(dia)).getMonth(),
        año: (new Date(dia)).getFullYear(),
      }
      const aux: boolean =this.isSameObject.isSame(diaSemanaCompleta,hoy);
      if(aux) {
        encontre = aux;
      }
    });
    return encontre;
  }
}
