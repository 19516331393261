import { Component, OnChanges, OnInit, SimpleChanges, Input } from '@angular/core';
import { of, Subscription } from 'rxjs';
import { RamaPrincipal, TreeElement } from 'src/app/api/interfaces/support';
import { SupportService } from '../../../../../api/services/support/support.service';
import { SnackbarService } from 'src/app/utils/snackbar/snackbar.service';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';

/**
 * Receive support elements from backend, allow user to navigate, search and choose whether element was usefull or not using SupportTreeUsefullComponent.
 * Elements are show using SupportTreeIterableElementsComponent.
 * 
 * Autocomplete docs: https://material.angular.io/components/autocomplete/overview
 */
@Component({
  selector: 'app-support-tree-main',
  templateUrl: './support-tree-main.component.html',
  styleUrls: ['./support-tree-main.component.scss']
})
export class SupportTreeMainComponent implements OnInit, OnChanges {

  @Input('backHome') backHome: number;

  loading: boolean = true;

  /**
   * Contains all the support elements from backend, no matter user's search
   */
  elementsPrincipales: RamaPrincipal[] = [];

  /**
   * Contains user searched support elements after user selected this or received fromn backend.
   * This will be send to SupportTreeIterableElementsComponent
   */
  elementsSearchPrincipales: RamaPrincipal[] = [];

  /**
   * Contains user searched support elements.
   * This will not be send to SupportTreeIterableElementsComponent
   */
  elementsSearchAuxs: RamaPrincipal[] = [];
  filteredElementsPrincipales: Observable<RamaPrincipal[]>;

  searchControl = new FormControl();

  /**
   * Choosed element selected by user
   */
  choosedElementPrincipal: TreeElement;

  constructor(
    private supportService: SupportService,
    private snack: SnackbarService,
    private sharedData: SharedDataService
  ) { }

  ngOnInit(): void {
    this.getRamasPrincipales();
    this.filteredElementsPrincipales = this.searchControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value)),
    );
  }

  /**
   * Filter elementsPrincipales according user's search. If no search, return all elements.
   * These changes are not listening by ngOnChanges on SupportTreeIterableElementsComponent.
   * @param value - string, user's search
   * @returns all the elements that match with user search
   */
  private _filter(value: string): RamaPrincipal[] {
    const filterValue = value.toLowerCase();
    const opcionesFiltradas: RamaPrincipal[] = this.elementsPrincipales.filter(option => option.titulo.toLowerCase().includes(filterValue) );
    this.elementsSearchAuxs = opcionesFiltradas;
    return opcionesFiltradas;
  }


  /**
   * Set choosedElementPrincipal and elementsSearchPrincipales values. Create changes that are listening by ngOnChanges on SupportTreeIterableElementsComponent.
   * @param element - user's choose.
   * @param event used to verify if its user input (desired event) or the input has updated after changed.
   */
  toggleElement(element: TreeElement,event) {
    if(event.isUserInput) {
      this.choosedElementPrincipal = element;
      this.elementsSearchPrincipales = this.elementsSearchAuxs;
    }
  }

  /**
   * Request data to backend
   */
  getRamasPrincipales() {
    this.loading = true;
    this.supportService.getRamasPrincipales()
    .subscribe(response => {
      this.elementsPrincipales = response;
      this.elementsSearchPrincipales = response;
      this.loading = false;
      this.choosedElementPrincipal = null;
    },
      error => {
        console.error(error);
        this.snack.openSnackBar(error.error.message);
        this.loading = false;
      });
  }

  /**
   * Remove search value
   */
  clear() {
    this.searchControl.setValue('');
  }

  /**
   * Trigger getRamasPrincipales after user clicked in home button
   * backHome value is bigger than 0 if user clicked in home button
   * @param changes 
   */
  ngOnChanges(changes: SimpleChanges) {
    if(changes.backHome.currentValue > 0) {
      this.getRamasPrincipales();
    }
  }

}
