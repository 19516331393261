<app-header [showLogout]="false"></app-header>

<div class="terminos-condiciones">
    <p class="headline5">Términos y Condiciones de Uso  </p>
    
    <p class="body3">
        Condiciones Generales de Uso: se establecen las condiciones bajo las cuales los usuarios ingresarán y harán uso de la aplicación iuTrein.  
        <br/><br/>
        Este documento es utilizado para regular las condiciones y reglas a las que se someten tanto los usuarios de nuestra de la aplicación iuTein como su administrador, en lo referente al acceso y utilización de la aplicación Trein. De igual manera, hace referencia a las políticas de privacidad, protección de datos personales, enlaces, y otros, que se tomarán para proteger la privacidad de los usuarios.  
    </p>
    
    <p class="subtitle3">Términos</p>
    
    <p class="body3">
        Al acceder al sitio web www.iutrein.com usted acepta estar vinculado por estos términos de servicio, todas las leyes y regulaciones aplicables, y acepta que usted es responsable del cumplimiento de las leyes locales aplicables. Si no está de acuerdo con alguno de estos términos, se le prohíbe utilizar o acceder a este sitio. Los materiales contenidos en este sitio web están protegidos por las leyes de derechos de autor y marcas aplicables.  
        <br/><br/>
        Es requisito necesario para el uso de los productos que se ofrecen en este sitio, que lea y acepte los siguientes Términos y Condiciones que a continuación se presentan. El uso de nuestros servicios implica que usted ha leído y aceptado los Términos y Condiciones de Uso en el presente documento.  
    </p>
    
    <p class="subtitle3">Licencia de Uso</p>
    
    <p class="body3">
        iuTrein a través de su sitio web concede una licencia para que los usuarios utilicen el software de acuerdo con los Términos y Condiciones que se describen en este documento.  
        <br/><br/>
        Esta es la concesión de una licencia, no una transferencia de título, y bajo esta licencia usted no puede:  
        <br/><br/>
        Modificar o copiar los materiales; utilizar los materiales para cualquier propósito comercial, o para cualquier exhibición pública (comercial o no comercial); intentar descompilar o realizar ingeniería inversa de cualquier software contenido en el sitio web de iuTrein; eliminar cualquier Copyright u otras anotaciones propietarias de los materiales; o  
        transferir los materiales a otra persona o «reflejar» los materiales en cualquier otro servidor.  
        <br/><br/>
        Esta licencia se terminará automáticamente si usted viola cualquiera de estas restricciones y puede ser terminada por iuTrein en cualquier momento. Al terminar su visualización de estos materiales o al finalizar esta licencia, debe destruir cualquier material descargado en su posesión ya sea en formato electrónico o impreso.  
    </p>

    <p class="subtitle3">Responsabilidad</p>

    <p class="body3">Los materiales del sitio web de iuTrein se proporcionan «tal cual». iuTrein otorga ninguna garantía, expresa o implícita, y por la presente renuncia y niega todas las demás garantías, incluyendo, sin limitación, garantías implícitas o condiciones de comerciabilidad, idoneidad para un propósito en particular, o no infracción de propiedad intelectual u otra violación de derechos.  
        <br/><br/>
        Además, iuTrein no garantiza ni hace ninguna representación con respecto a la exactitud, los resultados probables, o la confiabilidad del uso de los materiales en su sitio web o de otra manera relacionado con tales materiales o en cualquier sitio vinculado a este lugar.  
    </p>

    <p class="subtitle3">Limitaciones</p>

    <p class="body3">
        En ningún caso iuTrein o sus proveedores serán responsables de ningún daño (incluyendo, sin limitación, daños por pérdida de datos o beneficio, o debido a la interrupción del negocio) que surjan del uso o la incapacidad de utilizar los materiales en el sitio web de iuTrein, incluso si iuTrein o un representante autorizado de iuTrein ha sido notificado verbalmente o por escrito de la posibilidad de tal daño.  
    </p>

    <p class="subtitle3">Exactitud de Materiales</p>  

    <p class="body3">
        Los materiales que aparecen en el sitio web de iuTrein pueden incluir errores técnicos, tipográficos o fotográficos. iuTrein no garantiza que cualquiera de los materiales en su sitio web son exactos, completos o actuales. iuTrein puede realizar cambios en los materiales contenidos en su sitio web en cualquier momento sin previo aviso. Sin embargo, iuTrein no se compromete a actualizar los materiales. 
    </p>

    <p class="subtitle3">Enlaces</p>  
    
    <p class="body3">
        iuTrein ha revisado todos los sitios vinculados a su sitio web y no es responsable de los contenidos de dichos sitios vinculados. iuTrein puede agregar vínculo (s) o texto al código incrustado. La inclusión de cualquier enlace no implica la aprobación por iuTrein del sitio. El uso de cualquier sitio web vinculado es bajo el propio riesgo del usuario. 
    </p>
    
    <p class="subtitle3">Propiedad</p>  
    
    <p class="body3">
        Usted no puede declarar propiedad intelectual o exclusiva a ninguno de nuestros productos, modificado o sin modificar. Todos los productos iuTrein son propiedad de 3MC S.A. En caso que no se especifique lo contrario, nuestros productos se proporcionan sin ningún tipo de garantía, expresa o implícita. En ningún caso esta compañía será responsable de ningún daño incluyendo, pero no limitado a, daños directos, indirectos, especiales, fortuitos o consecuentes u otras pérdidas resultantes del uso o de la imposibilidad de utilizar nuestros productos. 
    </p>

    <p class="subtitle3">Política de Garantía </p> 

    <p class="body3">
        La garantía por el uso de la plataforma iuTrein cubrirá fallas técnicas exclusivamente y sólo se hará efectiva cuando el producto haya sido utilizado correctamente. Los términos de la garantía están asociados a posibles fallas de funcionamiento en condiciones normales de disponibilidad del software y sólo se harán efectivos estos términos si ha sido utilizado correctamente. Esto incluye el uso de acuerdo a las especificaciones técnicas indicadas, y en uso específico para la función para la cual fue construido. 
    </p>
    <p class="subtitle3">Privacidad</p>  

    <p class="body3">
        Esta licencia de software garantiza que la información personal que usted envía cuenta con la seguridad necesaria. Los datos ingresados por cada usuario no serán entregados a terceros, y podrán ser utilizados por iuTrein, 3MC S.A., y sus empresas relacionadas, para acciones de marketing. La suscripción a boletines de correos electrónicos publicitarios es voluntaria y podría ser seleccionada al momento de crear su cuenta.  
        <br/><br/> 
        En la Argentina los datos personales están especialmente protegidos por la ley Ley de Protección de los Datos Personales Nro 25.326.  
        <br/><br/>
        Al utilizar el sitio www.iutrein.com , se puede recopilar lo siguiente: la URL de los enlaces a través de los cuales el usuario accedió al sitio www.iutrein.com , el proveedor de acceso del usuario, la dirección de Protocolo de Internet (IP) del usuario. En cualquier caso, iuTrein únicamente recopila información personal relativa al usuario para las necesidades de determinados servicios ofrecidos por el sitio www.iutrein.com . El usuario proporciona esta información con pleno conocimiento de causa, en particular cuando la introduce él mismo. A continuación, se especifica al usuario del sitio www.iutrein.com la obligación o no de proporcionar esta información.  
        <br/><br/>
        De conformidad con lo dispuesto en el artículo 43 y siguientes de la ley 25.326, relativa al tratamiento de datos, archivos y su finalidad, todo usuario tiene derecho de acceso, rectificación y oposición a los datos personales que le conciernan, realizando su solicitud escrita y firmada, acompañada de copia del documento de identidad con firma del titular del documento, especificando la dirección a la que debe enviarse la respuesta. 
        <br/><br/>
        Ninguna información personal del usuario del sitio www.iutrein.com es publicada sin el conocimiento del usuario, intercambiada, transferida, cedida o vendida por cualquier medio a terceros. Únicamente el supuesto de redención de iuTrein y sus derechos permitiría la transmisión de dicha información al posible comprador que a su vez estaría sujeto a la misma obligación de almacenar y modificar datos con respecto al usuario del sitio web www.iutrein.com . 
    </p>

    <p class="subtitle3">Ley aplicable y atribución de jurisdicción  </p>
    
    <p class="body3">
        Cualquier disputa relacionada con el uso del sitio www.iutrein.com está sujeta a la ley de la República Argentina. Se otorga jurisdicción exclusiva a los tribunales competentes de la Ciudad Autónoma de Buenos Aires. 
    </p>
    <p class="subtitle3">Modificaciones</p>
    
    <p class="body3">
        iuTrein puede revisar estos términos de servicio para su sitio web en cualquier momento sin previo aviso. Al utilizar este sitio web, usted acepta estar vinculado por la versión actual de estos términos de servicio.  
        <br/><br/>
        iuTrein se reserva los derechos de cambiar o de modificar <b>estos términos sin previo aviso.</b>
    </p>
        
</div>