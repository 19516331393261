import { Component, OnInit, Input } from '@angular/core';
import { Group } from '../../../../../api/interfaces/groups';
import { GroupsService } from '../../../../../api/services/groups/groups.service';
import { SnackbarService } from 'src/app/utils/snackbar/snackbar.service';
import { MobileDetectorService } from 'src/app/services/mobileDetector/mobile-detector.service';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';
import { FlagsGroups } from 'src/app/enums/sharedDataFlag';

/**
 * Show one group's data
 * @param Group, 0: id number, 1: descripcion string, 2: activo boolean, 3: capacidad number, 4: horario string ,5: cantalumnos number
 */
@Component({
  selector: 'app-groups-item',
  templateUrl: './groups-item.component.html',
  styleUrls: ['./groups-item.component.scss']
})
export class GroupsItemComponent implements OnInit {

  @Input('edit') edit: boolean;
  @Input('showNew') showNew: boolean;

  // @Input('group') group: Group;
  @Input('group') group: any;
  isMobile: boolean = false;

  isChecked = true;
  constructor(
    private groupsService: GroupsService,
    private snack: SnackbarService,
    private mobileDetectorService: MobileDetectorService,
    private sharedData: SharedDataService
  ) { }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    // this.isChecked = this.group.activo;
    this.isChecked = this.group[2];
  }

  toggleSlide() {
    this.groupsService.activarDesactivar(this.group[0], !this.group[2]).subscribe( response => {
      this.group[2]= !this.group[2];
    },
    error => {
      console.error(error);
      this.snack.openSnackBar(error.error.message);
      this.group[2] = this.group[2];
    });
  }

  toggleEdit() {
    sessionStorage.setItem('groupId',this.group[0]);
    this.sharedData.changeMessage(FlagsGroups.editGroup);
  }

}
