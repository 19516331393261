import { Injectable } from '@angular/core';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { SnackbarService } from '../../../utils/snackbar/snackbar.service';
import { Formacion, FormacionEdicion, ListadoTrainersPageable, NewTrainer, SuspensionTrainer, Trainer, TrainerPut } from '../../interfaces/trainers';
import { Filters } from '../../interfaces/user';
import { InsertUser } from '../../interfaces/register';
import { Role } from '../../../enums/role';

@Injectable({
  providedIn: 'root'
})
export class TrainersService {

  private url = environment.apiUrl + '/';
  httpOptionsJson = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }),
    withCredentials: true,
  };

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'Accept': 'application/json'
    }),
    withCredentials: true,
  };
  constructor(
    private http: HttpClient,
    private snack: SnackbarService
  ) { }

  createTrainer(trainer: Trainer): Observable<any> {
    return this.http.post<any>(this.url + 'api/entrenadores', trainer, this.httpOptionsJson);
  }

  editTrainer(id: number, trainer: TrainerPut): Observable<any> {
    return this.http.put<any>(this.url + `api/entrenadores?entrenadorId=${id}`, trainer, this.httpOptionsJson);
  }

  getTrainersWithPaginator(cantidadPorPagina:number, pagina: number, nombreApellido: string): Observable<ListadoTrainersPageable> {
    return this.http.get<ListadoTrainersPageable>(this.url + `api/entrenadores/listado?ape=${nombreApellido}&pag=${pagina}&reg=${cantidadPorPagina}`,  this.httpOptions);
  }

  getTrainerById(id: number): Observable<Trainer> {
    return this.http.get<Trainer>(this.url + `api/entrenadores?entrenadorId=${id}`,  this.httpOptions);
  }

  searchTrainer(apellido: string): Observable<Trainer[]> { 
    return this.http.get<Trainer[]>(this.url + `api/entrenadores/sorted?apellido=${apellido}`, this.httpOptions);
  }

  createFormacion(formacion: FormacionEdicion, entrenadorId: number): Observable<any> {
    let body = {
      algo: "algo"
    }
    return this.http.post<any>(this.url + `api/entrenadores/formacion?entrenadorId=${entrenadorId}&titulo=${formacion.entrenadorFormacionTitulo}&descripcion=${formacion.entrenadorFormacionDescripcion}`, body, this.httpOptionsJson);
  }

  editFormacion(formacion: FormacionEdicion, entrenadorFormacionId: number): Observable<any> {
    return this.http.put<any>(this.url + `api/entrenadores/formacion?entrenadorFormacionId=${entrenadorFormacionId}`, formacion, this.httpOptionsJson);
  }

  historialSuspensionesEntrenador(entrenadorId: number): Observable<SuspensionTrainer[]> { 
    return this.http.get<SuspensionTrainer[]>(this.url + `api/entrenadores/estadoslog?entrenadorId=${entrenadorId}`, this.httpOptions);
  }

  cambiarEstado(entrenadorId: number, estado: string): Observable<any> {
    let body = {
      algo: "algo"
    }
    return this.http.put<any>(this.url + `api/entrenadores/baja?entrenadorId=${entrenadorId}&estado=${estado}`, body, this.httpOptions);
  }

  getCantidadAlumnos(id: number): Observable<number> {
    return this.http.get<number>(this.url + `api/entrenadores/alumnos?entrenadorId=${id}`,  this.httpOptions);
  }

  
  getUsuarioIdFromEntrenadorId(entrenadorId: number): Observable<number> {
    return this.http.get<number>(this.url + `api/entrenadores/obtenerUsuarioId/?entrenadorId=${entrenadorId}`,  this.httpOptions);
  }

  public completeUserData(user: NewTrainer, userId: number): InsertUser {
    let today = new Date().toISOString();
    let newUser: InsertUser = {
      usuario: user.usuario,
      descripcionUsuario: user.descripcionUsuario,
      rolId: Role.TRAINER,
      activo: 1,
      creado: today,
      creadoPor: userId,
      ultimaModificacion: today,
      ultimaModificacionPor: userId
    }
    return newUser;
  }
}
