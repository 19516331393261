<div class="background">

    <app-header></app-header>
    <app-navbar *ngIf="!isMobile"></app-navbar>
    <section>
            <div class="flexLeft" *ngIf="!isMobile">
                <app-settings-card 
                    (toggleOptionEvent)="toggleOption($event)"
                    [showGroups]="showGroups"
                    [showArancel]="showArancel">
                </app-settings-card>
                <app-groups-main *ngIf="showGroups" class="flexGrow"></app-groups-main>
                <app-settings-arancel-card *ngIf="showArancel" class="flexGrow"></app-settings-arancel-card>
            </div>
            <div *ngIf="isMobile">
                <app-settings-card
                    *ngIf="!showGroups && !showArancel"
                    (toggleOptionEvent)="toggleOption($event)">
                </app-settings-card>

                <app-groups-main
                    *ngIf="showGroups"
                    (toggleOptionEvent)="toggleOption($event)"
                    class="flexGrow">
                </app-groups-main>

                <app-settings-arancel-card
                    *ngIf="showArancel"
                    (toggleOptionEvent)="toggleOption($event)"
                    class="flexGrow">
                </app-settings-arancel-card>
            </div>
    </section>
</div>