export enum AntecedentesEnum {
    EXP = 1,
    FREQ = 2,
    SEM = 3,
    EST = 4,
    COMP = 5,
    DYA = 6,
    CALZ = 7,
    MARK = 8,
    RUTA = 20,
    PISTA = 21,
    TRAIL = 22,
    ULTRA = 23
}