import { Injectable } from '@angular/core';
import { RespuestaFormularioDinamicoPregunta } from 'src/app/api/interfaces/formulario-dinamico';
import { Objetive, FiltratedObjetives, Antecedente, FilteredMode, FiltratedUser } from 'src/app/api/interfaces/register';
import { EntrenadorAsignado } from 'src/app/api/interfaces/trainers';
import { AntecedentesEnum } from 'src/app/enums/antecedentes';
import { HoursDayFixedService } from 'src/app/utils/hoursDayFixed/hours-day-fixed.service';

@Injectable({
  providedIn: 'root'
})
export class FilterDataService {

  constructor(
    private hourDayFixed: HoursDayFixedService,
  ) { }

  public filterObjetives(input: Objetive[]): FiltratedObjetives[] {
    let objetivesArray = Array();
    if (input.length > 0 && input[0].name) {
      input.forEach(element => {
        let aux: FiltratedObjetives;
        if (element.date) {

          // bugfix date
          let fechaCorregida : string = this.hourDayFixed.getDateFixed(new Date(element.date).toISOString());

          aux = {
            objetivoDescripcion: element.name,
            fechaObjetivo: fechaCorregida,
            id: element.id ? element.id : null,
          }
        } else {
          aux = {
            objetivoDescripcion: element.name,
            fechaObjetivo: null,
            id: element.id ? element.id : null,
          }
        }
        objetivesArray.push(aux);
      });
      return objetivesArray;
    } else {
      return null;
    }
  }

  public filterObjetive(input: Objetive): FiltratedObjetives {
    if (input) {
      let aux: FiltratedObjetives;
      if (input.date) {

        let fechaCorregida: string = input.date.toString();

        // bugfix date
        if(!input.date.toString().includes('T12') && input.date.toString().includes('GMT')) {
          fechaCorregida = this.hourDayFixed.getDateFixed(input.date.toISOString());
        }

        aux = {
          objetivoDescripcion: input.name,
          id: input.id,
          fechaObjetivo: fechaCorregida
        }
      } else {
        aux = {
          objetivoDescripcion: input.name,
          fechaObjetivo: null,
          id: input.id,
        }
      }
      return aux;
    } else {
      return null;
    }
  }

  public getIdAchievement(mode: string): number {
    let id: number = 0;
    switch (mode) {
      case "pista":
        id = AntecedentesEnum.PISTA;
        break;
      case "ultra":
        id = AntecedentesEnum.ULTRA;
        break;
      case "trail":
        id = AntecedentesEnum.TRAIL;
        break;
      default:
        id = 0;
        break;
    }
    return id;
  }

  /**
   * updated 2670
   * @param input antecendentesRespuesta: RespuestaFormularioDinamicoPregunta[]
   * @returns antecedentes preguntas y respuestas
   */
  public filterBackground(antecendentesRespuesta: RespuestaFormularioDinamicoPregunta[]): Antecedente[] {

    let aux: Antecedente[] = [];
    
    antecendentesRespuesta.forEach(respuesta => {
      const element: Antecedente = {
        antecedentePreguntaId: respuesta.preguntaId,
        antecedenteRespuesta: respuesta.respuesta ? respuesta.respuesta : "No"
      }
      aux.push(element);
    });

    return aux;

  }

  /**
   * Filtra datos del front y los adapta para enviar al backend.
   * Los datos provienen de step1 y step2
   * Datos de entrenamiento depende de pasos habilitados, mediante filteredMode null o con datos.
   * @param data - datos personales, proviene del step1
   * @param filteredMode - datos de grupo de entrenamiento, proviene del step2 y depende de si estaba habilitado. Si deshabilitado será null
   * @param alumno_id - id del alumno completando el registro
   * @returns estructura para enviar al backend
   */
  filterUserData(data: any, filteredMode: FilteredMode, alumno_id: number) {
    let today = new Date().toISOString();
    //ticket 4262 fixed entrenadorId harcodeado
    const entrenadorAsignado: EntrenadorAsignado = JSON.parse(localStorage.getItem('entrenadorAsignado'));

    let aux: FiltratedUser = {
      //datos que siempre estan, por el paso 1
      email: data.email,
      nombre: data.nombre,
      apellido: data.apellido,
      segundoApellido: data.segundoApellido ? data.segundoApellido: "",
      fechaNacimiento: data.fechaNacimiento,
      numeroDocumento: data.DNI,
      paisId: data.pais.id,
      residencia: data.residencia,
      prefijoCelular: data.prefijoCelular,
      numeroCelular: data.numeroCelular,
      prefijoEmergencia: data.prefijoEmergencia,
      numeroEmergencia: data.numeroEmergencia,
      peso: data.peso,
      altura: data.altura,
      documentTipoId: data.documentoTipo.id,
      avatarUrl: null,
      activo: true,
      ultimaModificacion: today,
      ultimaModificacionPor: alumno_id,
      estado: "P",


      //datos que dependen de pasos Habilitados
      entrenamientoTipoId:  filteredMode && filteredMode.id       ? filteredMode.id : null,
      entrenamientoGrupoId: filteredMode && filteredMode.grupoId  ? filteredMode.grupoId : null,
      entrenamientoNivelId: filteredMode ? 1 : null,
      
      entrenadorId: entrenadorAsignado.entrenadorId,
    }
    return aux;
  }

  /**
   * Filtra datos de tipo de entrenamiento para enviar al backend.
   * Los datos provienen de step2.
   * @param mode 
   * @returns 
   */
  public filterDataMode(mode: any) {
    let filteredMode: FilteredMode;
    let dia;
    if(mode.days && mode.days.index) {
      dia = mode.days.index;
    } else {
      dia = null;
    }
    if(!mode.mode) {

      filteredMode = {
        entrenamientoTipo: "PRE",
        grupo: mode.trainingDays.entrenamientoGrupoDescripcion,
        id: 1,  //required from database
        dia: dia,
        grupoId: mode.trainingDays.id
      }
    } else {
      filteredMode = {
        entrenamientoTipo: "ONL",
        grupo: null,
        id: 2,  //required from database
        dia: dia,
        grupoId: 2,  //required from database
      }
    }
    return filteredMode;
  }
}