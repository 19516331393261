<div *ngIf="!uploadAvailable" [class]="containerClass">
    <span *ngIf="loadedAvatar">

        <img 
        *ngIf="!avatarUrl" 
        [class]="class" 
        src="../../../../../assets/user.svg">
        
        <img 
        *ngIf="avatarUrl" 
        [class]="class" 
        [src]="avatarUrl | secure | async">
    </span>
    <app-skeleton *ngIf="!loadedAvatar" [class]="class"></app-skeleton>
</div>

<div *ngIf="uploadAvailable" [class]="containerClass">

    <span *ngIf="loadedAvatar">
        <label *ngIf="!avatarUrl" for="file-upload">
            <div class="upload">
                <mat-icon  *ngIf="!avatarUrl && showCameraIcon" class="camera">photo_camera</mat-icon>
                <img *ngIf="!avatarUrl && !showCameraIcon" [class]="class"  src="../../../../../assets/user.svg">
            </div>
        </label>
        <label *ngIf="avatarUrl" for="file-upload">
            <img [class]="class" [src]="avatarUrl  | secure | async">
            
        </label>
        <input *ngIf="canChangeAvatar" accept="image/*" required id="file-upload" type="file" (change)="selectFiles($event)" (click)="nullFiles($event)"/>
    </span>

    <app-skeleton *ngIf="!loadedAvatar" [class]="class"></app-skeleton>
</div>