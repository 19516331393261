import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AlertTriggerService } from 'src/app/api/services/alertTrigger/alert-trigger.service';
import { RolService } from 'src/app/services/rol/rol.service';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';
import { LogoutService } from '../../../services/logout/logout.service';
import { MobileDetectorService } from '../../../services/mobileDetector/mobile-detector.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Input('showLogout') showLogout?: boolean;
  @Input('registration') registration?: boolean;
  @Input('showLogoEquipo') showLogoEquipo?: boolean;
  descripcionUsuario: string = "";
  isMobile: boolean = false;

  usuarioId: number = 0;
  avatarUrl: string = null;
  userHasImage: boolean = false;
  isAdmin: boolean = false;

  logoEquipo: string = "../../../../assets/equipoId_";

  private sharedData$: Subscription;


  constructor(
    private logoutService: LogoutService,
    private mobileDetectorService: MobileDetectorService,
    private router: Router,
    private rolService: RolService,
    private sharedData: SharedDataService,
    private alertTriggerService: AlertTriggerService
  ) { }

  // ticket 2743
  showCertificateAlert: boolean = false;

  ngOnInit(): void {
    this.isAdmin = this.rolService.isAdmin();
    this.usuarioId = parseInt(localStorage.getItem('idUsuario'),10);
    if(this.showLogout == undefined) {
      this.showLogout = true;
    }
    this.isMobile = this.mobileDetectorService.isMobile();
    this.descripcionUsuario =  localStorage.getItem("descripcionUsuario");
    this.showCertificateAlert = this.alertTriggerService.showMedicalAlertCircle();

    // ticket 2743
    this.sharedData$ = this.sharedData.currentMessage.subscribe(message => {

      if( message == "medicalCertificateAlarm") {
        this.showCertificateAlert = this.alertTriggerService.showMedicalAlertCircle();
      }
    });
    // ticket 3483 parametrizar logo
    if(localStorage.getItem('equipoId')) {
      this.logoEquipo += localStorage.getItem('equipoId').toString() + '/logo.png';
    }
  }

  logout() {
    this.logoutService.logOut();
  }

  navigateProfile() {
    let path: string = 'alumno/mi-perfil';
    let isTrainer: boolean = this.rolService.isTrainer();

    //recupero el id del entrenador desde localStorage para setearlo en sessionStorage donde hace falta
    if(this.isAdmin) {
      sessionStorage.setItem('id_entrenador', localStorage.getItem('idEntrenador'));
    }
    if(isTrainer || this.isAdmin) {
      path = "entrenador/perfil";
    }
    
    this.router.navigate([path]);
  }

  public ngOnDestroy(): void {
    this.sharedData$.unsubscribe();
  }
}
