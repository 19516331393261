import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NivelesEnum } from 'src/app/enums/niveles';
import { MobileDetectorService } from 'src/app/services/mobileDetector/mobile-detector.service';
import { PlanTable } from '../../../api/interfaces/plans';
import { Level } from '../../../api/interfaces/user';
import { PlansService } from '../../../api/services/plans/plans.service';
import { TrainingService } from '../../../api/services/training/training.service';
import { StatusEnum } from '../../../enums/status';
import { SnackbarService } from '../../../utils/snackbar/snackbar.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit {

  emptyPlansCurrent: boolean = false;
  emtpyPlansSchedule: boolean = false;
  paginateRight: boolean = true;
  firstLoad: boolean = true;
  isMobile: boolean = false;
  loaded: boolean = false;

  StatusEnum = StatusEnum;

  totalPerPage: number = 10;
  page: number = 0;

  planDataCurrent: PlanTable[] = [];
  planData: PlanTable[] = [];
  
  levels: Level[] = [];

  semanas: string [] = [];


  constructor(
    private mobileDetectorService: MobileDetectorService,
    private router: Router,
    private snack: SnackbarService,
    private plansService: PlansService,
    public dialog: MatDialog,
    private trainingService: TrainingService
  ) { }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    sessionStorage.setItem('active','planes');
    this.loadLevels();
  }

  loadPlans() {
    this.loaded = false;
    this.emptyPlansCurrent = false;

    this.plansService.getPlans()
      .subscribe(plans => {
        if (plans.length == 0) {
          this.emptyPlansCurrent = true;
          this.loaded = true;
        } else {
          this.firstLoad = false;
          plans.forEach(plan => {
            this.convertAndPushPlan(plan)
          });
          this.loaded = true;
        }
      },
        error => {
          this.snack.snackTranslate('snacks.error.generic')
          console.error(error);
          this.loaded = true;
          this.emptyPlansCurrent = true;
          this.emtpyPlansSchedule = true
        });
  }

  loadLevels() {
    this.trainingService.getLevels().subscribe( response=> {
      this.levels = response;
      let remover: number = this.levels.findIndex(option => option.entrenamientoNivelDescripcion == NivelesEnum.noDefinido);
      if(remover != -1) {
        this.levels.splice(remover, 1);
      }
      this.loadPlans();
    },
    error => {
      console.error(error);
    });
  }

  pushPlan(plan: PlanTable) {
    if (plan != null) {
      this.planData.push(plan);
    }
  }

  convertAndPushPlan(plan) {
    const todayDate = (new Date()).getDate();
    const todayDay = (new Date()).getDay();
    const todayMes = (new Date()).getMonth();
    const resta = todayDate - (todayDay -1)
    const planDia = (new Date(plan.planSemana)).getDate();
    const planMes = (new Date(plan.planSemana)).getMonth();
    let estado: string = "PROGRAMADO";
    
    let condicional: boolean = (resta == planDia) || (todayMes - planMes == 1) || (todayMes - planMes < -8 );
    if(condicional) {
      estado = "EN CURSO";
    }
    let level: Level = this.levels.find(level => level.id == plan.entrenamientoNivelId);
    let aux: PlanTable = {
      nivelDescripcion: level.entrenamientoNivelDescripcion,
      estado: estado,
      fechaCreacion: plan.creado,
      creadoPor: plan.creadoPor,
      planId: plan.planId,
      planSemana: plan.planSemana
    }
    if (aux != null) {
      if(aux.estado == "EN CURSO") {
        this.planDataCurrent.push(aux);
      } else {
        this.planData.push(aux);
        const semanaString = new Date(aux.planSemana).toLocaleDateString();
        if(!this.semanas.some(semana => semana == semanaString)) {
          this.semanas.push(semanaString);
        }
        
      }
    }
  }
  
  createPlan() {
    this.router.navigate(['planes/nuevo']);

  }

  updateAfterDelete() {
    this.loaded = false;
    this.semanas = [];
    this.planData = [];
    this.planDataCurrent = [];
    this.loadPlans();
  }

}
