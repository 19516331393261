import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PlansService } from 'src/app/api/services/plans/plans.service';
import { trainingDaysEnum } from 'src/app/enums/training-type';
import { SharedDataService } from 'src/app/services/sharedData/shared-data.service';
import { SnackbarService } from 'src/app/utils/snackbar/snackbar.service';
import { WeekService } from '../../../utils/week/week.service';
import { FormularioDia, PlanDetalle, PlanDatosDia, PostFeedback, PlanEditarDia, ReloadDay } from '../../../api/interfaces/plans';
import { UserService } from '../../../api/services/user/user.service';
import { DescansoEnum } from '../../../enums/training-type';
import { ParametrosService } from '../../../api/services/parametros/parametros.service';
import { RoleString } from '../../../enums/role';
import { RolService } from 'src/app/services/rol/rol.service';
import { HoursDayFixedService } from 'src/app/utils/hoursDayFixed/hours-day-fixed.service';
import { EnviarMensaje, MetadataAvisoFeedback } from 'src/app/api/interfaces/messages';
import { MessagesService } from 'src/app/api/services/messages/messages.service';
import { MessagesTypes } from 'src/app/enums/messagesTypes';
import { ImageService } from 'src/app/api/services/image/image.service';


@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.scss']
})
export class FeedbackComponent implements OnInit, OnDestroy {


  @Output() notEnabledEvent = new EventEmitter<boolean>();
  @Output() editFormEvent = new EventEmitter<FormularioDia>();

  @Input('ultimoDiaDisponible') ultimoDiaDisponible: Date;
  @Input('canGiveFeedback') canGiveFeedback: boolean;
  @Input('detalles') detalles: PlanDetalle[];
  @Input('edit') edit: boolean;
  @Input('usuarioId') usuarioId?: number;

  avatarChecked: boolean = false;
  loadedAvatar: boolean = false;
  noComments: boolean = undefined;
  hasFeedback: boolean = false;
  isStudent: boolean = false;
  isTrainer: boolean = false;
  hasImage: boolean = false;
  isAdmin: boolean = false;
  canSave: boolean = true;
  loaded: boolean = false;
  /**
   * Flag para determinar que sea un día del futuro. Si lo es, el alumno no puede dar feedback.
   * Modificado ticket 4365
   */
  future: boolean = false;

  especificaciones: string = "Sin especificaciones";
  ultimoDiaDisponibleConvertido: Date = null;
  fechaFeedback: string = "";
  respuesta: string = "";
  invisible: string = "";
  avatarUrl: string = "";
  fase1: string = "";
  fase2: string = "";
  fase3: string = "";

  datos: PlanDatosDia[] = [];

  respuestaRequired: boolean = false;

  longitudFeedback: number = 0;
  alumnoId: number = 0;

  planForm = new FormGroup({
    feedbackOpcion: new FormControl('', Validators.required),
    feedbackRespuesta: new FormControl(''),
  });

  private sharedData$: Subscription;

  dayForm = new FormGroup({
    fase1: new FormControl('', Validators.required),
    fase2: new FormControl('', Validators.required),
    fase3: new FormControl('', Validators.required),
    invisible: new FormControl('', Validators.required),
    especificaciones: new FormControl('', Validators.required)
  });

  constructor(
    private cdr: ChangeDetectorRef,
    private plansService: PlansService,
    private snack: SnackbarService,
    private sharedData: SharedDataService,
    private weekService: WeekService,
    private activatedrouter: ActivatedRoute,
    private userService: UserService,
    private parametrosService: ParametrosService,
    private rol: RolService,
    private hoursDayFixedService : HoursDayFixedService,
    private messagesService: MessagesService,
    private imageService: ImageService,
  ) { }

  ngOnInit(): void {
    
    this.isStudent = this.rol.isStudent();
    this.isAdmin = this.rol.isAdmin();
    this.isTrainer = this.rol.isTrainer();
    this.getLongitudMensaje();

    this.cdr.detectChanges();
    this.alumnoId = parseInt(localStorage.getItem('idAlumno'),10);
    if(!this.alumnoId) {
      this.alumnoId = parseInt(sessionStorage.getItem('id_usuario'),10);
    }

    this.planForm.statusChanges
    .subscribe(val => {
      const { feedbackRespuesta, feedbackOpcion } = this.planForm.getRawValue();
      if( (feedbackOpcion == 1 || feedbackOpcion == 2)) {
        this.respuestaRequired = true;
      }
      if( (feedbackOpcion == 3 || feedbackOpcion == 4) || feedbackRespuesta) {
        this.respuestaRequired = false;
      }
      if(this.planForm.valid) {
        this.editFormEvent.emit(this.planForm.value);
      }
    });

    this.sharedData$ = this.sharedData.currentMessage.subscribe(message => {
      if(message == "updateDays") {
      this.loaded = false;
       this.getFeedback();
       if(new Date(this.fechaFeedback) > new Date() ) {
         this.canGiveFeedback = false;
       }
      }

      if(message == "updateDay" || message == "reloadWeek" || message == "reloadWeek2") {
        this.loaded = false;

        // ticket 2480: removed sessionStorage.remove fecha
         this.getFeedback();
         if(new Date(this.fechaFeedback) > new Date() ) {
           this.canGiveFeedback = false;
         }
        }
      if(message === "semanaNoDisponible") {
        this.loaded = false;
        this.datos = null;
        this.fechaFeedback = sessionStorage.getItem('semana');
        this.loaded = true;
      }
    });


    // bugfix date format
    const today: string = this.hoursDayFixedService.getTodayFixedString();

    if(this.canGiveFeedback) {
      sessionStorage.setItem('fecha',today);
    } else {
      this.canSave = false;
    }
    this.getFeedback();
   
    const entrenamientoDiaTipo: string = sessionStorage.getItem('entrenamientoDiaTipo');
    if(entrenamientoDiaTipo == trainingDaysEnum.DESCANSO) {
      this.canGiveFeedback = false;
    } 
  }

  checkDate() {
    this.fechaFeedback = sessionStorage.getItem('fecha');
    const currentWeek: boolean = this.weekService.isCurrentWeek(this.fechaFeedback);
    const flagCambioSemana: boolean = JSON.parse(sessionStorage.getItem('flagCambioSemana'));
    if(currentWeek && flagCambioSemana) {
      this.fechaFeedback = this.hoursDayFixedService.getTodayFixedString();
    }
  }

  getFechaFeedbackWithCorrection() {
    this.fechaFeedback = sessionStorage.getItem('fecha');
    if(!this.fechaFeedback) {
      // bugfix date format "toDateString" was not correct, use "toISOString" and split to set hours to 12.

      this.fechaFeedback = this.hoursDayFixedService.getTodayFixedString();
    }
    
    const flagCambioSemana: boolean = JSON.parse(sessionStorage.getItem('flagCambioSemana'));
    if(flagCambioSemana) {
      this.checkDate();
    }
  }

  getFeedback() {
    // this.checkDate();
    this.getFechaFeedbackWithCorrection();
    this.plansService.getFeedback(this.weekService.convertDate(this.fechaFeedback), this.alumnoId).subscribe(response => {
      if(response) {
        //aca cargo el feedback
        this.planForm.patchValue({feedbackOpcion: response.feedbackOpcion});
        this.planForm.patchValue({feedbackRespuesta: response.feedbackRespuesta});
        this.respuesta = response.feedbackRespuesta;
        if(response.fechaFeedback && !response.feedbackRespuesta) {
          this.noComments = true;
        } else {
          this.noComments = false;
        }
        this.planForm.controls['feedbackRespuesta'].disable();
        this.canSave = false;
        this.hasFeedback = true;
        this.canGiveFeedback = false;
      } else {
        //default: opcion bien seleccionada y sin comentarios para alumno, no aplica para admin
        if(!this.isStudent) {
          this.planForm.patchValue({feedbackOpcion: null});
        } else {
          this.planForm.patchValue({feedbackOpcion: 3});
        }
        this.noComments = false;
        this.planForm.patchValue({feedbackRespuesta: null});
        this.planForm.controls['feedbackRespuesta'].enable();
        this.canSave = true;
        this.datos = null;
        this.respuesta = null;
        this.hasFeedback = false;
      }
      this.changeControls();
      this.check();
      // using avatar component
      // if(!this.usuarioId) {
      //   this.getProfileData();
      // }

      // optimizacion: evitar repetir pedido de perfil y avatar
      if(!this.avatarChecked) {
        this.getProfileData();
      }

    },
      error => {
        console.error(error);
      });
  }

  changeControls() {
    if(!this.hasFeedback) {
      this.dayForm.controls['fase1'].enable();
      this.dayForm.controls['fase2'].enable();
      this.dayForm.controls['fase3'].enable();
      this.dayForm.controls['invisible'].enable();
      this.dayForm.controls['especificaciones'].enable();
    } else {
      this.dayForm.controls['fase1'].disable();
      this.dayForm.controls['fase2'].disable();
      this.dayForm.controls['fase3'].disable();
      this.dayForm.controls['invisible'].disable();
      this.dayForm.controls['especificaciones'].disable();
    }
  }

  /**
   * Si el usuario no es un alumno ({@link isStudent}) llama a {@link getPlanDiaData}, si es un alumno:
   * 
   * Verifica que el día seleccionado no sea en el futuro (ticket 4365)
   * Si cumple habilita dar feedback, sino no habilita y el flag {@link future} es true. Si es un día de semanas próximas no deja ver la actividad (patch null), aunque ésta llegue desde el backend.
   * Si es un día de semanas pasadas permite ver los datos y editarlos.
   * 
   * Si el alumno no dio feedback para ese día, setea a null las opciones para que pueda darlo, 
   * setea la hora para el bugfix de España, verifica que la semana esté habilitada en la base de datos y,
   * si se cumplen todas las condiciones, llama a {@link getPlanDiaData}.
   */
  async check() {
    if(this.isStudent) {
      try {
        
        // ticket 4365: removidas validaciones del pasado, no puede dar feedback solo para el futuro
        const today: string = this.hoursDayFixedService.getTodayFixedString();
        if(new Date(this.fechaFeedback) > new Date(today)) {
          this.canGiveFeedback = false;
          this.future = true;
          //si estoy viendo un dia del futuro no tiene que tener opcion por defecto
          this.planForm.patchValue({feedbackOpcion: null});
          this.planForm.patchValue({feedbackRespuesta: null});
        } else {
          this.canGiveFeedback = true;
        }

        // optimizacion: prevenir llamar de nuevo y volver a convertir
        if(!this.ultimoDiaDisponibleConvertido) {
          let ultimoDiaDisponibleConvertido: Date = new Date(this.ultimoDiaDisponible);
          ultimoDiaDisponibleConvertido.setHours(12);
          ultimoDiaDisponibleConvertido.setMinutes(10);
          ultimoDiaDisponibleConvertido.setSeconds(10);
          this.ultimoDiaDisponibleConvertido = ultimoDiaDisponibleConvertido;
        }

        //bugfix españa
        let fechaFeedbackDate: Date = new Date(this.fechaFeedback);
        fechaFeedbackDate.setHours(12);
        fechaFeedbackDate.setMinutes(10);
        fechaFeedbackDate.setSeconds(10);
        
        if(  fechaFeedbackDate <= this.ultimoDiaDisponibleConvertido) {
          this.getPlanDiaData();
        }
      } catch {
      }
    } else {
      this.getPlanDiaData();
    }
  }

  saveButton(feedbackOpcion: number) {
    if(this.canSave && this.canGiveFeedback) {
      this.planForm.patchValue({feedbackOpcion: feedbackOpcion});
    }
  }

  onSubmit() {
    const { feedbackRespuesta, feedbackOpcion } = this.planForm.getRawValue();
    const today = new Date().toISOString();
    const alumnoId: number = parseInt(localStorage.getItem('idAlumno'),10);
    const usuarioId: number = parseInt(localStorage.getItem('idUsuario'),10);
    const fechaFeedback: string = this.hoursDayFixedService.getSplittedDate(this.fechaFeedback);
    const feedback: PostFeedback = {
      alumnoId: alumnoId,
      fechaFeedback: fechaFeedback,
      feedbackOpcion: feedbackOpcion,
      feedbackRespuesta: feedbackRespuesta,
      creado: today,
      creadoPor: usuarioId,
      ultimaModificacion: today,
      ultimaModificacionPor: usuarioId,
    }
    this.plansService.postFeedback(feedback).subscribe(response => {
      this.snack.snackTranslate('snacks.sucess.feedback');
      this.canSave = false;
      this.getFeedback();
      this.sendMessageToTrainer(feedback);
      const reloadDay: ReloadDay = {
        fechaFeedback: new Date(this.fechaFeedback).toISOString(),
        feedbackOpcion
      }
      sessionStorage.setItem('reloadDay',JSON.stringify(reloadDay));
      this.sharedData.changeMessage("reloadDay");
    },
      error => {
        console.error(error);
        this.snack.openSnackBar(error.error.message);
      });
  }

  onSubmitDayChanges() {
    const { fase1, fase2, fase3, invisible, especificaciones } = this.dayForm.getRawValue();
    let datos: PlanEditarDia[] = [
      {
        planDetalleTitulo: "Fase 1",
        actividad: fase1
      },
      {
        planDetalleTitulo: "Fase 2",
        actividad: fase2
      },
      {
        planDetalleTitulo: "Fase 3",
        actividad: fase3
      },
      {
        planDetalleTitulo: "Entrenamiento Invisible",
        actividad: invisible
      },
      {
        planDetalleTitulo: "Especificaciones del Entrenamiento",
        actividad: especificaciones
      },
    ];

    this.plansService.editarDiaCompleto(this.alumnoId,this.weekService.convertDate(this.fechaFeedback),datos)
    .subscribe(response => {
      this.snack.snackTranslate('snacks.sucess.plan.edit');
      this.sharedData.changeMessage("reloadWeek");
    },
    error => {
      console.error(error);
      this.snack.snackTranslate('snacks.error.plan.edit');
    });
  }

  /**
   * Obtiene los datos del plan del día y parchea el formulario
   */
  getPlanDiaData() {
    this.getFechaFeedbackWithCorrection();
    //si cambio la semana quiero ver el dia de hoy, sea o no lunes
    
    sessionStorage.removeItem('flagCambioSemana');
    this.plansService.getPlanDiaData(this.weekService.convertDate(this.fechaFeedback), this.alumnoId).subscribe(response => {
      if(response.length > 0) {
        let auxSort: PlanDatosDia [] = [];
        auxSort = response;
        auxSort.forEach(dato => {
          if(dato.planDetalleTitulo.includes('Fase')) {
            dato.clase = "subtitle1Primary";
          } else {
            dato.clase = "subtitle3";
          }
        });

        //ordenar la respuesta para mostrar, los datos no necesariamente vienen ordenados
        this.datos = [];
        this.datos.push(auxSort.find(dato => dato.planDetalleTitulo == "Fase 1"));
        this.datos.push(auxSort.find(dato => dato.planDetalleTitulo == "Fase 2"));
        this.datos.push(auxSort.find(dato => dato.planDetalleTitulo == "Fase 3"));
        this.datos.push(auxSort.find(dato => dato.planDetalleTitulo == "Entrenamiento Invisible"));
        this.datos.push(auxSort.find(dato => dato.planDetalleTitulo == "Especificaciones del Entrenamiento" || dato.planDetalleTitulo == "Especificaciones del Entreno"));
        if(this.isStudent) {
          //formatear con bulleteado
          this.datos.forEach(dato => {
            if(dato.planDetalleTitulo == "Entrenamiento Invisible" || dato.planDetalleTitulo == "Especificaciones del Entrenamiento" || dato.planDetalleTitulo == "Especificaciones del Entreno" || (dato.planDetalleTitulo == "Fase 1" && parseInt(localStorage.getItem('equipoId'),10) == 4)) {
              if(dato.actividad.charAt(0) == '-') {
                dato.actividad = dato.actividad.substring(1);
              }
              dato.actividad = '<li>'+dato.actividad;
              dato.actividad = dato.actividad.replace(/(?:\n-)/g, '</li><li>');
              dato.actividad = dato.actividad.replace(/(?:\n)/g, '<br/>');
              dato.actividad += '</li/>';
            }
          });
        }
        this.dayForm.patchValue({fase1: this.datos[0].actividad});
        this.dayForm.patchValue({fase2: this.datos[1].actividad});
        this.dayForm.patchValue({fase3: this.datos[2].actividad});
        this.dayForm.patchValue({invisible: this.datos[3].actividad});
        this.dayForm.patchValue({especificaciones: this.datos[4].actividad});
        const today = new Date();
        if(this.weekService.convertDate(today.toString()) >= this.weekService.convertDate(this.fechaFeedback)) {
          if(this.isStudent && sessionStorage.getItem('detalle') != DescansoEnum.DESCANSO) {
            if(!this.future) {
              this.canGiveFeedback = true;
            }
          } else {
            this.canGiveFeedback = false;
          }
        }
      } else {
        this.datos = null;
        this.canGiveFeedback = false;
        this.dayForm.patchValue({fase1: null});
        this.dayForm.patchValue({fase2: 'Descanso'});
        this.dayForm.patchValue({fase3: null});
        this.dayForm.patchValue({invisible: null});
        this.dayForm.patchValue({especificaciones: null});
      }
      this.loaded = true;
    },
      error => {
        console.error(error);
      });
  }

  getProfileData() {
    
    // optimizacion: alumno puede obtener su idUsuario de localStorage, admin no.
    if(this.isAdmin) {
      if(!this.usuarioId) {
        this.userService.getProfileUserId(this.alumnoId).subscribe(response => {
          this.getAvatar(response.usuarioId);
        },
        error => {
          console.error(error);
          this.snack.snackTranslate('snacks.error.generic');
        })
      } else {
        this.getAvatar(this.usuarioId);
      }
    } else {
      if(this.isStudent) {
        const userId: number = parseInt(localStorage.getItem('idUsuario'),10);
        this.getAvatar(userId);
      }
    }
  }

  async getAvatar(usuarioId: number) {
    try {
      this.avatarChecked = true;
      this.avatarUrl = await this.imageService.getAvatarUrl(usuarioId);
      this.loadedAvatar = true;
    } catch {
      this.loadedAvatar = true;
    }
  }




  // using avatar component
  // getProfileData() {
  //   this.userService.getProfileUserId(this.alumnoId).subscribe(response => {
  //    this.usuarioId = response.usuarioId;
  //   },
  //     error => {
  //       console.error(error);
  //       this.snack.snackTranslate('snacks.error.generic');
  //     })
  // }

  getLongitudMensaje() {
    this.parametrosService.getParametro('Feedback').subscribe(response => {
      this.longitudFeedback = parseInt(response[0].valor,10);
     },
       error => {
         console.error(error);
         this.snack.snackTranslate('snacks.error.generic');
       })
  }

  sendMessageToTrainer(feedback: PostFeedback) {

    this.parametrosService.getProcesos('Feedback','MensajeHabilitado').subscribe( response=> {
      if(parseInt(response.valor,10) == 1) {
        const metadataJson: MetadataAvisoFeedback  = {
          feedbackOpcion: feedback.feedbackOpcion,
          fechaFeedback: feedback.fechaFeedback
        }
    
        let enviarMensaje: EnviarMensaje = {
          mensaje: feedback.feedbackRespuesta,
          mensajeTipo: MessagesTypes.AVISO,
          metadata: JSON.stringify(metadataJson)
        }
    
        const canalId: number = parseInt(localStorage.getItem('canalIdEntrenador'),10);
        
        this.messagesService.postMensaje(canalId, enviarMensaje).subscribe(response => {
        }, error => {
          console.error(error);
          this.snack.snackTranslate('snacks.error.generic');
        });
      }
    },
    error => {
      console.error(error);
    });    
  }

  public ngOnDestroy(): void {
    this.sharedData$.unsubscribe();
  }

}
