import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class TokenDecoderService {

  constructor() { }
  token = localStorage.getItem('token');
  helper = new JwtHelperService();

  decodedToken() {
    let result = this.helper.decodeToken(localStorage.getItem('token'));
    return result;
  }

  expirationDate() {
    return this.helper.getTokenExpirationDate(this.token);  
  }

  isExpired() {
    if(this.token) {
      return this.helper.isTokenExpired(this.token);
    } else {
      return true;
    }
  }

  getRole() {
    let decoded = this.decodedToken();
    let retornable = null;
    if(decoded) {
      retornable = decoded.roles;
    }
    return retornable;
  }

}
