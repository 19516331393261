import { Component, OnInit } from '@angular/core';
import { MobileDetectorService } from 'src/app/services/mobileDetector/mobile-detector.service';
import { DownloadService } from '../../../api/services/download/download.service';
import { FileData } from '../../../api/interfaces/fileData';
import { saveAs } from 'file-saver';
import { WeekService } from '../../../utils/week/week.service';
import { SnackbarService } from 'src/app/utils/snackbar/snackbar.service';
import { ParametrosService } from 'src/app/api/services/parametros/parametros.service';


/**
 * Student can download plan pdf
 * Call weekComponent in order to switch weeks and see the plan for that week
 * Call feedbackComponent, in order to give/read feedback
 */
@Component({
  selector: 'app-student-plans',
  templateUrl: './student-plans.component.html',
  styleUrls: ['./student-plans.component.scss']
})
export class StudentPlansComponent implements OnInit {

  isMobile: boolean = false;
  descripcionUsuario: string = "";
  fechaFeedback: string = ""
  alumnoId: number = 0;

  ultimoDiaDisponible: Date = null;

  constructor(
    private mobileDetectorService: MobileDetectorService,
    private downloadService: DownloadService,
    private weekService: WeekService,
    private snack: SnackbarService,
    private parametrosService: ParametrosService,
  ) { }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    sessionStorage.setItem('active','planes');
    this.alumnoId = parseInt(localStorage.getItem('idAlumno'),10);
    this.descripcionUsuario =  localStorage.getItem("descripcionUsuario");
    this.check();
  }

  downloadFile() {
    let semana: string = sessionStorage.getItem('semana');
    if(!semana) {
      const aux: string[] = this.weekService.getWeek(new Date());
      semana = this.weekService.convertDate(aux[0]);
    }
    const fileData: FileData = {
      filename: semana+".pdf",
    }
    this.downloadService.download(this.alumnoId,semana)
      .subscribe(archivo => {
        saveAs(archivo, fileData.filename);
        this.snack.snackTranslate('snacks.sucess.plan.download');
      });
  }

  /**
   * Construye el último dia disponible a partir de la semana habilitada. Creada por optimización
   */
  async check() {
    const ultimaSemanaDisponible: string [] = await this.parametrosService.getUltimaSemanaDisponible();
    this.ultimoDiaDisponible = new Date(ultimaSemanaDisponible[6]);
  }

}