import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {HttpClient, HttpClientModule} from '@angular/common/http';

import { MaterialModule } from '../../../material/material.module';
import { MainpageadminComponent } from '../students/mainpageadmin/mainpageadmin.component';
import { AdminRoutingModule } from './admin-routing.module';
import { GlobalModule } from '../../global/modules/global.module'
import { ProfileComponent } from '../profile/profile/profile.component'
import { CreateStudentComponent } from '../students/create-student/create-student.component';
import { ProfileModule } from '../../profile/profile.module';
import { StudentsTableComponent } from '../students/students-table/students-table.component';
import { FiltersComponent } from '../students/filters/filters.component';
import { PaginatorComponent } from '../students/paginator/paginator.component';
import { PlansModule } from '../../plans/modules/plans.module';
import { MainpageTrainersComponent } from '../trainers/mainpage-trainers/mainpage-trainers.component';
import { TrainersTableComponent } from '../trainers/trainers-table/trainers-table.component';
import { CreateTrainerComponent } from '../trainers/create-trainer/create-trainer.component';
import { ProfileTrainerAdminComponent } from '../profile/profile-trainer-admin/profile-trainer-admin.component';

import { SettingsPageComponent } from '../settings/settings-page/settings-page.component';
import { SettingsCardComponent } from '../settings/settings-card/settings-card.component';
import { GroupsMainComponent } from '../settings/groups/groups-main/groups-main.component';
import { GroupsListComponent } from '../settings/groups/groups-list/groups-list.component';
import { GroupsItemComponent } from '../settings/groups/groups-item/groups-item.component';
import { GroupsNewComponent } from '../settings/groups/groups-new/groups-new.component';

import { PageDomiciliacionComponent } from '../domiciliacion/page-domiciliacion/page-domiciliacion.component';
import { MonthTableDomiciliacionComponent } from '../domiciliacion/month-table-domiciliacion/month-table-domiciliacion.component';
import { NewPopupDomiciliacionComponent } from '../domiciliacion/new-popup-domiciliacion/new-popup-domiciliacion.component';
import { MainTableDomiciliacionComponent } from '../domiciliacion/main-table-domiciliacion/main-table-domiciliacion.component';
import { FiltersDomiciliacionComponent } from '../domiciliacion/filters-domiciliacion/filters-domiciliacion.component';

import { EmisorDialogComponent } from '../domiciliacion/emisor-dialog/emisor-dialog.component';

import { PopupArancelComponent } from '../domiciliacion/popup-arancel/popup-arancel.component';
import { MessagesModule } from '../../messages/modules/messages.module';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { EstadoDomiciliacionComponent } from '../domiciliacion/estado-domiciliacion/estado-domiciliacion.component';
import { BotonesCobroDomiciliacionComponent } from '../domiciliacion/botones-cobro-domiciliacion/botones-cobro-domiciliacion.component';
import { SettingsArancelCardComponent } from '../settings/arancel/settings-arancel-card/settings-arancel-card.component';
import { SettingsArancelDialogComponent } from '../settings/arancel/settings-arancel-dialog/settings-arancel-dialog.component';
import { MessageDomiciliacionComponent } from '../domiciliacion/message-domiciliacion/message-domiciliacion.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  declarations: [
    MainpageadminComponent,
    ProfileComponent,
    CreateStudentComponent,
    StudentsTableComponent,
    FiltersComponent,
    PaginatorComponent,


    ProfileTrainerAdminComponent,

    MainpageTrainersComponent,
    TrainersTableComponent,
    CreateTrainerComponent,
    SettingsPageComponent,
    SettingsCardComponent,
    GroupsMainComponent,
    GroupsListComponent,
    GroupsItemComponent,
    GroupsNewComponent,
    SettingsArancelCardComponent,
    SettingsArancelDialogComponent,

    PageDomiciliacionComponent,
    MonthTableDomiciliacionComponent,
    NewPopupDomiciliacionComponent,
    MainTableDomiciliacionComponent,
    FiltersDomiciliacionComponent,
    PopupArancelComponent,
    EmisorDialogComponent,
    EstadoDomiciliacionComponent,
    BotonesCobroDomiciliacionComponent,
    MessageDomiciliacionComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    AdminRoutingModule,
    GlobalModule,
    ProfileModule,
    PlansModule,
    MessagesModule,
    NgxMaskModule.forRoot(maskConfig),

    HttpClientModule,
    TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        }
    })
  ],
  exports: [
  ]
})
export class AdminModule { }

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}