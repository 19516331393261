import { Injectable } from '@angular/core';
import { ParametrosService } from 'src/app/api/services/parametros/parametros.service';
import { PaletaColoresDefaultEnum } from 'src/app/enums/paletaColoresDefault';
import { ThemeService } from 'src/app/theme.service';

@Injectable({
  providedIn: 'root'
})
export class PaletaColoresService {

  constructor(
    private parametrosService: ParametrosService,
    private themeService: ThemeService
  ) { }

  /**
   * Change app.component.scss primary and secondary.
   * After login, primary and secondary is readed from backend and saved in LocalStorage, otherwhise the default colors are setted
   */
  setPaletaColores(): Promise <string> {
    this.setPaletaDefault();
    return new Promise((resolve, reject) => {
      this.parametrosService.getParametros('Paleta Colores').subscribe(response => {
        if(response.length > 0) {
          let index: number = response.findIndex(element => element.clave == "primary");
          if(index != -1) {
            document.documentElement.style.setProperty('--primary', response[index].valor);
          } 
          index = response.findIndex(element => element.clave == "secondary");
          if(index != -1) {
            document.documentElement.style.setProperty('--secondary', response[index].valor);
          }
          index = response.findIndex(element => element.clave == "primaryLigth");
          if(index != -1) {
            document.documentElement.style.setProperty('--primaryLigth', response[index].valor);
          }

          index = response.findIndex(element => element.clave == "primary50");
          if(index != -1) {
            document.documentElement.style.setProperty('--primary50', response[index].valor);
          }
          index = response.findIndex(element => element.clave == "secondary50");
          if(index != -1) {
            document.documentElement.style.setProperty('--secondary50', response[index].valor);
          }

          index = response.findIndex(element => element.clave == "backgroundMessages");
          if(index != -1) {
            document.documentElement.style.setProperty('--backgroundMessages', response[index].valor);
          }

          index = response.findIndex(element => element.clave == "material-theme");
          if(index != -1) {
            this.themeService.toggleThemeFromBackend(response[index].valor);
            resolve(response[index].valor);
          }
        }
      },
      error => {
        console.error(error);
        reject();
      });
    });
  }

  /**
   * Called after logout and expired session
   */
  setPaletaDefault() {
    document.documentElement.style.setProperty('--primary', PaletaColoresDefaultEnum.primary);
    document.documentElement.style.setProperty('--secondary', PaletaColoresDefaultEnum.secondary);
    document.documentElement.style.setProperty('--primaryLigth', PaletaColoresDefaultEnum.primaryLigth);

    document.documentElement.style.setProperty('--primary50', PaletaColoresDefaultEnum.primary50);
    document.documentElement.style.setProperty('--secondary50', PaletaColoresDefaultEnum.secondary50);
    document.documentElement.style.setProperty('--backgroundMessages', PaletaColoresDefaultEnum.backgroundMessages);
  }
}

// paleta de sebastian
// primario #2C85C6
// secundario #FF9E68
