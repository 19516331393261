import { Component, Input, OnInit } from '@angular/core';
import { Canal, Message, MessageListOption, Participante } from '../../../../api/interfaces/messages';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from "@angular/material/icon";
import { SharedDataService } from '../../../../services/sharedData/shared-data.service';
import { MessagesService } from 'src/app/api/services/messages/messages.service';
import { RolService } from 'src/app/services/rol/rol.service';
import { ParticipanteService } from 'src/app/services/participante/participante.service';
import { ImageService } from 'src/app/api/services/image/image.service';

/**
 * Display canals to click and change current conversation. 
 * Name Option is deprecated, backend named it Canal
 * @todo refactor option to canal
 */
@Component({
  selector: 'app-option',
  templateUrl: './option.component.html',
  styleUrls: ['./option.component.scss']
})
export class OptionComponent implements OnInit {

  @Input('canal') canal: Canal;
  @Input('massive') massive: boolean;
  @Input('unreadMassive') unreadMassive?: boolean;

  nameClass: string = "subtitle3";
  class: string = "messageOption";

  avatarUrl: string = "";
  idUsuario: number = null;

  isAdmin: boolean;

  id: number = 0;

  otroParticipante: Participante = null;
  miParticipante: Participante = null;
  equipoNombre: string = "pepito corre";
  
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private sharedData: SharedDataService,
    private messagesService: MessagesService,
    private rolService: RolService,
    private participanteService: ParticipanteService,
    private imageService: ImageService
  ) {
    this.matIconRegistry.addSvgIcon(
      `massive`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../assets/massive.svg')
    );
    this.matIconRegistry.addSvgIcon(
      `massiveEmpty`,
      this.domSanitizer.bypassSecurityTrustResourceUrl('../../../../../assets/massiveEmpty.svg')
    );
   }

  ngOnInit(): void {
    this.equipoNombre = localStorage.getItem('equipoNombre');
    this.sharedData.currentMessage.subscribe(message => {
      if (message === "changeMessageOption" && this.canal) {
        if(this.canal.selected) {
          this.class= "messageOptionSelected";
        } else {
          this.class = "messageOption";
        }
      }
      if(message === "ultimoMensajeAdmin" && this.canal) {
        if(this.canal.selected) {
          const aux: Message = JSON.parse(sessionStorage.getItem('ultimoMensaje'));
          this.canal.ultimoMensaje = aux.mensaje;
        }
      }
    });

    this.isAdmin = this.rolService.isAdmin();
    
    this.otroParticipante = this.participanteService.getParticipante(this.canal);
    this.miParticipante = this.participanteService.getMiParticipante(this.canal);
    if(this.canal) {
      if(!this.miParticipante.flgLeido) {
        this.nameClass = "subtitle3Blue"
      }
    }
    this.otroParticipante = this.participanteService.getParticipante(this.canal);
    if(this.otroParticipante && this.otroParticipante.idUsuario) {
      this.idUsuario = this.otroParticipante.idUsuario;
    }
  }

  markUnread() {
    this.messagesService.setUnreadRead(this.canal.id).subscribe( response=> {
      this.sharedData.changeMessage("ultimoMensaje");
    },
    error => {
      console.error(error);
    });
  }

}
