<div class="background">

    <app-header></app-header>
    <app-navbar *ngIf="!isMobile"></app-navbar>
    <section>
        <div >
            <div *ngIf="!isMobile">
                <p class="headline4"> {{'section.help' | translate}}</p>
            </div>
            <div *ngIf="isMobile">
                <br/>
                <p class="headline5 flexLeft"> {{'section.help' | translate}}</p>
            </div>
            <app-subseccion-help [preguntasFrecuentes]="preguntasFrecuentesCero"></app-subseccion-help>
            <app-subseccion-help [preguntasFrecuentes]="preguntasFrecuentesPlanes"></app-subseccion-help>
            <app-subseccion-help [preguntasFrecuentes]="preguntasFrecuentesComunicacion"></app-subseccion-help>
            <app-subseccion-help [preguntasFrecuentes]="preguntasFrecuentesAdicional"></app-subseccion-help>
            <app-subseccion-help [preguntasFrecuentes]="preguntasFrecuentesPerfil"></app-subseccion-help>
        </div>
    </section>
</div>