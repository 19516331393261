import { Component, OnInit, Input } from '@angular/core';
import { PreguntaFrecuente } from 'src/app/api/interfaces/help';

/**
 * calls PreguntaHelpComponent for each FAQ and show subseccion's title
 */
@Component({
  selector: 'app-subseccion-help',
  templateUrl: './subseccion-help.component.html',
  styleUrls: ['./subseccion-help.component.scss']
})
export class SubseccionHelpComponent implements OnInit {
  @Input('preguntasFrecuentes') preguntasFrecuentes: PreguntaFrecuente[];

  constructor() { }

  ngOnInit(): void {
  }

}
