<p class="headline6">{{ 'registration.stepThree.achievements' | translate}}</p>

<p class="subtitle1"
    *ngIf="disable && allAchievements.route.length == 0 && allAchievements.pista.length == 0 && allAchievements.trail.length == 0 && allAchievements.ultra.length == 0">
    {{'planes.objetivesPopup.emptyAchievements' | translate}}
</p>

<!-- ruta -->
<p class="body2">{{'registration.stepThree.question8.ruta' | translate}}</p>

<app-achievements 
    [alumnoId]="alumnoId" 
    [edit]="true" 
    [route]="allAchievements.route" 
    [mode]="'ruta'"
    (changeAchievementEvent)="changeAchievement($event)">
</app-achievements>


<!-- pista -->
<p class="body2"color="primary">{{'registration.stepThree.question8.pista' | translate}}</p>

<app-achievements 
    [alumnoId]="alumnoId" 
    [edit]="true" 
    [achievement]="allAchievements.pista" 
    [mode]="'pista'"
    (changeAchievementEvent)="changeAchievement($event)">
</app-achievements>


<!-- trail -->
<p class="body2">{{'registration.stepThree.question8.trail' |translate}}</p>

<app-achievements 
    [alumnoId]="alumnoId" 
    [edit]="true" 
    [achievement]="allAchievements.trail" 
    [mode]="'trail'"
    (changeAchievementEvent)="changeAchievement($event)">
</app-achievements>


<!-- ultra -->
<p class="body2"  color="primary">{{'registration.stepThree.question8.ultra' | translate}}</p>

<app-achievements 
    [alumnoId]="alumnoId" 
    [edit]="true" 
    [achievement]="allAchievements.ultra" 
    [mode]="'ultra'"
    (changeAchievementEvent)="changeAchievement($event)">
</app-achievements>
