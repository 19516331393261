import { Component, OnInit, Input } from '@angular/core';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { StepperEnum } from 'src/app/enums/stepper';
import { Bank } from '../../../api/interfaces/register';
import { FormValidatorService } from '../../../services/formValidator/form-validator.service';
import { MobileDetectorService } from '../../../services/mobileDetector/mobile-detector.service';
import { SharedDataService } from '../../../services/sharedData/shared-data.service';

@Component({
  selector: 'app-step-four',
  templateUrl: './step-four.component.html',
  styleUrls: ['./step-four.component.scss']
})
export class StepFourComponent implements OnInit {

  @Input('cantidadPasosHabilitados') cantidadPasosHabilitados: number;
  @Input('posicion') posicion: number;

  isMobile: boolean = false;
  bankForm = new FormGroup({
    entidad: new FormControl(),
    claveBancaria: new FormControl("", [
      Validators.pattern(/^[a-zA-Z0-9]+$/),
      Validators.minLength(24),
      Validators.maxLength(24),
    ]),
  });
  constructor(
    private formValidatorService: FormValidatorService,
    private sharedData: SharedDataService,
    private mobileDetectorService: MobileDetectorService,
  ) { }

  ngOnInit(): void {
    this.isMobile = this.mobileDetectorService.isMobile();
    this.bankForm.statusChanges
    .subscribe(val => this.formValidatorService.onFormValidation(val, "step4","bank"));

    this.sharedData.currentMessage.subscribe(message => {
      if(message === StepperEnum.SAVE_STEP) {
        sessionStorage.setItem('bank', JSON.stringify(this.bankForm.value));
      }
  });
  }
}
