<div  *ngIf="loaded">

  <nav *ngIf="!isMobile">
    <div class="flexInColumn" *ngIf="loaded">
      <div class="navbarButton" *ngFor="let option of navbarButton">
        <app-navbar-button [option]="option" [showCircle]="showCircle"></app-navbar-button>
      </div>
    </div>
    <br/><br/>
  </nav>
  
  <button *ngIf="isMobile" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
    <mat-icon *ngIf="showCircle" matBadge="&#8288;" matBadgeColor="warn" class="blackIcon">menu</mat-icon>
    <mat-icon *ngIf="!showCircle" class="blackIcon">menu</mat-icon>
  </button>
  <mat-menu #menu="matMenu" [backdropClass]="'backdropBackground'" [hasBackdrop]="true">
    <div *ngFor="let option of navbarButton">
      <app-navbar-button [option]="option" [showCircle]="showCircle"></app-navbar-button>
    </div>
  </mat-menu>
</div>